import { combineReducers } from 'redux';
import { IndexedById, MessageVariant, InAppNotificationMessage } from '../models';
import { MessageActions, MessagesActionsType } from './message.action';

export interface MessageState {
  byId: IndexedById<InAppNotificationMessage>;
  allIds: string[];
}

export const MESSAGES_INITIAL_STATE = {
  byId: {},
  allIds: [],
};

const messageReducer = (state: MessageState = MESSAGES_INITIAL_STATE, action: MessageActions): MessageState => {
  switch (action.type) {
    case MessagesActionsType.SHOW_MESSAGE:
      const message = action.payload;
      if (!action.payload.message && action.payload.type !== MessageVariant.Custom) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [message.id]: message,
        },
        allIds: [...state.allIds, message.id],
      };
    case MessagesActionsType.DELETE_MESSAGE:
      const byId = { ...state.byId };
      delete byId[action.payload];

      return {
        ...state,
        byId,
        allIds: state.allIds.filter((id) => id !== action.payload),
      };
    case MessagesActionsType.DELETE_ALL_MESSAGES:
      return MESSAGES_INITIAL_STATE;
    default:
      return state;
  }
};

export const messages = combineReducers({
  result: messageReducer,
});

export default messages;
