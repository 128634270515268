import React from 'react';
import { defineMessages } from 'react-intl';
import config from '../../config';
import { logger } from '../../logging';
import { addListener, addSmartListener } from '../../middlewares/listener.middleware';
import { Integrations, MessageVariant } from '../../models';
import { selectedLanguageSelector } from '../../settings/settings.selector';
import { deleteAllMessages, showMessage } from '../../inAppNotification/message.action';
import { uuid } from '../../uuid';
import { MsTeamsAuthenticationActionsType } from '../constants';
import { userObjectIdSelector } from '../sdk.selectors';
import { signInError, signInProcessing, signInSuccess, signOutSuccess } from './authentication.actions';
import * as authentication from './authentication.service';
import { MsTeamsAuthButton } from './MsTeamsAuthButton.container';
import { Store } from 'redux';

export const definedMessages = defineMessages({
  SIGNOUT_REQUEST_ERROR: {
    id: 'Authentication.SignOutRequestError',
    defaultMessage: 'An authentication error has occured. Please sign out then sign back in.',
  },
});

export const signOutMessageCreator = (store: Store) => ({
  id: uuid(),
  type: MessageVariant.Error,
  message: definedMessages.SIGNOUT_REQUEST_ERROR,
  params: {
    autoHide: false,
    actions: (
      <MsTeamsAuthButton
        key="close"
        color="inherit"
        variant="secondary"
        onClick={() => store.dispatch(deleteAllMessages())}
      />
    ),
  },
});

addListener(MsTeamsAuthenticationActionsType.SIGNIN_REQUEST)(() => {
  return authentication.signIn().then(
    (info) => signInProcessing(info),
    (error) => signInError(error),
  );
});

addListener(MsTeamsAuthenticationActionsType.SIGNING_PROCESSING)(async (_, { payload: { token, jiveId } }) => {
  try {
    const { id, displayName, entitlements } = await authentication.whoAmI(token);
    return signInSuccess({ token, jiveId, externalUserKey: id, name: displayName, entitlements });
  } catch (e) {
    logger.error('Error logging in msteams app user', e);
    return signInError();
  }
});

addListener(MsTeamsAuthenticationActionsType.SIGNOUT_REQUEST)(() => {
  return authentication.signOut().then(() => signOutSuccess());
});

addSmartListener(MsTeamsAuthenticationActionsType.SIGNOUT_REQUEST_ERROR)((store) => {
  store.dispatch(showMessage(signOutMessageCreator(store)));
});

addListener(MsTeamsAuthenticationActionsType.REDIRECT_SIGN_IN)((state) => {
  const locale = selectedLanguageSelector(state);
  const userObjectId = userObjectIdSelector(state);
  const msTeamsConfig = config[Integrations.MsTeams];

  const redirectPath = window.location.host.includes('jive.com')
    ? '/embedded-integrations/msteams/loginSuccess'
    : '/msteams/loginSuccess';

  const redirectURL = new URL(redirectPath, window.location.href).toString();

  const queryParams = new URLSearchParams();
  queryParams.append('action', 'link');
  queryParams.append('clientId', config.integrationClientId);
  queryParams.append('userObjectId', userObjectId ?? '');
  queryParams.append('locale', locale);
  queryParams.append('redirectUrl', redirectURL);

  const url = new URL(`${config.jiveUrl}${msTeamsConfig.integrationPageUri}`);
  url.search = queryParams.toString();
  window.location.replace(url.toString());
});

addListener(MsTeamsAuthenticationActionsType.REDIRECT_SIGN_OUT)(() => {
  const msTeamsConfig = config[Integrations.MsTeams];

  const redirectPath = window.location.host.includes('jive.com')
    ? '/embedded-integrations/msteams/logoutSuccess'
    : '/msteams/logoutSuccess';
  const redirectURL = new URL(redirectPath, window.location.href).toString();

  const queryParams = new URLSearchParams();
  queryParams.append('client_id', msTeamsConfig.enablementClientId);
  queryParams.append('redirect_uri', redirectURL);

  const url = new URL(`${msTeamsConfig.lmiOAuthBase}${msTeamsConfig.logoutPath}`);
  url.search = queryParams.toString();
  window.location.replace(url.toString());
});
