import { createSelector } from 'reselect';
import { AppState } from '../../reducers/index';

const coxCustomerSelector = (state: AppState) => state.cox.customer;

export const loadCustomerRequestStateSelector = createSelector(
  coxCustomerSelector,
  (customer) => customer.loadRequestState,
);
export const customerSelector = createSelector(coxCustomerSelector, (customer) => customer.customer);
