import { makeActionCreator } from '../actions/actionCreators';
import {
  CallDispositionValue,
  SalesforceCallLog,
  SalesforceCallLogWithNames,
  TaskRelatedObjectPrefixes,
  TaskRelatedThemeItems,
} from '../../../salesforce-shared/salesforce-service.models';
import { WithMessage } from '../models';

export enum SalesforceActionType {
  OPEN_EDITIONS_HELP_PAGE = 'salesforce/editionsHelpPage/open',
  CONTACT_CREATE_FORM_OPENED = 'salesforce/entity/create/Success',
  UPDATE_CALL_DISPOSITION_VALUES = 'salesforce/settings/callDispositionValues/update',
  LOAD_SAVED_CALL_LOG = 'salesforce/callLog/load',
  LOAD_SAVED_CALL_LOG_SUCCESS = 'salesforce/callLog/load/success',
  LOAD_SAVED_CALL_LOG_ERROR = 'salesforce/callLog/load/error',
  CALL_LOG_STARTED = 'salesforce/callLog/save/started',
  CALL_LOG_UPDATE_STARTED = 'salesforce/callLog/update/started',
  CALL_LOG_SAVED = 'salesforce/callLog/save/success',
  CALL_LOG_ERROR = 'salesforce/callLog/save/error',
  INIT_ERROR = 'salesforce/initialization/error',
  STORE_CALL_LOG = 'salesforce/storeCallLog',
  REMOVE_STORED_CALL_LOG = 'salesforce/removeCallLog',
  SEE_LICENSE_WARNING = 'salesforce/seeLicenseWarning',
  GET_TASK_RELATIONS_REQUEST = 'salesforce/getTaskRelations/request',
  GET_TASK_RELATIONS_SUCCESS = 'salesforce/getTaskRelations/success',
  GET_TASK_RELATIONS_ERROR = 'salesforce/getTaskRelations/error',

  SEARCH_WHAT_OBJECTS_REQUEST = 'salesforce/searchWhatObjects/request',
  SEARCH_WHAT_OBJECTS_SUCCESS = 'salesforce/searchWhatObjects/success',
  SEARCH_WHAT_OBJECTS_ERROR = 'salesforce/searchWhatObjects/error',
  CLEAR_WHAT_OBJECTS = 'salesforce/searchWhatObjects/clear',

  SEARCH_WHO_OBJECTS_REQUEST = 'salesforce/searchWhoObjects/request',
  SEARCH_WHO_OBJECTS_SUCCESS = 'salesforce/searchWhoObjects/success',
  SEARCH_WHO_OBJECTS_ERROR = 'salesforce/searchWhoObjects/error',
  CLEAR_WHO_OBJECTS = 'salesforce/searchWhoObjects/clear',
}

export interface GetMoreNotesPayload {
  contactId: string;
  nextOffset: number;
  notesCount: number;
}

export interface LogPayload {
  error: Error;
}

export const openSalesforceEditionsHelpPage = makeActionCreator(SalesforceActionType.OPEN_EDITIONS_HELP_PAGE)();
export const createContactFormOpened = makeActionCreator(SalesforceActionType.CONTACT_CREATE_FORM_OPENED)();
export const updateCallDispositionValues = makeActionCreator(SalesforceActionType.UPDATE_CALL_DISPOSITION_VALUES)<
  CallDispositionValue[]
>();

export interface GetSavedCallLogPayload {
  callId: string;
}
export const loadSavedCallLogRequest = makeActionCreator(
  SalesforceActionType.LOAD_SAVED_CALL_LOG,
)<GetSavedCallLogPayload>();
export const loadSavedCallLogError = makeActionCreator(SalesforceActionType.LOAD_SAVED_CALL_LOG_ERROR)<
  GetSavedCallLogPayload & WithMessage
>();

export interface LoadSavedCallLogSuccessPayload {
  callId: string;
  callLog?: SalesforceCallLogWithNames;
}

export const loadSavedCallLogSuccess = makeActionCreator(
  SalesforceActionType.LOAD_SAVED_CALL_LOG_SUCCESS,
)<LoadSavedCallLogSuccessPayload>();

export const salesforceCallLogStarted = makeActionCreator(SalesforceActionType.CALL_LOG_STARTED)();
export const salesforceCallLogUpdateStarted = makeActionCreator(SalesforceActionType.CALL_LOG_UPDATE_STARTED)();

export const salesforceCallLogSaved = makeActionCreator(SalesforceActionType.CALL_LOG_SAVED)<{
  callId: string;
  taskRecordId: string;
}>();

export const salesforceCallLogError = makeActionCreator(SalesforceActionType.CALL_LOG_ERROR)<{ error: any }>();

export const salesforceInitializationError = makeActionCreator(SalesforceActionType.INIT_ERROR)<{
  error: string;
}>();

export const storeSalesforceCallLog = makeActionCreator(SalesforceActionType.STORE_CALL_LOG)<{
  callId: string;
  callLog: SalesforceCallLog;
}>();
export const removeSalesforceStoredCallLog = makeActionCreator(SalesforceActionType.REMOVE_STORED_CALL_LOG)<{
  callId: string;
}>();

export const getTaskRelationsRequest = makeActionCreator(SalesforceActionType.GET_TASK_RELATIONS_REQUEST)();
export const getTaskRelationsSuccess = makeActionCreator(SalesforceActionType.GET_TASK_RELATIONS_SUCCESS)<{
  taskWhatIdObjectNames: string[];
  taskWhoIdObjectNames: string[];
  taskRelatedThemeItems: TaskRelatedThemeItems;
  taskRelatedObjectPrefixes: TaskRelatedObjectPrefixes;
}>();
export const getTaskRelationsError = makeActionCreator(SalesforceActionType.GET_TASK_RELATIONS_ERROR)();

export const searchWhatObjectsRequest = makeActionCreator(SalesforceActionType.SEARCH_WHAT_OBJECTS_REQUEST)();
export const searchWhatObjectsSuccess = makeActionCreator(SalesforceActionType.SEARCH_WHAT_OBJECTS_SUCCESS)<
  Array<{ id: string; name: string }>
>();
export const searchWhatObjectsError = makeActionCreator(SalesforceActionType.SEARCH_WHAT_OBJECTS_ERROR)();
export const clearWhatObjects = makeActionCreator(SalesforceActionType.CLEAR_WHAT_OBJECTS)();

export const searchWhoObjectsRequest = makeActionCreator(SalesforceActionType.SEARCH_WHO_OBJECTS_REQUEST)();
export const searchWhoObjectsSuccess = makeActionCreator(SalesforceActionType.SEARCH_WHO_OBJECTS_SUCCESS)<
  Array<{ id: string; name: string }>
>();
export const searchWhoObjectsError = makeActionCreator(SalesforceActionType.SEARCH_WHO_OBJECTS_ERROR)();
export const clearWhoObjects = makeActionCreator(SalesforceActionType.CLEAR_WHO_OBJECTS)();

export const seeLicenseWarning = makeActionCreator(SalesforceActionType.SEE_LICENSE_WARNING)();

export type SalesforceActions =
  | ReturnType<typeof openSalesforceEditionsHelpPage>
  | ReturnType<typeof createContactFormOpened>
  | ReturnType<typeof updateCallDispositionValues>
  | ReturnType<typeof loadSavedCallLogRequest>
  | ReturnType<typeof loadSavedCallLogSuccess>
  | ReturnType<typeof loadSavedCallLogError>
  | ReturnType<typeof salesforceCallLogStarted>
  | ReturnType<typeof salesforceCallLogUpdateStarted>
  | ReturnType<typeof salesforceCallLogSaved>
  | ReturnType<typeof salesforceCallLogError>
  | ReturnType<typeof salesforceInitializationError>
  | ReturnType<typeof storeSalesforceCallLog>
  | ReturnType<typeof removeSalesforceStoredCallLog>
  | ReturnType<typeof seeLicenseWarning>
  | ReturnType<typeof getTaskRelationsRequest>
  | ReturnType<typeof getTaskRelationsSuccess>
  | ReturnType<typeof getTaskRelationsError>
  | ReturnType<typeof searchWhatObjectsRequest>
  | ReturnType<typeof searchWhatObjectsSuccess>
  | ReturnType<typeof searchWhatObjectsError>
  | ReturnType<typeof searchWhoObjectsRequest>
  | ReturnType<typeof searchWhoObjectsSuccess>
  | ReturnType<typeof searchWhoObjectsError>
  | ReturnType<typeof clearWhoObjects>
  | ReturnType<typeof clearWhatObjects>;
