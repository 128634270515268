import { MessageBody } from '@ucc/messaging-service';
import { combineReducers, Reducer } from 'redux';
import { ActionTypes } from '../actions/actionCreators';
import * as conversationsActions from './conversations.actions';
import { PagedConversations } from '@ucc/messaging-platform-service';
import { conversationMessagesReducer } from './conversationMessages.reducer';

type ModifiedPagedConversations = Partial<Pick<PagedConversations<MessageBody>, 'pageIdentifiers'>> &
  Omit<PagedConversations<MessageBody>, 'pageIdentifiers'>;

export interface ConversationsState extends ModifiedPagedConversations {
  loading: boolean;
  error: boolean;
  conversationsPhoneNumber?: string;
}

export const CONVERSATIONS_INITIAL_STATE: ConversationsState = {
  conversations: [],
  firstPage: false,
  pageIdentifiers: undefined,
  loading: false,
  error: false,
  conversationsPhoneNumber: undefined,
};

export const conversationsReducer: Reducer<ConversationsState, ActionTypes<typeof conversationsActions>> = (
  state = CONVERSATIONS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case conversationsActions.clearConversationsAction.type:
      return { ...CONVERSATIONS_INITIAL_STATE, conversationsPhoneNumber: state.conversationsPhoneNumber };
    case conversationsActions.selectConversationsPhoneNumberAction.type:
      return { ...CONVERSATIONS_INITIAL_STATE, conversationsPhoneNumber: action.payload };
    case conversationsActions.loadConversationsAction.type:
      return { ...state, loading: true };
    case conversationsActions.loadConversationsSuccessAction.type:
      return {
        ...state,
        loading: false,
        ...action.payload,
        conversations: [...state.conversations, ...action.payload.conversations],
      };
    case conversationsActions.loadConversationsErrorAction.type:
      return { ...state, loading: false, error: true };
    case conversationsActions.dismissConversationsLoadErrorAction.type:
      return { ...state, error: false };
  }
  return state;
};

export const conversationsCombinedReducer = combineReducers({
  conversations: conversationsReducer,
  conversationMessages: conversationMessagesReducer,
});
