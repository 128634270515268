import { GetMoreCasesRequestMeta } from './case.actions';
import { SalesforceCase } from '../../../../salesforce-shared/salesforce-service.models';

export enum GetCasesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export enum GetMoreCasesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface GetCasesState {
  requestState: GetCasesRequestState;
  moreRequestState: GetMoreCasesRequestState;

  requestMeta?: GetMoreCasesRequestMeta;
  cases: SalesforceCase[];
  casesCount?: number;
}
