import { Action } from 'redux';
import { ERROR, REQUEST, SUCCESS } from '../constants';

export const ERROR_INITIAL_STATE = false;

const errorReducer = (basicAction: string) => (state = ERROR_INITIAL_STATE, action: Action<string>) => {
  if (action.type.includes(basicAction)) {
    if (action.type.includes(ERROR)) {
      return true;
    }
    if (action.type.includes(SUCCESS) || action.type.includes(REQUEST)) {
      return false;
    }
  }
  return state;
};

export default errorReducer;
