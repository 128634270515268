import { createSelector } from 'reselect';
import { AppState } from '../../reducers';

const zendeskStateSelector = (state: AppState) => state.zendesk;

export const ticketsSelector = createSelector(zendeskStateSelector, (zendeskState) => zendeskState.tickets.tickets);

export const ticketsLoadingSelector = createSelector(
  zendeskStateSelector,
  (zendeskState) => zendeskState.tickets.ticketLoadingState,
);
