import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../../theme/Typography.component';

const definedMessages = defineMessages({
  COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_ERROR: {
    id: 'ClioCommunicationLogForm.AlreadyLogged.Error',
    defaultMessage: 'This call is already logged in Clio',
  },
  COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_CONFIRMATION: {
    id: 'ClioCommunicationLogForm.AlreadyLogged.Confirmation',
    defaultMessage: 'Are you sure you want to log it again?',
  },
  COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_HINT: {
    id: 'ClioCommunicationLogForm.AlreadyLogged.Hint',
    defaultMessage: '(This will mean multiple logging for this call.)',
  },
});

export const CommunicationLogConfirmation: React.FC = () => {
  return (
    <>
      <Typography>
        <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_ERROR} />
      </Typography>
      <Typography>
        <b>
          <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_CONFIRMATION} />
        </b>
      </Typography>
      <Typography>
        <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_HINT} />
      </Typography>
    </>
  );
};
