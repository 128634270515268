import { Reducer } from 'redux';
import { SettingsActionTypes } from '../../constants';
import { selectLine } from '../../settings/settings.action';
import { TestCallActions, TestCallActionType, TestCallOptions } from './testCall.actions';

export enum TestCallRequestState {
  Initial = 0,
  Loading = 1,
  Succeeded = 2,
  Failed = 3,
}

export interface TestCallState {
  testCallState: TestCallRequestState;
  testCallOptions?: TestCallOptions;
  testCallId?: string;
}

const TEST_CALL_INITIAL_STATE: TestCallState = {
  testCallState: TestCallRequestState.Initial,
  testCallOptions: undefined,
  testCallId: undefined,
};

export const testCallReducer: Reducer<TestCallState, TestCallActions | ReturnType<typeof selectLine>> = (
  state = TEST_CALL_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SettingsActionTypes.SELECT_LINE:
      return {
        ...state,
        testCallState: TestCallRequestState.Initial,
      };
    case TestCallActionType.START_TEST_CALL:
      return {
        ...state,
        testCallState: TestCallRequestState.Loading,
        testCallOptions: action.payload,
      };
    case TestCallActionType.TEST_CALL_FAILED: {
      return {
        ...state,
        testCallState: TestCallRequestState.Failed,
      };
    }
    case TestCallActionType.TEST_CALL_SUCCEEDED: {
      return {
        ...state,
        testCallState: TestCallRequestState.Succeeded,
        testCallId: action.payload.id,
      };
    }
    default:
      return state;
  }
};
