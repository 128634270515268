import { TextArea as ChamTextArea, TextAreaProps } from '@getgo/chameleon-web/react';
import React, { RefObject, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { newTracker } from '../analytics-new/tracker-new';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { logger } from '../logging';
import { useSelector } from 'react-redux';
import { injectedIntlSelector } from '../settings/settings.selector';
import { makeStyles } from '@material-ui/core';

export interface TextAreaComponentProps extends TextAreaProps {
  value: string;
  labelDescription?: MessageDescriptor;
  placeholderDescriptor?: MessageDescriptor;
  required?: boolean;
  inputRef?: RefObject<any>;
  autoFocus?: boolean;
  onBlur?: () => void;
  trackingEvent?: TrackingEvent;
  className?: string;
  helperText?: MessageDescriptor;
  name: string;
}

const useStyles = makeStyles({
  requiredSup: {
    verticalAlign: 'top',
  },
});

export const TextArea: React.FC<TextAreaComponentProps> = ({
  onChange,
  value,
  required,
  labelDescription,
  placeholderDescriptor,
  autoFocus,
  onBlur,
  trackingEvent,
  className,
  inputRef,
  helperText,
  name,
  fullwidth,
  ...rest
}) => {
  const classes = useStyles();
  const requiredSign = <sup className={classes.requiredSup}>*</sup>;
  const label = labelDescription ? <FormattedMessage {...labelDescription} /> : null;
  const [hasValueChanged, setChanged] = useState<boolean>(false);
  const [alreadyTracked, setAlreadyTracked] = useState<boolean>(false);
  const intl = useSelector(injectedIntlSelector);

  const isRequired = required === undefined ? true : required;

  const getLabel = () => {
    if (label && isRequired) {
      return (
        <>
          {label} {requiredSign}
        </>
      );
    } else if (label) {
      return label;
    } else {
      return null;
    }
  };

  return (
    <ChamTextArea
      name={name}
      ref={inputRef}
      autoFocus={autoFocus}
      required={isRequired}
      fullwidth={fullwidth ?? true}
      className={className}
      helperText={helperText ? intl.formatMessage(helperText) : ''}
      placeholder={placeholderDescriptor ? intl.formatMessage(placeholderDescriptor) : undefined}
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        setChanged(true);
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur();
        }
        if (trackingEvent && hasValueChanged && !alreadyTracked) {
          try {
            newTracker.trackAnalyticsEvent(trackingEvent);
          } catch (error) {
            logger.error('Error while tracking textarea', error);
          }
          setAlreadyTracked(true);
        }
      }}
      {...rest}
    >
      {getLabel()}
    </ChamTextArea>
  );
};
