import Qs from 'qs';
import React from 'react';
import { extractStateObject } from './loginHelper';

interface LogoutStateObject {
  logoutSuccessPath: string;
  logoutSuccessOrigin: string;
  integration: string;
  ƒ;
}

export const LogoutComponent: React.FC = () => {
  let hash = window.location.hash;
  if (hash.indexOf('#') === 0) {
    hash = hash.slice(1);
  }

  let search = window.location.search;
  if (search.indexOf('?') === 0) {
    search = search.slice(1);
  }

  const { state } = Qs.parse(hash || search);
  const { logoutSuccessPath, logoutSuccessOrigin, integration } = extractStateObject<LogoutStateObject>(
    state as string,
  );
  const redirectOrigin =
    logoutSuccessOrigin && logoutSuccessOrigin.includes(window.location.host)
      ? logoutSuccessOrigin
      : window.location.origin;

  document.location.href = `${redirectOrigin}${logoutSuccessPath}?integration=${integration}${
    window.location.hash || `&${search}`
  }`;
  return null;
};
