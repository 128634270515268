import { defineMessages, FormattedMessage } from 'react-intl';
import React, { FC } from 'react';
import { Button } from '@getgo/chameleon-web/react';
import { useDispatch, useSelector } from 'react-redux';
import { linkAccountRequest } from './linkAccount.action';
import { isAuthenticatedSelector } from '../../authentication/authentication.selector';
import { proxyProviderAvailableSelector } from '../../ambassador/proxy/proxy.selector';

const definedMessages = defineMessages({
  UNLINK_ACCOUNT_BUTTON_LABEL: {
    id: 'UnLinkAccount.Button.Label',
    defaultMessage: 'Unlink Account',
  },
});

export const UnlinkAccountButton: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const providerAvailable = useSelector(proxyProviderAvailableSelector);
  const dispatch = useDispatch();

  return (
    <Button
      disabled={!isAuthenticated || !providerAvailable}
      variant="secondary"
      onClick={() => dispatch(linkAccountRequest())}
      fullwidth
    >
      <FormattedMessage {...definedMessages.UNLINK_ACCOUNT_BUTTON_LABEL} />
    </Button>
  );
};
