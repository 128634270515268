import { realtimeMiddleware } from '@jive/realtime-events';
import { Middleware } from 'redux';
import reduxLogger from 'redux-logger';
import thunk from 'redux-thunk';
import './ambassador/ambassador.listener';
import './ambassador/proxy/proxy.listener';
import { getActiveRoute } from './browserHistory';
import './clio/clio.listener';
import './clio/communication/communication.listener';
import './connectivity/connectivity.listener';
import { ApplicationRoute } from './constants';
import './embedded/embedded.listener';
import './listeners/callHandler.listener';
import './listeners/createEntitySuccess.listener';
import './listeners/desktopNotification.listener';
import { analyticsMiddleware } from './middlewares/analytics.middleware';
import listenerMiddleware from './middlewares/listener.middleware';
import { inAppNotificationMiddleware } from './middlewares/inAppNotification.middleware';
import './notes/notes.listeners';
import './connectwise/connectwise.listener';
import './redtail/redtail.listener';
import './phone/phone.listeners';
import './search/entitySelected.listener';
import './settings/link-account/linkAccount.listener';
import './inAppNotification/message.listeners';
import './zendesk/tickets/tickets.listeners';
import './zendesk/zendesk.listener';
import './salesforce/salesforce.listener';
import './presence/presence.listener';
import './cox/cox.listener';
import './settings/realtimeService.listeners';
import './dialog/dialog.listener';
import './dynamics/dynamics.listener';

import { Environment } from './config';
import { callHistoryMetadataPersistMiddleware } from './callHistoryPush/callHistoryMetadataPersist.middleware';
import { settingsPersistenceMiddleware } from './settings/settings.persistence.service';

// JIF-2620 - With integrations which are embedded during the authentication process
// we open a new window which redirects back to the application after a successful login.
// But we close that window and in that case the request for user identity aborts causing an  message to display.
const isAllListenersNeeded = (): boolean => {
  const route = getActiveRoute().route;

  // On Login/Logout paths no need to load the whole app
  if ([ApplicationRoute.LOGIN, ApplicationRoute.LOGOUT].includes(route)) {
    return false;
  }

  return (
    ![ApplicationRoute.LOGIN_SUCCESS, ApplicationRoute.LOGOUT_SUCCESS].includes(route) ||
    (!window.opener && !window.frameElement)
  );
};

export const configureMiddlewares = (): Middleware[] => {
  let middlewares: Middleware[] = [];

  if (isAllListenersNeeded()) {
    middlewares = middlewares.concat([
      listenerMiddleware,
      inAppNotificationMiddleware,
      thunk,
      realtimeMiddleware,
      callHistoryMetadataPersistMiddleware,
      settingsPersistenceMiddleware,
      analyticsMiddleware,
    ]);
  }

  if (window.environment !== Environment.PROD) {
    // based on https://www.npmjs.com/package/redux-logger
    // this need to be added last
    middlewares.push(reduxLogger);
  }

  return middlewares;
};
