import { combineReducers } from 'redux';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';
import { MicrosoftTeamsHostClientType, SdkActionsType } from './constants';
import { SdkActions } from './sdk.actions';

export interface SdkState {
  fontSize: number;
  ready: boolean;
  userObjectId?: string;
  currentDevice: MicrosoftTeamsHostClientType;
  tenantId?: string;
  // The locale received from the ms-teams context is the locale of the ms-teams client.
  // We will use this locale until one is explicitly saved in our ms-teams preferences.
  // Therefore, the actual locale used may differ from the locale of the ms-teams client.
  locale: string;
}

export const initialState: SdkState = {
  fontSize: 16,
  ready: false,
  userObjectId: undefined,
  currentDevice: MicrosoftTeamsHostClientType.Web,
  tenantId: undefined,
  locale: 'en-us',
};

const sdkReducer = (state: SdkState = initialState, action: SdkActions): SdkState => {
  switch (action.type) {
    case SdkActionsType.SET_SDK_STATE:
      return {
        ...state,
        userObjectId: action.payload.userObjectId,
        tenantId: action.payload.tid,
        currentDevice: (action.payload.hostClientType as string) as MicrosoftTeamsHostClientType,
        locale: action.payload.locale,
      };
    case SdkActionsType.SET_FONT_SIZE:
      return {
        ...state,
        fontSize: action.payload,
      };
    case SdkActionsType.INIT_REQUEST: {
      return {
        ...state,
        ready: false,
      };
    }
    case SdkActionsType.INIT_SUCCESS:
      return {
        ...state,
        ready: true,
      };
    default:
      return state;
  }
};

const sdk = combineReducers({
  isLoading: loadingReducer(SdkActionsType.INIT_REQUEST),
  hasError: errorReducer(SdkActionsType.INIT_REQUEST),
  result: sdkReducer,
});

export default sdk;
