import React from 'react';
import { Store } from 'redux';
import { initializeActionFacade } from './actionFacade/action.facade.factory';
import { Integrations } from './models';
import { AppState } from './reducers';

export interface ActionFacadeProviderProps {
  store: Store<AppState>;
  integration: Integrations;
}

export const ActionFacadeProvider: React.FC<ActionFacadeProviderProps> = ({ children, store, integration }) => {
  if (integration !== Integrations.MsTeams) {
    initializeActionFacade(store, integration);
  }

  return <>{children}</>;
};
