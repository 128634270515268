import React from 'react';
import MuiIcon, { IconProps } from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

const Icon = withStyles({
  root: {
    transform: 'scale(0.85)',
    width: 24,
    height: 24,
    fontSize: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})(MuiIcon);

export const SmallIcon: React.FC<IconProps> = ({ children, ...props }) => <Icon {...props}>{children}</Icon>;
