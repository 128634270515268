import { CallEventsActionsType } from '@jive/realtime-events';
import { addListener, addListeners } from '../middlewares/listener.middleware';
import { EntitiesActionsType } from '../search/entities.action';
import { TicketsActionsType } from '../zendesk/tickets/tickets.action';
import { closeIntegrationPanel, openIntegrationPanel } from './embedded.action';
import { addConversationStartListener } from '../calls/callListeners';
import { embeddedBroadcastChannel } from './embedded.broadcastChannel';

addListeners([CallEventsActionsType.START_INCOMING_CALL, CallEventsActionsType.START_OUTGOING_CALL])(() => {
  if (document.visibilityState === 'visible') {
    return openIntegrationPanel({ reason: 'Call' });
  }
});

addConversationStartListener((_, action) => {
  const { call } = action.payload;

  // do not open the panel if this isn't the first event we have from this call
  // the user may've closed the panel manually
  if (call.previousStates.length) {
    return;
  }
  if (document.visibilityState === 'visible') {
    return openIntegrationPanel({ reason: 'Call' });
  }
});

addListener(openIntegrationPanel.type)((_, { payload: { call } }) => {
  embeddedBroadcastChannel.integrationPanelShownForCall(call);
});

// TODO: Make a generic Create entity success response for both integrations
addListeners([EntitiesActionsType.OPEN_ENTITY_SUCCESS, TicketsActionsType.OPEN_TICKET_SUCCESS])(() => {
  return closeIntegrationPanel();
});
