import { useLayoutEffect, useState } from 'react';

export function useNarrowIntegrationDetector() {
  const [isNarrowIntegration, setIsNarrowIntegration] = useState(window.innerWidth < 340);
  useLayoutEffect(() => {
    function updateValue() {
      setIsNarrowIntegration(window.innerWidth < 340);
    }
    window.addEventListener('resize', updateValue, true);
    updateValue();
    return () => window.removeEventListener('resize', updateValue, true);
  }, []);
  return isNarrowIntegration;
}
