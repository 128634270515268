import { Badge, RadioGroup } from '@getgo/chameleon-web/react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TrackedRadioButton } from '../analytics-new/TrackedRadioButton.component';
import { getActiveRoute } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { closeDialog, openDialog } from '../dialog/dialog.actions';
import { DialogBodyNames } from '../dialog/DialogBodies';
import { StepReadySection } from '../onboarding/StepReadySection.component';
import { TestCall } from '../onboarding/testCall/TestCall.component';
import { hasTestCallSucceededSelector } from '../onboarding/testCall/testCall.selectors';
import { setSoftphoneEnabled } from '../settings/settings.action';
import { isSoftphoneEnabledSelector } from '../settings/settings.selector';
import { Typography } from '../theme/Typography.component';
import { isPermissionNotificationDismissed, isNotMicrophonePermissionDenied } from './microphonePermission.service';

const definedMessages = defineMessages({
  SOFTPHONE_LABEL: {
    id: 'SoftphoneSettings.Softphone.Label',
    defaultMessage: 'Built-in softphone',
  },
  SOFTPHONE_DESCRIPTION: {
    id: 'SoftphoneSettings.Softphone.Description',
    defaultMessage: 'Make and receive calls with GoTo Integration without running the main GoTo app.',
  },
  CLICK_TO_CALL_LABEL: {
    id: 'SoftphoneSettings.ClickToCall.Label',
    defaultMessage: 'Click-to-call',
  },
  CLICK_TO_CALL_DESCRIPTION: {
    id: 'SoftphoneSettings.ClickToCall.Description',
    defaultMessage:
      'To make and receive calls you need to run GoTo on your browser, mobile device, desktop, or VoIP phone.',
  },
  NEW_BADGE: {
    id: 'Badge.New',
    defaultMessage: 'New',
  },
  TEST_CALL_SUCCESS: {
    id: 'Onboarding.TestCall.SuccessMessage',
    defaultMessage: 'Testing call was successful.',
  },
  READ_MORE: {
    id: 'SoftphoneSettings.Softphone.ReadMore',
    defaultMessage: 'Read more',
  },
  READ_MORE_BODY: {
    id: 'SoftphoneSettings.Softphone.ReadMoreModal.Body',
    defaultMessage:
      "There is a new and simpler way to make and receive calls with Goto Integration. You don't need to run GoTo on your browser, mobile device, desktop, or VoIP phone. Every incoming and outgoing call will be managed by the integration",
  },
  READ_MORE_BUTTON: {
    id: 'SoftphoneSettings.Softphone.ReadMoreModal.Button',
    defaultMessage: 'Thanks, got it',
  },
});

type CallSettingsValue = 'softphone' | 'click-to-call';

const trackingEvents = defineTrackingEvents({
  SOFTPHONE_SWITCHED_ON: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemChanged,
    label: 'Use softphone | toggle | ON',
  },
  SOFTPHONE_SWITCHED_OFF: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemChanged,
    label: 'Use softphone | toggle | OFF',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioLabelContainer: {
      flex: 1,
    },
    radioButton: {
      '&::part(control)': {
        alignSelf: 'flex-start',
        marginTop: '2px',
      },
    },
    description: {
      marginTop: theme.spacing(1),
    },
    newFeatureBadge: {
      marginLeft: theme.spacing(2),
    },
  }),
);

interface SoftphoneSettingsProps {
  showBannerOnTestCallSuccess?: boolean;
  startSoftPhoneWhenEnabled?: boolean;
}

export const SoftphoneSettings: FC<SoftphoneSettingsProps> = ({
  showBannerOnTestCallSuccess,
  startSoftPhoneWhenEnabled = true,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOnOnboardingPage = getActiveRoute()?.route === ApplicationRoute.ONBOARDING;
  const isSoftphoneEnabled = useSelector(isSoftphoneEnabledSelector);
  const hasTestCallSucceeded = useSelector(hasTestCallSucceededSelector);
  const isMicrophonePermissionNotificationDismissed = isPermissionNotificationDismissed();
  const [isMicrophoneEnabled, setMicrophoneEnabled] = useState<boolean | undefined>(undefined);

  const queryPermissions = useCallback(async () => {
    const hasMicrophone = await isNotMicrophonePermissionDenied();
    setMicrophoneEnabled(hasMicrophone);
  }, []);

  useEffect(() => {
    if (!isOnOnboardingPage || isSoftphoneEnabled) {
      void queryPermissions();
    }
  }, [isSoftphoneEnabled, isOnOnboardingPage]);

  const onSoftphoneSettingsChange = async (value: CallSettingsValue): Promise<void> => {
    const isSoftphoneChecked = value === 'softphone';
    if (isSoftphoneChecked && startSoftPhoneWhenEnabled) {
      const hasMicrophonePermission = await isNotMicrophonePermissionDenied();
      if (hasMicrophonePermission) {
        await getActionFacade().startSoftphone();
      }
    } else {
      getActionFacade().stopSoftphone();
    }
    dispatch(setSoftphoneEnabled(isSoftphoneChecked));
  };

  const openReadMoreDialog = () => {
    dispatch(
      openDialog({
        texts: {
          title: definedMessages.SOFTPHONE_LABEL,
          confirm: definedMessages.READ_MORE_BUTTON,
        },
        confirmAction: closeDialog({}),
        bodyName: DialogBodyNames.SoftphoneSettingsReadMore,
        hideCancelButton: true,
        badge: {
          variant: 'success',
          content: definedMessages.NEW_BADGE,
        },
        cancellable: false,
      }),
    );
  };

  return (
    <Box>
      <RadioGroup
        value={isSoftphoneEnabled ? 'softphone' : 'click-to-call'}
        onChange={(e) => onSoftphoneSettingsChange(e.currentTarget.value as CallSettingsValue)}
      >
        <TrackedRadioButton
          className={classes.radioButton}
          disabled={!isOnOnboardingPage && !isMicrophoneEnabled && isMicrophonePermissionNotificationDismissed}
          onTrackingEvent={trackingEvents.SOFTPHONE_SWITCHED_ON}
          value="softphone"
        >
          <Box className={classes.radioLabelContainer}>
            <Box>
              <Typography variant="body-small">
                <b>
                  <FormattedMessage {...definedMessages.SOFTPHONE_LABEL} />
                </b>
                <Badge variant="success" className={classes.newFeatureBadge}>
                  <FormattedMessage {...definedMessages.NEW_BADGE} />
                </Badge>
              </Typography>
            </Box>
            {isSoftphoneEnabled && (
              <Box className={classes.description}>
                <Typography variant="body-small">
                  <FormattedMessage {...definedMessages.SOFTPHONE_DESCRIPTION} />{' '}
                  <Typography variant="body-small-semibold" tag="span" onClick={() => openReadMoreDialog()}>
                    <FormattedMessage {...definedMessages.READ_MORE} />
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </TrackedRadioButton>

        <TrackedRadioButton
          className={classes.radioButton}
          value="click-to-call"
          onTrackingEvent={trackingEvents.SOFTPHONE_SWITCHED_OFF}
        >
          <Box className={classes.radioLabelContainer}>
            <Typography variant="body-small">
              <b>
                <FormattedMessage {...definedMessages.CLICK_TO_CALL_LABEL} />
              </b>
            </Typography>

            {!isSoftphoneEnabled && (
              <Box className={classes.description}>
                <Typography variant="body-small">
                  <FormattedMessage {...definedMessages.CLICK_TO_CALL_DESCRIPTION} />
                </Typography>
                <Box mt={2}>
                  {hasTestCallSucceeded && !showBannerOnTestCallSuccess ? (
                    <StepReadySection ready={true}>
                      <Typography variant="body-small">
                        <FormattedMessage {...definedMessages.TEST_CALL_SUCCESS} />
                      </Typography>
                    </StepReadySection>
                  ) : (
                    <TestCall showBannerOnSuccess={showBannerOnTestCallSuccess} />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </TrackedRadioButton>
      </RadioGroup>
    </Box>
  );
};
