export const getTimeZoneString = () => {
  let timeZone = new Date().toLocaleDateString(undefined, { timeZoneName: 'short' });
  timeZone = timeZone.substr(timeZone.indexOf(',') + 1); // trim the date, it should remain as GMT+X, UTC+X
  let timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone || '';

  if (timeZoneName && timeZoneName !== '') {
    timeZoneName = `(${timeZoneName})`;
  }

  return ` ${timeZone} ${timeZoneName}`.trimRight();
};
