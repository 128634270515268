import { getRouteParams } from '../browserHistory';
import { RouteSearchParams } from '../constants';
import { call } from '../phone/phone.actions';
import { logger } from '../logging/logger';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { SalesforceHostEventData } from '../../../salesforce-shared/salesforce-host-typings';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { logoutSuccess } from '../authentication/authentication.actions';
import { SalesforceActionFacadeBase } from './salesforce.actionfacade-base';

export const salesforceLightningEventListener = (store: Store<AppState>) => {
  const eventListener = (message: { data: SalesforceHostEventData; origin: string }) => {
    const salesforceDomain = getRouteParams(RouteSearchParams.SALESFORCE_DOMAIN);

    if (!message?.data?.type) {
      return;
    }

    // verify if the message come from salesforce
    if (message.origin !== salesforceDomain) {
      return;
    }

    switch (message.data.type) {
      case 'clicktocall/start': {
        const { phoneNumber, contact } = message.data.payload;

        // TODO: quick fix
        if (contact && contact.doNotCall === 'false') {
          contact.doNotCall = false;
        }

        newTracker.trackAnalyticsEvent({
          category: AnalyticsCategory.Application,
          action: AnalyticsAction.ContactMatchPulled,
          label: 'click to call',
        });

        store.dispatch(call({ phoneNumber, contact }));
        break;
      }
      case 'errorlog/track': {
        const { message: errorMessage, result, error } = message.data.payload;

        let resultLogs = {};
        if (result) {
          // flatten result keys for splunk
          resultLogs = Object.keys(result).reduce((a, field) => {
            a['result_' + field] = result[field];
            return a;
          }, {});
        }

        logger.error(errorMessage, {
          ...resultLogs,
          error: JSON.stringify(error),
        });

        break;
      }
      case 'messageChannel/message': {
        if (message.data.payload?.action === 'login') {
          void getActionFacade<SalesforceActionFacadeBase>().loadAuthToken();
        }
        if (message.data.payload?.action === 'logout') {
          store.dispatch(logoutSuccess());
        }

        break;
      }
    }
  };

  window.addEventListener('message', eventListener as any, false);
};
