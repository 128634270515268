export interface Customer {
  id: string;
  vehicles: string[];
  assignedSalesPerson?: string;
  preferredPhone?: string;
  cellPhone?: string;
  businessPhone?: string;
  phoneNumber?: string;
  otherPhone?: string;
  pagePhone?: string;
}

export enum LoadCustomerRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}
