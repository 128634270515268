import { Matter } from '../clio.models';

export enum GetMattersRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface MattersState {
  requestState: GetMattersRequestState;
  matters: Matter[];
}
