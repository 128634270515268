import { Button, ButtonProps } from '@getgo/chameleon-material-ui';
import React, { FC } from 'react';

export interface TrackedButtonStateProps {
  trackEvent(eventLabel: string, eventName: string);
}

export interface TrackedButtonOwnProps extends ButtonProps {
  eventLabel: string;
  eventName: string;
}

export const TrackedButtonComponent: FC<TrackedButtonStateProps & TrackedButtonOwnProps> = ({
  children,
  trackEvent,
  eventLabel,
  eventName,
  onClick,
  ...buttonProps
}) => {
  function trackEventOnClickAndCallHandler(event) {
    trackEvent(eventLabel, eventName);
    if (onClick) {
      onClick(event);
    }
  }

  return (
    <Button {...buttonProps} onClick={trackEventOnClickAndCallHandler}>
      {children}
    </Button>
  );
};
