export interface SentryTag {
  key: string;
  value: string;
}

export interface SentryMetadata {
  userId: string;
  tags: SentryTag[];
}

export enum LogLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Verbose = 'verbose',
  Debug = 'debug',
  Silly = 'silly',
}
