import React from 'react';
import { CallActionsBox } from './CallActionsBox.component';
import NewEntityAction from '../entityActions/NewEntityAction.container';
import { goToCallActionRoute } from '../browserHistory';
import { CallActionRoutes, EntityType } from '../constants';
import { CallType, CallStates } from '@jive/realtime-events';
import { Integrations } from '../models';
import { CallWithContact } from './calls.reducer';
import {
  AddPersonActiveIcon,
  ClockInactiveIcon,
  BulbInactiveIcon,
  HelpdeskIcon,
  LinkActiveIcon,
} from '@getgo/chameleon-icons/react';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';

export interface CallActionsComponentProps {
  integration: Integrations;
  call: CallWithContact;
}

const trackingEvents = defineTrackingEvents({
  CREATE_CONTACT_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Create contact | button',
  },
  NEW_ACCOUNT_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New account | button',
  },
  NEW_LEAD_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New lead | button',
  },
  CALL_LOG_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Call log | button',
  },
  NEW_COMMUNICATION_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New communication | button',
  },
  NEW_SERVICE_TICKET_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New service ticket | button',
  },
  NEW_ACTIVITY_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New activity | button',
  },
  NEW_OPPORTUNITY_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'New opportunity | button',
  },
});

export const CallActionsComponent: React.FC<CallActionsComponentProps> = ({ integration, call }) => {
  const getActions = (): JSX.Element[] => {
    const getCreateContactAction = () => (
      <NewEntityAction
        actionOverride={() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])}
        key={'openContactView'}
        icon={<AddPersonActiveIcon />}
        entityType={EntityType.CONTACT}
        actionClass={'openContactView'}
        trackingEvent={trackingEvents.CREATE_CONTACT_CLICKED}
      />
    );

    const getClioActions = () => {
      const actions = [getCreateContactAction()];

      // Clio communication can be created from non missed calls
      const isNotAMissedCall =
        (call.endTime && call.type !== CallType.MissedCall && call.type !== CallType.DeclinedCall) ||
        call.state === CallStates.Conversation;
      if (isNotAMissedCall) {
        actions.push(
          <NewEntityAction
            actionOverride={() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.COMMUNICATION])}
            key={'openNewCommunicationView'}
            icon={<LinkActiveIcon />}
            entityType={EntityType.COMMUNICATION}
            actionClass={'openNewCommunicationView'}
            trackingEvent={trackingEvents.NEW_COMMUNICATION_CLICKED}
          />,
        );
      }

      return actions;
    };

    const getRedtailActions = () => {
      return [
        <NewEntityAction
          actionOverride={() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])}
          key={'createContact'}
          icon={<AddPersonActiveIcon />}
          entityType={EntityType.CONTACT}
          actionClass={'createContact'}
          trackingEvent={trackingEvents.CREATE_CONTACT_CLICKED}
        />,
        <NewEntityAction
          renderOnlyIfContactAssigned
          actionOverride={() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.REDTAIL_ACTIVITY])}
          key={'createRedtailActivity'}
          icon={<ClockInactiveIcon />}
          entityType={EntityType.REDTAIL_ACTIVITY}
          actionClass={'createRedtailActivity'}
          trackingEvent={trackingEvents.NEW_ACTIVITY_CLICKED}
        />,
      ];
    };

    const getConnectWiseActions = () => {
      const actions = [getCreateContactAction()];
      const crmActions = [
        <NewEntityAction
          actionOverride={() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONNECTWISE_SERVICE_TICKET])}
          key={'createConnectWiseServiceTicket'}
          icon={<HelpdeskIcon />}
          entityType={EntityType.CONNECTWISE_SERVICE_TICKET}
          actionClass={'createConnectWiseServiceTicket'}
          trackingEvent={trackingEvents.NEW_SERVICE_TICKET_CLICKED}
          renderOnlyIfContactAssigned
        />,
        <NewEntityAction
          key={'createConnectWiseActivity'}
          icon={<ClockInactiveIcon />}
          entityType={EntityType.CONNECTWISE_ACTIVITY}
          actionClass={'createConnectWiseActivity'}
          trackingEvent={trackingEvents.NEW_ACTIVITY_CLICKED}
        />,
        <NewEntityAction
          key={'createConnectWiseOpportunity'}
          icon={<BulbInactiveIcon />}
          entityType={EntityType.CONNECTWISE_OPPORTUNITY}
          actionClass={'createConnectWiseOpportunity'}
          trackingEvent={trackingEvents.NEW_OPPORTUNITY_CLICKED}
        />,
      ];

      return [...actions, ...crmActions];
    };

    switch (integration) {
      case Integrations.Clio:
        return getClioActions();
      case Integrations.Redtail:
        return getRedtailActions();
      case Integrations.ConnectWise:
        return getConnectWiseActions();
      default:
        return [];
    }
  };

  const actions = getActions();

  if (actions.length === 0) {
    return null;
  }

  return <CallActionsBox actions={actions} />;
};
