import { logger } from '../../logging';
import { Entity } from '../../models/index';
import { formatPhoneNumberToDigitsOnlyFormats } from '../../phone/phone-utils';
import { RedtailCrmUri } from '../../redtail/redtail.model';
import { redtailPhoneNumberTypeMapper } from '../../redtail/redtail.phoneNumberTypeMapper';
import { redtailService } from '../../redtail/redtail.service';
import { EntityPhoneNumber } from '../../search/entities.model';
import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';

export class RedtailContactActionCreator extends ContactActionCreator {
  protected formatPhoneNumberToPossibleMatches(phoneNumberToFormat: string) {
    return [
      ...new Set([
        phoneNumberToFormat, // let's try the original phone number too
        ...formatPhoneNumberToDigitsOnlyFormats(phoneNumberToFormat),
      ]),
    ];
  }

  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const phoneNumbers = await redtailService.getPhonesOfContact(contactId);
    return phoneNumbers.map((redtailPhoneNumber) => ({
      entityId: contactId,
      type: redtailPhoneNumberTypeMapper(redtailPhoneNumber.Type),
      phoneNumber: redtailPhoneNumber.Number,
    }));
  }

  protected _openCrmContactPage(contactId: string): Promise<void> {
    const urlToOpen = RedtailCrmUri.CONTACT(contactId);

    window.open(urlToOpen, '_blank', 'openInDefaultBrowser');

    return Promise.resolve();
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this._openCrmContactPage(contactId)
      .then(() => true)
      .catch(() => false);
  }

  protected async _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]> {
    const contacts = await redtailService.searchContactsByNumber(phoneNumber);

    if (contacts.length) {
      const results = await Promise.all(
        contacts.map(async (contact) => {
          try {
            const phones = await redtailService.getPhonesOfContact(contact.id);
            return {
              ...contact,
              phoneNumber: phones[0]?.Number,
              phoneNumbers: phones,
            };
          } catch (error) {
            logger.error('Error downloading phone of contact in redtail', error);
          }

          return contact;
        }),
      );

      if (results.length !== 0 && results.filter((r) => r.phoneNumber).length === 0) {
        throw Error('Could not get phones of contact in redtail');
      }

      return results;
    }

    return [];
  }

  protected _searchContactByName(query: string): Promise<Entity[]> {
    return redtailService.searchContactsByName(query);
  }

  protected _canUpdateContactPhoneNumber(): Promise<boolean> {
    return Promise.resolve(true);
  }

  protected async _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    await redtailService.updateContactPhoneNumber(contactId, phoneNumber);
  }

  protected async _createContact(contactData: ContactCreateModel): Promise<string> {
    const newContactId = await redtailService.createContact(contactData);

    if (!newContactId) {
      throw Error('No new contact id returned on contact creation');
    }

    await redtailService.updateContactPhoneNumber(newContactId, contactData.phoneNumber);

    return newContactId;
  }
}
