import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { Matter } from '../clio.models';
import { GetMattersRequestState, MattersState } from './matter.models';

const mattersSelector = (state: AppState): MattersState => state.clio.matters;

export const matterListSelector: (state: AppState) => Matter[] = createSelector(
  mattersSelector,
  (result) => result.matters,
);

export const getMattersRequestStateSelector: (state: AppState) => GetMattersRequestState = createSelector(
  mattersSelector,
  (result) => result.requestState,
);
