import { getActiveRoute } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { logger } from '../logging';
import { PresenceAppearance } from './presence.models';

const DISMISS_POPUP_TIMESPAN = 3600000; // 1 hour
const STORAGE_KEY = 'presence-offline-state-dismissed-at';

export const shouldDisplayPresenceOfflinePopup = () => {
  let dismissedAt: string | null;
  try {
    dismissedAt = localStorage.getItem(STORAGE_KEY);
  } catch (error) {
    logger.warn('Failed to load presence popup dismissal time', error);
    return true;
  }
  if (!dismissedAt) {
    return true;
  }

  const isDismissedRecently = Date.now() - Number(dismissedAt) < DISMISS_POPUP_TIMESPAN;
  if (isDismissedRecently) {
    return false;
  }

  return getActiveRoute(undefined, false)?.route !== ApplicationRoute.ONBOARDING;
};

export const dismissPresenceOfflinePopup = () => {
  try {
    localStorage.setItem(STORAGE_KEY, String(Date.now()));
  } catch (error) {
    logger.warn('Failed to store presence popup dismissal time', error);
  }
};

export const clearPresenceOfflinePopupDismissal = () => {
  try {
    localStorage.removeItem(STORAGE_KEY);
  } catch (error) {
    logger.warn('Failed to clear presence popup dismissal time', error);
  }
};

export const isOffline = (appearance: PresenceAppearance) => {
  return ['UNAVAILABLE', 'OFFLINE'].includes(appearance);
};
