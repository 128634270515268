import { combineReducers } from 'redux';
import { NoteActions, NoteActionsType } from './notes.actions';
import { GetMoreNotesRequestState, GetNotesRequestState, GetNotesState } from './notes.models';

export const INITIAL_STATE: GetNotesState = {
  requestState: GetNotesRequestState.INITIAL,
  moreRequestState: GetMoreNotesRequestState.INITIAL,
  notes: [],
  notesCount: undefined,
};

export const notesListReducer = (state: GetNotesState = INITIAL_STATE, action: NoteActions): GetNotesState => {
  switch (action.type) {
    case NoteActionsType.RESET:
      return {
        ...INITIAL_STATE,
      };

    case NoteActionsType.GET_NOTES:
      return {
        ...state,
        moreRequestState: GetMoreNotesRequestState.INITIAL,
        requestState: GetNotesRequestState.LOADING,
      };

    case NoteActionsType.GET_NOTES_SUCCESS:
      return {
        ...state,
        moreRequestState: GetMoreNotesRequestState.INITIAL,
        requestState: GetNotesRequestState.LOADED,
        requestMeta: action.payload.meta,
        notes: action.payload.notes,
        notesCount: action.payload?.meta?.notesCount,
      };

    case NoteActionsType.GET_NOTES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreNotesRequestState.INITIAL,
        requestState: GetNotesRequestState.FAILED,
      };

    case NoteActionsType.GET_MORE_NOTES:
      return {
        ...state,
        moreRequestState: GetMoreNotesRequestState.LOADING,
      };

    case NoteActionsType.GET_MORE_NOTES_SUCCESS:
      return {
        ...state,
        requestMeta: action.payload.meta,
        notes: [...state.notes, ...action.payload.notes],
        moreRequestState: GetMoreNotesRequestState.LOADED,
      };

    case NoteActionsType.GET_MORE_NOTES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreNotesRequestState.FAILED,
      };

    case NoteActionsType.LOAD_NOTES_COUNT:
      return {
        ...state,
        notesCount: undefined,
      };
    case NoteActionsType.LOAD_NOTES_COUNT_SUCCESS:
      return {
        ...state,
        notesCount: action.payload.notesCount,
      };
    case NoteActionsType.LOAD_NOTES_COUNT_ERROR:
      return {
        ...state,
        notesCount: undefined,
      };

    default:
      return state;
  }
};

export default combineReducers({
  notesList: notesListReducer,
});
