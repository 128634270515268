import { CreateNewOpportunityModel } from '../dynamics.model';
import { DynamicsService } from '../dynamics.service';
import { createEntityError, createEntitySuccess } from '../../search/entities.action';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { goToCallPage } from '../../browserHistory';
import { EntityType } from '../../constants';
import { Store } from 'redux';
import { AppState } from '../../reducers';

export const definedMessages = defineMessages({
  CREATE_OPPORTUNITY_ERROR: {
    id: 'Create.Opportunity.Error',
    defaultMessage: 'There was an error while creating the opportunity, please try again.',
    description: 'error message lead create',
  },
  CREATE_OPPORTUNITY_SUCCESS: {
    id: 'Create.Opportunity.Success',
    defaultMessage: 'Opportunity created successfully!',
    description: 'success message opportunity create',
  },
});

export class DynamicsOpportunityActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async createOpportunity({ topic, contactId, callId }: CreateNewOpportunityModel): Promise<void> {
    try {
      const opportunityId = await DynamicsService.createOpportunity(topic, contactId);

      this.store.dispatch(
        createEntitySuccess({
          message: definedMessages.CREATE_OPPORTUNITY_SUCCESS,
          callId,
          entity: {
            id: opportunityId,
            label: 'Opportunity',
            name: 'Opportunity',
            type: EntityType.OPPORTUNITY,
          },
        }),
      );
    } catch (error) {
      logger.error('Error during opportunity creation', error);

      this.store.dispatch(
        createEntityError({
          message: definedMessages.CREATE_OPPORTUNITY_ERROR,
        }),
      );
    }

    goToCallPage(callId);
  }
}
