import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AppAction } from '../actions/actionCreators';
import { DialogBadge, DialogStyles, DialogTexts } from './dialog.reducer';
import { DialogBody, DialogBodyNames } from './DialogBodies';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { Typography } from '../theme/Typography.component';
import { IconButton } from '@getgo/chameleon-material-ui';
import { Button } from '@getgo/chameleon-web/react';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { Badge } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  CANCEL_BUTTON_TEXT: {
    id: 'Form.General.CancelButtonText',
    defaultMessage: 'Cancel',
  },
});

const useStyles = makeStyles(() => ({
  dialog: (props: { isNarrowIntegration: boolean }) => ({
    padding: '24px 20px 20px 20px',
    margin: props.isNarrowIntegration ? '0 5px' : '0 20px',
    position: 'relative',
    overflow: 'initial',
  }),
  footer: {
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    justifyContent: 'space-between',
    margin: '0 -2px',
  },
  button: (props: { isNarrowIntegration: boolean }) => {
    const styles: CreateCSSProperties = { flexGrow: 1 };
    if (props.isNarrowIntegration) {
      styles.height = 'auto';
    }
    return styles;
  },
  closingButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
}));

export interface DialogStateProps {
  isOpen: boolean;
  confirmActionToDispatch?: AppAction;
  body?: JSX.Element;
  bodyName?: DialogBodyNames;
  texts?: DialogTexts;
  cancellable?: boolean;
  cancelTrackingEvent?: TrackingEvent;
  confirmTrackingEvent?: TrackingEvent;
  hideCancelButton?: boolean;
  isNarrowIntegration: boolean;
  badge?: DialogBadge;
  closeCallback?: (params: { isConfirmed: boolean }) => void;
  dialogStyles?: DialogStyles;
}

export interface DialogDispatchProps {
  confirmAction: (action?: AppAction, trackingEvent?: TrackingEvent) => void;
  cancelAction: (trackingEvent?: TrackingEvent) => void;
}

export const DialogComponent: React.FC<DialogStateProps & DialogDispatchProps> = ({
  isOpen,
  confirmAction,
  confirmActionToDispatch,
  cancelAction,
  texts,
  body,
  bodyName,
  cancellable,
  cancelTrackingEvent,
  confirmTrackingEvent,
  hideCancelButton,
  closeCallback,
  isNarrowIntegration,
  dialogStyles,
  badge,
}) => {
  const styles = useStyles({ isNarrowIntegration });

  if (isOpen) {
    if (!body && !bodyName && (!texts || !texts.body)) {
      throw Error('Dialog is open without any text or body provided');
    }
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        className: styles.dialog,
      }}
    >
      {cancellable ? (
        <IconButton
          variant="secondary"
          size="xsmall"
          className={styles.closingButton}
          onClick={() => {
            if (closeCallback) {
              closeCallback({ isConfirmed: false });
            }
            cancelAction(cancelTrackingEvent);
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Box mb={2}>
        <Box display="flex" gridGap={8}>
          <Typography variant="heading-xsmall">{texts ? <FormattedMessage {...texts.title} /> : null}</Typography>
          {badge && (
            <Badge variant={badge.variant}>
              <FormattedMessage {...badge.content} />
            </Badge>
          )}
        </Box>
      </Box>

      <Typography>
        {body ? body : texts && texts.body ? <FormattedMessage {...texts.body} values={{ lineBreak: <br /> }} /> : null}
        {bodyName ? <DialogBody bodyName={bodyName} /> : null}
      </Typography>

      <div className={styles.footer}>
        {confirmActionToDispatch || closeCallback ? (
          <Button
            className={styles.button}
            onClick={() => {
              if (closeCallback) {
                closeCallback({ isConfirmed: true });
              }
              confirmAction(confirmActionToDispatch, confirmTrackingEvent);
            }}
            fullwidth
            variant={dialogStyles?.confirmButtonVariant ?? 'primary'}
            size="small"
            autoFocus
          >
            {texts && texts.confirm ? <FormattedMessage {...texts.confirm} /> : null}
          </Button>
        ) : null}
        {cancellable && hideCancelButton !== true ? (
          <Button
            className={styles.button}
            onClick={() => {
              if (closeCallback) {
                closeCallback({ isConfirmed: false });
              }
              cancelAction(cancelTrackingEvent);
            }}
            fullwidth
            variant="secondary"
            size="small"
          >
            <FormattedMessage {...(texts?.cancel || definedMessages.CANCEL_BUTTON_TEXT)} />
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};
