import React from 'react';
import { LogInactiveIcon } from '@getgo/chameleon-icons/react';
import { EntityType } from '../../constants';
import { CallActionButton, CallActionProps } from './CallActionButton.component';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { defineMessages } from 'react-intl';

const definedMessage = defineMessages({
  LOG_THIS_CALL: {
    id: 'Call.LogThisCall',
    defaultMessage: 'Log this call',
  },
});

export const LogCallButton: React.FC<Partial<CallActionProps>> = (props) => {
  return (
    <CallActionButton
      leadingIcon={<LogInactiveIcon aria-hidden />}
      variant="primary"
      label={definedMessage.LOG_THIS_CALL}
      entityType={EntityType.CALL_LOG}
      trackingEvent={{
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ItemClicked,
        label: 'Call log | button',
      }}
      {...props}
    />
  );
};
