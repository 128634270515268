import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const msTeamsSdkResultSelector = (state: AppState) => state.msteams.sdk.result;

export const fontSizeSelector = createSelector(msTeamsSdkResultSelector, (result) => result.fontSize);

export const userObjectIdSelector = createSelector(msTeamsSdkResultSelector, (result) => result.userObjectId);

export const currentDeviceSelector = createSelector(msTeamsSdkResultSelector, (result) => result.currentDevice);

export const tenantIdSelector = createSelector(msTeamsSdkResultSelector, (result) => result.tenantId);

export const readySelector = createSelector(msTeamsSdkResultSelector, (result) => result.ready);
