export const REQUEST = 'Request';
export const SUCCESS = 'Success';
export const WARNING = 'Warning';
export const ERROR = 'Error';
export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const CREATE = 'create';
export const SEARCH = 'search';
export const OPEN = 'open';

export const AUTHENTICATION = 'authentication';
export const LOGOUT_REQUEST = `${AUTHENTICATION}/${LOGOUT}`;
export const LOGOUT_SUCCESS = `${LOGOUT_REQUEST}${SUCCESS}`;
export const TOKEN_VALIDATION = `${AUTHENTICATION}/token`;

const CALL = 'call';
export const CLICK_TO_CALL = `${CALL}/latest`;

const CALL_EVENTS = `callEvents`;
export const END_CONVERSATION = `${CALL_EVENTS}/endConversation`;

export const ENTITY = 'entity';
export const CREATE_ENTITY_ERROR = `${ENTITY}/${CREATE}${ERROR}`;

export enum EntityType {
  LEAD = 'Lead',
  CONTACT = 'Contact',
  ACCOUNT = 'Account',
  CASE = 'Case',
  NOTE = 'ContentNote',
  OPPORTUNITY = 'Opportunity',
  COMMUNICATION = 'Communication',
  REDTAIL_ACTIVITY = 'RedtailActivity',
  CONNECTWISE_ACTIVITY = 'ConnectWiseActivity',
  CONNECTWISE_OPPORTUNITY = 'ConnectWiseOpportunity',
  CONNECTWISE_SERVICE_TICKET = 'ConnectWiseServiceTicket',
  CALL_LOG = 'Task',
}

export const CallActionRoutes = {
  [EntityType.COMMUNICATION]: 'communication',
  [EntityType.NOTE]: 'note',
  [EntityType.CONTACT]: 'contact',
  [EntityType.REDTAIL_ACTIVITY]: 'redtail-activity',
  [EntityType.CONNECTWISE_SERVICE_TICKET]: 'service-ticket',
  [EntityType.CALL_LOG]: 'call-log',
  [EntityType.CASE]: 'case',
  [EntityType.LEAD]: 'lead',
  [EntityType.OPPORTUNITY]: 'opportunity',
};
export type CallActionType = typeof CallActionRoutes[keyof typeof CallActionRoutes];

export enum ClickToCallActionTypes {
  CALL = 'clickToCall/call',
  CALL_REQUEST = 'clickToCall/callRequest',
  CALL_SUCCESS = 'clickToCall/callSuccess',
  CALL_ERROR = 'clickToCall/callError',
}

export enum DevicesActionsType {
  LOAD = 'settings/devices/load',
  LOAD_REQUEST = 'settings/devices/loadRequest',
  LOAD_SUCCESS = 'settings/devices/loadSuccess',
  LOAD_ERROR = 'settings/devices/loadError',
}

export enum VoicemailsActionsType {
  LOAD_REQUEST = 'voicemails/loadRequest',
  LOAD_SUCCESS = 'voicemails/loadSuccess',
  LOAD_ERROR = 'voicemails/loadError',
  TOGGLE_READ_STATE = 'voicemails/toggleReadState',
  TOGGLE_READ_STATE_REQUEST = 'voicemails/toggleReadStateRequest',
  TOGGLE_READ_STATE_SUCCESS = 'voicemails/toggleReadStateSuccess',
  TOGGLE_READ_STATE_ERROR = 'voicemails/toggleReadStateError',
  DELETE = 'voicemails/delete',
  DELETE_REQUEST = 'voicemails/deleteRequest',
  DELETE_SUCCESS = 'voicemails/deleteSuccess',
  DELETE_ERROR = 'voicemails/deleteError',
  CALL = 'voicemails/call',
  CALL_REQUEST = 'voicemails/callRequest',
  CALL_SUCCESS = 'voicemails/callSuccess',
  CALL_ERROR = 'voicemails/callError',
}

export enum VoicemailPlayerActionsType {
  OPEN = 'voicemails/player/open',
  CLOSE = 'voicemails/player/close',
  SET_VOICEMAIL_ID = 'voicemails/player/setVoicemailId',
  PLAY = 'voicemails/player/play',
  PAUSE = 'voicemails/player/pause',
  SEEK = 'voicemails/player/seek',
  UPDATE_CURRENT_TIME = 'voicemails/player/updateCurrentTime',
  UPDATE_DURATION = 'voicemails/player/updateDuration',
  ENDED = 'voicemails/player/ended',
  LOAD_FILE = 'voicemails/player/loadFile',
  LOAD_FILE_REQUEST = 'voicemails/player/loadFileRequest',
  LOAD_FILE_SUCCESS = 'voicemails/player/loadFileSuccess',
  LOAD_FILE_ERROR = 'voicemails/player/loadFileError',
  LOAD_VOICEMAIL = 'voicemails/player/loadVoicemail',
  LOAD_VOICEMAIL_REQUEST = 'voicemails/player/loadVoicemailRequest',
  LOAD_VOICEMAIL_SUCCESS = 'voicemails/player/loadVoicemailSuccess',
  LOAD_VOICEMAIL_ERROR = 'voicemails/player/loadVoicemailError',
}

export enum RealtimeServiceActionTypes {
  TRY_START_SERVICE = 'settings/realtimeService/tryStart',
}

export enum SettingsActionTypes {
  OPEN_SETTINGS_PAGE = 'settings/openPage',
  SELECT_LINE = 'settings/selectLine',
  SELECT_DEVICE = 'settings/selectDevice',
  SELECT_LANGUAGE = 'settings/selectLanguage',
  SET_AUTOMATIC_CALL_LOGGING = 'settings/automaticCallLogging/update',
  SET_OPEN_CALL_LOG_AFTER_CALL = 'settings/salesforce/openCallLogAfterCall/update',
  SET_CALL_DISPOSITION_FORM = 'settings/salesforce/callDisposition/update',
  SET_OPPORTUNITIES = 'settings/salesforce/opportunities/update',
  SET_CASES = 'settings/salesforce/cases/update',
  SET_OPEN_CONTACT_IN_NEW_TAB = 'settings/salesforce/openContactInNewTab/update',
  SET_SOFTPHONE_ENABLED = 'settings/softphone/enabled',
  SET_WIDGET_AUTO_POPUP = 'settings/salesforce/widgetAutoPopup/update',
  SET_DEALERTRACK_ENTITIES = 'settings/cox/dealertrack/entities/update',
  SET_ZENDESK_SETTINGS = 'settings/zendesk/automaticCallLogging/update',
}

// calculate value is not permit in enum to recompose CALL_ENDED route with this param
export const PARAM_CALLID = 'callId';
export const PARAM_CONVERSATION_ID = 'conversationId';
export const PARAM_CALL_ACTION_ID = 'action';

export enum ApplicationRoute {
  LOGIN = '/login',
  LOGIN_SUCCESS = '/login-success',
  LOGOUT = '/logout',
  LOGOUT_SUCCESS = '/logout-success',
  PHONE_ROUTE = '/phone',
  ACTIVE_CALLS_ROUTE = '/activeCalls',
  CALL_ROUTE_WITH_ACTION = '/call/:callId/:action',
  CALL_ROUTE = '/call/:callId',
  SETTINGS_ROUTE = '/settings',
  CONTACT_ROUTE = '/contact',
  CALL_HISTORY_ROUTE = '/callHistory',
  CONVERSATIONS_ROUTE = '/conversations',
  CONVERSATION_ROUTE = '/conversations/:conversationId',
  CONVERSATION_LOG_ROUTE = '/conversations/:conversationId/:messageId/log',
  NOTIFICATION_PERMISSION_ROUTE = '/notificationPermissions',
  UNKNOWN_ROUTE = '/unknown',
  INTEGRATION_PICKER_ROUTE = '/integrationpicker',
  THIRD_PARTY_ERROR = '/disabledThirdPartyCookies',
  ONBOARDING = '/onboarding',
  ONBOARDING_TOS = '/onboarding/termsOfService',
  ONBOARDING_LINKING = '/onboarding/linking',
  ONBOARDING_ENTERPRISE_LINKING = '/onboarding/enterpriseLinking',
  ONBOARDING_LOGIN = '/onboarding/login',
  ONBOARDING_TEST_CALL = '/onboarding/test-call',
  CHANGELOG = '/changelog',
  MICROPHONE_PERMISSION = '/microphonePermissions',
}

export enum RouteSearchParams {
  SALESFORCE_DOMAIN = 'salesforceDomain',
}

export enum InAppNotificationVariantType {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
