import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { acceptTermsOfService } from './termsOfService.action';

import {
  TermsOfServiceComponent,
  TermsOfServiceDispatchProps,
  TermsOfServiceStateProps,
} from './TermsOfService.component';
import { currentIntegrationNameSelector, isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { Dispatch } from 'redux';

const mapDispatch = (dispatch: Dispatch): TermsOfServiceDispatchProps => ({
  acceptTermsOfService: () => dispatch(acceptTermsOfService()),
});

const mapStateToProps = (state: AppState): TermsOfServiceStateProps => ({
  integrationName: currentIntegrationNameSelector(state),
  isNarrowIntegration: isNarrowIntegrationSelector(state),
});

export default connect<TermsOfServiceStateProps, TermsOfServiceDispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatch,
)(TermsOfServiceComponent);
