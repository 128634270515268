import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { CoxService } from '../cox.service';
import {
  loadOpenRepairOrders,
  loadOpenRepairOrdersError,
  loadOpenRepairOrdersSuccess,
  loadClosedRepairOrders,
  loadClosedRepairOrdersSuccess,
  loadClosedRepairOrdersError,
  loadClosedRepairOrderDetails,
  loadClosedRepairOrderDetailsSuccess,
  loadClosedRepairOrderDetailsError,
} from './repairOrders.actions';
import { getPartsFromRepairOrders } from '../parts/parts.selectors';
import { loadServiceWriters, loadServiceWritersSuccess, loadServiceWritersError } from './repairOrders.actions';

const definedMessages = defineMessages({
  REPAIR_ORDER_LIST_LOAD_ERROR: {
    id: 'Cox.RepairOrders.List.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading repair orders. Please try again later.',
  },
  SERVICE_WRITERS_LOAD_ERROR: {
    id: 'Cox.ServiceWriters.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading service writers. Please try again later.',
  },
});

const trackingEvents = defineTrackingEvents({
  OPEN_REPAIR_ORDER_LOAD_STARTED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Open repair orders',
  },
  OPEN_REPAIR_ORDER_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Open repair orders',
  },
  OPEN_REPAIR_ORDER_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Open repair orders',
  },
  CLOSED_REPAIR_ORDER_LOAD_STARTED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Closed repair orders',
  },
  CLOSED_REPAIR_ORDER_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Closed repair orders',
  },
  CLOSED_REPAIR_ORDER_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Closed repair orders',
  },
  CLOSED_REPAIR_ORDER_DETAILS_LOAD_STARTED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Closed repair order details',
  },
  CLOSED_REPAIR_ORDER_DETAILS_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Closed repair order details',
  },
  CLOSED_REPAIR_ORDER_DETAILS_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Closed repair order details',
  },
  PARTS_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Parts',
  },
  ADVISOR_LOAD: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Service writers',
  },
  ADVISOR_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Service writers',
  },
  ADVISOR_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: '-',
  },
});

export class RepairOrdersActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async loadOpenRepairOrdersForVehicles(VINs: string[]): Promise<void> {
    await Promise.allSettled(VINs.map((VIN) => this.loadRepairOrdersForVehicle(VIN)));
  }

  public async loadClosedRepairOrdersForVehicle(VIN: string): Promise<void> {
    newTracker.trackAnalyticsEvent(trackingEvents.CLOSED_REPAIR_ORDER_LOAD_STARTED);
    this.store.dispatch(loadClosedRepairOrders({ VIN }));

    try {
      const repairOrders = await CoxService.getClosedRepairOrdersByVIN(VIN);
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CLOSED_REPAIR_ORDER_LOAD_SUCCEEDED,
        label: `Closed repair orders | ${repairOrders.length}`,
      });
      this.store.dispatch(loadClosedRepairOrdersSuccess({ VIN, repairOrders }));
    } catch (error) {
      logger.error('Closed repair order loading error', error);
      newTracker.trackAnalyticsEvent(trackingEvents.CLOSED_REPAIR_ORDER_LOAD_FAILED);
      this.store.dispatch(loadClosedRepairOrdersError({ VIN, message: definedMessages.REPAIR_ORDER_LIST_LOAD_ERROR }));
    }
  }

  async loadClosedRepairOrderDetails(VIN: string, repairOrderNumber: string): Promise<void> {
    newTracker.trackAnalyticsEvent(trackingEvents.CLOSED_REPAIR_ORDER_DETAILS_LOAD_STARTED);
    this.store.dispatch(loadClosedRepairOrderDetails({ VIN, repairOrderNumber }));

    try {
      const repairOrder = await CoxService.getClosedRepairOrderDetails(repairOrderNumber);
      this.store.dispatch(loadClosedRepairOrderDetailsSuccess({ VIN, repairOrder }));
      newTracker.trackAnalyticsEvent(trackingEvents.CLOSED_REPAIR_ORDER_DETAILS_LOAD_SUCCEEDED);
    } catch (error) {
      logger.error('Repair order details loading error', error);
      newTracker.trackAnalyticsEvent(trackingEvents.CLOSED_REPAIR_ORDER_DETAILS_LOAD_FAILED);
      this.store.dispatch(
        loadClosedRepairOrderDetailsError({
          VIN,
          repairOrderNumber,
          message: definedMessages.REPAIR_ORDER_LIST_LOAD_ERROR,
        }),
      );
    }
  }

  public async loadServiceWriters(): Promise<void> {
    this.store.dispatch(loadServiceWriters());

    newTracker.trackAnalyticsEvent(trackingEvents.ADVISOR_LOAD);

    try {
      const serviceWriters = await CoxService.getServiceWriters();

      this.store.dispatch(loadServiceWritersSuccess({ serviceWriters }));
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.ADVISOR_LOAD_SUCCEEDED,
        label: `Service writers | ${serviceWriters.length}`,
      });
    } catch (e) {
      this.store.dispatch(
        loadServiceWritersError({
          message: definedMessages.SERVICE_WRITERS_LOAD_ERROR,
        }),
      );
      logger.error('Error loading service writers', e);
      newTracker.trackAnalyticsEvent(trackingEvents.ADVISOR_LOAD_FAILED);
    }
  }

  private async loadRepairOrdersForVehicle(VIN: string): Promise<void> {
    newTracker.trackAnalyticsEvent(trackingEvents.OPEN_REPAIR_ORDER_LOAD_STARTED);
    this.store.dispatch(loadOpenRepairOrders({ VIN }));

    try {
      const repairOrders = await CoxService.getOpenRepairOrdersByVIN(VIN);
      this.store.dispatch(loadOpenRepairOrdersSuccess({ VIN, repairOrders }));

      newTracker.trackAnalyticsEvent({
        ...trackingEvents.OPEN_REPAIR_ORDER_LOAD_SUCCEEDED,
        label: `Open repair orders | ${repairOrders.length}`,
      });

      // The parts are represented as a detail in the repair order
      const parts = getPartsFromRepairOrders(repairOrders);
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.PARTS_LOAD_SUCCEEDED,
        label: `Parts | ${parts.length}`,
      });
    } catch (error) {
      logger.error('Repair order loading error', error);
      newTracker.trackAnalyticsEvent(trackingEvents.OPEN_REPAIR_ORDER_LOAD_FAILED);
      this.store.dispatch(loadOpenRepairOrdersError({ VIN, message: definedMessages.REPAIR_ORDER_LIST_LOAD_ERROR }));
      return;
    }
  }
}
