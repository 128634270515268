import React, { FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { GotoIntegrationsLogo } from '../components/GotoIntegrationsLogo.component';

interface StyleProps {
  fullHeight?: boolean;
  isNarrowIntegration: boolean;
}

const useStyles = makeStyles(() => ({
  outerContainer: (props: StyleProps) => ({
    // this cant be flex in order to make scrolling work fine
    width: '100%',
    overflow: 'auto',
    height: props.fullHeight ? '100%' : undefined,
  }),
  container: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: props.isNarrowIntegration ? '4px 0 0 0' : '24px 20px',
    minHeight: '100%', // so it can scroll AND still use spaceBetween inside
    boxSizing: 'border-box',
  }),
  justifyContentCenter: {
    justifyContent: 'center',
  },
}));

export interface FullScreenPageWithLogoProps {
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  className?: string;
  fullHeight?: boolean;
}

export const FullScreenPageWithLogo: FC<FullScreenPageWithLogoProps> = ({
  fullHeight,
  justifyContent,
  className,
  children,
}) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration, fullHeight });

  return (
    <div className={classes.outerContainer}>
      <div className={clsx(classes.container, className)} style={{ justifyContent }}>
        <GotoIntegrationsLogo width={200} narrowWidth={140} />
        {children}
      </div>
    </div>
  );
};
