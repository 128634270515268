export class Timer {
  private start: number = Date.now();
  private id: ReturnType<typeof setTimeout> | null = null;

  static create(callback: () => Promise<void>, _delay: number) {
    return new Timer(callback, _delay);
  }

  constructor(private readonly callback: () => Promise<void> | void, private delay: number) {
    this.resume();
  }

  pause() {
    this.stop();
    this.delay -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();
    this.stop();
    this.id = setTimeout(this.callback, this.delay);
  }

  stop() {
    if (this.id) {
      clearTimeout(this.id);
      this.id = null;
    }
  }
}

// tslint:disable-next-line:max-classes-per-file
export class Interval {
  private id: ReturnType<typeof setInterval> | null = null;

  constructor(private readonly callback: () => void, private readonly delay: number) {
    this.resume();
  }

  pause() {
    this.stop();
  }

  resume() {
    this.stop();
    this.id = setInterval(this.callback, this.delay);
  }

  stop() {
    if (this.id) {
      clearInterval(this.id);
      this.id = null;
    }
  }
}
