import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import { PartsComponent, PartsProps, PartsStateProps } from './Parts.component';
import { openRepairOrdersLoadRequestStateByVINSelector } from '../repairOrders/repairOrders.selectors';
import { inventoryPartsSelector, repairOrderPartsSelector } from './parts.selectors';

const mapStateToProps = (state: AppState, ownProps: PartsProps): PartsStateProps => ({
  requestState: openRepairOrdersLoadRequestStateByVINSelector(ownProps.VIN)(state),
  repairOrderParts: repairOrderPartsSelector(ownProps.VIN)(state),
  inventoryParts: inventoryPartsSelector(state),
  locale: selectedDateFnsLocaleSelector(state),
});

export const Parts = connect<PartsStateProps, {}, PartsProps, AppState>(mapStateToProps)(PartsComponent);
