import { makeActionCreator } from '../actions/actionCreators';
import { FeatureFlag } from '../featureFlag/featureFlag.model';
import { IndexedById } from '../models';

export enum FeatureFlagEntityActionsType {
  ADD = 'jiveEntities/featureFlag/add',
  CLEAN = 'jiveEntities/featureFlag/clean',
}

export const addFeatureFlagEntity = makeActionCreator(FeatureFlagEntityActionsType.ADD)<IndexedById<FeatureFlag>>();
export const cleanFeatureFlagEntity = makeActionCreator(FeatureFlagEntityActionsType.CLEAN)();

export type FeatureFlagEntityActions =
  | ReturnType<typeof addFeatureFlagEntity>
  | ReturnType<typeof cleanFeatureFlagEntity>;
