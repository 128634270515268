import { Link } from '@getgo/chameleon-web/react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { LabeledSwitch } from '../../components/LabeledSwitch.component';
import { EntityType } from '../../constants';
import { Typography } from '../../theme/Typography.component';
import { hasAccessToEntity } from '../salesforce.selectors';
import { CallDispositionErrorMessage } from './CallDispositionErrorMessage.container';
import { currentIntegrationSelector } from '../../integrations/integrations.selector';
import { Integrations } from '../../models';
import { isSoftphoneCapableAndEnabledSelector } from '../../settings/settings.selector';

const definedMessages = defineMessages({
  AUTO_CALL_LOG_SWITCH_LABEL: {
    id: 'Salesforce.Settings.AutoCallLogSwitch.Label',
    defaultMessage: 'Auto log calls',
  },
  AUTO_CALL_LOG_SWITCH_MESSAGE: {
    id: 'Salesforce.Settings.AutoCallLogSwitch.Message',
    defaultMessage: 'Automatically log your call details in Salesforce.',
  },
  OPEN_CALL_LOG_SWITCH_LABEL: {
    id: 'Salesforce.Settings.OpenCallLogSwitch.Label',
    defaultMessage: 'Open a call log',
  },
  OPEN_CALL_LOG_SWITCH_MESSAGE: {
    id: 'Salesforce.Settings.OpenCallLogSwitch.Message',
    defaultMessage: 'Automatically open a call log when you finish the call.',
  },
  CALL_DISPOSITION_SWITCH_LABEL: {
    id: 'Salesforce.Settings.CallDispositionSwitch.Label',
    defaultMessage: 'Add call dispositions',
  },
  CALL_DISPOSITION_SWITCH_MESSAGE: {
    id: 'Salesforce.Settings.CallDispositionSwitch.Message',
    defaultMessage: 'Select a disposition type for your call.',
  },
  CALL_DISPOSITION_SWITCH_ERROR: {
    id: 'Salesforce.Settings.CallDispositionSwitch.Error',
    defaultMessage: "Call dispositions aren't configured correctly. Contact your admin.",
  },
  ENTITIES_TITLE: {
    id: 'Salesforce.Settings.SalesforceEntities.Title',
    defaultMessage: 'Show Salesforce entities',
  },
  ENTITIES_MESSAGE: {
    id: 'Salesforce.Settings.SalesforceEntities.Message',
    defaultMessage: 'See actionable Salesforce info during GoToConnect calls.',
  },
  OPPORTUNITIES_LABEL: {
    id: 'Salesforce.Settings.OpportunitiesSwitch.Label',
    defaultMessage: 'Opportunities',
  },
  CASES_LABEL: {
    id: 'Salesforce.Settings.CasesSwitch.Label',
    defaultMessage: 'Cases',
  },
  OPEN_CONTACT_IN_NEW_TAB_LABEL: {
    id: 'Salesforce.Settings.OpenContactInNewTabSwitch.Label',
    defaultMessage: 'Open a contact tab',
  },
  OPEN_CONTACT_IN_NEW_TAB_MESSAGE: {
    id: 'Salesforce.Settings.OpenContactInNewTabSwitch.Message',
    defaultMessage: 'When your saved contacts call in, a new tab will open with their info.',
  },
  OPEN_CONTACT_IN_NEW_TAB_ENABLE_POPUPS: {
    id: 'Salesforce.Settings.OpenContactInNewTabSwitch.LinkText',
    defaultMessage: 'Popups required',
  },
  WIDGET_AUTO_POPUP_SWITCH_LABEL: {
    id: 'Salesforce.Settings.WidgetAutoPopup.Label',
    defaultMessage: 'Show GoTo during calls',
  },
  WIDGET_AUTO_POPUP_SWITCH_MESSAGE: {
    id: 'Salesforce.Settings.WidgetAutoPopup.Message',
    defaultMessage: 'Activate GoTo for Salesforce during incoming or outgoing calls.',
  },
});

const trackingEvents = defineTrackingEvents({
  AUTO_CALL_LOG_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Auto log call | toggle | ON',
  },
  AUTO_CALL_LOG_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Auto log call | toggle | OFF',
  },
  OPEN_CALL_LOG_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Open call log | toggle | ON',
  },
  OPEN_CALL_LOG_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Open call log | toggle | OFF',
  },
  CALL_DISPOSITION_POPUP_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Call disposition popup | toggle | ON',
  },
  CALL_DISPOSITION_POPUP_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Call disposition popup | toggle | OFF',
  },
  OPPORTUNITIES_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Opportunities | toggle | ON',
  },
  OPPORTUNITIES_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Opportunities | toggle | OFF',
  },
  CASES_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Cases | toggle | ON',
  },
  CASES_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Cases | toggle | OFF',
  },
  OPEN_CONTACT_IN_NEW_TAB_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Open contact in new tab | toggle | ON',
  },
  OPEN_CONTACT_IN_NEW_TAB_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Open contact in new tab | toggle | OFF',
  },
  WIDGET_AUTO_POPUP_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Widget auto popup | toggle | ON',
  },
  WIDGET_AUTO_POPUP_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Widget auto popup | toggle | OFF',
  },
});

const useStyles = makeStyles({
  labelPlacement: {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export interface SalesforceSettingsStateProps {
  isAutomaticCallLoggingEnabled: boolean;
  isOpenCallLogAfterCallEnabled: boolean;
  isShowCallDispositionFormEnabled: boolean;
  isCallDispositionConfigured: boolean;
  isOpportunitiesEnabled: boolean;
  isCasesEnabled: boolean;
  isOpenContactInNewTabEnabled: boolean;
  isNarrowIntegration: boolean;
  isWidgetAutoPopupEnabled: boolean;
}

export interface SalesforceSettingsDispatchProps {
  setAutomaticCallLogging: (enabled: boolean) => void;
  setOpenCallLogAfterCall: (enabled: boolean) => void;
  setShowCallDispositionForm: (enabled: boolean) => void;
  setOpportunities: (enabled: boolean) => void;
  setCases: (enabled: boolean) => void;
  showCallDispositionConfigurationErrorMessage: (content: React.ReactElement) => void;
  setOpenContactInNewTab: (enabled: boolean) => void;
  setWidgetAutoPopupEnabled: (enabled: boolean) => void;
}

type Props = SalesforceSettingsStateProps & SalesforceSettingsDispatchProps;

export const SalesforceSettingsComponent: React.FC<Props> = ({
  isAutomaticCallLoggingEnabled,
  isOpenCallLogAfterCallEnabled,
  isShowCallDispositionFormEnabled,
  isCallDispositionConfigured,
  isOpportunitiesEnabled,
  isCasesEnabled,
  isOpenContactInNewTabEnabled,
  setAutomaticCallLogging,
  setOpenCallLogAfterCall,
  setShowCallDispositionForm,
  setOpportunities,
  setCases,
  setOpenContactInNewTab,
  showCallDispositionConfigurationErrorMessage,
  isNarrowIntegration,
  isWidgetAutoPopupEnabled,
  setWidgetAutoPopupEnabled,
}) => {
  const classes = useStyles();

  const shouldShowCasesOption = useSelector(hasAccessToEntity(EntityType.CASE));
  const shouldShowopportunitiesOption = useSelector(hasAccessToEntity(EntityType.OPPORTUNITY));
  const integration = useSelector(currentIntegrationSelector);
  const isSoftphoneCapableAndEnabled = useSelector(isSoftphoneCapableAndEnabledSelector);

  const Heading: FC = ({ children }) => {
    const variant = isNarrowIntegration ? 'body-small' : 'heading-xsmall';
    return (
      <Typography variant={variant} style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    );
  };

  function showCallDispositionErrorMessage() {
    showCallDispositionConfigurationErrorMessage(<CallDispositionErrorMessage />);
  }

  function renderWidgetAutoPopSwitch() {
    if (isSoftphoneCapableAndEnabled || integration !== Integrations.SalesforceLightning) {
      return null;
    }

    return (
      <Box mb={6}>
        <Box mb={1}>
          <LabeledSwitch
            id="widget-auto-pop-switch"
            checked={isWidgetAutoPopupEnabled}
            labelPlacement="start"
            onChange={({ currentTarget: { checked } }) => setWidgetAutoPopupEnabled(checked)}
            label={
              <Heading>
                <FormattedMessage {...definedMessages.WIDGET_AUTO_POPUP_SWITCH_LABEL} />
              </Heading>
            }
            onTrackingEvent={trackingEvents.WIDGET_AUTO_POPUP_SWITCHED_ON}
            offTrackingEvent={trackingEvents.WIDGET_AUTO_POPUP_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.WIDGET_AUTO_POPUP_SWITCH_MESSAGE} />
        </Typography>
      </Box>
    );
  }

  function renderAutoCallLogSwitch() {
    return (
      <>
        <Box mb={1}>
          <LabeledSwitch
            id="auto-call-log-switch"
            checked={isAutomaticCallLoggingEnabled}
            labelPlacement="start"
            onChange={({ currentTarget: { checked } }) => setAutomaticCallLogging(checked)}
            label={
              <Heading>
                <FormattedMessage {...definedMessages.AUTO_CALL_LOG_SWITCH_LABEL} />
              </Heading>
            }
            onTrackingEvent={trackingEvents.AUTO_CALL_LOG_SWITCHED_ON}
            offTrackingEvent={trackingEvents.AUTO_CALL_LOG_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.AUTO_CALL_LOG_SWITCH_MESSAGE} />
        </Typography>
      </>
    );
  }

  function renderOpenCallLogSwitch() {
    return (
      <>
        <Box mb={1}>
          <LabeledSwitch
            id="open-call-log-after-call-switch"
            checked={isOpenCallLogAfterCallEnabled}
            labelPlacement="start"
            onChange={({ currentTarget: { checked } }) => setOpenCallLogAfterCall(checked)}
            label={
              <Heading>
                <FormattedMessage {...definedMessages.OPEN_CALL_LOG_SWITCH_LABEL} />
              </Heading>
            }
            onTrackingEvent={trackingEvents.OPEN_CALL_LOG_SWITCHED_ON}
            offTrackingEvent={trackingEvents.OPEN_CALL_LOG_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.OPEN_CALL_LOG_SWITCH_MESSAGE} />
        </Typography>
      </>
    );
  }

  function renderCallDispositionSwitch() {
    return (
      <>
        <Box mb={1}>
          <LabeledSwitch
            id="call-dispositions-switch"
            checked={isCallDispositionConfigured && isShowCallDispositionFormEnabled}
            labelPlacement="start"
            name="show-call-disposition"
            onChange={(event) => {
              const checked = event.target.checked;
              if (isCallDispositionConfigured) {
                setShowCallDispositionForm(checked);
                if (checked) {
                  newTracker.trackAnalyticsEvent({
                    ...trackingEvents.CALL_DISPOSITION_POPUP_SWITCHED_ON,
                    label: `${trackingEvents.CALL_DISPOSITION_POPUP_SWITCHED_ON.label} | Succeeded`,
                  });
                }
              } else {
                event.target.checked = false;
                showCallDispositionErrorMessage();
                if (checked) {
                  newTracker.trackAnalyticsEvent({
                    ...trackingEvents.CALL_DISPOSITION_POPUP_SWITCHED_ON,
                    label: `${trackingEvents.CALL_DISPOSITION_POPUP_SWITCHED_ON.label} | Failed`,
                  });
                }
              }
            }}
            label={
              <Heading>
                <FormattedMessage {...definedMessages.CALL_DISPOSITION_SWITCH_LABEL} />
              </Heading>
            }
            offTrackingEvent={trackingEvents.CALL_DISPOSITION_POPUP_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.CALL_DISPOSITION_SWITCH_MESSAGE} />
        </Typography>
        {!isCallDispositionConfigured && (
          <Box mt={3}>
            <Typography variant="caption-default-01" color="text-error-01">
              <FormattedMessage {...definedMessages.CALL_DISPOSITION_SWITCH_ERROR} />
            </Typography>
          </Box>
        )}
      </>
    );
  }

  function renderSalesforceEntitiesSwitches() {
    return (
      <>
        <Heading>
          <FormattedMessage {...definedMessages.ENTITIES_TITLE} />
        </Heading>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.ENTITIES_MESSAGE} />
        </Typography>
        {shouldShowopportunitiesOption && (
          <Box mt={3}>
            <LabeledSwitch
              id="opportunities-switch"
              checked={isOpportunitiesEnabled}
              labelPlacement="start"
              onChange={({ currentTarget: { checked } }) => setOpportunities(checked)}
              label={
                <Typography variant="body-small">
                  <b>
                    <FormattedMessage {...definedMessages.OPPORTUNITIES_LABEL} />
                  </b>
                </Typography>
              }
              onTrackingEvent={trackingEvents.OPPORTUNITIES_SWITCHED_ON}
              offTrackingEvent={trackingEvents.OPPORTUNITIES_SWITCHED_OFF}
              controlLabelClasses={{
                labelPlacementStart: classes.labelPlacement,
              }}
            />
          </Box>
        )}
        {shouldShowCasesOption && (
          <Box mt={2}>
            <LabeledSwitch
              id="cases-switch"
              checked={isCasesEnabled}
              labelPlacement="start"
              onChange={({ currentTarget: { checked } }) => setCases(checked)}
              label={
                <Typography variant="body-small">
                  <b>
                    <FormattedMessage {...definedMessages.CASES_LABEL} />
                  </b>
                </Typography>
              }
              onTrackingEvent={trackingEvents.CASES_SWITCHED_ON}
              offTrackingEvent={trackingEvents.CASES_SWITCHED_OFF}
              controlLabelClasses={{
                labelPlacementStart: classes.labelPlacement,
              }}
            />
          </Box>
        )}
      </>
    );
  }

  const renderOpenContactInNewTabSwitch = () => {
    return (
      <>
        <Box mb={1}>
          <LabeledSwitch
            id="open-contacts-new-tab-switch"
            checked={isOpenContactInNewTabEnabled}
            labelPlacement="start"
            onChange={({ currentTarget: { checked } }) => setOpenContactInNewTab(checked)}
            label={
              <Heading>
                <FormattedMessage {...definedMessages.OPEN_CONTACT_IN_NEW_TAB_LABEL} />
              </Heading>
            }
            onTrackingEvent={trackingEvents.OPEN_CONTACT_IN_NEW_TAB_SWITCHED_ON}
            offTrackingEvent={trackingEvents.OPEN_CONTACT_IN_NEW_TAB_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01" tag="span">
          <FormattedMessage {...definedMessages.OPEN_CONTACT_IN_NEW_TAB_MESSAGE} />
        </Typography>
        &nbsp;
        <Typography variant="caption-default-01" tag="span">
          <Link
            target="_blank"
            href="https://support.goto.com/connect/help/how-do-i-allow-pop-ups-for-the-salesforce-integration"
          >
            <FormattedMessage {...definedMessages.OPEN_CONTACT_IN_NEW_TAB_ENABLE_POPUPS} />
          </Link>
        </Typography>
      </>
    );
  };

  return (
    <>
      {renderWidgetAutoPopSwitch()}
      <Box mb={6}>{renderAutoCallLogSwitch()}</Box>
      <Box mb={6}>{renderOpenCallLogSwitch()}</Box>
      <Box mb={6}>{renderCallDispositionSwitch()}</Box>
      {shouldShowCasesOption || shouldShowopportunitiesOption ? (
        <Box mb={6}>{renderSalesforceEntitiesSwitches()}</Box>
      ) : null}
      <Box mb={6}>{renderOpenContactInNewTabSwitch()}</Box>
    </>
  );
};
