import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { tokens } from '@getgo/chameleon-core';
import { numberOfActiveCallsSelector } from '../calls/calls.selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      width: '24px',
      height: '24px',
      backgroundColor: 'currentColor',
      borderRadius: '3px',
    },
    text: (props: { isActive: boolean }) => ({
      color: props.isActive ? tokens.icon02 : tokens.icon03,
      flex: 1,
      textAlign: 'center',
    }),
  }),
);

export const ActiveCallsMenuItem: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const classes = useStyles({ isActive });
  const numberOfActiveCalls = useSelector(numberOfActiveCallsSelector).toString();
  return (
    <div className={classes.container}>
      <div className={classes.text}>{numberOfActiveCalls}</div>
    </div>
  );
};
