import React from 'react';
import { useSelector } from 'react-redux';
import { selectSoftphoneCallByRealtimeCallSelector } from './softphoneCall.selector';
import { SoftphoneCall } from './softphone.model';
import { Call, CallType } from '@jive/realtime-events';
import { isSoftphoneCapableAndEnabledSelector } from '../settings/settings.selector';

export interface SoftphoneContainerProps {
  render: (softphoneCall: SoftphoneCall) => React.ReactElement;
  realtimeCall: Call;
}
export interface OptionalSoftphoneContainerProps {
  render: (softphoneCall: SoftphoneCall | undefined) => React.ReactElement;
  optional?: true;
  realtimeCall: Call;
}

export const SoftphoneContainer: React.FC<SoftphoneContainerProps & OptionalSoftphoneContainerProps> = ({
  realtimeCall,
  render,
  optional,
}) => {
  const isSoftphoneCapableAndEnabled = useSelector(isSoftphoneCapableAndEnabledSelector);
  const softphoneCall: SoftphoneCall | undefined = useSelector(selectSoftphoneCallByRealtimeCallSelector(realtimeCall));

  if (
    realtimeCall.type === CallType.DeclinedCall ||
    realtimeCall.type === CallType.MissedCall ||
    !!realtimeCall.endTime
  ) {
    return null;
  }

  if (optional) {
    return render(softphoneCall);
  }

  if (!isSoftphoneCapableAndEnabled || !softphoneCall) {
    return null;
  }

  return render(softphoneCall);
};
