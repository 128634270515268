import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Entity, MessageVariant } from '../models';
import { SmallIcon } from '../components/SmallIcon.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { allCallSelector, getContactMatchTypeSelector } from '../calls/calls.selector';
import { newTracker } from '../analytics-new/tracker-new';
import { stringifyCallType } from '../calls/call.helper';
import { MenuItem, MenuSection, MenuSeparator } from '@getgo/chameleon-web/react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { AppState } from '../reducers';
import { setPhoneSelectedEntity } from '../phone/phone.actions';
import { PersonTransferToInactiveIcon } from '@getgo/chameleon-icons/react';
import { callAssignmentService } from '../calls/callAssignmentService';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { CAPABILITY_AUTO_CALL_ASSIGNMENT } from '../actionFacade/action.facade.capabilities';
import { deleteMessage, showMessage } from '../inAppNotification/message.action';
import { Box } from '@material-ui/core';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { dismissService } from '../dismissService';

export const definedMessages = defineMessages({
  ASSIGN_TO_THIS_CONTACT: {
    id: 'SearchResult.AssignToThisContact.Header',
    defaultMessage: 'Assign the call to this contact?',
  },
  THIS_CALL: {
    id: 'SearchResult.AssignToThisContact.ThisCall',
    defaultMessage: 'For this call only',
  },
  THIS_AND_FUTURE_CALLS: {
    id: 'SearchResult.AssignToThisContact.ThisAndFutureCalls',
    defaultMessage: 'For this and future calls',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning',
    defaultMessage:
      'Future calls will be associated with this contact. You can change this in the menu next to your contact’s name.',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING_DISMISS: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning.Dismiss',
    defaultMessage: 'Thanks, got it',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING_DONT_REMIND_ME: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning.DontRemindMe',
    defaultMessage: 'Don’t remind me again',
  },
});

const trackingEvents = defineTrackingEvents({
  DISMISS_AUTO_SINGLE_MATCH_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Thanks got it | button',
  },
  DONT_REMIND_ME_AUTO_SINGLE_MATCH_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Don’t remind me again | button',
  },
});

export interface AssignToCallProps {
  entity: Entity;
}

export const AssignToCallContextMenuItem: React.FC<AssignToCallProps> = ({ entity }) => {
  const routeMatch = useRouteMatch<{ callId: string }>();
  const call = useSelector((state: AppState) => allCallSelector(state, routeMatch.params.callId));
  const matchType = useSelector((state: AppState) => getContactMatchTypeSelector(state, routeMatch.params.callId));
  const dispatch = useDispatch();

  if (!call) {
    return null;
  }

  return (
    <>
      <MenuSection>
        <FormattedMessage {...definedMessages.ASSIGN_TO_THIS_CONTACT} />
      </MenuSection>
      <MenuItem
        leadingIcon={
          <SmallIcon>
            <PersonTransferToInactiveIcon />
          </SmallIcon>
        }
        onClick={() => {
          dispatch(setPhoneSelectedEntity({ entityId: entity.id }));
          newTracker.trackAnalyticsEvent({
            category: AnalyticsCategory.Contact,
            action: AnalyticsAction.ItemClicked,
            label: `Assign contact to call | menuitem | ${stringifyCallType(call.type)} | ${matchType}`,
          });
        }}
      >
        <FormattedMessage {...definedMessages.THIS_CALL} />
      </MenuItem>
      {getActionFacade().isCapable(CAPABILITY_AUTO_CALL_ASSIGNMENT) ? (
        <MenuItem
          leadingIcon={
            <SmallIcon>
              <PersonTransferToInactiveIcon />
            </SmallIcon>
          }
          onClick={async () => {
            await callAssignmentService.upsertAssignment({
              contactId: entity.id,
              phoneNumber: call.theOtherParty.number,
              isActive: true,
            });
            if (!dismissService.isDismissed('auto_single_match_warning_msg')) {
              dispatch(
                showMessage({
                  id: 'auto_single_match_warning_msg',
                  type: MessageVariant.Info,
                  message: definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING,
                  params: {
                    autoHide: false,
                    dismissible: false,
                    actions: (
                      <Box display="flex" gridGap={8}>
                        <TrackedButton
                          trackingEvent={trackingEvents.DISMISS_AUTO_SINGLE_MATCH_CLICKED}
                          variant="neutral-inverse"
                          size="small"
                          onClick={() => dispatch(deleteMessage('auto_single_match_warning_msg'))}
                        >
                          <FormattedMessage {...definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING_DISMISS} />
                        </TrackedButton>
                        <TrackedButton
                          trackingEvent={trackingEvents.DONT_REMIND_ME_AUTO_SINGLE_MATCH_CLICKED}
                          variant="neutral-inverse"
                          size="small"
                          onClick={() => {
                            dispatch(deleteMessage('auto_single_match_warning_msg'));
                            const dismissDuration = 1000 * 60 * 60 * 24 * 365 * 5; // 5 years ¯\_(ツ)_/¯
                            dismissService.dismiss('auto_single_match_warning_msg', dismissDuration);
                          }}
                        >
                          <FormattedMessage {...definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING_DONT_REMIND_ME} />
                        </TrackedButton>
                      </Box>
                    ),
                  },
                }),
              );
            }
            dispatch(setPhoneSelectedEntity({ entityId: entity.id, isAutoSingleMatch: true }));
            newTracker.trackAnalyticsEvent({
              category: AnalyticsCategory.Contact,
              action: AnalyticsAction.ItemClicked,
              label: `Assign contact to this and future calls | menuitem | ${stringifyCallType(
                call.type,
              )} | ${matchType}`,
            });
          }}
        >
          <FormattedMessage {...definedMessages.THIS_AND_FUTURE_CALLS} />
        </MenuItem>
      ) : null}
      <MenuSeparator />
    </>
  );
};
