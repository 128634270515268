import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const surveySelector = (state: AppState) => state.survey;

const messagingExperienceSurveySelector = createSelector(surveySelector, (survey) => survey.messagingExperience);

export const messagingExperienceFirstVisitSelector = createSelector(messagingExperienceSurveySelector, (me) =>
  me.firstVisit ? new Date(me.firstVisit) : undefined,
);

export const messagingExperienceNumberOfMessagesLoggedSelector = createSelector(
  messagingExperienceSurveySelector,
  (me) => me.numberOfLoggedMessages,
);

export const messagingExperienceNumberOfLogsSelector = createSelector(
  messagingExperienceSurveySelector,
  (me) => me.numberOfLogs,
);

export const messagingExperienceFirstMessageLogSelector = createSelector(messagingExperienceSurveySelector, (me) =>
  me.firstMessageLog ? new Date(me.firstMessageLog) : undefined,
);
