import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../components/LoadingSpinner';
import JiveForm from '../../entityForms/JiveForm.component';
import { RedtailActivityAddModel } from '../redtail.model';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import TimePicker from '../../components/TimePicker.container';
import { DatePicker } from '../../components/DatePicker.component';
import { TextInputField } from '../../entityForms/TextInputField.component';
import { newTracker } from '../../analytics-new/tracker-new';
import { getTimeZoneString } from './redtail.activity.utils';
import { CallActionFormProps } from '../../calls/CallPageForm.model';
import { Typography } from '../../theme/Typography.component';
import Box from '@material-ui/core/Box/Box';
import { convertToAllDayEvent, getNextOneHourSlot } from './RedtailActivityForm.utils';
import { addDays, addMinutes, differenceInDays, differenceInMinutes } from 'date-fns';
import { LabeledSwitch } from '../../components/LabeledSwitch.component';

const useStyles = makeStyles((theme: Theme) => ({
  allDaySwitch: {
    marginRight: 0,
  },
  allDaySwitchLabel: {
    marginRight: theme.spacing(2),
  },
}));

const trackingEvents = defineTrackingEvents({
  ACTIVITY_FORM_LOADED: {
    category: AnalyticsCategory.Activity,
    action: AnalyticsAction.PageLoaded,
    label: 'Create activity',
  },
  START_DATE_CHANGED: {
    category: AnalyticsCategory.Activity,
    label: 'Start date | datepicker',
    action: AnalyticsAction.ItemChanged,
  },
  END_DATE_CHANGED: {
    category: AnalyticsCategory.Activity,
    label: 'End date | datepicker',
    action: AnalyticsAction.ItemChanged,
  },
  START_TIME_CHANGED: {
    category: AnalyticsCategory.Activity,
    label: 'Start time | timepicker',
    action: AnalyticsAction.ItemChanged,
  },
  END_TIME_CHANGED: {
    category: AnalyticsCategory.Activity,
    label: 'End time | timepicker',
    action: AnalyticsAction.ItemChanged,
  },
  ALL_DAY_SWITCHED_ON: {
    category: AnalyticsCategory.Activity,
    label: 'All day toggle | ON',
    action: AnalyticsAction.ItemChanged,
  },
  ALL_DAY_SWITCHED_OFF: {
    category: AnalyticsCategory.Activity,
    label: 'All day toggle | OFF',
    action: AnalyticsAction.ItemChanged,
  },
  SUBJECT_CHANGED: {
    category: AnalyticsCategory.Activity,
    label: 'Subject | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_ACTIVITY_CLICKED: {
    category: AnalyticsCategory.Activity,
    label: 'Add activity | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Activity,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
});

const definedMessages = defineMessages({
  NEW_ACTIVITY_FORM_TITLE: {
    id: 'Redtail.NewActivityForm.Title',
    defaultMessage: 'Create Activity',
  },
  NEW_ACTIVITY_FORM_SUBJECT: {
    id: 'Redtail.NewActivityForm.Subject',
    defaultMessage: 'Subject',
  },
  NEW_ACTIVITY_FORM_SAVE_BUTTON: {
    id: 'Redtail.NewActivityForm.SaveButtonText',
    defaultMessage: 'Add Activity',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_TITLE: {
    id: 'Redtail.NewActivityForm.Scheduling.Title',
    defaultMessage: 'Scheduling',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_ALL_DAY_LABEL: {
    id: 'Redtail.NewActivityForm.AllDay.Label',
    defaultMessage: 'All day',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_START_DATE_LABEL: {
    id: 'Redtail.NewActivityForm.StartDate.Label',
    defaultMessage: 'Start Date',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_END_DATE_LABEL: {
    id: 'Redtail.NewActivityForm.EndDate.Label',
    defaultMessage: 'End Date',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_START_TIME_LABEL: {
    id: 'Redtail.NewActivityForm.StartTime.Label',
    defaultMessage: 'Start Time',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_END_TIME_LABEL: {
    id: 'Redtail.NewActivityForm.EndTime.Label',
    defaultMessage: 'End Time',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_DATE_ERROR: {
    id: 'Redtail.NewActivityForm.Date.Error',
    defaultMessage: 'Start date cannot be after the end date.',
  },
  NEW_ACTIVITY_FORM_SCHEDULING_END_DATE_ERROR: {
    id: 'Redtail.NewActivityForm.EndDate.Error',
    defaultMessage: 'End date cannot be before the start date.',
  },
  NEW_ACTIVITY_TIMEZONE: {
    id: 'Redtail.NewActivityForm.TimeZone',
    defaultMessage: 'Time zone:',
  },
});

export interface RedtailActivityFormStateProps {
  contactId?: string;
  isActivityAddLoading: boolean;
  isActiveCall: boolean;
}

export interface RedtailActivityFormDispatchProps {
  addActivity: (activity: RedtailActivityAddModel) => void;
}

export type RedtailActivityFormProps = CallActionFormProps;

const RedtailActivityForm: React.FC<
  RedtailActivityFormProps & RedtailActivityFormStateProps & RedtailActivityFormDispatchProps
> = ({ addActivity, contactId, isActivityAddLoading, isActiveCall, callId, onClose }) => {
  const classes = useStyles();
  const nextOneHourSlot = getNextOneHourSlot();
  const [subject, setSubject] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(nextOneHourSlot.startDate);
  const [endDate, setEndDate] = useState<Date>(nextOneHourSlot.endDate);
  const [isAllDayEvent, setIsAllDayEvent] = useState<boolean>(false);
  const [hasDateError, setHasDateError] = useState<boolean>(false);

  useEffect(() => {
    setHasDateError(startDate >= endDate);
  }, [startDate, endDate, isAllDayEvent]);

  useEffect(() => {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.ACTIVITY_FORM_LOADED);
    // lint disabled because this doesnt have to run when activeCall changes, only ones on page load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSubmit = !!subject && !hasDateError;

  if (!contactId) {
    return null;
  }

  function renderDateValidationError() {
    if (!hasDateError) {
      return null;
    }
    return (
      <Typography color="text-error-01">
        <FormattedMessage {...definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_DATE_ERROR} />
      </Typography>
    );
  }

  function renderTimePickers() {
    if (isAllDayEvent) {
      return null;
    }

    const timeZoneString = getTimeZoneString();

    return (
      <>
        <Grid container justifyContent="space-around" spacing={1}>
          <Grid item xs={6}>
            <TimePicker
              label={definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_START_TIME_LABEL}
              value={startDate}
              onChange={(date: Date | null) => {
                if (!date) {
                  return;
                }
                const timeDifferenceInMinutes = differenceInMinutes(date, startDate);
                setStartDate(date);
                // automatically adjust end date (Redtail behavior)
                setEndDate(addMinutes(endDate, timeDifferenceInMinutes));
              }}
              dense
              trackingEvent={trackingEvents.START_TIME_CHANGED}
              name="start time"
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              label={definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_END_TIME_LABEL}
              value={endDate}
              onChange={(date) => date && setEndDate(date)}
              dense
              trackingEvent={trackingEvents.END_TIME_CHANGED}
              name="end time"
            />
          </Grid>
        </Grid>
        <Typography variant="caption-default-01" gutterBottom>
          <FormattedMessage {...definedMessages.NEW_ACTIVITY_TIMEZONE} />
          {timeZoneString}
        </Typography>
      </>
    );
  }

  return (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => {
        if (canSubmit) {
          const datesToSubmit = isAllDayEvent ? convertToAllDayEvent(startDate, endDate) : { startDate, endDate };
          addActivity({
            subject,
            contactId,
            startDate: datesToSubmit.startDate,
            endDate: datesToSubmit.endDate,
            isAllDayEvent,
            isActiveCall,
            callId,
          });
        }
      }}
      canSubmit={!isActivityAddLoading && canSubmit}
      saveButtonDescriptor={definedMessages.NEW_ACTIVITY_FORM_SAVE_BUTTON}
      saveTrackingEvent={trackingEvents.ADD_ACTIVITY_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
    >
      <Box mb={2}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.NEW_ACTIVITY_FORM_TITLE} />
        </Typography>
      </Box>
      <TextInputField
        name="activity-subject"
        maxLength={80}
        autoFocus
        value={subject}
        labelDescription={definedMessages.NEW_ACTIVITY_FORM_SUBJECT}
        onChange={(event) => setSubject(event.target.value)}
        trackingEvent={trackingEvents.SUBJECT_CHANGED}
      />
      <Box mb={4}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Typography variant="heading-xsmall" style={{ textAlign: 'center' }}>
              <FormattedMessage {...definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_TITLE} />
            </Typography>
          </Grid>
          <Grid item>
            <LabeledSwitch
              id="all-day-switch"
              checked={isAllDayEvent}
              labelPlacement="start"
              label={<FormattedMessage {...definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_ALL_DAY_LABEL} />}
              onChange={({ currentTarget: { checked } }) => setIsAllDayEvent(checked)}
              onTrackingEvent={trackingEvents.ALL_DAY_SWITCHED_ON}
              offTrackingEvent={trackingEvents.ALL_DAY_SWITCHED_OFF}
              controlLabelClasses={{
                root: classes.allDaySwitch,
                label: classes.allDaySwitchLabel,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <DatePicker
        label={definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_START_DATE_LABEL}
        value={startDate}
        onChange={(date: Date | null | undefined) => {
          if (!date) {
            return;
          }
          const dateDifferenceInDays = differenceInDays(date, startDate);
          setStartDate(date);
          // automatically adjust end date (Redtail behavior)
          setEndDate(addDays(endDate, dateDifferenceInDays));
        }}
        trackingEvent={trackingEvents.START_DATE_CHANGED}
        name="start date"
      />
      <DatePicker
        label={definedMessages.NEW_ACTIVITY_FORM_SCHEDULING_END_DATE_LABEL}
        value={endDate}
        onChange={(date) => date && setEndDate(date)}
        trackingEvent={trackingEvents.END_DATE_CHANGED}
        name="end date"
      />
      {renderTimePickers()}
      {renderDateValidationError()}
      <LoadingSpinner isLoading={isActivityAddLoading}>
        <></>
      </LoadingSpinner>
    </JiveForm>
  );
};

export default RedtailActivityForm;
