import Qs from 'qs';
import React from 'react';
import { extractStateObject } from './loginHelper';

interface LoginStateObject {
  loginSuccessPath: string;
  loginSuccessOrigin: string;
  integration: string;
}

export const LoginComponent: React.FC = () => {
  let hash = window.location.hash;
  if (hash.indexOf('#') === 0) {
    hash = hash.slice(1);
  }

  let search = window.location.search;
  if (search.indexOf('?') === 0) {
    search = search.slice(1);
  }

  const { state } = Qs.parse(hash || search);
  const { loginSuccessPath, loginSuccessOrigin, integration } = extractStateObject<LoginStateObject>(state as string);
  const redirectOrigin =
    loginSuccessOrigin && loginSuccessOrigin.includes(window.location.host)
      ? loginSuccessOrigin
      : window.location.origin;

  document.location.href = `${redirectOrigin}${loginSuccessPath}?integration=${integration}${
    window.location.hash || `&${search}`
  }`;
  return null;
};
