import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';
import { Entity } from '../../models';
import { EntityPhoneNumber, EntityPhoneNumberType } from '../../search/entities.model';
import { CoxService } from '../cox.service';
import { formatPhoneNumberToNationalDigitsOnlyFormats } from '../../phone/phone-utils';
import { loadCustomer, loadCustomerError, loadCustomerSuccess } from './cox.customer.actions';
import { defineMessages } from 'react-intl';
import { logger } from '../../logging';

const definedMessages = defineMessages({
  CUSTOMER_LOAD_ERROR: {
    id: 'Cox.Customer.CustomerLoadError',
    defaultMessage: 'Sorry, something went wrong while loading customer. Please try again later.',
  },
  ADVISORIES_LOAD_ERROR: {
    id: 'Cox.Customer.AdvisoriesLoadError',
    defaultMessage: 'Sorry, something went wrong while loading advisories. Please try again later.',
  },
});

export class CoxContactActionCreator extends ContactActionCreator {
  public async loadCustomerById(customerId: string): Promise<void> {
    this.store.dispatch(loadCustomer());
    try {
      const customer = await CoxService.lookupCustomerById(customerId);
      this.store.dispatch(loadCustomerSuccess({ customer }));
    } catch (error) {
      logger.error('Error while loading customer', error);
      this.store.dispatch(loadCustomerError({ message: definedMessages.CUSTOMER_LOAD_ERROR }));
    }
  }

  protected _canUpdateContactPhoneNumber(_: string, __: string): Promise<boolean> {
    return Promise.resolve(false);
  }

  protected _createContact(_: ContactCreateModel): Promise<string> {
    throw new Error('Method not implemented yet');
  }

  protected async _getPhoneNumbersOfContact(id: string): Promise<EntityPhoneNumber[]> {
    const contact = await CoxService.lookupCustomerById(id);

    if (!contact) {
      return [];
    }

    // Preferred phone number to use for contact (B=Business, H=Home, C=Cell, P=Pager)
    const nodesToCheck = [
      { node: 'cellPhone', type: EntityPhoneNumberType.MOBILE, preferredNumberSign: 'C' },
      { node: 'businessPhone', type: EntityPhoneNumberType.BUSINESS, preferredNumberSign: 'B' },
      { node: 'phoneNumber', type: EntityPhoneNumberType.HOME, preferredNumberSign: 'H' },
      { node: 'otherPhone', type: EntityPhoneNumberType.OTHER, preferredNumberSign: undefined },
      { node: 'pagePhone', type: EntityPhoneNumberType.PAGER, preferredNumberSign: 'P' },
    ] as const;

    const preferredPhoneKey = contact.preferredPhone ?? '';
    const phoneNumbers: EntityPhoneNumber[] = nodesToCheck.reduce<EntityPhoneNumber[]>(
      (numbers, { node, type, preferredNumberSign }) => {
        // it seems 0 is the default value when no phone number is provided
        // ex: HomePhone	N10,0	Home phone number (from documentation)
        if (contact[node] && contact[node] !== '0') {
          const entityNumber = {
            entityId: id,
            type,
            phoneNumber: contact[node]!,
          };
          // let's put the preferred number to the front of the array
          if (preferredNumberSign === preferredPhoneKey) {
            numbers.unshift(entityNumber);
          } else {
            numbers.push(entityNumber);
          }
        }
        return numbers;
      },
      [],
    );

    return phoneNumbers;
  }

  protected _openCrmContactPage(_: string): Promise<void> {
    throw new Error('Method not implemented yet');
  }

  protected _openCrmContactTab(_: string): Promise<boolean> {
    throw new Error('Method not implemented yet');
  }

  protected _searchContactByName(name: string): Promise<Entity[]> {
    return CoxService.searchCustomerByName(name);
  }

  protected _searchContactByPhoneNumber(phone: string): Promise<Entity[]> {
    return CoxService.searchCustomerByPhone(phone);
  }

  protected _updateContactPhoneNumber(_: string, __: string): Promise<void> {
    throw new Error('Method not implemented yet');
  }

  protected formatPhoneNumberToPossibleMatches(phoneNumber: string): string[] {
    // cox handles phone numbers as numbers => only digits are allowed, max 10 digits
    // See: https://otwiki.dms.dealertrack.com/opentrack/index.php/CustomerSearch_Request
    return formatPhoneNumberToNationalDigitsOnlyFormats(phoneNumber);
  }
}
