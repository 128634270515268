import { LoadVehiclesRequestState, Vehicle } from './vehicles.models';
import React from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';
import { VehicleComponent } from './Vehicle.component';
import { DealerTrackEntitiesSettings } from '../settings/cox.settings.models';
import { Typography } from '../../theme/Typography.component';
import { Box } from '@material-ui/core';

const definedMessages = defineMessages({
  EMPTY_MESSAGE: {
    id: 'Cox.Vehicles.List.Empty',
    defaultMessage: 'This customer has no vehicles.',
  },
});

export interface VehiclesStateProps {
  dealerTrackSettings: DealerTrackEntitiesSettings;
  requestState: LoadVehiclesRequestState;
  vehicles: Vehicle[];
  locale: Locale;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    name: {
      fontWeight: 500,
      fontSize: '13px',
    },
  }),
);

export const VehiclesComponent: React.FC<VehiclesStateProps & CallContextPanelProps> = ({
  dealerTrackSettings,
  requestState,
  vehicles,
  locale,
}) => {
  const classes = useStyles();

  function renderLoadedState() {
    return vehicles.length ? (
      <>
        {vehicles.map((vehicle) => (
          <VehicleComponent
            key={vehicle.VIN}
            vehicle={vehicle}
            locale={locale}
            dealerTrackSettings={dealerTrackSettings}
          />
        ))}
      </>
    ) : (
      <Box mt={1} mr={1} ml={1}>
        <Typography>
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      </Box>
    );
  }

  function render() {
    switch (requestState) {
      case LoadVehiclesRequestState.INITIAL:
        return null;
      case LoadVehiclesRequestState.LOADING:
        return (
          <LoadingSpinner isLoading={true}>
            <></>
          </LoadingSpinner>
        );
      case LoadVehiclesRequestState.LOADED:
        return renderLoadedState();
      case LoadVehiclesRequestState.FAILED:
        return null; // The snack bar will display the error message.;
    }
  }

  return <div className={classes.container}>{render()}</div>;
};
