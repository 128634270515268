import { GoogleAnalyticsPayload } from './tracker.models';

export function createAnalyticsPayloadFromAction(
  eventLabel: string,
  eventName: string,
  organizationId: string,
  integration: string,
  userIdHash: string,
  payload: any,
): GoogleAnalyticsPayload | null {
  if (!organizationId || !eventLabel || !integration || !eventName) {
    return null;
  }

  return {
    label: eventLabel,
    action: eventName,
    category: integration,
    dimension1: integration,
    dimension2: organizationId,
    dimension3: userIdHash,
    dimension4: payload,
  };
}
