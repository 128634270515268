import React, { FC, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Select, Option, MenuSection } from '@getgo/chameleon-web/react';
import { tokens } from '@getgo/chameleon-core';
import { Typography } from '../theme/Typography.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectLine } from './settings.action';
import { selectedLineSelector } from './settings.selector';
import { linesByOrganizationsSelector } from '../lines/lines.selectors';
import { makeStyles } from '@material-ui/core';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { isUnifiedCallHistoryLoadingSelector } from '../callHistoryPush/unifiedCallHistory.selector';
import clsx from 'clsx';
import { SelectComponent } from '@getgo/chameleon-web/react/src/Select/Select';

const definedMessages = defineMessages({
  NO_LINES: {
    id: 'SettingsContainer.NoLines',
    defaultMessage: 'No lines',
    description: 'No lines available for the user',
  },
});

const trackingEvents = defineTrackingEvents({
  LINE_CHANGED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemChanged,
    label: 'Lines | select',
  },
});

const useStyles = makeStyles(() => ({
  selectPlaceholder: {
    color: tokens.text03,
  },
}));

export interface LinesSelectProps {
  helperText?: string;
  error?: boolean;
}

export const LinesSelect: FC<LinesSelectProps> = ({ helperText, error }) => {
  const dispatch = useDispatch();
  const linesByOrganizations = useSelector(linesByOrganizationsSelector);
  const isCallHistoryLoading = useSelector(isUnifiedCallHistoryLoadingSelector);
  const currentLine = useSelector(selectedLineSelector);
  const classes = useStyles();
  const selectRef = useRef<SelectComponent>(null);
  useEffect(() => {
    setTimeout(() => {
      const firstOrganization = linesByOrganizations[0];
      const firstLine = firstOrganization.lines[0];
      const line = currentLine ?? firstLine;
      if (selectRef.current && line) {
        selectRef.current.displayValue = `${line.number} - ${line.name} (${line.organization.name})`;
      }
    });
  }, [currentLine, linesByOrganizations]);

  if (!linesByOrganizations || linesByOrganizations.length === 0) {
    return (
      <Typography>
        <FormattedMessage {...definedMessages.NO_LINES} />
      </Typography>
    );
  }

  return (
    <Select
      className={clsx(!currentLine && classes.selectPlaceholder)}
      disabled={isCallHistoryLoading}
      fullwidth
      ref={selectRef}
      name="lines"
      id="line-select"
      aria-labelledby="line-select-label"
      data-cy="line-select"
      value={currentLine?.id ?? ''}
      helperText={helperText}
      error={error}
      onChange={(event) => {
        dispatch(selectLine(String(event.target.value)));
        newTracker.trackAnalyticsEvent(trackingEvents.LINE_CHANGED);
      }}
    >
      {linesByOrganizations.map(({ id, name, lines }) => [
        <MenuSection key={id}>{name}</MenuSection>,
        ...lines.map((line) => <Option key={line.id} value={line.id}>{`${line.number} - ${line.name}`}</Option>),
      ])}
    </Select>
  );
};
