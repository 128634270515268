import { tracker } from './analytics/tracker';
import { history } from './browserHistory';
import configuration from './config';
import { AppState } from './reducers';
import { newTracker } from './analytics-new/tracker-new';
import ReactGA, { GaOptions } from 'react-ga';

export const initAnalytics = (getState: () => AppState) => {
  if (!configuration.analytics.trackingId || !configuration.analytics.newTrackingId || !getState) {
    throw new Error('Analytics initialization was not successful!');
  }

  const newTrackerName = 'tracker';
  const cookieSettings: GaOptions = { cookieFlags: 'secure;samesite=none' };
  ReactGA.initialize(
    [
      { trackingId: configuration.analytics.trackingId, gaOptions: cookieSettings },
      { trackingId: configuration.analytics.newTrackingId, gaOptions: { name: newTrackerName, ...cookieSettings } },
    ],
    {
      alwaysSendToDefaultTracker: false,
    },
  );

  tracker.initialize(getState);
  newTracker.initialize(ReactGA, newTrackerName, configuration.analytics.appVersion, getState);

  history.listen(() => {
    tracker.trackPageView(window.location.pathname + window.location.search);
  });
};
