import { getActionFacade } from '../actionFacade/action.facade.store';
import { NotificationChannelEvent } from '../notification-channel/models';
import { PresenceAppearance } from './presence.models';

export const presencePushEventHandler = () => async (event: NotificationChannelEvent) => {
  if (event.data.type !== 'presence') {
    return;
  }

  const { appearance } = event.data.content as { appearance: PresenceAppearance };
  await getActionFacade().setPresence(appearance);
};
