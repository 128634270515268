import {
  ChannelEvent,
  CHANNEL_NOT_CREATED,
  NETWORK_NOT_RESPONDING,
  NETWORK_OFFLINE,
  REQUEST_TIMEOUT,
  SESSION_LOST,
  UNAVAILABLE,
  UNHANDLED_MESSAGE,
  WEBSOCKET_CLOSE,
  WEBSOCKET_ERROR,
} from './channel-event';
import { EventEmitter } from 'events';
import {
  ChannelRequest,
  ChannelResponse,
  ChannelInfo,
  ChannelRefreshInfo,
  RTCError,
  NotificationChannelEvent,
} from './models';
import { Interval, Timer } from './helpers';
import { logger } from '../logging';
import axios from 'axios';

const stopTimer = (timerOrInterval: Timer | Interval | null) => {
  timerOrInterval?.stop();
};

export const isChannelResponse = (obj: unknown): obj is ChannelResponse =>
  typeof obj === 'object' && !!obj && 'code' in obj && 'correlationId' in obj && 'data' in obj;

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export abstract class Channel<TChannelInfo extends ChannelInfo> extends EventEmitter {
  private webSocket: WebSocket | null = null;
  private _url: string | null = null;
  private disconnectTimeout: Timer | null = null;
  private pingInterval: Interval | null = null;
  private existingSession = false;
  private correlationId = 0;
  private attempts = 0;
  private isOnline = navigator.onLine;
  private isTerminated = false;
  private refreshTimeout: Timer | null = null;

  private readonly requests = new Map<number, ChannelRequest>();
  private static readonly PING_COMMAND = 'ping';
  private static readonly REQUEST_TIMEOUT_INTERVAL_MS = 10000;
  private static readonly PING_INTERVAL_MS = 8000;
  private static readonly PING_TIMEOUT_INTERVAL_MS = 4000;
  private static readonly REFRESH_THRESHOLD_IN_SECONDS = 20;
  // Issued when a WS fails to connect, or disconnects abnormally (including 404)
  private static readonly ABNORMAL_CLOSE_CODE = 1006;
  // 	CloseEvent.code: Available for use by applications.
  private static readonly REFRESH_CLOSE_CODE = 4000;
  private static readonly LIFETIME_ELAPSED = 'Lifetime elapsed';
  private static readonly KICKED_BY_NEWER_CONNECTION = 'Kicked by newer connection';

  protected static readonly DISCONNECTED_TIMEOUT_MS = 1000;
  protected static readonly DEFAULT_CHANNEL_LIFETIME = 3600;
  protected reconnectTimeout: Timer | null = null;

  public id = '';
  public channelInfo!: TChannelInfo;
  public isConnected = false;

  constructor(protected readonly externalUserKey: string) {
    super();
    window.addEventListener('online', this.onlineStatusChanged);
    window.addEventListener('offline', this.onlineStatusChanged);
  }

  release() {
    this.close();
    window.removeEventListener('online', this.onlineStatusChanged);
    window.removeEventListener('offline', this.onlineStatusChanged);
  }
  /**
   * Creates a new channel and connects to it via a websocket
   */
  async start() {
    await this.createChannel();

    this.open();
  }

  /**
   * Closes the websocket connection
   */
  stop() {
    this.close();
  }

  set url(url: string | null) {
    if (this._url !== url) {
      this._url = url;
      this.resetWebSocket();
      this.refresh();
    }
  }

  get url() {
    return this._url;
  }

  protected async createChannel() {
    if (this.channelInfo) {
      try {
        const channelURL = this.getChannelDeleteURL(this.channelInfo);
        logger.debug('Deleting previous channel', { channelInfo: this.channelInfo });

        await axios.request<TChannelInfo>({
          url: channelURL,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        logger.debug('Deleting previous channel succeeded', { channelInfo: this.channelInfo });
      } catch (e) {
        logger.warn('Could not delete previous channel', e);
      }
    }

    try {
      const channelInfoUrl = this.getChannelInfoURL();

      this.channelInfo = (
        await axios.request<TChannelInfo>({
          url: channelInfoUrl,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            channelLifetime: Channel.DEFAULT_CHANNEL_LIFETIME,
          },
        })
      ).data;

      this.url = this.getChannelURL(this.channelInfo);
      this.id = this.getChannelID(this.channelInfo);

      if (!this.id) {
        // eslint-disable-next-line
        throw {
          errorCode: 'INVALID_RESOURCE_URL',
          message: 'Invalid Resource URL from notification channel',
        };
      }

      logger.debug('Loaded channel info', this.channelInfo);
    } catch (error) {
      logger.warn('Failed to fetch notificationChannelInfo', error);
      stopTimer(this.reconnectTimeout);
      this.reconnectTimeout = new Timer(() => this.open(), Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000);
    }
  }

  protected abstract getChannelURL(channelInfo: TChannelInfo): string;
  protected abstract getChannelID(channelInfo: TChannelInfo): string;
  protected abstract getChannelDeleteURL(channelInfo: TChannelInfo): string;
  protected abstract getChannelRefreshInfoURL(): string;
  protected abstract getChannelInfoURL(): string;

  protected open = () => {
    if (this.isTerminated) {
      return;
    }

    if (!this.isOnline) {
      logger.debug(`Connection offline, next reconnecting attempt in ${Channel.DISCONNECTED_TIMEOUT_MS}ms`);

      stopTimer(this.reconnectTimeout);
      this.reconnectTimeout = new Timer(() => this.open(), Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000);

      return;
    }

    if (!this._url) {
      this.doChannelError(CHANNEL_NOT_CREATED);
      return;
    }

    logger.debug('Opening a new connection', { url: this._url });
    this.startDisconnectTimeout();

    this.createWebSocket();
  };

  private createWebSocket() {
    // setting the this.ws to null is important because otherwise sometimes chrome does not release the previous WS
    this.webSocket = null;

    if (!this._url) {
      this.doChannelError(CHANNEL_NOT_CREATED);
      return;
    }

    try {
      this.webSocket = new WebSocket(this._url);
    } catch (wsEvent) {
      logger.warn('Error creating websocket connection', wsEvent);
      this.doChannelError(WEBSOCKET_ERROR);
      return;
    }

    this.webSocket.onopen = () => this.onOpenHandler();
    this.webSocket.onclose = (e) => this.onCloseHandler(e);
    this.webSocket.onerror = () => this.onErrorHandler();
    this.webSocket.onmessage = (e) => this.onMessageHandler(e);
  }

  private startDisconnectTimeout() {
    stopTimer(this.disconnectTimeout);

    this.disconnectTimeout = new Timer(() => {
      const error: RTCError = !this.isOnline ? NETWORK_OFFLINE : NETWORK_NOT_RESPONDING;
      this.updateConnectionState(false, error);
    }, Channel.REQUEST_TIMEOUT_INTERVAL_MS + getRandomInt(1, 5) * 1000);
  }

  protected clearRequests(reason = REQUEST_TIMEOUT) {
    this.requests.forEach((promise) => promise.reject(reason));
    this.requests.clear();
  }

  protected resetWebSocket() {
    if (this.webSocket) {
      stopTimer(this.reconnectTimeout);
      stopTimer(this.pingInterval);
      this.clearRequests();
    }
  }

  protected close() {
    this.isTerminated = true;

    stopTimer(this.pingInterval);
    stopTimer(this.disconnectTimeout);
    stopTimer(this.reconnectTimeout);

    try {
      this.webSocket?.close(1000);
      this.webSocket = null;
    } catch (error) {
      logger.warn('Error closing the notification-channel websocket', error);
    }

    this.requests.forEach((promise) => promise.requestTimer.stop());
    this.requests.clear();
  }

  private refresh() {
    this.refreshTimeout?.stop();
    this.refreshTimeout = new Timer(
      () => this.refreshChannel(),
      // Refresh should really not even be called if we don't have a channel (and its info)
      (this.channelInfo.channelLifetime - Channel.REFRESH_THRESHOLD_IN_SECONDS - getRandomInt(1, 5)) * 1000,
    );
  }

  private refreshChannel(): Promise<void> {
    const refreshUrl = this.getChannelRefreshInfoURL();
    return axios
      .request<ChannelRefreshInfo>({
        url: refreshUrl,
        method: 'PUT',
      })
      .then((response) => response.data)
      .then(({ channelLifetime }) => {
        this.channelInfo = {
          ...this.channelInfo,
          channelLifetime,
        };
        this.refresh();
      })
      .catch((e) => {
        logger.warn('Could not refresh notification channel', e);
        // TODO: We probably should add a retry logic here... It would be a better fail safe than what we currently have
        stopTimer(this.reconnectTimeout);
        this.reconnectTimeout = new Timer(
          () => this.start(),
          (this.channelInfo.channelLifetime + getRandomInt(1, 5)) * 1000,
        );
      });
  }

  private sendPingCommand() {
    this.correlationId++;

    const request = {
      correlationId: this.correlationId,
      command: Channel.PING_COMMAND,
    };

    return new Promise<unknown>((resolve, reject) => {
      const timeout = () => {
        this.requests.delete(request.correlationId);
        return reject(REQUEST_TIMEOUT);
      };

      const requestTimer = new Timer(timeout, Channel.PING_TIMEOUT_INTERVAL_MS);

      const promise: ChannelRequest = {
        resolve,
        reject,
        command: Channel.PING_COMMAND,
        requestTimer,
      };

      this.requests.set(this.correlationId, promise);
      this.webSocket?.send(JSON.stringify(request));
    });
  }

  async onOpenHandler() {
    logger.debug('Connection established');

    this.attempts = 0;

    stopTimer(this.reconnectTimeout);
    stopTimer(this.disconnectTimeout);
    stopTimer(this.pingInterval);

    try {
      this.updateConnectionState(true);

      this.requests.forEach((promise) => promise.requestTimer.resume());

      this.existingSession = true;
      this.createPingInterval();
    } catch (error) {
      logger.error('Channel authentication failure:', error);
      this.existingSession = false;
      this.clearRequests(error);

      if (error.errorCode === UNAVAILABLE.errorCode) {
        if (error.constraintViolations && error.constraintViolations.length > 0) {
          if (error.constraintViolations[0].constraint) {
            const retryAfter = Number.parseInt(error.constraintViolations[0].constraint, 10);
            stopTimer(this.reconnectTimeout);
            this.reconnectTimeout = new Timer(() => this.onOpenHandler(), (retryAfter + getRandomInt(1, 5)) * 1000);
          }
        }
      }

      delete error.constraintViolations;
      this.updateConnectionState(false, error);
    }
  }

  private onErrorHandler() {
    // We don't need to log these errors. According to the specs websocket error events emitted before close events.
    // No description can be found here about the error reason
    // https://html.spec.whatwg.org/multipage/web-sockets.html#feedback-from-the-protocol%3Aconcept-websocket-closed
    this.doChannelError(WEBSOCKET_ERROR);
  }

  private onCloseHandler({ code, reason }: CloseEvent) {
    let needNewUrl = false;

    switch (true) {
      case this.isTerminated:
      case this.webSocket?.readyState === 1:
      case reason === Channel.KICKED_BY_NEWER_CONNECTION:
        return;

      case code === Channel.REFRESH_CLOSE_CODE:
        stopTimer(this.refreshTimeout);
        this.reconnectTimeout = new Timer(
          () => this.open(),
          Channel.DISCONNECTED_TIMEOUT_MS + getRandomInt(1, 5) * 1000,
        );
        break;

      case code === Channel.ABNORMAL_CLOSE_CODE:
        needNewUrl = true;
        break;

      case reason === Channel.LIFETIME_ELAPSED:
        this.isTerminated = true;
        break;
    }

    this.attempts++;

    if (this.attempts > 1) {
      this.existingSession = false;
      this.requests.forEach((promise) => promise.reject(SESSION_LOST));
      this.requests.clear();
    }

    if (this.attempts >= 2) {
      this.updateConnectionState(false, WEBSOCKET_CLOSE);
      // We know at this point that we need to create a brand new channel
      needNewUrl = true;
    }

    stopTimer(this.reconnectTimeout);

    stopTimer(this.pingInterval);

    const attemptDelayInSec = this.attempts > 5 ? 30 : 2 ** this.attempts;
    const delayInMs = (attemptDelayInSec + getRandomInt(1, 5)) * 1000;

    let callback: (() => Promise<void> | void) | undefined;

    if (needNewUrl) {
      callback = () => this.start();
    } else if (this.isOnline) {
      callback = () => this.open();
    }

    if (typeof callback === 'function') {
      this.reconnectTimeout = new Timer(callback, delayInMs);
    }
  }

  onMessageHandler(event: MessageEvent) {
    try {
      const data = JSON.parse(event.data);
      if (isChannelResponse(data)) {
        if (!this.requests.has(data.correlationId)) {
          this.doChannelError(UNHANDLED_MESSAGE);
          return;
        }

        const promise = this.requests.get(data.correlationId);

        if (promise) {
          if (data.code >= 200 && data.code < 300) {
            if (promise.command !== Channel.PING_COMMAND) {
              logger.debug('Receiving correlated message', data);
            }

            promise.resolve(data.data);
          } else {
            const error = data.error;

            logger.error('Notification channel message receiving error', error);
            promise.reject(error);
          }
        }

        this.requests.delete(data.correlationId);
        return;
      }
      this.doChannelMessage(data);
    } catch (error) {
      this.doChannelError({
        errorCode: 'INVALID_JSON',
        message: error,
      });
    }
  }

  private onlineStatusChanged = (event: Event) => {
    logger.debug('Network condition', { type: event.type });

    if (event.type !== 'online') {
      this.isOnline = false;
      stopTimer(this.pingInterval);
      this.requests.forEach((promise) => promise.requestTimer.pause());
      this.updateConnectionState(false, NETWORK_OFFLINE);
      return;
    }

    this.isOnline = true;

    if (!this.webSocket) {
      this.open();
    }

    stopTimer(this.pingInterval);

    void this.ping();
    this.createPingInterval();
  };

  private handlePingInterval = () => {
    void this.ping();
  };

  private createPingInterval() {
    this.pingInterval = new Interval(this.handlePingInterval, Channel.PING_INTERVAL_MS);
  }

  private async ping() {
    if (!this.isOnline) {
      return;
    }

    try {
      await this.sendPingCommand();
      this.updateConnectionState(true);
    } catch (error) {
      if (this.isConnected) {
        this.updateConnectionState(false, error);
      }

      stopTimer(this.pingInterval);

      this.clearRequests();

      this.open();
    }
  }

  private updateConnectionState(isConnected: boolean, error?: RTCError) {
    if (isConnected) {
      if (this.isConnected !== isConnected) {
        this.isConnected = isConnected;
        this.doChannelConnected();
      }
    } else {
      this.isConnected = isConnected;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.doChannelDisconnected(error!); // assume that if we're not connected then we have an error
    }
  }

  protected doChannelMessage(data: NotificationChannelEvent) {
    this.emit(ChannelEvent.MESSAGE, data);
  }

  protected doChannelError(error: RTCError) {
    this.emit(ChannelEvent.ERROR, error);
  }

  protected doChannelConnected() {
    this.emit(ChannelEvent.CONNECTED, this.existingSession);
  }

  protected doChannelDisconnected(error: RTCError) {
    this.emit(ChannelEvent.DISCONNECTED, error);
  }
}
