import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { goToCallPage } from '../../browserHistory';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { RedtailActivityAddModel, RedtailCrmUri } from '../redtail.model';
import { redtailService } from '../redtail.service';
import {
  addRedtailActivityError,
  addRedtailActivityRequest,
  addRedtailActivitySuccess,
  openRedtailActivityError,
} from './redtail.activity.actions';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';

const definedMessages = defineMessages({
  ADD_ACTIVITY_ERROR: {
    id: 'Redtail.Activity.Add.Error',
    defaultMessage: 'An error occurred while creating activity.',
  },
  ADD_ACTIVITY_SUCCESS: {
    id: 'Redtail.Activity.Add.Success',
    defaultMessage: 'Activity created successfully!',
  },
  OPEN_ACTIVITY_ERROR: {
    id: 'Redtail.Activity.Open.Error',
    defaultMessage: 'An error occurred while opening the activity.',
  },
});

export class RedtailActivityActionCreator {
  constructor(private store: Store<AppState>) {}

  public async addActivity(activtyAddModel: RedtailActivityAddModel): Promise<void> {
    const parametersAreProvided =
      !!activtyAddModel.subject &&
      !!activtyAddModel.contactId &&
      !!activtyAddModel.startDate &&
      !!activtyAddModel.endDate;

    if (!parametersAreProvided) {
      logger.error('Not all parameters are provided for activity creation', activtyAddModel);
      this.store.dispatch(
        addRedtailActivityError({
          message: definedMessages.ADD_ACTIVITY_ERROR,
        }),
      );
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Activity,
        action: AnalyticsAction.ActivityCreationFailed,
        label: 'Not all parameters are provided for activity creation',
      });
    }

    this.store.dispatch(addRedtailActivityRequest());

    try {
      const { activityId } = await redtailService.addActivity(activtyAddModel);

      this.store.dispatch(
        addRedtailActivitySuccess({
          message: definedMessages.ADD_ACTIVITY_SUCCESS,
        }),
      );
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Activity,
        action: AnalyticsAction.ActivityCreationSucceeded,
        label: activityId || 'activity id not defined',
      });

      goToCallPage(activtyAddModel.callId);
      this.openActivityInCrm(activityId);
    } catch (error) {
      logger.error('Activity creation failed in redtail', error);
      this.store.dispatch(
        addRedtailActivityError({
          message: definedMessages.ADD_ACTIVITY_ERROR,
        }),
      );
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Activity,
        action: AnalyticsAction.ActivityCreationFailed,
        label: error.message || error.statusText || 'Unknown error while creating activity.',
      });
    }
  }

  public openActivityInCrm(id: string): void {
    if (id === undefined || id === '') {
      logger.error('Tried to open activity without id.');
      this.store.dispatch(
        openRedtailActivityError({
          message: definedMessages.OPEN_ACTIVITY_ERROR,
        }),
      );
      return;
    }

    try {
      const urlToOpen = RedtailCrmUri.ACTIVITY(id);
      window.open(urlToOpen, '_blank', 'openInDefaultBrowser');
    } catch (error) {
      logger.error(`Could not open contact with id`, error, { id });
      this.store.dispatch(
        openRedtailActivityError({
          message: definedMessages.OPEN_ACTIVITY_ERROR,
        }),
      );
    }
  }
}
