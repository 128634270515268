import React, { FC } from 'react';
import { Typography as ChamTypography, TypographyProps as ChamTypographyProps } from '@getgo/chameleon-web/react';
import { CustomTypography } from './CustomTypography.component';

export interface TypographyProps extends Omit<ChamTypographyProps, 'variant'> {
  variant?: ChamTypographyProps['variant'] | 'heading-xsmall' | 'body-xsmall';
}

export const Typography: FC<TypographyProps> = ({ variant = 'body-small', ...props }: TypographyProps) => {
  if (variant === 'heading-xsmall' || variant === 'body-xsmall') {
    return <CustomTypography variant={variant} {...props} />;
  }
  return <ChamTypography variant={variant} {...props} />;
};
