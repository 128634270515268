import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { Customer } from './cox.customer.models';

export enum CustomerActionsType {
  LOAD_CUSTOMER = 'customer/load',
  LOAD_CUSTOMER_SUCCESS = 'customer/load/success',
  LOAD_CUSTOMER_ERROR = 'customer/load/error',
}

export const loadCustomer = makeActionCreator(CustomerActionsType.LOAD_CUSTOMER)();
export const loadCustomerSuccess = makeActionCreator(CustomerActionsType.LOAD_CUSTOMER_SUCCESS)<{
  customer: Customer;
}>();
export const loadCustomerError = makeActionCreator(CustomerActionsType.LOAD_CUSTOMER_ERROR)<WithMessage>();

export type CustomerActions =
  | ReturnType<typeof loadCustomer>
  | ReturnType<typeof loadCustomerSuccess>
  | ReturnType<typeof loadCustomerError>;
