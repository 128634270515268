import React, { ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import { CallWithContact } from '../calls/calls.reducer';
import { ActionButton } from '../components/ActionButton.component';
import { EntityType } from '../constants';
import { Entity } from '../models';
import { CreateEntityActionPayload } from '../search/entities.action';

const definedMessages = defineMessages({
  CREATE_CONTACT: {
    id: 'Call.CreateContact',
    defaultMessage: 'Create contact',
    description: 'Open new contact create view',
  },
  NEW_LEAD: {
    id: 'Call.NewLead',
    defaultMessage: 'New lead',
    description: 'Open new lead create view',
  },
  NEW_ACCOUNT: {
    id: 'Call.NewAccount',
    defaultMessage: 'New account',
    description: 'Open new account create view',
  },
  NEW_NOTE: {
    id: 'Contact.NewNote',
    defaultMessage: 'New note',
    description: 'Open new note create view',
  },
  NEW_CASE: {
    id: 'Call.NewCase',
    defaultMessage: 'New case',
    description: 'Open new case create view',
  },
  NEW_COMMUNICATION: {
    id: 'Call.NewCommunication',
    defaultMessage: 'New communication',
    description: 'Open new communication create view',
  },
  NEW_REDTAIL_ACTIVITY: {
    id: 'Call.NewRedtailActivity',
    defaultMessage: 'New activity',
  },
  NEW_CONNECTWISE_ACTIVITY: {
    id: 'Call.NewRedtailActivity',
    defaultMessage: 'New activity',
  },
  NEW_CONNECTWISE_OPPORTUNITY: {
    id: 'Call.NewOpportunity',
    defaultMessage: 'New opportunity',
  },
  NEW_CONNECTWISE_SERVICE_TICKET: {
    id: 'Call.NewServiceTicket',
    defaultMessage: 'New service ticket',
  },
  CALL_LOG: {
    id: 'Call.CallLog',
    defaultMessage: 'Log your activity',
  },
});

const getDescription = (type: EntityType) => {
  switch (type) {
    case EntityType.ACCOUNT: {
      return definedMessages.NEW_ACCOUNT;
    }
    case EntityType.CASE: {
      return definedMessages.NEW_CASE;
    }
    case EntityType.CONTACT: {
      return definedMessages.CREATE_CONTACT;
    }
    case EntityType.LEAD: {
      return definedMessages.NEW_LEAD;
    }
    case EntityType.NOTE: {
      return definedMessages.NEW_NOTE;
    }
    case EntityType.COMMUNICATION: {
      return definedMessages.NEW_COMMUNICATION;
    }
    case EntityType.REDTAIL_ACTIVITY: {
      return definedMessages.NEW_REDTAIL_ACTIVITY;
    }
    case EntityType.CONNECTWISE_ACTIVITY: {
      return definedMessages.NEW_CONNECTWISE_ACTIVITY;
    }
    case EntityType.CONNECTWISE_OPPORTUNITY: {
      return definedMessages.NEW_CONNECTWISE_OPPORTUNITY;
    }
    case EntityType.CONNECTWISE_SERVICE_TICKET: {
      return definedMessages.NEW_CONNECTWISE_SERVICE_TICKET;
    }
    case EntityType.CALL_LOG: {
      return definedMessages.CALL_LOG;
    }
    case EntityType.OPPORTUNITY: {
      return definedMessages.NEW_CONNECTWISE_OPPORTUNITY;
    }
  }
};

export interface NewEntityActionStateProps {
  isActiveCall: boolean;
  call: CallWithContact;
  isSingleMatch: boolean;
  entity: Entity | undefined;
}

export interface NewEntityActionDispatchProps {
  newEntityAction: (payload: CreateEntityActionPayload) => void;
}

export interface NewEntityActionProps {
  actionOverride?: () => void;
  renderOnlyIfContactAssigned?: boolean;
  entityType: EntityType;
  icon: ReactNode;
  actionClass: string;
  trackingEvent: TrackingEvent;
}

const NewEntityAction: React.FC<NewEntityActionStateProps & NewEntityActionDispatchProps & NewEntityActionProps> = ({
  actionOverride,
  entityType,
  call,
  newEntityAction,
  renderOnlyIfContactAssigned,
  icon,
  isSingleMatch,
  entity,
  actionClass,
  isActiveCall,
  trackingEvent,
}) => {
  const phoneNumber = getNumberOfTheOtherParty(call);

  let shouldRenderAction: boolean = true;

  if (renderOnlyIfContactAssigned && isSingleMatch) {
    if (entityType === EntityType.CASE) {
      if (entity && entity.type === EntityType.CONTACT) {
        shouldRenderAction = true;
      } else {
        shouldRenderAction = false;
      }
    } else {
      shouldRenderAction = true;
    }
  } else if (!renderOnlyIfContactAssigned) {
    shouldRenderAction = true;
  } else {
    shouldRenderAction = false;
  }

  if (shouldRenderAction) {
    return (
      <ActionButton
        actionClass={actionClass}
        action={{
          icon,
          action: actionOverride
            ? () => actionOverride()
            : () =>
                newEntityAction({
                  isActiveCall,
                  entityName: entityType,
                  defaultFieldValues: renderOnlyIfContactAssigned ? { ContactId: entity!.id } : { Phone: phoneNumber },
                  callId: call.id,
                }),
          description: getDescription(entityType),
        }}
        trackingEvent={trackingEvent}
      />
    );
  } else {
    return null;
  }
};

export default NewEntityAction;
