import { logger } from '../logging';
import { sessionManager } from './JiveRtcSessionManager';
import { softphoneSynchronizer } from './SoftphoneSynchronizer';
import { openMicrophonePermissionsPage } from './microphonePermission.service';

export class SoftphoneActionCreator {
  async answerCall(softphoneCallId: string): Promise<void> {
    try {
      await sessionManager.holdOtherCalls(softphoneCallId);
      await softphoneSynchronizer.holdOtherCalls();
      await sessionManager.answerCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to answer call', error);
    }
  }

  async hangupCall(softphoneCallId: string): Promise<void> {
    try {
      await softphoneSynchronizer.hangupCall(softphoneCallId);
      await sessionManager.hangupCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to hangup call', error);
    }
  }

  async holdCall(softphoneCallId: string): Promise<void> {
    try {
      await softphoneSynchronizer.holdCall(softphoneCallId);
      await sessionManager.holdCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to hold call', error);
    }
  }

  async makeCall(callee: string): Promise<void> {
    try {
      await sessionManager.holdOtherCalls();
      await softphoneSynchronizer.holdOtherCalls();
      await sessionManager.makeCall(callee);
    } catch (error) {
      logger.error('Failed to make a call', error);
    }
  }

  async muteCall(softphoneCallId: string): Promise<void> {
    try {
      await softphoneSynchronizer.muteCall(softphoneCallId);
      await sessionManager.muteCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to mute call', error);
    }
  }

  async rejectCall(softphoneCallId: string): Promise<void> {
    try {
      await softphoneSynchronizer.rejectCall(softphoneCallId);
      await sessionManager.rejectCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to reject call', error);
    }
  }

  async resumeCall(softphoneCallId: string): Promise<void> {
    try {
      await sessionManager.holdOtherCalls(softphoneCallId);
      await softphoneSynchronizer.holdOtherCalls();
      await softphoneSynchronizer.resumeCall(softphoneCallId);
      await sessionManager.resumeCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to resume call', error);
    }
  }

  async unmuteCall(softphoneCallId: string): Promise<void> {
    try {
      await softphoneSynchronizer.unmuteCall(softphoneCallId);
      await sessionManager.unmuteCall(softphoneCallId);
    } catch (error) {
      logger.error('Failed to unmute call', error);
    }
  }

  async sendDtmf(softphoneCallId: string, value: string): Promise<void> {
    try {
      await softphoneSynchronizer.sendDtmf(softphoneCallId, value);
      await sessionManager.sendDtmf(softphoneCallId, value);
    } catch (err: any) {
      logger.error('Failed to send dtmf to call.', err);
    }
  }

  async openMicrophonePermissionPage(): Promise<void> {
    return Promise.resolve(openMicrophonePermissionsPage());
  }
}
