import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { GetMoreCasesRequestState, GetCasesRequestState } from './case.models';
import { SalesforceCase } from '../../../../salesforce-shared/salesforce-service.models';

export const casesListSelector = (state: AppState): SalesforceCase[] =>
  state.salesforce.cases && state.salesforce.cases && state.salesforce.cases.cases;

export const casesCountSelector = createSelector(
  casesListSelector,
  (casesList) => (casesList && casesList.length) || 0,
);

export const getCasesRequestStateSelector = (state: AppState): GetCasesRequestState =>
  state.salesforce.cases.requestState;

export const getMoreCasesRequestStateSelector = (state: AppState): GetMoreCasesRequestState =>
  state.salesforce.cases.moreRequestState;

export const getCaseRequestMetaSelector = (state: AppState) => state.salesforce.cases.requestMeta;

export const canLoadMoreCasesSelector = createSelector(getCaseRequestMetaSelector, (meta) => !!meta && !!meta.next);

export const allCasesCountSelector = (state: AppState) => state.salesforce.cases.casesCount;
