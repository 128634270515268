import React, { ReactNode, FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AlertInactiveIcon, CheckIcon, InfoInactiveIcon, CloseIcon } from '@getgo/chameleon-icons/react';
import { IconButton } from '@getgo/chameleon-web/react';
import { reactStyling, tokens } from '@getgo/chameleon-core';

const useStyles = makeStyles(() => {
  const {
    banner,
    bannerIcon: icon,
    bannerDanger: danger,
    bannerInfo: info,
    bannerDark: neutral,
    bannerSuccess: success,
  } = reactStyling(undefined as any);
  return {
    icon,
    danger,
    info,
    success,
    neutral,
    banner: {
      ...banner,
      height: 'auto',
      flexGrow: 1,
    },
    bannerContent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '12px 0',
    },
    bannerMessage: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    bannerActions: {},
    closeButton: {
      position: 'absolute',
      top: 4,
      right: 12,
      '&::part(content)': {
        '--goto-icon-color': tokens.iconColorInverted,
      },
    },
  };
});

const variantIcon = {
  danger: <AlertInactiveIcon />,
  info: <InfoInactiveIcon />,
  success: <CheckIcon />,
  neutral: null,
};

export interface BannerProps {
  className?: string;
  icon?: ReactNode;
  message?: ReactNode;
  actions?: ReactNode;
  variant: keyof typeof variantIcon;
  isClosable?: boolean;
  hasIcon?: boolean;
  onClose?: (...args: any[]) => any;
}

export const Banner: FC<BannerProps> = ({
  className,
  message,
  actions,
  icon,
  isClosable,
  hasIcon,
  onClose,
  variant = 'neutral',
}: BannerProps) => {
  // Hooks
  const classes = useStyles();

  const isIconVisible = hasIcon ?? true;

  let IconToDisplay: React.ReactNode | null = null;
  if (isIconVisible) {
    IconToDisplay = icon ? icon : variantIcon[variant];
  }

  const CloseButton = () => (
    <>
      <IconButton size="xsmall" onClick={onClose} className={classes.closeButton} variant="secondary">
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <Box className={clsx(classes[variant], classes.banner, className)}>
      <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
        <Grid item style={{ flexGrow: 1 }}>
          <Box className={classes.bannerContent}>
            <Box
              className={classes.bannerMessage}
              style={{
                marginRight: isClosable === true ? 20 : 'initial',
              }}
            >
              {message && (
                <Box display="flex" alignItems="center">
                  {IconToDisplay && (
                    <Box alignSelf="flex-start" display="grid" height="20px" style={{ placeItems: 'center' }}>
                      <Box className={clsx(classes.icon)}>{IconToDisplay}</Box>
                    </Box>
                  )}
                  <Box>{message}</Box>
                </Box>
              )}
            </Box>
            {actions && <Box className={classes.bannerActions}>{actions}</Box>}
          </Box>
        </Grid>
        {isClosable === true && <CloseButton />}
      </Grid>
    </Box>
  );
};
