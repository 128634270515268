import { logger } from '../logging';
import { RedtailContact, RedtailContactTypes } from './redtail.model';

export const getLabelFromContact = (contact: RedtailContact): string => {
  switch (contact.Type) {
    case RedtailContactTypes.Business:
    case RedtailContactTypes.Association:
    case RedtailContactTypes.Trust:
    case RedtailContactTypes.Union:
      return contact.Name;
    case RedtailContactTypes.Individual:
      return contact.MiddleName
        ? `${contact.FirstName} ${contact.MiddleName} ${contact.LastName}`
        : `${contact.FirstName} ${contact.LastName}`;
    default:
      logger.error('Unknown redtail contact type', { contactType: contact.Type });
      return '';
  }
};
