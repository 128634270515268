import React, { FC } from 'react';
import { OnboardingPage } from './OnboardingPage.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ApplicationRoute } from '../constants';
import { Box } from '@material-ui/core';
import { Typography } from '../theme/Typography.component';
import {
  isIntegrationLinkedIfNecessarySelector,
  isNarrowIntegrationSelector,
} from '../integrations/integrations.selector';
import { useSelector } from 'react-redux';
import { LinkAccount } from '../settings/link-account/LinkAccount.component';
import { StepReadySection } from './StepReadySection.component';
import { OnboardingNavigationButton } from './OnboardingNavigationButton.component';

const definedMessages = defineMessages({
  HEADING: {
    id: 'Onboarding.LetsGetStartedMessage',
    defaultMessage: `Let's get started`,
  },
  LINK_HINT: {
    id: 'Onboarding.Linking.Hint',
    defaultMessage: 'Link your account',
  },
  LINKED_MESSAGE: {
    id: 'Onboarding.Linking.AccountLinkedMessage',
    defaultMessage: 'Account linked',
  },
});

export const OnboardingLinking: FC = () => {
  const integrationLinked = useSelector(isIntegrationLinkedIfNecessarySelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);

  return (
    <OnboardingPage
      content={
        <Box>
          <Box mb={6}>
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              <FormattedMessage {...definedMessages.HEADING} />
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Box mb={integrationLinked ? 2 : 4}>
              <StepReadySection ready={integrationLinked === true}>
                <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'}>
                  <FormattedMessage
                    {...(integrationLinked ? definedMessages.LINKED_MESSAGE : definedMessages.LINK_HINT)}
                  />
                </Typography>
              </StepReadySection>
            </Box>
            <LinkAccount variant="onboarding" />
          </Box>
        </Box>
      }
      navigation={
        <Box>
          <Box mb={1}>
            <OnboardingNavigationButton
              variant="done"
              route={ApplicationRoute.SETTINGS_ROUTE}
              disabled={!integrationLinked}
            />
          </Box>
          <OnboardingNavigationButton variant="previous" route={ApplicationRoute.ONBOARDING_TEST_CALL} />
        </Box>
      }
    />
  );
};
