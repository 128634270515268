import { AmbassadorProxyAxiosInterceptor } from '../ambassador/proxy/proxy.interceptor';
import { IntegrationNames, Integrations } from '../models';
import { CoxApiUri } from './cox.service';
import { xml2js } from 'xml-js';
import { AxiosRequestConfig } from 'axios';

export class CoxAxiosInterceptor extends AmbassadorProxyAxiosInterceptor {
  protected integrationName = IntegrationNames[Integrations.Cox];
  protected providerHeaders = {
    'Content-Type': 'text/xml',
    'Provider-Accept-Encoding': 'gzip',
  };

  protected isUrlToIntercept(url: string): boolean {
    const coxApiUris = Object.values(CoxApiUri);
    return coxApiUris.some((coxUri) => url && url.includes(coxUri));
  }

  protected replaceRequestBody(request: AxiosRequestConfig) {
    request.data = `<?xml version="1.0" encoding="utf-8"?>
        <soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope">
            <soap:Header>
                <wsse:Security soap:mustUnderstand="1" xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">
                    <wsse:UsernameToken>
                        <wsse:Username>{{username}}</wsse:Username>
                        <wsse:Password>{{password}}</wsse:Password>
                    </wsse:UsernameToken>
                </wsse:Security>
            </soap:Header>
            <soap:Body>
                ${request.data}
            </soap:Body>
        </soap:Envelope>
        `;
  }

  protected parseProviderContent(content: string): any {
    const returnValue = xml2js(content, { compact: true, ignoreAttributes: true });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return returnValue['soap:Envelope']['soap:Body'];
  }
}
