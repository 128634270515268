import { ExternalLinkIcon } from '@getgo/chameleon-icons/react';
import React from 'react';
import { Entity } from '../models';
import { OpenEntityPayload } from '../search/entities.action';
import { canOpenContact, definedMessages } from './OpenEntity.common';
import { SmallIcon } from '../components/SmallIcon.component';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { CallType } from '@jive/realtime-events';
import { MenuItem } from '@getgo/chameleon-web/react';
import { FormattedMessage } from 'react-intl';

export type AnalyticsSection = 'contact-search' | 'contact-details';

export interface OpenEntityContextMenuItemProps {
  entity: Entity;
  callType?: CallType;
  analyticsSection?: AnalyticsSection;
}

export interface OpenEntityContextMenuItemDispatchProps {
  openEntityAction: (payload: OpenEntityPayload) => void;
}

const OpenEntityContextMenuItem: React.FC<OpenEntityContextMenuItemProps & OpenEntityContextMenuItemDispatchProps> = ({
  entity,
  callType,
  analyticsSection,
  openEntityAction,
}) =>
  canOpenContact(entity.source) ? (
    <MenuItem
      className={'openEntity'}
      leadingIcon={
        <SmallIcon>
          <ExternalLinkIcon />
        </SmallIcon>
      }
      onClick={() => {
        newTracker.trackAnalyticsEvent({
          // prettier-ignore
          label: `Open contact in CRM | button${analyticsSection ? ` | section ${analyticsSection}` : ''}${callType ? ` | ${callType}` : ''}`,
          action: AnalyticsAction.ItemClicked,
          category: AnalyticsCategory.Contact,
        });
        openEntityAction({ entity });
      }}
    >
      <FormattedMessage {...definedMessages.OPEN_IN_CRM} />
    </MenuItem>
  ) : null;

export default OpenEntityContextMenuItem;
