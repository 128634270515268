import { Store } from 'redux';
import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { ZendeskContactActionCreator } from './contact/zendesk.contact.actioncreator';
import { AppState } from '../reducers';
import { ZendeskService } from './zendesk.service';
import { NoteActionCreator } from '../actionFacade/actionCreators/note/note.actioncreator';
import { PresenceActionCreator } from '../presence/presence.actionCreator';
import { ZendeskPresenceActionCreator } from '../presence/zendesk.presence.actionCreator';
import { ZendeskCallLogActionCreator } from './callLog/zendesk.callLog.actionCreator';
import { TicketActionCreator } from './tickets/ticket.actioncreator';
import { SoftphoneActionCreator } from '../softphone/softphone.actioncreator';
import { ZendeskSoftphoneActionCreator } from './zendesk.softhpone.actioncreator';

export default class ZendeskActionCreatorFactory extends ActionCreatorFactory {
  private zendeskService: ZendeskService;

  constructor(store: Store<AppState>) {
    super(store);

    this.zendeskService = new ZendeskService();
  }

  public createContactActionCreator(): ContactActionCreator {
    return new ZendeskContactActionCreator(this.store, this.zendeskService);
  }

  public createNoteActionCreator(): NoteActionCreator {
    throw new Error('Method should not be implemented and called.');
  }

  public createPresenceActionCreator(): PresenceActionCreator {
    return new ZendeskPresenceActionCreator(this.store, this.zendeskService);
  }

  public createCallLogActionCreator(): ZendeskCallLogActionCreator {
    return new ZendeskCallLogActionCreator(this.store, this.zendeskService);
  }

  public createTicketActionCreator(): TicketActionCreator {
    return new TicketActionCreator(this.store, this.zendeskService);
  }

  public createSoftphoneActionCreator(): SoftphoneActionCreator {
    return new ZendeskSoftphoneActionCreator(this.zendeskService);
  }
}
