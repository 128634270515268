export enum FeatureFlagActionTypes {
  LOAD = 'featureFlag/load',
  LOAD_REQUEST = 'featureFlag/loadRequest',
  LOAD_SUCCESS = 'featureFlag/loadSuccess',
  LOAD_ERROR = 'featureFlag/loadError',
  WATCH = 'featureFlag/watch',
  UNWATCH = 'featureFlag/unwatch',
}

export interface FeatureFlag {
  organizationId: string;
  featureFlagId: string;
  isDisabled: boolean;
}

export interface FeatureFlagState {
  watched: string[];
}
