import { CallIdentity, CallStates, CallType } from '@jive/realtime-events';
import { contactFound, contactMatchingRequested } from '../actions/contacts';
import { OAuthService } from '../authentication/authentication.service';
import { getStore } from '../configureStore';
import {
  addFakeCall,
  clearSelectedLine,
  expireToken,
  invalidateToken,
  removeFakeCallLogs,
  resetHasSeenSoftphoneSettings,
  setTokenValidity,
  stopContactMatchingRequested,
} from './debug.actions';
import configuration from '../config';
import { AuthenticationEventMessages } from '../authentication/authentication.eventListener';
import { storageService, ObjectStoreNames } from '../storage/storage.service';
import { Vehicle } from '../cox/vehicles/vehicles.models';
import { CoxService } from '../cox/cox.service';
import { setShowCallDispositionForm, setSoftphoneEnabled } from '../settings/settings.action';
import { saveCallHistory } from '../calls/callEvents.action';
import { uuid } from '../uuid';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { stockPileService } from '../stockPile/stockPile.service';
import { showMessage } from '../inAppNotification/message.action';
import { MessageVariant } from '../models';
import { defineMessages } from 'react-intl';
import { syncService } from '../sync/sync.service';
import { ClioActionFacade } from '../clio/clio.action.facade';
import { ensureSingleCreativeIsOpened } from '../qualtricsSurvey/qulatricsSurvey';
import { CallWithDuration } from '../calls/calls.reducer';
import { sessionManager } from '../softphone/JiveRtcSessionManager';
import { SoftphoneSessionManager } from '../softphone/SoftphoneSessionManager';

const definedMessages = defineMessages({
  UPDATE_ACTIVE_CALL_WARNING: {
    id: 'Update.Active.Call.Warning',
    defaultMessage: 'Switch to new call',
    description: 'warning message update active call',
  },
});

declare global {
  interface Window {
    jiveDebug: Debugger;
  }
}

interface DebuggerConfig {
  makeTokenRefreshFail: boolean;
}

const DEBUG_LOCALSTORAGE_KEY = 'jiveDebug';

class Debugger {
  public get actionFacade() {
    return getActionFacade();
  }

  public get version() {
    return configuration.version;
  }

  private set config(debuggerConfig: DebuggerConfig) {
    this._config = debuggerConfig;
    try {
      localStorage.setItem(DEBUG_LOCALSTORAGE_KEY, JSON.stringify(this._config));
    } catch {
      console.warn('Failed to access localStorage');
    }
  }

  private get config() {
    return this._config;
  }
  private readonly originalTokenRefresh: any;

  private _config: DebuggerConfig = {
    makeTokenRefreshFail: false,
  };

  constructor() {
    this.originalTokenRefresh = OAuthService.startTokenRefresh;
    this.loadConfig();
    if (this.config.makeTokenRefreshFail) {
      this.makeTokenRefreshFail();
    }
  }

  showGenericQualtricsSurvey() {
    return this.showQualtricsSurvey({ callNumberSinceSurvey: 100, softphoneCallNumberSinceSurvey: 0 });
  }

  showSoftphoneSurvey() {
    return this.showQualtricsSurvey({ callNumberSinceSurvey: 0, softphoneCallNumberSinceSurvey: 10 });
  }

  private async showQualtricsSurvey(cookies: Record<string, any>) {
    Object.entries(cookies).forEach(([cookie, value]) => {
      window.document.cookie = `${cookie}=${value};max-age=31536000;secure;samesite=none`;
    });
    window.localStorage.removeItem('Q_INTER');
    window.localStorage.removeItem('QSI_ActionSetHistory');

    window.QSI.API.unload();
    await window.QSI.API.load();
    window.QSI.API.run();

    await ensureSingleCreativeIsOpened();
  }

  setCurrentCallToProcessing(value: boolean = true) {
    const { dispatch } = getStore();
    const callId = window.location.pathname.split('/')[3];

    if (!callId) {
      console.error('Could not read callid from path');
      return;
    }

    if (value) {
      dispatch(contactMatchingRequested({ callId }));
    } else {
      dispatch(stopContactMatchingRequested({ callId }));
    }
  }

  expireToken() {
    const { dispatch } = getStore();
    dispatch(expireToken());
  }

  invalidateToken() {
    const { dispatch } = getStore();
    dispatch(invalidateToken());
  }

  makeTokenRefreshFail() {
    OAuthService.startTokenRefresh = () => {
      setTimeout(async () => {
        window.postMessage({ type: AuthenticationEventMessages.REFRESH_TOKEN_ERROR }, '*');
      }, 1000);
    };
    this.config = { ...this.config, makeTokenRefreshFail: true };
  }

  restoreTokenRefresh() {
    OAuthService.startTokenRefresh = this.originalTokenRefresh;
    this.config = { ...this.config, makeTokenRefreshFail: false };
  }

  setTokenValidity(days: number) {
    const { dispatch } = getStore();
    dispatch(setTokenValidity({ tokenExpirationInDays: days }));
  }

  createVehicle(customerKey: string, vehicle: Vehicle = {} as Vehicle) {
    const defaultVehicle: Vehicle = {
      VIN: '22209898989898',
      Make: 'Ford',
      Model: 'Escort',
      ModelYear: '1987',
      VehicleCost: 2300,
      Odometer: 958123,
      DateDelivered: new Date(1987, 5, 3),
      LastServiceDate: new Date(1990, 11, 1),
      WarrantyMonths: 50,
      WarrantyMiles: 50000,
    };

    return CoxService.createVehicle(customerKey, { ...defaultVehicle, ...vehicle });
  }

  // usage: jiveDebugger.showScreenPop().incoming().noMatch()
  showScreenPop() {
    const createScreenPopper = (calltype: 'incoming' | 'outgoing' | 'missed') => () => ({
      noMatch: () => makeScreenPop(calltype, 'nomatch'),
      singleMatch: () => makeScreenPop(calltype, 'single'),
      multipleMatch: () => makeScreenPop(calltype, 'multiple'),
    });

    return {
      incoming: createScreenPopper('incoming'),
      outgoing: createScreenPopper('outgoing'),
      missed: createScreenPopper('missed'),
    };
  }

  createFakeCallLogs(count: number, caller: CallIdentity, callee?: CallIdentity) {
    const { dispatch } = getStore();

    Array.from({ length: count }).forEach(() => {
      const id = `fake-call-${uuid()}`;
      dispatch(
        saveCallHistory({
          call: {
            callee: callee ?? caller,
            caller,
            creationTime: new Date().valueOf(),
            id,
            allIds: [id],
            isClickToCall: true,
            previousStates: [CallStates.Standing, CallStates.WaitForAnswer],
            state: CallStates.Standing,
            theOtherParty: caller,
            type: CallType.MissedCall,
            duration: 0,
            endTime: new Date().valueOf() + 5_000,
            allMatches: [],
            participant: 'participant',
          },
        }),
      );
    });
  }

  removeFakeCallLogs() {
    const { dispatch } = getStore();
    dispatch(removeFakeCallLogs());
  }

  clearSelectedLine() {
    const { dispatch } = getStore();
    dispatch(clearSelectedLine());
  }

  private loadConfig() {
    let config = {};
    try {
      const persistedState = localStorage.getItem(DEBUG_LOCALSTORAGE_KEY);
      if (persistedState) {
        config = JSON.parse(persistedState);
      }
    } catch (e) {
      console.debug('error loading debug config from localStorage');
    }
    this.config = { ...this.config, ...config };
  }

  public getTransaction(store: ObjectStoreNames) {
    return storageService.transaction(store);
  }

  public setCallDispositionEnabled(isEnabled: boolean) {
    const { dispatch } = getStore();
    dispatch(setShowCallDispositionForm(isEnabled));
  }

  public loadCallHistoryItems(pageNumber: number): Promise<void> {
    const selectedOrganizationId = selectedOrganizationIdSelector(getStore().getState());
    return getActionFacade().loadCallHistoryItems(selectedOrganizationId, pageNumber);
  }

  public getBucket = stockPileService.getBucket;
  public updateBucket = stockPileService.updateBucket;
  public deleteBucket = stockPileService.deleteBucket;

  public async syncServiceTest() {
    const getDebugElement = () => {
      if (!document.getElementById('sync-service-debug')) {
        const debugElement = document.createElement('p');
        debugElement.id = 'sync-service-debug';
        document.body.prepend(debugElement);
      }

      return document.getElementById('sync-service-debug');
    };

    const canOperate = await syncService.canCurrentTabOperate('sync-service-debug', 'asd', 3000);
    if (canOperate) {
      const count = Number(localStorage.getItem('sync-service-debug') ?? 0) + 1;
      const debugElement = getDebugElement();
      if (debugElement) {
        debugElement.innerHTML = debugElement.innerHTML + String(count) + '<br/>';
      }
      localStorage.setItem('sync-service-debug', String(count));
    }
    setTimeout(() => this.syncServiceTest(), 1000);
  }

  public async loadConversations(): Promise<any> {
    return getActionFacade<ClioActionFacade>().loadConversations();
  }

  public setSoftphoneEnabled(enabled: boolean): void {
    const { dispatch } = getStore();
    dispatch(setSoftphoneEnabled(enabled));
  }

  public resetHasSeenSoftphoneSettings(): void {
    const { dispatch } = getStore();
    dispatch(resetHasSeenSoftphoneSettings());
  }

  public softphone: SoftphoneSessionManager & { init: () => Promise<void> } = {
    init: () => sessionManager.init(getStore()),
    answerCall: (callId) => sessionManager.answerCall(callId),
    hangupCall: (callId) => sessionManager.hangupCall(callId),
    holdCall: (callId) => sessionManager.holdCall(callId),
    resumeCall: (callId) => sessionManager.resumeCall(callId),
    makeCall: (callee) => sessionManager.makeCall(callee),
    muteCall: (callId) => sessionManager.muteCall(callId),
    rejectCall: (callId) => sessionManager.rejectCall(callId),
    subscribeToCallUpdates: (callback) => sessionManager.subscribeToCallUpdates(callback),
    unmuteCall: (callId) => sessionManager.unmuteCall(callId),
    unsubscribeFromCallUpdates: () => sessionManager.unsubscribeFromCallUpdates(),
    sendDtmf: (callId, value) => sessionManager.sendDtmf(callId, value),
    holdOtherCalls: (callId) => sessionManager.holdOtherCalls(callId),
  };
}

const makeScreenPop = (
  callType: 'incoming' | 'outgoing' | 'missed' | 'declined',
  matchType: 'nomatch' | 'single' | 'multiple',
) => {
  const { dispatch } = getStore();

  const callTypeToCreate: CallType = {
    incoming: CallType.IncomingCall,
    outgoing: CallType.OutgoingCall,
    missed: CallType.MissedCall,
    declined: CallType.DeclinedCall,
  }[callType];

  const fakeCall: CallWithDuration = {
    callee: {
      name: 'A',
      number: 'B',
    },
    caller: {
      name: 'B',
      number: 'A',
    },
    theOtherParty: {
      name: 'B',
      number: '1234',
    },
    creationTime: new Date().getTime(),
    id: 'test',
    allIds: ['test'],
    isClickToCall: true,
    previousStates: [],
    state: CallStates.Ringing,
    type: callTypeToCreate,
    participant: 'participant',
  };

  dispatch(addFakeCall({ call: fakeCall }));

  dispatch(contactMatchingRequested({ callId: fakeCall.id }));
  dispatch(
    showMessage({
      id: uuid(), // async function
      type: MessageVariant.CallInfo,
      message: definedMessages.UPDATE_ACTIVE_CALL_WARNING,
      params: {
        autoHide: false,
        entityId: fakeCall.id,
      },
    }),
  );

  const contact = {
    id: '1',
    label: 'John Debug Doe',
    name: 'John Debug Doe',
    type: 'debug',
    phoneNumber: '123 debug',
  };

  const matches = {
    nomatch: [],
    single: [contact],
    multiple: [contact, contact],
  }[matchType];

  dispatch(
    contactFound({
      callId: 'test',
      allMatches: matches,
      theOtherParty: fakeCall.theOtherParty,
    }),
  );
};

window.jiveDebug = new Debugger();
