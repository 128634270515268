import React from 'react';
import { EntityType } from '../../constants';
import { CallActionButton, CallActionProps } from './CallActionButton.component';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { defineMessages } from 'react-intl';
import { HealthcareInactiveIcon } from '@getgo/chameleon-icons/react';

const definedMessage = defineMessages({
  NEW_CASE: {
    id: 'Call.NewCase',
    defaultMessage: 'New case',
  },
});

export const NewCaseButton: React.FC<Partial<CallActionProps>> = (props) => {
  return (
    <CallActionButton
      leadingIcon={<HealthcareInactiveIcon aria-hidden />}
      label={definedMessage.NEW_CASE}
      entityType={EntityType.CASE}
      variant="custom"
      trackingEvent={{
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ItemClicked,
        label: 'New case | button',
      }}
      {...props}
    />
  );
};
