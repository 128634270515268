import { Box, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { toDateStringOrDefault } from '../cox.utils';
import { DataRow } from '../DataRow.component';
import {
  ClosedRepairOrder,
  ClosedRepairOrderDetails,
  ClosedRepairOrderDetailsRequestState,
  ServiceWriter,
  ServiceWritersRequestState,
} from './repairOrders.models';
import { defineMessages } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2, 3),
      backgroundColor: tokens.uiBase01,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const definedMessages = defineMessages({
  TITLE_DATE: {
    id: 'Cox.ClosedRepairOrder.Title',
    defaultMessage: 'Close date:',
  },
  TOTAL_SALE: {
    id: 'Cox.ClosedRepairOrder.Detail.TotalSale',
    defaultMessage: 'Price charged:',
  },
  ORDER_NUMBER: {
    id: 'Cox.ServiceAppointments.Detail.OrderNumber',
    defaultMessage: 'Order number:',
  },
  ADVISOR: {
    id: 'Cox.ServiceAppointments.Detail.Advisor',
    defaultMessage: 'Advisor:',
  },
});

export interface ClosedRepairOrderProps {
  repairOrder: ClosedRepairOrder;
  repairOrderDetails?: ClosedRepairOrderDetails;
  repairOrderDetailsRequestState?: ClosedRepairOrderDetailsRequestState;
  locale: Locale;
  serviceWritersRequestState: ServiceWritersRequestState;
  serviceWriters?: ServiceWriter[];
}

export const ClosedRepairOrderComponent: React.FC<ClosedRepairOrderProps> = ({
  repairOrder,
  locale,
  repairOrderDetails,
  repairOrderDetailsRequestState,
  serviceWritersRequestState,
  serviceWriters,
}) => {
  const classes = useStyles();
  const serviceWriter = serviceWriters && serviceWriters.find((sw) => sw.id === repairOrder.serviceWriterId);
  const advisor =
    serviceWritersRequestState === ServiceWritersRequestState.INITIAL ||
    serviceWritersRequestState === ServiceWritersRequestState.LOADING ? (
      <CircularProgress size={12} />
    ) : (
      <>{serviceWriter?.name || ''}</>
    );

  const renderPriceCharged = () => {
    if (repairOrderDetailsRequestState === ClosedRepairOrderDetailsRequestState.LOADING) {
      return <CircularProgress size={12} />;
    }

    if (repairOrderDetailsRequestState === ClosedRepairOrderDetailsRequestState.FAILED) {
      return '-';
    }

    return `$${(repairOrderDetails?.priceCharged || 0).toFixed(2)}`;
  };

  return (
    <Box mb={2}>
      <div className={classes.container}>
        <Box mb={1}>
          <DataRow label={definedMessages.TITLE_DATE} value={toDateStringOrDefault(repairOrder.closeDate, locale)} />
        </Box>
        <DataRow color="text-02" label={definedMessages.ADVISOR} value={advisor} />
        <DataRow color="text-02" label={definedMessages.TOTAL_SALE} value={renderPriceCharged()} />
        <DataRow color="text-02" label={definedMessages.ORDER_NUMBER} value={repairOrder.repairOrderNumber} />
      </div>
    </Box>
  );
};
