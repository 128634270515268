import { makeActionCreator } from '../actions/actionCreators';
import { IndexedById } from '../models';
import { EntityPhoneNumber } from '../search/entities.model';

export enum PhoneNumbersActionsType {
  ADD = 'jiveEntities/phoneNumbers/add',
  CLEAN = 'jiveEntities/phoneNumbers/clean',
}

export const addPhoneNumbers = makeActionCreator(PhoneNumbersActionsType.ADD)<IndexedById<EntityPhoneNumber>>();
export const cleanPhoneNumbers = makeActionCreator(PhoneNumbersActionsType.CLEAN)();

export type PhoneNumbersActions = ReturnType<typeof addPhoneNumbers> | ReturnType<typeof cleanPhoneNumbers>;
