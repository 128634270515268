import { connect } from 'react-redux';
import { TimePicker, TimePickerStateProps, TimePickerProps } from './TimePicker.component';
import { selectedDateFnsLocaleSelector } from '../settings/settings.selector';
import { AppState } from '../reducers';

const mapStateToProps = (state): TimePickerStateProps => ({
  locale: selectedDateFnsLocaleSelector(state),
});

export default connect<TimePickerStateProps, null, TimePickerProps, AppState>(mapStateToProps)(TimePicker);
