import { Call } from '@jive/realtime-events';
import React, { useEffect, useState } from 'react';
import getDurationDisplay from '../formatCallDuration';

export interface CallDurationProps {
  call: Call;
  format?: 'hhmmss' | 'mmss';
}

export interface StateProps {
  duration: string;
}

export const CallDuration: React.FC<CallDurationProps> = ({ call, format = 'hhmmss' }) => {
  const [duration, setDuration] = useState(getDurationDisplay(call, format));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDuration(getDurationDisplay(call, format));
    }, 500);
    return () => clearInterval(intervalId);
  }, [call, format]);

  return <>{duration}</>;
};
