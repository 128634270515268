import { connect } from 'react-redux';
import { AppState } from '../../reducers';

import { getCompaniesRequestStateSelector, companyListSelector } from './companyPicker.selectors';
import { CompanyPickerComponent, CompanyPickerProps, CompanyPickerStateProps } from './CompanyPicker.component';

const mapStateToProps = (state: AppState): CompanyPickerStateProps => ({
  companies: companyListSelector(state),
  requestState: getCompaniesRequestStateSelector(state),
});

export const CompanyPicker = connect<CompanyPickerStateProps, {}, CompanyPickerProps, AppState>(mapStateToProps)(
  CompanyPickerComponent,
);
