import { Locale } from 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';

export interface AppMuiPickersUtilsProviderStateProps {
  locale: Locale;
}

export const AppMuiPickersUtilsProvider: React.FC<AppMuiPickersUtilsProviderStateProps> = ({ locale, children }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
    {children}
  </MuiPickersUtilsProvider>
);
