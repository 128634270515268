import { logger } from '../logging';

export interface LoginStateObject {
  loginSuccessPath?: string;
  loginSuccessOrigin?: string;
  integration?: string;
}

export function extractStateObject<T extends {}>(state: string): Partial<T> {
  try {
    if (state) {
      return JSON.parse(state) as T;
    }
    return {};
  } catch (e) {
    logger.error('Failed parsing state object', e);
    return {};
  }
}
