import { Box } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const definedMessages = defineMessages({
  NO_PERMISSIONS_DIALOG_BODY1: {
    id: 'Microphone.Permission.Dialog.Body1',
    defaultMessage: 'To use the built-in softphone, grant microphone permissions in your web browser settings.',
  },
  NO_PERMISSIONS_DIALOG_BODY2: {
    id: 'Microphone.Permission.Dialog.Body2',
    defaultMessage: 'Until then, you can continue using click-to-call.',
  },
});

export const NoMicPermission: React.FC = () => {
  return (
    <>
      <Box mt={4}>
        <FormattedMessage {...definedMessages.NO_PERMISSIONS_DIALOG_BODY1} />
      </Box>

      <Box mt={4} mb={4}>
        <FormattedMessage {...definedMessages.NO_PERMISSIONS_DIALOG_BODY2} />
      </Box>
    </>
  );
};
