import { GetNoteTypesRequestState, ConnectWiseNoteType } from '../../connectwise.model';
import { ConnectWiseNoteTypeActionsType, ConnectWiseNoteTypeActions } from './connectwise.notetype.actions';

export interface NoteTypeState {
  requestState: GetNoteTypesRequestState;
  noteTypes: ConnectWiseNoteType[];
}
export const INITIAL_STATE: NoteTypeState = {
  requestState: GetNoteTypesRequestState.INITIAL,
  noteTypes: [],
};

export const connectWiseNoteTypeReducer = (
  state = INITIAL_STATE,
  action: ConnectWiseNoteTypeActions,
): NoteTypeState => {
  switch (action.type) {
    case ConnectWiseNoteTypeActionsType.NOTETYPE_REQUEST:
      return {
        ...state,
        noteTypes: [],
        requestState: GetNoteTypesRequestState.LOADING,
      };
    case ConnectWiseNoteTypeActionsType.NOTETYPE_GET_SUCCESS:
      return {
        ...state,
        noteTypes: action.payload.noteTypes,
        requestState: GetNoteTypesRequestState.LOADED,
      };
    case ConnectWiseNoteTypeActionsType.NOTETYPE_GET_ERROR:
      return {
        ...state,
        noteTypes: [],
        requestState: GetNoteTypesRequestState.FAILED,
      };
    default:
      return state;
  }
};
