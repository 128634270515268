import { CallLogFormComponent, CallLogFormStateProps, CallLogFormProps } from './CallLogForm.component';
import { connect } from 'react-redux';
import { allCallSelector, getEntityInCallSelector } from '../../calls/calls.selector';
import { isCallDispositionConfiguredSelector, savedCallLogsSelector } from '../salesforce.selectors';
import { isShowCallDispositionFormEnabledSelector } from '../../settings/settings.selector';

const mapStateToProps = (state, { callId }) => ({
  call: allCallSelector(state, callId),
  entity: getEntityInCallSelector(state, callId),
  isCallDispositionPickerVisible:
    isCallDispositionConfiguredSelector(state) && isShowCallDispositionFormEnabledSelector(state),
  savedCallLog: savedCallLogsSelector(state)[callId],
  shouldShowCallDispositionError:
    !isCallDispositionConfiguredSelector(state) && isShowCallDispositionFormEnabledSelector(state),
});

export const CallLogForm = connect<CallLogFormStateProps, {}, CallLogFormProps>(mapStateToProps)(CallLogFormComponent);
