import { MiddlewareAPI } from 'redux';
import { NotificationChannelEvent } from '../notification-channel/models';
import { addUserCallHistoryItem } from './unifiedCallHistory.action';
import { callHistoryUCHArraySelector } from './unifiedCallHistory.selector';
import { activeCallsSelector } from '../calls/calls.selector';
import { areIdenticalCallHistoryItems, areIdenticalCallItems } from './callHistoryItemUtils';
import { differenceInSeconds } from 'date-fns';

export const callHistoryPushEventHandler = ({ dispatch, getState }: MiddlewareAPI) => (
  event: NotificationChannelEvent,
) => {
  if (event.data.type !== 'UchItem') {
    return;
  }

  // UCH events are unconventional
  let userCallHistoryItem = JSON.parse((event.data.content as any) as string);

  const startTime = new Date(userCallHistoryItem.startTime);
  const answerTime = new Date(userCallHistoryItem.answerTime);
  const isMissedCall = !userCallHistoryItem.answerTime || !userCallHistoryItem.duration;

  userCallHistoryItem = {
    ...userCallHistoryItem,
    startTime,
    answerTime,
    duration: isMissedCall ? 0 : userCallHistoryItem.duration / 1000,
  };

  setTimeout(() => {
    const hasIdenticalCallHistoryItem = callHistoryUCHArraySelector(getState())
      .filter(
        (callHistoryItemInState) => Math.abs(differenceInSeconds(callHistoryItemInState.startTime, startTime)) <= 2,
      )
      .some((callHistoryItemInState) => areIdenticalCallHistoryItems(callHistoryItemInState, userCallHistoryItem));

    // do not add duplicates
    if (hasIdenticalCallHistoryItem) {
      return;
    }

    const activeCalls = activeCallsSelector(getState());
    const hasIdenticalCallHistoryItemInProgress = activeCalls.some((call) =>
      areIdenticalCallItems(userCallHistoryItem, call),
    );

    // in case of multiple devices we should not add missed call items if identical in-progress call item can be found
    if (isMissedCall && hasIdenticalCallHistoryItemInProgress) {
      return;
    }

    dispatch(addUserCallHistoryItem(userCallHistoryItem));
  }, 2500);
};
