import { Message, PagedResults } from '@ucc/messaging-platform-service';
import { MessageBody } from '@ucc/messaging-service';
import { uniq } from 'lodash-es';
import { Reducer } from 'redux';
import { ActionTypes } from '../actions/actionCreators';
import * as conversationMessagesActions from './conversationMessages.actions';

export enum RequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

interface ConversationMessage extends PagedResults<Message<MessageBody>> {
  selectedMessages: string[];
  requestState: RequestState;
}

export interface ConversationMessagesState {
  allIds: string[];
  byId: Record<string, ConversationMessage>;
  conversationLogRequestState: RequestState;
}

const INITIAL_STATE: ConversationMessagesState = {
  allIds: [],
  byId: {},
  conversationLogRequestState: RequestState.INITIAL,
};

export const conversationMessagesReducer: Reducer<
  ConversationMessagesState,
  ActionTypes<typeof conversationMessagesActions>
> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case conversationMessagesActions.clearConversationMessagesAction.type: {
      return {
        ...state,
        allIds: uniq(state.allIds.concat(action.payload.conversationId)),
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            requestState: RequestState.INITIAL,
            results: [],
            selectedMessages: state.byId[action.payload.conversationId]?.selectedMessages ?? [],
          },
        },
      };
    }
    case conversationMessagesActions.loadConversationMessagesAction.type: {
      return {
        ...state,
        allIds: uniq(state.allIds.concat(action.payload.conversationId)),
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            ...state.byId[action.payload.conversationId],
            requestState: RequestState.LOADING,
          },
        },
      };
    }
    case conversationMessagesActions.loadConversationMessagesSuccessAction.type: {
      return {
        ...state,
        allIds: uniq(state.allIds.concat(action.payload.conversationId)),
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            requestState: RequestState.LOADED,
            pageIdentifier: action.payload.pageIdentifier,
            results: (state.byId[action.payload.conversationId]?.results ?? []).concat(action.payload.results),
            selectedMessages: state.byId[action.payload.conversationId]?.selectedMessages ?? [],
          },
        },
      };
    }
    case conversationMessagesActions.loadConversationMessagesErrorAction.type: {
      return {
        ...state,
        allIds: uniq(state.allIds.concat(action.payload.conversationId)),
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            requestState: RequestState.FAILED,
            results: state.byId[action.payload.conversationId]?.results ?? [],
            selectedMessages: state.byId[action.payload.conversationId]?.selectedMessages ?? [],
          },
        },
      };
    }
    case conversationMessagesActions.selectConversationMessages.type: {
      return {
        ...state,
        allIds: state.allIds,
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            ...state.byId[action.payload.conversationId],
            selectedMessages: uniq(
              (state.byId[action.payload.conversationId]?.selectedMessages ?? []).concat(
                action.payload.conversationMessageIds,
              ),
            ),
          },
        },
      };
    }
    case conversationMessagesActions.deselectConversationMessages.type: {
      return {
        ...state,
        allIds: state.allIds,
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            ...state.byId[action.payload.conversationId],
            selectedMessages: (state.byId[action.payload.conversationId]?.selectedMessages ?? []).filter(
              (id) => !action.payload.conversationMessageIds.includes(id),
            ),
          },
        },
      };
    }
    case conversationMessagesActions.sendLogMessagesRequestAction.type: {
      return {
        ...state,
        conversationLogRequestState: RequestState.LOADING,
      };
    }
    case conversationMessagesActions.sendLogMessagesRequestSuccessAction.type: {
      return {
        ...state,
        conversationLogRequestState: RequestState.LOADED,
      };
    }
    case conversationMessagesActions.sendLogMessagesRequestErrorAction.type: {
      return {
        ...state,
        conversationLogRequestState: RequestState.FAILED,
      };
    }
    case conversationMessagesActions.deselectAllConversationMessages.type: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.conversationId]: {
            ...state.byId[action.payload.conversationId],
            selectedMessages: [],
          },
        },
      };
    }
  }

  return state;
};
