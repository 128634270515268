import React from 'react';
import { GotoAppIconIcon } from '@getgo/chameleon-icons/react';

interface AppLogoProps {
  hideLogo?: boolean;
  variant?: 'narrow' | 'wide';
}

export const AppLogo: React.FC<AppLogoProps> = ({ hideLogo }) => {
  if (hideLogo) {
    return null;
  }

  return <GotoAppIconIcon size="48px" aria-hidden />;
};
