import React, { FC } from 'react';
import { getActiveRoute } from '../browserHistory';
import { personalLinkingNeededForCurrentIntegrationSelector } from '../integrations/integrations.selector';
import { useSelector } from 'react-redux';
import { ApplicationRoute } from '../constants';
import { Typography } from '../theme/Typography.component';

export interface OnboardingPageNumberProps {
  className?: string;
}

const getCurrentPageNumber = (): number => {
  const { route } = getActiveRoute(undefined, true);
  switch (route) {
    case ApplicationRoute.ONBOARDING:
      return 1;
    case ApplicationRoute.ONBOARDING_TOS:
      return 2;
    case ApplicationRoute.ONBOARDING_LOGIN:
      return 3;
    case ApplicationRoute.ONBOARDING_TEST_CALL:
      return 4;
    case ApplicationRoute.ONBOARDING_LINKING:
      return 5;
    default:
      return 1;
  }
};

export const OnboardingPageNumber: FC<OnboardingPageNumberProps> = ({ className }) => {
  const linkingNeeded = useSelector(personalLinkingNeededForCurrentIntegrationSelector);
  const allPages = linkingNeeded ? 5 : 4;
  const currentPage = getCurrentPageNumber();

  return (
    <Typography variant="body-small" color="text-02" className={className}>
      {currentPage}/{allPages}
    </Typography>
  );
};
