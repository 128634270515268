import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  UPDATE_AVAILABLE_MESSAGE_1: {
    id: 'UpdateAvailable.Message.1',
    defaultMessage: 'A new version of GoToConnect for {integrationName} Integration is available.',
  },
  UPDATE_AVAILABLE_MESSAGE_2: {
    id: 'UpdateAvailable.Message.2',
    defaultMessage: 'Please update to the new version by restarting the app.',
  },
});

export interface UpdateAvailableDialogBodyStateProps {
  integrationName: string;
}

export const UpdateAvailableDialogBody: React.FC<UpdateAvailableDialogBodyStateProps> = ({ integrationName }) => {
  return (
    <>
      <Typography style={{ fontWeight: 'bold' }} tag="span">
        <FormattedMessage {...definedMessages.UPDATE_AVAILABLE_MESSAGE_1} values={{ integrationName }} />
      </Typography>
      &nbsp;
      <Typography tag="span">
        <FormattedMessage {...definedMessages.UPDATE_AVAILABLE_MESSAGE_2} />
      </Typography>
    </>
  );
};
