import { CallEventsActionsType, listenLine, RealtimeActionTypes, startRealtime } from '@jive/realtime-events';
import { MiddlewareAPI } from 'redux';
import { isAuthenticatedSelector, jiveIdSelector, tokenSelector } from '../authentication/authentication.selector';
import { selectUserCallHistoryItemById } from '../callHistoryPush/unifiedCallHistory.selector';
import { saveCall, saveCallHistory, SaveCallPayload } from '../calls/callEvents.action';
import { getCallLogObject, calculateCallDuration } from '../calls/callLogger.utilities';
import { callSelector, contactInCallSelector, contactsByCallIdSelector } from '../calls/calls.selector';
import config from '../config';
import { RealtimeServiceActionTypes, SettingsActionTypes } from '../constants';
import { logger } from '../logging';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Entity, Integrations } from '../models';
import { AppState } from '../reducers';
import { tryStartRealtimeService } from './realtimeService.action';
import {
  injectedIntlSelector,
  selectedDateFnsLocaleSelector,
  selectedNormalizedLineSelector,
} from './settings.selector';
import { isTestCallSelector } from '../onboarding/testCall/testCall.selectors';
import { syncService } from '../sync/sync.service';
import { startNotificationChannel } from '../notification-channel/notification-channel.manager';
import { IntegrationsActionsType } from '../integrations/integrations.actions';

const CALL_LOG_SYNC_PREFIX = 'call_log_sync';

// initialize all Clio listeners and interceptor
addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (_, action) => {
  if (action.payload !== Integrations.MsTeams) {
    registerRealtimeListeners();
  }
});

let registered = false;

function registerRealtimeListeners() {
  if (registered) {
    return;
  }

  registered = true;

  function tryConnectToRealtimeAPI(state: AppState) {
    if (!isAuthenticatedSelector(state)) {
      return;
    }
    return startRealtime({ token: tokenSelector(state), realtimeApiUrl: config.realtimeApiUrl });
  }

  addSmartListener(SettingsActionTypes.SELECT_LINE)(async (store) => {
    try {
      await startNotificationChannel(store);
    } catch (e) {
      logger.error('Failed to start notification channel');
    }
    return store.dispatch(tryStartRealtimeService());
  });

  addListener(RealtimeServiceActionTypes.TRY_START_SERVICE)((state) => tryConnectToRealtimeAPI(state));

  addListener(RealtimeActionTypes.START_REALTIME)((state) => {
    const selectedLine = selectedNormalizedLineSelector(state);
    const jiveId = jiveIdSelector(state);

    if (selectedLine && jiveId) {
      const userLine = {
        id: selectedLine.id,
        number: selectedLine.number,
        name: jiveId,
        organizationId: selectedLine.organization,
      };
      return listenLine(userLine);
    }
  });

  addSmartListener(CallEventsActionsType.END_CONVERSATION)((store, action) => {
    const isTestCall = isTestCallSelector(action.payload.call.id)(store.getState());
    if (isTestCall) {
      return;
    }

    setTimeout(async () => await saveConversation(store, action.payload.call.id), 2000);
  });

  async function saveConversation(store: MiddlewareAPI, callId: string) {
    const state = store.getState();
    const contact: Entity | undefined = contactInCallSelector(state, callId);

    const matches = contactsByCallIdSelector(state, callId);
    const retrievedCall = callSelector(state, callId);

    if (!retrievedCall) {
      logger.error('Could not save CallLog, there is no call retrieved to log.');
      return;
    }

    const callHistoryItem = retrievedCall.answeredLegId
      ? selectUserCallHistoryItemById(state, retrievedCall.answeredLegId)
      : undefined;

    if (retrievedCall.answeredLegId && !callHistoryItem) {
      logger.warn('Call history item does not exists during call save.');
    }

    const intl = injectedIntlSelector(state);
    const locale = selectedDateFnsLocaleSelector(state);

    const callDurationInSeconds = calculateCallDuration(retrievedCall, callHistoryItem);
    const callToSave = { ...retrievedCall, ...matches, duration: callDurationInSeconds };

    store.dispatch(saveCallHistory({ call: callToSave }));

    if (contact) {
      let saveCallPayload: SaveCallPayload = {
        call: callToSave,
        shouldPersist: false,
      };

      const callLog = getCallLogObject({ call: saveCallPayload.call, contact, intl, locale, callDurationInSeconds });
      saveCallPayload = {
        ...saveCallPayload,
        callLog,
      };

      try {
        const canSave = await syncService.canCurrentTabOperate(CALL_LOG_SYNC_PREFIX, saveCallPayload.call.id);
        store.dispatch(saveCall({ ...saveCallPayload, shouldPersist: canSave }));
      } catch (e) {
        logger.error('Error saving call with tab sync', e);
      }
    }
  }
}
