import { defineMessages } from 'react-intl';

export const definedMessages = defineMessages({
  OPEN_ENTITY_ERROR: {
    id: 'Open.Entity.Error',
    defaultMessage: 'Entity could not open',
    description: 'error message for entity open failure',
  },
  CREATE_TICKET_SUCCESS: {
    id: 'Create.Ticket.Success',
    defaultMessage: 'Ticket created successfully!',
    description: 'success message ticket create',
  },
  CREATE_TICKET_ERROR: {
    id: 'Create.Ticket.Error',
    defaultMessage: 'There was an error while creating the ticket, please try again.',
    description: 'error message ticket create',
  },
  OPEN_TICKET_ERROR: {
    id: 'Open.Ticket.Error',
    defaultMessage: 'An error occurred while opening the ticket.',
    description: 'error message ticket open',
  },
  CREATE_CALL_DETAIL_SUCCESS: {
    id: 'Create.Call.Detail.Success',
    defaultMessage: 'Note created successfully!',
    description: 'success message call detail create',
  },
  CREATE_CALL_DETAIL_ERROR: {
    id: 'Create.Call.Detail.Error',
    defaultMessage: 'An error has occurred',
    description: 'error message call detail create',
  },
  GET_NOTES_ERROR: {
    id: 'Get.Notes.Error',
    defaultMessage: 'There was an error while downloading the notes, please try again.',
    description: 'error message get notes',
  },
  GET_USERS_ERROR: {
    id: 'Get.Users.Error',
    defaultMessage: 'There was an error while downloading the users, please try again.',
    description: 'error message get users',
  },
  GLOBAL_MESSAGE_SNACKBAR_CLOSE: {
    id: 'GlobalMessage.Snackbar.Close',
    defaultMessage: 'Close',
  },
  REALTIME_API_CONNECTION_LOST: {
    id: 'RealtimeApi.ConnectionLost.Message.Lost',
    defaultMessage: 'Connection Lost.',
  },
  REALTIME_API_CONNECTION_LOST_ATTEMPTING: {
    id: 'RealtimeApi.ConnectionLost.Attempting',
    defaultMessage: '{connectionLostMessage}Attempting to reconnect...',
  },
  REALTIME_API_CONNECTION_LOST_BUTTON_LABEL: {
    id: 'RealtimeApi.ConnectionLost.Button.Label',
    defaultMessage: 'Restart app',
  },
  LOGIN_REQUIRED_MESSAGE: {
    id: 'Login.Required.Message',
    defaultMessage: 'Your session has expired. Please sign in.',
  },
  LOGIN_REQUIRED_TITLE: {
    id: 'Login.Required.Title',
    defaultMessage: 'Session expired',
  },
  SIGN_IN: {
    id: 'SignIn.Button',
    defaultMessage: `Sign in`,
  },
  CLICK_TO_CALL_ERROR_TITLE: {
    id: 'ClickToCall.Error.Title',
    defaultMessage: "We couldn't call this number for you!",
  },
  CLICK_TO_CALL_ERROR_MESSAGE: {
    id: 'ClickToCall.Error.Message',
    defaultMessage: 'Please check that your GoToConnect phone application or GoToConnect Desktop Phone is working.',
  },
  OPEN_APP_BUTTON_LABEL: {
    id: 'Open.App.Button.Label',
    defaultMessage: 'Open app',
  },
  FORM_GENERAL_SAVE_BUTTON_TEXT: {
    id: 'Form.General.SaveButtonText',
    defaultMessage: 'Save',
  },
  FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER: {
    id: 'FilterAsYouTypeSelect.Placeholder',
    defaultMessage: 'Start typing to see suggestions',
  },
  WEBSOCKET_CONNECTION_ERROR_MESSAGE_TITLE: {
    id: 'WebsocketConnection.Error.Title',
    defaultMessage: "Can't connect to GoToConnect",
  },
  WEBSOCKET_CONNECTION_ERROR_MESSAGE_DESC: {
    id: 'WebsocketConnection.Error.Description',
    defaultMessage: 'Check your internet connection or try restarting the app.',
  },
  WEBSOCKET_CONNECTION_ERROR_MESSAGE_BUTTON_LABEL: {
    id: 'WebsocketConnection.Error.Button.Label',
    defaultMessage: 'Restart GoToConnect',
  },
});
