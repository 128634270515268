import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { FC, ReactElement } from 'react';
import { OnboardingPageNumber } from './OnboardingPageNumber.component';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { GotoIntegrationsLogo } from '../components/GotoIntegrationsLogo.component';

const useStyles = makeStyles({
  contentContainer: {
    position: 'relative',
    flexGrow: 1,
    overflowY: 'auto',
  },
  pageNumber: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: 1,
  },
});

export interface OnboardingPageProps {
  content: ReactElement;
  navigation: ReactElement;
}

export const OnboardingPage: FC<OnboardingPageProps> = ({ content, navigation }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      flexDirection="column"
      boxSizing="border-box"
      px={isNarrowIntegration ? 0 : 3}
      py={isNarrowIntegration ? 1 : 6}
    >
      <Box className={classes.contentContainer}>
        <header role="banner">
          <OnboardingPageNumber className={classes.pageNumber} />
          <Box m={0} mb={9} component="h1">
            <GotoIntegrationsLogo width={200} narrowWidth={140} />
          </Box>
        </header>
        <main role="main">{content}</main>
      </Box>
      <nav role="navigation">
        <Box mt={4}>{navigation}</Box>
      </nav>
    </Box>
  );
};
