import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { tokenSelector } from '../../authentication/authentication.selector';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { AmbassadorService } from '../ambassador.service';
import { proxyUserProviderSelector } from './proxy.selector';
import { Store } from 'redux';
import { defineMessages } from 'react-intl';
import { openDialog } from '../../dialog/dialog.actions';
import { linkAccountRequest } from '../../settings/link-account/linkAccount.action';
import { addListenersForOneTimeExecution } from '../../middlewares/listener.middleware';
import { ProxyActionsTypes } from './proxy.action';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';

const definedMessages = defineMessages({
  RELINK_REQUIRED_MESSAGE: {
    id: 'Relink.Required.Message',
    defaultMessage: 'Your {integrationName} session has expired. Please link your account again.',
  },
  RELINK_REQUIRED_TITLE: {
    id: 'Login.Required.Title',
    defaultMessage: 'Session expired',
  },
  RELINK_REQUIRED_BUTTON_LABEL: {
    id: 'Relink.Required.Button.Label',
    defaultMessage: 'Link {integrationName} Account',
  },
});

export abstract class AmbassadorProxyAxiosInterceptor {
  protected abstract integrationName: string;
  protected abstract get providerHeaders(): Object;
  private requestInterceptor: Optional<number>;
  private responseInterceptor: Optional<number>;

  constructor(protected store: Store<AppState>) {}

  register() {
    if (this.requestInterceptor === undefined) {
      this.requestInterceptor = axios.interceptors.request.use((request) => this.providerRequestInterceptor(request));
    }

    if (this.responseInterceptor === undefined) {
      this.responseInterceptor = axios.interceptors.response.use((response) => {
        return this.providerResponseInterceptor(response);
      });
    }
  }

  deregister() {
    if (this.requestInterceptor !== undefined) {
      axios.interceptors.request.eject(this.requestInterceptor);
      this.requestInterceptor = undefined;
    }

    if (this.responseInterceptor !== undefined) {
      axios.interceptors.response.eject(this.responseInterceptor);
      this.responseInterceptor = undefined;
    }
  }

  protected providerRequestInterceptor(request: AxiosRequestConfig) {
    if (!this.isUrlToIntercept(`${request.baseURL}${request.url})`)) {
      return request;
    }

    const state = this.store.getState();
    const linkParams = this.getLinkParameters();

    try {
      if (!linkParams) {
        throw new axios.Cancel('Integration not linked yet');
      }

      const token = tokenSelector(state);

      request.headers = {
        ...request.headers,
        ...AmbassadorService.generateAmbassadorHeaders(token, this.providerHeaders),
        'X-Ambassador-RetryCount': 3,
      };
      request.baseURL = AmbassadorService.getProxyUrl(linkParams.linkId);
      
      request.params = { ...request.params, providerConfigAppId: linkParams.selectedProviderId };

      this.replaceRequestBody(request);

      return request;
    } catch (e) {
      if (e instanceof axios.Cancel) {
        throw e;
      }
      logger.error(`Cannot intercept ${this.integrationName} request`, e);
    }
    return request;
  }

  protected async providerResponseInterceptor(response: AxiosResponse): Promise<AxiosResponse> {
    if (!this.isUrlToIntercept(`${response.config.baseURL}${response.config.url}`)) {
      return response;
    }

    let data: any;

    try {
      data = this.parseProviderContent(response.data.content);
    } catch (e) {
      logger.error(`Cannot parse ${this.integrationName} response`, response);
      return Promise.reject<AxiosResponse>(response);
    }

    // if response haven't been intercepted yet, transform it
    if (response.data.responseCode && response.data.reasonPhrase) {
      response = {
        ...response,
        status: response.data.responseCode,
        statusText: response.data.reasonPhrase,
        headers: {
          ...response.headers,
          originalHeaders: response.data.headers,
        },
        data,
      };
    }

    if (response.status > 399) {
      if (response.status === 401) {
        return await this.unauthorizedResponseHandler(response);
      } else {
        logger.error(`${this.integrationName} response error [${response.status}]`, response);
        return Promise.reject<AxiosResponse>(response);
      }
    }
    return response;
  }

  protected async unauthorizedResponseHandler(response: AxiosResponse): Promise<AxiosResponse> {
    const messageValues = { integrationName: this.integrationName };

    return new Promise<AxiosResponse>(async (resolve, reject) => {
      try {
        const userProvider = proxyUserProviderSelector(this.store.getState());
        const token = tokenSelector(this.store.getState());

        if (!userProvider || !token) {
          logger.error('No userProvider or token found, cannot delete user provider');
          return reject(response);
        }

        // first delete the userProvider record, AKA `unlink account`
        await AmbassadorService.deleteUserProvider(userProvider, token);

        newTracker.trackAnalyticsEvent({
          category: AnalyticsCategory.Application,
          action: AnalyticsAction.PopupShown,
          label: 'Session expired | relink issue',
        });
        // open dialog to ask user to relink their account
        this.store.dispatch(
          openDialog({
            cancellable: false,
            texts: {
              title: definedMessages.RELINK_REQUIRED_TITLE,
              body: { ...definedMessages.RELINK_REQUIRED_MESSAGE, values: messageValues },
              confirm: { ...definedMessages.RELINK_REQUIRED_BUTTON_LABEL, values: messageValues },
            },
            confirmAction: linkAccountRequest(),
            confirmTrackingEvent: {
              category: AnalyticsCategory.Application,
              action: AnalyticsAction.ItemClicked,
              label: `Link ${this.integrationName} account | button`,
            },
            closeCallback: () => {
              addListenersForOneTimeExecution([
                ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS,
                ProxyActionsTypes.PROXY_CHECK_LINK_ERROR,
              ])((_, { type }) => {
                if (type === ProxyActionsTypes.PROXY_CHECK_LINK_ERROR) {
                  logger.error('Error renewing ambassador provider link - PROXY_CHECK_LINK_ERROR');
                  reject(response);
                } else {
                  const retriedRequest = axios.request(response.config);
                  resolve(retriedRequest);
                }
              });
            },
          }),
        );
      } catch (e) {
        logger.error('Error renewing ambassador provider link', e);
        reject(response);
      }
    });
  }

  protected getLinkParameters(): undefined | { linkId: string; selectedProviderId: string } {
    const state = this.store.getState();
    const userProvider = proxyUserProviderSelector(state);
    return userProvider ? { linkId: userProvider.id, selectedProviderId: userProvider.providerUuid } : undefined;
  }

  protected abstract isUrlToIntercept(url: string): boolean;

  protected parseProviderContent(data: string): any {
    return data ? JSON.parse(data) : '';
  }

  protected replaceRequestBody(_: AxiosRequestConfig): void {
    return;
  }
}
