import { combineReducers } from 'redux';
import { connectWiseNotesReducer } from './note/connectwise.note.reducer';
import { connectWiseNoteTypeReducer } from './note/notetype/connectwise.notetype.reducer';
import { companyPickerReducer } from './companyPicker/companyPicker.reducer';
import { connectWiseServiceTicketReducer } from './serviceTicket/serviceTicket.reducer';

export const connectWiseReducer = combineReducers({
  notes: connectWiseNotesReducer,
  noteTypes: connectWiseNoteTypeReducer,
  companies: companyPickerReducer,
  serviceTicket: connectWiseServiceTicketReducer,
});
