import axios from 'axios';

import { HttpResponseData, JiveHttpHandler, JiveRequestOptionsArgs } from '@jive/common';
import { Observable, from } from 'rxjs';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
  HEAD = 'head',
}

export function httpClient(token: string): JiveHttpHandler {
  return {
    get<T, E>(url: string, options?: JiveRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
      return createRequest(url, HttpMethod.GET, token, null, options);
    },
    post<T, E>(url: string, body: any | null, options?: JiveRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
      return createRequest(url, HttpMethod.POST, token, body, options);
    },
    put<T, E>(url: string, body: any | null, options?: JiveRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
      return createRequest(url, HttpMethod.PUT, token, body, options);
    },
    // eslint-disable-next-line
    patch<T, E>(_url: string, _body: any | null, _options?: JiveRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
      throw new Error('Not implement yet!');
    },
    delete<T, E>(url: string, options?: JiveRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
      return createRequest(url, HttpMethod.DELETE, token, null, options);
    },
  };
}

function createRequest<T, E>(
  url: string,
  method: HttpMethod.DELETE | HttpMethod.POST | HttpMethod.GET | HttpMethod.PUT,
  token: string,
  data = null,
  options?: any,
): Observable<HttpResponseData<T, E>> {
  const headers = options ? options.headers : {};
  let config = { method, url, headers: { ...headers, Authorization: `Bearer ${token}` } };
  if (data !== null) {
    config = Object.assign({}, config, { data });
  }

  return from(
    axios(config)
      .then((resp) => {
        if (resp.status === 401) {
          throw new Error('UNAUTHORIZED!');
        }
        return resp;
      })
      .catch((err) => {
        throw err;
      }),
  );
}
