import { makeActionCreator } from '../../actions/actionCreators';
import { SalesforceCase } from '../../../../salesforce-shared/salesforce-service.models';
import { WithMessage } from '../../models';

export enum CaseActionsType {
  RESET = 'cases/reset',
  GET_CASES = 'cases/get',
  GET_CASES_SUCCESS = 'cases/get/success',
  GET_CASES_ERROR = '/get/error',

  GET_MORE_CASES = 'cases/more/get',
  GET_MORE_CASES_SUCCESS = 'cases/more/success',
  GET_MORE_CASES_ERROR = 'cases/more/error',

  CREATE_CASE = 'cases/create',
  CREATE_CASE_SUCCESS = 'cases/create/success',
  CREATE_CASE_ERROR = 'cases/create/error',

  OPEN_CASE = 'cases/open',
  OPEN_CASE_SUCCESS = 'cases/open/success',
  OPEN_CASE_ERROR = 'cases/open/error',

  GET_CASES_COUNT = 'cases/getCount',
  GET_CASES_COUNT_SUCCESS = 'cases/getCount/success',
  GET_CASES_COUNT_ERROR = 'cases/getCount/error',
}

export const resetCases = makeActionCreator(CaseActionsType.RESET)();

export const getCasesRequest = makeActionCreator(CaseActionsType.GET_CASES)();

export interface GetCasesSuccessPayload {
  meta: GetMoreCasesRequestMeta;
  cases: SalesforceCase[];
}

export const getCasesSuccess = makeActionCreator(CaseActionsType.GET_CASES_SUCCESS)<GetCasesSuccessPayload>();

export interface GetCasesErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export const getCasesError = makeActionCreator(CaseActionsType.GET_CASES_ERROR)<GetCasesErrorPayload>();

export interface GetMoreCasesRequestMeta {
  next?: string;
  casesCount: number; // current number of downloaded cases
  contactId: string;
}

export const getMoreCasesRequest = makeActionCreator(CaseActionsType.GET_MORE_CASES)();
export const getMoreCasesSuccess = makeActionCreator(CaseActionsType.GET_MORE_CASES_SUCCESS)<GetCasesSuccessPayload>();
export const getMoreCasesError = makeActionCreator(CaseActionsType.GET_MORE_CASES_ERROR)<GetCasesErrorPayload>();

export const createCaseRequest = makeActionCreator(CaseActionsType.CREATE_CASE)();
export const createCaseSuccess = makeActionCreator(CaseActionsType.CREATE_CASE_SUCCESS)();
export const createCaseError = makeActionCreator(CaseActionsType.CREATE_CASE_ERROR)<WithMessage>();

export const openCaseRequest = makeActionCreator(CaseActionsType.OPEN_CASE)();
export const openCaseSuccess = makeActionCreator(CaseActionsType.OPEN_CASE_SUCCESS)();
export const openCaseError = makeActionCreator(CaseActionsType.OPEN_CASE_ERROR)<WithMessage>();

export interface GetCasesCountPayload {
  casesCount: number; // number of all Cases in CRM
}

export const getCasesCountRequest = makeActionCreator(CaseActionsType.GET_CASES_COUNT)();
export const getCasesCountSuccess = makeActionCreator(CaseActionsType.GET_CASES_COUNT_SUCCESS)<GetCasesCountPayload>();
export const getCasesCountError = makeActionCreator(CaseActionsType.GET_CASES_COUNT_ERROR)<WithMessage>();

export type CaseActions =
  | ReturnType<typeof resetCases>
  | ReturnType<typeof getCasesRequest>
  | ReturnType<typeof getCasesSuccess>
  | ReturnType<typeof getCasesError>
  | ReturnType<typeof getMoreCasesRequest>
  | ReturnType<typeof getMoreCasesSuccess>
  | ReturnType<typeof getMoreCasesError>
  | ReturnType<typeof createCaseRequest>
  | ReturnType<typeof createCaseSuccess>
  | ReturnType<typeof createCaseError>
  | ReturnType<typeof openCaseRequest>
  | ReturnType<typeof openCaseSuccess>
  | ReturnType<typeof openCaseError>
  | ReturnType<typeof getCasesCountRequest>
  | ReturnType<typeof getCasesCountSuccess>
  | ReturnType<typeof getCasesCountError>;
