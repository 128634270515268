import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Integrations } from '../../models';
import { AppState } from '../../reducers';
import { CallWithContact } from '../calls.reducer';
import { contactInCallSelector, getEntityInCallSelector } from '../calls.selector';
import { Typography } from '../../theme/Typography.component';
import { useCallActions } from './useCallActions';

const definedMessages = defineMessages({
  ACTIONS: {
    id: 'Call.Actions',
    defaultMessage: 'Actions',
  },
});

export interface CallActionsComponentV2Props {
  integration: Integrations;
  call: CallWithContact;
}

export const CallActionsComponentV2: React.FC<CallActionsComponentV2Props> = ({ call }) => {
  const contact = useSelector((state: AppState) => contactInCallSelector(state, call.id));
  const entityInCall = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));

  const { primaryAction, secondaryActions } = useCallActions(call);

  return (
    <Box component="div" mb={6}>
      <Box mb={3}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.ACTIONS} />
        </Typography>
      </Box>
      {primaryAction ? <Box>{React.cloneElement(primaryAction, { call, contact })}</Box> : null}
      {entityInCall ? (
        <Box mt={4}>
          <Grid container wrap="nowrap" justifyContent={secondaryActions.length === 3 ? 'space-between' : 'flex-start'}>
            {secondaryActions.map((secondaryAction, index) => (
              <Grid item key={index}>
                {React.cloneElement(secondaryAction, { call, contact })}
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        secondaryActions.map((secondaryAction, index) => (
          <Box key={index} mt={2}>
            {React.cloneElement(secondaryAction, { call, contact })}
          </Box>
        ))
      )}
    </Box>
  );
};
