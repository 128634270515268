import { externalUserKeySelector } from './authentication/authentication.selector';
import { getStore } from './configureStore';
import { currentIntegrationNameSelector } from './integrations/integrations.selector';
import { SentryMetadata } from './logging';

export const generateSentryMetadata = (): SentryMetadata => {
  const appState = getStore().getState();
  const result: SentryMetadata = {
    userId: externalUserKeySelector(appState),
    tags: [
      {
        key: 'integration',
        value: currentIntegrationNameSelector(appState),
      },
    ],
  };

  return result;
};
