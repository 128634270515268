import { memoize } from 'lodash-es';
import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { OpenRepairOrder } from '../repairOrders/repairOrders.models';
import { openRepairOrderByVINSelector } from '../repairOrders/repairOrders.selectors';
import { RepairOrderPart } from './parts.models';

const partsSelector = (state: AppState) => state.cox.parts;

export const getPartsFromRepairOrders = (repairOrders: Optional<OpenRepairOrder[]>) =>
  repairOrders?.flatMap((ro) => ro.details).filter((d) => d.lineType === 'P') as RepairOrderPart[];

const repairOrderPartsSelectorBase = (VIN: string) =>
  createSelector(openRepairOrderByVINSelector(VIN), getPartsFromRepairOrders);

// Selecting the Parts from repair orders is expensive and results in a new array each time it is called.
// By memoizing it, it will be called only when the Repair orders for the specific VIN are updated.
export const repairOrderPartsSelector = memoize(repairOrderPartsSelectorBase);

export const inventoryPartsSelector = createSelector(partsSelector, (part) => part.byPartNumber);
