import { Integrations } from '../models';
import { IntegrationsActions, StartupState } from './integrations.actions';
import { IntegrationsActionsType, IntegrationsState } from './integrations.actions';

export const INITIAL_STATE: IntegrationsState = {
  current: Integrations.None,
  startupState: StartupState.Uninitialized,
};

const integrationsReducer = (
  state: IntegrationsState = INITIAL_STATE,
  action: IntegrationsActions,
): IntegrationsState => {
  switch (action.type) {
    case IntegrationsActionsType.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case IntegrationsActionsType.INTEGRATION_UNINITIALIZED:
      return { ...state, startupState: StartupState.Uninitialized };
    case IntegrationsActionsType.INTEGRATION_STARTING:
      return { ...state, startupState: StartupState.Starting };
    case IntegrationsActionsType.INTEGRATION_STARTED:
      return { ...state, startupState: StartupState.Started };
  }

  return state;
};

export default integrationsReducer;
