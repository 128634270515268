import { Store } from 'redux';
import { GetMoreNotesRequestMeta, GetNotesSuccessPayload } from '../../notes/notes.actions';
import { AppState } from '../../reducers';
import { ClioService, GetNotesResponse } from '../clio.service';
import { NoteActionCreator } from '../../actionFacade/actionCreators/note/note.actioncreator';
import { AddNotePayload } from '../../notes/notes.models';
import { AddNoteModel } from '../../actionFacade/actionCreators/note/note.actioncreator.models';
import { showMessage } from '../../inAppNotification/message.action';
import { uuid } from '../../uuid';
import { MessageVariant } from '../../models';
import { defineMessages } from 'react-intl';
import { proxyUserProviderSelector } from '../../ambassador/proxy/proxy.selector';
import { openNoteInClioFactory } from './OpenNoteInClio.component';

const definedMessages = defineMessages({
  NOTE_CREATED_MESSAGE: {
    id: 'ClioNotes.NoteSaved.Banner.Label',
    defaultMessage: 'A new not for {username} is saved in Clio',
  },
});

export class ClioNoteActionCreator extends NoteActionCreator {
  constructor(protected store: Store<AppState>) {
    super(store);
  }

  protected async _loadMostRecentNoteOfContact(contactId: string): Promise<GetNotesSuccessPayload> {
    const notes = await ClioService.getNotes(contactId);

    return this.createGetNotesSuccessPayload(notes, 0);
  }

  protected async _loadMoreNotesOfContact(moreNotesMeta: GetMoreNotesRequestMeta): Promise<GetNotesSuccessPayload> {
    if (!moreNotesMeta || !moreNotesMeta.next) {
      throw new Error('There is no next url stored to load more notes from!');
    }

    const notes = await ClioService.getMoreNotes(moreNotesMeta.next);

    return this.createGetNotesSuccessPayload(notes, moreNotesMeta.notesCount);
  }

  protected async _addNote(notePayload: AddNotePayload): Promise<Optional<{ noteId: string }>> {
    const { contactId, content, noteSubject } = notePayload;
    const noteId = await ClioService.addNote(contactId, noteSubject, content);
    return { noteId };
  }

  protected _handleAddNoteSuccess(contactId: string, note: AddNoteModel): void {
    const userProvider = proxyUserProviderSelector(this.store.getState());
    const appUrl = userProvider?.customAttributes?.regionApiUrl;
    if (!appUrl) {
      super._handleAddNoteSuccess(contactId, note);
      return;
    }

    const queryString = decodeURI(
      `contactId={"value":${contactId}}&type={"value":"Contact"}&query={"value":"${note.noteSubject}"}`,
    );
    const noteUrl = new URL(`nc/#/contacts/${contactId}/notes?${queryString}`, appUrl);

    this.store.dispatch(
      showMessage({
        id: uuid(),
        type: MessageVariant.Info,
        message: {
          ...definedMessages.NOTE_CREATED_MESSAGE,
          values: { contactName: note.contactName ?? note.contactId },
        },
        params: {
          autoHide: true,
          dismissible: true,
          actions: openNoteInClioFactory(noteUrl.toString()),
        },
      }),
    );
  }

  private createGetNotesSuccessPayload(response: GetNotesResponse, notesCount: number): GetNotesSuccessPayload {
    const { notes, recordsCount } = response;

    // Clio will return a next paging URL even if thats empty.
    // To better detect the end of notes, let's check the returned record count as well
    if (recordsCount !== undefined && notesCount + response.notes.length >= recordsCount) {
      return {
        notes,
        meta: {
          notesCount: notesCount + response.notes.length,
        },
      };
    }

    return {
      notes: response.notes,
      meta: {
        next: response.next,
        notesCount: (notesCount || 0) + notes.length,
      },
    };
  }
}
