import { AlertInactiveIcon } from '@getgo/chameleon-icons/react';
import { makeStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FullScreenPageWithLogo } from '../theme/FullScreenPageWithLogo.component';
import { Typography } from '../theme/Typography.component';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { FormattedMessageWithMessageValues } from '../reactInltModels';

const useStyles = makeStyles(() => ({
  errorContainer: (props: { isNarrowIntegration: boolean }) => ({
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: props.isNarrowIntegration ? '0' : '0 24px',
    textAlign: 'center',
  }),
  warningIcon: {
    margin: '15px',
  },
  restartButton: {
    marginTop: '15px',
  },
}));

export interface ErrorPageProps {
  title: FormattedMessageWithMessageValues;
  message: FormattedMessageWithMessageValues;
  containerClass?: string;
}

export const ErrorPage: FC<ErrorPageProps> = ({ title, message, children, containerClass }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });
  return (
    <FullScreenPageWithLogo fullHeight>
      <div data-cy="error-page" className={classNames(classes.errorContainer, containerClass)}>
        <AlertInactiveIcon size="40px" color={tokens.textError02} class={classes.warningIcon} />
        <Typography variant="heading-xsmall" color="text-info">
          <span style={{ textTransform: 'uppercase' }}>
            <FormattedMessage {...title} />
          </span>
        </Typography>
        <Typography color="text-02" variant="body-medium">
          <b>
            <FormattedMessage {...message} />
          </b>
        </Typography>
        {children}
      </div>
    </FullScreenPageWithLogo>
  );
};
