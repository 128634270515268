import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  READ_MORE_BODY_1: {
    id: 'SoftphoneSettings.Softphone.ReadMoreModal.Body',
    defaultMessage:
      "There is a new and simpler way to make and receive calls with Goto Integration. You don't need to run GoTo on your browser, mobile device, desktop, or VoIP phone. Every incoming and outgoing call will be managed by the integration.",
  },
  READ_MORE_BODY_2: {
    id: 'Onboarding.TestCall.ManageCalls.HelperText',
    defaultMessage: 'You can change this anytime in your settings.',
  },
});

export const SoftphoneSettingsReadMore: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
        <FormattedMessage {...definedMessages.READ_MORE_BODY_1} />
      </Typography>
      <Typography gutterBottom>
        <FormattedMessage {...definedMessages.READ_MORE_BODY_2} />
      </Typography>
    </>
  );
};
