import * as microsoftTeams from '@microsoft/teams-js';
import { Entitlements } from '../../authentication/authentication.models';
import { IdentityService, WhoAmIResponse } from '../../settings/identity.service';
import { RedirectOAuthPages } from './redirect.model';

const REDIRECT_PAGE_URL = '/embedded-integrations/msteams/redirect';

export interface SignInResult {
  token: string;
  jiveId: string;
}

export interface SignInDetails {
  token: string;
  jiveId: string;
  externalUserKey: string;
  name: string;
  entitlements: Entitlements[];
}

export function signIn(): Promise<SignInResult> {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication.authenticate({
      url: `${REDIRECT_PAGE_URL}/${RedirectOAuthPages.SignIn}`,
      width: 860,
      height: 625,
      successCallback: (result) => {
        if (!result) {
          reject(new Error('Empty result'));
          return;
        }

        const resultObject = JSON.parse(result);

        if (!resultObject.token) {
          reject(new Error('Access token is missing'));
          return;
        }

        if (!resultObject.jiveId) {
          reject(new Error('Jive ID is missing'));
          return;
        }

        // TODO: Find why when we are closing the window it overrides the
        // local storage (probably redux-persist run on close too)
        setTimeout(() => {
          resolve({
            token: resultObject.token,
            jiveId: resultObject.jiveId,
          });
        }, 1000);
      },
      failureCallback: (reason) => reject(reason),
    });
  });
}

export function whoAmI(token: string): Promise<WhoAmIResponse> {
  return IdentityService.whoAmI(token);
}

export function signOut() {
  return new Promise<void>((resolve) => {
    microsoftTeams.authentication.authenticate({
      url: `${REDIRECT_PAGE_URL}/${RedirectOAuthPages.SignOut}`,
      width: 860,
      height: 625,
      successCallback: () => resolve(),
      failureCallback: () => resolve(),
    });
  });
}
