import { Box, createStyles, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { tokens } from '@getgo/chameleon-core';
import { SoftphoneCapableFacade } from '../actionFacade/action.facade.capabilities';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { KeyPad } from '../phone/KeyPad.component';
import { SoftphoneCall } from './softphone.model';
import { Typography } from '../theme/Typography.component';
import { useEffect } from 'react';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: tokens.backgroundColorDefault,
    },
    valueDisplayContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      lineHeight: theme.spacing(8),
      height: theme.spacing(8),
    },
    dtmfEntered: {
      '&::part(text)': {
        maxWidth: 150,
        direction: 'rtl',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  }),
);

interface SoftphoneDialPadProps {
  call: SoftphoneCall;
}

export const SoftphoneDialPad: FC<SoftphoneDialPadProps> = ({ call }) => {
  const classes = useStyles();
  const [valueDisplay, setValueDisplay] = useState('');

  const keyEventListener = useCallback((e: KeyboardEvent) => {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#'];
    if (validKeys.includes(e.key)) {
      onValueReceived(e.key);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', keyEventListener);

    return () => {
      document.removeEventListener('keydown', keyEventListener);
    };
  }, [keyEventListener]);

  const onValueReceived = (value: string) => {
    void getActionFacade<SoftphoneCapableFacade>().sendDtmf(call.id, value);
    setValueDisplay((prevValueDisplay) => `${prevValueDisplay}${value}`);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.valueDisplayContainer}>
        <Typography variant="heading-medium" className={classes.dtmfEntered}>
          <bdo dir="ltr">{valueDisplay}</bdo>
        </Typography>
      </Box>
      <KeyPad handleOnClick={onValueReceived} />
    </Box>
  );
};
