import { PartsActions, PartsActionsType } from './parts.actions';
import { getPartId, InventoryParts, LoadInventoryPartRequestState } from './parts.models';

export interface PartsState {
  byPartNumber: InventoryParts;
}

const INITIAL_STATE: PartsState = {
  byPartNumber: {},
};

export const partsReducer = (state: PartsState = INITIAL_STATE, action: PartsActions): PartsState => {
  switch (action.type) {
    case PartsActionsType.LOAD_INVENTORY_PART: {
      return {
        ...state,
        byPartNumber: {
          ...state.byPartNumber,
          [getPartId(action.payload)]: {
            loadRequestState: LoadInventoryPartRequestState.LOADING,
            part: undefined,
          },
        },
      };
    }

    case PartsActionsType.LOAD_INVENTORY_PART_SUCCESS: {
      return {
        ...state,
        byPartNumber: {
          ...state.byPartNumber,
          [getPartId(action.payload)]: {
            loadRequestState: LoadInventoryPartRequestState.LOADED,
            part: action.payload.part,
          },
        },
      };
    }

    case PartsActionsType.LOAD_INVENTORY_PART_ERROR: {
      return {
        ...state,
        byPartNumber: {
          ...state.byPartNumber,
          [getPartId(action.payload)]: {
            loadRequestState: LoadInventoryPartRequestState.FAILED,
            part: undefined,
          },
        },
      };
    }

    default:
      return state;
  }
};
