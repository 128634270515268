import { logger } from '../logging';
import { NotificationChannel } from './index';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { externalUserKeySelector } from '../authentication/authentication.selector';
import { subscribeToCallHistoryChannel } from '../callHistoryPush/callHistoryChannel.service';
import { subscribeToPresenceChannel } from '../presence/presenceChannel.service';
import { callHistoryPushEventHandler } from '../callHistoryPush/callHistoryPushEventHandler';
import { presencePushEventHandler } from '../presence/presenceEventHandler';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { ChannelEvent } from './channel-event';

let notificationChannel: NotificationChannel;

export const startNotificationChannel = async (store: Store<AppState>): Promise<void> => {
  if (notificationChannel) {
    notificationChannel.stop();
  }
  const externalUserKey = externalUserKeySelector(store.getState());

  if (!externalUserKey) {
    throw Error('Cannot start notification channel without externalUserKey');
  }

  notificationChannel = new NotificationChannel(externalUserKey);
  await notificationChannel.start();
  return await subscribeToNotificationChannelEvents(store);
};

const subscribeToNotificationChannelEvents = async (store: Store<AppState>): Promise<void> => {
  const externalUserKey = externalUserKeySelector(store.getState());
  const organizationId = selectedOrganizationIdSelector(store.getState());

  if (!organizationId || !externalUserKey) {
    throw Error('Cannot start notification channel without organizationId or externalUserKey');
  }

  const channelSubscriptionHandlers = [
    subscribeToCallHistoryChannel(organizationId),
    subscribeToPresenceChannel([externalUserKey]),
  ];
  const messageEventHandlers = [callHistoryPushEventHandler(store), presencePushEventHandler()];

  for (const channelSubscriptionHandler of channelSubscriptionHandlers) {
    await channelSubscriptionHandler(notificationChannel.id);
  }
  notificationChannel.subscribe((event) => {
    for (const handler of messageEventHandlers) {
      try {
        handler(event);
      } catch (e) {
        logger.error('Error handling notification channel message', e);
      }
    }
  });
  notificationChannel.on(ChannelEvent.DISCONNECTED, (event) => {
    // this can mean multiple things and notificationChannel may reconnect from it
    // TODO: monitor these errors in splunk and decide how to handle disconnected events
    logger.warn('Notification channel websocket disconnected', event);
  });
};
