import { Store } from 'redux';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { selectedLineSelector } from '../settings/settings.selector';
import {
  loadCallHistory,
  loadCallHistorySuccess,
  loadCallHistoryError,
  loadCallHistoryMetadataSuccess,
  loadCallHistoryDiffSuccess,
} from './unifiedCallHistory.action';
import { subYears } from 'date-fns';
import { getCallHistoryItems } from './callHistoryService';
import { callHistoryMetadataService } from './callHistoryMetadata.service';
import { loadedCallHistoryMetadataBucketsSelector } from './unifiedCallHistory.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';

export class CallHistoryActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async loadCallHistoryItems(organizationId: string, pageToLoad: number, startDate?: Date): Promise<void> {
    try {
      const state = this.store.getState();
      const selectedLine = selectedLineSelector(state);

      this.store.dispatch(loadCallHistory({ page: pageToLoad, requestDate: new Date() }));

      const { items, hasNextPage, page } = await getCallHistoryItems({
        organizationId,
        startTime: startDate ?? subYears(new Date(), 1),
        endTime: new Date(),
        page: pageToLoad,
        pageSize: 50, // number of interactions
        selectedLine,
      });

      const loadedBuckets = loadedCallHistoryMetadataBucketsSelector(state);
      const integration = currentIntegrationSelector(state);
      const metadataResult = await callHistoryMetadataService.loadCallHistoryMetadata(
        integration,
        items.map((item) => item.startTime),
        loadedBuckets,
      );

      this.store.dispatch(loadCallHistoryMetadataSuccess(metadataResult));

      if (startDate === undefined) {
        this.store.dispatch(loadCallHistorySuccess({ items, page, hasNextPage }));
      } else {
        this.store.dispatch(loadCallHistoryDiffSuccess(items));
      }
    } catch (e) {
      logger.error('CallHistory loading error', e);
      this.store.dispatch(loadCallHistoryError());
    }
  }
}
