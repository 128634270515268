import { AppState } from '../../reducers';
import { ZendeskService } from '../zendesk.service';
import { Store } from 'redux';
import { CallLog } from '../../calls/callEvents.action';
import { logger } from '../../logging';
import { showErrorMessage, showSuccessMessage } from '../../inAppNotification/message.action';
import { newTracker } from '../../analytics-new/tracker-new';
import { defineMessages } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { ZendeskVia, ZendeskViaType } from '../zendesk.models';
import { CallWithContact } from '../../calls/calls.reducer';
import { CallType } from '@jive/realtime-events';

export const definedMessages = defineMessages({
  CALL_LOG_SAVE_SUCCESS_MESSAGE: {
    id: 'Zendesk.CallLog.SaveSuccess.Message',
    defaultMessage: 'Call logged in Zendesk',
  },
  CALL_LOG_SAVE_ERROR_MESSAGE: {
    id: 'Zendesk.CallLog.SaveError.Message',
    defaultMessage: 'There was an error while saving the call log, please try again.',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_LOG_SAVED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.CallLogCreationSucceeded,
    label: '-',
  },
  CALL_LOG_SAVE_FAILED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.CallLogCreationFailed,
    label: '-',
  },
});

export class ZendeskCallLogActionCreator {
  constructor(private store: Store<AppState>, private zendeskService: ZendeskService) {}

  async createCallLog(callLogToCreate: CallLog, call: CallWithContact): Promise<void> {
    const { EntityId, Subject, Description } = callLogToCreate;

    const via: ZendeskVia = {
      channel: call.type === CallType.IncomingCall ? ZendeskViaType.Incoming : ZendeskViaType.Outgoing,
      source: {
        to: call.type === CallType.OutgoingCall ? call.caller : call.callee,
        from: call.type === CallType.OutgoingCall ? call.callee : call.caller,
      },
      rel: call.type === CallType.IncomingCall ? 'inbound' : 'outbound',
    };
    try {
      await this.zendeskService.createTicket({
        userId: EntityId,
        subject: Subject,
        description: Description,
        via,
        isCommentPublic: false,
        ticketStatus: 'open',
      });
      this.store.dispatch(showSuccessMessage(definedMessages.CALL_LOG_SAVE_SUCCESS_MESSAGE));
      newTracker.trackAnalyticsEvent(trackingEvents.CALL_LOG_SAVED);
    } catch (err) {
      logger.error('Error while creating automatic zendesk call log', err);
      this.store.dispatch(showErrorMessage(definedMessages.CALL_LOG_SAVE_ERROR_MESSAGE));
      newTracker.trackAnalyticsEvent(trackingEvents.CALL_LOG_SAVE_FAILED);
    }
  }
}
