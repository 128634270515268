import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { AmbassadorProvider, AmbassadorUserProvider } from '../ambassador.service';

const proxyReducerSelector = (state: AppState) => state.proxy;

export const proxyLinkedSelector: (state: AppState) => Optional<boolean> = createSelector(
  proxyReducerSelector,
  (proxyReducer) => proxyReducer && proxyReducer.linked,
);

export const proxyProviderSelector: (state: AppState) => AmbassadorProvider | undefined = createSelector(
  proxyReducerSelector,
  (proxyReducer) => proxyReducer && proxyReducer.provider,
);

export const proxyUserProviderSelector: (state: AppState) => AmbassadorUserProvider | undefined = createSelector(
  proxyReducerSelector,
  (proxyReducer) => proxyReducer && proxyReducer.userProvider,
);

export const proxyProviderAvailableSelector: (state: AppState) => boolean | undefined = createSelector(
  proxyReducerSelector,
  (proxyReducer) => proxyReducer && proxyReducer.providerAvailable,
);
