import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
  });

interface InlineActionsProps {
  actions: ReactNode[];
}

const InlineActions: React.FC<InlineActionsProps & WithStyles<typeof styles>> = ({ classes, actions }) => (
  <div className={classes.root}>{actions}</div>
);

// export default InlineActions;
export default withStyles(styles, { withTheme: true })(InlineActions);
