import { AmbassadorProxyAxiosInterceptor } from '../ambassador/proxy/proxy.interceptor';
import { IntegrationNames, Integrations } from '../models';
import { DynamicsApiUri } from './dynamics.config';

export class DynamicsAxiosInterceptor extends AmbassadorProxyAxiosInterceptor {
  protected integrationName = IntegrationNames[Integrations.Dynamics];

  protected isUrlToIntercept(url: string): boolean {
    const dynamicsApiUris = Object.values(DynamicsApiUri);
    return dynamicsApiUris.some((dynamicsUri) => url && url.includes(dynamicsUri));
  }

  protected providerHeaders = {
    'Provider-Authorization': 'Bearer --ambassador--access_token--ambassador--',
    // This header must be dropped by Ambassador, because it performs a server-to-server communication with the Dynamics API,
    // so if it is sent, Microsoft rules the request unsecure, and denies it.
    // Read more about it here: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow#redirect-uri-setup-required-for-single-page-apps
    'X-Ambassador-RemoveHeaders': 'Origin,origin',
  };
}
