import configuration from './config';
import { Integrations } from './models';

export const getDistinctItemsByProperty = <T>(items: T[], getDistinctPropertyValue: (item: T) => string): T[] => {
  const distinctResult: T[] = [];
  const map = new Map();

  for (const item of items) {
    const distinctPropertyValue = getDistinctPropertyValue(item);
    if (!map.has(distinctPropertyValue)) {
      map.set(distinctPropertyValue, true);
      distinctResult.push(item);
    }
  }

  return distinctResult;
};

export function openGoToConnectApp(features: string[] = []) {
  window.open(configuration.goToAppUrl, '_blank', ['noopener'].concat(features).join(','));
}

export const isLocalStorageAvailable = (): boolean => {
  try {
    localStorage.setItem('sync-service-space-check', 'x'.repeat(10 * 1024)); // 10 kB
    localStorage.removeItem('sync-service-space-check');
    return true;
  } catch {
    return false;
  }
};

export const isDesktopIntegration = (integration: Integrations): boolean => {
  return (
    integration === Integrations.Clio ||
    integration === Integrations.ConnectWise ||
    integration === Integrations.Redtail ||
    integration === Integrations.Cox
  );
};

export function isFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}

export function isRejected<T>(item: PromiseSettledResult<T>): item is PromiseRejectedResult {
  return item.status === 'rejected';
}

export function sleep(timeMs: number) {
  return new Promise((resolve) => setTimeout(resolve, timeMs));
}
