import { Reducer } from 'redux';
import { AnalyticsPageType } from './analytics.models';
import { AnalyticsActions, AnalyticsActionTypes } from './analytics.action';

export interface AnalyticsState {
  currentPageType: AnalyticsPageType;
}

export const ANALYTICS_INITIAL_STATE: AnalyticsState = {
  currentPageType: AnalyticsPageType.None,
};

export const analyticsReducer: Reducer<AnalyticsState, AnalyticsActions> = (
  state = ANALYTICS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_ANALYTICS_CURRENT_PAGE_TYPE:
      return { ...state, currentPageType: action.payload };
    default:
      return state;
  }
};
