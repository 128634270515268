import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from './settings.selector';
import { selectLanguage } from './settings.action';
import { Option, Select } from '@getgo/chameleon-web/react';
import { defineMessages, useIntl } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { pendoService } from '../pendo/pendo.service';
import { getSelectableLanguages } from '../i18n/i18n';

const definedMessages = defineMessages({
  LANGUAGE_LABEL: {
    id: 'SettingsContainer.SectionLanguage',
    defaultMessage: 'Language',
  },
});

const trackingEvents = defineTrackingEvents({
  LANGUAGE_CHANGED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemChanged,
    label: 'Language | select | {selectedLanguage}',
  },
});

export interface LanguageSelectorProps {
  showLabel?: boolean;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ showLabel }) => {
  const selectableLanguages = getSelectableLanguages();
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <Select
      aria-label={showLabel ? intl.formatMessage(definedMessages.LANGUAGE_LABEL) : undefined}
      label={showLabel ?? false ? intl.formatMessage(definedMessages.LANGUAGE_LABEL) : ''}
      fullwidth
      size="medium"
      name="language"
      id="language-picker"
      aria-labelledby={showLabel ? undefined : 'language-picker-label'}
      data-cy={'language-select'}
      helperText=""
      value={selectedLanguage}
      onChange={(event) => {
        dispatch(selectLanguage(String(event.target.value)));
        pendoService.updateLocale(event.target.value);
        newTracker.trackAnalyticsEvent({
          ...trackingEvents.LANGUAGE_CHANGED,
          label: `Language | select | ${event.target.value}`,
        });
      }}
    >
      {Object.entries(selectableLanguages).map(([localeKey, localeDisplayName]) => (
        <Option key={localeKey} value={localeKey}>
          {localeDisplayName}
        </Option>
      ))}
    </Select>
  );
};
