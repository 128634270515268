import { createStyles, withStyles, WithStyles, Tooltip, Theme, Box } from '@material-ui/core';
import React, { Component, createRef, Fragment } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { BodyLayout } from '../components/BodyLayout.component';
import { SearchInput } from '../components/SearchInput';
import { SearchResultType } from '../search/SearchResult.model';
import SearchResults from '../search/SearchResults.container';
import { IconsToggler } from './IconsToggler.component';
import { KeyPad } from './KeyPad.component';
import { formatPhoneNumber } from './phone-utils';
import { PhoneMode } from './phone.model';
import { CallPayload } from './phone.actions';
import { Entity, Integrations } from '../models';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { AlertActiveIcon, DialPadIcon, PersonActiveIcon, PersonInactiveIcon } from '@getgo/chameleon-icons/react';
import { Button } from '@getgo/chameleon-material-ui';
import { numberCanBeDialed } from '../softphone/phone-number';

const styles = (theme: Theme) =>
  createStyles({
    phoneContainer: {
      display: 'flex',
      flex: 1,
      height: '100%',
    },
    bodyLayout: {
      flex: 'initial',
      margin: theme.spacing(7, 0),
    },
    keyPadBodyLayout: {
      flex: 'initial',
    },
    containerLayout: {
      maxHeight: 440,
    },
    narrowIntegrationContainerLayout: {
      maxHeight: 350,
    },
    searchContainerLayout: {
      maxHeight: 'initial',
      justifyContent: 'initial',
    },
  });

const definedMessages = defineMessages({
  CALL: {
    id: 'Phone.Call',
    defaultMessage: 'Call',
    description: 'Call button text',
  },
  SIGN_OUT: {
    id: 'Preferences.SignOut',
    defaultMessage: 'Sign out',
  },
  INPUT_PLACEHOLDER: {
    id: 'Phone.Search.Placeholder',
    defaultMessage: 'Phone number or name',
  },
  COX_SEARCH_CUSTOMERS_ALERT_MESSAGE: {
    id: 'Cox.Search.AlertMessage',
    defaultMessage: 'Search by number, last name, or first and last name',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_BUTTON_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Call | button',
  },
  PAGE_LOADED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.PageLoaded,
    label: 'Phone',
  },
  CHANGE_TO_CONTACT_SEARCH: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Change to contact search',
  },
  CHANGE_TO_NUMBER_DIALER: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Change to number dialer',
  },
  TYPING_PHONENUMBERS: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemChanged,
    label: 'dialer input | numbers',
  },
  TYPING_CONTACT_NAME: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemChanged,
    label: 'dialer input | strings',
  },
});

export interface PhoneStateProps {
  selectedEntity?: Entity;
  loading: boolean;
  mode: PhoneMode;
  selectedPhoneNumber: string;
  entities?: Entity[];
  isNarrowIntegration: boolean;
  integration: Integrations;
  isSoftphoneCapableAndEnabled: boolean;
}

export interface PhoneDispatchProps {
  callContact: (payload: CallPayload) => void;
  search: ({ query }: { query: string }) => void;
  setMode: (mode: PhoneMode) => void;
  setSelectedPhoneNumber: (phoneNumber: string) => void;
  clear: () => void;
}

export interface PhonePropsOwns {
  signOutButton?: JSX.Element;
}

interface PhoneState {
  input: string;
  didTrackInputChange: boolean;
  isCallClickDebouncePending: boolean;
}

const CALL_DEBOUNCE_TIME_MS = 3_000;

class Phone extends Component<
  PhoneStateProps & PhoneDispatchProps & PhonePropsOwns & WithStyles<typeof styles>,
  PhoneState
> {
  inputRef = createRef<HTMLInputElement>();

  state = {
    input: '',
    didTrackInputChange: false,
    isCallClickDebouncePending: false,
  };

  call = () => {
    const { callContact, selectedEntity, selectedPhoneNumber } = this.props;

    if (selectedPhoneNumber) {
      callContact({
        contact: selectedEntity,
        phoneNumber: selectedPhoneNumber,
      });
    }
  };

  componentDidMount() {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.PAGE_LOADED);
  }

  onChange = (value: string) => {
    const { search, setSelectedPhoneNumber, mode } = this.props;

    // Check max size
    if (value.length >= 32) {
      return;
    }

    const isTypingAContactName = this.doesNotStartLikeAPhoneNumber(value);

    if (!this.state.didTrackInputChange) {
      newTracker.trackAnalyticsEvent(
        isTypingAContactName ? trackingEvents.TYPING_CONTACT_NAME : trackingEvents.TYPING_PHONENUMBERS,
      );
    }

    this.setState({
      input: value,
      didTrackInputChange: true,
    });

    // switch to Search mode when the entered value starts with a letter
    if (mode === PhoneMode.Dialpad && isTypingAContactName) {
      return this.setMode(PhoneMode.Search);
    }

    if (mode === PhoneMode.Dialpad) {
      setSelectedPhoneNumber(value);
    } else if (value && value.trim().length >= 3) {
      search({ query: value });
    }
  };

  showNumber = (value) => {
    const { setSelectedPhoneNumber } = this.props;
    const newPhoneNumber = formatPhoneNumber(this.state.input.concat(value));
    this.setState({
      input: newPhoneNumber,
    });
    setSelectedPhoneNumber(newPhoneNumber);
    this.inputRef.current!.focus();
  };

  setMode = (mode: PhoneMode) => {
    const { setSelectedPhoneNumber } = this.props;
    this.props.setMode(mode);
    this.inputRef.current!.focus();

    if (mode === PhoneMode.Dialpad) {
      // clear the entered value if it's not phone number like
      if (this.doesNotStartLikeAPhoneNumber(this.state.input)) {
        return this.clear();
      }
      setSelectedPhoneNumber(this.state.input);
    }
  };

  clear = () => {
    this.setState({ input: '', didTrackInputChange: false });
    this.props.clear();
    this.inputRef.current!.focus();
  };

  doesNotStartLikeAPhoneNumber(value: string): boolean {
    return /^[^\d+() ]/.test(value);
  }

  render() {
    const {
      classes,
      loading,
      mode,
      selectedPhoneNumber,
      signOutButton,
      entities,
      isNarrowIntegration,
      integration,
      isSoftphoneCapableAndEnabled,
    } = this.props;
    const containerLayout =
      mode === PhoneMode.Search
        ? classes.searchContainerLayout
        : isNarrowIntegration
        ? classes.narrowIntegrationContainerLayout
        : classes.containerLayout;

    const { input } = this.state;
    const canBeDialed = isSoftphoneCapableAndEnabled ? numberCanBeDialed(selectedPhoneNumber) : !!selectedPhoneNumber;

    const endAdornment =
      integration === Integrations.Cox ? (
        <Tooltip
          arrow
          title={<FormattedMessage {...definedMessages.COX_SEARCH_CUSTOMERS_ALERT_MESSAGE} />}
          enterDelay={500}
        >
          {/* box added to make tooltip work*/}
          <Box>
            <AlertActiveIcon color="var(--goto-icon-01)" style={{ cursor: 'pointer' }} />
          </Box>
        </Tooltip>
      ) : undefined;

    return (
      <div
        className={classes.phoneContainer}
        style={{ paddingBottom: integration === Integrations.MsTeams ? undefined : 10 }}
      >
        <BodyLayout
          header={
            <SearchInput
              startAdornment={
                <IconsToggler
                  rightIsActive={mode === PhoneMode.Search}
                  handleLeftIconClick={() => {
                    newTracker.trackAnalyticsEvent(trackingEvents.CHANGE_TO_NUMBER_DIALER);
                    this.setMode(PhoneMode.Dialpad);
                  }}
                  handleRightIconClick={() => {
                    newTracker.trackAnalyticsEvent(trackingEvents.CHANGE_TO_CONTACT_SEARCH);
                    this.setMode(PhoneMode.Search);
                  }}
                  leftIcon={<DialPadIcon data-cy="dialpad" aria-hidden />}
                  rightIcon={
                    mode === PhoneMode.Search ? (
                      <PersonActiveIcon data-cy="person" aria-hidden />
                    ) : (
                      <PersonInactiveIcon data-cy="person" aria-hidden />
                    )
                  }
                  leftLabel="dial pad"
                  rightLabel="contact search"
                />
              }
              autoFocus={true}
              mode={mode}
              onInputChange={this.onChange}
              onEnter={() => this.call()}
              onClearInput={this.clear}
              input={input}
              inputRef={this.inputRef}
              placeholder={definedMessages.INPUT_PLACEHOLDER}
              endAdornment={endAdornment}
            />
          }
          classes={{
            body: mode === PhoneMode.Search ? classes.bodyLayout : classes.keyPadBodyLayout,
            container: containerLayout,
          }}
          body={
            <Fragment>
              {mode === PhoneMode.Search ? (
                <SearchResults loading={loading} searchResultType={SearchResultType.DialPad} />
              ) : (
                <KeyPad handleOnClick={this.showNumber} />
              )}
            </Fragment>
          }
          footer={
            <Fragment>
              {mode === PhoneMode.Dialpad || !!entities?.length ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!canBeDialed || this.state.isCallClickDebouncePending}
                  data-cy="call"
                  onClick={() => {
                    try {
                      // disable call button for a short time to avoid multiple clicks.
                      this.setState({ isCallClickDebouncePending: true });

                      newTracker.trackAnalyticsEvent(trackingEvents.CALL_BUTTON_CLICKED);
                      this.call();
                    } finally {
                      setTimeout(() => {
                        this.setState({ isCallClickDebouncePending: false });
                      }, CALL_DEBOUNCE_TIME_MS);
                    }
                  }}
                >
                  <FormattedMessage {...definedMessages.CALL} />
                </Button>
              ) : undefined}

              {signOutButton ?? null}
            </Fragment>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Phone);
