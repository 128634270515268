import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../../actions/actionCreators';
import { allCallSelector } from '../../calls/calls.selector';
import { AppState } from '../../reducers';
import { createCommunication } from './communication.action';
import ClioCommunicationLogForm, {
  ClioCommunicationLogFormDispatchProps,
  ClioCommunicationLogFormProps,
  ClioCommunicationLogFormStateProps,
} from './CommunicationLogForm.component';
import { getSavedCommunication } from './saved-communication.action';
import { savedCommunicationByCallIdSelector } from './saved-communication.selector';

export default connect<
  ClioCommunicationLogFormStateProps,
  ClioCommunicationLogFormDispatchProps,
  ClioCommunicationLogFormProps,
  AppState
>(
  (state: AppState, { callId }: ClioCommunicationLogFormProps) => ({
    call: allCallSelector(state, callId),
    savedCommunication: savedCommunicationByCallIdSelector(state, callId),
  }),
  (dispatch: Dispatch<AppAction>) => ({
    saveCommunication: (payload) => dispatch(createCommunication(payload)),
    getSavedCommunication: (callId) => dispatch(getSavedCommunication({ callId })),
  }),
)(ClioCommunicationLogForm);
