import Push from 'push.js';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsCategory, defineTrackingEvents, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { BellInactiveIcon, LockedActiveIcon } from '@getgo/chameleon-icons/react';
import { getNotificationSupportLinkByBroswer } from './notificationPermissions.service';
import { Typography } from '../theme/Typography.component';
import { GotoIntegrationsLogo } from '../components/GotoIntegrationsLogo.component';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  PERMISSION_REQUEST_ALLOW_MESSAGE: {
    id: 'Notification.Permission.Page.AllowPermission',
    defaultMessage: 'Set notification permissions',
  },
  PERMISSION_REQUEST_DESCRIPTION: {
    id: 'Notification.Permission.Page.Description',
    defaultMessage:
      'To receive calls with GoToConnect, enable web notifications for this site. Click the button below to set permissions.',
  },
  PERMISSION_REQUEST_DISABLED_TITLE: {
    id: 'Notification.Permission.Page.Disabled.Title',
    defaultMessage: 'Allow web notifications',
  },
  PERMISSION_REQUEST_DISABLED_DESCRIPTION: {
    id: 'Notification.Permission.Page.Disabled.Description',
    defaultMessage:
      'To receive calls with GoToConnect, enable web notifications on this site. Follow the steps below or {link}.',
  },
  PERMISSION_REQUEST_DISABLED_DESCRIPTION_LINK: {
    id: 'Notification.Permission.Page.Disabled.Description.Link',
    defaultMessage: 'change your browser settings',
  },
  PERMISSION_REQUEST_DISABLED_STEPS_1: {
    id: 'Notification.Permission.Page.Disabled.Steps.1',
    defaultMessage: '1. Click on the lock icon {icon} before the URL.',
  },
  PERMISSION_REQUEST_DISABLED_STEPS_2: {
    id: 'Notification.Permission.Page.Disabled.Steps.2',
    defaultMessage: '2. Open the notification settings.',
  },
  PERMISSION_REQUEST_DISABLED_STEPS_3: {
    id: 'Notification.Permission.Page.Disabled.Steps.3',
    defaultMessage: '3. Allow web notifications.',
  },
});

const trackingEvents = defineTrackingEvents({
  SET_PERMISSION_CLICKED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemClicked,
    label: 'Set notification permissions | button',
  },
  PERMISSIONS_ENABLED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemChanged,
    label: 'Notification permissions | enabled',
  },
  PERMISSIONS_DISABLED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemChanged,
    label: 'Notification permissions | disabled',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    rightContainer: {
      backgroundColor: tokens.neutral050,
    },
    video: {
      maxWidth: '80%',
    },
    buttonIcon: {
      width: 20,
      height: 20,
    },
    steps: {
      '& > p': {
        marginBottom: theme.spacing(2),
      },
    },
    stepsIcon: {
      verticalAlign: 'middle',
      display: 'inline-flex',
    },
  }),
);

const helperAnimation = require('../assets/notif-permissions.webm');

export const NotificationPermissions: React.FC = () => {
  const classes = useStyles();
  const [permissionState, setPermissionState] = useState(Push.Permission.get());
  const [isRequestingPermission, setIsRequestingPermission] = useState(false);

  if (permissionState === Push.Permission.GRANTED) {
    window.close();
    return null;
  }

  const onGranted = () => {
    setIsRequestingPermission(false);
    newTracker.trackAnalyticsEvent(trackingEvents.PERMISSIONS_ENABLED);
    setPermissionState(Push.Permission.GRANTED);
    window.close();
  };

  const onDenied = () => {
    setIsRequestingPermission(false);
    newTracker.trackAnalyticsEvent(trackingEvents.PERMISSIONS_DISABLED);
    setPermissionState(Push.Permission.DENIED);
  };

  const allowNotification = () => {
    newTracker.trackAnalyticsEvent(trackingEvents.SET_PERMISSION_CLICKED);

    setIsRequestingPermission(true);
    Push.Permission.request(onGranted, onDenied);
  };

  const renderPermissionRequest = () => {
    return (
      <>
        <Typography variant="heading-small">
          <FormattedMessage {...definedMessages.PERMISSION_REQUEST_ALLOW_MESSAGE} />
        </Typography>
        <Box mt={4} mb={4} maxWidth="50%">
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.PERMISSION_REQUEST_DESCRIPTION} />
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={isRequestingPermission}
          onClick={() => allowNotification()}
        >
          <Box mr={1} flex>
            <BellInactiveIcon className={classes.buttonIcon} />
          </Box>
          <FormattedMessage {...definedMessages.PERMISSION_REQUEST_ALLOW_MESSAGE} />
        </Button>
      </>
    );
  };

  const renderPermissionDenied = () => {
    const supportLink = getNotificationSupportLinkByBroswer();

    return (
      <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <Typography variant="heading-small">
          <FormattedMessage {...definedMessages.PERMISSION_REQUEST_DISABLED_TITLE} />
        </Typography>
        <Box mt={4} mb={4} maxWidth="50%">
          <Typography variant="body-small" color="text-02">
            <FormattedMessage
              {...definedMessages.PERMISSION_REQUEST_DISABLED_DESCRIPTION}
              values={{
                link: (
                  <a href={supportLink} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage {...definedMessages.PERMISSION_REQUEST_DISABLED_DESCRIPTION_LINK} />
                  </a>
                ),
              }}
            />
          </Typography>
        </Box>
        <Box className={classes.steps}>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage
              {...definedMessages.PERMISSION_REQUEST_DISABLED_STEPS_1}
              values={{
                icon: (
                  <Box display="inline-block">
                    <LockedActiveIcon size="16px" />
                  </Box>
                ),
              }}
            />
          </Typography>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.PERMISSION_REQUEST_DISABLED_STEPS_2} />
          </Typography>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.PERMISSION_REQUEST_DISABLED_STEPS_3} />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" width="100%" height="100%">
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={8}>
          <GotoIntegrationsLogo width={215} />
        </Box>
        <Box mb={2}>
          <BellInactiveIcon size="64px" color="var(--goto-icon-04)" />
        </Box>
        {permissionState === Push.Permission.DEFAULT ? renderPermissionRequest() : renderPermissionDenied()}
      </Box>
      <Box width="100%" display="flex" justifyContent="center" className={classes.rightContainer}>
        <video src={helperAnimation} autoPlay loop className={classes.video} />
      </Box>
    </Box>
  );
};
