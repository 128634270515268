import axios from 'axios';
import {
  CONNECTWISE_API_PATH,
  ConnectWiseContact,
  ConnectWisePhoneNumber,
  ConnectWisePhoneNumberType,
  ConnectWisePhoneNumberTypes,
  ConnectWiseNote,
  ConnectWiseNoteType,
  ConnectWiseNewNote,
  NewConnectWiseContact,
  Company,
} from './connectwise.model';
import { Entity } from '../models';
import { getDigitsFromString } from '../phone/phone-utils';

export const ConnectWiseApiUri = {
  NOTETYPES: `${CONNECTWISE_API_PATH}/company/noteTypes`,
  CONTACTS: `${CONNECTWISE_API_PATH}/company/contacts`,
  NEWNOTE: (contactId: string) => `${CONNECTWISE_API_PATH}/company/contacts/${contactId}/notes`,
  COMPANIES: `${CONNECTWISE_API_PATH}/company/companies`,
  SERVICE_TICKETS: `${CONNECTWISE_API_PATH}/service/tickets`,
};

const searchContactsByNumber = async (phoneNumber: string): Promise<Entity[]> => {
  const normalizedPhoneNumber = getDigitsFromString(phoneNumber);
  const phoneNumberCondition = normalizedPhoneNumber.length > 4 ? ` like "*${phoneNumber}*"` : `="${phoneNumber}"`;
  const response = await axios.get<ConnectWiseContact[]>(
    `${ConnectWiseApiUri.CONTACTS}?childconditions=communicationItems/communicationType="Phone" and communicationItems/value${phoneNumberCondition}`,
  );
  const retrievedContacts = response.data.filter((contact) =>
    getDigitsFromString(contact.defaultPhoneNbr).includes(normalizedPhoneNumber),
  );

  return extractContactsFromResponse(retrievedContacts);
};

const searchContactsByName = async (name: string): Promise<Entity[]> => {
  const conditionFragments = name
    .split(' ')
    .map(escapeSpecialCharacters)
    .map(
      (nameFragment) =>
        `(firstname contains "${nameFragment}" or lastname contains "${nameFragment}" or company/name contains "${nameFragment}")`,
    );

  const conditions = conditionFragments.join(' and ');

  const response = await axios.get<ConnectWiseContact[]>(ConnectWiseApiUri.CONTACTS, { params: { conditions } });

  return extractContactsFromResponse(response.data);
};

const getPhonesOfContact = async (contactId: string): Promise<ConnectWisePhoneNumber[]> => {
  const conditions = 'communicationType="Phone"';
  const url = `${ConnectWiseApiUri.CONTACTS}/${contactId}/communications`;
  const response = await axios.get<ConnectWisePhoneNumber[]>(url, { params: { conditions } });
  return response.data;
};

const getNotesOfContact = async (contactId: string): Promise<ConnectWiseNote[]> => {
  const limit = 50;
  const url = `${ConnectWiseApiUri.CONTACTS}/${contactId}/notes?orderBy=_info/lastUpdated%20desc&pagesize=${limit}`;
  const response = await axios.get<ConnectWiseNote[]>(url);
  return response.data;
};

const getNoteTypes = async (): Promise<ConnectWiseNoteType[]> => {
  const response = await axios.get<ConnectWiseNoteType[]>(`${ConnectWiseApiUri.NOTETYPES}/?pageSize=1000`);

  if (response.data && response.data.length === 1000) {
    console.error('ConnectWise max note type reached, we should implement paging');
  }

  return response.data;
};

const addNote = async (contactId: string, note: ConnectWiseNewNote): Promise<string> => {
  const url = ConnectWiseApiUri.NEWNOTE(contactId);
  const response = await axios.post(url, note);
  return String(response.data.id || '');
};

// https://developer.connectwise.com/Best_Practices/Manage_API_Requests?mt-learningpath=manage#Patch
const updateContactPhoneNumber = async (contactId: string, phoneNumber: string): Promise<void> => {
  const phoneNumberToSave = getDigitsFromString(phoneNumber);
  const phoneNumbers = await getPhonesOfContact(contactId);
  const directPhone = phoneNumbers.find((phone) => phone.type.name === ConnectWisePhoneNumberType.Direct);

  if (directPhone) {
    const url = `${ConnectWiseApiUri.CONTACTS}/${contactId}/communications/${directPhone.id}`;
    const body = [
      {
        op: 'replace',
        path: 'value',
        value: phoneNumberToSave,
      },
    ];
    await axios.patch(url, body);
  } else {
    const url = `${ConnectWiseApiUri.CONTACTS}/${contactId}/communications`;
    const body = {
      type: ConnectWisePhoneNumberTypes[ConnectWisePhoneNumberType.Direct],
      value: phoneNumberToSave,
      communicationType: 'Phone',
    };
    await axios.post(url, body);
  }
};

const createContact = async (contact: NewConnectWiseContact): Promise<string> => {
  contact.communicationItems = contact.communicationItems.map((phoneNumber) => ({
    ...phoneNumber,
    value: getDigitsFromString(phoneNumber.value),
  }));
  const response = await axios.post<ConnectWiseContact>(ConnectWiseApiUri.CONTACTS, contact);

  return response.data.id;
};

const getCompanies = async (): Promise<Company[]> => {
  const response = await axios.get<Company[]>(`${ConnectWiseApiUri.COMPANIES}/?pageSize=1000`);

  if (response.data && response.data.length === 1000) {
    console.error('ConnectWise max companies reached, we should implement paging');
  }

  return response.data;
};

const saveServiceTicket = async (contactId: number, companyId: number, summary: string): Promise<void> => {
  const body = {
    contact: { id: contactId },
    company: { id: companyId },
    summary,
  };

  await axios.post(ConnectWiseApiUri.SERVICE_TICKETS, body);
};

const extractContactsFromResponse = (contacts: ConnectWiseContact[]): Entity[] => {
  return contacts.map((contact) => ({
    id: contact.id,
    label: `${contact.firstName} ${contact.lastName}`,
    name: `${contact.firstName} ${contact.lastName}`,
    phoneNumber: contact.defaultPhoneNbr,
    type: 'Contact',
  }));
};

// https://developer.connectwise.com/Products/Manage/Developer_Guide#Escaping%20Characters
const escapeSpecialCharacters = (input: string): string => {
  return input.replace(/\\/gm, '\\\\').replace(/"/gm, '\\"');
};

export const ConnectWiseService = {
  searchContactsByNumber,
  searchContactsByName,
  getPhonesOfContact,
  getNotesOfContact,
  getNoteTypes,
  addNote,
  updateContactPhoneNumber,
  createContact,
  getCompanies,
  saveServiceTicket,
};
