import { NoteActionCreator } from './note.actioncreator';
import { AddNoteModel } from './note.actioncreator.models';
import { showMessage } from '../../../inAppNotification/message.action';
import { defineMessages } from 'react-intl';
import { MessageVariant } from '../../../models';
import { callHistoryElementByCallSelector } from '../../../callHistoryPush/unifiedCallHistory.selector';
import { CallWithContact } from '../../../calls/calls.reducer';

export const definedMessages = defineMessages({
  NEW_NOTE_FORM_SAVE_DELAYED_MESSAGE: {
    id: 'Notes.NewForm.SaveDelayed.Message',
    defaultMessage: 'This note will be saved when the call is ended.',
  },
});

export abstract class AsyncAddNoteActionCreator extends NoteActionCreator {
  protected async _addNoteWhenTheCallEnds(
    call: CallWithContact,
    notePayload: AddNoteModel,
  ): Promise<Optional<{ noteId: string }>> {
    this.store.dispatch(
      showMessage({
        message: definedMessages.NEW_NOTE_FORM_SAVE_DELAYED_MESSAGE,
        type: MessageVariant.Info,
        id: `CREATE_NOTE_DELAYED_MESSAGE_${call.id}`,
        params: {
          autoHide: true,
          dismissible: true,
        },
      }),
    );
    return new Promise((resolve) => {
      const unsubscribe = this.store.subscribe(() => {
        const endedCall = callHistoryElementByCallSelector(call)(this.store.getState());
        if (endedCall) {
          unsubscribe();
          const addNoteResult = this._addNote(notePayload);
          resolve(addNoteResult);
        }
      });
    });
  }
}
