import React, { useEffect } from 'react';
import NotesList from '../notes/NotesList.container';
import { CallContextPanel, CallContextPanels } from './CallContextPanels.component';
import { defineMessages } from 'react-intl';
import { NoteIcon, HistoryIcon } from '@getgo/chameleon-icons/react';
import { Entity } from '../models';
import { Opportunities } from '../salesforce/opportunities/Opportunities.container';
import { Cases } from '../salesforce/cases/Cases.container';
import { getActionFacade } from '../actionFacade/action.facade.store';
import {
  CAPABILITY_DOWNLOAD_NOTES,
  CAPABILITY_LOAD_NOTES_COUNT,
  LoadNotesCountCapableFacade,
  CAPABILITY_DOWNLOAD_OPPORTUNITIES,
  CAPABILITY_DOWNLOAD_CASES,
  DownloadOpportunitiesCapableFacade,
  DownloadCasesCapableFacade,
  CAPABILITY_VEHICLES,
} from '../actionFacade/action.facade.capabilities';
import { AnalyticsCategory } from '../analytics-new/analytics.models';
import { VehiclesCapableFacade } from '../actionFacade/action.facade.capabilities';
import { CallContextPanelProps } from './CallContextPanel.models';
import { Vehicles } from '../cox/vehicles/Vehicles.container';
import { EntityType } from '../constants';
import { Customer } from '../cox/contact/cox.customer.models';
import { Integrations } from '../models/index';
import { ContactCallHistory } from '../contactCallHistory/ContactCallHistory.container';
import { IS_CALL_HISTORY_ENABLED } from '../cox/cox.flags';
import { tokens } from '@getgo/chameleon-core';
import { useSelector } from 'react-redux';
import { hasAccessToEntity } from '../salesforce/salesforce.selectors';
import { BulbInactiveIcon, HealthcareInactiveIcon } from '@getgo/chameleon-icons/react';

export interface CallContextSectionStateProps {
  currentIntegration: Integrations;

  contact?: Entity;
  notesCount?: number;
  opportunitiesCount?: number;
  casesCount?: number;
  vehicleCount?: number;
  coxCustomer?: Customer;
  contactCallHistoryCount?: number;

  isDisplayingCasesEnabled: boolean;
  isDisplayingOpportunitiesEnabled: boolean;
  isDisplayingCallHistoryEnabled: boolean;

  isCallHistoryExpandedByDefault: boolean;
}

export interface CallContextSectionProps {
  callId: string;
}

const definedMessages = defineMessages({
  NOTES_TITLE: {
    id: 'Notes.List.Header',
    defaultMessage: 'Notes',
  },
  SALESFORCE_NOTES_TITLE: {
    id: 'Notes.List.Header.Salesforce',
    defaultMessage: 'Contact Notes',
  },
  OPPORTUNITIES_TITLE: {
    id: 'Opportunities.List.Header',
    defaultMessage: 'Opportunities',
  },
  CASES_TITLE: {
    id: 'Cases.List.Header',
    defaultMessage: 'Cases',
  },
  VEHICLES_TITLE: {
    id: 'Cox.Vehicles.List.Header',
    defaultMessage: 'Vehicles',
  },
  CALLHISTORY_TITLE: {
    id: 'CallHistory.History.Title',
    defaultMessage: 'Call history',
  },
});

export const CallContextSectionComponent: React.FC<CallContextSectionStateProps & CallContextSectionProps> = ({
  callId,
  contact,
  notesCount,
  opportunitiesCount,
  casesCount,
  vehicleCount,
  coxCustomer,
  contactCallHistoryCount,
  isDisplayingCasesEnabled,
  isDisplayingOpportunitiesEnabled,
  isDisplayingCallHistoryEnabled,
  isCallHistoryExpandedByDefault,
  currentIntegration,
}) => {
  const facade = getActionFacade();
  const isDisplayingNotesSupported = facade.isCapable(CAPABILITY_DOWNLOAD_NOTES);
  const isDisplayingNotesCountSupported = facade.isCapable(CAPABILITY_LOAD_NOTES_COUNT);
  const isDisplayingOpportunitiesSupported = facade.isCapable(CAPABILITY_DOWNLOAD_OPPORTUNITIES);
  const isDisplayingCasesSupported = facade.isCapable(CAPABILITY_DOWNLOAD_CASES);
  const isDisplayingVehiclesSupported = facade.isCapable(CAPABILITY_VEHICLES);

  const hasAccessToCases = useSelector(hasAccessToEntity(EntityType.CASE));
  const hasAccessToOpportunities = useSelector(hasAccessToEntity(EntityType.OPPORTUNITY));

  useEffect(() => {
    if (!contact) {
      return;
    }

    const isCustomerLoaded = coxCustomer?.id === contact.id;

    if (isDisplayingNotesCountSupported) {
      void (facade as LoadNotesCountCapableFacade).loadNotesCount(contact.id);
    }

    if (isDisplayingOpportunitiesSupported && isDisplayingOpportunitiesEnabled && hasAccessToOpportunities) {
      void (facade as DownloadOpportunitiesCapableFacade).getOpportunitiesCount(contact.id);
    }

    if (isDisplayingCasesSupported && isDisplayingCasesEnabled && hasAccessToCases) {
      void (facade as DownloadCasesCapableFacade).getCasesCount(contact.id);
    }

    if (isDisplayingVehiclesSupported && !isCustomerLoaded) {
      void (facade as VehiclesCapableFacade).loadCustomer(contact.id);
    }

    if (isDisplayingVehiclesSupported && isCustomerLoaded) {
      void (facade as VehiclesCapableFacade).loadVehicles(coxCustomer?.vehicles ?? []);
      void (facade as VehiclesCapableFacade).loadOpenRepairOrdersForVehicles(coxCustomer?.vehicles ?? []);
    }
  }, [
    contact,
    facade,
    isDisplayingCasesSupported,
    isDisplayingOpportunitiesSupported,
    isDisplayingCasesEnabled,
    isDisplayingOpportunitiesEnabled,
    isDisplayingNotesCountSupported,
    isDisplayingVehiclesSupported,
    coxCustomer,
    hasAccessToOpportunities,
    hasAccessToCases,
  ]);

  if (
    !isDisplayingNotesSupported &&
    !isDisplayingOpportunitiesSupported &&
    !isDisplayingCasesSupported &&
    !isDisplayingVehiclesSupported &&
    !isDisplayingCallHistoryEnabled
  ) {
    return null;
  }

  if (!contact) {
    return <CallContextPanels callId={callId} panels={[]} />;
  }

  const panels: CallContextPanel[] = [];

  const contextProps: CallContextPanelProps = {
    callId,
    contact,
  };

  const shouldDisplayOpportunities =
    isDisplayingOpportunitiesSupported &&
    isDisplayingOpportunitiesEnabled &&
    contact &&
    [EntityType.CONTACT, EntityType.ACCOUNT].includes(contact.type as EntityType) &&
    hasAccessToOpportunities;

  const shouldDisplayCases =
    isDisplayingCasesSupported &&
    isDisplayingCasesEnabled &&
    contact &&
    [EntityType.CONTACT, EntityType.ACCOUNT].includes(contact.type as EntityType) &&
    hasAccessToCases;

  const shouldDisplayCallhistory =
    IS_CALL_HISTORY_ENABLED && isDisplayingCallHistoryEnabled && currentIntegration === Integrations.Cox;

  const iconDimensions = {
    width: 20,
    height: 20,
  };

  if (isDisplayingNotesSupported) {
    // no contact, no notes
    panels.push({
      id: 'Notes',
      trackingCategory: AnalyticsCategory.Note,
      icon: <NoteIcon style={{ color: tokens.icon04, ...iconDimensions }} />,
      title:
        currentIntegration === Integrations.SalesforceLightning || currentIntegration === Integrations.SalesforceClassic
          ? definedMessages.SALESFORCE_NOTES_TITLE
          : definedMessages.NOTES_TITLE,
      body: <NotesList {...contextProps} />,
      itemCount: isDisplayingNotesCountSupported ? notesCount : undefined,
      isOpenByDefault: true,
    });
  }

  if (shouldDisplayOpportunities) {
    // no contact, no opportunities
    panels.push({
      id: 'Opportunities',
      trackingCategory: AnalyticsCategory.Opportunity,
      icon: <BulbInactiveIcon style={iconDimensions} />,
      title: definedMessages.OPPORTUNITIES_TITLE,
      body: <Opportunities {...contextProps} />,
      itemCount: opportunitiesCount,
      isOpenByDefault: false,
    });
  }

  if (shouldDisplayCases) {
    // no contact, no cases
    panels.push({
      id: 'Cases',
      trackingCategory: AnalyticsCategory.Case,
      icon: <HealthcareInactiveIcon style={iconDimensions} />,
      title: definedMessages.CASES_TITLE,
      body: <Cases {...contextProps} />,
      itemCount: casesCount,
      isOpenByDefault: false,
    });
  }

  // we can only start to load the vehicles after the customer is loaded
  if (isDisplayingVehiclesSupported && coxCustomer) {
    panels.push({
      id: 'Vehicles',
      trackingCategory: AnalyticsCategory.Vehicle,
      icon: null,
      title: definedMessages.VEHICLES_TITLE,
      body: <Vehicles {...contextProps} />,
      itemCount: vehicleCount,
      isOpenByDefault: true,
    });
  }

  if (shouldDisplayCallhistory) {
    panels.push({
      id: 'Call history',
      trackingCategory: AnalyticsCategory.Callhistory,
      icon: <HistoryIcon />,
      title: definedMessages.CALLHISTORY_TITLE,
      body: <ContactCallHistory {...contextProps} />,
      itemCount: contactCallHistoryCount,
      isOpenByDefault: isCallHistoryExpandedByDefault,
    });
  }

  return <CallContextPanels callId={callId} panels={panels} />;
};
