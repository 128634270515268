import { Message } from '@ucc/messaging-platform-service';
import { MessageBody } from '@ucc/messaging-service';
import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { memoize } from 'lodash-es';
import { startOfDay } from 'date-fns';
import { RequestState } from './conversationMessages.reducer';

const conversationMessagesStateSelector = (state: AppState) => state.conversations.conversationMessages;

export const conversationMessagesSelector = memoize((conversationId: string) =>
  createSelector(
    conversationMessagesStateSelector,
    (state) => hasConversationMessagesNextPageSelector(conversationId)(state),
    (messages, hasNextPage): ReadonlyArray<Message<MessageBody>> => {
      const result = messages.byId[conversationId]?.results ?? [];
      if (result.length === 0 || !hasNextPage) {
        return result;
      }

      const firstDate = Math.min(...result.map((message) => new Date(message.metadata.timestamp).valueOf()));
      const firstDay = startOfDay(firstDate).valueOf();

      // We can't be sure if the first day (oldest message) is fully loaded, so we won't show it.
      return result.filter((message) => startOfDay(new Date(message.metadata.timestamp)).valueOf() !== firstDay);
    },
  ),
);

export const selectedConversationMessagesSelector = (conversationId: string) =>
  createSelector(
    conversationMessagesStateSelector,
    (messages): readonly string[] => messages.byId[conversationId]?.selectedMessages ?? [],
  );

export const conversationMessagesNextPageIdentifierSelector = (conversationId: string) =>
  createSelector(conversationMessagesStateSelector, (messages) => messages.byId[conversationId]?.pageIdentifier);

export const hasConversationMessagesNextPageSelector = (conversationId: string) =>
  createSelector(conversationMessagesStateSelector, (messages) => !!messages.byId[conversationId]?.pageIdentifier);

export const isConversationMessagesLoadingSelector = (conversationId: string) =>
  createSelector(
    conversationMessagesStateSelector,
    (messages) => messages.byId[conversationId]?.requestState === RequestState.LOADING,
  );

export const selectedMessagesSelector = memoize((conversationId: string) =>
  createSelector(
    conversationMessagesStateSelector,
    (messages): Array<Message<MessageBody>> => {
      const selectedMessageIds = messages.byId[conversationId]?.selectedMessages;

      return messages.byId[conversationId]?.results.filter((message) => selectedMessageIds.includes(message.id)) ?? [];
    },
  ),
);

export const logMessagesRequestStateSelector = createSelector(
  conversationMessagesStateSelector,
  (state) => state.conversationLogRequestState,
);

export const isMessageLogPendingSelector = createSelector(
  logMessagesRequestStateSelector,
  (state) => state === RequestState.LOADING,
);
