import { makeActionCreator } from '../actions/actionCreators';
import { User } from './dynamics.model';

export enum DynamicsActionsTypes {
  DYNAMICS_WHO_AM_I_SUCCESS = 'dynamics/whoAmI/success',
  DYNAMICS_WHO_AM_I = 'dynamics/whoAmI/get',
  DYNAMICS_WHO_AM_I_ERROR = 'dynamics/whoAmI/error',
}

export const dynamicsWhoAmI = makeActionCreator(DynamicsActionsTypes.DYNAMICS_WHO_AM_I)();
export const dynamicsWhoAmISuccess = makeActionCreator(DynamicsActionsTypes.DYNAMICS_WHO_AM_I_SUCCESS)<User>();
export const dynamicsWhoAmIError = makeActionCreator(DynamicsActionsTypes.DYNAMICS_WHO_AM_I_ERROR)();

export type DynamicsActions =
  | ReturnType<typeof dynamicsWhoAmI>
  | ReturnType<typeof dynamicsWhoAmISuccess>
  | ReturnType<typeof dynamicsWhoAmIError>;
