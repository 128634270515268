import { connect } from 'react-redux';
import { isAuthenticatedSelector } from '../../authentication/authentication.selector';
import { AppState } from '../../reducers';
import {
  AuthButtonComponent,
  AuthButtonDispatchProps,
  AuthButtonProps,
  AuthButtonStateProps,
} from './../../authentication/AuthButton.component';
import { signInRequest, signOutRequest } from './authentication.actions';

export const MsTeamsAuthButton = connect<AuthButtonStateProps, AuthButtonDispatchProps, AuthButtonProps, AppState>(
  (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
  }),
  (dispatch) => ({
    signIn: () => dispatch(signInRequest()),
    signOut: () => dispatch(signOutRequest()),
  }),
)(AuthButtonComponent);
