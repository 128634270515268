import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import {
  latestRepairOrderByVINSelector,
  openRepairOrdersLoadRequestStateByVINSelector,
} from '../repairOrders/repairOrders.selectors';
import { VehicleStatusComponent, VehicleStatusProps, VehicleStatusStateProps } from './VehicleStatus.component';

const mapStateToProps = (state: AppState, ownProps: VehicleStatusProps): VehicleStatusStateProps => ({
  repairOrderStatus: latestRepairOrderByVINSelector(ownProps.vehicle.VIN)(state),
  openRepairOrdersRequestState: openRepairOrdersLoadRequestStateByVINSelector(ownProps.vehicle.VIN)(state),
});

export const VehicleStatus = connect<VehicleStatusStateProps, {}, VehicleStatusProps, AppState>(mapStateToProps)(
  VehicleStatusComponent,
);
