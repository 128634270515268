import { Store } from 'redux';
import { logger } from '../../../logging';
import {
  getMoreNotesError,
  GetMoreNotesRequestMeta,
  getMoreNotesSuccess,
  getNotesError,
  getNotesSuccess,
  GetNotesSuccessPayload,
  addNoteError,
  addNoteSuccess,
} from '../../../notes/notes.actions';
import { getNoteRequestMetaSelector } from '../../../notes/notes.selectors';
import { AppState } from '../../../reducers';
import { definedMessages } from '../../../inAppNotification/message.content';
import { AddNoteModel } from './note.actioncreator.models';
import { AnalyticsCategory, AnalyticsAction } from '../../../analytics-new/analytics.models';
import { newTracker } from '../../../analytics-new/tracker-new';
import { defineMessages } from 'react-intl';

export const createNoteDefinedMessages = defineMessages({
  CREATE_NOTE_ERROR: {
    id: 'Create.Note.Error',
    defaultMessage: 'There was an error while creating the note, please try again.',
  },
  CREATE_NOTE_SUCCESS: {
    id: 'Create.Call.Detail.Success',
    defaultMessage: 'Note created successfully!',
  },
});

export abstract class NoteActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async loadMostRecentNoteOfContact(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose notes should be queried!');
      this.store.dispatch(getNotesError({ message: definedMessages.GET_NOTES_ERROR }));
      return;
    }
    try {
      const notesWithMeta = await this._loadMostRecentNoteOfContact(contactId);
      this.store.dispatch(getNotesSuccess(notesWithMeta));
    } catch (error) {
      logger.error('Error getting notes of contact', error);
      this.store.dispatch(getNotesError({ message: definedMessages.GET_NOTES_ERROR }));
    }
  }

  public async loadMoreNotesOfContact(): Promise<void> {
    const state = this.store.getState();

    try {
      const requestMeta = getNoteRequestMetaSelector(state);

      if (!requestMeta || !requestMeta.next) {
        logger.error('There is no next url stored to load more notes from!');
        this.store.dispatch(
          getMoreNotesError({
            message: definedMessages.GET_NOTES_ERROR,
          }),
        );
        return;
      }

      const notesWithMeta = await this._loadMoreNotesOfContact(requestMeta);
      this.store.dispatch(getMoreNotesSuccess(notesWithMeta));
    } catch (error) {
      logger.error('Error getting notes of contact', error);
      this.store.dispatch(
        getMoreNotesError({
          message: definedMessages.GET_NOTES_ERROR,
        }),
      );
    }
  }

  public async addNote(note: AddNoteModel): Promise<void> {
    const { contactId } = note;

    if (!contactId) {
      logger.error('contactId is undefined or empty in AddNoteActionCreator.addNote');
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Note,
        action: AnalyticsAction.NoteCreationFailed,
        label: 'contactId is undefined or empty in AddNoteActionCreator.addNote',
      });
      this.store.dispatch(addNoteError({ message: createNoteDefinedMessages.CREATE_NOTE_ERROR }));
      return;
    }

    try {
      // Persist note
      const result = await this._addNote(note);

      // JIF-4903: only log actual notes which aren't made just for call logging purposes.
      if (note.content?.trim()) {
        newTracker.trackAnalyticsEvent({
          category: AnalyticsCategory.Note,
          action: AnalyticsAction.NoteCreationSucceeded,
          label: result && result.noteId ? '' + result.noteId : 'Note id undefined',
        });
      }

      this._handleAddNoteSuccess(contactId, note);
    } catch (error) {
      logger.error('Error saving note', error);
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Note,
        action: AnalyticsAction.NoteCreationFailed,
        label: error.message || error.statusText || 'Unknown issue while creating note',
      });
      this.store.dispatch(addNoteError({ message: createNoteDefinedMessages.CREATE_NOTE_ERROR }));
    }
  }

  protected _handleAddNoteSuccess(contactId: string, _note: AddNoteModel): void {
    // Show toaster
    this.store.dispatch(
      addNoteSuccess({
        message: createNoteDefinedMessages.CREATE_NOTE_SUCCESS,
        contactId,
      }),
    );
  }

  protected abstract _loadMostRecentNoteOfContact(contactId: string): Promise<GetNotesSuccessPayload>;
  protected abstract _loadMoreNotesOfContact(moreNotesMeta: GetMoreNotesRequestMeta): Promise<GetNotesSuccessPayload>;
  protected abstract _addNote(note: AddNoteModel): Promise<Optional<{ noteId: string }>>;
}
