import { CallIdentity } from '@jive/realtime-events';
import { MessageDescriptor } from 'react-intl';
import { Entity } from '../models';
import { makeActionCreator } from './actionCreators';

export enum ContactActionsType {
  UPDATE_ENTITY_PHONE_NUMBER = 'entity/updateEntityPhoneNumber',
  UPDATE_ENTITY_PHONE_NUMBER_SUCCESS = 'entity/updateEntityPhoneNumberSuccess',
  UPDATE_ENTITY_PHONE_NUMBER_ERROR = 'entity/updateEntityPhoneNumberError',
  CONTACT_ASSOCIATE = 'contacts/associate',
  CREATE_CONTACT_ERROR = 'contacts/createError',
  CONTACT_MATCHING_REQUESTED = 'contacts/contactMatchingRequested',
  CONTACT_FOUND = 'contacts/contactFound',
  CONTACT_NOT_FOUND = 'contacts/contactNotFound',
}

export interface UpdateEntityPhoneNumberPayload {
  contactId: string;
  phoneNumber: string;
}

export const updateEntityPhoneNumber = makeActionCreator(
  ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER,
)<UpdateEntityPhoneNumberPayload>();

export const updateEntityPhoneNumberSuccess = makeActionCreator(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_SUCCESS)<{
  contactId: string;
  message: MessageDescriptor;
}>();

export const updateEntityPhoneNumberError = makeActionCreator(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_ERROR)<{
  contactId: string;
  message: MessageDescriptor;
}>();

export interface ContactAssociatePayload {
  entityId: string | undefined;
  callId: string;
  entities: Entity[] | undefined;
  isAutoSingleMatch: boolean;
}
export const associateContact = makeActionCreator(ContactActionsType.CONTACT_ASSOCIATE)<ContactAssociatePayload>();

export type AssociateContactAction = ReturnType<typeof associateContact>;

export const createContactError = makeActionCreator(ContactActionsType.CREATE_CONTACT_ERROR)<{
  phoneNumber: string;
  message: MessageDescriptor;
}>();

export interface ContactFoundPayload {
  callId: string;
  allMatches: Entity[];
  theOtherParty: CallIdentity;
  autoSingleMatch?: Entity;
}

export interface ContactNotFoundPayload {
  callId: string;
  phoneNumber: string;
}
export const contactFound = makeActionCreator(ContactActionsType.CONTACT_FOUND)<ContactFoundPayload>();
export type ContactFoundAction = ReturnType<typeof contactFound>;

export const contactNotFound = makeActionCreator(ContactActionsType.CONTACT_NOT_FOUND)<ContactNotFoundPayload>();
export type ContactNotFoundAction = ReturnType<typeof contactNotFound>;

export interface ContactMatchingRequestedPayload {
  callId: string;
}

export const contactMatchingRequested = makeActionCreator(
  ContactActionsType.CONTACT_MATCHING_REQUESTED,
)<ContactMatchingRequestedPayload>();
export type ContactMatchingRequestedAction = ReturnType<typeof contactMatchingRequested>;

export type ContactsActions =
  | ReturnType<typeof updateEntityPhoneNumber>
  | ReturnType<typeof updateEntityPhoneNumberSuccess>
  | ReturnType<typeof updateEntityPhoneNumberError>
  | AssociateContactAction
  | ReturnType<typeof createContactError>
  | ContactFoundAction
  | ContactNotFoundAction
  | ContactMatchingRequestedAction;
