import { useSelector } from 'react-redux';
import { Entitlements } from './authentication.models';
import { entitlementsSelector, hasSeenLicenseWarningSelector } from './authentication.selector';

// Logic based on: https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=~johnspa&title=GoToConnect+Licenses
export const useEntitlements = () => {
  // Fallback when the user is logged in during the update and has a state saved in IndexedDb without the entitlements array.
  // As the user was using the GTC integration, it's safe to assume, that he or she has the entitlements to it.
  const entitlements = useSelector(entitlementsSelector) ?? [Entitlements.Jive];
  const hasGTCLicense =
    entitlements.includes(Entitlements.GoToConnect) ||
    entitlements.includes(Entitlements.Hvoice) ||
    entitlements.includes(Entitlements.Jive);
  const hasGTMLicense = entitlements.includes(Entitlements.G2M) || entitlements.includes(Entitlements.GoToConnect);

  const hasNoLicense = !hasGTCLicense && !hasGTMLicense;
  const hasSeenLicenseWarning = useSelector(hasSeenLicenseWarningSelector);

  return { hasGTCLicense, hasGTMLicense, hasNoLicense, hasSeenLicenseWarning };
};
