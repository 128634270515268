import { Call } from '@jive/realtime-events';
import { closeIntegrationPanel, integrationPanelOpenElsewhere, openIntegrationPanel } from './embedded.action';
import { activeCallsSelector } from '../calls/calls.selector';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { isIntegrationPanelShownForCallSelector } from './embedded.selector';
import { salesforceService } from '../salesforce/salesforceLightning.service';
import { logger } from '../logging';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';

export class EmbeddedBroadcastChannel {
  private broadcastChannel: BroadcastChannel | undefined;
  private store: Store<AppState> | undefined;

  initialize(store: Store<AppState>): void {
    this.broadcastChannel = window.BroadcastChannel
      ? new window.BroadcastChannel('goto-embedded-integrations-broadcast-channel')
      : undefined;
    this.store = store;
    try {
      this.listenToBroadcastChannelEvents();
      this.listenToDocumentVisibilityChange();
      this.listenToIntegrationPanelCloseEvent();
    } catch (e) {
      logger.error('error initializing embedded broadcast channel', e);
    }
  }

  integrationPanelShownForCall(call: Call): void {
    this.broadcastChannel?.postMessage({ type: 'integrationPanelShownForCall', call });
  }

  private integrationPanelClosedByUser(): void {
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Application,
      action: AnalyticsAction.ItemClicked,
      label: 'Salesforce Integration Panel | minimize button',
    });
    this.broadcastChannel?.postMessage({ type: 'integrationPanelClosedByUser' });
  }

  private listenToBroadcastChannelEvents(): void {
    if (!this.broadcastChannel) {
      return;
    }
    this.broadcastChannel.addEventListener('message', (event) => {
      if (!this.store) {
        return;
      }
      switch (event.data?.type) {
        case 'integrationPanelShownForCall': {
          this.store.dispatch(integrationPanelOpenElsewhere({ call: event.data?.call }));
          return;
        }
        case 'integrationPanelClosedByUser': {
          this.store.dispatch(closeIntegrationPanel());
          return;
        }
      }
    });
  }

  private listenToDocumentVisibilityChange(): void {
    document.addEventListener('visibilitychange', () => this.handleVisibilityChange(), false);
  }

  private listenToIntegrationPanelCloseEvent(): void {
    let wasPanelVisible = false;

    const intervalId = setInterval(async () => {
      try {
        const isPanelVisible = await salesforceService.isSoftphonePanelVisible();
        const isPanelClosedByUser = !isPanelVisible && wasPanelVisible && document.visibilityState === 'visible';
        if (isPanelClosedByUser) {
          this.integrationPanelClosedByUser();
        }
        wasPanelVisible = isPanelVisible;
      } catch (e) {
        logger.error('Error checking panel visibility state', e);
        clearInterval(intervalId);
      }
    }, 500);
  }

  private handleVisibilityChange(): void {
    if (!this.store || document.visibilityState !== 'visible') {
      return;
    }
    const state = this.store.getState();
    const activeCalls = activeCallsSelector(state);
    if (!activeCalls.length) {
      return;
    }

    const wasPanelShownForActiveCallsElsewhere = activeCalls.some((c) =>
      isIntegrationPanelShownForCallSelector(c.id)(state),
    );
    if (wasPanelShownForActiveCallsElsewhere) {
      return;
    }

    const call = activeCalls.find((c) => !isIntegrationPanelShownForCallSelector(c.id)(state));
    this.store.dispatch(openIntegrationPanel({ reason: 'Call', call }));
  }
}

export const embeddedBroadcastChannel = new EmbeddedBroadcastChannel();
