import { makeActionCreator } from '../actions/actionCreators';
import { User } from './clio.models';

export enum ClioActionsTypes {
  CLIO_WHO_AM_I_SUCCESS = 'clio/whoAmI/success',
  CLIO_WHO_AM_I = 'clio/whoAmI/get',
  CLIO_WHO_AM_I_ERROR = 'clio/whoAmI/error',
}

export const clioWhoAmI = makeActionCreator(ClioActionsTypes.CLIO_WHO_AM_I)();
export const clioWhoAmISuccess = makeActionCreator(ClioActionsTypes.CLIO_WHO_AM_I_SUCCESS)<User>();
export const clioWhoAmIError = makeActionCreator(ClioActionsTypes.CLIO_WHO_AM_I_ERROR)();

export type ClioActions =
  | ReturnType<typeof clioWhoAmI>
  | ReturnType<typeof clioWhoAmISuccess>
  | ReturnType<typeof clioWhoAmIError>;
