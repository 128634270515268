import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { definedMessages } from '../inAppNotification/message.content';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { Banner } from '../inAppNotification/Banner';
import Box from '@material-ui/core/Box/Box';
import { TrackedButton } from '../analytics-new/TrackedButton.component';

export interface WebsocketConnectionErrorMessageDispatchProps {
  onRestartApp(): void;
}

const trackingEvents = defineTrackingEvents({
  WEBSOCKET_CONNECTION_ERROR_MESSAGE_SHOWN: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PopupShown,
    label: "Can't connect to GoToConnect Check your internet connection",
  },
  WEBSOCKET_CONNECTION_ERROR_MESSAGE_RESTART_CLICKED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: 'Restart app | button | Websocket reconnect',
  },
});

export const WebsocketConnectionErrorMessageComponent: React.FC<{} & WebsocketConnectionErrorMessageDispatchProps> = ({
  onRestartApp,
}) => {
  useEffect(() => {
    newTracker.trackAnalyticsEvent(trackingEvents.WEBSOCKET_CONNECTION_ERROR_MESSAGE_SHOWN);
  }, []);

  const Message = () => (
    <>
      <Box fontWeight="bold">
        <FormattedMessage {...definedMessages.WEBSOCKET_CONNECTION_ERROR_MESSAGE_TITLE} />
      </Box>
      <Box>
        <FormattedMessage {...definedMessages.WEBSOCKET_CONNECTION_ERROR_MESSAGE_DESC} />
      </Box>
    </>
  );

  const Actions = () => (
    <TrackedButton
      color="default"
      variant="neutral-inverse"
      size="small"
      trackingEvent={trackingEvents.WEBSOCKET_CONNECTION_ERROR_MESSAGE_RESTART_CLICKED}
      onClick={onRestartApp}
    >
      <FormattedMessage {...definedMessages.WEBSOCKET_CONNECTION_ERROR_MESSAGE_BUTTON_LABEL} />
    </TrackedButton>
  );

  return <Banner variant="danger" message={<Message />} actions={<Actions />} />;
};
