import { PhoneUpInactiveIcon, PhoneUpDisabledInactiveIcon } from '@getgo/chameleon-icons/react';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { RoundButton } from '@getgo/chameleon-web/react';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  CALL_BUTTON_LABEL: {
    id: 'Phone.Call',
    defaultMessage: 'Call',
  },
  DO_NOT_CALL_BUTTON_LABEL: {
    id: 'Call.DoNotCall.Tooltip',
    defaultMessage: 'Do not call!',
  },
});

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '150px',
  },
  callButtonContainer: () => ({
    background: tokens.ui01,
    borderRadius: '50%',
  }),
  callButton: (props: any) => ({
    '&::part(control)': {
      '--goto-icon-color': props.error ? tokens.textError01 : undefined,
    },
  }),
}));

interface CallButtonBaseProps {
  icon: JSX.Element;
  onClick: () => void;
  tooltip: MessageDescriptor;
  trackingEvent: TrackingEvent;
  error?: boolean;
  cypressId: string;
}

export interface CallButtonProps {
  onClick: () => void;
  trackingEvent: TrackingEvent;
}

const CallButtonBase: React.FC<CallButtonBaseProps> = ({ icon, onClick, tooltip, trackingEvent, error, cypressId }) => {
  const classes = useStyles({ error });
  const intl = useIntl();

  return (
    <div
      data-cy={cypressId}
      onClick={(e) => {
        newTracker.trackAnalyticsEvent(trackingEvent);
        onClick();
        e.stopPropagation();
      }}
      className={classes.callButtonContainer}
    >
      <Tooltip arrow classes={{ tooltip: classes.tooltip }} title={<FormattedMessage {...tooltip} />} enterDelay={500}>
        <RoundButton
          size="medium"
          variant="secondary"
          className={classes.callButton}
          aria-label={intl.formatMessage(tooltip)}
          role="button"
        >
          {icon}
        </RoundButton>
      </Tooltip>
    </div>
  );
};

export const CallButton: React.FC<CallButtonProps> = ({ onClick, trackingEvent }) => {
  return (
    <CallButtonBase
      cypressId="callButton"
      icon={<PhoneUpInactiveIcon aria-hidden />}
      tooltip={definedMessages.CALL_BUTTON_LABEL}
      onClick={onClick}
      trackingEvent={trackingEvent}
    />
  );
};

export const DoNotCallButton: React.FC<CallButtonProps> = ({ onClick, trackingEvent }) => {
  return (
    <CallButtonBase
      error
      cypressId="doNotCallButton"
      icon={<PhoneUpDisabledInactiveIcon aria-hidden />}
      tooltip={definedMessages.DO_NOT_CALL_BUTTON_LABEL}
      onClick={onClick}
      trackingEvent={trackingEvent}
    />
  );
};
