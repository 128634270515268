import add from 'date-fns/add';

export const getNextOneHourSlot = () => {
  const now = new Date(Date.now()); // using this format to get current time to make it easy to test this fn
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
  const minutes = now.getMinutes();
  const minutesToAdd = minutes === 0 ? 0 : minutes === 30 ? 30 : minutes < 30 ? 30 : 60;

  return {
    startDate: add(start, { minutes: minutesToAdd }),
    endDate: add(start, { minutes: minutesToAdd + 60 }),
  };
};

export const convertToAllDayEvent = (startDate: Date, endDate: Date): { startDate: Date; endDate: Date } => {
  return {
    startDate: new Date(
      Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), 0, 0, 0, 0),
    ),
    endDate: new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), 23, 59, 59, 999)),
  };
};
