import { combineReducers } from 'redux';
import { ambassadorReducer as ambassador } from '../ambassador/ambassador.reducer';
import { proxyReducer as proxy } from '../ambassador/proxy/proxy.reducer';
import authenticationProgress from '../authentication/authentication-progress.reducer';
import authentication from '../authentication/authentication.reducer';
import { unifiedCallHistoryReducer as unifiedCallHistory } from '../callHistoryPush/unifiedCallHistory.reducer';
import calls from '../calls/calls.reducer';
import clickToCall from '../calls/click-to-call.reducer';
import clio from '../clio/clio.reducer';
import connectivity from '../connectivity/connectivity.reducer';
import { connectWiseReducer as connectWise } from '../connectwise/connectwise.reducer';
import { withDebugReducer } from '../debug/debug.reducer';
import dialogState from '../dialog/dialog.reducer';
import featureFlag from '../featureFlag/featureFlag.reducer';
import integrations from '../integrations/integrations.reducers';
import jiveEntities from '../jiveEntities/jiveEntities.reducer';
import msteams from '../msteams/reducers';
import notes from '../notes/notes.reducers';
import phone from '../phone/phone.reducers';
import { redtailReducer as redtail } from '../redtail/redtail.reducer';
import entities from '../search/entities.reducer';
import devices from '../settings/devices.reducer';
import { linesReducer as lines } from '../lines/lines.reducer';
import settings from '../settings/settings.reducer';
import messages from '../inAppNotification/message.reducer';
import termsOfService from '../termsOfService/termsOfService.reducer';
import theme from '../theme/theme.reducer';
import voicemails from '../voicemails/voicemails.reducer';
import callHistory from './callHistory.reducer';
import { analyticsReducer as analytics } from '../analytics-new/analytics.reducer';
import salesforce from '../salesforce/salesforce.reducer';
import { presenceReducer as presence } from '../presence/presence.reducer';
import { coxReducer as cox } from '../cox/cox.reducer';
import { testCallReducer as testCall } from '../onboarding/testCall/testCall.reducer';
import { unifiedCallHistoryMetadataReducer as callHistoryMetadata } from '../callHistoryPush/unifiedCallHistoryMetadata.reducer';
import { unifiedCallHistoryMetadataReducer as persistedCallHistoryMetadata } from '../callHistoryPush/persistedCallHistoryMetadata.reducer';
import { changelogReducer as changelog } from '../changelog/changelog.reducer';
import { conversationsCombinedReducer as conversations } from '../conversations/conversations.reducer';
import { surveyReducer as survey } from '../qualtricsSurvey/survey.reducer';
import { embeddedReducer as embedded } from '../embedded/embedded.reducer';
import { rtcReducers } from '@jive/rtc';
import { softphoneCallReducer as softphoneCalls } from '../softphone/softphoneCall.reducer';
import { zendeskReducer as zendesk } from '../zendesk/zendesk.reducer';
import { settingsPersistenceStateReducer as settingsPersistence } from '../settings/settings.persistence.reducer';
import dynamics from '../dynamics/dynamics.reducer';

const rootReducer = combineReducers({
  ambassador,
  analytics,
  authentication,
  authenticationProgress,
  callHistory,
  calls,
  cox,
  clickToCall,
  clio,
  connectWise,
  connectivity,
  devices,
  dialogState,
  dynamics,
  embedded,
  entities,
  featureFlag,
  integrations,
  jiveEntities,
  lines,
  messages,
  msteams,
  notes,
  phone,
  presence,
  proxy,
  redtail,
  salesforce,
  settings,
  termsOfService,
  theme,
  unifiedCallHistory,
  voicemails,
  testCall,
  callHistoryMetadata,
  persistedCallHistoryMetadata,
  changelog,
  conversations,
  survey,
  ...rtcReducers,
  softphoneCalls,
  zendesk,
  settingsPersistence,
});

export const stateToPersist: Array<keyof AppState> = [
  'authentication',
  'settings',
  'jiveEntities',
  'termsOfService',
  'survey',
];

export type AppState = ReturnType<typeof rootReducer>;

export default withDebugReducer(rootReducer);
