type CallbackFunction<T> = (event: keyof T, data?: any) => void;

type Callbacks<T> = Array<{
  events: Array<keyof T>;
  once: boolean;
  method: CallbackFunction<T>;
}>;

export abstract class EventListenerBase<T> {
  private callbacks: Callbacks<T> = [];

  protected fireEvent = (event: keyof T, data?: any) => {
    const callbacks = this.callbacks.filter((c) => c.events.includes(event));
    if (!callbacks.length) {
      return;
    }

    for (const callback of callbacks) {
      callback.method(event, data);
    }

    this.callbacks = this.callbacks.filter((c) => !c.once || !c.events.includes(event));
  };

  on(events: keyof T | Array<keyof T>, method: CallbackFunction<T>) {
    this.callbacks.push({
      events: Array.isArray(events) ? events : [events],
      once: false,
      method,
    });
  }

  once(events: keyof T | Array<keyof T>, method: CallbackFunction<T>) {
    this.callbacks.push({
      events: Array.isArray(events) ? events : [events],
      once: true,
      method,
    });
  }
}
