import { Store } from 'redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ContactActionsType } from '../actions/contacts';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { NoteActionsType } from '../notes/notes.actions';
import { AppState } from '../reducers';
import { CreateEntityActionPayload, EntitiesActionsType } from '../search/entities.action';
import { RedtailActionFacade } from './redtail.action.facade';
import { RedtailAxiosInterceptor } from './redtail.axios.interceptor';

addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (store, action) => {
  if (action.payload === Integrations.Redtail) {
    registerRedtailListener(store);
  }
});

let isListenerRegistered = false;

function registerRedtailListener(store: Store<AppState>) {
  if (isListenerRegistered) {
    return;
  }

  isListenerRegistered = true;

  new RedtailAxiosInterceptor(store).register();

  addCallStartListener(async (_, action) => {
    await getActionFacade().createScreenpop(action.payload.call);
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(action.payload.call);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const query = action.payload.query;
    await getActionFacade().searchContact(query);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, action) => {
    if (!action.payload.entity) {
      return;
    }

    await getActionFacade().openContactInCrm(action.payload.entity.id);
  });

  addListener(NoteActionsType.GET_NOTES)(async (_, { payload }) => {
    await getActionFacade<RedtailActionFacade>().loadMostRecentNoteOfContact(payload.contactId);
  });

  addListener(NoteActionsType.GET_MORE_NOTES)(async (_, __) => {
    await getActionFacade<RedtailActionFacade>().loadMoreNotesOfContact();
  });

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, action) => {
    const { contactId, phoneNumber } = action.payload;

    await getActionFacade().updateContactPhoneNumber(contactId, phoneNumber);
  });

  addListener(EntitiesActionsType.CREATE_ENTITY)(async (_, action) => {
    const createContact: CreateEntityActionPayload = action.payload;
    const { firstName, lastName, phoneNumber } = createContact.defaultFieldValues;

    await getActionFacade().createContact({
      isActiveCall: action.payload.isActiveCall,
      callId: action.payload.callId,
      firstName,
      lastName,
      phoneNumber,
    });
  });

  addListener(NoteActionsType.ADD_NOTE)(async (_, { payload }) => {
    await getActionFacade<RedtailActionFacade>().addNote(payload);
  });
}
