import React, { FC } from 'react';
import { OnboardingPage } from './OnboardingPage.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ApplicationRoute } from '../constants';
import { LanguageSelector } from '../settings/LanguageSelector.component';
import { Box } from '@material-ui/core';
import { Typography } from '../theme/Typography.component';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { OnboardingNavigationButton } from './OnboardingNavigationButton.component';

const definedMessages = defineMessages({
  CHOOSE_LANGAUAGE: {
    id: 'Onboarding.Language.Heading',
    defaultMessage: 'Choose a language',
  },
});

export const OnboardingLanguageSelector: FC = () => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  return (
    <OnboardingPage
      content={
        <>
          <Box mb={6}>
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              <FormattedMessage {...definedMessages.CHOOSE_LANGAUAGE} />
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <LanguageSelector showLabel />
          </Box>
        </>
      }
      navigation={
        <>
          <Box mb={8}>
            <OnboardingNavigationButton variant="next" route={ApplicationRoute.ONBOARDING_TOS} />
          </Box>
        </>
      }
    />
  );
};
