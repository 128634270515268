import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { SalesforceLightningContactActionCreator } from './contacts/salesforceLightning.contact.actionCreator';
import { SalesforceLightningNoteActionCreator } from './notes/salesforceLightning.note.actionCreator';
import { Store } from 'redux';
import { AppState } from '../reducers';
import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';
import { SalesforceLightningCrmFormActionCreator } from './crmForm/salesforceLightning.crmForm.actionCreator';
import { SalesforceLightningCallLogActionCreator } from './callLog/salesforceLightning.callLog.actionCreator';
import { SalesforceLightningOpportunityActionCreator } from './opportunities/salesforceLightning.opportunity.actionCreator';
import { SalesforceLightningCaseActionCreator } from './cases/salesforceLightning.case.actionCreator';
import { AuthenticationActionCreator } from '../authentication/authentication.actioncreator';
import { SalesforceStartupActionCreator } from './salesforce.startup.actioncreator';
import { SalesforceCallDispositionActionCreator } from './callDisposition/salesforce.callDisposition.actionCreator';
import { UnifiedSalesforceAuthActionCreator } from './unifiedSalesforce.auth.actioncreator';
import { LegacySalesforceAuthActionCreator } from './legacySalesforce.auth.actioncreator';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { SalesforcePresenceActionCreator } from '../presence/salesforce.presence.actionCreator';

export abstract class SalesforceActionCreatorFactoryBase extends ActionCreatorFactory {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }

  public createAuthenticationActionCreator(): AuthenticationActionCreator {
    const searchParams = new URLSearchParams(window.location.search);
    const isSfAuthEnabled = searchParams.get('isSfAuthEnabled');

    if (isSfAuthEnabled === 'true') {
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Authentication,
        action: AnalyticsAction.AuthMethodSelected,
        label: 'Salesforce unified authentication',
      });
      return new UnifiedSalesforceAuthActionCreator(this.store, this.salesforceLightningProxy);
    }

    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Authentication,
      action: AnalyticsAction.AuthMethodSelected,
      label: 'Salesforce legacy authentication',
    });
    return new LegacySalesforceAuthActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createContactActionCreator(): ContactActionCreator {
    return new SalesforceLightningContactActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createNoteActionCreator(): SalesforceLightningNoteActionCreator {
    return new SalesforceLightningNoteActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createCrmFormActionCreator(): SalesforceLightningCrmFormActionCreator {
    return new SalesforceLightningCrmFormActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createCallLogActionCreator(): SalesforceLightningCallLogActionCreator {
    return new SalesforceLightningCallLogActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createOpportunityActionCreator(): SalesforceLightningOpportunityActionCreator {
    return new SalesforceLightningOpportunityActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createCaseActionCreator(): SalesforceLightningCaseActionCreator {
    return new SalesforceLightningCaseActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createCallDispositionActionCreator(): SalesforceCallDispositionActionCreator {
    return new SalesforceCallDispositionActionCreator(this.store);
  }

  public createStartupActionCreator(): SalesforceStartupActionCreator {
    return new SalesforceStartupActionCreator(this.store, this.salesforceLightningProxy);
  }

  public createPresenceActionCreator(): SalesforcePresenceActionCreator {
    return new SalesforcePresenceActionCreator(this.store, this.salesforceLightningProxy);
  }
}
