import { logger } from '../logging';
import { ActionFacade } from './action.facade';

let actionFacadeIstance: ActionFacade;

export const getActionFacade = <T extends ActionFacade = ActionFacade>(): T => {
  if (!actionFacadeIstance) {
    logger.error('Action facade is not initialized yet.');
    throw Error('Action facade is not initialized yet.');
  }

  return actionFacadeIstance as T;
};

export const storeActionFacade = (newActionFacadeInstance: ActionFacade) => {
  actionFacadeIstance = newActionFacadeInstance;
};
