import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { NoteActionCreator } from '../actionFacade/actionCreators/note/note.actioncreator';
import { CoxContactActionCreator } from './contact/cox.contact.actioncreator';
import { PartsActionCreator } from './parts/parts.actionCreator';
import { RepairOrdersActionCreator } from './repairOrders/repairOrders.actionCreator';
import { ServiceAppointmentsActionCreator } from './serviceAppointments/serviceAppointments.actionCreator';
import { VehiclesActionCreator } from './vehicles/vehicles.actionCreator';

export default class CoxActionCreatorFactory extends ActionCreatorFactory {
  public createVehiclesActionCreator(): VehiclesActionCreator {
    return new VehiclesActionCreator(this.store);
  }

  public createServiceAppointmentsActionCreator(): ServiceAppointmentsActionCreator {
    return new ServiceAppointmentsActionCreator(this.store);
  }

  public createRepairOrdersActionCreator(): RepairOrdersActionCreator {
    return new RepairOrdersActionCreator(this.store);
  }

  createContactActionCreator(): ContactActionCreator {
    return new CoxContactActionCreator(this.store);
  }

  createPartsActionCreator(): PartsActionCreator {
    return new PartsActionCreator(this.store);
  }

  createNoteActionCreator(): NoteActionCreator {
    throw new Error('Method should not be implemented and called.');
  }
}
