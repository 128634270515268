import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import LoadingSpinner from '../components/LoadingSpinner';
import { EntityType } from '../constants';
import { createEntity, CreateEntityActionPayload } from '../search/entities.action';
import JiveForm from './JiveForm.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TextInputField } from './TextInputField.component';
import { newTracker } from '../analytics-new/tracker-new';
import { CallActionFormProps } from '../calls/CallPageForm.model';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { allCallSelector, callSelector } from '../calls/calls.selector';
import { isCreateEntityLoadingSelector } from '../search/entities.selector';

export type NewLeadFormProps = CallActionFormProps;

const trackingEvents = defineTrackingEvents({
  FIRST_NAME_CHANGED: {
    category: AnalyticsCategory.Lead,
    label: 'Firstname changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  LAST_NAME_CHANGED: {
    category: AnalyticsCategory.Lead,
    label: 'Lastname changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  TOPIC_CHANGED: {
    category: AnalyticsCategory.Lead,
    label: 'Topic changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_LEAD_CLICKED: {
    category: AnalyticsCategory.Lead,
    label: 'Add lead | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Lead,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
  LEAD_FORM_PAGE_LOADED: {
    category: AnalyticsCategory.Lead,
    action: AnalyticsAction.PageLoaded,
    label: 'Create lead',
  },
});

const definedMessages = defineMessages({
  LEAD_FORM_TITLE: {
    id: 'Lead.New.Form.Title',
    defaultMessage: 'Create Contact',
  },
  LEAD_FORM_SAVE_BUTTON_TEXT: {
    id: 'Lead.New.Form.SaveButtonText',
    defaultMessage: 'Add Contact',
  },
  LEAD_FORM_FIRST_NAME_LABEL: {
    id: 'Lead.New.Form.FirstName.Label',
    defaultMessage: 'First Name',
  },
  LEAD_FORM_LAST_NAME_LABEL: {
    id: 'Lead.New.Form.LastName.Label',
    defaultMessage: 'Last Name',
  },
  LEAD_FORM_TOPIC_LABEL: {
    id: 'Lead.New.Form.Topic.Label',
    defaultMessage: 'Topic',
  },
});

const NewLeadForm: React.FC<NewLeadFormProps> = (props) => {
  const { callId, onClose } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [topic, setTopic] = useState('');

  const activeCall = useSelector((state: AppState) => callSelector(state, callId));
  const isCreateEntityLoading = useSelector(isCreateEntityLoadingSelector);
  const call = useSelector((state: AppState) => allCallSelector(state, callId));
  const isActiveCall = (activeCall && !activeCall.endTime) as boolean;

  const dispatch = useDispatch();
  const createLead = (payload: CreateEntityActionPayload) => dispatch(createEntity(payload));

  useEffect(() => {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.LEAD_FORM_PAGE_LOADED);
  }, []);

  const canSubmitValidation = firstName.length > 0 && lastName.length > 0;

  return (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => {
        createLead({
          isActiveCall,
          defaultFieldValues: {
            phoneNumber: getNumberOfTheOtherParty(call),
            firstName,
            lastName,
            topic,
          },
          entityName: EntityType.LEAD,
          callId: call.id,
        });
      }}
      canSubmit={!isCreateEntityLoading && canSubmitValidation}
      saveButtonDescriptor={definedMessages.LEAD_FORM_SAVE_BUTTON_TEXT}
      saveTrackingEvent={trackingEvents.ADD_LEAD_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
    >
      <Box mb={2}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.LEAD_FORM_TITLE} />
        </Typography>
      </Box>
      <TextInputField
        required={false}
        name="lead-first-name"
        trackingEvent={trackingEvents.FIRST_NAME_CHANGED}
        labelDescription={definedMessages.LEAD_FORM_FIRST_NAME_LABEL}
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        maxLength={32}
      />
      <TextInputField
        name="lead-last-name"
        trackingEvent={trackingEvents.LAST_NAME_CHANGED}
        labelDescription={definedMessages.LEAD_FORM_LAST_NAME_LABEL}
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
        maxLength={32}
      />
      <TextInputField
        name="lead-topic"
        trackingEvent={trackingEvents.TOPIC_CHANGED}
        labelDescription={definedMessages.LEAD_FORM_TOPIC_LABEL}
        value={topic}
        onChange={(event) => setTopic(event.target.value)}
        maxLength={300}
      />
      <LoadingSpinner isLoading={isCreateEntityLoading}>
        <></>
      </LoadingSpinner>
    </JiveForm>
  );
};
export default NewLeadForm;
