import { IntlShape, createIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { toClosestSupportedLocale } from '../i18n/i18n';
import { getMessages } from '../i18n/i18n-messages';
import { devicesObjectSelector } from '../jiveEntities/devicesEntity.selector';
import { getLineVoicemailsSelector } from '../jiveEntities/relationships/lineVoicemails.selector';
import { AppState } from '../reducers';
import { DeviceLine } from './devices.model';
import { NormalizedLine } from '../lines/lines.model';
import { LineOrganization } from './organizations.model';

import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import deLocale from 'date-fns/locale/de';
import ptLocale from 'date-fns/locale/pt';
import frLocale from 'date-fns/locale/fr-CA';
import itLocale from 'date-fns/locale/it';
import { Locale } from 'date-fns';
import { CoxSettings } from '../cox/settings/cox.settings.models';
import { COX_INITIAL_SETTINGS, ZENDESK_INITIAL_SETTING } from './settings.reducer';
import { linesSelector, organizationsSelector } from '../lines/lines.selectors';
import { IndexedById, Integrations } from '../models/index';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';
import { currentIntegrationSelector } from '../integrations/integrations.selector';

export const hasSeenSoftphoneSettingsSelector = (state: AppState) => state.settings.hasSeenSoftphoneSettings;
export const selectedLineIdSelector = (state: AppState) => state.settings.selectedLine;
export const selectedDeviceIdSelector = (state: AppState) => state.settings.selectedDevice;
export const selectedLanguageSelector = (state: AppState) =>
  toClosestSupportedLocale(state?.settings?.selectedLanguage);
export const isAutomaticCallLoggingEnabledSelector = (state: AppState) =>
  state.settings.isAutomaticCallLoggingEnabled ?? true;

// let's migrate previous users who enabled call disposition form
export const isOpenCallLogAfterCallEnabledSelector = (state: AppState) =>
  state.settings.isOpenCallLogAfterCallEnabled ?? state.settings.isShowCallDispositionFormEnabled ?? false;
export const isShowCallDispositionFormEnabledSelector = (state: AppState) =>
  state.settings.isShowCallDispositionFormEnabled ?? false; // false by default
export const isOpportunitiesEnabledSelector = (state: AppState) => state.settings.isOpportunitiesEnabled ?? true;
export const isCasesEnabledSelector = (state: AppState) => state.settings.isCasesEnabled ?? true;
export const isOpenContactInNewTabEnabledSelector = (state: AppState) =>
  state.settings.isOpenContactInNewTabEnabled ?? false;
export const isWidgetAutoPopupEnabledSelector = (state: AppState): boolean =>
  state.settings.isWidgetAutoPopupEnabled ?? true;

export const selectedNormalizedDeviceSelector = createSelector(
  devicesObjectSelector,
  selectedDeviceIdSelector,
  (devices, selectedDeviceId) => devices.byId[selectedDeviceId],
);

export const selectedNormalizedLineSelector = createSelector<
  AppState,
  IndexedById<NormalizedLine>,
  string,
  NormalizedLine
>(linesSelector, selectedLineIdSelector, (lines, selectedLineId) => lines[selectedLineId]);

export const selectedLineSelector: (state: AppState) => LineOrganization = createSelector(
  selectedNormalizedLineSelector,
  organizationsSelector,
  (selectedNormalizedLine, organizations) =>
    selectedNormalizedLine &&
    ({
      ...selectedNormalizedLine,
      organization: organizations[selectedNormalizedLine.organization],
    } as LineOrganization),
);

export const selectedDeviceSelector = createSelector(
  selectedNormalizedDeviceSelector,
  linesSelector,
  organizationsSelector,
  selectedLineIdSelector,
  (selectedNormalizedDevice, lines, organizations, lineId) => {
    if (!selectedNormalizedDevice) {
      return;
    }

    const selectedLine = lines[lineId];
    if (!selectedLine) {
      return;
    }

    const selectedOrganization = organizations[selectedLine.organization];
    return {
      ...selectedNormalizedDevice,
      line: {
        ...selectedLine,
        organization: {
          ...selectedOrganization,
        },
      },
    } as DeviceLine;
  },
);

export const selectedOrganizationIdSelector = createSelector(
  selectedNormalizedLineSelector,
  (line) => (line && line.organization) || '',
);

// TODO: Fix memoization for this selector.
// It is not blocking us because this selector is used on a "controled" environment.
export const selectedLineVoicemailsSelector = createSelector(
  selectedLineIdSelector,
  (state) => state, // HACK: It invalidates memoization on all state changes
  (id, state) => getLineVoicemailsSelector(id)(state),
);

export const isSoftphoneEnabledSelector = (state: AppState) => state.settings.isSoftphoneEnabled === true;

export const isSoftphoneCapableAndEnabledSelector = (state: AppState) => {
  const integration = currentIntegrationSelector(state);
  const isSoftphoneEnabled = isSoftphoneEnabledSelector(state);
  if (integration === Integrations.MsTeams) {
    return false;
  }

  return getActionFacade().isCapable(CAPABILITY_SOFTPHONE) && isSoftphoneEnabled;
};

export const getIntlProvider = (locale: string): IntlShape => {
  return createIntl({ locale, messages: getMessages() });
};

export const injectedIntlSelector = createSelector<AppState, string, IntlShape>(selectedLanguageSelector, (locale) =>
  getIntlProvider(locale),
);

export const selectedDateFnsLocaleSelector = createSelector<AppState, string, Locale>(
  selectedLanguageSelector,
  (locale) => {
    if (locale.toLowerCase().startsWith('es-')) {
      return esLocale;
    } else if (locale.toLowerCase().startsWith('fr-')) {
      return frLocale;
    } else if (locale.toLowerCase().startsWith('de-')) {
      return deLocale;
    } else if (locale.toLowerCase().startsWith('pt-')) {
      return ptLocale;
    } else if (locale.toLowerCase().startsWith('it-')) {
      return itLocale;
    }

    return enLocale;
  },
);

const defaultCoxSettings: CoxSettings = COX_INITIAL_SETTINGS;

const coxSettingsSelector = (state: AppState) => state.settings.cox ?? defaultCoxSettings;
export const dealerTrackEntitiesSelector = createSelector(
  coxSettingsSelector,
  (coxSettings) => coxSettings.dealerTrackEntities,
);

export const isDisplayingCallHistoryEnabledSelector = createSelector(
  coxSettingsSelector,
  (coxSettings) => coxSettings.dealerTrackEntities.history.show,
);

export const isCallHistoryExpandedByDefaultSelector = createSelector(
  coxSettingsSelector,
  (coxSettings) => coxSettings.dealerTrackEntities.history.expand,
);

export const zendeskSettingSelector = (state: AppState) => state.settings.zendesk ?? ZENDESK_INITIAL_SETTING;
