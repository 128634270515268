import { Call } from '@jive/realtime-events';
import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../../actions/actionCreators';

export enum CommunicationActionTypes {
  CREATE_COMMUNICATION = 'clio/communication/add',
  STORE_COMMUNICATION = 'clio/communication/store',
  SAVE_COMMUNICATION = 'clio/communication/save',
  CONFIRM_COMMUNICATION_SAVE = 'clio/communication/confirmSave',
  SAVE_COMMUNICATION_ERROR = 'clio/communication/save/error',
  SAVE_COMMUNICATION_SUCCESS = 'clio/communication/save/success',
}

export interface SaveCommunicationPayload {
  call: Call;
  description: string;
  isBillable: boolean;
  matterId?: number;
  activityCategoryId?: number;
}

export interface SaveCommunicationErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export interface CreateCommunicationSuccessActionPayload {
  message: MessageDescriptor;
  callId: string;
}

export const createCommunication = makeActionCreator(CommunicationActionTypes.CREATE_COMMUNICATION)<
  SaveCommunicationPayload
>();
export const storeCommunication = makeActionCreator(CommunicationActionTypes.STORE_COMMUNICATION)<
  SaveCommunicationPayload
>();
export const saveCommunication = makeActionCreator(CommunicationActionTypes.SAVE_COMMUNICATION)<
  SaveCommunicationPayload
>();
export const saveCommunicationSuccess = makeActionCreator(CommunicationActionTypes.SAVE_COMMUNICATION_SUCCESS)<
  CreateCommunicationSuccessActionPayload
>();
export const saveCommunicationError = makeActionCreator(CommunicationActionTypes.SAVE_COMMUNICATION_ERROR)<
  SaveCommunicationErrorPayload
>();
export const confirmCommunicationSave = makeActionCreator(CommunicationActionTypes.CONFIRM_COMMUNICATION_SAVE)<
  SaveCommunicationPayload
>();

export type CommunicationActions =
  | ReturnType<typeof createCommunication>
  | ReturnType<typeof storeCommunication>
  | ReturnType<typeof saveCommunication>
  | ReturnType<typeof saveCommunicationError>
  | ReturnType<typeof saveCommunicationSuccess>
  | ReturnType<typeof confirmCommunicationSave>;
