import { combineReducers } from 'redux';
import { CaseActions, CaseActionsType } from './case.actions';
import { GetMoreCasesRequestState, GetCasesRequestState, GetCasesState } from './case.models';

export const INITIAL_STATE: GetCasesState = {
  requestState: GetCasesRequestState.INITIAL,
  moreRequestState: GetMoreCasesRequestState.INITIAL,
  cases: [],
  casesCount: undefined,
};

export const casesListReducer = (state: GetCasesState = INITIAL_STATE, action: CaseActions): GetCasesState => {
  switch (action.type) {
    case CaseActionsType.RESET:
      return {
        ...INITIAL_STATE,
      };

    case CaseActionsType.GET_CASES:
      return {
        ...state,
        moreRequestState: GetMoreCasesRequestState.INITIAL,
        requestState: GetCasesRequestState.LOADING,
      };

    case CaseActionsType.GET_CASES_SUCCESS:
      return {
        ...state,
        moreRequestState: GetMoreCasesRequestState.INITIAL,
        requestState: GetCasesRequestState.LOADED,
        requestMeta: action.payload.meta,
        cases: action.payload.cases,
        casesCount: action.payload.meta.casesCount,
      };

    case CaseActionsType.GET_CASES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreCasesRequestState.INITIAL,
        requestState: GetCasesRequestState.FAILED,
      };

    case CaseActionsType.GET_MORE_CASES:
      return {
        ...state,
        moreRequestState: GetMoreCasesRequestState.LOADING,
      };

    case CaseActionsType.GET_MORE_CASES_SUCCESS:
      return {
        ...state,
        requestMeta: action.payload.meta,
        cases: [...state.cases, ...action.payload.cases],
        moreRequestState: GetMoreCasesRequestState.LOADED,
      };

    case CaseActionsType.GET_MORE_CASES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreCasesRequestState.FAILED,
      };

    case CaseActionsType.GET_CASES_COUNT:
      return {
        ...state,
        casesCount: undefined,
      };
    case CaseActionsType.GET_CASES_COUNT_SUCCESS:
      return {
        ...state,
        casesCount: action.payload.casesCount,
      };
    case CaseActionsType.GET_CASES_COUNT_ERROR:
      return {
        ...state,
        casesCount: undefined,
      };

    default:
      return state;
  }
};

export default combineReducers({
  casesList: casesListReducer,
});
