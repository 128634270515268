import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import config from '../config';
import { closeDialog, openDialog } from '../dialog/dialog.actions';
import { AppState } from '../reducers';
import { definedMessages } from '../inAppNotification/message.content';
import { hasAuthenticationErrorSelector, isAuthenticationLoadingSelector } from './authentication.selector';
import { signOutRequestError } from '../msteams/auth/authentication.actions';
import { authenticationEventListener } from './authentication.eventListener';
import { Dispatch, Store } from 'redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { AuthType } from './authentication.actions';

/// This function is responsible registering az axios interceptor that does the following:
/// The interceptor checks if the errorCode of the response is `AUTHN_INVALID_TOKEN` and does the following with these responses:
/// 1, it tries to refresh the token for the user in the background, when it succeeded retries the request with the new token (see request interceptor)
/// 2, when refreshing token in the background failed because user don't have session with the auth server any more
///    - ask the user to login by showing a popup where user needs to click on the login button to continue
///    - when user signs in successfully or there is an error the popup is closed
///    - if user signs in successfully the request retried with the new token otherwise the original request gets rejected
export function registerUnauthorizedRequestInterceptor(axiosInstance: AxiosInstance, store: Store<AppState>) {
  const { getState, dispatch } = store;

  registerExpiredTokenInterceptorWithRetry(axiosInstance, () => {
    const hasAuthenticationError = hasAuthenticationErrorSelector(getState());
    // dispatch a refresh token request, let's try to refresh the token for the user
    // do not try to refresh the token multiple times ex: multiple requests got 401 responses

    if (!isAuthenticationLoadingSelector(getState()) && !hasAuthenticationError) {
      authenticationEventListener.fireTokenRefreshStart();
    }

    return new Promise<{ success: boolean }>(async (resolve) => {
      if (hasAuthenticationError) {
        // if there was an error with authentication previously, let's ask the user to login and return with the result of this attempt
        return resolve(await askForLoginAndWaitForResult(dispatch));
      }

      // wait for the refresh token result
      const { success } = await waitForTokenRefreshResult();
      if (success) {
        // if the attempt refreshing the token was successful resolve the promise with this info
        resolve({ success });
      } else {
        // otherwise; let's show a popup asking the user to login again and resolve with the result of this attempt
        resolve(await askForLoginAndWaitForResult(dispatch));
      }
    });
  });
}

export function registerExpiredTokenInterceptor(axiosInstance: AxiosInstance, store: Store<AppState>) {
  addAxiosRequestInterceptor(axiosInstance, () => {
    store.dispatch(signOutRequestError());
    return Promise.reject<AxiosResponse>('Token expired, signing out.');
  });
}

export function registerAuthTokenInterceptor(axiosInstance: AxiosInstance, store: Store<AppState>) {
  axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const apisToIntercept = [config.apiBaseUrl, config.ambassador.url, config.identityService.baseUrl];

    if (apisToIntercept.every((apiUrl) => !request.url || !request.url.startsWith(apiUrl))) {
      return request;
    }

    const {
      authentication: {
        result: { token },
      },
    } = store.getState();

    if (token) {
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return request;
  });
}

function addAxiosRequestInterceptor(
  axiosInstance: AxiosInstance,
  tokenErrorCallback: (response: AxiosResponse) => Promise<AxiosResponse>,
) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const shouldInterceptUrl = error.request?.responseURL.includes(config.apiBaseUrl);
      const isInvalidTokenResponse =
        error.response?.status === 401 && error.response?.data?.errorCode === 'AUTHN_INVALID_TOKEN';

      if (shouldInterceptUrl && isInvalidTokenResponse) {
        return await tokenErrorCallback(error.response);
      }

      return Promise.reject(error);
    },
  );
}

function registerExpiredTokenInterceptorWithRetry(
  axiosInstance: AxiosInstance,
  refreshInvalidTokenFn: () => Promise<{ success: boolean }>,
) {
  addAxiosRequestInterceptor(axiosInstance, async ({ config }) => {
    const { success } = await refreshInvalidTokenFn();

    if (success) {
      return axiosInstance.request({ ...config });
    } else {
      return Promise.reject<AxiosResponse>();
    }
  });
}

async function askForLoginAndWaitForResult(dispatch: Dispatch): Promise<{ success: boolean }> {
  return new Promise<{ success: boolean }>((resolve) => {
    dispatch(
      openDialog({
        cancellable: false,
        texts: {
          title: definedMessages.LOGIN_REQUIRED_TITLE,
          body: definedMessages.LOGIN_REQUIRED_MESSAGE,
          confirm: definedMessages.SIGN_IN,
        },
        closeCallback: ({ isConfirmed }) => {
          if (!isConfirmed) {
            return;
          }

          getActionFacade().login(AuthType.NewTab);
        },
      }),
    );

    authenticationEventListener.once(['loginError', 'loginSuccess'], (event) => {
      dispatch(closeDialog({}));
      resolve({ success: event === 'loginSuccess' });
    });
  });
}

async function waitForTokenRefreshResult(): Promise<{ success: boolean }> {
  return new Promise<{ success: boolean }>((resolve) => {
    authenticationEventListener.once(['tokenRefreshSuccess', 'tokenRefreshError'], (event) => {
      resolve({ success: event === 'tokenRefreshSuccess' });
    });
  });
}
