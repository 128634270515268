import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { OpenRepairOrdersRequestState, RepairOrderStatus } from '../repairOrders/repairOrders.models';
import { Vehicle } from './vehicles.models';
import { Typography } from '../../theme/Typography.component';

export interface VehicleStatusProps {
  vehicle: Vehicle;
}

export interface VehicleStatusStateProps {
  repairOrderStatus?: RepairOrderStatus;
  openRepairOrdersRequestState?: OpenRepairOrdersRequestState;
}

const definedMessages = defineMessages({
  RO_STATUS_A: {
    id: 'Cox.RepairOrders.StatusValues.ServiceDepartment',
    defaultMessage: 'Service Department',
  },
  RO_STATUS_B: {
    id: 'Cox.RepairOrders.StatusValues.BodyShop',
    defaultMessage: 'Body Shop',
  },
  RO_STATUS_C: {
    id: 'Cox.RepairOrders.StatusValues.WP',
    defaultMessage: 'WP',
  },
  RO_STATUS_D: {
    id: 'Cox.RepairOrders.StatusValues.FirstVisit',
    defaultMessage: 'First Visit',
  },
  RO_STATUS_E: {
    id: 'Cox.RepairOrders.StatusValues.BookedFromTheWeb',
    defaultMessage: 'Booked from the WEB',
  },
  RO_STATUS_L: {
    id: 'Cox.RepairOrders.StatusValues.GLError',
    defaultMessage: 'G/L error',
  },
  RO_STATUS_S: {
    id: 'Cox.RepairOrders.StatusValues.WaitingForSpecialOrderPart',
    defaultMessage: 'Cashier - Waiting for special order part',
  },
  RO_STATUS_1: {
    id: 'Cox.RepairOrders.StatusValues.Open',
    defaultMessage: 'Open',
  },
  RO_STATUS_2: {
    id: 'Cox.RepairOrders.StatusValues.InProcess',
    defaultMessage: 'In Process',
  },
  RO_STATUS_3: {
    id: 'Cox.RepairOrders.StatusValues.ApprovedByPartsDepartment',
    defaultMessage: 'Approved by Parts Department',
  },
  RO_STATUS_4: {
    id: 'Cox.RepairOrders.StatusValues.Cashier',
    defaultMessage: 'Cashier',
  },
  RO_STATUS_5: {
    id: 'Cox.RepairOrders.StatusValues.CashierDelayedClose',
    defaultMessage: 'Cashier, Delayed Close',
  },
  RO_STATUS_6: {
    id: 'Cox.RepairOrders.StatusValues.PreInvoice',
    defaultMessage: 'Pre-Invoice',
  },
  RO_STATUS_7: {
    id: 'Cox.RepairOrders.StatusValues.OdomRequired',
    defaultMessage: 'Odom Required',
  },
  RO_STATUS_8: {
    id: 'Cox.RepairOrders.StatusValues.WaitingForParts',
    defaultMessage: 'Waiting for Parts',
  },
  RO_STATUS_9: {
    id: 'Cox.RepairOrders.StatusValues.PartsApprovalRequired',
    defaultMessage: 'Parts Approval Required',
  },
  RO_STATUS_NA: {
    id: 'Cox.RepairOrders.StatusValues.NotAvailable',
    defaultMessage: 'n/a',
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    roStatusOk: {
      color: tokens.success01,
    },
    roStatusDanger: {
      color: tokens.danger01,
    },
    roStatusNeutral: {},
  }),
);

export const VehicleStatusComponent: React.FC<VehicleStatusProps & VehicleStatusStateProps> = ({
  repairOrderStatus,
  openRepairOrdersRequestState,
}) => {
  const classes = useStyles();

  const mapStatusToClass = (status: Optional<RepairOrderStatus>) => {
    if (!status) {
      return classes.roStatusNeutral;
    }

    switch (status) {
      case RepairOrderStatus.Cashier:
        return classes.roStatusOk;

      case RepairOrderStatus.Open:
      case RepairOrderStatus.InProcess:
      case RepairOrderStatus.PreInvoice:
      case RepairOrderStatus.ServiceDepartment:
      case RepairOrderStatus.BodyShop:
      case RepairOrderStatus.WP:
      case RepairOrderStatus.FirstVisit:
      case RepairOrderStatus.GLError:
      case RepairOrderStatus.BookedFromTheWeb:
      case RepairOrderStatus.WaitingForSpecialOrderPart:
        return classes.roStatusNeutral;

      default:
        return classes.roStatusDanger;
    }
  };

  if (openRepairOrdersRequestState === OpenRepairOrdersRequestState.LOADING) {
    return <CircularProgress size={12} />;
  }

  const mapStatusToText = (status: Optional<RepairOrderStatus>) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    definedMessages['RO_STATUS_' + status] || definedMessages.RO_STATUS_NA;

  const statusClass = mapStatusToClass(repairOrderStatus);
  const statusText = mapStatusToText(repairOrderStatus);

  return (
    <Typography variant="caption-default-02" tag="span">
      <FormattedMessage {...statusText}>{(text) => <span className={statusClass}>{text}</span>}</FormattedMessage>
    </Typography>
  );
};
