import React, { useCallback, useEffect, useState } from 'react';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { VehiclesCapableFacade } from '../../actionFacade/action.facade.capabilities';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Accordion, AccordionSummary } from '../../components/Accordion.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronDownIcon, WrenchInactiveIcon } from '@getgo/chameleon-icons/react';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { PartComponent } from './Part.component';
import { getPartId, InventoryPartId, InventoryParts, RepairOrderPart } from './parts.models';
import { isEqual, uniqWith } from 'lodash-es';
import { OpenRepairOrdersRequestState } from '../repairOrders/repairOrders.models';
import { Typography } from '../../theme/Typography.component';
import { Button } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';

const trackingEvents = defineTrackingEvents({
  SHOW_MORE_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Parts show more | button',
  },
  SHOW_LESS_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Parts show less | button',
  },
});

const definedMessages = defineMessages({
  LIST_TITLE: {
    id: 'Cox.Parts.List.Title',
    defaultMessage: 'Parts',
  },
  EMPTY_MESSAGE: {
    id: 'Cox.Parts.List.Empty',
    defaultMessage: 'There are no parts for this vehicle.',
  },
  SHOW_MORE: {
    id: 'Lists.ShowMore',
    defaultMessage: 'Show more',
  },
  SHOW_LESS: {
    id: 'Lists.ShowLess',
    defaultMessage: 'Show less',
  },
});

export interface PartsStateProps {
  requestState?: OpenRepairOrdersRequestState;
  repairOrderParts: RepairOrderPart[];
  inventoryParts: InventoryParts;
  locale: Locale;
}

export interface PartsProps {
  VIN: string;
  isExpandedDefault: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    partsIcon: {
      fill: tokens.interactive01,
      height: 20,
      width: 20,
      marginRight: theme.spacing(2),
    },
    expandIcon: {
      color: tokens.interactive01,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    showButton: {
      width: '100%',
      fontSize: '14px',
      padding: theme.spacing(1, 2),
    },
  }),
);

const DEFAULT_VISIBLE_ITEM_COUNT = 2;

export const PartsComponent: React.FC<PartsProps & PartsStateProps> = ({
  requestState,
  repairOrderParts,
  inventoryParts,
  locale,
  isExpandedDefault,
}) => {
  const classes = useStyles();
  const itemCount = requestState === OpenRepairOrdersRequestState.LOADED ? repairOrderParts?.length : null;

  const [visibleItemCount, setVisibleItemCount] = useState(isExpandedDefault ? DEFAULT_VISIBLE_ITEM_COUNT : 0);

  const pagedRepairOrderParts = repairOrderParts.slice(0, visibleItemCount);

  const getPartDisplayName = useCallback(
    (part: InventoryPartId) => {
      const partId = getPartId(part);
      return inventoryParts[partId]?.part?.partDescription ?? part.partNumber;
    },
    [inventoryParts],
  );

  const getPartRequestState = useCallback(
    (part: InventoryPartId) => {
      const partId = getPartId(part);
      return inventoryParts[partId]?.loadRequestState;
    },
    [inventoryParts],
  );

  useEffect(() => {
    const unknownParts = pagedRepairOrderParts
      .filter((p) => getPartRequestState(p) === undefined)
      .map((p) => [p.manufacturer, p.partNumber]);

    if (!unknownParts.length) {
      return;
    }

    uniqWith(unknownParts, isEqual).forEach(([manufacturer, partNumber]) => {
      void getActionFacade<VehiclesCapableFacade>().loadPart(partNumber, manufacturer);
    });
  }, [pagedRepairOrderParts, getPartRequestState]);

  const onShowMoreClick = () => {
    setVisibleItemCount((value) => Math.min(value + 2, repairOrderParts?.length ?? DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_MORE_CLICKED);
  };

  const onShowLessClick = () => {
    setVisibleItemCount((value) => Math.max(value - 2, DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_LESS_CLICKED);
  };

  const renderBody = () => {
    if (requestState === undefined || requestState === OpenRepairOrdersRequestState.LOADING) {
      return (
        <LoadingSpinner isLoading={true}>
          <></>
        </LoadingSpinner>
      );
    }

    if (!repairOrderParts.length) {
      return (
        <Typography variant="caption-small-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      );
    }

    return (
      <div className={classes.container}>
        {pagedRepairOrderParts.map((part, index) => (
          <PartComponent
            key={index}
            part={part}
            partName={getPartDisplayName(part)}
            requestState={getPartRequestState(part)}
            locale={locale}
          />
        ))}
        {repairOrderParts.length > DEFAULT_VISIBLE_ITEM_COUNT ? (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.showButton}
              color="primary"
              variant="text"
              onClick={onShowLessClick}
              disabled={visibleItemCount <= 2}
            >
              <FormattedMessage {...definedMessages.SHOW_LESS} />
            </Button>
            <Button
              className={classes.showButton}
              color="primary"
              variant="outlined"
              onClick={onShowMoreClick}
              disabled={visibleItemCount >= repairOrderParts?.length}
            >
              <FormattedMessage {...definedMessages.SHOW_MORE} />
            </Button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Accordion
      summary={
        <AccordionSummary
          expandIcon={<ChevronDownIcon className={classes.expandIcon} />}
          aria-controls="panel-service-appointments-content"
          id="panel-service-appointments-header"
        >
          <WrenchInactiveIcon className={classes.partsIcon} />
          <Typography variant="caption-default-02">
            <FormattedMessage {...definedMessages.LIST_TITLE} />
            <sup>{itemCount != null ? <> ({itemCount})</> : <>&nbsp;</>}</sup>
          </Typography>
        </AccordionSummary>
      }
      isExpandedDefault={isExpandedDefault}
      onExpand={() => {
        setVisibleItemCount(DEFAULT_VISIBLE_ITEM_COUNT);
      }}
      body={renderBody()}
    />
  );
};
