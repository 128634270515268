import { MessageVariant, InAppNotificationMessage } from '../models';
import React, { useEffect } from 'react';
import { ScreenPop } from './ScreenPop.container';
import { InAppNotificationVariantType } from '../constants';
import { FormattedMessage } from 'react-intl';
import { Banner } from './Banner';
import { useNarrowIntegrationDetector } from '../theme/narrowIntegrationDetector';
import { DeprecatedScreenPop } from './DeprecatedScreenPop.container';

interface BannerProps {
  message: InAppNotificationMessage;
  close: () => void;
}

export const InAppNotification: React.FC<BannerProps> = ({ message, close }) => {
  const isNarrowIntegration = useNarrowIntegrationDetector();

  useEffect(() => {
    const timeout = setTimeout(() => close(), message.params.autoHide ? 6000 : 300000);
    return () => {
      clearTimeout(timeout);
    };
  }, [close, message]);

  const handleClose = (_, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    close();
  };

  let notification: Optional<React.ReactElement>;
  switch (message.type) {
    case MessageVariant.CallInfo:
      if (isNarrowIntegration) {
        notification = <DeprecatedScreenPop onClose={handleClose} callId={message.params.entityId!} />;
      } else {
        notification = <ScreenPop onClose={handleClose} callId={message.params.entityId!} />;
      }
      break;
    case MessageVariant.Custom:
      if (message.params.customContent) {
        notification = message.params.customContent;
      }
      break;
    default:
      if (!message.message) {
        break;
      }

      let bannerVariant: 'danger' | 'info' | 'success' | 'neutral';
      switch (message.type as InAppNotificationVariantType) {
        case InAppNotificationVariantType.SUCCESS:
          bannerVariant = 'success';
          break;
        case InAppNotificationVariantType.ERROR:
          bannerVariant = 'danger';
          break;
        case InAppNotificationVariantType.INFO:
          bannerVariant = 'info';
          break;
        case InAppNotificationVariantType.NEUTRAL:
          bannerVariant = 'neutral';
          break;
      }
      notification = (
        <Banner
          variant={bannerVariant}
          isClosable={message.params.dismissible}
          onClose={handleClose}
          icon={message.params.icon}
          message={<FormattedMessage {...message.message} />}
          actions={message.params.actions}
        />
      );
      break;
  }

  return notification ?? null;
};
