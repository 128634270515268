import React, { Component } from 'react';
import { SearchIcon } from '@getgo/chameleon-icons/react';
import { defineMessages } from 'react-intl';
import { SearchInput } from '../components/SearchInput';
import { PhoneMode } from '../phone/phone.model';
import { SearchEntityPayload } from './entities.action';

const definedMessages = defineMessages({
  SEARCH_CONTACTS_IN_CRM_PLACEHOLDER: {
    id: 'Search.Contacts.In.Crm.Placeholder',
    defaultMessage: 'Search contacts in {integrationName}',
  },
});

export interface EntitiesSearchDispatchProps {
  clearEntity: () => void;
  search: (payload: SearchEntityPayload) => void;
}

export interface EntitiesSearchStateProps {
  integrationName: string;
}

export interface EntitiesSearchProps {
  defaultValue: string;
}

interface EntitiesSearchState {
  input: string;
}

class EntitiesSearch extends Component<
  EntitiesSearchStateProps & EntitiesSearchDispatchProps & EntitiesSearchProps,
  EntitiesSearchState
> {
  state = {
    input: '',
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { defaultValue } = this.props;
    this.onChange(defaultValue);
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    if (defaultValue !== prevProps.defaultValue) {
      this.onChange(defaultValue);
    }
  }

  componentWillUnmount() {
    const { clearEntity } = this.props;
    clearEntity();
  }

  onChange = (value) => {
    const { search, clearEntity } = this.props;
    if (value === '') {
      clearEntity();
    } else if (value.length >= 3) {
      search({ query: `${value}` });
    }
    this.setState({
      input: value,
    });
  };

  clearInput = () => {
    const { clearEntity } = this.props;
    clearEntity();
    this.setState({
      input: '',
    });
  };

  render() {
    const { input } = this.state;
    const { integrationName } = this.props;
    return (
      <SearchInput
        startAdornment={<SearchIcon aria-hidden />}
        onInputChange={this.onChange}
        onClearInput={this.clearInput}
        input={input}
        mode={PhoneMode.Search}
        placeholder={{ ...definedMessages.SEARCH_CONTACTS_IN_CRM_PLACEHOLDER, values: { integrationName } }}
      />
    );
  }
}

export default EntitiesSearch;
