import { Store } from 'redux';
import { Entity } from '../../models';
import { AppState } from '../../reducers';
import { EntityPhoneNumber, EntityPhoneNumberType } from '../../search/entities.model';
import { ZendeskEntityType } from '../zendesk.models';
import { ZendeskService } from '../zendesk.service';
import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';
import { showErrorMessage } from '../../inAppNotification/message.action';
import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
  UPDATE_CONTACT_ERROR_NUMBER_ALREADY_EXISTS: {
    id: 'Update.Contact.Error.Number.Already.Exists',
    defaultMessage: 'This number already exists on a contact',
    description: 'error message contact update number already exists',
  },
});

export class ZendeskContactActionCreator extends ContactActionCreator {
  constructor(protected store: Store<AppState>, private zendeskService: ZendeskService) {
    super(store);
  }

  protected async _openCrmContactPage(contactId: string): Promise<void> {
    await this.zendeskService.openEntity(ZendeskEntityType.User, contactId);
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this._openCrmContactPage(contactId)
      .then(() => true)
      .catch(() => false);
  }

  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const identities = await this.zendeskService.getUserPhoneIdentities(contactId);
    return identities.map((identity) => ({
      entityId: identity.id.toString(),
      type: EntityPhoneNumberType.BUSINESS,
      phoneNumber: identity.value,
    }));
  }

  protected _searchContactByName(query: string): Promise<Entity[]> {
    return this.zendeskService.searchEntitites(query);
  }

  protected _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]> {
    return this.zendeskService.searchEntitiesByPhone(phoneNumber);
  }

  protected async _canUpdateContactPhoneNumber(_: string, phoneNumber: string): Promise<boolean> {
    const foundUsers = await this.zendeskService.searchEntitiesByPhone(phoneNumber);
    if (foundUsers.length > 0) {
      this.store.dispatch(showErrorMessage(definedMessages.UPDATE_CONTACT_ERROR_NUMBER_ALREADY_EXISTS));
      return false;
    }
    return true;
  }

  protected async _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    await this.zendeskService.addPhoneNumber(contactId, phoneNumber);
  }

  protected async _createContact(contactData: ContactCreateModel): Promise<string> {
    const name = `${contactData.firstName} ${contactData.lastName}`;

    const newId = await this.zendeskService.createContact(name, contactData.phoneNumber);

    return newId;
  }
}
