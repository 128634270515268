import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { getEntityPhoneNumbersSelector } from '../jiveEntities/relationships/entityPhoneNumbers.selector';
import { setPhoneSelectedEntity } from '../phone/phone.actions';
import { selectedEntityIdSelector } from '../phone/phone.selector';
import { AppState } from '../reducers';
import SearchResultComponent, {
  SearchResultDispatchProps,
  SearchResultProps,
  SearchResultStateProps,
} from './SearchResult.component';

export default connect<SearchResultStateProps, SearchResultDispatchProps, SearchResultProps, AppState>(
  (state: AppState, ownProps: SearchResultProps) => ({
    isEntitySelected: selectedEntityIdSelector(state) === ownProps.entity.id,
    phoneNumbers: getEntityPhoneNumbersSelector(ownProps.entity.id)(state),
  }),
  (dispatch: Dispatch<AppAction>, ownProps: SearchResultProps) => ({
    selectEntity: () => dispatch(setPhoneSelectedEntity({ entityId: ownProps.entity.id })),
  }),
)(SearchResultComponent);
