import { createSelector } from 'reselect';
import { smsCapablePhoneNumbersSelector } from '../jiveEntities/pbxesEntity.selector';
import { AppState } from '../reducers';

export const conversationsReducerSelector = (state: AppState) => state.conversations.conversations;
export const selectedSMSPhoneNumberSelector = createSelector(
  conversationsReducerSelector,
  (conversations) => conversations.conversationsPhoneNumber,
);

export const selectedConversationPhoneNumberSelector = createSelector(
  selectedSMSPhoneNumberSelector,
  smsCapablePhoneNumbersSelector,
  (selectedPhoneNumber, smsPhoneNumbers) =>
    selectedPhoneNumber
      ? smsPhoneNumbers.find((pn) => pn === selectedPhoneNumber) ?? smsPhoneNumbers[0]
      : smsPhoneNumbers[0],
);

export const conversationsPageIdentifiersSelector = createSelector(
  conversationsReducerSelector,
  (conversations) => conversations.pageIdentifiers,
);

export const conversationsListSelector = createSelector(
  conversationsReducerSelector,
  (conversations) => [...conversations.conversations] ?? [],
);

export const isConversationsLoadingSelector = createSelector(
  conversationsReducerSelector,
  (conversations) => conversations.loading,
);

export const isConversationsLoadingFailedSelector = createSelector(
  conversationsReducerSelector,
  (conversations) => conversations.error,
);

export const hasConversationsNextPageSelector = createSelector(
  conversationsPageIdentifiersSelector,
  selectedConversationPhoneNumberSelector,
  (pageIdentifiers, phoneNumber) => (pageIdentifiers ? !!pageIdentifiers[phoneNumber] : false),
);
