import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { AppState } from '../reducers';
import { openEntity, OpenEntityPayload } from '../search/entities.action';
import OpenEntityContextMenuItem, {
  OpenEntityContextMenuItemDispatchProps,
  OpenEntityContextMenuItemProps,
} from './OpenEntityContextMenuItem.component';

export default connect<{}, OpenEntityContextMenuItemDispatchProps, OpenEntityContextMenuItemProps, AppState>(
  undefined,
  (dispatch: Dispatch<AppAction>) => ({
    openEntityAction: (payload: OpenEntityPayload) => dispatch(openEntity(payload)),
  }),
)(OpenEntityContextMenuItem);
