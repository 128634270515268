import React from 'react';
import { EntityType } from '../../constants';
import { CallActionButton, CallActionProps } from './CallActionButton.component';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { defineMessages } from 'react-intl';
import { AccessibilityIcon } from '@getgo/chameleon-icons/react';

const definedMessage = defineMessages({
  NEW_LEAD: {
    id: 'Call.NewLead',
    defaultMessage: 'New lead',
  },
});

export const NewLeadButton: React.FC<Partial<CallActionProps>> = (props) => {
  return (
    <CallActionButton
      leadingIcon={<AccessibilityIcon aria-hidden />}
      label={definedMessage.NEW_LEAD}
      entityType={EntityType.LEAD}
      variant="custom"
      trackingEvent={{
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ItemClicked,
        label: 'New lead | button',
      }}
      {...props}
    />
  );
};
