import { getActiveRoute, goToRouteWithParams, ActiveRoute, goToRoute, history } from './browserHistory';
import { ApplicationRoute } from './constants';

const LAST_ROUTE_KEY = 'gtc_integration_last_route';

export const getStoredRoute = (): ActiveRoute | null => {
  const storedRoute = sessionStorage.getItem(LAST_ROUTE_KEY);
  if (storedRoute) {
    return JSON.parse(storedRoute) as ActiveRoute;
  }
  return null;
};
export const storeCurrentRoute = () => {
  const currentRoute = getActiveRoute();
  if (currentRoute.route !== ApplicationRoute.UNKNOWN_ROUTE) {
    sessionStorage.setItem(LAST_ROUTE_KEY, JSON.stringify(currentRoute));
  }
};
export const checkAndNavigateToPersistedRoute = () => {
  const storedRoute = getStoredRoute();
  const currentRoute = getActiveRoute();

  if (
    storedRoute &&
    storedRoute.route &&
    history.location.pathname === '/' &&
    storedRoute.route !== currentRoute.route &&
    !storedRoute.route.startsWith(ApplicationRoute.ONBOARDING)
  ) {
    if (storedRoute.match) {
      goToRouteWithParams(storedRoute.route, storedRoute.match.params);
    } else {
      goToRoute(storedRoute.route);
    }
  }
};
