import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import LoadingSpinner from '../../components/LoadingSpinner';
import {
  ClosedRepairOrdersRequestState,
  ClosedRepairOrder,
  ClosedRepairOrderDetailsState,
  ServiceWritersRequestState,
  ServiceWriter,
} from './repairOrders.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { VehiclesCapableFacade } from '../../actionFacade/action.facade.capabilities';
import { ClosedRepairOrderComponent } from './ClosedRepairOrder.component';
import { newTracker } from '../../analytics-new/tracker-new';
import { defineTrackingEvents, AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { Typography } from '../../theme/Typography.component';
import { Button } from '@getgo/chameleon-material-ui';

const definedMessages = defineMessages({
  LIST_TITLE: {
    id: 'Cox.ClosedRepairOrders.List.Title',
    defaultMessage: 'Closed services',
  },
  EMPTY_MESSAGE: {
    id: 'Cox.ClosedRepairOrders.List.Empty',
    defaultMessage: 'There are no closed services for this vehicle.',
  },
  SHOW_MORE: {
    id: 'Lists.ShowMore',
    defaultMessage: 'Show more',
  },
  SHOW_LESS: {
    id: 'Lists.ShowLess',
    defaultMessage: 'Show less',
  },
});

const trackingEvents = defineTrackingEvents({
  SHOW_MORE_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Closed repair orders show more | button',
  },
  SHOW_LESS_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Closed repair orders show less | button',
  },
});

export interface ClosedRepairOrdersStateProps {
  requestState?: ClosedRepairOrdersRequestState;
  closedRepairOrders: ClosedRepairOrder[];
  closedRepairOrderDetails: ClosedRepairOrderDetailsState;
  locale: Locale;
  serviceWritersRequestState: ServiceWritersRequestState;
  serviceWriters?: ServiceWriter[];
}

export interface ClosedRepairOrdersProps {
  VIN: string;
}

const DEFAULT_VISIBLE_ITEM_COUNT = 1;

export const ClosedRepairOrdersComponent: React.FC<ClosedRepairOrdersProps & ClosedRepairOrdersStateProps> = ({
  VIN,
  requestState,
  closedRepairOrders,
  closedRepairOrderDetails,
  locale,
  serviceWritersRequestState,
  serviceWriters,
}) => {
  useEffect(() => {
    void getActionFacade<VehiclesCapableFacade>().loadClosedRepairOrdersForVehicle(VIN);
  }, [VIN]);

  const [visibleItemCount, setVisibleItemCount] = useState(DEFAULT_VISIBLE_ITEM_COUNT);

  const closedRepairOrdersPaged = useMemo(() => closedRepairOrders?.slice(0, visibleItemCount) ?? [], [
    closedRepairOrders,
    visibleItemCount,
  ]);

  useEffect(() => {
    const unknownClosedRepairOrderDetails = closedRepairOrdersPaged
      .filter((ro) => closedRepairOrderDetails?.[ro.repairOrderNumber] === undefined)
      .map((ro) => ro.repairOrderNumber);

    unknownClosedRepairOrderDetails.forEach((roNumber) => {
      void getActionFacade<VehiclesCapableFacade>().loadClosedRepairOrderDetails(VIN, roNumber);
    });
  }, [VIN, closedRepairOrdersPaged, closedRepairOrderDetails]);

  const onShowMoreClick = () => {
    setVisibleItemCount((value) => Math.min(value + 2, closedRepairOrders?.length ?? DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_MORE_CLICKED);
  };

  const onShowLessClick = () => {
    setVisibleItemCount((value) => Math.max(value - 2, DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_LESS_CLICKED);
  };

  const renderShowButtons = () => {
    if (!closedRepairOrders || closedRepairOrders.length <= DEFAULT_VISIBLE_ITEM_COUNT) {
      return null;
    }

    return (
      <Box display="flex" mt={4}>
        <Button
          fullWidth
          color="primary"
          variant="text"
          onClick={onShowLessClick}
          disabled={visibleItemCount <= DEFAULT_VISIBLE_ITEM_COUNT}
        >
          <FormattedMessage {...definedMessages.SHOW_LESS} />
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={onShowMoreClick}
          disabled={visibleItemCount >= closedRepairOrders.length}
        >
          <FormattedMessage {...definedMessages.SHOW_MORE} />
        </Button>
      </Box>
    );
  };

  const renderBody = () => {
    if (requestState === undefined || requestState === ClosedRepairOrdersRequestState.LOADING) {
      return (
        <LoadingSpinner isLoading={true}>
          <></>
        </LoadingSpinner>
      );
    }

    if (!closedRepairOrders || !closedRepairOrders.length) {
      return (
        <Typography variant="caption-small-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      );
    }

    return (
      <>
        {closedRepairOrdersPaged.map((repairOrder) => (
          <ClosedRepairOrderComponent
            key={repairOrder.repairOrderNumber}
            repairOrder={repairOrder}
            repairOrderDetails={closedRepairOrderDetails[repairOrder.repairOrderNumber]?.closedRepairOrderDetail}
            repairOrderDetailsRequestState={closedRepairOrderDetails[repairOrder.repairOrderNumber]?.requestState}
            serviceWriters={serviceWriters}
            serviceWritersRequestState={serviceWritersRequestState}
            locale={locale}
          />
        ))}
        {renderShowButtons()}
      </>
    );
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.LIST_TITLE} />
        </Typography>
      </Box>
      {renderBody()}
    </div>
  );
};
