import React, { useEffect } from 'react';
import { RedirectKeepSearch } from '../components/RedirectKeepSearch.component';
import { ApplicationRoute } from '../constants';
import { extractStateObject } from './loginHelper';
import Qs from 'qs';

const LogoutSuccessComponent: React.FC = () => {
  function pageIsInAPopup() {
    return !!window.opener;
  }

  useEffect(() => {
    if (window.opener) {
      window.close();
    }
  });

  let hash = window.location.hash;
  if (hash.indexOf('#') === 0) {
    hash = hash.slice(1);
  }

  let search = window.location.search;
  if (search.indexOf('?') === 0) {
    search = search.slice(1);
  }

  const { state } = Qs.parse(hash || search);
  const { pathToRedirect } = extractStateObject<{ pathToRedirect: string }>(state as string);

  // do not show anything when the page is opened in a popup
  return pageIsInAPopup() ? (
    <></>
  ) : (
    <RedirectKeepSearch pathname={pathToRedirect || ApplicationRoute.ONBOARDING_LOGIN} />
  );
};

export default LogoutSuccessComponent;
