import { logger } from '../logging';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { searchMatters, searchMattersSuccess, searchMattersError } from './matters/matter.actions';
import { ClioService } from './clio.service';
import { defineMessages } from 'react-intl';
import { AnalyticsCategory, AnalyticsAction, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import {
  loadActivityCategories,
  loadActivityCategoriesSuccess,
  loadActivityCategoriesError,
} from './activities/activity.actions';

const definedMessages = defineMessages({
  GET_ACTIVITY_CATEGORIES_ERROR: {
    id: 'ClioCommunicationLogForm.ActivityCategories.Loading.Error',
    defaultMessage: 'There was an error while downloading the activity categories, please try again.',
  },
  GET_MATTERS_ERROR: {
    id: 'ClioCommunicationLogForm.Matters.Loading.Error',
    defaultMessage: 'There was an error while downloading the matters, please try again.',
  },
});

const trackingEvents = defineTrackingEvents({
  ACTIVITY_CATEGORIES_LOAD_STARTED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ActivityCategoriesLoadStarted,
    label: '-', // will be updated dynamically
  },
  ACTIVITY_CATEGORIES_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ActivityCategoriesLoadSucceeded,
    label: '-', // will be updated dynamically
  },
  ACTIVITY_CATEGORIES_LOAD_FAILED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ActivityCategoriesLoadFailed,
    label: '-', // will be updated dynamically
  },
  MATTER_SEARCH_SUCCEEDED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.MatterSearchSucceeded,
    label: '-', // will be updated dynamically
  },
  MATTER_SEARCH_FAILED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.MatterSearchFailed,
    label: '-',
  },
});

export class ClioActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async searchMatters(query: string): Promise<void> {
    try {
      this.store.dispatch(searchMatters({ query }));

      newTracker.trackAnalyticsEvent(trackingEvents.ACTIVITY_CATEGORIES_LOAD_STARTED);

      const matters = await ClioService.searchMatters(query);

      this.store.dispatch(
        searchMattersSuccess({
          matters,
        }),
      );
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.MATTER_SEARCH_SUCCEEDED,
        label: matters.length.toString(),
      });
    } catch (ex) {
      logger.error('Error getting matters', ex);
      this.store.dispatch(
        searchMattersError({
          message: definedMessages.GET_MATTERS_ERROR,
        }),
      );
      newTracker.trackAnalyticsEvent(trackingEvents.MATTER_SEARCH_FAILED);
    }
  }

  public async loadActivityCategories(): Promise<void> {
    try {
      this.store.dispatch(loadActivityCategories());
      const activityCategories = await ClioService.getActivityCategories();

      this.store.dispatch(
        loadActivityCategoriesSuccess({
          activityCategories,
        }),
      );
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.ACTIVITY_CATEGORIES_LOAD_SUCCEEDED,
        label: activityCategories.length.toString(),
      });
    } catch (ex) {
      logger.error('Error getting activity categories', ex);
      this.store.dispatch(
        loadActivityCategoriesError({
          message: definedMessages.GET_ACTIVITY_CATEGORIES_ERROR,
        }),
      );
      newTracker.trackAnalyticsEvent(trackingEvents.ACTIVITY_CATEGORIES_LOAD_FAILED);
    }
  }
}
