import { AppAction } from '../../actions/actionCreators';
import { IndexedById } from '../../models';
import jiveEntityReducer, { JiveEntityReducerActionsTypesOptions } from '../jiveEntitiesCommon.reducer';

export type JiveEntityRelationshipsOneToMany = IndexedById<string[]>;

const jiveEntityRelationshipsOneToManyReducer = <T extends AppAction<string, JiveEntityRelationshipsOneToMany | void>>(
  actionsTypesOptions: JiveEntityReducerActionsTypesOptions,
) => jiveEntityReducer<string[], T>(actionsTypesOptions);

export default jiveEntityRelationshipsOneToManyReducer;
