import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { deleteAllMessages } from '../inAppNotification/message.action';
import {
  ClickToCallCallingMessageComponent,
  ClickToCallCallingMessageDispatchProps,
  ClickToCallCallingMessageProps,
} from './ClickToCallCallingMessage.component';
import { Dispatch } from 'redux';

const mapDispatch = (dispatch: Dispatch): ClickToCallCallingMessageDispatchProps => ({
  onClose: () => dispatch(deleteAllMessages()),
});

export default connect<{}, ClickToCallCallingMessageDispatchProps, ClickToCallCallingMessageProps, AppState>(
  undefined,
  mapDispatch,
)(ClickToCallCallingMessageComponent);
