import React, { FC } from 'react';
import { goToRoute } from '../browserHistory';
import { ButtonProps } from '@getgo/chameleon-web/react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import {
  defineTrackingEvents,
  AnalyticsCategory,
  AnalyticsAction,
  TrackingEvent,
} from '../analytics-new/analytics.models';
import { ApplicationRoute } from '../constants';

const definedMessages = defineMessages({
  NEXT: {
    id: 'Onboarding.NextButton.Label',
    defaultMessage: 'Next',
  },
  DONE: {
    id: 'Onboarding.DoneButton.Label',
    defaultMessage: 'Done',
  },
  PREVIOUS: {
    id: 'Onboarding.PreviousButton.Label',
    defaultMessage: 'Previous',
  },
});

const trackingEvents = defineTrackingEvents({
  PREVIOUS_BUTTON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Previous | button',
  },
  NEXT_BUTTON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Next | button',
  },
  DONE_BUTTON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Done | button',
  },
});

type NavigationButtonVariant = 'done' | 'next' | 'previous';
export interface NavigationButtonProps extends Omit<ButtonProps, 'variant'> {
  variant: NavigationButtonVariant;
  route: ApplicationRoute;
}

const labelMapping: Record<NavigationButtonVariant, MessageDescriptor> = {
  done: definedMessages.DONE,
  next: definedMessages.NEXT,
  previous: definedMessages.PREVIOUS,
};

const trackingEventMapping: Record<NavigationButtonVariant, TrackingEvent> = {
  done: trackingEvents.DONE_BUTTON,
  next: trackingEvents.NEXT_BUTTON,
  previous: trackingEvents.PREVIOUS_BUTTON,
};

export const OnboardingNavigationButton: FC<NavigationButtonProps> = ({ variant, route, ...buttonProps }) => {
  return (
    <TrackedButton
      {...buttonProps}
      onClick={() => goToRoute(route)}
      variant={variant === 'done' || variant === 'next' ? 'primary' : 'tertiary'}
      fullwidth
      trackingEvent={trackingEventMapping[variant]}
    >
      <FormattedMessage {...labelMapping[variant]} />
    </TrackedButton>
  );
};
