import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { GetMoreOpportunitiesRequestState, GetOpportunitiesRequestState } from './opportunity.models';
import { SalesforceOpportunity } from '../../../../salesforce-shared/salesforce-service.models';

export const opportunitiesListSelector = (state: AppState): SalesforceOpportunity[] =>
  state.salesforce.opportunities && state.salesforce.opportunities && state.salesforce.opportunities.opportunities;

export const opportunitiesCountSelector = createSelector(
  opportunitiesListSelector,
  (opportunitiesList) => (opportunitiesList && opportunitiesList.length) || 0,
);

export const getOpportunitiesRequestStateSelector = (state: AppState): GetOpportunitiesRequestState =>
  state.salesforce.opportunities.requestState;

export const getMoreOpportunitiesRequestStateSelector = (state: AppState): GetMoreOpportunitiesRequestState =>
  state.salesforce.opportunities.moreRequestState;

export const getOpportunityRequestMetaSelector = (state: AppState) => state.salesforce.opportunities.requestMeta;

export const canLoadMoreOpportunitiesSelector = createSelector(
  getOpportunityRequestMetaSelector,
  (meta) => !!meta && !!meta.next,
);

export const allOpportunitiesCountSelector = (state: AppState) => state.salesforce.opportunities.opportunitiesCount;
