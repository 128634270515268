import { TermsOfServiceActions, TermsOfServiceActionTypes } from './termsOfService.action';

interface TermsOfServiceUserState {
  accepted: boolean;
}

export interface TermsOfServiceState {
  accepted: boolean;
  // Since the new onboarding flow, the user based state is obsolete, keeping it for backward-compatibility.
  byId: Record<string, TermsOfServiceUserState>;
}

export const TERMS_OF_SERVICE_INITIAL_STATE: TermsOfServiceState = {
  accepted: false,
  byId: {},
};

export const termsOfServiceReducer = (state = TERMS_OF_SERVICE_INITIAL_STATE, action: TermsOfServiceActions) => {
  switch (action.type) {
    case TermsOfServiceActionTypes.ACCEPT_TERMS_OF_SERVICE: {
      return {
        ...state,
        accepted: true,
      };
    }
    default:
      return state;
  }
};

export default termsOfServiceReducer;
