import { Box, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { toDateTimeStringOrDefault } from '../cox.utils';
import { DataRow } from '../DataRow.component';
import { OpenRepairOrder, RepairOrderStatus, ServiceWritersRequestState, ServiceWriter } from './repairOrders.models';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2, 3),
      backgroundColor: tokens.uiBase01,
      borderRadius: theme.shape.borderRadius,
    },
    titleLabel: {
      marginRight: theme.spacing(1),
    },
  }),
);

const definedMessages = defineMessages({
  TITLE_DATE: {
    id: 'Cox.OpenRepairOrder.Title',
    defaultMessage: 'Date:',
  },
  STATUS: {
    id: 'Cox.ServiceAppointments.Detail.Status',
    defaultMessage: 'Status:',
  },
  ESTIMATED_PRICE: {
    id: 'Cox.ServiceAppointments.Detail.EstimatedPrice',
    defaultMessage: 'Estimated price:',
  },
  ORDER_NUMBER: {
    id: 'Cox.ServiceAppointments.Detail.OrderNumber',
    defaultMessage: 'Order number:',
  },
  ADVISOR: {
    id: 'Cox.ServiceAppointments.Detail.Advisor',
    defaultMessage: 'Advisor:',
  },
  STATUS_SERVICE_DEPARTMENT: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.ServiceDepartment',
    defaultMessage: 'Service Department',
  },
  STATUS_BODY_SHOP: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.BodyShop',
    defaultMessage: 'Body Shop',
  },
  // The status definitions are from this documentation ROStatus: https://otwiki.dms.dealertrack.com/opentrack/index.php/OpenRepairOrderLookup_Request
  STATUS_WP: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.WP',
    defaultMessage: 'WP',
  },
  STATUS_FIRST_VISIT: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.FirstVisit',
    defaultMessage: 'First visit',
  },
  STATUS_BOOKED_FROM_THE_WEB: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.BookedFromTheWeb',
    defaultMessage: 'Booked from the WEB',
  },
  STATUS_GL_ERROR: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.GLError',
    defaultMessage: 'GL Error',
  },
  STATUS_WAITING_FOR_SPECIAL_PART: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.WaitingForSpecialPart',
    defaultMessage: 'Waiting for special part',
  },
  STATUS_OPEN: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.Open',
    defaultMessage: 'Open',
  },
  STATUS_IN_PROCESS: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.InProcess',
    defaultMessage: 'In process',
  },
  STATUS_APPROVED_BY_PARTS: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.ApprovedByParts',
    defaultMessage: 'Approved by parts',
  },
  STATUS_CASHIER: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.Cashier',
    defaultMessage: 'Cashier',
  },
  STATUS_CASHIER_DELAYED: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.CashierDelayed',
    defaultMessage: 'Cashier delayed',
  },
  STATUS_PREINVOICE: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.Preinvoce',
    defaultMessage: 'Pre invoice',
  },
  STATUS_ODOM_REQUIRED: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.ODOMRequired',
    defaultMessage: 'ODOM required',
  },
  STATUS_WAITING_FOR_PARTS: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.WaitingForParts',
    defaultMessage: 'Waiting for parts',
  },
  STATUS_PARTS_APPROVAL_REQUIRED: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.PartsApprovalRequired',
    defaultMessage: 'Parts approval required',
  },
});

export interface OpenRepairOrderProps {
  repairOrder: OpenRepairOrder;
  locale: Locale;
  serviceWritersRequestState: ServiceWritersRequestState;
  serviceWriters?: ServiceWriter[];
}

const statusLabelMapping: Record<RepairOrderStatus, MessageDescriptor> = {
  [RepairOrderStatus.ServiceDepartment]: definedMessages.STATUS_SERVICE_DEPARTMENT,
  [RepairOrderStatus.BodyShop]: definedMessages.STATUS_BODY_SHOP,
  [RepairOrderStatus.WP]: definedMessages.STATUS_WP,
  [RepairOrderStatus.FirstVisit]: definedMessages.STATUS_FIRST_VISIT,
  [RepairOrderStatus.BookedFromTheWeb]: definedMessages.STATUS_BOOKED_FROM_THE_WEB,

  [RepairOrderStatus.GLError]: definedMessages.STATUS_GL_ERROR,
  [RepairOrderStatus.WaitingForSpecialOrderPart]: definedMessages.STATUS_WAITING_FOR_SPECIAL_PART,
  [RepairOrderStatus.Open]: definedMessages.STATUS_OPEN,
  [RepairOrderStatus.InProcess]: definedMessages.STATUS_IN_PROCESS,
  [RepairOrderStatus.ApprovedByPartsDepartment]: definedMessages.STATUS_APPROVED_BY_PARTS,
  [RepairOrderStatus.Cashier]: definedMessages.STATUS_CASHIER,
  [RepairOrderStatus.CashierDelayedClose]: definedMessages.STATUS_CASHIER_DELAYED,
  [RepairOrderStatus.PreInvoice]: definedMessages.STATUS_PREINVOICE,
  [RepairOrderStatus.OdomRequired]: definedMessages.STATUS_ODOM_REQUIRED,
  [RepairOrderStatus.WaitingForParts]: definedMessages.STATUS_WAITING_FOR_PARTS,
  [RepairOrderStatus.PartsApprovalRequired]: definedMessages.STATUS_PARTS_APPROVAL_REQUIRED,
};

export const OpenRepairOrderComponent: React.FC<OpenRepairOrderProps> = ({
  repairOrder,
  locale,
  serviceWritersRequestState,
  serviceWriters,
}) => {
  const classes = useStyles();
  const serviceWriter = serviceWriters && serviceWriters.find((sw) => sw.id === repairOrder.serviceWriterId);

  const advisor =
    serviceWritersRequestState === ServiceWritersRequestState.INITIAL ||
    serviceWritersRequestState === ServiceWritersRequestState.LOADING ? (
      <CircularProgress size={12} />
    ) : (
      <>{serviceWriter?.name || ''}</>
    );

  return (
    <Box mb={2}>
      <div className={classes.container}>
        <Box mb={1}>
          <DataRow
            label={definedMessages.TITLE_DATE}
            value={toDateTimeStringOrDefault(repairOrder.promisedDateTime, locale)}
          />
        </Box>
        <FormattedMessage {...statusLabelMapping[repairOrder.status]}>
          {(status) => <DataRow color="text-02" label={definedMessages.STATUS} value={status as string} />}
        </FormattedMessage>
        <DataRow color="text-02" label={definedMessages.ADVISOR} value={advisor} />
        <DataRow
          color="text-02"
          label={definedMessages.ESTIMATED_PRICE}
          value={`$${(repairOrder.totalEstimate || 0).toFixed(2)}`}
        />
        <DataRow color="text-02" label={definedMessages.ORDER_NUMBER} value={repairOrder.repairOrderNumber} />
      </div>
    </Box>
  );
};
