import { uniq } from 'lodash-es';
import { Reducer } from 'redux';
import { IndexedById } from '../models';
import { UserCallHistoryItem } from './callHistory.models';
import { UnifiedCallHistoryActions, UnifiedCallHistoryActionsType } from './unifiedCallHistory.action';
import { SettingsActionTypes } from '../constants';
import { SettingsActions } from '../settings/settings.action';

export enum UnifiedCallHistoryStateRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface UnifiedCallHistoryState {
  page: number;
  requestState: UnifiedCallHistoryStateRequestState;
  initialRequestDate?: Date;
  hasNextPage: boolean;
  byId: IndexedById<UserCallHistoryItem>;
  allIds: string[];
}
const INITIAL_STATE = {
  page: 0,
  requestState: UnifiedCallHistoryStateRequestState.INITIAL,
  hasNextPage: true,
  requestDate: undefined,
  byId: {},
  allIds: [],
};

export const unifiedCallHistoryReducer: Reducer<
  UnifiedCallHistoryState,
  UnifiedCallHistoryActions | SettingsActions
> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UnifiedCallHistoryActionsType.ADD:
      const callHistoryItem = action.payload;
      const { legId } = callHistoryItem;
      return {
        ...state,
        byId: {
          ...state.byId,
          [legId]: callHistoryItem,
        },
        allIds: uniq([legId, ...state.allIds]),
      };
    case UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY:
      return {
        ...state,
        requestState: UnifiedCallHistoryStateRequestState.LOADING,
        initialRequestDate: action.payload.page === 0 ? action.payload.requestDate : state.initialRequestDate,
      };
    case UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_SUCCESS:
      return {
        ...state,
        requestState: UnifiedCallHistoryStateRequestState.LOADED,
        page: action.payload.page,
        hasNextPage: action.payload.hasNextPage,
        byId: Object.assign({}, state.byId, ...action.payload.items.map((i) => ({ [i.legId]: i }))),
        allIds: uniq(state.allIds.concat(action.payload.items.map((i) => i.legId))),
      };
    case UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_DIFF_SUCCESS:
      return {
        ...state,
        requestState: UnifiedCallHistoryStateRequestState.LOADED,
        byId: Object.assign({}, state.byId, ...action.payload.map((i) => ({ [i.legId]: i }))),
        allIds: uniq(action.payload.map((i) => i.legId).concat(state.allIds)),
      };
    case UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_ERROR:
      return {
        ...state,
        requestState: UnifiedCallHistoryStateRequestState.FAILED,
      };
    case SettingsActionTypes.SELECT_LINE: {
      // clear
      return { ...INITIAL_STATE };
    }
    default:
      return state;
  }
};
