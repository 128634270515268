import { List, ListItem } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CallWithContact } from '../calls/calls.reducer';
import { CallPayload } from '../calls/callEvents.action';
import { EmptyState } from '@getgo/chameleon-web/react';
import { PhoneUpDisabledInactiveIcon } from '@getgo/chameleon-icons/react';
import { ActiveCallListItem } from './ActiveCallItem.component';
import { SoftphoneContainer } from '../softphone/SoftphoneContainer';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  EMPTY_MESSAGE_HEADER: {
    id: 'ActiveCalls.EmptyStateMessageHeader',
    defaultMessage: 'No active calls',
  },
  EMPTY_MESSAGE: {
    id: 'ActiveCalls.EmptyStateMessage',
    defaultMessage: 'You don’t have an active call. We will bring up information when you jump on your next call.',
  },
});

export interface ActiveCallsStateProps {
  numberOfActiveCalls: number;
  activeCalls: CallWithContact[];
}

export interface ActiveCallsDispatchProps {
  updateActiveCall: (payload: CallPayload) => void;
}

const avatarColors = [
  tokens.avatarColor00,
  tokens.avatarColor10,
  tokens.avatarColor20,
  tokens.avatarColor30,
  tokens.avatarColor40,
  tokens.avatarColor50,
  tokens.avatarColor02,
  tokens.avatarColor12,
  tokens.avatarColor22,
  tokens.avatarColor32,
];

const ActiveCalls: React.FC<ActiveCallsStateProps & ActiveCallsDispatchProps> = ({
  numberOfActiveCalls,
  activeCalls,
  updateActiveCall,
}) => {
  const intl = useIntl();

  return numberOfActiveCalls > 0 ? (
    <List dense disablePadding>
      {activeCalls.map((call, index) => (
        <ListItem
          data-cy="active-call-item"
          key={call.id}
          disableGutters
          dense
          button
          onClick={() => updateActiveCall({ call })}
        >
          <SoftphoneContainer
            realtimeCall={call}
            optional
            render={(softphoneCall) => (
              <ActiveCallListItem
                realtimeCall={call}
                softphoneCall={softphoneCall}
                avatarColor={avatarColors[index % avatarColors.length]}
              />
            )}
          />
        </ListItem>
      ))}
    </List>
  ) : (
    <EmptyState icon={<PhoneUpDisabledInactiveIcon />} title={intl.formatMessage(definedMessages.EMPTY_MESSAGE_HEADER)}>
      <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
    </EmptyState>
  );
};

export default ActiveCalls;
