import { makeActionCreator } from '../actions/actionCreators';
import { IndexedById } from '../models';
import { Voicemail } from '../voicemails/Voicemail.model';

export enum VoicemailsEntityActionsType {
  ADD = 'jiveEntities/voicemails/add',
  CLEAN = 'jiveEntities/voicemails/clean',
}

export const addVoicemails = makeActionCreator(VoicemailsEntityActionsType.ADD)<IndexedById<Voicemail>>();
export const cleanVoicemails = makeActionCreator(VoicemailsEntityActionsType.CLEAN)();

export type VoicemailsEntityActions = ReturnType<typeof addVoicemails> | ReturnType<typeof cleanVoicemails>;
