import React from 'react';
import { offlineWatcher, onlineWatcher } from '@jive/common';
import { RealtimeActionTypes } from '@jive/realtime-events';
import { IntegrationsActionsType, StartupState } from '../integrations/integrations.actions';
import { integrationStartupStateSelector } from '../integrations/integrations.selector';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { tryStartRealtimeService } from '../settings/realtimeService.action';
import { appOffline, appOnline, ConnectivityActionTypes } from './connectivity.action';
import { isRealtimeReconnectingSelector } from './connectivity.selector';
import { ReconnectHandlerFactory } from './connectivity.service';
import { logger } from '../logging';
import { showMessage } from '../inAppNotification/message.action';
import { MessageVariant } from '../models';
import { definedMessages } from '../inAppNotification/message.content';
import WebsocketConnectionErrorMessage from './WebsocketConnectionErrorMessage.container';

const reconnectHandler = ReconnectHandlerFactory.getReconnectHandler();

addSmartListener(IntegrationsActionsType.INTEGRATION_STARTING)((store) => {
  onlineWatcher(window).subscribe({
    next: () => store.dispatch(appOnline()),
  });
  offlineWatcher(window).subscribe({
    next: () => store.dispatch(appOffline()),
  });
});

addSmartListener(RealtimeActionTypes.REALTIME_OFFLINE)(async (store) => {
  if (navigator.onLine) {
    await reconnectHandler.tryReconnect(
      // Reconnect callback
      () => store.dispatch(tryStartRealtimeService()),
      // Error callback
      () => {
        store.dispatch(
          showMessage({
            id: 'websocket_connection_error_msg',
            message: definedMessages.WEBSOCKET_CONNECTION_ERROR_MESSAGE_DESC,
            type: MessageVariant.Custom,
            params: {
              autoHide: false,
              customContent: <WebsocketConnectionErrorMessage />,
            },
          }),
        );
        logger.error('Connection lost to realtime events.');
      },
    );
  }
});

addListener(RealtimeActionTypes.REALTIME_ONLINE)(() => reconnectHandler.reset());

addListener(ConnectivityActionTypes.APP_ONLINE)((state) => {
  const isIntegrationStarted = integrationStartupStateSelector(state) === StartupState.Started;
  const isRealtimeRestarting = isRealtimeReconnectingSelector(state);

  // in case the app was offline when opened; do not start realtime from here because it will be started during startup
  if (isIntegrationStarted && !isRealtimeRestarting) {
    reconnectHandler.reset();
    return tryStartRealtimeService();
  }
});

addListener(ConnectivityActionTypes.APP_RESTART)(() => window.location.reload());
