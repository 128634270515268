import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { ServiceAppointment } from './serviceAppointments.models';

export enum ServiceAppointmentActionsType {
  LOAD_SERVICE_APPOINTMENTS = 'serviceAppointments/load',
  LOAD_SERVICE_APPOINTMENTS_SUCCESS = 'serviceAppointments/load/success',
  LOAD_SERVICE_APPOINTMENTS_ERROR = 'serviceAppointments/load/error',
}

export const loadServiceAppointments = makeActionCreator(ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS)<{
  VIN: string;
}>();
export const loadServiceAppointmentsSuccess = makeActionCreator(
  ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS_SUCCESS,
)<{ VIN: string; serviceAppointments: ServiceAppointment[] }>();
export const loadServiceAppointmentsError = makeActionCreator(
  ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS_ERROR,
)<WithMessage & { VIN: string }>();

export type ServiceAppointmentActions =
  | ReturnType<typeof loadServiceAppointments>
  | ReturnType<typeof loadServiceAppointmentsSuccess>
  | ReturnType<typeof loadServiceAppointmentsError>;
