import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { ServiceAppointment, ServiceAppointmentStatus } from './serviceAppointments.models';
import { DataRow } from '../DataRow.component';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { toDateTimeStringOrDefault } from '../cox.utils';
import Box from '@material-ui/core/Box';
import { Typography } from '../../theme/Typography.component';

export interface ServiceProps {
  serviceAppointment: ServiceAppointment;
  locale: Locale;
}

const definedMessages = defineMessages({
  STATUS: {
    id: 'Cox.ServiceAppointments.Detail.Status',
    defaultMessage: 'Status:',
  },
  ESTIMATED_PRICE: {
    id: 'Cox.ServiceAppointments.Detail.EstimatedPrice',
    defaultMessage: 'Estimated price:',
  },
  ORDER_NUMBER: {
    id: 'Cox.ServiceAppointments.Detail.OrderNumber',
    defaultMessage: 'Order number:',
  },
  STATUS_SERVICE_DEPARTMENT: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.ServiceDepartment',
    defaultMessage: 'Service Department',
  },
  STATUS_BODY_SHOP: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.BodyShop',
    defaultMessage: 'Body Shop',
  },
  // The status definitions are from this documentation: https://otwiki.dms.dealertrack.com/opentrack/index.php/AppointmentLookup_Request#Definitions
  // Couldn't resolve WP
  STATUS_WP: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.WP',
    defaultMessage: 'WP',
  },
  STATUS_FIRST_VISIT: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.FirstVisit',
    defaultMessage: 'First visit',
  },
  STATUS_BOOKED_FROM_THE_WEB: {
    id: 'Cox.ServiceAppointments.Detail.StatusValues.BookedFromTheWeb',
    defaultMessage: 'Booked from the WEB',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2, 3),
      backgroundColor: tokens.uiBase01,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const statusLabelMapping: Record<ServiceAppointmentStatus, MessageDescriptor> = {
  [ServiceAppointmentStatus.ServiceDepartment]: definedMessages.STATUS_SERVICE_DEPARTMENT,
  [ServiceAppointmentStatus.BodyShop]: definedMessages.STATUS_BODY_SHOP,
  [ServiceAppointmentStatus.WP]: definedMessages.STATUS_WP,
  [ServiceAppointmentStatus.FirstVisit]: definedMessages.STATUS_FIRST_VISIT,
  [ServiceAppointmentStatus.BookedFromTheWeb]: definedMessages.STATUS_BOOKED_FROM_THE_WEB,
};

const getStatusLabel = (status: ServiceAppointmentStatus): MessageDescriptor => statusLabelMapping[status];

export const ServiceAppointmentComponent: React.FC<ServiceProps> = ({ serviceAppointment, locale }) => {
  const classes = useStyles();
  return (
    <Box mb={2}>
      <div className={classes.container}>
        <Box mb={1}>
          <Typography variant="caption-default-02">
            {toDateTimeStringOrDefault(serviceAppointment.appointmentDateTime, locale)}
          </Typography>
        </Box>
        <FormattedMessage {...getStatusLabel(serviceAppointment.status)}>
          {(status) => <DataRow color="text-02" label={definedMessages.STATUS} value={status as string} />}
        </FormattedMessage>
        <DataRow
          color="text-02"
          label={definedMessages.ESTIMATED_PRICE}
          value={`$${(serviceAppointment.totalEstimate || 0).toFixed(2)}`}
        />
        <DataRow color="text-02" label={definedMessages.ORDER_NUMBER} value={serviceAppointment.appointmentNumber} />
      </div>
    </Box>
  );
};
