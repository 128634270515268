import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../../actions/actionCreators';
import { AppState } from '../../reducers';
import { setDealerTrackEntities } from '../../settings/settings.action';
import { dealerTrackEntitiesSelector } from '../../settings/settings.selector';
import { DealerTrackEntitiesSettings as DealerTrackEntitiesSettingsModel } from './cox.settings.models';
import {
  DealerTrackEntitiesComponent,
  DealerTrackEntitiesDispatchProps,
  DealerTrackEntitiesStateProps,
} from './DealertrackEntities.component';

const mapStateToProps = (state): DealerTrackEntitiesStateProps => ({
  settings: dealerTrackEntitiesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppAction>): DealerTrackEntitiesDispatchProps => ({
  setDealerTrackEntities: (settings: DealerTrackEntitiesSettingsModel) => dispatch(setDealerTrackEntities(settings)),
});

export const DealerTrackEntitiesSettings = connect<
  DealerTrackEntitiesStateProps,
  DealerTrackEntitiesDispatchProps,
  {},
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(DealerTrackEntitiesComponent);
