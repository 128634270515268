import { getBaseURIWithoutSlash } from '../browserHistory';
import { ApplicationRoute } from '../constants';

declare global {
  /* eslint-disable no-var */
  var opr: any;
  var InstallTrigger: any;
  var safari: any;
  /* eslint-enable no-var */

  interface Window {
    opr?: any;
    opera?: any;
    HTMLElement?: any;

    chrome?: {
      webstore?: any;
      runtime?: any;
    };
  }

  interface Document {
    documentMode?: any;
  }
}

export function openNotificationPermissionsPage() {
  const permissionEnableUrl = getBaseURIWithoutSlash() + ApplicationRoute.NOTIFICATION_PERMISSION_ROUTE;
  window.open(permissionEnableUrl.toString());
}

export function getNotificationSupportLinkByBroswer(): string {
  // Opera 8.0+
  const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    ((p: boolean | string) => p.toString() === '[object SafariRemoteNotification]')(
      !window.safari || (typeof safari !== 'undefined' && safari.pushNotification),
    );

  // Edge
  const isEdge = /Edg/.test(navigator.userAgent);

  if (isSafari) {
    return 'https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac';
  }

  if (isFirefox) {
    return 'https://support.mozilla.org/en-US/kb/push-notifications-firefox';
  }

  if (isEdge) {
    return 'https://blogs.windows.com/msedgedev/2016/05/16/web-notifications-microsoft-edge';
  }

  if (isOpera) {
    return 'https://help.opera.com/en/latest/web-preferences/#notifications';
  }

  // for everything else, return chrome's setup guide
  return 'https://support.google.com/chrome/answer/3220216#zippy=%2Cfix-issues-with-notifications';
}
