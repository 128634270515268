import { combineReducers } from 'redux';
import errorReducer from '../../reducers/error';
import loadingReducer from '../../reducers/loading';
import { MSTEAMS_PREFERENCES, MsTeamsPreferencesActionsType } from '../constants';
import { MsTeamsPreferencesActions } from './preferences.actions';
import { Locale, Preferences } from './preferences.model';

export interface PreferencesState {
  isLoading: boolean;
  hasError: any;
  result: Preferences;
  availableLocales: Locale[];
}

export const INITIAL_STATE: PreferencesState = {
  isLoading: false,
  hasError: false,
  availableLocales: [],
  result: {
    deviceId: '',
    lineId: '',
    voiceMailNotificationsEnabled: false,
    setupCompleted: false,
    locale: '',
  },
};

function resultReducer(state: Preferences = INITIAL_STATE.result, action: MsTeamsPreferencesActions): Preferences {
  switch (action.type) {
    case MsTeamsPreferencesActionsType.LOAD_SUCCESS:
    case MsTeamsPreferencesActionsType.SAVE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
  }

  return state;
}

function localeReducer(state: Locale[] = INITIAL_STATE.availableLocales, action: MsTeamsPreferencesActions): Locale[] {
  switch (action.type) {
    case MsTeamsPreferencesActionsType.LOAD_AVAILABLE_LOCALES_SUCCESS:
      return [...action.payload];
  }
  return state;
}

const preferences = combineReducers({
  isLoading: loadingReducer(MSTEAMS_PREFERENCES),
  hasError: errorReducer(MSTEAMS_PREFERENCES),
  result: resultReducer,
  availableLocales: localeReducer,
});

export default preferences;
