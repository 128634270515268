import axios from 'axios';
import config from '../config';

const bucketsUrl = `${config.apiBaseUrl}/stockpile/v3/buckets`;

export enum StockpileTtl {
  Forever = 0,
  OneYear = 365,
}

class StockPileService {
  public async getBucket<T>(key: string): Promise<T | undefined> {
    try {
      const response = await axios.get(`${bucketsUrl}/${key}`);
      const data = response?.data?.[key];

      if (!data) {
        return undefined;
      }

      return JSON.parse(data) as T;
    } catch (error) {
      if (error?.response?.status === 404) {
        return undefined;
      }

      throw error;
    }
  }

  public async updateBucket(key: string, data: unknown, ttl: StockpileTtl = StockpileTtl.OneYear): Promise<void> {
    await axios.put(`${bucketsUrl}/${key}`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
      params: { ttl },
    });
  }

  public async deleteBucket(key: string): Promise<void> {
    await axios.delete(`${bucketsUrl}/${key}`);
  }
}

export const stockPileService = new StockPileService();
