import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { fontFamily, ColorTokens } from '@getgo/chameleon-core';
import { TypographyProps as ChamTypographyProps } from '@getgo/chameleon-web/react';

const colorTokens: Array<[keyof ColorTokens, string]> = [
  ['border', tokens.border],
  ['inverse-border', tokens.inverseBorder],
  ['ui-background', tokens.uiBackground],
  ['experience-badge-assist', tokens.experienceBadgeAssist],
  ['experience-badge-message', tokens.experienceBadgeMessage],
  ['experience-badge-voice', tokens.experienceBadgeVoice],
  ['experience-badge-meeting', tokens.experienceBadgeMeeting],
  ['experience-badge-training', tokens.experienceBadgeTraining],
  ['experience-badge-webinar', tokens.experienceBadgeWebinar],
  ['experience-badge-customer', tokens.experienceBadgeCustomer],
  ['experience-badge-gotoresolve', tokens.experienceBadgeGotoresolve],
  ['information-01', tokens.information01],
  ['information-02', tokens.information02],
  ['information-03', tokens.information03],
  ['interactive-01', tokens.interactive01],
  ['interactive-02', tokens.interactive02],
  ['interactive-03', tokens.interactive03],
  ['inverse-interactive-01', tokens.inverseInteractive01],
  ['danger-01', tokens.danger01],
  ['danger-02', tokens.danger02],
  ['danger-03', tokens.danger03],
  ['orange-01', tokens.orange01],
  ['orange-03', tokens.orange03],
  ['success-01', tokens.success01],
  ['success-02', tokens.success02],
  ['success-03', tokens.success03],
  ['warning-01', tokens.warning01],
  ['warning-02', tokens.warning02],
  ['warning-03', tokens.warning03],
  ['ui-01', tokens.ui01],
  ['ui-02', tokens.ui02],
  ['ui-03', tokens.ui03],
  ['ui-04', tokens.ui04],
  ['ui-05', tokens.ui05],
  ['ui-06', tokens.ui06],
  ['ui-base-01', tokens.uiBase01],
  ['ui-base-02', tokens.uiBase02],
  ['text-01', tokens.text01],
  ['text-02', tokens.text02],
  ['text-03', tokens.text03],
  ['text-04', tokens.text04],
  ['text-05', tokens.text05],
  ['text-06', tokens.text06],
  ['text-07', tokens.text07],
  ['text-error-01', tokens.textError01],
  ['text-error-02', tokens.textError02],
  ['text-info', tokens.textInfo],
  ['text-neutral', tokens.textNeutral],
  ['text-success-01', tokens.textSuccess01],
  ['text-success-02', tokens.textSuccess02],
  ['text-warning-01', tokens.textWarning01],
  ['text-warning-02', tokens.textWarning02],
  ['icon-01', tokens.icon01],
  ['icon-02', tokens.icon02],
  ['icon-03', tokens.icon03],
  ['icon-04', tokens.icon04],
  ['icon-05', tokens.icon05],
  ['field-01', tokens.field01],
  ['field-02', tokens.field02],
  ['field-03', tokens.field03],
  ['field-04', tokens.field04],
  ['overlay-01', tokens.overlay01],
  ['presence-available', tokens.presenceAvailable],
  ['presence-away', tokens.presenceAway],
  ['presence-busy', tokens.presenceBusy],
  ['presence-dnd', tokens.presenceDnd],
  ['presence-offline', tokens.presenceOffline],
  ['purple-01', tokens.purple01],
  ['purple-03', tokens.purple03],
  ['inverse-01', tokens.inverse01],
  ['inverse-02', tokens.inverse02],
  ['inverse-ui-05', tokens.inverseUi05],
  ['link', tokens.link],
  ['neutral', tokens.neutral],
  ['neutral-03', tokens.neutral03],
  ['contextual-menu-background-color', tokens.contextualMenuBackgroundColor],
];

const useStyles = makeStyles(() => {
  type ColorSyles = { [P in keyof Required<ColorTokens>]: { color: 'string' } };
  const colorStyles: ColorSyles = colorTokens.reduce<ColorSyles>(
    (a, [key, token]) => ({ ...a, [key]: { color: token } }),
    {} as ColorSyles,
  );

  return {
    common: {
      margin: 0,
    },
    noWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    gutterBottom: {
      marginBottom: 'var(--goto-spacing-04)',
    },
    gutterTop: {
      marginTop: 'var(--goto-spacing-06)',
    },
    ...colorStyles,
    'heading-xsmall': {
      font: `bold 17px/1.3 ${fontFamily}`,
    },
    'body-xsmall': {
      font: `0.75rem ${fontFamily}`,
    },
    lineClamp: {
      '-webkit-line-clamp': (props: { lineClamp: CustomTypographyProps['lineClamp'] }) => props.lineClamp,
    },
  };
});

export interface CustomTypographyProps extends Omit<ChamTypographyProps, 'variant'> {
  variant: 'heading-xsmall' | 'body-xsmall';
}

export const CustomTypography: FC<CustomTypographyProps> = ({
  tag: Tag = 'p',
  className,
  color,
  variant,
  gutterBottom,
  gutterTop,
  noWrap,
  lineClamp,
  children,
  ...rest
}) => {
  const classes = useStyles({ lineClamp });
  return (
    <Tag
      className={clsx(
        classes.common,
        classes[variant],
        color && classes[color],
        gutterBottom && classes.gutterBottom,
        gutterTop && classes.gutterTop,
        noWrap && classes.noWrap,
        lineClamp && classes.lineClamp,
        className,
      )}
      {...(rest as any)}
    >
      {children}
    </Tag>
  );
};
