import { connect } from 'react-redux';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { AppState } from '../../reducers';
import { ServiceTicketFormModel } from './connectwise.serviceTicket.model';
import ServiceTicketForm, { ServiceTicketFormStateProps, ServiceTicketFormProps } from './ServiceTicketForm.component';
import { ConnectWiseActionFacade } from '../connectwise.action.facade';
import { isServiceTicketSavingSelector } from './serviceTicket.selector';
import { getEntityInCallSelector } from '../../calls/calls.selector';

const mapStateToProps = (state: AppState, { callId }: ServiceTicketFormProps): ServiceTicketFormStateProps => ({
  contactId: getEntityInCallSelector(state, callId)?.id,
  isServiceTicketSaveLoading: isServiceTicketSavingSelector(state),
  saveServiceTicket: (serviceTicket: ServiceTicketFormModel) =>
    getActionFacade<ConnectWiseActionFacade>().saveServiceTicket(serviceTicket),
});

export default connect<ServiceTicketFormStateProps, {}, ServiceTicketFormProps, AppState>(mapStateToProps)(
  ServiceTicketForm,
);
