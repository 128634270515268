import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AuthButton } from '../authentication/AuthButton.container';
import { OnboardingPage } from './OnboardingPage.component';
import { Typography } from '../theme/Typography.component';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { SignedInAs } from '../authentication/SignedInAs.container';
import { LinesSelect } from '../settings/LinesSelect.component';
import { ApplicationRoute } from '../constants';
import {
  injectedIntlSelector,
  selectedLineSelector,
  selectedOrganizationIdSelector,
} from '../settings/settings.selector';
import { StepReadySection } from './StepReadySection.component';
import { currentIntegrationNameSelector, isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { areLinesLoadingSelector, linesSelector } from '../lines/lines.selectors';
import { OnboardingNavigationButton } from './OnboardingNavigationButton.component';
import { proxyProviderAvailableSelector } from '../ambassador/proxy/proxy.selector';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  HEADING: {
    id: 'Onboarding.LetsGetStartedMessage',
    defaultMessage: `Let's get started`,
  },
  SIGN_IN_HINT: {
    id: 'Onboarding.SignIn.Hint',
    defaultMessage: 'Sign in to your GoToConnect for {integrationName}',
  },
  LINE_SELECT_HINT: {
    id: 'Onboarding.SignIn.ExtensionSelect.ChooseMessage',
    defaultMessage: 'Choose your extension',
  },
  LINE_SELECT_HELPERTEXT: {
    id: 'Onboarding.SignIn.ExtensionSelect.HelperText',
    defaultMessage: 'You can change this anytime in your settings.',
  },
  LINES_ERROR: {
    id: 'OnBoarding.NoLines',
    defaultMessage:
      'There is no PBX associated with your account. Please contact your GoToConnect admin or sign in with a different account.',
  },
  LOGOUT_LINK_TEXT: {
    id: 'Onboarding.SignIn.WithDifferentAccount.LinkText',
    defaultMessage: 'Sign in with a different account?',
  },
  SELECTED_EXTENSION: {
    id: 'Onboarding.TestCall.SelectedExtension',
    defaultMessage: `You're using extension:`,
  },
  INTEGRATION_STATUS_UNAVAILABLE: {
    id: 'SettingsContainer.Integration.Unavailable',
    defaultMessage: '{integrationName} is not enabled on this PBX. Contact your admin.',
  },
});

export const OnboardingWithSignIn: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const hasLineSelected = !!useSelector(selectedOrganizationIdSelector);
  const intl = useSelector(injectedIntlSelector);
  const integrationName = useSelector(currentIntegrationNameSelector);
  const lines = useSelector(linesSelector);
  const areLinesLoading = useSelector(areLinesLoadingSelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const selectedLine = useSelector(selectedLineSelector);
  const providerAvailable = useSelector(proxyProviderAvailableSelector);

  const FeatureNotAvailableMessage = () => (
    <Box mt={1}>
      <Typography color="text-error-01" variant="caption-default-01">
        <FormattedMessage {...definedMessages.INTEGRATION_STATUS_UNAVAILABLE} values={{ integrationName }} />
      </Typography>
    </Box>
  );

  const renderSignIn = () => {
    return (
      <>
        <StepReadySection ready={isAuthenticated}>
          {isAuthenticated ? (
            <SignedInAs />
          ) : (
            <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'}>
              <FormattedMessage {...definedMessages.SIGN_IN_HINT} values={{ integrationName }} />
            </Typography>
          )}
        </StepReadySection>
        {isAuthenticated ? (
          <Box mt={2}>
            <Link
              onClick={() => getActionFacade().logout()}
              size="small"
              style={{ '--goto-button-padding': '2px' } as any}
            >
              <FormattedMessage {...definedMessages.LOGOUT_LINK_TEXT} />
            </Link>
          </Box>
        ) : (
          <Box mt={2}>
            <AuthButton />
          </Box>
        )}
      </>
    );
  };

  const renderLineSelectSection = () => {
    if (!isAuthenticated || areLinesLoading) {
      return undefined;
    }

    if (Object.keys(lines).length === 0) {
      return (
        <Typography color="text-error-01" variant={isNarrowIntegration ? 'caption-small-01' : 'caption-default-01'}>
          <FormattedMessage
            {...definedMessages.LINES_ERROR}
            values={{
              integrationName,
            }}
          />
        </Typography>
      );
    }

    if (Object.keys(lines).length === 1) {
      return (
        <>
          <Box mb={6}>
            <FormattedMessage {...definedMessages.SELECTED_EXTENSION} />
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              {selectedLine?.number}
            </Typography>
            {providerAvailable === false ? <FeatureNotAvailableMessage /> : null}
          </Box>
        </>
      );
    }

    return (
      <>
        <Box mb={2} id="line-select-label">
          <StepReadySection ready={hasLineSelected && providerAvailable !== false}>
            <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'}>
              <FormattedMessage {...definedMessages.LINE_SELECT_HINT} />
            </Typography>
          </StepReadySection>
        </Box>
        <LinesSelect
          helperText={
            providerAvailable === false ? undefined : intl.formatMessage(definedMessages.LINE_SELECT_HELPERTEXT)
          }
          error={providerAvailable === false}
        />
        {providerAvailable === false ? <FeatureNotAvailableMessage /> : null}
      </>
    );
  };

  const renderNavigation = () => {
    return (
      <>
        <Box mb={1}>
          <OnboardingNavigationButton
            variant="next"
            route={ApplicationRoute.ONBOARDING_TEST_CALL}
            disabled={!hasLineSelected || providerAvailable === false}
          />
        </Box>
        <OnboardingNavigationButton variant="previous" route={ApplicationRoute.ONBOARDING_TOS} />
      </>
    );
  };

  return (
    <OnboardingPage
      content={
        <>
          <Box mb={6}>
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              <FormattedMessage {...definedMessages.HEADING} />
            </Typography>
          </Box>
          <Box mb={5}>{renderSignIn()}</Box>
          <Box flexGrow={1}>{renderLineSelectSection()}</Box>
        </>
      }
      navigation={renderNavigation()}
    />
  );
};
