import React from 'react';
import { ListItem, ListItemText, Paper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Typography } from '../theme/Typography.component';

export interface CallContextListItemProps {
  id: string;
  className: string;
  primary: React.ReactNode;
  secondary: React.ReactNode;
  secondaryActionButton?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(3),
      margin: theme.spacing(2, 0, 0, 0),
    },
    listItemTextRoot: {
      margin: 0,
    },
    primary: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '40px',
    },
    secondaryAction: {
      top: '22px',
      right: '2px',
    },
  }),
);

export const CallContextListItem: React.FC<CallContextListItemProps> = ({
  id,
  className,
  primary,
  secondary,
  secondaryActionButton,
}) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} key={id}>
      <ListItem className={`${className}`} classes={{ root: classes.listItemRoot }}>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
          }}
          primary={<div className={classes.primary}>{primary}</div>}
          secondary={
            <Typography tag="span" variant="caption-default-01" color="text-02">
              {secondary}
            </Typography>
          }
        />
        {secondaryActionButton ? (
          <ListItemSecondaryAction className={classes.secondaryAction}>{secondaryActionButton}</ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </Paper>
  );
};
