import { makeActionCreator } from '../actions/actionCreators';
import { SFUserInfo } from '../../../salesforce-shared/salesforce-service.models';

export enum SaleforceAnalyticsActionTypes {
  USER_INFORMATION = 'salesforce/userInformation',
  USER_INFORMATION_FOUND = 'salesforce/userInformationFound',
}

export const salesforceUserInformation = makeActionCreator(SaleforceAnalyticsActionTypes.USER_INFORMATION)();
export const salesforceUserInformationFound = makeActionCreator(SaleforceAnalyticsActionTypes.USER_INFORMATION_FOUND)<
  SFUserInfo[]
>();

export type SalesforceAnalyticsActions =
  | ReturnType<typeof salesforceUserInformation>
  | ReturnType<typeof salesforceUserInformationFound>;
