import { Integrations } from '../models';
import semver from 'semver';
import { stockPileService, StockpileTtl } from '../stockPile/stockPile.service';
import {
  LAST_SEEN_ENTRY_KEY,
  VersionInfo,
  NEXT_VERSION_PLACEHOLDER,
  LAST_SEEN_ANNOUNCEMENT_KEY,
} from './changelog.models';
import axios from 'axios';
import { logger } from '../logging';

export class ChangelogVersionManager {
  private _latestVersions: VersionInfo | undefined;
  private _latestAnnouncements: VersionInfo | undefined;

  public async getLastSeenVersion(): Promise<string | undefined> {
    return await stockPileService.getBucket<string>(LAST_SEEN_ENTRY_KEY);
  }

  public async getLastSeenAnnouncement(): Promise<string | undefined> {
    return await stockPileService.getBucket<string>(LAST_SEEN_ANNOUNCEMENT_KEY);
  }

  public async hasNewerVersion(integration: Integrations, lastSeenEntry: string | undefined): Promise<boolean> {
    const versions = await this.getLatestVersions();
    return this.hasNewerItem(integration, lastSeenEntry, versions);
  }

  public async hasNewerAnnouncement(
    integration: Integrations,
    lastSeenAnnouncement: string | undefined,
  ): Promise<boolean> {
    const announcements = await this.getLatestAnnouncements();
    return this.hasNewerItem(integration, lastSeenAnnouncement, announcements);
  }

  public async getLatestVersion(integration: Integrations): Promise<string | undefined> {
    const versions = await this.getLatestVersions();
    return versions ? versions[integration] : undefined;
  }

  public async getLatestAnnouncement(integration: Integrations): Promise<string | undefined> {
    const announcements = await this.getLatestAnnouncements();
    const integrationAnnouncement = announcements ? announcements[integration] : undefined;
    const allAnnouncement = announcements ? announcements.all : undefined;

    if (integrationAnnouncement && allAnnouncement) {
      return semver.gt(integrationAnnouncement, allAnnouncement) ? integrationAnnouncement : allAnnouncement;
    }
    return integrationAnnouncement ?? allAnnouncement;
  }

  public async storeLastSeenVersion(integration: Integrations) {
    const versions = await this.getLatestVersions();
    const lastSeenVersionOfIntegration = versions?.[integration] ?? '0.0.0';
    const lastSeenVersionOfAll = versions.all ?? '0.0.0';

    const lastSeenVersion = semver.gt(lastSeenVersionOfAll, lastSeenVersionOfIntegration)
      ? lastSeenVersionOfAll
      : lastSeenVersionOfIntegration;

    await this.storeLastSeenItem(lastSeenVersion, LAST_SEEN_ENTRY_KEY);
  }

  public async storeLastSeenAnnouncement(integration: Integrations): Promise<void> {
    const announcements = await this.getLatestAnnouncements();
    const lastSeenVersionOfIntegration = announcements?.[integration] ?? '0.0.0';
    const lastSeenVersionOfAll = announcements.all ?? '0.0.0';

    const lastSeenVersion = semver.gt(lastSeenVersionOfAll, lastSeenVersionOfIntegration)
      ? lastSeenVersionOfAll
      : lastSeenVersionOfIntegration;

    await this.storeLastSeenItem(lastSeenVersion, LAST_SEEN_ANNOUNCEMENT_KEY);
  }

  private hasNewerItem(integration: Integrations, lastSeenVersion: string | undefined, versions: VersionInfo): boolean {
    const latestIntegrationVersion = versions ? versions[integration] : undefined;
    const latestAllVersion = versions ? versions.all : undefined;

    if (!latestIntegrationVersion && !latestAllVersion) {
      return false;
    }

    if (!lastSeenVersion) {
      return true;
    }

    const isNextVersion =
      latestIntegrationVersion === NEXT_VERSION_PLACEHOLDER || latestAllVersion === NEXT_VERSION_PLACEHOLDER;
    if (isNextVersion) {
      return true;
    }

    return (
      semver.gt(latestIntegrationVersion ?? '0.0.0', lastSeenVersion) ||
      semver.gt(latestAllVersion ?? '0.0.0', lastSeenVersion)
    );
  }

  private async storeLastSeenItem(version: string, storeKey: string) {
    if (version !== NEXT_VERSION_PLACEHOLDER) {
      await stockPileService.updateBucket(storeKey, version, StockpileTtl.OneYear);
    }
  }

  private async getLatestVersions(): Promise<VersionInfo> {
    if (!this._latestVersions) {
      try {
        const response = await axios.get<VersionInfo>('./json/changelog.latestversions.json');
        this._latestVersions = response.data;
      } catch (e) {
        logger.error('Error loading latest version info', e);
        this._latestVersions = {};
      }
    }

    return this._latestVersions;
  }

  private async getLatestAnnouncements(): Promise<VersionInfo> {
    if (!this._latestAnnouncements) {
      try {
        const response = await axios.get<VersionInfo>('./json/changelog.announcements.json');
        this._latestAnnouncements = response.data;
      } catch (e) {
        logger.error('Error loading announcement versions', e);
        this._latestAnnouncements = {};
      }
    }
    return this._latestAnnouncements;
  }
}

export const changelogVersionManager = new ChangelogVersionManager();
