import { ActionFacade } from '../actionFacade/action.facade';
import {
  AddNoteCapableFacade,
  DownloadNotesCapableFacade,
  OpenFormInCrmCapableFacade,
  CAPABILITY_DOWNLOAD_NOTES,
  CAPABILITY_OPEN_FORM_IN_CRM,
  CAPABILITY_ADD_NOTES,
} from '../actionFacade/action.facade.capabilities';
import { ConnectWiseNoteActionCreator } from './note/connectwise.note.actioncreator';
import ConnectWiseActionCreatorFactory from './connectwise.actionCreator.factory';
import { OpenCrmFormModel } from '../actionFacade/actionCreators/crmForm/crmForm.model';
import { CrmFormActionCreator } from '../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { ConnectWiseNoteTypeActionCreator } from './note/notetype/connectwise.notetype.actioncreator';
import { AddNotePayload } from '../notes/notes.models';
import { ServiceTicketActionCreator } from './serviceTicket/connectwise.serviceTicket.actioncreator';
import { ServiceTicketFormModel } from './serviceTicket/connectwise.serviceTicket.model';
import { CompanyActionCreator } from './companyPicker/connectwise.company.actioncreator';

export class ConnectWiseActionFacade extends ActionFacade
  implements DownloadNotesCapableFacade, OpenFormInCrmCapableFacade, AddNoteCapableFacade {
  [CAPABILITY_DOWNLOAD_NOTES]: true = true;
  [CAPABILITY_OPEN_FORM_IN_CRM]: true = true;
  [CAPABILITY_ADD_NOTES]: true = true;

  private noteActionCreator: ConnectWiseNoteActionCreator;
  private crmFormActionCreator: CrmFormActionCreator;
  private noteTypeActionCreator: ConnectWiseNoteTypeActionCreator;
  private serviceTicketActionCreator: ServiceTicketActionCreator;
  private companyActionCreator: CompanyActionCreator;

  constructor(actionCreatorFactory: ConnectWiseActionCreatorFactory) {
    super(actionCreatorFactory);
    this.noteActionCreator = actionCreatorFactory.createNoteActionCreator();
    this.crmFormActionCreator = actionCreatorFactory.createCrmFormActionCreator();
    this.noteTypeActionCreator = actionCreatorFactory.createNoteTypeActionCreator();
    this.serviceTicketActionCreator = actionCreatorFactory.createServiceTicketActionCreator();
    this.companyActionCreator = actionCreatorFactory.createCompanyActionCreator();
  }

  loadMostRecentNoteOfContact(contactId: string): Promise<void> {
    return this.noteActionCreator.loadMostRecentNoteOfContact(contactId);
  }

  loadMoreNotesOfContact(): Promise<void> {
    return this.noteActionCreator.loadMoreNotesOfContact();
  }

  openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    return this.crmFormActionCreator.openFormInCrm(model);
  }

  getNoteTypes(): Promise<void> {
    return this.noteTypeActionCreator.getNoteTypes();
  }

  addNote(model: AddNotePayload): Promise<void> {
    return this.noteActionCreator.addNote(model);
  }

  saveServiceTicket(model: ServiceTicketFormModel): Promise<void> {
    return this.serviceTicketActionCreator.saveServiceTicket(model);
  }

  getCompanies(): Promise<void> {
    return this.companyActionCreator.getCompanies();
  }

  clearCompanies(): Promise<void> {
    return this.companyActionCreator.clearCompanies();
  }
}
