import { makeActionCreator } from '../../actions/actionCreators';
import { JiveEntityRelationshipsOneToMany } from './relationshipsOneToMany.reducer';

export enum LineVoicemailsEntityRelationshipActionsType {
  REPLACE = 'jiveEntities/relationships/lineVoicemails/replace',
  CLEAN = 'jiveEntities/relationships/lineVoicemails/clean',
}

export const replaceLineVoicemailsRelationship = makeActionCreator(LineVoicemailsEntityRelationshipActionsType.REPLACE)<
  JiveEntityRelationshipsOneToMany
>();
export const cleanLineVoicemailsRelationship = makeActionCreator(LineVoicemailsEntityRelationshipActionsType.CLEAN)();

export type LineVoicemailsRelationshipActions =
  | ReturnType<typeof replaceLineVoicemailsRelationship>
  | ReturnType<typeof cleanLineVoicemailsRelationship>;
