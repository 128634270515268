import { Entity } from '../../models/index';
import { EntityPhoneNumber, EntityPhoneNumberType } from '../../search/entities.model';
import { AppState } from '../../reducers';
import { Store } from 'redux';
import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';
import { SalesforceContact } from '../../../../salesforce-shared/salesforce-service.models';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import { closeDialog, openDialog } from '../../dialog/dialog.actions';
import { DialogBodyNames } from '../../dialog/DialogBodies';
import { defineMessages } from 'react-intl';
import { syncService } from '../../sync/sync.service';
import { openEntityTabError, openEntityTabSuccess } from '../../search/entities.action';
import { isOpenContactInNewTabEnabledSelector } from '../../settings/settings.selector';
import { logger } from '../../logging';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { Call } from '@jive/realtime-events';
import { calledContactSelector } from '../../phone/phone.selector';
import { contactsByCallIdSelector } from '../../calls/calls.selector';
import { isRunningInConsoleModeSelector } from '../salesforce.selectors';
import { SalesforceEntity } from '../salesforce.models';
import { callAssignmentService } from '../../calls/callAssignmentService';

const definedMessages = defineMessages({
  DIALOG_CONTACT_UPDATE_TITLE: {
    id: 'Salesforce.Dialog.ContactUpdate.Title',
    defaultMessage: 'Contact Update',
  },
  DIALOG_CONTACT_UPDATE_CONFIRM: {
    id: 'Salesforce.Dialog.ContactUpdate.Confirm',
    defaultMessage: 'Send',
  },
  DIALOG_CONTACT_UPDATE_CANCEL: {
    id: 'Salesforce.Dialog.ContactUpdate.Cancel',
    defaultMessage: 'Cancel',
  },
  OPEN_CONTACT_ERROR: {
    id: 'Open.Contact.Error',
    defaultMessage: 'An error occurred while opening the user.',
    description: 'error message contact open',
  },
});

const OPEN_CONTACT_ON_NEW_TAB_SYNC_PREFIX = 'open_contact_tab_sync';
const CLEANUP_OPEN_CONTACT_TAB_SYNC_AFTER_MILLIS = 15000;

export class SalesforceLightningContactActionCreator extends ContactActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
    this.mapSalesforceContactToEntity = this.mapSalesforceContactToEntity.bind(this);
  }

  public async searchContactForCall(call: Call): Promise<void> {
    // run contact search
    await super.searchContactForCall(call);

    // open contact in new tab is settings say so
    const isOpenContactInNewTabEnabled = isOpenContactInNewTabEnabledSelector(this.store.getState());
    if (!isOpenContactInNewTabEnabled) {
      return;
    }

    // check if we started the call from a contact
    const calledContact = calledContactSelector(this.store.getState());

    if (call.isClickToCall && calledContact) {
      await this.openContactOnNewTab(calledContact.id);
    } else {
      const contactForCall = contactsByCallIdSelector(this.store.getState(), call.id);
      if (contactForCall.allMatches?.length === 1) {
        await this.openContactOnNewTab(contactForCall.allMatches[0].id);
      }
    }
  }

  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const salesforcePhoneNumbers = await this.salesforceLightningProxy.getContactPhoneNumbers(contactId);

    return salesforcePhoneNumbers.map((phoneNumber) => ({
      entityId: contactId,
      type: this.mapPhoneFieldNameToPhoneType(phoneNumber.name),
      phoneNumber: phoneNumber.number,
    }));
  }

  protected _openCrmContactPage(contactId: string): Promise<void> {
    return this.salesforceLightningProxy.openEntityPage(contactId);
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this.salesforceLightningProxy.openEntityTab(contactId);
  }

  protected async _searchContactByName(query: string): Promise<Entity[]> {
    const contacts = await this.salesforceLightningProxy.searchContacts(query);

    return contacts.map(this.mapSalesforceContactToEntity);
  }

  protected async _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]> {
    const contacts = await this.salesforceLightningProxy.searchContactsByPhoneNumber(phoneNumber);
    return contacts.map(this.mapSalesforceContactToEntity);
  }

  protected async _getUserAssignmentForCall(
    call: Call,
  ): Promise<{ entity: Entity | undefined; isAutoSingleMatchActiveForPhoneNumber: boolean }> {
    try {
      const assignments = await callAssignmentService.loadAssignments();
      const assignment = assignments?.find((a) => a.phoneNumber === call.theOtherParty.number);
      if (assignment === undefined || assignment.isActive === false) {
        return { entity: undefined, isAutoSingleMatchActiveForPhoneNumber: assignment?.isActive ?? true };
      }

      const assignedEntity = await this._getContactById(assignment.contactId);
      return { entity: assignedEntity, isAutoSingleMatchActiveForPhoneNumber: true };
    } catch (error) {
      logger.error('Failed to fetch user assignment for call', error);
      return { entity: undefined, isAutoSingleMatchActiveForPhoneNumber: true };
    }
  }

  protected async _findAutoSingleMatchForContacts(entities: SalesforceEntity[]): Promise<Entity | undefined> {
    if (entities.length !== 2) {
      return undefined;
    }

    return entities.find(
      (entity) =>
        entity.type === 'Contact' && entities.some((relatedEntity) => relatedEntity.id === entity.relatedEntityId),
    );
  }

  protected _canUpdateContactPhoneNumber(): Promise<boolean> {
    return new Promise((resolve) => {
      this.store.dispatch(
        openDialog({
          confirmAction: closeDialog({}),
          cancellable: true,
          texts: {
            title: definedMessages.DIALOG_CONTACT_UPDATE_TITLE,
            confirm: definedMessages.DIALOG_CONTACT_UPDATE_CONFIRM,
            cancel: definedMessages.DIALOG_CONTACT_UPDATE_CANCEL,
          },
          bodyName: DialogBodyNames.PhoneNumberUpdateConfirmation,
          closeCallback: ({ isConfirmed }) => resolve(isConfirmed),
        }),
      );
    });
  }

  protected async _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    await this.salesforceLightningProxy.updateEntityPhoneNumber(contactId, phoneNumber);
  }

  protected _createContact(contactData: ContactCreateModel): Promise<string> {
    throw new Error('Method not implemented.' + contactData);
  }

  protected _isAutoContactAssignmentEnabled(): boolean {
    return true;
  }

  protected async _getContactById(contactId: string): Promise<Entity | undefined> {
    const salesforceContact = await this.salesforceLightningProxy.getContactById(contactId);
    if (!salesforceContact) {
      return undefined;
    }
    return this.mapSalesforceContactToEntity(salesforceContact);
  }

  private mapPhoneFieldNameToPhoneType(name: string): EntityPhoneNumberType {
    return phoneFieldNameToPhoneTypeMap[name] || EntityPhoneNumberType.BUSINESS;
  }

  protected mapSalesforceContactToEntity(contact: SalesforceContact): SalesforceEntity {
    return {
      id: contact.id,
      name: contact.name,
      label: this.mapContactToContactLabel(contact),
      phoneNumber: contact.phoneNumber,
      type: contact.type,
      doNotCall: contact.doNotCall,
      relatedEntityId: contact.relatedEntityId,
    };
  }

  protected mapContactToContactLabel(contact: SalesforceContact): string {
    return `${contact.type} - ${contact.name || contact.id}`;
  }

  private async openContactOnNewTab(contactId: string) {
    if (contactId === undefined || contactId === '') {
      logger.error('Tried to open contact on new tab without id.');
      this.store.dispatch(
        openEntityTabError({
          message: definedMessages.OPEN_CONTACT_ERROR,
        }),
      );
      return;
    }

    try {
      // in console mode we can open new tabs without any issue
      if (!isRunningInConsoleModeSelector(this.store.getState())) {
        const canOpenNewTab = await syncService.canCurrentTabOperate(
          OPEN_CONTACT_ON_NEW_TAB_SYNC_PREFIX,
          contactId,
          CLEANUP_OPEN_CONTACT_TAB_SYNC_AFTER_MILLIS,
        );

        if (!canOpenNewTab) {
          return;
        }
      }

      const isContactOpened = await this._openCrmContactTab(contactId);
      const openContactAnalyticsAction: AnalyticsAction = isContactOpened
        ? AnalyticsAction.ContactTabOpened
        : AnalyticsAction.ContactTabBlocked;
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Contact,
        action: openContactAnalyticsAction,
        label: 'Open contact on new tab',
      });

      if (isContactOpened) {
        this.store.dispatch(openEntityTabSuccess());
      }
    } catch (error) {
      logger.error(`Could not open contact on new tab with id`, error, { contactId });
      this.store.dispatch(
        openEntityTabError({
          message: definedMessages.OPEN_CONTACT_ERROR,
        }),
      );
    }
  }
}

const phoneFieldNameToPhoneTypeMap: { [key: string]: EntityPhoneNumberType } = {
  Phone: EntityPhoneNumberType.BUSINESS,
  Fax: EntityPhoneNumberType.FAX,
  OtherPhone: EntityPhoneNumberType.OTHER,
  AssistantPhone: EntityPhoneNumberType.ASSISTANT,
  HomePhone: EntityPhoneNumberType.HOME,
  MobilePhone: EntityPhoneNumberType.MOBILE,
};
