import { Store } from 'redux';
import { AppState } from '../../reducers';
import { logger } from '../../logging';
import { ServiceTicketFormModel } from './connectwise.serviceTicket.model';
import { ConnectWiseService } from '../connectwise.service';
import { defineMessages } from 'react-intl';
import { saveServiceTicketError, saveServiceTicketRequest, saveServiceTicketSuccess } from './serviceTicket.action';
import { goToCallPage } from '../../browserHistory';

export const definedMessages = defineMessages({
  SAVE_SERVICE_TICKET_ERROR: {
    id: 'ConnectWise.ServiceTicket.Save.Error',
    defaultMessage: 'There was an error while saving the service ticket, please try again.',
  },
  SAVE_SERVICE_TICKET_SUCCESS: {
    id: 'ConnectWise.ServiceTicket.Save.Success',
    defaultMessage: 'Service ticket created successfully!',
  },
});

export class ServiceTicketActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async saveServiceTicket(model: ServiceTicketFormModel): Promise<void> {
    const { companyId, contactId, summary } = model;

    const parametersAreProvided = !!companyId && !!contactId && !!summary.trim();

    if (!parametersAreProvided) {
      logger.error('Not all parameters are provided for service ticket creation', model);
      this.store.dispatch(
        saveServiceTicketError({
          message: definedMessages.SAVE_SERVICE_TICKET_ERROR,
        }),
      );
    }

    try {
      this.store.dispatch(saveServiceTicketRequest());
      await ConnectWiseService.saveServiceTicket(contactId, companyId, summary);
      this.store.dispatch(saveServiceTicketSuccess({ message: definedMessages.SAVE_SERVICE_TICKET_SUCCESS }));
      goToCallPage(model.callId);
    } catch (e) {
      logger.error('Error creating service ticket', e);
      this.store.dispatch(
        saveServiceTicketError({
          message: definedMessages.SAVE_SERVICE_TICKET_ERROR,
        }),
      );
    }
  }
}
