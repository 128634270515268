import { Channel } from './channel';
import { ChannelEvent } from './channel-event';
import { NotificationChannelInfo, NotificationChannelEvent, RTCError } from './models';
import configuration from '../config';

export class NotificationChannel extends Channel<NotificationChannelInfo> {
  protected getChannelRefreshInfoURL() {
    const { notificationChannelUrl } = configuration;
    return `${notificationChannelUrl}/channels/${this.externalUserKey}/${this.channelInfo?.channelId}/channelLifetime`;
  }

  protected getChannelInfoURL() {
    const { notificationChannelUrl } = configuration;
    return `${notificationChannelUrl}/channels/${this.externalUserKey}`;
  }

  protected getChannelURL(channelInfo: NotificationChannelInfo) {
    return channelInfo.channelData?.channelURL ?? '';
  }

  protected getChannelID(channelInfo: NotificationChannelInfo) {
    return channelInfo.resourceURL?.split('/').pop() ?? '';
  }

  protected getChannelDeleteURL(channelInfo: NotificationChannelInfo) {
    const { notificationChannelUrl } = configuration;
    const channelId = this.getChannelID(channelInfo);

    return `${notificationChannelUrl}/channels/${this.externalUserKey}/${channelId}`;
  }

  subscribe<T>(eventListener: (event: NotificationChannelEvent<T>) => void) {
    this.addListener(ChannelEvent.MESSAGE, eventListener);
  }

  unsubscribe<T>(eventListener: (event: NotificationChannelEvent<T>) => void) {
    this.removeListener(ChannelEvent.MESSAGE, eventListener);
  }

  protected doChannelConnected() {
    super.doChannelConnected();
    this.emit(ChannelEvent.CONNECTED);
  }

  protected doChannelDisconnected(error: RTCError) {
    super.doChannelDisconnected(error);
    this.emit(ChannelEvent.DISCONNECTED);
  }

  protected doChannelMessage(data: NotificationChannelEvent) {
    super.doChannelMessage(data);
    this.emit(ChannelEvent.MESSAGE, data);
  }

  protected doChannelError(error: RTCError) {
    super.doChannelError(error);
    this.emit(ChannelEvent.ERROR, error);
  }
}
