import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import classNames from 'classnames';
import { TrackedSwitch } from '../../analytics-new/TrackedSwitch.component';
import { DealerTrackEntitiesSettings } from './cox.settings.models';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { IS_CALL_HISTORY_ENABLED } from '../cox.flags';

const definedMessages = defineMessages({
  ENTITY_HEADER: {
    id: 'Cox.Settings.DealerTrackEntities.EntityHeader',
    defaultMessage: 'Entity',
  },
  SHOW_HEADER: {
    id: 'Cox.Settings.DealerTrackEntities.ShowHeader',
    defaultMessage: 'Show',
  },
  EXPAND_HEADER: {
    id: 'Cox.Settings.DealerTrackEntities.ExpandHeader',
    defaultMessage: 'Expand',
  },
  VEHICLES_LABEL: {
    id: 'Cox.Settings.DealerTrackEntities.VehiclesLabel',
    defaultMessage: 'Vehicles',
  },
  SERVICES_LABEL: {
    id: 'Cox.Settings.DealerTrackEntities.ServicesLabel',
    defaultMessage: 'Services',
  },
  PARTS_LABEL: {
    id: 'Cox.Settings.DealerTrackEntities.PartsLabel',
    defaultMessage: 'Parts',
  },
  HISTORY_LABEL: {
    id: 'Cox.Settings.DealerTrackEntities.HistoryLabel',
    defaultMessage: 'History',
  },
});

const trackingEvents = defineTrackingEvents({
  SHOW_PARTS_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show parts | toggle | ON',
  },
  SHOW_PARTS_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show parts | toggle | OFF',
  },
  SHOW_SERVICES_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show services | toggle | ON',
  },
  SHOW_SERVICES_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show services | toggle | OFF',
  },
  SHOW_HISTORY_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show history | toggle | ON',
  },
  SHOW_HISTORY_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Show history | toggle | OFF',
  },
  EXPAND_PARTS_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand parts | toggle | ON',
  },
  EXPAND_PARTS_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand parts | toggle | OFF',
  },
  EXPAND_SERVICES_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand services | toggle | ON',
  },
  EXPAND_SERVICES_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand services | toggle | OFF',
  },
  EXPAND_HISTORY_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand history | toggle | ON',
  },
  EXPAND_HISTORY_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand history | toggle | OFF',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchColumn: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 2),
      alignItems: 'center',
    },
    entitiesHeaderCell: {
      lineHeight: '26px',
      fontWeight: 'bold',
    },
    entitiesCell: {
      lineHeight: '26px',
      marginTop: theme.spacing(3),
    },
    entitiesSubSwitch: {
      marginTop: 0,
    },
    entitiesSubCell: {
      margin: theme.spacing(0, 0, 0, 4),
    },
    entitiesContainer: {
      borderRadius: '5px',
      backgroundColor: tokens.uiBase01,
      padding: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  }),
);

export interface DealerTrackEntitiesStateProps {
  settings: DealerTrackEntitiesSettings;
}

export interface DealerTrackEntitiesDispatchProps {
  setDealerTrackEntities(settings: DealerTrackEntitiesSettings);
}

type DealerTrackEntitiesProps = DealerTrackEntitiesStateProps & DealerTrackEntitiesDispatchProps;

type SwitchNames = 'services' | 'parts' | 'history';
type SwitchTypes = 'show' | 'expand';

interface SwitchProps {
  name: SwitchNames;
  type: SwitchTypes;
}

const ARIA_LABELS = {
  show: definedMessages.SHOW_HEADER,
  expand: definedMessages.EXPAND_HEADER,
  parts: definedMessages.PARTS_LABEL,
  services: definedMessages.SERVICES_LABEL,
  history: definedMessages.HISTORY_LABEL,
};

const TRACKING_EVENTS = {
  services: {
    show: {
      on: trackingEvents.SHOW_SERVICES_SWITCHED_ON,
      off: trackingEvents.SHOW_SERVICES_SWITCHED_OFF,
    },
    expand: {
      on: trackingEvents.EXPAND_SERVICES_SWITCHED_ON,
      off: trackingEvents.EXPAND_SERVICES_SWITCHED_OFF,
    },
  },
  parts: {
    show: {
      on: trackingEvents.SHOW_PARTS_SWITCHED_ON,
      off: trackingEvents.SHOW_PARTS_SWITCHED_OFF,
    },
    expand: {
      on: trackingEvents.EXPAND_PARTS_SWITCHED_ON,
      off: trackingEvents.EXPAND_PARTS_SWITCHED_OFF,
    },
  },
  history: {
    show: {
      on: trackingEvents.SHOW_HISTORY_SWITCHED_ON,
      off: trackingEvents.SHOW_HISTORY_SWITCHED_OFF,
    },
    expand: {
      on: trackingEvents.EXPAND_HISTORY_SWITCHED_ON,
      off: trackingEvents.EXPAND_HISTORY_SWITCHED_OFF,
    },
  },
};

export const DealerTrackEntitiesComponent: React.FC<DealerTrackEntitiesProps> = ({
  settings,
  setDealerTrackEntities,
}) => {
  const intl = useIntl();

  const isChecked = (name: SwitchNames, type: SwitchTypes) => {
    if (type === 'show') {
      return settings[name][type];
    }
    return settings[name].show && settings[name][type];
  };

  const isDisabled = (name: SwitchNames, type: SwitchTypes) => {
    return type === 'expand' && !settings[name].show;
  };

  const onChange = (name: SwitchNames, type: SwitchTypes, value: boolean) => {
    const newState = {
      ...settings,
      [name]: {
        ...settings[name],
        [type]: value,
      },
    };
    setDealerTrackEntities(newState);
  };

  const EntitiesSwitch = ({ name, type }: SwitchProps) => (
    <TrackedSwitch
      checked={isChecked(name, type)}
      onChange={({ currentTarget: { checked } }) => onChange(name, type, checked)}
      onTrackingEvent={TRACKING_EVENTS[name][type].on}
      offTrackingEvent={TRACKING_EVENTS[name][type].off}
      disabled={isDisabled(name, type)}
      aria-label={`${intl.formatMessage(ARIA_LABELS[type])} ${intl.formatMessage(ARIA_LABELS[name])}`}
    />
  );

  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.entitiesContainer}>
      <Box display="flex">
        <Box display="flex" flex={1} flexDirection="column">
          <Box display="flex" flex={1} flexDirection="column">
            <Box className={classes.entitiesHeaderCell}>
              <FormattedMessage {...definedMessages.ENTITY_HEADER} />
            </Box>
          </Box>
          <Box className={classes.entitiesCell}>
            <FormattedMessage {...definedMessages.VEHICLES_LABEL} />
          </Box>
          <Box className={classNames(classes.entitiesCell, classes.entitiesSubCell)}>
            <FormattedMessage {...definedMessages.SERVICES_LABEL} />
          </Box>
          <Box className={classNames(classes.entitiesCell, classes.entitiesSubCell)}>
            <FormattedMessage {...definedMessages.PARTS_LABEL} />
          </Box>
          {IS_CALL_HISTORY_ENABLED ? (
            <Box className={classes.entitiesCell}>
              <FormattedMessage {...definedMessages.HISTORY_LABEL} />
            </Box>
          ) : null}
        </Box>

        <Box display="flex">
          <Box className={classes.switchColumn}>
            <Box className={classNames(classes.entitiesHeaderCell)}>
              <FormattedMessage {...definedMessages.SHOW_HEADER} />
            </Box>
            <Box className={classNames(classes.entitiesCell)}>&nbsp;</Box>
            <Box className={classNames(classes.entitiesSubSwitch, classes.entitiesCell)}>
              <EntitiesSwitch name="services" type="show" />
            </Box>
            <Box className={classNames(classes.entitiesSubSwitch, classes.entitiesCell)}>
              <EntitiesSwitch name="parts" type="show" />
            </Box>
            {IS_CALL_HISTORY_ENABLED ? (
              <Box className={classNames(classes.entitiesCell)}>
                <EntitiesSwitch name="history" type="show" />
              </Box>
            ) : null}
          </Box>

          <Box className={classes.switchColumn}>
            <Box className={classNames(classes.entitiesHeaderCell)}>
              <FormattedMessage {...definedMessages.EXPAND_HEADER} />
            </Box>
            <Box className={classNames(classes.entitiesCell)}>&nbsp;</Box>
            <Box className={classNames(classes.entitiesSubSwitch, classes.entitiesCell)}>
              <EntitiesSwitch name="services" type="expand" />
            </Box>
            <Box className={classNames(classes.entitiesSubSwitch, classes.entitiesCell)}>
              <EntitiesSwitch name="parts" type="expand" />
            </Box>
            {IS_CALL_HISTORY_ENABLED ? (
              <Box className={classNames(classes.entitiesCell)}>
                <EntitiesSwitch name="history" type="expand" />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
