import { makeActionCreator } from '../../actions/actionCreators';
import { Note } from '../../notes/notes.models';

export enum RedtailNoteActionsType {
  GET_NOTES_SUCCESS = 'redtail/notes/get/success',
}

export interface GetRedtailNotesSuccessPayload {
  notes: Note[];
}

export const getRedtailNotesSuccess = makeActionCreator(RedtailNoteActionsType.GET_NOTES_SUCCESS)<
  GetRedtailNotesSuccessPayload
>();

export type RedtailNoteActions = ReturnType<typeof getRedtailNotesSuccess>;
