import { MessageDescriptor } from 'react-intl';
import { AppAction } from '../actions/actionCreators';
import { InAppNotificationMessage } from '../models';
export const MESSAGE_INITIAL_STATE = null;

type Message = MessageDescriptor | null;

const messageReducer =
  (actionType: string, resetActionType: string) =>
  (state: Message = MESSAGE_INITIAL_STATE, action: AppAction<string, InAppNotificationMessage>): Message => {
    if (action.type === actionType) {
      return (action.payload && action.payload.message) || null;
    }

    if (action.type === resetActionType) {
      return null;
    }

    return state;
  };

export default messageReducer;
