import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Entity } from '../models';
import { SmallIcon } from '../components/SmallIcon.component';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { allCallSelector, getContactMatchTypeSelector } from '../calls/calls.selector';
import { newTracker } from '../analytics-new/tracker-new';
import { stringifyCallType } from '../calls/call.helper';
import { MenuItem, MenuSection, MenuSeparator } from '@getgo/chameleon-web/react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { AppState } from '../reducers';
import { setPhoneSelectedEntity } from '../phone/phone.actions';
import { ExternalLinkIcon, PersonTransferToInactiveIcon } from '@getgo/chameleon-icons/react';
import { callAssignmentService } from '../calls/callAssignmentService';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { CAPABILITY_AUTO_CALL_ASSIGNMENT } from '../actionFacade/action.facade.capabilities';
import { CallWithContact } from './calls.reducer';
import { openEntity } from '../search/entities.action';

export const definedMessages = defineMessages({
  REMOVE_ASSOCIATION: {
    id: 'SearchResult.RemoveAssociationToThisContact.Header',
    defaultMessage: 'Remove the association to this contact?',
  },
  THIS_CALL: {
    id: 'SearchResult.RemoveAssociationToThisContact.ThisCall',
    defaultMessage: 'For this call only',
  },
  EVERY_CALL: {
    id: 'SearchResult.RemoveAssociationToThisContact.EveryCall',
    defaultMessage: 'For every call',
  },
  OPEN_CONTACT_IN_CRM: {
    id: 'SearchResult.OpenInCRM',
    defaultMessage: 'Open contact in CRM',
  },
});

export interface CallCardMoreProps {
  entity: Entity;
}

export const CallCardMore: React.FC<CallCardMoreProps> = ({ entity }) => {
  const routeMatch = useRouteMatch<{ callId: string }>();
  const call = useSelector((state: AppState) => allCallSelector(state, routeMatch.params.callId)) as CallWithContact;
  const matchType = useSelector((state: AppState) => getContactMatchTypeSelector(state, routeMatch.params.callId));
  const dispatch = useDispatch();

  if (!call) {
    return null;
  }

  return (
    <>
      <MenuSection>
        <FormattedMessage {...definedMessages.REMOVE_ASSOCIATION} />
      </MenuSection>
      <MenuItem
        leadingIcon={
          <SmallIcon>
            <PersonTransferToInactiveIcon />
          </SmallIcon>
        }
        onClick={() => {
          dispatch(setPhoneSelectedEntity({ entityId: undefined }));
          newTracker.trackAnalyticsEvent({
            category: AnalyticsCategory.Contact,
            action: AnalyticsAction.ItemClicked,
            label: `Remove assignment to this contact for this call | menuitem | ${stringifyCallType(
              call.type,
            )} | ${matchType}`,
          });
        }}
      >
        <FormattedMessage {...definedMessages.THIS_CALL} />
      </MenuItem>
      {getActionFacade().isCapable(CAPABILITY_AUTO_CALL_ASSIGNMENT) && call.isAutoSingleMatch ? (
        <MenuItem
          leadingIcon={
            <SmallIcon>
              <PersonTransferToInactiveIcon />
            </SmallIcon>
          }
          onClick={async () => {
            await callAssignmentService.upsertAssignment({
              phoneNumber: call.theOtherParty.number,
              contactId: entity.id,
              isActive: false,
            });
            dispatch(setPhoneSelectedEntity({ entityId: undefined }));
            newTracker.trackAnalyticsEvent({
              category: AnalyticsCategory.Contact,
              action: AnalyticsAction.ItemClicked,
              label: `Remove assignment to this contact for every call | menuitem | ${stringifyCallType(
                call.type,
              )} | ${matchType}`,
            });
          }}
        >
          <FormattedMessage {...definedMessages.EVERY_CALL} />
        </MenuItem>
      ) : null}
      <MenuSeparator />
      <MenuItem
        leadingIcon={
          <SmallIcon>
            <ExternalLinkIcon />
          </SmallIcon>
        }
        onClick={() => {
          dispatch(openEntity({ entity }));
          newTracker.trackAnalyticsEvent({
            label: `Open contact in CRM | button | section contact-details | ${call.type}`,
            action: AnalyticsAction.ItemClicked,
            category: AnalyticsCategory.Contact,
          });
        }}
        variant="secondary"
      >
        <FormattedMessage {...definedMessages.OPEN_CONTACT_IN_CRM} />
      </MenuItem>
    </>
  );
};
