import { Entity } from '../../models';
import { EntityPhoneNumber } from '../../search/entities.model';
import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';
import { connectWisePhoneNumberTypeMapper } from '../connectwise.phoneNumberTypeMapper';
import { connectWiseApiUrlSelector } from '../connectwise.selector';
import { ConnectWiseService } from '../connectwise.service';
import { formatPhoneNumberToDigitsOnlyFormats } from '../../phone/phone-utils';
import { ConnectWisePhoneNumberType, ConnectWisePhoneNumber, ConnectWisePhoneNumberTypes } from '../connectwise.model';
import { closeDialog, openDialog } from '../../dialog/dialog.actions';
import { DialogBodyNames } from '../../dialog/DialogBodies';
import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
  DIALOG_CONTACT_UPDATE_TITLE: {
    id: 'Salesforce.Dialog.ContactUpdate.Title',
    defaultMessage: 'Contact Update',
  },
  DIALOG_CONTACT_UPDATE_CONFIRM: {
    id: 'Salesforce.Dialog.ContactUpdate.Confirm',
    defaultMessage: 'Send',
  },
  DIALOG_CONTACT_UPDATE_CANCEL: {
    id: 'Salesforce.Dialog.ContactUpdate.Cancel',
    defaultMessage: 'Cancel',
  },
});

export class ConnectWiseContactActionCreator extends ContactActionCreator {
  protected formatPhoneNumberToPossibleMatches(phoneNumberToFormat: string) {
    return formatPhoneNumberToDigitsOnlyFormats(phoneNumberToFormat);
  }

  protected async _createContact(contactData: ContactCreateModel): Promise<string> {
    const communicationItem: ConnectWisePhoneNumber = {
      id: 0,
      value: contactData.phoneNumber,
      type: {
        id: ConnectWisePhoneNumberTypes[ConnectWisePhoneNumberType.Direct].id,
        name: ConnectWisePhoneNumberType.Direct,
      },
      defaultFlag: true,
    };

    const contactId: string = await ConnectWiseService.createContact({
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      communicationItems: [communicationItem],
    });
    return contactId;
  }

  protected _openCrmContactPage(contactId: string): Promise<void> {
    const connectWiseUrl = connectWiseApiUrlSelector(this.store.getState());
    const urlToOpen = `${connectWiseUrl}?routeTo=ContactFV&recid=${contactId}`;
    window.open(urlToOpen, '_blank', 'openInDefaultBrowser');
    return Promise.resolve();
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this._openCrmContactPage(contactId)
      .then(() => true)
      .catch(() => false);
  }

  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const connectWisePhoneNumbers = await ConnectWiseService.getPhonesOfContact(contactId);
    return connectWisePhoneNumbers.map((cwPhone) => ({
      entityId: contactId,
      type: connectWisePhoneNumberTypeMapper(cwPhone.type.name),
      phoneNumber: cwPhone.value,
    }));
  }
  protected _searchContactByName(name: string): Promise<Entity[]> {
    return ConnectWiseService.searchContactsByName(name);
  }

  protected _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]> {
    return ConnectWiseService.searchContactsByNumber(phoneNumber);
  }

  protected async _canUpdateContactPhoneNumber(contactId: string): Promise<boolean> {
    const phoneNumbers = await ConnectWiseService.getPhonesOfContact(contactId);
    const hasDirectPhone = phoneNumbers.some((phone) => phone.type.name === ConnectWisePhoneNumberType.Direct);

    // We use the Direct phone number in ConnectWise to save the number.
    // If the contact already has a Direct phone number we need to ask them to confirm that it will be overwritten.
    if (!hasDirectPhone) {
      return true;
    }

    return new Promise((resolve) => {
      this.store.dispatch(
        openDialog({
          confirmAction: closeDialog({}),
          cancellable: true,
          texts: {
            title: definedMessages.DIALOG_CONTACT_UPDATE_TITLE,
            confirm: definedMessages.DIALOG_CONTACT_UPDATE_CONFIRM,
            cancel: definedMessages.DIALOG_CONTACT_UPDATE_CANCEL,
          },
          bodyName: DialogBodyNames.PhoneNumberUpdateConfirmation,
          closeCallback: ({ isConfirmed }) => resolve(isConfirmed),
        }),
      );
    });
  }

  protected _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    return ConnectWiseService.updateContactPhoneNumber(contactId, phoneNumber);
  }
}
