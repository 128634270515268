import React from 'react';
import { EntityType } from '../../constants';
import { CallActionButton, CallActionProps } from './CallActionButton.component';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { defineMessages } from 'react-intl';
import { AddPersonActiveIcon } from '@getgo/chameleon-icons/react';

const definedMessage = defineMessages({
  NEW_CONTACT: {
    id: 'Call.NewContact',
    defaultMessage: 'New contact',
  },
});

export const NewContactButton: React.FC<Partial<CallActionProps>> = (props) => {
  return (
    <CallActionButton
      leadingIcon={<AddPersonActiveIcon aria-hidden />}
      label={definedMessage.NEW_CONTACT}
      entityType={EntityType.CONTACT}
      variant="custom"
      trackingEvent={{
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ItemClicked,
        label: 'Create contact | button',
      }}
      {...props}
    />
  );
};
