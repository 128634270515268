import { logger } from '../logging';
import { syncService } from '../sync/sync.service';

const RING_SYNC_PREFIX = 'call_ringing';

const sounds = {
  ring: `${process.env.PUBLIC_URL}/assets/sounds/officePhone1`,
  busy: `${process.env.PUBLIC_URL}/assets/sounds/call_waiting`,
  dialing: `${process.env.PUBLIC_URL}/assets/sounds/dialing`,
  outgoing: `${process.env.PUBLIC_URL}/assets/sounds/outgoing`,
  hangup: `${process.env.PUBLIC_URL}/assets/sounds/hangup`,
};

export class RingService {
  private ringElement: HTMLAudioElement | undefined;
  private audioContext: AudioContext | undefined;

  public async playRingingSound(callId: string): Promise<void> {
    return this.playSyncedSound(callId, sounds.ring);
  }

  public async playBusyRingingSound(callId: string): Promise<void> {
    return this.playSyncedSound(callId, sounds.busy);
  }

  public async playDialSound(): Promise<void> {
    await this.playSound(sounds.dialing, { loop: false });
  }

  public async playOutgoingSound(): Promise<void> {
    return this.playSound(sounds.outgoing);
  }

  public async playHangupSound(callee: string): Promise<void> {
    return this.playSyncedSound(callee, sounds.hangup, { loop: false });
  }

  public stopRinging(): void {
    return this.ringElement?.pause();
  }

  private async playSound(sound: string, config?: { loop: boolean }): Promise<void> {
    try {
      if (!this.ringElement) {
        this.audioContext = new AudioContext();
        this.ringElement = new Audio();
        const source = this.audioContext.createMediaElementSource(this.ringElement);
        source.connect(this.audioContext.destination);
      }

      if (this.audioContext?.state !== 'running') {
        this.audioContext?.resume();
      }
      this.ringElement.src = sound + this.getSupportedAudioExtension();
      this.ringElement.loop = config?.loop ?? true;
      this.ringElement.autoplay = true;
      this.ringElement.currentTime = 0;
      await this.ringElement.play();
    } catch (error) {
      logger.warn('Failed to play sound', error);
    }
  }

  private async playSyncedSound(tabSyncId: string, sound: string, config?: { loop: boolean }): Promise<void> {
    if (!tabSyncId) {
      return;
    }

    try {
      const canRing = await syncService.canCurrentTabOperate(RING_SYNC_PREFIX, tabSyncId);
      if (!canRing) {
        console.warn('!canRing');
        return;
      }
    } catch (e) {
      logger.error('Failed to synchronize ringtone play.');
    }

    return this.playSound(sound, config);
  }

  private getSupportedAudioExtension(): string {
    if (this.ringElement?.canPlayType('audio/ogg; codecs="vorbis"')) {
      return '.ogg';
    }
    return '.mp3';
  }
}
