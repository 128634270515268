import { CoxServiceWritersTableError } from '../cox.service.models';
import {
  CoxAppointmentLookupError,
  CoxCustomerErrors,
  CoxOpenRepairOrderLookupError,
  CoxDealSalesPersonsTableError,
  CoxVehicleLookupError,
  GetClosedRepairOrdersResultError,
  CoxPartsInventoryRequestError,
  GetClosedRepairOrderDetailsResultError,
} from '../cox.service.models';

export class CoxError extends Error {
  constructor(
    message: string,
    public coxErrors:
      | CoxCustomerErrors
      | CoxAppointmentLookupError
      | CoxVehicleLookupError[]
      | CoxOpenRepairOrderLookupError
      | CoxOpenRepairOrderLookupError[]
      | CoxDealSalesPersonsTableError
      | GetClosedRepairOrdersResultError
      | GetClosedRepairOrderDetailsResultError
      | CoxPartsInventoryRequestError
      | CoxPartsInventoryRequestError[]
      | CoxServiceWritersTableError,
  ) {
    super(message);
  }
}
