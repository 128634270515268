import React, { useEffect, useState } from 'react';
import { Call, CallType } from '@jive/realtime-events';
import { SalesforceCallLogWithNames } from '../../../../salesforce-shared/salesforce-service.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { SalesforceLightningActionFacade } from '../salesforceLightning.actionfacade';
import { CallDispositionPicker } from '../settings/CallDispositionPicker.container';
import { defineMessages, FormattedMessage } from 'react-intl';
import JiveForm from '../../entityForms/JiveForm.component';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextArea } from '../../entityForms/TextArea.component';
import { tokens } from '@getgo/chameleon-core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CallDetails } from '../../calls/CallDetails.component';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { CallActionFormProps } from '../../calls/CallPageForm.model';
import { Typography } from '../../theme/Typography.component';
import { Box } from '@material-ui/core';
import { WhoPicker } from './WhoPicker.component';
import { WhatPicker } from './WhatPicker.component';
import { Entity } from '../../models';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSalesforceObjectNameByObjectId,
  whatObjectNamesSelector,
  whoObjectNamesSelector,
} from '../salesforce.selectors';
import { closeDialog, openDialog } from '../../dialog/dialog.actions';

const definedMessages = defineMessages({
  CALL_LOG_FORM_TITLE: {
    id: 'Salesforce.CallLogForm.Title',
    defaultMessage: 'Log your activity',
  },
  CALL_LOG_EDIT_IN_SALESFORCE: {
    id: 'Salesforce.CallLogForm.EditInSalesforce.Link',
    defaultMessage: 'Edit in Salesforce',
  },
  CALL_LOG_FORM_REQUIREMENT_MESSAGE: {
    id: 'Salesforce.CallLogForm.Requirement.Message',
    defaultMessage: "To log this activity, select a name and/or what it's related to.",
  },
  CALL_LOG_FORM_REQUIREMENT_WITH_DISPOSITIONS_MESSAGE: {
    id: 'Salesforce.CallLogForm.RequirementWithDisposition.Message',
    defaultMessage:
      "To log this activity, select a name and/or what it's related to. Choose a disposition type to keep track of your calls.",
  },
  CALL_LOG_FORM_LEAD_SELECTED_WARNING: {
    id: 'Salesforce.CallLogForm.LeadSelectedWarning.Message',
    defaultMessage: 'A lead can’t be related to anything else',
  },
  CALL_LOG_FORM_COMMENT_LABEL: {
    id: 'Salesforce.CallLogForm.Comment.Label',
    defaultMessage: 'Comments',
  },
  CALL_LOG_FORM_COMMENT_PLACEHOLDER: {
    id: 'Salesforce.CallLogForm.Comment.Placeholder',
    defaultMessage: 'Add comments on the call',
  },
  CALL_LOG_FORM_LOADING_MESSAGE: {
    id: 'Salesforce.CallLogForm.Loading.Message',
    defaultMessage: 'Searching in Salesforce for existing call log',
  },
  CALL_DISPOSITION_SWITCH_ERROR: {
    id: 'Salesforce.Settings.CallDispositionSwitch.Error',
    defaultMessage: "Call dispositions aren't configured correctly. Contact your admin.",
  },
  LEAD_WITH_RELATED_TO_DIALOG_TITLE: {
    id: 'Salesforce.CallLogForm.LeadWithRelatedTo.Dialog.Title',
    defaultMessage: 'Draft this as a lead?',
  },
  LEAD_WITH_RELATED_TO_DIALOG_BODY: {
    id: 'Salesforce.CallLogForm.LeadWithRelatedTo.Dialog.Body',
    defaultMessage:
      'A lead can’t be related to anything else.{lineBreak}{lineBreak} If you continue working on this as a lead, the Related to option will be deactivated.',
  },
  LEAD_WITH_RELATED_TO_DIALOG_CONFIRM: {
    id: 'Salesforce.CallLogForm.LeadWithRelatedTo.Dialog.Confirm',
    defaultMessage: 'Deactivate related to',
  },
});

const trackingEvents = defineTrackingEvents({
  PAGE_LOADED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.PageLoaded,
    label: 'Call disposition',
  },
  INCOMING_CALL_COMMENT_CHANGED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Comment | textarea | incoming call',
  },
  OUTGOING_CALL_COMMENT_CHANGED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Comment | textarea | outgoing call',
  },
  SAVE_BUTTON_CLICKED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Save | button',
  },
  CANCEL_BUTTON_CLICKED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Cancel | button',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: '8px',
    },
    cardContent: {
      background: tokens.uiBase01,
      padding: theme.spacing(3, 3, 5, 3),
    },
    loadingIndicator: {
      margin: '5px 0',
    },
    // 5 lines by default
    comments: {
      '&::part(control)': {
        minHeight: 140,
      },
    },
  }),
);

export interface CallLogFormStateProps {
  call: Call;
  entity?: Entity;
  savedCallLog?: { loading: boolean; callLog?: SalesforceCallLogWithNames };
  isCallDispositionPickerVisible: boolean;
  shouldShowCallDispositionError: boolean;
}

export type CallLogFormProps = CallActionFormProps;

export const CallLogFormComponent: React.FC<CallLogFormStateProps & CallLogFormProps> = ({
  savedCallLog,
  callId,
  call,
  entity,
  onClose,
  isCallDispositionPickerVisible,
  shouldShowCallDispositionError,
}) => {
  const callLog = savedCallLog?.callLog;
  const loading = !savedCallLog || savedCallLog.loading;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [callDispositionValue, setCallDispositionValue] = useState<Optional<string>>(undefined);
  const [who, setWho] = useState<Optional<{ id: string; name: string }>>(
    entity && entity?.type !== 'Account' ? { id: entity.id, name: entity.name } : undefined,
  );
  const [what, setWhat] = useState<Optional<{ id: string; name: string }>>(
    entity && entity?.type === 'Account' ? { id: entity.id, name: entity.name } : undefined,
  );
  const [comments, setComments] = useState<string>('');

  const whoObjectNameByWhoId = useSelector(selectSalesforceObjectNameByObjectId(callLog?.WhoId));
  const whatObjectNameByWhatId = useSelector(selectSalesforceObjectNameByObjectId(callLog?.WhatId));
  const whoObjectNames = useSelector(whoObjectNamesSelector);
  const whatObjectNames = useSelector(whatObjectNamesSelector);

  const initialWhoType = whoObjectNameByWhoId ?? (entity && entity?.type !== 'Account' ? entity.type : undefined);
  const initialWhatType = whatObjectNameByWhatId ?? (entity && entity?.type === 'Account' ? entity.type : undefined);
  const [whoType, setWhoType] = useState(
    initialWhoType ?? (whoObjectNames.includes('Contact') ? 'Contact' : whoObjectNames[0] ?? ''),
  );
  const [whatType, setWhatType] = useState(
    initialWhatType ?? (whatObjectNames.includes('Opportunity') ? 'Opportunity' : whatObjectNames[0] ?? ''),
  );

  const formMessage = isCallDispositionPickerVisible
    ? definedMessages.CALL_LOG_FORM_REQUIREMENT_WITH_DISPOSITIONS_MESSAGE
    : definedMessages.CALL_LOG_FORM_REQUIREMENT_MESSAGE;

  // load callLog when the form is opened
  useEffect(() => {
    void getActionFacade<SalesforceLightningActionFacade>().loadSavedCallLog(callId);
    newTracker.trackAnalyticsEvent(trackingEvents.PAGE_LOADED);
  }, [callId]);

  // set values when callog is loaded
  useEffect(() => {
    if (savedCallLog?.callLog) {
      const callLog = savedCallLog.callLog;

      setCallDispositionValue(callLog.CallDispositionValue);
      setComments(callLog.Description || '');

      if (whoObjectNameByWhoId) {
        setWhoType(whoObjectNameByWhoId);
      }
      if (callLog.WhoId && callLog.WhoName) {
        setWho({ id: callLog.WhoId, name: callLog.WhoName });
      }

      if (whatObjectNameByWhatId) {
        setWhatType(whatObjectNameByWhatId);
      }
      if (callLog.WhatId && callLog.WhatName) {
        setWhat({ id: callLog.WhatId, name: callLog.WhatName });
      }
    }
  }, [savedCallLog, whoObjectNameByWhoId, whatObjectNameByWhatId]);

  const isOutgoingCall = call.isClickToCall || call.type === CallType.OutgoingCall;

  const LoadingMessage = () => {
    return (
      <>
        <LinearProgress className={classes.loadingIndicator} />
        <Typography color="text-info" style={{ textAlign: 'center' }}>
          <FormattedMessage {...definedMessages.CALL_LOG_FORM_LOADING_MESSAGE} />
        </Typography>
      </>
    );
  };

  const onSave = () => {
    if (callLog && callLog.Id) {
      void getActionFacade<SalesforceLightningActionFacade>().updateCallLog({
        Id: callLog.Id,
        CallId: callLog.CallId,
        CallDispositionValue: callDispositionValue ?? '',
        Description: comments,
        WhoId: who?.id,
        WhatId: what?.id,
      });
    } else {
      void getActionFacade<SalesforceLightningActionFacade>().createCallLog({
        CallId: callId,
        CallDispositionValue: callDispositionValue ?? '',
        Description: comments,
        WhoId: who?.id,
        WhatId: what?.id,
      });
    }

    onClose();
  };

  return loading ? (
    <LoadingMessage />
  ) : (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => onSave()}
      canSubmit={!loading && (!!who || !!what)}
      variant={'no-card'}
      saveTrackingEvent={trackingEvents.SAVE_BUTTON_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_BUTTON_CLICKED}
    >
      <>
        <Box mb={2}>
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.CALL_LOG_FORM_TITLE} />
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography>
            <FormattedMessage {...formMessage} />
          </Typography>
        </Box>
        <Card className={classes.card}>
          <CardContent classes={{ root: classes.cardContent }}>
            <Box mb={4}>
              <CallDetails call={call} variant="short" size="normal" />
            </Box>
            <Box mt={2}>
              <WhoPicker
                selectedWhoType={whoType}
                onTypeChange={(selectedType) => {
                  if (selectedType === 'Lead' && !!what) {
                    return dispatch(
                      openDialog({
                        confirmAction: closeDialog({}),
                        cancellable: true,
                        texts: {
                          title: definedMessages.LEAD_WITH_RELATED_TO_DIALOG_TITLE,
                          body: definedMessages.LEAD_WITH_RELATED_TO_DIALOG_BODY,
                          confirm: definedMessages.LEAD_WITH_RELATED_TO_DIALOG_CONFIRM,
                        },
                        dialogStyles: {
                          confirmButtonVariant: 'danger',
                        },
                        closeCallback: ({ isConfirmed }) => {
                          if (!isConfirmed) {
                            return;
                          }
                          setWhoType(selectedType);
                          setWho(undefined);
                          setWhat(undefined);
                        },
                      }),
                    );
                  }
                  setWhoType(selectedType);
                  setWho(undefined);
                }}
                selectedWho={who}
                onChange={(selectedWho) => setWho(selectedWho)}
              />
            </Box>

            {whoType === 'Lead' ? (
              <Box mt={2}>
                <Typography variant="caption-default-01">
                  <FormattedMessage {...definedMessages.CALL_LOG_FORM_LEAD_SELECTED_WARNING} />
                </Typography>
              </Box>
            ) : null}

            <Box mt={7}>
              <WhatPicker
                disabled={whoType === 'Lead'}
                selectedWhatType={whatType}
                onTypeChange={(selectedType) => setWhatType(selectedType)}
                onChange={(selectedWhat) => setWhat(selectedWhat)}
                selectedWhat={what}
              />
            </Box>

            {isCallDispositionPickerVisible && (
              <Box mt={7}>
                <CallDispositionPicker
                  onChange={(value) => setCallDispositionValue(value)}
                  selectedCallDisposition={callDispositionValue}
                />
              </Box>
            )}
            {shouldShowCallDispositionError && (
              <Box mt={4}>
                <Typography variant="caption-default-01" color="text-error-01">
                  <FormattedMessage {...definedMessages.CALL_DISPOSITION_SWITCH_ERROR} />
                </Typography>
              </Box>
            )}
            <Box mt={7}>
              <TextArea
                name="call-log-description"
                id="textfield-call-log-description"
                required={false}
                className={classes.comments}
                value={comments}
                fullwidth
                labelDescription={definedMessages.CALL_LOG_FORM_COMMENT_LABEL}
                placeholderDescriptor={definedMessages.CALL_LOG_FORM_COMMENT_PLACEHOLDER}
                onChange={({ target: { value } }) => setComments(value ?? '')}
                trackingEvent={
                  isOutgoingCall
                    ? trackingEvents.OUTGOING_CALL_COMMENT_CHANGED
                    : trackingEvents.INCOMING_CALL_COMMENT_CHANGED
                }
              />
            </Box>
          </CardContent>
        </Card>
      </>
    </JiveForm>
  );
};
