import { GetNotesSuccessPayload, GetMoreNotesRequestMeta } from '../../notes/notes.actions';
import { AddNoteModel } from '../../actionFacade/actionCreators/note/note.actioncreator.models';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { ConnectWiseService } from '../connectwise.service';
import { Note } from '../../notes/notes.models';
import { getConnectWiseNotesSuccess } from './connectwise.note.action';
import { connectWiseNoteSelector } from './connectwise.note.selector';
import { Call } from '@jive/realtime-events';
import { defineMessages } from 'react-intl';
import { injectedIntlSelector, selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import { getCallDetails } from '../../calls/callLogger.utilities';
import { AsyncAddNoteActionCreator } from '../../actionFacade/actionCreators/note/async.note.actioncreator';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { callHistoryElementByCallSelector } from '../../callHistoryPush/unifiedCallHistory.selector';

export const definedMessages = defineMessages({
  CONNECTWISE_NOTE_TYPES_GET_ERROR: {
    id: 'ConnectWise.Note.Types.Get.Error',
    defaultMessage: 'There was an error while trying to download ConnectWise note types, please try again.',
  },
  CALL_DETAILS_HEADER: {
    id: 'CallDetails.Header',
    defaultMessage: 'Call Details',
  },
});

interface ConnectWiseAddNoteModel extends AddNoteModel {
  call: Call;
  noteTypeId: number;
  includeCallDetails: boolean;
}

export class ConnectWiseNoteActionCreator extends AsyncAddNoteActionCreator {
  constructor(protected store: Store<AppState>) {
    super(store);
  }

  protected async _loadMostRecentNoteOfContact(contactId: string): Promise<GetNotesSuccessPayload> {
    const connectWiseNotes = await ConnectWiseService.getNotesOfContact(contactId);

    const notes = connectWiseNotes.map((cwn) => {
      return {
        id: String(cwn.id),
        subject: cwn.type.name,
        content: cwn.text ? cwn.text.replace(RegExp('\\n', 'gm'), '{lineBreak}') : '',
        createdAtIso8601: cwn._info.lastUpdated,
      };
    });

    if (notes.length > 1) {
      this.store.dispatch(getConnectWiseNotesSuccess({ notes }));
    }

    return this.createGetNotesSuccessPayload(contactId, 0, notes);
  }

  protected async _loadMoreNotesOfContact(moreNotesMeta: GetMoreNotesRequestMeta): Promise<GetNotesSuccessPayload> {
    const notes = connectWiseNoteSelector(this.store.getState());
    const nextPage = moreNotesMeta.next ? Number(moreNotesMeta.next) : 0;
    return this.createGetNotesSuccessPayload(moreNotesMeta.contactId ?? '', nextPage, notes);
  }

  protected async _addNote(model: AddNoteModel): Promise<Optional<{ noteId: string }>> {
    const connectWiseAddNoteModel = model as ConnectWiseAddNoteModel;
    const { contactId, noteTypeId, content, includeCallDetails, call } = connectWiseAddNoteModel;
    const callInHistory = callHistoryElementByCallSelector(call)(this.store.getState());

    if (includeCallDetails && !callInHistory) {
      return this._addNoteWhenTheCallEnds(call, connectWiseAddNoteModel);
    }

    let note: string;
    if (includeCallDetails && callInHistory) {
      const intl = injectedIntlSelector(this.store.getState());
      const locale = selectedDateFnsLocaleSelector(this.store.getState());
      const callDetailsString = getCallDetails({ call: callInHistory, intl, locale, lineBreak: '\n' });
      const formattedCallDetails = `\n${intl.formatMessage(definedMessages.CALL_DETAILS_HEADER)}\n${callDetailsString}`;

      note = `${content}${formattedCallDetails}`;
    } else {
      note = content;
    }

    const noteId = await ConnectWiseService.addNote(contactId, {
      contactId: Number(contactId),
      text: note,
      type: {
        name: '',
        id: noteTypeId,
      },
      flagged: false,
    });

    if (includeCallDetails) {
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.CallLogCreationSucceeded,
        label: '-',
      });
    }

    return {
      noteId,
    };
  }

  private createGetNotesSuccessPayload(contactId: string, page: number, notes: Note[]): GetNotesSuccessPayload {
    if (notes.length === 0) {
      return {
        meta: {
          notesCount: 0,
          contactId,
          next: undefined,
        },
        notes: [],
      };
    }

    if (page === 0) {
      return {
        meta: {
          notesCount: notes.length - 1,
          contactId,
          next: notes.length === 1 ? undefined : '1', // next 'page'
        },
        notes: [notes[0]],
      };
    }

    const startIndex = page * 2 - 1;
    const endIndex = startIndex + 2;
    const noteSlice = notes.slice(startIndex, endIndex);

    return {
      meta: {
        notesCount: 2,
        contactId,
        next: endIndex >= notes.length ? undefined : String(page + 1),
      },
      notes: noteSlice,
    };
  }
}
