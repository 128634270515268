import FormControlLabel, { FormControlLabelClassKey } from '@material-ui/core/FormControlLabel';
import React from 'react';
import { TrackedSwitch, TrackedSwitchProps } from '../analytics-new/TrackedSwitch.component';

export interface LabeledSwitchProps extends TrackedSwitchProps {
  labelPlacement: 'end' | 'start' | 'top' | 'bottom';
  label: React.ReactNode;
  controlLabelClasses?: Partial<Record<FormControlLabelClassKey, string>>;
  id: string;
}

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  labelPlacement,
  label,
  controlLabelClasses,
  ...switchProps
}) => (
  <FormControlLabel
    labelPlacement={labelPlacement}
    control={<TrackedSwitch {...switchProps} />}
    label={label}
    id={`${switchProps.id}-label`}
    classes={controlLabelClasses}
  />
);
