import { createSelector } from 'reselect';
import { AppState } from '../../reducers/index';
import { LoadVehiclesRequestState } from './vehicles.models';
import { VehiclesState } from './vehicles.reducer';

const coxVehicleSelector = (state: AppState) => state.cox.vehicles;

export const loadVehiclesRequestStateSelector = createSelector(
  coxVehicleSelector,
  (vehicles: VehiclesState) => vehicles.loadRequestState,
);
export const vehiclesSelector = createSelector(coxVehicleSelector, (vehicles: VehiclesState) => vehicles.vehicles);
export const allVehiclesCountSelector = createSelector(coxVehicleSelector, (vehicles: VehiclesState) =>
  vehicles.loadRequestState === LoadVehiclesRequestState.LOADED ? vehicles.vehicles.length : undefined,
);
