import { stockPileService, StockpileTtl } from '../stockPile/stockPile.service';

export interface CallAssignment {
  phoneNumber: string;
  contactId: string;
  isActive: boolean;
}

export class CallAssignmentService {
  private readonly buckedId = 'callAssignments';

  async upsertAssignment(callAssignment: CallAssignment): Promise<void> {
    const assignments = await this.loadAssignments();
    const updatedAssignments = (assignments ?? []).filter((a) => a.phoneNumber !== callAssignment.phoneNumber);
    return stockPileService.updateBucket(this.buckedId, [callAssignment, ...updatedAssignments], StockpileTtl.Forever);
  }

  loadAssignments(): Promise<CallAssignment[] | undefined> {
    return stockPileService.getBucket<CallAssignment[]>(this.buckedId);
  }
}

export const callAssignmentService = new CallAssignmentService();
