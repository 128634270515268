import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import {
  allCallSelector,
  getEntityInCallSelector,
  isContactSingleMatchSelector,
  callSelector,
} from '../calls/calls.selector';
import { AppState } from '../reducers';
import { createEntity } from '../search/entities.action';
import NewEntityAction, {
  NewEntityActionDispatchProps,
  NewEntityActionProps,
  NewEntityActionStateProps,
} from './NewEntityAction.component';

export default withRouter(
  connect<
    NewEntityActionStateProps,
    NewEntityActionDispatchProps,
    RouteComponentProps<{ callId: string }> & NewEntityActionProps,
    AppState
  >(
    (state: AppState, { match }) => {
      const activeCall = callSelector(state, match.params.callId);

      return {
        isActiveCall: Boolean(activeCall && !activeCall.endTime),
        call: allCallSelector(state, match.params.callId),
        isSingleMatch: isContactSingleMatchSelector(state, match.params.callId),
        entity: getEntityInCallSelector(state, match.params.callId),
      };
    },
    (dispatch: Dispatch<AppAction>) => ({
      newEntityAction: (payload) => dispatch(createEntity(payload)),
    }),
  )(NewEntityAction),
);
