import { Box, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import { AlertInactiveIcon } from '@getgo/chameleon-icons/react';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { SearchResultType } from './SearchResult.model';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  NO_RESULTS_TITLE: {
    id: 'SearchResult.NoResults.Title',
    defaultMessage: 'No Results',
  },
  NO_RESULTS_MESSAGE_CALL: {
    id: 'SearchResult.NoResults.Message.Call',
    defaultMessage: "Sorry, we couldn't find anything matching {query}.",
  },
  NO_RESULTS_MESSAGE_DIALPAD: {
    id: 'SearchResult.NoResults.Message.Dialpad',
    defaultMessage: "Sorry, we couldn't find anything matching {query}. Please check the spelling and try again.",
  },
});

const styles = (theme: Theme) =>
  createStyles({
    noResults: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    warningIcon: {
      margin: theme.spacing(4, 4, 0, 4),
    },
  });

export interface SearchNoResultStateProps {
  query: string;
}

export interface SearchNoResultProps {
  searchType: SearchResultType;
}

const SearchNoResultsComponent: React.FC<SearchNoResultStateProps &
  SearchNoResultProps &
  WithStyles<typeof styles>> = ({ classes, query, searchType }) => {
  const message =
    searchType === SearchResultType.DialPad
      ? definedMessages.NO_RESULTS_MESSAGE_DIALPAD
      : definedMessages.NO_RESULTS_MESSAGE_CALL;

  return (
    <div className={classes.noResults} data-cy="no-results-message">
      <AlertInactiveIcon className={classes.warningIcon} size="40px" color={tokens.interactive01} />
      <Typography variant="heading-xsmall">
        <FormattedMessage {...definedMessages.NO_RESULTS_TITLE} />
      </Typography>
      <Box width="75%" mt={2}>
        <Typography color="text-02">
          <FormattedMessage
            {...message}
            values={{
              query: <b>{query}</b>,
            }}
          />
        </Typography>
      </Box>
    </div>
  );
};

export const SearchNoResults = withStyles(styles, { withTheme: true })(SearchNoResultsComponent);
