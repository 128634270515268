import { Store } from 'redux';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { numberOfActiveCallsSelector } from '../calls/calls.selector';
import { AppState } from '../reducers';
import { NpsProperties } from './qualtricsProperties';
import './qualtrics';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { syncService } from '../sync/sync.service';
import { addCallStartListener } from '../calls/callListeners';
import { logger } from '../logging';
import { selectSoftphoneCallByRealtimeCallSelector } from '../softphone/softphoneCall.selector';

const TRIGGER_INTERVAL = 900_000; // 15 minutes in ms
const SYNC_PREFIX = 'qualtrics_survey_call_count';

const trackingEvents = defineTrackingEvents({
  SURVEY_POPUP_SHOWN: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PopupShown,
    label: 'NPS survey popup shown',
  },
});

function getCookie(name) {
  const value = `; ${window.document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

async function triggerIntercepts() {
  window.QSI.API.unload();
  await window.QSI.API.load();
  window.QSI.API.run();
}

function setCallNumberSinceSurveyCookie(value: number) {
  window.document.cookie = `callNumberSinceSurvey=${value};max-age=31536000;secure;samesite=none`;
}

function setSoftphoneCallNumberSinceSurveyCookie(value: number) {
  window.document.cookie = `softphoneCallNumberSinceSurvey=${value};max-age=31536000;secure;samesite=none`;
}

function hasSurveyDisplayed() {
  return Object.values(window.QSI.reg).length > 0;
}

function shouldRunSurvey(store: Store<AppState>) {
  const state = store.getState();
  const hasActiveCall = numberOfActiveCallsSelector(state) > 0;

  return isAuthenticatedSelector(state) && window.QSI && !hasSurveyDisplayed() && !hasActiveCall;
}

// Couldn't find a way to make sure that only one Creative is opened, so if there are multiple opened we remove the bottom ones
export function ensureSingleCreativeIsOpened(): Promise<void[]> {
  return Promise.all(
    Object.values(window.QSI.reg)
      .filter((_, index, self) => index < self.length - 1)
      .map(async (reg) => {
        await reg.displayed;
        reg.remove();
      }),
  );
}

export function setupQualtricsSurvey(store: Store<AppState>) {
  window.npsProperties = new NpsProperties(store);

  setInterval(async () => {
    if (!shouldRunSurvey(store)) {
      return;
    }

    await triggerIntercepts();

    if (!hasSurveyDisplayed()) {
      return;
    }

    await ensureSingleCreativeIsOpened();

    newTracker.trackAnalyticsEvent(trackingEvents.SURVEY_POPUP_SHOWN);

    setCallNumberSinceSurveyCookie(0);
    setSoftphoneCallNumberSinceSurveyCookie(0);
  }, TRIGGER_INTERVAL);

  addCallStartListener(async (state, action) => {
    const softphoneCall = selectSoftphoneCallByRealtimeCallSelector(action.payload.call)(state);
    try {
      await syncService.executeOnce(SYNC_PREFIX, action.payload.call.id, () => {
        const callNumberSinceSurvey = Number(getCookie('callNumberSinceSurvey') ?? 0);
        setCallNumberSinceSurveyCookie(callNumberSinceSurvey + 1);

        if (softphoneCall) {
          const softphoneCallNumberSinceSurvey = Number(getCookie('softphoneCallNumberSinceSurvey') ?? 0);
          setSoftphoneCallNumberSinceSurveyCookie(softphoneCallNumberSinceSurvey + 1);
        }
      });
    } catch (e) {
      logger.error('Error setting callNumberSinceSurvey for Qualtrics', e);
    }
  });
}
