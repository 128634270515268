const DISMISS_PREFIX = 'DISMISS_';
const DEFAULT_DURATION = 1000 * 60 * 60 * 24; // 1 day

class DismissService {
  private getByKey(key): string | undefined {
    const allcookies = document.cookie;
    const parts = allcookies.split(`;`).reduce((map, part) => {
      map[(part.split('=')[0] || '').trim()] = part.split('=')[1];
      return map;
    }, {});

    return parts[`${DISMISS_PREFIX}${key}`] as string | undefined;
  }

  isDismissed(key: string): boolean {
    const value = this.getByKey(key);

    return !!value;
  }

  /**
   * Dismisses an operation for 1 day
   * @param key Identifier of operation to dismiss
   * @param duration Duration to dismiss operation for. Default duration is 1 day.
   */
  dismiss(key: string, duration?: number): void {
    const expires = new Date(new Date().getTime() + (duration || DEFAULT_DURATION));
    document.cookie = `${DISMISS_PREFIX}${key}=dismiss; expires=${expires.toUTCString()}; path=/;secure;samesite=none`;
  }
}

export const dismissService = new DismissService();
