import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { isCallDispositionConfiguredSelector, callDispositionValuesSelector } from '../salesforce.selectors';
import {
  CallDispositionPickerStateProps,
  CallDispositionPickerComponent,
  CallDispositionPickerProps,
} from './CallDispositionPicker.component';

const mapStateToProps = (state: AppState): CallDispositionPickerStateProps => ({
  isCallDispositionConfigured: isCallDispositionConfiguredSelector(state),
  callDispositionValues: callDispositionValuesSelector(state),
});

export const CallDispositionPicker = connect<CallDispositionPickerStateProps, {}, CallDispositionPickerProps, AppState>(
  mapStateToProps,
)(CallDispositionPickerComponent);
