import React, { FC, useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApplicationRoute } from '../constants';
import { OnboardingLanguageSelector } from './OnboardingLanguageSelector.component';
import { OnboardingWithTestCall } from './OnboardingWithTestCall.component';
import { OnboardingLinking } from './OnboardingLinking.component';
import TermsAndConditions from '../termsOfService/TermsOfService.container';
import { OnboardingWithSignIn } from './OnboardingWithSignIn.component';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { areLinesLoadingSelector, hasLinesLoadingErrorSelector, linesSelector } from '../lines/lines.selectors';
import { selectLine } from '../settings/settings.action';
import { CookiesUnavailablePage } from '../error/CookiesUnavailablePage.component';
import { LocalStorageUnavailablePage } from '../error/LocalStorageUnavailablePage.component';
import { isLocalStorageAvailable } from '../utils';
import { OnboardingEnterpriseLinking } from './OnboardingEnterpriseLinking.component';
import { NoLicenseErrorPage } from '../error/NoLicenseErrorPage.component';
import { Integrations } from '../models';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { LinesLoadingErrorPage } from '../error/LinesLoadingErrorPage.component';
import { useEntitlements } from '../authentication/useEntitlements';
import {
  settingsPersistenceSelector,
  wasSettingsStateMissingSelector,
} from '../settings/settings.persistence.selector';
import { RedirectKeepSearch } from '../components/RedirectKeepSearch.component';
import { FullPageAppLoading } from '../components/FullPageAppLoading';
import { isTermsOfServiceAcceptedSelector } from '../termsOfService/termsOfService.selector';
import { goToRoute } from '../browserHistory';
import { useLocation } from 'react-router-dom';
import { selectedLineSelector } from '../settings/settings.selector';

export const Onboarding: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const hasLinesError = useSelector(hasLinesLoadingErrorSelector);
  const selectedLine = useSelector(selectedLineSelector);
  const areLinesLoading = useSelector(areLinesLoadingSelector);
  const lines = useSelector(linesSelector);
  const currentIntegration = useSelector(currentIntegrationSelector);
  const settingsPersistenceState = useSelector(settingsPersistenceSelector);
  const wasSettingsStateMissing = useSelector(wasSettingsStateMissingSelector);
  const isTermsOfServiceAccepted = useSelector(isTermsOfServiceAcceptedSelector);
  const { hasGTCLicense, hasGTMLicense, hasSeenLicenseWarning } = useEntitlements();
  const canUseLocalStorage = useMemo(isLocalStorageAvailable, []);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      [ApplicationRoute.ONBOARDING, ApplicationRoute.ONBOARDING_TOS].includes(location.pathname as ApplicationRoute)
    ) {
      return;
    }
    if (!isTermsOfServiceAccepted) {
      goToRoute(ApplicationRoute.ONBOARDING_TOS);
    }
  }, [isTermsOfServiceAccepted, location]);

  useEffect(() => {
    if (!isTermsOfServiceAccepted) {
      return;
    }
    if (!isAuthenticated || areLinesLoading || hasLinesError) {
      return;
    }

    if (settingsPersistenceState === 'initial') {
      void getActionFacade().loadSettingsState();
      return;
    }

    if (Object.keys(lines).length === 0) {
      // we only need lines first
      void getActionFacade().loadLines();
    } else if (!!selectedLine) {
      // let's load PBXes and the realtime connection
      void getActionFacade().startOnboardingServices();
    }

    if (settingsPersistenceState !== 'missing') {
      return;
    }
  }, [
    isAuthenticated,
    selectedLine,
    lines,
    areLinesLoading,
    hasLinesError,
    settingsPersistenceState,
    isTermsOfServiceAccepted,
  ]);

  useEffect(() => {
    if (!isTermsOfServiceAccepted) {
      return;
    }
    const lineKeys = Object.keys(lines);
    if (isAuthenticated && !selectedLine && lineKeys.length) {
      dispatch(selectLine(lineKeys[0]));
    }
    if (settingsPersistenceState !== 'missing') {
      return;
    }
  }, [dispatch, isAuthenticated, selectedLine, lines, settingsPersistenceState, isTermsOfServiceAccepted]);

  useEffect(() => {
    if (!isTermsOfServiceAccepted) {
      return;
    }
    if (settingsPersistenceState !== 'missing') {
      return;
    }

    if (hasLinesError || !selectedLine) {
      return;
    }

    void getActionFacade().loadSoftphoneCapability();
  }, [selectedLine, hasLinesError, settingsPersistenceState, isTermsOfServiceAccepted]);

  if (!navigator.cookieEnabled) {
    return <CookiesUnavailablePage />;
  }

  if (!canUseLocalStorage) {
    return <LocalStorageUnavailablePage />;
  }

  if (hasLinesError) {
    return <LinesLoadingErrorPage />;
  }

  if (isAuthenticated && !hasSeenLicenseWarning) {
    if (
      currentIntegration === Integrations.SalesforceClassic ||
      currentIntegration === Integrations.SalesforceLightning
    ) {
      if (!hasGTCLicense || !hasGTMLicense) {
        return <NoLicenseErrorPage />;
      }
    }
  }

  if (['loading', 'initial'].includes(settingsPersistenceState) && isAuthenticated) {
    return <FullPageAppLoading />;
  }

  if (
    !wasSettingsStateMissing &&
    settingsPersistenceState === 'loaded' &&
    isAuthenticated &&
    isTermsOfServiceAccepted
  ) {
    return <RedirectKeepSearch pathname={ApplicationRoute.SETTINGS_ROUTE} />;
  }

  return (
    <Switch>
      <Route exact path={ApplicationRoute.ONBOARDING_LINKING}>
        <OnboardingLinking />
      </Route>
      <Route exact path={ApplicationRoute.ONBOARDING_ENTERPRISE_LINKING}>
        <OnboardingEnterpriseLinking />
      </Route>
      <Route exact path={ApplicationRoute.ONBOARDING_TEST_CALL}>
        <OnboardingWithTestCall />
      </Route>
      <Route exact path={ApplicationRoute.ONBOARDING_TOS}>
        <TermsAndConditions />
      </Route>
      <Route path={ApplicationRoute.ONBOARDING_LOGIN}>
        <OnboardingWithSignIn />
      </Route>
      <Route path={ApplicationRoute.ONBOARDING}>
        <OnboardingLanguageSelector />
      </Route>
    </Switch>
  );
};
