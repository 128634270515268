import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tile: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto',
        boxSizing: 'border-box',
        placeContent: 'center space-between',
        alignItems: 'center',
        borderRadius: '3px',
        border: `solid 1px ${tokens.uiBase01}`,
        backgroundColor: tokens.uiBase01,
        color: tokens.text01,
        userSelect: 'none',
        marginBottom: theme.spacing(2),
      },
      selected: {
        backgroundColor: tokens.hoverPrimary,
        color: tokens.text04,
        '--goto-icon-color': tokens.text04,
      },
    }),
  { name: 'SearchResultTile' },
);

interface SearchResultTileProps {
  isSelected?: boolean;
  onClick?: (...args: any[]) => void;
}

export const SearchResultTile: React.FC<SearchResultTileProps> = ({ onClick, isSelected, children }) => {
  const classes = useStyles();
  const showAsSelected = isSelected && true;

  return (
    <div
      data-cy="search-result"
      onClick={() => onClick && onClick()}
      className={`${classes.tile} ${showAsSelected ? classes.selected : ''}`}
    >
      {children}
    </div>
  );
};
