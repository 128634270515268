import { makeActionCreator } from '../../actions/actionCreators';
import { SalesforceOpportunity } from '../../../../salesforce-shared/salesforce-service.models';
import { WithMessage } from '../../models';

export enum OpportunityActionsType {
  RESET = 'opportunities/reset',
  GET_OPPORTUNITIES = 'opportunities/get',
  GET_OPPORTUNITIES_SUCCESS = 'opportunities/get/success',
  GET_OPPORTUNITIES_ERROR = '/get/error',

  GET_MORE_OPPORTUNITIES = 'opportunities/more/get',
  GET_MORE_OPPORTUNITIES_SUCCESS = 'opportunities/more/success',
  GET_MORE_OPPORTUNITIES_ERROR = 'opportunities/more/error',

  CREATE_OPPORTUNITY = 'opportunities/create',
  CREATE_OPPORTUNITY_SUCCESS = 'opportunities/create/success',
  CREATE_OPPORTUNITY_ERROR = 'opportunities/create/error',

  OPEN_OPPORTUNITY = 'opportunities/open',
  OPEN_OPPORTUNITY_SUCCESS = 'opportunities/open/success',
  OPEN_OPPORTUNITY_ERROR = 'opportunities/open/error',

  GET_OPPORTUNITIES_COUNT = 'opportunities/getCount',
  GET_OPPORTUNITIES_COUNT_SUCCESS = 'opportunities/getCount/success',
  GET_OPPORTUNITIES_COUNT_ERROR = 'opportunities/getCount/error',
}

export const resetOpportunities = makeActionCreator(OpportunityActionsType.RESET)();

export const getOpportunitiesRequest = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES)();

export interface GetOpportunitiesSuccessPayload {
  meta: GetMoreOpportunitiesRequestMeta;
  opportunities: SalesforceOpportunity[];
}

export const getOpportunitiesSuccess = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES_SUCCESS)<
  GetOpportunitiesSuccessPayload
>();

export interface GetOpportunitiesErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export const getOpportunitiesError = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES_ERROR)<
  GetOpportunitiesErrorPayload
>();

export interface GetMoreOpportunitiesRequestMeta {
  next?: string;
  opportunitiesCount: number; // current number of downloaded opportunities
  contactId: string;
}

export const getMoreOpportunitiesRequest = makeActionCreator(OpportunityActionsType.GET_MORE_OPPORTUNITIES)();
export const getMoreOpportunitiesSuccess = makeActionCreator(OpportunityActionsType.GET_MORE_OPPORTUNITIES_SUCCESS)<
  GetOpportunitiesSuccessPayload
>();
export const getMoreOpportunitiesError = makeActionCreator(OpportunityActionsType.GET_MORE_OPPORTUNITIES_ERROR)<
  GetOpportunitiesErrorPayload
>();

export const createOpportunityRequest = makeActionCreator(OpportunityActionsType.CREATE_OPPORTUNITY)();
export const createOpportunitySuccess = makeActionCreator(OpportunityActionsType.CREATE_OPPORTUNITY_SUCCESS)();
export const createOpportunityError = makeActionCreator(OpportunityActionsType.CREATE_OPPORTUNITY_ERROR)<WithMessage>();

export const openOpportunityRequest = makeActionCreator(OpportunityActionsType.OPEN_OPPORTUNITY)();
export const openOpportunitySuccess = makeActionCreator(OpportunityActionsType.OPEN_OPPORTUNITY_SUCCESS)();
export const openOpportunityError = makeActionCreator(OpportunityActionsType.OPEN_OPPORTUNITY_ERROR)<WithMessage>();

export interface GetOpportunitiesCountPayload {
  opportunitiesCount: number; // number of all Opportunities in CRM
}

export const getOpportunitiesCountRequest = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES_COUNT)();
export const getOpportunitiesCountSuccess = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES_COUNT_SUCCESS)<
  GetOpportunitiesCountPayload
>();
export const getOpportunitiesCountError = makeActionCreator(OpportunityActionsType.GET_OPPORTUNITIES_COUNT_ERROR)<
  WithMessage
>();

export type OpportunityActions =
  | ReturnType<typeof resetOpportunities>
  | ReturnType<typeof getOpportunitiesRequest>
  | ReturnType<typeof getOpportunitiesSuccess>
  | ReturnType<typeof getOpportunitiesError>
  | ReturnType<typeof getMoreOpportunitiesRequest>
  | ReturnType<typeof getMoreOpportunitiesSuccess>
  | ReturnType<typeof getMoreOpportunitiesError>
  | ReturnType<typeof createOpportunityRequest>
  | ReturnType<typeof createOpportunitySuccess>
  | ReturnType<typeof createOpportunityError>
  | ReturnType<typeof openOpportunityRequest>
  | ReturnType<typeof openOpportunitySuccess>
  | ReturnType<typeof openOpportunityError>
  | ReturnType<typeof getOpportunitiesCountRequest>
  | ReturnType<typeof getOpportunitiesCountSuccess>
  | ReturnType<typeof getOpportunitiesCountError>;
