import React from 'react';
import { getNameOfTheOtherParty, getNumberOfTheOtherParty } from './call.helper';
import { CallWithContact } from './calls.reducer';
import { Typography, TypographyProps } from '../theme/Typography.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AppState } from '../reducers/index';
import { useSelector } from 'react-redux';
import { getEntityInCallSelector, hasSingleContactSelector } from './calls.selector';
import { Tooltip, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExtendedTheme } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';
import { PhoneUpActiveIcon, PhoneUpDisabledActiveIcon } from '@getgo/chameleon-icons/react';
import { PhoneNumberSyncIcon } from './PhoneNumberSyncIcon.component';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  CALLER_ID_LABEL: {
    id: 'Call.Contact.CallerId',
    defaultMessage: 'Caller ID:',
  },
  DO_NOT_CALL_TOOLTIP: {
    id: 'Call.DoNotCall.Tooltip',
    defaultMessage: 'Do not call!',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  phoneNumber: {
    fontWeight: 'bold',
    color: tokens.text01,
    marginRight: theme.spacing(1),
  },
  link: {
    marginRight: theme.spacing(1),
    '&::part(control)': {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    '&::part(start)': {
      display: 'flex',
    },
  },
  phoneNumberSyncIcon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    display: 'inline-grid',
    alignContent: 'center',
  },
}));

interface CallerIdComponentProps {
  call: CallWithContact;
  typographyProps?: TypographyProps;
  onPhoneNumberClick?: () => void;
}

export const CallerIdComponent: React.FC<CallerIdComponentProps> = ({ call, typographyProps, onPhoneNumberClick }) => {
  const classes = useStyles();
  const displayName = getNameOfTheOtherParty(call);
  const displayNumber = getNumberOfTheOtherParty(call);

  const hasSingleContact = useSelector((state: AppState) => hasSingleContactSelector(state, call));
  const entity = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));

  const renderPhoneIcon = () => {
    if (entity?.doNotCall) {
      return (
        <Tooltip arrow title={<FormattedMessage {...definedMessages.DO_NOT_CALL_TOOLTIP} />} enterDelay={500}>
          <PhoneUpDisabledActiveIcon color={tokens.danger01} size="14px" aria-hidden />
        </Tooltip>
      );
    }

    return <PhoneUpActiveIcon size="14px" />;
  };

  const isPhoneNumberClickable = typeof onPhoneNumberClick === 'function';

  const renderPhoneNumberSyncButton = () => {
    if (!hasSingleContact || !entity) {
      return null;
    }

    return <PhoneNumberSyncIcon call={call} entity={entity} className={classes.phoneNumberSyncIcon} />;
  };

  return (
    <Typography tag="p" {...typographyProps} variant="caption-default-01">
      <Box component="span" display="inline-flex" alignItems="center">
        {hasSingleContact ? null : (
          <FormattedMessage {...definedMessages.CALLER_ID_LABEL}>
            {(message) => <span>{message}&nbsp;</span>}
          </FormattedMessage>
        )}
        {isPhoneNumberClickable ? (
          <>
            <Link onClick={onPhoneNumberClick} className={classes.link} leadingIcon={renderPhoneIcon()}>
              <span>{displayNumber}</span>
            </Link>
          </>
        ) : (
          <span className={classes.phoneNumber}>{displayNumber}</span>
        )}
        {renderPhoneNumberSyncButton()}
        {hasSingleContact || !displayName ? null : <span>&lt;{displayName}&gt;</span>}
      </Box>
    </Typography>
  );
};
