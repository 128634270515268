import { Button, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { createStyles, makeStyles, Box } from '@material-ui/core';
import React, { useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { logger } from '../../logging/logger';
import { salesforceInitializationErrorSelector } from '../../salesforce/salesforce.selectors';
import { AlertInactiveIcon, CopyInactiveIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '../../theme/Typography.component';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';
import { GotoIntegrationsLogo } from '../../components/GotoIntegrationsLogo.component';

const definedMessages = defineMessages({
  TITLE: {
    id: 'Salesforce.Dialog.InitError.Title',
    defaultMessage: 'Initialization error',
  },
  DESCRIPTION: {
    id: 'Salesforce.Dialog.InitError.Description',
    defaultMessage:
      'This application needs access to some objects in Salesforce to work. Please check the required setup {link}.',
  },
  SUPPORT_LINK_HERE: {
    id: 'Salesforce.Dialog.InitError.SupportLinkHere',
    defaultMessage: 'here',
  },
  SEND_TO_ADMIN: {
    id: 'Salesforce.Dialog.InitError.SendToAdmin',
    defaultMessage: 'You can send this error message to your IT Admin:',
  },
  COPY_BUTTON: {
    id: 'Salesforce.Dialog.InitError.CopyButton',
    defaultMessage: 'Copy text to clipboard',
  },
  COPY_BUTTON_SHORT: {
    id: 'Salesforce.Dialog.InitError.CopyButtonShort',
    defaultMessage: 'Copy to clipboard',
  },
});

const supportLink =
  'https://support.goto.com/connect/help/how-do-i-configure-the-gotoconnect-integration-in-my-salesforce-account';

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    hidden: {
      height: 0,
      position: 'absolute',
      top: '-20px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      overflowY: 'auto',
    },
    description: {
      marginTop: '48px', // theme spacing didnt work somehow
      marginRight: theme.spacing(8),
      marginLeft: theme.spacing(8),
      marginBottom: theme.spacing(10),
    },
  }),
);

const useNarrowStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    hidden: {
      height: 0,
      position: 'absolute',
      top: '-20px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      overflowY: 'auto',
    },
    description: {
      marginTop: '48px', // theme spacing didnt work somehow
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(8),
    },
    error: {
      fontSize: '12px',
      lineHeight: '1.4',
    },
  }),
);

export const SFInitializationError: React.FC = () => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const error = useSelector(salesforceInitializationErrorSelector);
  const errorRef = useRef<HTMLTextAreaElement>(null);

  const normalClasses = useStyles();
  const narrowClasses = useNarrowStyles();
  const classes = isNarrowIntegration ? narrowClasses : normalClasses;

  if (!error) {
    logger.error('SF Initialization error page showed when no error was recorder.');
    return null;
  }

  return (
    <div className={classes.container}>
      <textarea className={classes.hidden} ref={errorRef} value={error} readOnly />
      <GotoIntegrationsLogo width="50%" narrowWidth="100%" />
      <Box textAlign="center" className={classes.description}>
        <Box display="inline-flex">
          <AlertInactiveIcon color="var(--goto-icon-color-danger)" size="32px" />
        </Box>
        <Typography variant="heading-small">
          <FormattedMessage {...definedMessages.TITLE} />
        </Typography>
        <Typography variant="body-small">
          <FormattedMessage
            {...definedMessages.DESCRIPTION}
            values={{
              link: (
                <a href={supportLink} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage {...definedMessages.SUPPORT_LINK_HERE} />
                </a>
              ),
            }}
          />
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption-default-01">
          <b>
            <FormattedMessage {...definedMessages.SEND_TO_ADMIN} />
          </b>
        </Typography>
        <Typography variant="caption-default-01">
          <i>{error}</i>
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          onClick={() => {
            if (!errorRef.current) {
              return;
            }

            errorRef.current?.select();

            console.log('copy', document.execCommand('copy'));
          }}
        >
          <Box display="inline-flex" gridGap={4} alignItems="center">
            {isNarrowIntegration ? null : <CopyInactiveIcon size="14px" />}
            <FormattedMessage
              {...(isNarrowIntegration ? definedMessages.COPY_BUTTON_SHORT : definedMessages.COPY_BUTTON)}
            />
          </Box>
        </Button>
      </Box>
    </div>
  );
};
