import { defineMessages } from 'react-intl';
import { ContactActionsType } from '../actions/contacts';
import { tracker } from '../analytics/tracker';
import { closeDialog } from '../dialog/dialog.actions';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { logger } from '../logging';
import { addListener, addListeners, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { NoteActionsType, addNoteSuccess, addNoteError } from '../notes/notes.actions';
import { EntitiesActionsType } from '../search/entities.action';
import { removeSalesforceStoredCallLog, SalesforceActionType, updateCallDispositionValues } from './salesforce.actions';
import { SaleforceAnalyticsActionTypes, salesforceUserInformationFound } from './salesforceAnalytics.action';
import { closeIntegrationPanel, openIntegrationPanel } from '../embedded/embedded.action';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SalesforceLightningActionFacade } from './salesforceLightning.actionfacade';
import { CallEventsActionsType } from '@jive/realtime-events';
import { PostCallActionsType, SaveCallPayload } from '../calls/callEvents.action';
import { salesforceService } from './salesforceLightning.service';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsCategory, AnalyticsAction } from '../analytics-new/analytics.models';
import {
  isAutomaticCallLoggingEnabledSelector,
  isOpenCallLogAfterCallEnabledSelector,
  isSoftphoneCapableAndEnabledSelector,
  isWidgetAutoPopupEnabledSelector,
} from '../settings/settings.selector';
import { getRouteParams, goToRouteWithParams } from '../browserHistory';
import {
  ApplicationRoute,
  PARAM_CALLID,
  PARAM_CALL_ACTION_ID,
  EntityType,
  CallActionRoutes,
  RouteSearchParams,
} from '../constants';
import { contactInCallSelector } from '../calls/calls.selector';
import { isTestCallSelector } from '../onboarding/testCall/testCall.selectors';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { trackCtiPopup } from './salesforceCtiPopup.analytics';

const definedMessages = defineMessages({
  DIALOG_CONTACT_UPDATE_TITLE: {
    id: 'Salesforce.Dialog.ContactUpdate.Title',
    defaultMessage: 'Contact Update',
  },
  DIALOG_CONTACT_UPDATE_BODY: {
    id: 'Salesforce.Dialog.ContactUpdate.Body',
    defaultMessage: 'The existing phone number will be replaced. {areyousure}',
  },
  DIALOG_CONTACT_UPDATE_BODY_ARE_YOU_SURE: {
    id: 'Salesforce.Dialog.ContactUpdate.Body.AreYouSure',
    defaultMessage: 'Are you sure?',
  },
  DIALOG_CONTACT_UPDATE_CONFIRM: {
    id: 'Salesforce.Dialog.ContactUpdate.Confirm',
    defaultMessage: 'Send',
  },
  DIALOG_CONTACT_UPDATE_CANCEL: {
    id: 'Salesforce.Dialog.ContactUpdate.Cancel',
    defaultMessage: 'Cancel',
  },
  OPEN_CONTACT_ERROR: {
    id: 'Open.Contact.Error',
    defaultMessage: 'An error occurred while opening the user.',
    description: 'error message contact open',
  },
  CREATE_CONTACT_SUCCESS: {
    id: 'Create.Contact.Success',
    defaultMessage: 'Contact created successfully!',
    description: 'success message contact create',
  },
  CREATE_ENTITY_ERROR: {
    id: 'Create.Entity.Error',
    defaultMessage: 'An error has occurred',
    description: 'error message entity create',
  },
  UPDATE_CONTACT_ERROR: {
    id: 'Update.Contact.Error',
    defaultMessage: 'There was an error while updating the contact, please try again.',
    description: 'error message contact update',
  },
  UPDATE_CONTACT_SUCCESS: {
    id: 'Update.Contact.Success',
    defaultMessage: 'Contact updated successfully!',
    description: 'success message contact update',
  },
  CREATE_NOTE_ERROR: {
    id: 'Create.Note.Error',
    defaultMessage: 'There was an error while creating the note, please try again.',
  },
  CREATE_NOTE_SUCCESS: {
    id: 'Create.Call.Detail.Success',
    defaultMessage: 'Note created successfully!',
  },
  NO_CONTACT_ASSOCIATED_AUTOLOG_ERROR: {
    id: 'Salesforce.CallLog.NoContact.ErrorMessage',
    defaultMessage: 'Could not log the call. Please assign a contact to the call and try again.',
  },
});

addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, action) => {
  const currentIntegration: string = action.payload;

  if (
    currentIntegration === Integrations.SalesforceLightning ||
    currentIntegration === Integrations.SalesforceClassic
  ) {
    registerSalesforceListeners();
  }
});

let registered = false;

function registerSalesforceListeners() {
  if (registered) {
    return;
  }

  registered = true;
  const salesforceProxy = salesforceService;

  addListener(SaleforceAnalyticsActionTypes.USER_INFORMATION_FOUND)((_, action) => {
    action.payload.forEach((sfinfo) =>
      tracker.trackEvent(
        'Salesforce',
        'usage',
        JSON.stringify({
          category: 'GoToConnect - Usage',
          action: 'salesforce lightning',
          label: sfinfo.callCenterId,
          value: sfinfo.userCount,
        }),
      ),
    );

    const isConsole = action.payload.find((u) => u.isRunningInConsoleMode) !== undefined;
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Application,
      action: AnalyticsAction.SalesforceUserLoaded,
      label: `console | ${isConsole ? 'true' : 'false'}`,
    });

    logger.setContext('salesforce', {
      isRunningInConsoleMode: isConsole,
      domain: getRouteParams(RouteSearchParams.SALESFORCE_DOMAIN),
    });
  });

  addListener(SalesforceActionType.OPEN_EDITIONS_HELP_PAGE)(() => {
    window.open('https://help.salesforce.com/articleView?id=000326486&type=1&mode=1', '_blank');
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, { payload }) => {
    await getActionFacade().loadContactPhoneNumbers(payload);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const { query } = action.payload;

    await getActionFacade().searchContact(query);
  });

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, { payload: { contactId, phoneNumber } }) => {
    await getActionFacade().updateContactPhoneNumber(contactId, phoneNumber);
  });

  addListeners([
    ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_SUCCESS,
    ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_ERROR,
  ])(() => {
    return closeDialog({});
  });

  addListener(NoteActionsType.GET_MORE_NOTES)(async () => {
    await getActionFacade<SalesforceLightningActionFacade>().loadMoreNotesOfContact();
  });

  addListener(openIntegrationPanel.type)(async (state, action) => {
    const shouldOpenWidget = isSoftphoneCapableAndEnabledSelector(state) || isWidgetAutoPopupEnabledSelector(state);
    if (!shouldOpenWidget) {
      return;
    }

    await salesforceProxy.openSoftphonePanel();

    if (currentIntegrationSelector(state) === Integrations.SalesforceLightning) {
      await trackCtiPopup((action as ReturnType<typeof openIntegrationPanel>).payload.reason);
    }
  });

  addListener(closeIntegrationPanel.type)(async () => {
    await salesforceProxy.hideSoftphonePanel();
  });

  addListener(NoteActionsType.GET_NOTES)(async (_, action) => {
    const { contactId } = action.payload;
    await getActionFacade<SalesforceLightningActionFacade>().loadMostRecentNoteOfContact(contactId);
  });

  addListener(NoteActionsType.ADD_NOTE)(async (_, action) => {
    const { contactId, noteSubject, content } = action.payload;

    try {
      await salesforceProxy.saveNote(noteSubject, content, contactId);

      return addNoteSuccess({
        message: definedMessages.CREATE_NOTE_SUCCESS,
        contactId,
      });
    } catch (e) {
      logger.error('Error adding salesforce note', e);
      return addNoteError({
        message: definedMessages.CREATE_NOTE_ERROR,
      });
    }
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(call);
  });

  addCallStartListener(async (_, action) => {
    const { call } = action.payload;
    await getActionFacade().createScreenpop(call);
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, action) => {
    const contact = action.payload.entity;
    await getActionFacade().openContactInCrm(contact.id);
  });

  addListener(EntitiesActionsType.CREATE_ENTITY)(async (_, { payload: { entityName, defaultFieldValues } }) => {
    await getActionFacade<SalesforceLightningActionFacade>().openFormInCrm({
      entityType: entityName,
      defaultFieldValues,
    });
  });

  addListener(PostCallActionsType.SAVE_CALL)(async (state, action) => {
    const { callLog, shouldPersist, call }: SaveCallPayload = action.payload;
    const storedCallLog = state.salesforce.common.storedCallLogs[call.id];

    if (!shouldPersist) {
      return;
    }

    if (!callLog && !storedCallLog) {
      logger.error('No call log to save in salesforce lightning.');
      return;
    }

    const logToUse = storedCallLog ?? callLog;

    if (isAutomaticCallLoggingEnabledSelector(state) || storedCallLog) {
      await getActionFacade<SalesforceLightningActionFacade>().createCallLog(logToUse);

      if (storedCallLog) {
        return removeSalesforceStoredCallLog({ callId: call.id });
      }
    }

    if (isOpenCallLogAfterCallEnabledSelector(state)) {
      goToRouteWithParams(ApplicationRoute.CALL_ROUTE_WITH_ACTION, {
        [PARAM_CALLID]: logToUse.CallId,
        [PARAM_CALL_ACTION_ID]: CallActionRoutes[EntityType.CALL_LOG],
      });
    }
  });

  addListener(SaleforceAnalyticsActionTypes.USER_INFORMATION)(async () => {
    try {
      const information = await salesforceProxy.getUserInformation();
      return salesforceUserInformationFound(information);
    } catch (e) {
      logger.error('Error getting user information', e);
    }
  });

  addListener(SaleforceAnalyticsActionTypes.USER_INFORMATION_FOUND)(async (_, action) => {
    try {
      const isCallDispositionConfigured =
        action.payload.find((userInfo) => userInfo.isCallDispositionConfigured) !== undefined;

      if (!isCallDispositionConfigured) {
        return;
      }

      const callDispositionValues = await salesforceProxy.getCallDispositionValues();

      return updateCallDispositionValues(callDispositionValues);
    } catch (e) {
      logger.warn('Error processing SF user information', e);
      await getActionFacade<SalesforceLightningActionFacade>().handleCallDispositionConfigurationError();
    }
  });

  addListener(CallEventsActionsType.END_CONVERSATION)((state, action) => {
    const isTestCall = isTestCallSelector(action.payload.call.id)(state);
    if (isTestCall) {
      return;
    }

    const contact = contactInCallSelector(state, action.payload.call.id);

    const shouldCreateAutolog = isAutomaticCallLoggingEnabledSelector(state);
    const shouldshowCallLogForm = isOpenCallLogAfterCallEnabledSelector(state);

    if (!contact && (shouldCreateAutolog || shouldshowCallLogForm)) {
      goToRouteWithParams(ApplicationRoute.CALL_ROUTE_WITH_ACTION, {
        [PARAM_CALLID]: action.payload.call.id,
        [PARAM_CALL_ACTION_ID]: CallActionRoutes[EntityType.CALL_LOG],
      });
    }
  });
}
