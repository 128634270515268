// Original source: https://github.com/jive/jiveweb/blob/master/packages/gtc/src/app/utils/phone-number.ts

// can start with `+` or alpha numeric character or `*` or `#` or `tel:` or `tel://`
// must have at least 2 number like characters e.g. 11 or 1a or aa
// special characters allowed: +, *, [, ], (, ), {, }, ., three types of `-` (-, Option + -, Option + Shift + -)
// () -... would be invalid
// (800)  would be valid
// +99 would be valid
// *99 would be valid
// tel:1(800)GOT-JUNK would be valid
const phoneNumberLike = /^\s*\+?[\w\,\*\#\–\—\-\(\)\{\}\_\[\]\.\s]*$/;
const nonDigits = /[^\d,\*\#\+]+/g;
const acceptedCharacters = /^\s*[\(\)\{\}\[\]\_\.\–\—\-\s]/;
const telProtocol = /(?:^)(tel:\/\/|tel:)/;

export function normalizePhoneNumber(phoneNumber: string) {
  if (canParsePhoneNumber(phoneNumber)) {
    const number = phoneNumber.trim();

    // The regex will remove unwanted characters including any + in the number.
    // If there was a leading +, we put it back manually after the number has been normalized.
    // This keep the regex digestible. (a more complicated regex was attempted but failed on firefox).
    const normalizeRegex = /[^\*\#\,\d]+/g;
    const hasExplicitCountryCode = number.startsWith('+');
    const normalized = number.replace(normalizeRegex, '');
    return hasExplicitCountryCode ? '+' + normalized : normalized;
  } else {
    return undefined;
  }
}

/**
 * Parses a phone number and maps letters to its number counterpart
 * @param {string} query - Phone number
 * @returns The parsed phone number
 * @throws Will throw an error if query is undefined or unparsable
 * @example 1(800)GOT-JUNK
 *  // returns '18004685865'
 * @example 😀1(800)GOT-JUNK
 * // throws Cannot parse 😀1(800)GOT-JUNK as a phone number
 * @example 1.(800)-2.4.2-1.0.4..0
 * // returns '18002421040'
 */
export const parsePhoneNumber = (query: string) => {
  if (!canParsePhoneNumber(query)) {
    throw new Error(`Cannot parse ${query} as a phone number`);
  }

  return query
    .replace(telProtocol, '')
    .replace(/[abc]/gi, '2')
    .replace(/[def]/gi, '3')
    .replace(/[ghi]/gi, '4')
    .replace(/[jkl]/gi, '5')
    .replace(/[mno]/gi, '6')
    .replace(/[pqrs]/gi, '7')
    .replace(/[tuv]/gi, '8')
    .replace(/[wxyz]/gi, '9')
    .replace(nonDigits, '');
};

export function parseAndNormalizePhoneNumber(phoneNumber: string) {
  if (canParsePhoneNumber(phoneNumber)) {
    return normalizePhoneNumber(parsePhoneNumber(phoneNumber));
  } else {
    return undefined;
  }
}

export const isPossiblePhoneNumber = (number: string) => {
  let minPhoneNumberLength = 3;

  if (number.startsWith('*')) {
    return true;
  }

  if (number.startsWith('+')) {
    minPhoneNumberLength = 4;
  }

  return number.replace(/\D/g, '').length >= minPhoneNumberLength;
};

export function numberCanBeDialed(number: string) {
  const parsedNumber = parseAndNormalizePhoneNumber(number);

  if (!parsedNumber) {
    return false;
  }

  return isPossiblePhoneNumber(parsedNumber);
}

export const canParsePhoneNumber = (query?: string) => {
  if (!query) {
    return false;
  }

  return (
    (isTelProtocolStartValid(query) || isPhoneNumberStartValid(query)) &&
    phoneNumberLike.test(query.replace(telProtocol, ''))
  );
};

export const isTelProtocolStartValid = (query: string) => {
  if (telProtocol.test(query)) {
    return isPhoneNumberStartValid(query.replace(telProtocol, ''));
  }
  return false;
};

const isPhoneNumberStartValid = (query: string) => {
  if (/\d/g.test(query)) {
    return /^[\+\*]?\d/.test(query.replace(nonDigits, ''));
  } else if (/\w/g.test(query)) {
    return false;
  }

  return acceptedCharacters.test(query) || /[\+\*]/.test(query);
};
