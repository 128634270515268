import { Call } from '@jive/realtime-events';
import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import {
  contactFound,
  contactNotFound,
  updateEntityPhoneNumberError,
  updateEntityPhoneNumberSuccess,
} from '../../../actions/contacts';
import { createContactError } from '../../../actions/contacts';
import { goToCallPage } from '../../../browserHistory';
import { getNumberOfTheOtherParty } from '../../../calls/call.helper';
import { logger } from '../../../logging';
import { Entity, IndexedById } from '../../../models/index';
import { formatPhoneNumberToPossibleMatches, isValidPhoneNumber } from '../../../phone/phone-utils';
import { AppState } from '../../../reducers';
import {
  createContactSuccess,
  entityResultsNotFound,
  entitySearchSuccess,
  loadEntityPhoneNumbersError,
  loadEntityPhoneNumbersSuccess,
  openEntityError,
  openEntitySuccess,
} from '../../../search/entities.action';
import { EntityPhoneNumber } from '../../../search/entities.model';
import { getDistinctItemsByProperty } from '../../../utils';
import { ContactCreateModel, ContactSearchError } from './contact.actioncreator.models';
import { AnalyticsCategory, AnalyticsAction } from '../../../analytics-new/analytics.models';
import { newTracker } from '../../../analytics-new/tracker-new';
import { calledContactSelector } from '../../../phone/phone.selector';
import { isIntegrationLinkedIfNecessarySelector } from '../../../integrations/integrations.selector';

const CALL_MATCHTYPE_ANALYTICS_SYNC_PREFIX = 'call_matchtype_analytics_sync';
const CALL_MATCHTYPE_DURATION_ANALYTICS_SYNC_PREFIX = 'call_matchtype_duration_analytics_sync';

const definedMessages = defineMessages({
  OPEN_CONTACT_ERROR: {
    id: 'Open.Contact.Error',
    defaultMessage: 'An error occurred while opening the user.',
    description: 'error message contact open',
  },
  UPDATE_CONTACT_ERROR: {
    id: 'Update.Contact.Error',
    defaultMessage: 'There was an error while updating the contact, please try again.',
    description: 'error message contact update',
  },
  UPDATE_CONTACT_SUCCESS: {
    id: 'Update.Contact.Success',
    defaultMessage: 'Contact updated successfully!',
    description: 'success message contact update',
  },
  UPDATE_ACTIVE_CALL_WARNING: {
    id: 'Update.Active.Call.Warning',
    defaultMessage: 'Switch to new call',
    description: 'warning message update active call',
  },
  CREATE_CONTACT_SUCCESS: {
    id: 'Create.Contact.Success',
    defaultMessage: 'Contact created successfully!',
    description: 'success message contact create',
  },
  CREATE_CONTACT_ERROR: {
    id: 'Create.Contact.Error',
    defaultMessage: 'There was an error while creating the ticket, please try again.',
    description: 'error message contact create',
  },
});

export abstract class ContactActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async searchContactForCall(call: Call): Promise<void> {
    try {
      const isLinkedIfNecessary = isIntegrationLinkedIfNecessarySelector(this.store.getState());
      if (!isLinkedIfNecessary) {
        return;
      }

      // first check if we started the call from a contact
      const calledContact = calledContactSelector(this.store.getState());
      if (call.isClickToCall && calledContact) {
        this.store.dispatch(
          contactFound({
            callId: call.id,
            allMatches: [calledContact],
            theOtherParty: call.theOtherParty,
          }),
        );

        return;
      }

      const { entity, isAutoSingleMatchActiveForPhoneNumber } = await this._getUserAssignmentForCall(call);
      if (entity) {
        this.store.dispatch(
          contactFound({
            callId: call.id,
            allMatches: [entity],
            theOtherParty: call.theOtherParty,
            autoSingleMatch: entity,
          }),
        );
        await this.trackContactMatch(call, 'auto single match');

        return;
      }

      const now = performance.now();

      // if we did not start the call from a contact, make a search by number
      const query = getNumberOfTheOtherParty(call);

      const phoneNumbers = isValidPhoneNumber(query) ? this.formatPhoneNumberToPossibleMatches(query) : [query];
      const contacts = await this.searchContactByPhoneNumbers(phoneNumbers);
      const allMatches: Entity[] = contacts.filter((entity) => entity.phoneNumber);
      let autoSingleMatch: Entity | undefined;
      if (isAutoSingleMatchActiveForPhoneNumber) {
        autoSingleMatch = await this._findAutoSingleMatchForContacts(allMatches);
      }

      const matchType =
        !allMatches || allMatches.length === 0
          ? 'no match'
          : allMatches.length === 1 || !!autoSingleMatch
          ? !!autoSingleMatch
            ? 'auto single match'
            : 'single match'
          : 'multiple match';
      await this.trackContactMatch(call, matchType);

      this.store.dispatch(
        contactFound({
          callId: call.id,
          allMatches,
          theOtherParty: call.theOtherParty,
          autoSingleMatch,
        }),
      );

      const then = performance.now();
      await newTracker.trackAnalyticsEventOnce(
        {
          category: AnalyticsCategory.Call,
          action: AnalyticsAction.ContactMatchDuration,
          label: String(((then - now) / 1000).toFixed(2)),
        },
        CALL_MATCHTYPE_DURATION_ANALYTICS_SYNC_PREFIX,
        call.id,
      );
    } catch (error: any) {
      if ((error as ContactSearchError).handled) {
        return;
      }

      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Contact,
        action: AnalyticsAction.ContactMatchFailed,
        label: error.message || error.statusText || 'Incoming call could not check match.',
      });

      logger.error('Error searching for contact', error);
      this.store.dispatch(contactNotFound({ callId: call.id, phoneNumber: getNumberOfTheOtherParty(call) }));
    }
  }

  public async openContactInCrm(id: string) {
    if (id === undefined || id === '') {
      logger.error('Tried to open contact without id.');
      this.store.dispatch(
        openEntityError({
          message: definedMessages.OPEN_CONTACT_ERROR,
        }),
      );
      return;
    }

    try {
      await this._openCrmContactPage(id);
      this.store.dispatch(openEntitySuccess());
    } catch (error) {
      logger.error(`Could not open contact with id`, error, { id });
      this.store.dispatch(
        openEntityError({
          message: definedMessages.OPEN_CONTACT_ERROR,
        }),
      );
    }
  }

  public async searchContact(query: string): Promise<void> {
    try {
      let contacts: Entity[];

      if (isValidPhoneNumber(query)) {
        const phoneNumbers = this.formatPhoneNumberToPossibleMatches(query);
        contacts = await this.searchContactByPhoneNumbers(phoneNumbers);
      } else {
        contacts = await this._searchContactByName(query);
      }
      this.store.dispatch(entitySearchSuccess({ results: contacts }));
    } catch (error) {
      if ((error as ContactSearchError).handled) {
        return;
      }
      logger.error('Error searching for contact', error, { query });
      this.store.dispatch(entityResultsNotFound({}));
    }
  }

  public async loadContactPhoneNumbers(contactId: string): Promise<void> {
    try {
      const phoneNumbers = await this._getPhoneNumbersOfContact(contactId);

      const indexedPhoneNumbers = phoneNumbers.reduce<IndexedById<EntityPhoneNumber>>((prev, entityPhoneNumber) => {
        prev[contactId + entityPhoneNumber.phoneNumber] = entityPhoneNumber;
        return prev;
      }, {});

      this.store.dispatch(
        loadEntityPhoneNumbersSuccess({
          entityId: contactId,
          phoneNumbers: indexedPhoneNumbers,
        }),
      );
    } catch (error) {
      logger.error('Error loading contact phone numbers', error);
      this.store.dispatch(loadEntityPhoneNumbersError(error));
    }
  }

  public async updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    try {
      if (!contactId || !phoneNumber) {
        logger.error('Tried updating contact phone number without value', {
          contactId,
          phoneNumber,
        });
        this.store.dispatch(
          updateEntityPhoneNumberError({
            contactId,
            message: definedMessages.UPDATE_CONTACT_ERROR,
          }),
        );
        return;
      }

      const canUpdatePhoneNumber = await this._canUpdateContactPhoneNumber(contactId, phoneNumber);
      if (!canUpdatePhoneNumber) {
        return;
      }

      await this._updateContactPhoneNumber(contactId, phoneNumber);
      this.store.dispatch(
        updateEntityPhoneNumberSuccess({
          contactId,
          message: definedMessages.UPDATE_CONTACT_SUCCESS,
        }),
      );
    } catch (e) {
      logger.error('Error updating contact number', e);

      this.store.dispatch(
        updateEntityPhoneNumberError({
          contactId,
          message: definedMessages.UPDATE_CONTACT_ERROR,
        }),
      );
    }
  }

  public async createContact(contactData: ContactCreateModel): Promise<void> {
    try {
      const allParametersAreGiven =
        contactData.callId && contactData.firstName && contactData.lastName && contactData.phoneNumber;

      if (!allParametersAreGiven) {
        logger.error('Not all parameters are filled for contact creation', contactData);

        this.store.dispatch(
          createContactError({
            phoneNumber: contactData.phoneNumber,
            message: definedMessages.CREATE_CONTACT_ERROR,
          }),
        );

        newTracker.trackAnalyticsEvent({
          category: AnalyticsCategory.Contact,
          action: AnalyticsAction.ContactCreationFailed,
          label: 'Not all parameters are filled for contact creation',
        });
        return;
      }

      // Persist contact
      const newContactId = await this._createContact(contactData);
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Contact,
        action: AnalyticsAction.ContactCreationSucceeded,
        label: '' + newContactId,
      });

      // Show toaster
      const name = `${contactData.firstName} ${contactData.lastName}`;
      this.store.dispatch(
        createContactSuccess({
          callId: contactData.callId,
          contactId: newContactId,
          message: definedMessages.CREATE_CONTACT_SUCCESS,
          entity: {
            id: newContactId,
            label: `Contact - ${name}`,
            name,
            phoneNumber: contactData.phoneNumber,
            type: 'Contact',
          },
        }),
      );
    } catch (error) {
      logger.error('Error during contact creation', error);
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Contact,
        action: AnalyticsAction.ContactCreationFailed,
        label: error.message || error.statusText || 'Unknown error while creating contact.',
      });

      this.store.dispatch(
        createContactError({
          phoneNumber: contactData.phoneNumber,
          message: definedMessages.CREATE_CONTACT_ERROR,
        }),
      );
    }

    // This is how it happens:
    // 1 create success will reach some listeners, but not the contact_associate one, thats in second round
    // 2 goes back to call page
    // 3 search input initializes -> clears entities
    // 4 now runs the second round of listeners, including contact association
    // 5 entites is already empty, because of 3., so association fails
    // this settimeout should be removed when this ordering is controlled already
    setTimeout(() => {
      goToCallPage(contactData.callId);
    });

    // TODO: execute selecting contact for phone here instead of listener
    // TODO: execute contact association here instead of listener
    // TODO: execute contact opening in CRM here instead of listener
  }

  private async searchContactByPhoneNumbers(phoneNumbers: string[]) {
    const phoneNumbersToFind: string[] = phoneNumbers.filter((phoneNumber) => phoneNumber);

    const foundContacts: Entity[] = [];

    const searchRequestPromises = phoneNumbersToFind.map((phoneNumber) =>
      this._searchContactByPhoneNumber(phoneNumber),
    );

    let errorHappened = false;
    const responses = await Promise.allSettled(searchRequestPromises);

    responses.forEach((response, i) => {
      if (response.status === 'fulfilled' && response.value.length > 0) {
        foundContacts.push(...response.value);
      } else if (response.status === 'rejected') {
        errorHappened = true;
        logger.error('Error searching for contact', { phoneNumber: phoneNumbersToFind[i], reason: response.reason });
      }
    });

    if (foundContacts.length === 0 && errorHappened) {
      throw Error('Failed getting contact numbers');
    }

    return getDistinctItemsByProperty(foundContacts, (c) => c.id);
  }

  private trackContactMatch(
    call: Call,
    matchType: 'no match' | 'auto single match' | 'single match' | 'multiple match',
  ) {
    return newTracker.trackAnalyticsEventOnce(
      {
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ContactMatchSuccess,
        label: `Incoming call match checked | matchtype: ${matchType}`,
      },
      CALL_MATCHTYPE_ANALYTICS_SYNC_PREFIX,
      call.id,
    );
  }

  protected formatPhoneNumberToPossibleMatches(phoneNumber: string): string[] {
    return formatPhoneNumberToPossibleMatches(phoneNumber);
  }
  protected abstract _searchContactByName(query: string): Promise<Entity[]>;
  protected abstract _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]>;
  protected abstract _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]>;
  protected abstract _openCrmContactPage(contactId: string): Promise<void>;
  protected abstract _openCrmContactTab(contactId: string): Promise<boolean>;
  protected abstract _canUpdateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<boolean>;
  protected abstract _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void>;
  protected abstract _createContact(contactData: ContactCreateModel): Promise<string>;

  protected async _findAutoSingleMatchForContacts(_: Entity[]): Promise<Entity | undefined> {
    return undefined;
  }

  protected async _getUserAssignmentForCall(
    _call: Call,
  ): Promise<{ entity: Entity | undefined; isAutoSingleMatchActiveForPhoneNumber: boolean }> {
    return { entity: undefined, isAutoSingleMatchActiveForPhoneNumber: false };
  }
}
