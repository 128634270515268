import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { JiveEntity } from './jiveEntities.model';

export type jiveEntityTypes = 'featureFlags' | 'pbxes' | 'phoneNumbers' | 'voicemails';

export const jiveEntityResourceSelector =
  <T>(entityName: jiveEntityTypes) =>
  (state: AppState): JiveEntity<T> =>
    state.jiveEntities[entityName] as unknown as JiveEntity<T>;

export const jiveEntityArraySelector = <T>(entityName: jiveEntityTypes) =>
  createSelector(jiveEntityResourceSelector<T>(entityName), (entityResource) =>
    entityResource.allIds.map((id) => entityResource.byId[id]),
  );

export const jiveEntitySelector = <T>(entityName: jiveEntityTypes, entityId: string) =>
  createSelector(jiveEntityResourceSelector<T>(entityName), (entityResource) => entityResource.byId[entityId]);
