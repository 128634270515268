import { Banner } from '../../inAppNotification/Banner';
import { Box, makeStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { IconButton } from '@getgo/chameleon-material-ui';
import { useDispatch } from 'react-redux';
import { deleteAllMessages } from '../../inAppNotification/message.action';
import { ApplicationRoute } from '../../constants';
import { goToRoute } from '../../browserHistory';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { setShowCallDispositionForm } from '../../settings/settings.action';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  CALL_DISPOSITION_ERROR_TITLE: {
    id: 'Salesforce.CallDisposition.ConfigurationError.Title',
    defaultMessage: 'Call disposition error',
  },
  CALL_DISPOSITION_ERROR_SHORT_DESC: {
    id: 'Salesforce.CallDisposition.ConfigurationError.ShortDescription',
    defaultMessage: 'Your call disposition is not set correctly, it can not be saved at the moment.',
  },
  CALL_DISPOSITION_ERROR_LONG_DESC: {
    id: 'Salesforce.CallDisposition.ConfigurationError.LongDescription',
    defaultMessage: 'Your System Admin can make the necessary changes in Salesforce.',
  },
  CALL_DISPOSITION_ERROR_SETTINGS_PAGE_LINK: {
    id: 'Salesforce.CallDisposition.ConfigurationError.SettingsPageLinkLabel',
    defaultMessage: 'Settings page',
  },
  CALL_DISPOSITION_ERROR_SUPPORT_PAGE_LINK: {
    id: 'Salesforce.CallDisposition.ConfigurationError.SupportPageLinkLabel',
    defaultMessage: 'Support page',
  },
  CALL_DISPOSITION_ERROR_DISMISS_BUTTON: {
    id: 'Salesforce.CallDisposition.ConfigurationError.DismissButton.Text',
    defaultMessage: 'Disable call disposition',
  },
});

const useStyles = makeStyles(() => ({
  link: {
    '&::part(content)': {
      fontWeight: 'bold',
      color: tokens.text04,
    },
  },
  closeButton: {
    alignSelf: 'flex-end',
    marginTop: 3,
    '& svg': {
      color: tokens.text04,
    },
  },
}));

const trackingEvents = defineTrackingEvents({
  CALL_DISPOSITION_ALERT_SHOWN: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PopupShown,
    label: 'Call disposition error',
  },
  CALL_DISPOSITION_ALERT_DISMISSED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: 'Disable Call Disposition',
  },
});

export const CallDispositionConfigurationErrorMessage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    newTracker.trackAnalyticsEvent(trackingEvents.CALL_DISPOSITION_ALERT_SHOWN);
  }, []);

  const onSettingsLinkClicked = () => {
    dispatch(deleteAllMessages());
    goToRoute(ApplicationRoute.SETTINGS_ROUTE);
  };

  const onClose = () => {
    dispatch(deleteAllMessages());
  };

  const onDisableCallDisposition = () => {
    dispatch(setShowCallDispositionForm(false));
    dispatch(deleteAllMessages());
  };

  const Message = () => (
    <>
      <Box display="flex" alignItems="center">
        <Box fontWeight="bold" flexGrow={1}>
          <FormattedMessage {...definedMessages.CALL_DISPOSITION_ERROR_TITLE} />
        </Box>
        <IconButton size="small" onClick={onClose} className={classes.closeButton} variant="secondary">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box mt={3}>
        <FormattedMessage {...definedMessages.CALL_DISPOSITION_ERROR_SHORT_DESC} />
      </Box>
      <Box mt={3}>
        <FormattedMessage
          {...definedMessages.CALL_DISPOSITION_ERROR_LONG_DESC}
          values={{
            supportPage: (
              <Link
                className={classes.link}
                href="https://support.goto.com/connect/help/how-do-i-set-up-custom-call-dispositions-in-the-salesforce-integration"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...definedMessages.CALL_DISPOSITION_ERROR_SUPPORT_PAGE_LINK} />
              </Link>
            ),
            settingsPage: (
              <Link className={classes.link} onClick={onSettingsLinkClicked}>
                <FormattedMessage {...definedMessages.CALL_DISPOSITION_ERROR_SETTINGS_PAGE_LINK} />
              </Link>
            ),
          }}
        />
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <TrackedButton
          variant="neutral-inverse"
          size="small"
          onClick={onDisableCallDisposition}
          trackingEvent={trackingEvents.CALL_DISPOSITION_ALERT_DISMISSED}
        >
          <FormattedMessage {...definedMessages.CALL_DISPOSITION_ERROR_DISMISS_BUTTON} />
        </TrackedButton>
      </Box>
    </>
  );

  return <Banner variant="danger" message={<Message />} hasIcon={false} />;
};
