import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { DataRow } from '../DataRow.component';
import { defineMessages } from 'react-intl';
import { LoadInventoryPartRequestState, RepairOrderPart } from './parts.models';
import { toDateTimeStringOrDefault } from '../cox.utils';
import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from '../../theme/Typography.component';

export interface PartProps {
  part: RepairOrderPart;
  partName: string;
  locale: Locale;
  requestState: LoadInventoryPartRequestState;
}

const definedMessages = defineMessages({
  LINE_COMPLETED_DATE: {
    id: 'Cox.Parts.Detail.LineCompletedDate',
    defaultMessage: 'Completed:',
  },
  TRANS_DATE: {
    id: 'Cox.Parts.Detail.TransDate',
    defaultMessage: 'Created:',
  },
  PRICE: {
    id: 'Cox.Parts.Detail.Price',
    defaultMessage: 'Price:',
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2, 3),
      backgroundColor: tokens.uiBase01,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

export const PartComponent: React.FC<PartProps> = ({ part, partName, locale, requestState }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {requestState === LoadInventoryPartRequestState.LOADING ? (
        <CircularProgress size={21} />
      ) : (
        <Box mb={1}>
          <Typography variant="caption-default-02">{partName}</Typography>
        </Box>
      )}
      <div>
        {part.dateTimeLineCompleted ? (
          <DataRow
            color="text-02"
            label={definedMessages.LINE_COMPLETED_DATE}
            value={toDateTimeStringOrDefault(part.dateTimeLineCompleted, locale)}
          />
        ) : (
          <DataRow
            color="text-02"
            label={definedMessages.TRANS_DATE}
            value={toDateTimeStringOrDefault(part.transDate, locale)}
          />
        )}
        <DataRow color="text-02" label={definedMessages.PRICE} value={`$${part.price?.toLocaleString(locale.code)}`} />
      </div>
    </div>
  );
};
