import axios from 'axios';
import configuration from '../config';
import { logger } from '../logging';
import { PresenceAppearance } from './presence.models';

const getPresence = async (externalUserKey: string): Promise<{ appearance?: PresenceAppearance }> => {
  const response = await axios.request<{ items: Array<{ appearance: PresenceAppearance }> }>({
    url: `${configuration.apiBaseUrl}/presence/v1/presence`,
    method: 'GET',
    params: { externalUserKey },
  });

  if (response.status < 300) {
    return {
      appearance: response.data.items[0]?.appearance,
    };
  } else {
    logger.error('Error getting user presence', { response });
    return { appearance: undefined };
  }
};

export const PresenceService = {
  getPresence,
};
