import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { history } from '../browserHistory';

interface RedirectKeepSearchComponentProps {
  pathname: string;
  state?: Location;
}

export class RedirectKeepSearch extends Component<RedirectKeepSearchComponentProps> {
  render() {
    return <Redirect to={{ ...this.props, search: history.location.search }} />;
  }
}
