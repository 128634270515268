import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { callSelector, getEntityInCallSelector } from '../calls/calls.selector';
import { addNote } from '../notes/notes.actions';
import { AppState } from '../reducers';
import NewNoteForm, {
  NewNoteFormDispatchProps,
  NewNoteFormProps,
  NewNoteFormStateProps,
} from './NewNoteForm.component';

export default connect<NewNoteFormStateProps, NewNoteFormDispatchProps, NewNoteFormProps, AppState>(
  (state: AppState, { callId }: NewNoteFormProps) => {
    const activeCall = callSelector(state, callId);
    const entityInCall = getEntityInCallSelector(state, callId);

    return {
      contactId: entityInCall?.id,
      contactName: entityInCall?.name,
      isActiveCall: Boolean(activeCall && !activeCall.endTime),
    };
  },
  (dispatch: Dispatch<AppAction>) => ({
    addNote: (payload) => dispatch(addNote(payload)),
  }),
)(NewNoteForm);
