import { Call, CallType } from '@jive/realtime-events';
import { goToRoute } from '../../browserHistory';
import { PostCallActionsType } from '../../calls/callEvents.action';
import { getCallLogDescription, getCallLogSubject } from '../../calls/callLogger.utilities';
import { CallWithDuration } from '../../calls/calls.reducer';
import { getEntityInCallSelector } from '../../calls/calls.selector';
import { ApplicationRoute, CallActionRoutes, EntityType } from '../../constants';
import { IntegrationsActionsType } from '../../integrations/integrations.actions';
import { logger } from '../../logging';
import { addListener, addListenerForOneTimeExecution, addSmartListener } from '../../middlewares/listener.middleware';
import { Integrations, MessageVariant } from '../../models';
import { injectedIntlSelector, selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import { showMessage } from '../../inAppNotification/message.action';
import { ClioEntity, User } from '../clio.models';
import { clioSelfSelector } from '../clio.selector';
import { ClioService } from '../clio.service';
import { ClioCommunication, ClioType } from '../clio.service.models';
import { closeDialog, openDialog } from '../../dialog/dialog.actions';
import { DialogBodyNames } from '../../dialog/DialogBodies';
import { ClioActionsTypes } from '../clio.action';
import {
  CommunicationActionTypes,
  confirmCommunicationSave,
  saveCommunication,
  saveCommunicationError,
  saveCommunicationSuccess,
  storeCommunication,
} from './communication.action';
import { storedCommunicationSelector } from './communication.selector';
import {
  getSavedCommunicationError,
  getSavedCommunicationSuccess,
  SavedCommunicationActionTypes,
} from './saved-communication.action';
import { savedCommunicationByCallIdSelector } from './saved-communication.selector';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { callHistoryElementByCallSelector } from '../../callHistoryPush/unifiedCallHistory.selector';

export const definedMessages = {
  COMMUNICATION_LOG_FORM_TITLE: {
    id: 'ClioCommunicationLogForm.Title',
    defaultMessage: 'Log Communication',
  },
  COMMUNICATION_LOG_FORM_LOG_BUTTON_LABEL: {
    id: 'ClioCommunicationLogForm.Log.Button.Label',
    defaultMessage: 'Log',
  },
  CLIO_COMMUNICATION_GET_ERROR_MESSAGE: {
    id: 'ClioCommunication.Get.ErrorMessage',
    defaultMessage: 'Error getting Communication item from Clio.',
  },
  CREATE_COMMUNICATION_SUCCESS: {
    id: 'ClioCommunicationLog.Create.Success',
    defaultMessage: 'This call has been logged as a communication in Clio.',
    description: 'success message communication create',
  },
  CREATE_COMMUNICATION_DELAYED_MESSAGE: {
    id: 'ClioCommunicationLog.Create.Delayed',
    defaultMessage: 'This call will be logged as a communication in Clio when the call is ended.',
    description: 'success message communication delayed',
  },
  CREATE_COMMUNICATION_ERROR: {
    id: 'ClioCommunicationLog.Create.Error',
    defaultMessage: 'There was an error while creating the communication, please try again.',
    description: 'error message create communication',
  },
  GET_COMMUNICATIONS_ERROR: {
    id: 'Get.Communications.Error',
    defaultMessage: 'There was an error while downloading the activities, please try again.',
    description: 'error message get activities',
  },
};

const trackingEvents = defineTrackingEvents({
  CREATE_COMM_LOG_SUCCEEDED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.CreateCommLogSucceeded,
    label: '',
  },
  CREATE_COMM_LOG_FAILED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.CreateCommLogFailed,
    label: '',
  },
});

// initialize all Clio listeners and interceptor
addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (_, action) => {
  if (action.payload === Integrations.Clio) {
    registerClioCommunicationListener();
  }
});

const registered = false;

function registerClioCommunicationListener() {
  if (registered) {
    return;
  }

  async function getSavedCommunication(userId, callId) {
    try {
      const communication = await ClioService.getCommunicationByUserAndCallId(userId, callId);
      return getSavedCommunicationSuccess({ callId, communication });
    } catch (e) {
      logger.error('Error getting Clio Communication', e);
      return getSavedCommunicationError({
        userId,
        callId,
        message: definedMessages.CLIO_COMMUNICATION_GET_ERROR_MESSAGE,
      });
    }
  }

  addSmartListener(SavedCommunicationActionTypes.GET_SAVED_COMMUNICATION)(async (store, { payload: { callId } }) => {
    const self = clioSelfSelector(store.getState());

    if (!self) {
      addListenerForOneTimeExecution(ClioActionsTypes.CLIO_WHO_AM_I_SUCCESS)(async (_, { payload: { id } }) => {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await store.dispatch(await getSavedCommunication(id, callId));
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await store.dispatch(await getSavedCommunication(self.id, callId));
    }
  });

  addListener(PostCallActionsType.SAVE_CALL_HISTORY)((state, { payload: { call } }) => {
    // Do not log missed/declined call as communications in Clio
    if (call.type === CallType.MissedCall || call.type === CallType.DeclinedCall) {
      return;
    }

    // Let's save the stored communication if one was created during the call
    const storedCommunication = storedCommunicationSelector(state, call.id);
    if (storedCommunication) {
      return saveCommunication(storedCommunication);
    }

    goToRoute(
      ApplicationRoute.CALL_ROUTE_WITH_ACTION.toString()
        .replace(':callId', call.id)
        .replace(':action', CallActionRoutes[EntityType.COMMUNICATION]),
    );
  });

  addListener(CommunicationActionTypes.CREATE_COMMUNICATION)(async (state, { payload }) => {
    const savedCommunication = savedCommunicationByCallIdSelector(state, payload.call.id);

    if (savedCommunication) {
      return openDialog({
        texts: {
          title: definedMessages.COMMUNICATION_LOG_FORM_TITLE,
          confirm: definedMessages.COMMUNICATION_LOG_FORM_LOG_BUTTON_LABEL,
        },
        bodyName: DialogBodyNames.ClioSaveCommunicationLogConfirmation,
        confirmAction: confirmCommunicationSave(payload),
        cancellable: true,
      });
    }

    // let's see if the call is in the history, if yes, we can save the communication
    const callInHistory = callHistoryElementByCallSelector(payload.call)(state);
    const hasCallEnded = callInHistory || payload.call.endTime;

    if (hasCallEnded) {
      return saveCommunication(payload);
    } else {
      return storeCommunication(payload);
    }
  });

  addListener(CommunicationActionTypes.STORE_COMMUNICATION)(() => {
    return showMessage({
      message: definedMessages.CREATE_COMMUNICATION_DELAYED_MESSAGE,
      type: MessageVariant.Info,
      id: 'CREATE_COMMUNICATION_DELAYED_MESSAGE',
      params: {
        autoHide: true,
        dismissible: true,
      },
    });
  });

  addSmartListener(CommunicationActionTypes.CONFIRM_COMMUNICATION_SAVE)(async (store, { payload }) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await store.dispatch(closeDialog({}));
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await store.dispatch(saveCommunication(payload));
  });

  addListener(CommunicationActionTypes.SAVE_COMMUNICATION)(async (state, action) => {
    const { call: callPayload, description, matterId, activityCategoryId, isBillable } = action.payload;

    try {
      const receivedHistoryItem = callHistoryElementByCallSelector(callPayload)(state);
      const call: CallWithDuration = receivedHistoryItem || callPayload;
      const contact = getEntityInCallSelector(state, call.id) as ClioEntity;
      const intl = injectedIntlSelector(state);
      const self = clioSelfSelector(state);
      const locale = selectedDateFnsLocaleSelector(state);

      const contactName = contact ? contact.name : call.theOtherParty.name || call.theOtherParty.number;

      if (!call.endTime) {
        newTracker.trackAnalyticsEvent({
          ...trackingEvents.CREATE_COMM_LOG_FAILED,
          label: 'callLogger.getDescription called with undefined endTime',
        });
        logger.error('callLogger.getDescription called with undefined endTime');
        return saveCommunicationError({
          message: definedMessages.CREATE_COMMUNICATION_ERROR,
        });
      }

      if (call.duration === undefined) {
        logger.error('call duration was 0 or undefined when we tried to save the communication');
        // falling back to calculating the duration based on the call star/end time
        call.duration = Math.round((call.endTime - call.creationTime) / 1000);
      }

      const callLogSubject = getCallLogSubject({
        callType: call.type,
        date: new Date(call.endTime),
        contactName,
        intl,
        locale,
      });

      const date = new Date(call.creationTime).toISOString();
      const { senders, receivers } = getCommunicationParties(call, self, contact);

      const clioCommunication: ClioCommunication = {
        subject: callLogSubject,
        body: description || getCallLogDescription({ call, intl, locale, lineBreak: '\n' }),
        date,
        matter: matterId ? { id: matterId } : undefined,
        receivers,
        senders,
        external_properties: [{ name: 'jive_call_id', value: call.id }],
        type: ClioType.PhoneCommunication,
      };

      try {
        const {
          data: { id },
        } = await ClioService.createCommunication(clioCommunication);

        if (matterId) {
          await ClioService.createActivity({
            date,
            quantity: call.duration,
            non_billable: !isBillable,
            note: description || callLogSubject,
            communication: { id },
            matter: { id: matterId },
            type: ClioType.TimeEntry,
            activity_description: activityCategoryId ? { id: activityCategoryId } : undefined,
          });
        }

        newTracker.trackAnalyticsEvent({
          ...trackingEvents.CREATE_COMM_LOG_SUCCEEDED,
          label: '' + id,
        });
        return saveCommunicationSuccess({
          message: definedMessages.CREATE_COMMUNICATION_SUCCESS,
          callId: call.id,
        });
      } catch (e) {
        newTracker.trackAnalyticsEvent({
          ...trackingEvents.CREATE_COMM_LOG_FAILED,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          label: e?.message?.toString() || 'Unknown issues',
        });
        logger.error('cannot save Clio communication', e);
        return saveCommunicationError({
          message: definedMessages.CREATE_COMMUNICATION_ERROR,
        });
      }
    } catch (e) {
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CREATE_COMM_LOG_FAILED,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        label: e?.message?.toString() || 'Unknown issues',
      });
      logger.error('error creating Clio communication payload', e);
      return saveCommunicationError({
        message: definedMessages.CREATE_COMMUNICATION_ERROR,
      });
    }
  });
}

function getCommunicationParties(call: Call, self?: User, contact?: ClioEntity) {
  const clioSelf = self ? [{ id: self.id, type: ClioType.User }] : undefined;
  const clioContact = contact ? [{ id: Number(contact.id), type: ClioType.Contact }] : undefined;
  let receivers: Array<{ id: number; type: ClioType }> | undefined;
  let senders: Array<{ id: number; type: ClioType }> | undefined;

  switch (call.type) {
    case CallType.IncomingCall:
      receivers = clioSelf;
      senders = clioContact;
      break;
    case CallType.OutgoingCall:
      receivers = clioContact;
      senders = clioSelf;
      break;
  }

  return { senders, receivers };
}
