import { AnalyticsCategory, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { salesforceService } from './salesforceLightning.service';

const DISMISS_THRESHOLD_MSEC = 30_000;
const INACTIVITY_THRESHOLD_MSEC = 60_000;

export async function trackCtiPopup(reason: string) {
  newTracker.trackAnalyticsEvent({
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.IntegrationPanelShown,
    label: `Integration popup | reason: ${reason}`,
  });

  trackPanelDismiss(reason);

  trackInactivity(reason);
}

/** Sends an analytics event if the integration panel was closed within DISMISS_THRESHOLD_MSEC */
function trackPanelDismiss(reason: string) {
  setTimeout(async () => {
    const isVisible = await salesforceService.isSoftphonePanelVisible();

    if (isVisible) {
      return;
    }

    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Application,
      action: AnalyticsAction.IntegrationPanelShown,
      label: `Integration dismissed in less than ${DISMISS_THRESHOLD_MSEC / 1000} seconds | popup reason: ${reason}`,
    });
  }, DISMISS_THRESHOLD_MSEC);
}

/** Sends an analytics event if there were no click interactions within INACTIVITY_THRESHOLD_MSEC */
function trackInactivity(reason: string) {
  const clickHandler = () => {
    window.removeEventListener('click', clickHandler);
    clearTimeout(timeout);
  };

  window.addEventListener('click', clickHandler);

  const timeout = setTimeout(async () => {
    window.removeEventListener('click', clickHandler);
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Application,
      action: AnalyticsAction.IntegrationPanelShown,
      label: `Integration inactive after popup for ${
        INACTIVITY_THRESHOLD_MSEC / 1000
      } seconds | popup reason: ${reason}`,
    });
  }, INACTIVITY_THRESHOLD_MSEC);
}
