import { Action } from 'redux';
import { CREATE, ERROR, REQUEST, SEARCH, SUCCESS, WARNING } from '../constants';

export const LOADING_INITIAL_STATE = false;

const loadingReducer = (basicAction: string) => (state = LOADING_INITIAL_STATE, action: Action<string>) => {
  if (action.type.includes(basicAction)) {
    if (action.type.includes(SUCCESS) || action.type.includes(ERROR) || action.type.includes(WARNING)) {
      return false;
    }
    if (action.type.includes(REQUEST)) {
      return true;
    }
    if (action.type.includes(CREATE) || action.type.includes(SEARCH)) {
      return true;
    }
  }
  return state;
};

export default loadingReducer;
