import axios from 'axios';
import configuration from '../config';

export const subscribeToPresenceChannel = (externalUserKeys: string[]) => async (channelId: string): Promise<void> => {
  const url = `${configuration.apiBaseUrl}/presence/v1/subscriptions`;
  await axios.post(url, {
    channelId,
    externalUserKeys,
  });
};
