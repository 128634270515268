import { Call } from '@jive/realtime-events';
import { Box } from '@material-ui/core';
import React from 'react';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TextArea } from '../../entityForms/TextArea.component';
import JiveForm from '../../entityForms/JiveForm.component';
import { Communication } from '../clio.models';
import { ActivityCategoryPicker } from '../activities/ActivityCategoryPicker.container';
import { MatterPicker } from '../matters/MatterPicker.container';
import { SaveCommunicationPayload } from './communication.action';
import { LabeledCheckbox } from '../../components/LabeledCheckbox.component';
import { CallActionFormProps } from '../../calls/CallPageForm.model';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsCategory, AnalyticsAction, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { Typography } from '../../theme/Typography.component';

export interface ClioCommunicationLogFormStateProps {
  call: Call;
  savedCommunication?: Communication;
}

export interface ClioCommunicationLogFormDispatchProps {
  saveCommunication: (payload: SaveCommunicationPayload) => void;
  getSavedCommunication: (callId: string) => void;
}

export type ClioCommunicationLogFormProps = CallActionFormProps;

interface ClioCommunicationLogFormState {
  matterId?: number;
  activityCategoryId?: number;
  isBillable: boolean;
  description: string;
}

const definedMessages = defineMessages({
  COMMUNICATION_LOG_FORM_TITLE: {
    id: 'ClioCommunicationLogForm.Title',
    defaultMessage: 'Log Communication',
  },
  COMMUNICATION_LOG_FORM_SAVE_AS_NEW_BUTTON_LABEL: {
    id: 'ClioCommunicationLogForm.SaveAsNew.Button.Label',
    defaultMessage: 'Save as new',
  },
  COMMUNICATION_LOG_FORM_DESCRIPTION: {
    id: 'ClioCommunicationLogForm.Description',
    defaultMessage: 'Description',
  },
  COMMUNICATION_LOG_FORM_DESCRIPTION_HELPER_TEXT: {
    id: 'ClioCommunicationLogForm.DescriptionHelperText',
    defaultMessage: 'Without a description, your call information will appear instead.',
  },
  COMMUNICATION_LOG_FORM_NOT_LOGGED_YET_MESSAGE: {
    id: 'ClioCommunicationLogForm.NotLoggedYet.Message',
    defaultMessage: 'This call is not logged in Clio yet.',
  },
  COMMUNICATION_LOG_FORM_PLEASE_SELECT_MATTER_MESSAGE: {
    id: 'ClioCommunicationLogForm.PleaseSelectMatter.Message',
    defaultMessage: 'Please select a matter to save it under:',
  },
  COMMUNICATION_LOG_FORM_BILLABLE_CHECKBOX_LABEL: {
    id: 'ClioCommunicationLogForm.BillableCheckbox.Label',
    defaultMessage: 'Billable time',
  },
  COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_ERROR: {
    id: 'ClioCommunicationLogForm.AlreadyLogged.Error',
    defaultMessage: 'This call is already logged in Clio',
  },
  COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_MESSAGE: {
    id: 'ClioCommunicationLogForm.AlreadyLogged.Message',
    defaultMessage: 'If you want to save it please select a matter:',
  },
});

const trackingEvents = defineTrackingEvents({
  PAGE_LOADED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.PageLoaded,
    label: 'Create communication log',
  },
  BILLABLE_TIME_CLICKED_ON: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Billable time | toggle | ON',
  },
  BILLABLE_TIME_CLICKED_OFF: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Billable time | toggle | OFF',
  },
  DESCRIPTION_CHANGED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Call log description | textarea',
  },
  ACTIVITY_CATEGORY_CHANGED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Activity category | select',
  },
  SAVE_CLICKED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Save communication log | button',
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.CommmunicationLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Cancel | button',
  },
});

const DEFAULT_STATE: ClioCommunicationLogFormState = {
  description: '',
  matterId: undefined,
  activityCategoryId: undefined,
  isBillable: true,
};

class ClioCommunicationLogForm extends Component<
  ClioCommunicationLogFormStateProps & ClioCommunicationLogFormDispatchProps & ClioCommunicationLogFormProps,
  ClioCommunicationLogFormState
> {
  activityCategoryPickerRef = React.createRef<HTMLElement>();
  descriptionInputRef = React.createRef<HTMLInputElement>();
  state = { ...DEFAULT_STATE };

  componentDidMount(): void {
    const { call, getSavedCommunication } = this.props;
    newTracker.trackAnalyticsEvent(trackingEvents.PAGE_LOADED);
    getSavedCommunication(call.id);
  }

  handleChange = (name: keyof ClioCommunicationLogFormState, value: any) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  close = () => {
    this.setState({ ...DEFAULT_STATE });
    this.props.onClose();
  };

  renderFormInstructions(): React.ReactNode {
    const { savedCommunication } = this.props;
    if (savedCommunication) {
      return (
        <>
          <Typography color="text-error-01">
            <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_ERROR} />
          </Typography>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_CALL_ALREADY_LOGGED_MESSAGE} />
            </b>
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography>
            <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_NOT_LOGGED_YET_MESSAGE} />
          </Typography>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_PLEASE_SELECT_MATTER_MESSAGE} />
            </b>
          </Typography>
        </>
      );
    }
  }

  render() {
    const { description, matterId, activityCategoryId, isBillable } = this.state;
    const { saveCommunication, call, savedCommunication } = this.props;

    return (
      <JiveForm
        onCancelClick={() => this.close()}
        onSaveClick={() => {
          saveCommunication({
            call,
            description,
            activityCategoryId,
            matterId,
            isBillable,
          });
          this.close();
        }}
        canSubmit={true}
        saveButtonDescriptor={
          savedCommunication ? definedMessages.COMMUNICATION_LOG_FORM_SAVE_AS_NEW_BUTTON_LABEL : undefined
        }
        saveTrackingEvent={trackingEvents.SAVE_CLICKED}
        cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
      >
        <Box mb={3}>
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_TITLE} />
          </Typography>
        </Box>
        {this.renderFormInstructions()}
        <Box mb={3} mt={2}>
          <MatterPicker
            autoFocus
            callId={call.id}
            value={this.state.matterId}
            trackingCategory={AnalyticsCategory.CommmunicationLog}
            onMatterSelected={(value) => {
              this.handleChange('matterId', value);
              setTimeout(() => {
                if (this.activityCategoryPickerRef.current) {
                  this.activityCategoryPickerRef.current.focus();
                }
              });
            }}
          />
        </Box>
        {this.state.matterId ? (
          <Box mb={3}>
            <ActivityCategoryPicker
              selectedActivityId={this.state.activityCategoryId}
              inputRef={this.activityCategoryPickerRef}
              trackingCategory={AnalyticsCategory.CommmunicationLog}
              onActivitySelected={(value) => {
                this.handleChange('activityCategoryId', value);
                if (this.descriptionInputRef.current) {
                  this.descriptionInputRef.current.focus();
                }
              }}
            />
          </Box>
        ) : null}
        <Box pl={3} mb={3}>
          <LabeledCheckbox
            offTrackingEvent={trackingEvents.BILLABLE_TIME_CLICKED_OFF}
            onTrackingEvent={trackingEvents.BILLABLE_TIME_CLICKED_ON}
            onChange={({ target: { checked } }) => this.handleChange('isBillable', checked)}
            checked={isBillable}
            name={'isBillable'}
            disabled={!matterId}
            labelPlacement="end"
            label={<FormattedMessage {...definedMessages.COMMUNICATION_LOG_FORM_BILLABLE_CHECKBOX_LABEL} />}
          />
        </Box>
        <TextArea
          name="communication-log-description"
          id="communication-log-description"
          rows={4}
          trackingEvent={trackingEvents.DESCRIPTION_CHANGED}
          inputRef={this.descriptionInputRef}
          labelDescription={definedMessages.COMMUNICATION_LOG_FORM_DESCRIPTION}
          helperText={definedMessages.COMMUNICATION_LOG_FORM_DESCRIPTION_HELPER_TEXT}
          value={description}
          onChange={({ target: { value } }) => this.handleChange('description', value)}
        />
      </JiveForm>
    );
  }
}

export default ClioCommunicationLogForm;
