import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { loadVehicles, loadVehiclesError, loadVehiclesSuccess } from './vehicles.actions';
import { CoxService } from '../cox.service';
import { logger } from '../../logging/logger';
import { Vehicle } from './vehicles.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';

const definedMessages = defineMessages({
  VEHICLE_LIST_LOAD_ERROR: {
    id: 'Cox.Vehicles.List.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading vehicles. Please try again later.',
  },
});

const trackingEvents = defineTrackingEvents({
  VEHICLE_LOAD_STARTED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Vehicles',
  },
  VEHICLE_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Vehicles',
  },
  VEHICLE_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Vehicles',
  },
});

export class VehiclesActionCreator {
  constructor(protected store: Store<AppState>) {}

  async loadVehicles(VINs: string[]): Promise<void> {
    this.store.dispatch(loadVehicles());
    newTracker.trackAnalyticsEvent(trackingEvents.VEHICLE_LOAD_STARTED);

    if (!VINs?.length) {
      this.store.dispatch(loadVehiclesSuccess([]));
      return;
    }

    try {
      const results = await Promise.allSettled(VINs.map((vin) => CoxService.getVehicle(vin)));
      const errors: any[] = [];
      const vehicles: Vehicle[] = [];

      results.forEach((result) => {
        if (result.status === 'rejected') {
          errors.push(result.reason);
        } else {
          vehicles.push(result.value);
        }
      });

      if (errors.length > 0) {
        logger.error('Some of the vehicles couldnt be loaded for cox', {
          errors,
        });

        if (!vehicles.length) {
          this.store.dispatch(
            loadVehiclesError({
              message: definedMessages.VEHICLE_LIST_LOAD_ERROR,
            }),
          );
          newTracker.trackAnalyticsEvent({
            ...trackingEvents.VEHICLE_LOAD_FAILED,
            label: 'Vehicles - failed to load vehicles',
          });
          return;
        }
      }

      this.store.dispatch(loadVehiclesSuccess(vehicles));
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.VEHICLE_LOAD_SUCCEEDED,
        label: `Vehicles | ${vehicles.length}`,
      });
    } catch (e) {
      logger.error('Error loading vehicles', e);
      this.store.dispatch(
        loadVehiclesError({
          message: definedMessages.VEHICLE_LIST_LOAD_ERROR,
        }),
      );
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.VEHICLE_LOAD_FAILED,
        label: 'Vehicles - failed to load vehicles',
      });
    }
  }
}
