import axios from 'axios';
import { logger } from '../logging';

import configuration from '../config';

export interface ProviderConfig {
  id: string;
  providerId: string;
  appId: string;
  appType: string;
  apiUrl: string;
  description: string;
  publicAuthKeys: any;
  secretAuthKeys: any;
}

export interface AmbassadorProvider {
  id: string;
  name: string;
  displayName: string;
  appId: string;
  apiUrl: string;
  version: number;
  description: string;
  imageUrl: string;
  publicAuthKeys: any;
  customAttributes: any;
  tenantId: string;
  config: ProviderConfig[];
}

export interface AmbassadorUserProvider {
  authHeaders: any;
  customAttributes: Record<string, string>;
  dateCreated: string;
  externalId?: string;
  id: string;
  providerUuid: string;
  status: 'ACTIVE' | 'DISABLED' | 'BLOCKED' | 'ABUSER' | 'PENDING_APPROVAL';
  tenantId: string;
  version: number;
}

export interface UpdateAccessTokenModel {
  linkId: string;
  refreshAccessTokenResponseBody: RefreshAccessTokenResponseBody;
}

export interface RefreshAccessTokenRequestBody {
  client_id: string;
  client_secret: string;
  grant_type: string;
  refresh_token: string;
}

export interface RefreshAccessTokenResponseBody {
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token?: string;
}

export interface AmbassadorUrlParameters {
  tenantId: string;
  providerId: string;
  userId: string;
}

async function getUserProviders(
  token: string,
  jiveUserId: string,
  tenantId: string,
): Promise<AmbassadorUserProvider[]> {
  if (!token || !tenantId || !jiveUserId) {
    throw new Error('Token, tenantId and jiveUserId should not be empty strings');
  }

  const url = `${configuration.ambassador.url}/lookup/tenantId/${tenantId}/userId/${jiveUserId}`;
  const response = await axios.get<AmbassadorUserProvider[]>(url, {
    headers: generateAmbassadorHeaders(token),
  });
  return response.data;
}

async function getProviders(token: string, tenantId: string): Promise<AmbassadorProvider[]> {
  if (!token || !tenantId) {
    throw new Error('Both token and tenantId should not be empty strings');
  }

  const url = `${configuration.ambassador.url}/${tenantId}/metadata/providers`;
  const response = await axios.get<{ items: AmbassadorProvider[] }>(url, { headers: generateAmbassadorHeaders(token) });
  return response.data.items;
}

async function deleteUserProvider(userProvider: AmbassadorUserProvider, token: string): Promise<void> {
  const { id } = userProvider;
  const url = `${configuration.ambassador.url}/link/${id}`;
  await axios.delete(url, { headers: generateAmbassadorHeaders(token) });
}

async function refreshProviderAccessToken(
  jiveToken: string,
  linkId: string,
  providerConfigAppId: string,
  refreshTokenModel: RefreshAccessTokenRequestBody,
): Promise<void> {
  const url = `${getProxyUrl(linkId)}/oauth/token?providerConfigAppId?=${providerConfigAppId}`;
  const formUrlEncodedBody = new URLSearchParams();
  Object.entries(refreshTokenModel).forEach(([key, value]) => formUrlEncodedBody.append(key, value));

  try {
    const response = await axios.post<{ content: string; responseCode: number; reasonPhrase: string }>(
      url,
      formUrlEncodedBody,
      {
        headers: { ...generateAmbassadorHeaders(jiveToken), 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    );
    const status = response.data.responseCode;
    const statusText = response.data.reasonPhrase;
    if (status >= 400) {
      logger.error('Could not refresh provider access_token', { status, statusText, body: response.data.content });
      return Promise.reject('Could not refresh provider access_token');
    }
    const refreshAccessTokenResponseBody = JSON.parse(response.data.content) as RefreshAccessTokenResponseBody;
    return await updateProviderAccessToken(jiveToken, { linkId, refreshAccessTokenResponseBody });
  } catch (e) {
    logger.error('Could not refresh provider access_token', e);
    return Promise.reject(e);
  }
}

async function updateProviderAccessToken(jiveToken: string, model: UpdateAccessTokenModel): Promise<void> {
  const { linkId, refreshAccessTokenResponseBody } = model;

  const payload = {
    authHeaders: {
      ...refreshAccessTokenResponseBody,
    },
  };

  const url = `${configuration.ambassador.url}/link/${linkId}`;
  await axios.patch(url, payload, { headers: generateAmbassadorHeaders(jiveToken) });
  return;
}

function generateAmbassadorHeaders(token: string, providerHeaders?: any): any {
  const headers = {
    'client-app-id': configuration.ambassador.clientAppId,
    Authorization: `Bearer ${token}`,
    'Provider-Accept-Encoding': 'gzip',
  };

  if (providerHeaders) {
    Object.keys(providerHeaders).forEach((key) => {
      headers[key] = providerHeaders[key];
    });
  }

  return headers;
}

function getProxyUrl(linkId: string) {
  return `${configuration.ambassador.url}/${linkId}/proxy/`;
}

export const AmbassadorService = {
  getUserProviders,
  getProviders,
  generateAmbassadorHeaders,
  getProxyUrl,
  refreshProviderAccessToken,
  deleteUserProvider,
};
