import React from 'react';
import { Accordion, AccordionSummary } from '../../components/Accordion.component';
import { ChevronDownIcon } from '@getgo/chameleon-icons/react';
import { ReactComponent as ServicesIcon } from '../../assets/dealertrack_services.svg';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage, defineMessages } from 'react-intl';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { VehiclesCapableFacade } from '../../actionFacade/action.facade.capabilities';
import { ServiceAppointments } from '../serviceAppointments/ServiceAppointments.container';
import { OpenRepairOrders } from '../repairOrders/OpenRepairOrders.container';
import { ClosedRepairOrders } from '../repairOrders/ClosedRepairOrders.container';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { Typography } from '../../theme/Typography.component';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  SERVICES_LIST_TITLE: {
    id: 'Cox.Services.List.Title',
    defaultMessage: 'Services',
  },
  SERVICE_APPOINTMENT_LIST_TITLE: {
    id: 'Cox.ServiceAppointments.List.Title',
    defaultMessage: 'Service appointments',
  },
  EMPTY_MESSAGE: {
    id: 'Cox.ServiceAppointments.List.Empty',
    defaultMessage: 'There is no service appointment for this vehicle.',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    servicesIcon: {
      color: tokens.interactive01,
      height: 20,
      width: 20,
      marginRight: theme.spacing(2),
    },
    expandIcon: {
      color: tokens.interactive01,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    showButton: {
      width: '100%',
      fontSize: '14px',
      padding: theme.spacing(1, 2),
    },
  }),
);

export interface ServicesListProps {
  VIN: string;
  isExpandedDefault: boolean;
}

export const ServiceListsComponent: React.FC<ServicesListProps> = ({ VIN, isExpandedDefault }) => {
  const classes = useStyles();

  const trackChange = (isExpanded: boolean) => {
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Services,
      action: AnalyticsAction.ItemClicked,
      label: `Services section | accordion | ${isExpanded ? 'OPENED' : 'CLOSED'}`,
    });
  };

  return (
    <Accordion
      onCollapse={() => trackChange(false)}
      summary={
        <AccordionSummary
          expandIcon={<ChevronDownIcon className={classes.expandIcon} />}
          aria-controls="panel-service-appointments-content"
          id="panel-service-appointments-header"
        >
          <ServicesIcon className={classes.servicesIcon} />
          <Typography variant="body-small">
            <b>
              <FormattedMessage {...definedMessages.SERVICES_LIST_TITLE} />
            </b>
          </Typography>
        </AccordionSummary>
      }
      isExpandedDefault={isExpandedDefault}
      onExpand={() => {
        trackChange(true);
        void getActionFacade<VehiclesCapableFacade>().loadServiceAppointmentsForVehicle(VIN);
      }}
      body={
        <div className={classes.container}>
          <Box mb={4}>
            <OpenRepairOrders VIN={VIN} />
          </Box>
          <Box mb={4}>
            <ServiceAppointments VIN={VIN} />
          </Box>
          <Box mb={4}>
            <ClosedRepairOrders VIN={VIN} />
          </Box>
        </div>
      }
    />
  );
};
