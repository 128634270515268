import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { ClioContactActionCreator } from './contact/clio.contact.actioncreator';
import { ClioNoteActionCreator } from './note/clio.note.actioncreator';
import { ClioActionCreator } from './clio.actioncreator';
import { ClioStartupActionCreator } from './clio.startup.actioncreator';
import { DummyConversationCapability } from './dummyConversationCapability';
import { ConversationsActionCreator } from '../conversations/conversations.actioncreator';

export default class ClioActionCreatorFactory extends ActionCreatorFactory {
  public createStartupActionCreator(): ClioStartupActionCreator {
    return new ClioStartupActionCreator(this.store, new DummyConversationCapability());
  }

  public createContactActionCreator(): ContactActionCreator {
    return new ClioContactActionCreator(this.store);
  }

  public createNoteActionCreator(): ClioNoteActionCreator {
    return new ClioNoteActionCreator(this.store);
  }

  public createClioActionCreator(): ClioActionCreator {
    return new ClioActionCreator(this.store);
  }

  public createConversationsActionCreator(): ConversationsActionCreator {
    return new ConversationsActionCreator(this.store);
  }
}
