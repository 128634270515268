import { SaleforceAnalyticsActionTypes, SalesforceAnalyticsActions } from './salesforceAnalytics.action';
import { SalesforceActions, SalesforceActionType } from './salesforce.actions';
import {
  CallDispositionValue,
  SalesforceCallLog,
  SalesforceCallLogWithNames,
  TaskRelatedObjectPrefixes,
  TaskRelatedThemeItems,
} from '../../../salesforce-shared/salesforce-service.models';
import { combineReducers } from 'redux';
import { opportunitiesListReducer } from './opportunities/opportunity.reducers';
import { casesListReducer } from './cases/case.reducers';
import { EntityType } from '../constants';

export interface SalesforceState {
  isCallDispositionConfigured: boolean;
  callDispositionValues?: CallDispositionValue[];
  savedCallLogs: {
    [callId: string]: { callLog?: SalesforceCallLogWithNames; loading: boolean };
  };
  savedTaskRecordIds: {
    [callId: string]: string;
  };
  isRunningInConsoleMode: boolean;
  initError?: string;
  storedCallLogs: {
    [callId: string]: SalesforceCallLog;
  };
  lastCallLogError: any;
  missingObjectAccessRights: EntityType[];
  taskRelationsLoading: boolean;
  taskWhatIdObjectNames: string[];
  taskWhoIdObjectNames: string[];
  taskRelatedThemeItems: TaskRelatedThemeItems;
  taskRelatedObjectPrefixes: TaskRelatedObjectPrefixes;
  whoObjects: Array<{ id: string; name: string }>;
  whoObjectsLoading: boolean;
  whatObjects: Array<{ id: string; name: string }>;
  whatObjectsLoading: boolean;
}

const INITIAL_STATE: SalesforceState = {
  isCallDispositionConfigured: false,
  callDispositionValues: undefined,
  savedCallLogs: {},
  savedTaskRecordIds: {},
  isRunningInConsoleMode: false,
  initError: undefined,
  storedCallLogs: {},
  lastCallLogError: undefined,
  missingObjectAccessRights: [],
  taskRelationsLoading: false,
  taskWhatIdObjectNames: [],
  taskWhoIdObjectNames: [],
  whatObjects: [],
  whatObjectsLoading: false,
  whoObjects: [],
  whoObjectsLoading: false,
  taskRelatedThemeItems: {},
  taskRelatedObjectPrefixes: {},
};

export const salesforceCommonReducer = (
  state: SalesforceState = INITIAL_STATE,
  action: SalesforceAnalyticsActions | SalesforceActions,
): SalesforceState => {
  switch (action.type) {
    case SaleforceAnalyticsActionTypes.USER_INFORMATION_FOUND: {
      return {
        ...state,
        isCallDispositionConfigured:
          action.payload.find((userInfo) => userInfo.isCallDispositionConfigured) !== undefined,
        isRunningInConsoleMode: action.payload.find((userInfo) => userInfo.isRunningInConsoleMode) !== undefined,
        missingObjectAccessRights: action.payload[0].missingObjectAccessRights.map(
          // The .toUpperCase "hack" is needed because for example we get the string "Account" from SF
          // but the EntityType enum's key is "ACCOUNT"
          (objectName) => EntityType[objectName.toUpperCase()] as EntityType,
        ),
      };
    }
    case SalesforceActionType.UPDATE_CALL_DISPOSITION_VALUES: {
      return {
        ...state,
        callDispositionValues: action.payload,
      };
    }
    case SalesforceActionType.LOAD_SAVED_CALL_LOG: {
      return {
        ...state,
        savedCallLogs: {
          ...state.savedCallLogs,
          [action.payload.callId]: { callLog: undefined, loading: true },
        },
      };
    }
    case SalesforceActionType.LOAD_SAVED_CALL_LOG_ERROR: {
      return {
        ...state,
        savedCallLogs: {
          ...state.savedCallLogs,
          [action.payload.callId]: { callLog: undefined, loading: false },
        },
      };
    }
    case SalesforceActionType.LOAD_SAVED_CALL_LOG_SUCCESS: {
      return {
        ...state,
        savedCallLogs: {
          ...state.savedCallLogs,
          [action.payload.callId]: { callLog: action.payload.callLog, loading: false },
        },
      };
    }
    case SalesforceActionType.CALL_LOG_SAVED: {
      const { callId, taskRecordId } = action.payload;
      return {
        ...state,
        savedTaskRecordIds: {
          ...state.savedTaskRecordIds,
          [callId]: taskRecordId,
        },
      };
    }
    case SalesforceActionType.CALL_LOG_ERROR: {
      return {
        ...state,
        lastCallLogError: action.payload.error,
      };
    }
    case SalesforceActionType.CALL_LOG_STARTED:
    case SalesforceActionType.CALL_LOG_UPDATE_STARTED: {
      return {
        ...state,
        lastCallLogError: undefined,
      };
    }
    case SalesforceActionType.INIT_ERROR: {
      return {
        ...state,
        initError: action.payload.error,
      };
    }
    case SalesforceActionType.STORE_CALL_LOG: {
      return {
        ...state,
        storedCallLogs: {
          [action.payload.callId]: action.payload.callLog,
        },
      };
    }
    case SalesforceActionType.REMOVE_STORED_CALL_LOG: {
      const { [action.payload.callId]: _keyToRemove, ...newStoredCallLogs } = state.storedCallLogs;

      return {
        ...state,
        storedCallLogs: newStoredCallLogs,
      };
    }
    case SalesforceActionType.GET_TASK_RELATIONS_REQUEST: {
      return {
        ...state,
        taskRelationsLoading: true,
      };
    }
    case SalesforceActionType.GET_TASK_RELATIONS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        taskRelationsLoading: false,
      };
    }
    case SalesforceActionType.GET_TASK_RELATIONS_ERROR: {
      return {
        ...state,
        taskRelationsLoading: false,
        // TODO error state?
      };
    }
    case SalesforceActionType.SEARCH_WHO_OBJECTS_REQUEST: {
      return {
        ...state,
        whoObjects: [],
        whoObjectsLoading: true,
      };
    }
    case SalesforceActionType.SEARCH_WHO_OBJECTS_SUCCESS: {
      return {
        ...state,
        whoObjects: action.payload,
        whoObjectsLoading: false,
      };
    }
    case SalesforceActionType.SEARCH_WHO_OBJECTS_ERROR: {
      return {
        ...state,
        whoObjects: [],
        whoObjectsLoading: false,
        // TODO error state?
      };
    }
    case SalesforceActionType.SEARCH_WHAT_OBJECTS_REQUEST: {
      return {
        ...state,
        whatObjects: [],
        whatObjectsLoading: true,
      };
    }
    case SalesforceActionType.SEARCH_WHAT_OBJECTS_SUCCESS: {
      return {
        ...state,
        whatObjects: action.payload,
        whatObjectsLoading: false,
      };
    }
    case SalesforceActionType.SEARCH_WHAT_OBJECTS_ERROR: {
      return {
        ...state,
        whatObjects: [],
        whatObjectsLoading: false,
        // TODO error state?
      };
    }
    case SalesforceActionType.CLEAR_WHAT_OBJECTS: {
      return {
        ...state,
        whatObjects: [],
        whatObjectsLoading: false,
      };
    }
    case SalesforceActionType.CLEAR_WHO_OBJECTS: {
      return {
        ...state,
        whoObjects: [],
        whoObjectsLoading: false,
      };
    }
  }

  return state;
};

export const salesforceReducer = combineReducers({
  common: salesforceCommonReducer,
  opportunities: opportunitiesListReducer,
  cases: casesListReducer,
});

export default salesforceReducer;
