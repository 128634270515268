import { Button } from '@getgo/chameleon-material-ui';
import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { restartApp } from '../connectivity/connectivity.action';

const definedMessages = defineMessages({
  ERROR_MESSAGE_GENERAL_RESTART: {
    id: 'Error.Page.General.RestartButton',
    defaultMessage: 'Restart',
  },
});

export interface RestartButtonProps {
  children?: React.ReactNode;
}

export const RestartButton: React.FC<RestartButtonProps & ButtonProps> = ({ children, ...props }) => {
  const dispatch = useDispatch();

  const restart = () => {
    dispatch(restartApp());
  };

  return (
    <Button fullWidth variant="contained" color="primary" onClick={restart} {...props}>
      {children || <FormattedMessage {...definedMessages.ERROR_MESSAGE_GENERAL_RESTART} />}
    </Button>
  );
};
