import { ConnectError, ConnectHttpClient, ConnectRequestOptionsArgs, HttpResponseData } from '@ucc/common';
import { Observable, from } from 'rxjs';
import axios, { AxiosRequestConfig } from 'axios';
import { HttpMethod } from '../settings/httpClient';

export class AxiosConnectHttpClient implements ConnectHttpClient {
  get<T = any, E = ConnectError>(url: string, options?: ConnectRequestOptionsArgs): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.GET, undefined, options);
  }
  head<T = any, E = ConnectError>(
    url: string,
    options?: ConnectRequestOptionsArgs,
  ): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.HEAD, undefined, options);
  }
  post<T = any, E = ConnectError>(
    url: string,
    body: any,
    options?: ConnectRequestOptionsArgs,
  ): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.POST, body, options);
  }
  put<T = any, E = ConnectError>(
    url: string,
    body: any,
    options?: ConnectRequestOptionsArgs,
  ): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.PUT, body, options);
  }
  patch<T = any, E = ConnectError>(
    url: string,
    body: any,
    options?: ConnectRequestOptionsArgs,
  ): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.PATCH, body, options);
  }
  delete<T = any, E = ConnectError>(
    url: string,
    options?: ConnectRequestOptionsArgs,
  ): Observable<HttpResponseData<T, E>> {
    return createRequest(url, HttpMethod.DELETE, undefined, options);
  }
}

function createRequest<T = any, E = ConnectError>(
  url: string,
  method: HttpMethod.HEAD | HttpMethod.DELETE | HttpMethod.POST | HttpMethod.PATCH | HttpMethod.GET | HttpMethod.PUT,
  data?: any,
  options?: any,
): Observable<HttpResponseData<T, E>> {
  const headers = options ? options.headers : {};
  let config: AxiosRequestConfig = { method, url, headers, ...options };
  if (data !== null) {
    config = Object.assign({}, config, { data });
  }

  return from(axios(config));
}

export const axiosConnectHttpClient = new AxiosConnectHttpClient();
