import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../../actions/actionCreators';
import { Communication } from '../clio.models';

export enum SavedCommunicationActionTypes {
  GET_SAVED_COMMUNICATION = 'clio/saved-communication/get',
  GET_SAVED_COMMUNICATION_SUCCESS = 'clio/saved-communication/get/success',
  GET_SAVED_COMMUNICATION_ERROR = 'clio/saved-communication/get/error',
}

export interface GetSaveCommunicationPayload {
  callId: string;
}

export interface GetSaveCommunicationSuccessPayload {
  callId: string;
  communication?: Communication;
}

export interface GetSaveCommunicationErrorPayload {
  message: MessageDescriptor;
  callId: string;
  userId: number;
}

export const getSavedCommunication = makeActionCreator(SavedCommunicationActionTypes.GET_SAVED_COMMUNICATION)<
  GetSaveCommunicationPayload
>();
export const getSavedCommunicationSuccess = makeActionCreator(
  SavedCommunicationActionTypes.GET_SAVED_COMMUNICATION_SUCCESS,
)<GetSaveCommunicationSuccessPayload>();
export const getSavedCommunicationError = makeActionCreator(
  SavedCommunicationActionTypes.GET_SAVED_COMMUNICATION_ERROR,
)<GetSaveCommunicationErrorPayload>();

export type SavedCommunicationActions =
  | ReturnType<typeof getSavedCommunication>
  | ReturnType<typeof getSavedCommunicationSuccess>
  | ReturnType<typeof getSavedCommunicationError>;
