import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InAppNotificationVariantType } from '../constants';
import { Integrations, MessageVariant } from '../models';
import { definedMessages } from './message.content';
import { InAppNotification } from './InAppNotification.component';
import { Button } from '@getgo/chameleon-material-ui';
import { NotificationPermissionsSnackbarComponent } from '../notificationPermission/NotificationPermissionsSnackbar.component';
import { useDispatch, useSelector } from 'react-redux';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { deleteMessage } from './message.action';
import { restartApp } from '../connectivity/connectivity.action';
import { allMessagesSelector } from './message.selector';
import { isRealtimeReconnectingSelector } from '../connectivity/connectivity.selector';
import { MicrophonePermissionsSnackbarComponent } from '../softphone/MicrophonePermissionsSnackbar.component';
import { isSoftphoneCapableAndEnabledSelector } from '../settings/settings.selector';

export const useStyles = makeStyles(() =>
  createStyles({
    notificationsContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1,
    },
    notificationWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const InAppNotifications: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const integration = useSelector(currentIntegrationSelector);
  const messages = useSelector(allMessagesSelector);
  const isRealtimeReconnecting = useSelector(isRealtimeReconnectingSelector);
  const isSoftphoneEnabled = useSelector(isSoftphoneCapableAndEnabledSelector);

  const onClose = (messageId: string) => dispatch(deleteMessage(messageId));

  const renderConnectionLostSnackBarComponent = () => {
    return (
      <InAppNotification
        message={{
          id: 'REALTIME_API_CONNECTION_LOST_MESSAGE',
          type: InAppNotificationVariantType.ERROR,
          message: {
            id: definedMessages.REALTIME_API_CONNECTION_LOST_ATTEMPTING.id,
            defaultMessage: definedMessages.REALTIME_API_CONNECTION_LOST_ATTEMPTING.defaultMessage,
            values: {
              connectionLostMessage: (
                <div>
                  <strong>{definedMessages.REALTIME_API_CONNECTION_LOST.defaultMessage}</strong>
                </div>
              ),
            },
          },
          params: {
            autoHide: false,
            dismissible: false,
            actions: (
              <Button variant="outlined" size="small" inverse onClick={() => dispatch(restartApp())}>
                <FormattedMessage {...definedMessages.REALTIME_API_CONNECTION_LOST_BUTTON_LABEL} />
              </Button>
            ),
          },
        }}
        close={() => onClose('REALTIME_API_CONNECTION_LOST_MESSAGE')}
      />
    );
  };

  const screenPop = messages.find((m) => m.type === MessageVariant.CallInfo);

  if (screenPop) {
    return (
      <div className={classes.notificationsContainer} data-cy="inapp-notifications">
        <div className={classes.notificationWrapper}>
          <InAppNotification message={screenPop} close={() => onClose(screenPop.id)} />
        </div>
      </div>
    );
  }

  const lastMessage = messages ? messages[messages.length - 1] : undefined;

  return (
    <div className={classes.notificationsContainer} data-cy="inapp-notifications">
      {integration === Integrations.MsTeams ? null : <NotificationPermissionsSnackbarComponent />}
      {isSoftphoneEnabled && <MicrophonePermissionsSnackbarComponent />}
      {isRealtimeReconnecting ? renderConnectionLostSnackBarComponent() : null}
      {lastMessage ? (
        <div className={classes.notificationWrapper}>
          <InAppNotification message={lastMessage} close={() => onClose(lastMessage.id)} />
        </div>
      ) : null}
    </div>
  );
};
