import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AddNoteModel } from '../../actionFacade/actionCreators/note/note.actioncreator.models';
import { AppState } from '../../reducers';
import { GetMoreNotesRequestMeta, GetNotesSuccessPayload } from '../../notes/notes.actions';
import { redtailService } from '../redtail.service';
import { Note } from '../../notes/notes.models';
import { redtailNoteSelector } from './redtail.note.selector';
import { getRedtailNotesSuccess } from './redtail.note.action';
import { sanitizeRedtailNote, convertRedtailDateToIso8601String } from './redtail.note.utils';
import { RedtailAddNoteModel } from './redtail.addNote.actioncreator.model';
import { selectedDateFnsLocaleSelector, injectedIntlSelector } from '../../settings/settings.selector';
import { getCallDetails } from '../../calls/callLogger.utilities';
import { AsyncAddNoteActionCreator } from '../../actionFacade/actionCreators/note/async.note.actioncreator';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { callHistoryElementByCallSelector } from '../../callHistoryPush/unifiedCallHistory.selector';

export const definedMessages = defineMessages({
  REDTAIL_NOTE_CATEGORIES_GET_ERROR: {
    id: 'Redtail.Note.Categories.Get.Error',
    defaultMessage: 'There was an error while trying to download Redtail categories, please try again.',
  },
  CALL_DETAILS_HEADER: {
    id: 'CallDetails.Header',
    defaultMessage: 'Call Details',
  },
});

export class RedtailNoteActionCreator extends AsyncAddNoteActionCreator {
  constructor(protected store: Store<AppState>) {
    super(store);
  }

  protected async _loadMostRecentNoteOfContact(contactId: string): Promise<GetNotesSuccessPayload> {
    const notes = await redtailService.getNotesOfContact(contactId);

    const notesToDisplay = !notes
      ? []
      : notes
          .filter((note) => !note.Deleted)
          .map((note) => {
            return {
              id: String(note.RecID),
              subject: note.Category,
              content: sanitizeRedtailNote(note.Note),
              createdAtIso8601: convertRedtailDateToIso8601String(note.RecAdd),
            };
          });

    if (notesToDisplay.length > 1) {
      this.store.dispatch(getRedtailNotesSuccess({ notes: notesToDisplay }));
    }

    return this.createGetNotesSuccessPayload(contactId, 0, notesToDisplay);
  }

  protected async _loadMoreNotesOfContact(moreNotesMeta: GetMoreNotesRequestMeta): Promise<GetNotesSuccessPayload> {
    const notes = redtailNoteSelector(this.store.getState());
    const nextPage = moreNotesMeta.next ? Number(moreNotesMeta.next) : 0;
    return this.createGetNotesSuccessPayload(moreNotesMeta.contactId ?? '', nextPage, notes);
  }

  protected async _addNote(notePayload: AddNoteModel): Promise<Optional<{ noteId: string }>> {
    const addRedtailNotePayload = notePayload as RedtailAddNoteModel;
    const { contactId, categoryId, content, includeCallDetails, call } = addRedtailNotePayload;
    const callInHistory = callHistoryElementByCallSelector(call)(this.store.getState());

    if (includeCallDetails && !callInHistory) {
      return this._addNoteWhenTheCallEnds(call, addRedtailNotePayload);
    }

    let note: string;
    if (includeCallDetails && callInHistory) {
      const intl = injectedIntlSelector(this.store.getState());

      const locale = selectedDateFnsLocaleSelector(this.store.getState());
      const callDetailsString = getCallDetails({ call: callInHistory, intl, locale, lineBreak: '<br/>' });
      const formattedCallDetails = `<br/><br/><strong>${intl.formatMessage(
        definedMessages.CALL_DETAILS_HEADER,
      )}</strong><br/>${callDetailsString}`;

      note = `${content}${formattedCallDetails}`;
    } else {
      note = content;
    }

    const { noteId } = await redtailService.addNote(contactId, {
      Note: note,
      CategoryID: categoryId,
    });

    if (includeCallDetails) {
      newTracker.trackAnalyticsEvent({
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.CallLogCreationSucceeded,
        label: '-',
      });
    }

    return { noteId };
  }

  private createGetNotesSuccessPayload(contactId: string, page: number, notes: Note[]): GetNotesSuccessPayload {
    if (notes.length === 0) {
      return {
        meta: {
          notesCount: 0,
          contactId,
          next: undefined,
        },
        notes: [],
      };
    }

    if (page === 0) {
      return {
        meta: {
          notesCount: notes.length - 1,
          contactId,
          next: notes.length === 1 ? undefined : '1', // next 'page'
        },
        notes: [notes[0]],
      };
    }

    const startIndex = page * 2 - 1;
    const endIndex = startIndex + 2;
    const noteSlice = notes.slice(startIndex, endIndex);

    return {
      meta: {
        notesCount: 2,
        contactId,
        next: endIndex >= notes.length ? undefined : String(page + 1),
      },
      notes: noteSlice,
    };
  }
}
