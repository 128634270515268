import React from 'react';
import { CallEventsActionsType, startOutgoingCall } from '@jive/realtime-events';
import { Dispatch, Store } from 'redux';
import { addSmartListener } from '../../middlewares/listener.middleware';
import {
  startTestCallAction,
  testCallFailedAction,
  TestCallOptions,
  testCallSucceededAction,
} from './testCall.actions';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { deleteMessage, showMessage } from '../../inAppNotification/message.action';
import { MessageVariant } from '../../models';
import { TestCallError } from './TestCallError.component';
import { CLICK_TO_CALL_TIMEOUT, initiateCall } from '../../calls/click-to-call.service';
import { LineOrganization } from '../../settings/organizations.model';
import { defineMessages } from 'react-intl';
import { AppState } from '../../reducers';
import { selectedLineSelector } from '../../settings/settings.selector';
import { isTestCallPendingSelector, testCallOptionsSelector } from './testCall.selectors';
import { logger } from '../../logging';

const definedMessages = defineMessages({
  TEST_CALL_SUCCESS_MESSAGE: {
    id: 'Onboarding.TestCall.SuccessMessage',
    defaultMessage: 'Test call was successful.',
  },
});

const trackingEvents = defineTrackingEvents({
  TEST_CALL_SUCCEEDED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.TestCallSucceeded,
    label: '-',
  },
  TEST_CALL_FAILED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.TestCallFailed,
    label: '-',
  },
});

const testCallFailureNotificationId = 'testCallFailure';

let isSubscribed = false;
let timeoutId: number;

function outgoingCallHandler(store: Store<AppState>, { payload }: ReturnType<typeof startOutgoingCall>) {
  const state = store.getState();
  const selectedLine = selectedLineSelector(state);
  const isTestCallPending = isTestCallPendingSelector(state);
  const options = testCallOptionsSelector(state);

  if (!isTestCallPending || payload.call.theOtherParty.number !== selectedLine.number) {
    return;
  }

  store.dispatch(testCallSucceededAction(payload.call));
  store.dispatch(deleteMessage(testCallFailureNotificationId));
  if (options?.showBannerOnSuccess) {
    store.dispatch(
      showMessage({
        id: 'testCallSucceeded',
        type: 'success',
        params: {
          autoHide: true,
        },
        message: definedMessages.TEST_CALL_SUCCESS_MESSAGE,
      }),
    );
  }

  window.clearTimeout(timeoutId);

  newTracker.trackAnalyticsEvent(trackingEvents.TEST_CALL_SUCCEEDED);
}

export async function makeTestCall(
  dispatch: Dispatch<any>,
  line: LineOrganization,
  options: TestCallOptions,
): Promise<void> {
  dispatch(startTestCallAction(options));

  if (!isSubscribed) {
    isSubscribed = true;
    addSmartListener(CallEventsActionsType.START_OUTGOING_CALL)(outgoingCallHandler);
  }

  try {
    await initiateCall(line.number, line.id);

    timeoutId = window.setTimeout(() => {
      dispatch(testCallFailedAction());
      dispatch(
        showMessage({
          id: testCallFailureNotificationId,
          type: MessageVariant.Custom,
          params: {
            autoHide: false,
            customContent: <TestCallError />,
          },
        }),
      );

      newTracker.trackAnalyticsEvent({ ...trackingEvents.TEST_CALL_FAILED, label: 'No incoming call in 5 seconds' });
    }, CLICK_TO_CALL_TIMEOUT);
  } catch (error) {
    dispatch(testCallFailedAction());
    dispatch(
      showMessage({
        id: testCallFailureNotificationId,
        type: MessageVariant.Custom,
        params: {
          autoHide: false,
          customContent: <TestCallError />,
        },
      }),
    );
    logger.error('Failed to start click-to-call test call.', error);
    newTracker.trackAnalyticsEvent({ ...trackingEvents.TEST_CALL_FAILED, label: 'Failed to call click-to-call API.' });
  }
}
