import { makeStyles } from '@material-ui/core';
import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'space-between',
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
  },
});

export interface BodyLayoutProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  classes?: Partial<ClassNameMap<'container' | 'header' | 'body' | 'footer'>>;
}

export const BodyLayout: React.FC<BodyLayoutProps> = ({ header, body, footer, classes }) => {
  const ownClasses = useStyles();
  return (
    <div className={classNames(ownClasses.container, classes?.container)}>
      <div className={classes?.header}>{header}</div>
      <div className={classNames(ownClasses.body, classes?.body)} data-cy="scrollContainer">
        {body}
      </div>
      {footer && <div className={classes?.footer}>{footer}</div>}
    </div>
  );
};
