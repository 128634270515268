import { makeActionCreator } from '../actions/actionCreators';
import { Integrations } from '../models';

export enum IntegrationsActionsType {
  SET_CURRENT = 'integrations/setCurrent',
  INTEGRATION_UNINITIALIZED = 'integrations/uninitialized',
  INTEGRATION_STARTING = 'integrations/starting',
  INTEGRATION_STARTED = 'integrations/started',
}

export enum StartupState {
  Uninitialized = 'uninitialized',
  Starting = 'starting',
  Started = 'started',
}

export interface IntegrationsState {
  current: Integrations;
  startupState: StartupState;
}

export const setCurrentIntegration = makeActionCreator(IntegrationsActionsType.SET_CURRENT)<Integrations>();
export const integrationUninitialized = makeActionCreator(IntegrationsActionsType.INTEGRATION_UNINITIALIZED)();
export const integrationStarting = makeActionCreator(IntegrationsActionsType.INTEGRATION_STARTING)();
export const integrationStarted = makeActionCreator(IntegrationsActionsType.INTEGRATION_STARTED)();

export type IntegrationsActions =
  | ReturnType<typeof setCurrentIntegration>
  | ReturnType<typeof integrationUninitialized>
  | ReturnType<typeof integrationStarting>
  | ReturnType<typeof integrationStarted>;
