import ReactGA from 'react-ga';
import { externalUserKeySelector } from '../authentication/authentication.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { createAnalyticsPayloadFromAction } from './googleanalytics.service';
import { GoogleAnalyticsPayload, AnalyticsWhiteListEvents, Tracker } from './tracker.models';

const UNKNOWN_USER = 'Unknown user';
const UNKNOWN_PBX = 'Unknown PBX';

class GoogleAnalyticsTracker implements Tracker {
  private userId: string;
  private getState?: () => AppState;

  constructor() {
    this.userId = '';
    this.getState = undefined;
  }

  initialize(getState: () => AppState): void {
    this.getState = getState;
  }

  trackPageView(location: string): void {
    ReactGA.pageview(location);
  }

  trackEvent(eventLabel: string, eventAction: string, payload?: string): void {
    try {
      const { userId, organizationId, integration } = this.getTrackingMetadata();

      // in case user sign-out and -in again with another user
      if (this.userId !== userId) {
        this.userId = userId;
        ReactGA.set({ userId });
      }

      const analyticsPayload: GoogleAnalyticsPayload | null = createAnalyticsPayloadFromAction(
        eventLabel,
        eventAction,
        organizationId,
        integration || '',
        this.userId,
        payload || '',
      );

      if (!analyticsPayload) {
        logger.error('Could not construct the Google Analytics payload!', {
          actionLabel: eventLabel,
          actionType: eventAction,
          validOrganizationId: organizationId,
          integration,
          userId: this.userId,
          payload,
        });
        return;
      }

      ReactGA.event(analyticsPayload);
    } catch (e) {
      logger.error('Error tracking event', e);
    }
  }

  isEventTracked(actionType: string): boolean {
    if (!AnalyticsWhiteListEvents.find((i) => i === actionType)) {
      return false;
    }

    return true;
  }

  private getTrackingMetadata() {
    if (!this.getState) {
      throw Error('tracker initialized with no state getter');
    }

    const state = this.getState();

    // Ensure that we have an organization ID even for actions that occur before the user's lines are downloaded.
    // Ensure that we have a userID even if the user is not logged in yet.
    return {
      userId: externalUserKeySelector(state) || UNKNOWN_USER,
      organizationId: selectedOrganizationIdSelector(state) || UNKNOWN_PBX,
      integration: currentIntegrationSelector(state),
    };
  }
}

export const tracker: Tracker = new GoogleAnalyticsTracker();
