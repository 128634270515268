import { addListener, addListeners } from '../middlewares/listener.middleware';
import { setPhoneSelectedEntity } from '../phone/phone.actions';
import { EntitiesActionsType, openEntity } from '../search/entities.action';

addListener(EntitiesActionsType.CREATE_CONTACT_SUCCESS)((_, action) => {
  return setPhoneSelectedEntity({ entityId: action.payload.entity.id });
});

addListeners([EntitiesActionsType.CREATE_CONTACT_SUCCESS, EntitiesActionsType.CREATE_ENTITY_SUCCESS])((_, action) => {
  return openEntity({ entity: action.payload.entity });
});
