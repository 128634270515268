import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { GetCompaniesRequestState } from './companyPicker.models';
import { Company } from '../connectwise.model';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { ConnectWiseActionFacade } from '../connectwise.action.facade';
import { definedMessages as commonDefinedMessages } from '../../inAppNotification/message.content';
import { Autocomplete } from '@getgo/chameleon-material-ui';

export interface CompanyPickerProps {
  selectedCompanyId: number | null;
  onCompanySelected: (companyId?: number) => void;
  autoFocus?: boolean;
}

export interface CompanyPickerStateProps {
  companies: Company[];
  requestState: GetCompaniesRequestState;
}

const definedMessages = defineMessages({
  COMPANY_PICKER_LOADING_MESSAGE: {
    id: 'CompanyPicker.Loading',
    defaultMessage: 'Loading...',
  },
  COMPANY_PICKER_EMPTY_MESSAGE: {
    id: 'CompanyPicker.Empty',
    defaultMessage: 'No companies found',
  },
  COMPANY_PICKER_COMPANY: {
    id: 'CompanyPicker.Company',
    defaultMessage: 'Company',
  },
});

export const CompanyPickerComponent: React.FC<CompanyPickerStateProps & CompanyPickerProps> = ({
  requestState,
  companies,
  autoFocus,
  selectedCompanyId,
  onCompanySelected,
}) => {
  const intl = useIntl();

  useEffect(() => {
    void getActionFacade<ConnectWiseActionFacade>().getCompanies();

    return () => {
      void getActionFacade<ConnectWiseActionFacade>().clearCompanies();
    };
  }, []);

  const isLoading = requestState === GetCompaniesRequestState.LOADING;

  return (
    <Autocomplete
      options={companies}
      getOptionSelected={(company, selected) => company.id === selected.id}
      value={companies.find((m) => m.id === selectedCompanyId) ?? null}
      openOnFocus
      getOptionLabel={(company) => company.name}
      loading={isLoading}
      onChange={(_, company) => {
        onCompanySelected(company?.id);
      }}
      loadingText={<FormattedMessage {...definedMessages.COMPANY_PICKER_LOADING_MESSAGE} />}
      noOptionsText={<FormattedMessage {...definedMessages.COMPANY_PICKER_EMPTY_MESSAGE} />}
      TextFieldProps={{
        autoFocus,
        noMargin: true,
        placeholder: intl.formatMessage(commonDefinedMessages.FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER),
        label: intl.formatMessage(definedMessages.COMPANY_PICKER_COMPANY),
      }}
    />
  );
};
