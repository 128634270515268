import { connect } from 'react-redux';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { callSelector, getEntityInCallSelector } from '../../calls/calls.selector';
import { AppState } from '../../reducers';
import { RedtailActionFacade } from '../redtail.action.facade';
import { RedtailActivityAddModel } from '../redtail.model';
import { isRedtailActivitySavingSelector } from './redtail.activity.selectors';
import RedtailActivityForm, {
  RedtailActivityFormDispatchProps,
  RedtailActivityFormStateProps,
  RedtailActivityFormProps,
} from './RedtailActivityForm.component';

const mapStateToProps = (state: AppState, { callId }: RedtailActivityFormProps): RedtailActivityFormStateProps => {
  const activeCall = callSelector(state, callId);
  return {
    contactId: getEntityInCallSelector(state, callId)?.id,
    isActivityAddLoading: isRedtailActivitySavingSelector(state),
    isActiveCall: Boolean(activeCall && !activeCall.endTime),
  };
};

const mapDispatchToProps = (): RedtailActivityFormDispatchProps => ({
  addActivity: (activity: RedtailActivityAddModel) => getActionFacade<RedtailActionFacade>().addActivity(activity),
});

export default connect<
  RedtailActivityFormStateProps,
  RedtailActivityFormDispatchProps,
  RedtailActivityFormProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(RedtailActivityForm);
