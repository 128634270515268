import React from 'react';
import { CallActionRoutes, EntityType, ApplicationRoute, PARAM_CALLID, CallActionType } from '../constants';
import NewContactForm from '../entityForms/NewContactForm.container';
import { Integrations } from '../models';
import NewRedtailNoteForm from '../redtail/note/NewRedtailNoteForm.container';
import NewConnectWiseNoteForm from '../connectwise/note/NewConnectWiseNoteForm.container';
import NewNoteForm from '../entityForms/NewNoteForm.container';
import RedtailActivityForm from '../redtail/activity/RedtailActivityForm.container';
import ClioCommunicationLogForm from '../clio/communication/CommunicationLogForm.container';
import ServiceTicketFormComponent from '../connectwise/serviceTicket/ServiceTicketForm.container';
import { withRouter, RouteComponentProps } from 'react-router';
import { goToRouteWithParams } from '../browserHistory';
import { CallLogForm } from '../salesforce/callLog/CallLogForm.container';
import NewLeadForm from '../entityForms/NewLeadForm.component';
import NewOpportunityForm from '../entityForms/NewOpportunityForm.component';
import NewCaseForm from '../entityForms/NewCaseForm.component';

export interface ActionFormProps {
  integration: Integrations;
  callId: string;
  contactId?: string;
}

const CallActionFormComponent: React.FC<ActionFormProps & RouteComponentProps<{ action: CallActionType }>> = ({
  callId,
  contactId,
  integration,
  match,
}) => {
  function goToCall() {
    const params = { [PARAM_CALLID]: callId };
    goToRouteWithParams(ApplicationRoute.CALL_ROUTE, params);
  }

  switch (match?.params?.action) {
    case CallActionRoutes[EntityType.CONTACT]:
      return <NewContactForm callId={callId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.CASE]:
      return <NewCaseForm callId={callId} contactId={contactId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.LEAD]:
      return <NewLeadForm callId={callId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.OPPORTUNITY]:
      return <NewOpportunityForm callId={callId} contactId={contactId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.NOTE]:
      const integrationsWithAutoSave = [Integrations.Clio];

      if (integration === Integrations.Redtail) {
        return <NewRedtailNoteForm callId={callId} onClose={goToCall} />;
      } else if (integration === Integrations.ConnectWise) {
        return <NewConnectWiseNoteForm callId={callId} onClose={goToCall} />;
      } else {
        return (
          <NewNoteForm
            callId={callId}
            onClose={goToCall}
            shouldAutoSave={integrationsWithAutoSave.includes(integration)}
          />
        );
      }
    case CallActionRoutes[EntityType.COMMUNICATION]:
      return <ClioCommunicationLogForm callId={callId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.REDTAIL_ACTIVITY]:
      return <RedtailActivityForm callId={callId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.CONNECTWISE_SERVICE_TICKET]:
      return <ServiceTicketFormComponent callId={callId} onClose={goToCall} />;
    case CallActionRoutes[EntityType.CALL_LOG]:
      return <CallLogForm callId={callId} onClose={goToCall} />;
    default:
      return null;
  }
};

export const CallActionForm = withRouter(CallActionFormComponent);
