import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';

export enum ServiceTicketActionsType {
  SAVE_SERVICE_TICKET_REQUEST = 'connectwise/serviceTicket/save/request',
  SAVE_SERVICE_TICKET_SUCCESS = 'connectwise/serviceTicket/save/success',
  SAVE_SERVICE_TICKET_ERROR = 'connectwise/serviceTicket/save/error',
}

export const saveServiceTicketRequest = makeActionCreator(ServiceTicketActionsType.SAVE_SERVICE_TICKET_REQUEST)();

export const saveServiceTicketSuccess = makeActionCreator(ServiceTicketActionsType.SAVE_SERVICE_TICKET_SUCCESS)<
  WithMessage
>();

export const saveServiceTicketError = makeActionCreator(ServiceTicketActionsType.SAVE_SERVICE_TICKET_ERROR)<
  WithMessage
>();

export type ServiceTicketActions =
  | ReturnType<typeof saveServiceTicketRequest>
  | ReturnType<typeof saveServiceTicketSuccess>
  | ReturnType<typeof saveServiceTicketError>;
