import { ISyncService } from './sync.service.model';
import { getIntegration } from '../browserHistory';
import { isDesktopIntegration } from '../utils';

export class DummySyncService implements ISyncService {
  async canCurrentTabOperate(): Promise<boolean> {
    return true;
  }

  async isAvailable(): Promise<boolean> {
    const integration = getIntegration();
    return isDesktopIntegration(integration);
  }

  async cleanUpSyncData(): Promise<void> {
    return undefined;
  }
}
