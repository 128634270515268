import { LinesActions, LineActionTypes } from './lines.actions';
import { Reducer } from 'redux';
import { IndexedById } from '../models/index';
import { NormalizedLine } from './lines.model';
import { Organization } from '../settings/organizations.model';

export interface LinesState {
  isLoading: boolean;
  hasError: boolean;
  lines: IndexedById<NormalizedLine>;
  organizations: IndexedById<Organization>;
}

const LINES_INITIAL_STATE: LinesState = {
  isLoading: false,
  hasError: false,
  lines: {},
  organizations: {},
};

export const linesReducer: Reducer<LinesState, LinesActions> = (state = LINES_INITIAL_STATE, action) => {
  switch (action.type) {
    case LineActionTypes.LOAD_LINES: {
      return {
        lines: {},
        organizations: {},
        isLoading: true,
        hasError: false,
      };
    }
    case LineActionTypes.LOAD_LINES_SUCCESS: {
      return {
        lines: action.payload.lines,
        organizations: action.payload.organizations,
        isLoading: false,
        hasError: false,
      };
    }
    case LineActionTypes.LOAD_LINES_FAILED: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }
    default:
      return state;
  }
};
