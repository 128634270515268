import { CallWithDuration } from '../calls/calls.reducer';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CallType } from '@jive/realtime-events';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getCallsDurationDisplay } from '../formatCallDuration';
import classNames from 'classnames';
import { Box, createStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { Typography } from '../theme/Typography.component';

export interface TodaysCallsSummaryStateProps {
  todaysCalls: CallWithDuration[];
  isNarrowIntegration: boolean;
}

const definedMessages = defineMessages({
  CALL_HISTORY_TODAY_TITLE: {
    id: 'CallHistory.Today.Title',
    defaultMessage: 'Today',
  },
  CALLS_LABEL: {
    id: 'TodaysCalls.Calls.Label',
    defaultMessage: 'Calls',
  },
  MISSED_LABEL: {
    id: 'TodaysCalls.Missed.Label',
    defaultMessage: 'Missed',
  },
  DURATION_LABEL: {
    id: 'TodaysCalls.Duration.Label',
    defaultMessage: 'Duration',
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 6,
    },
    summaryItem: {
      borderRadius: '5px',
      backgroundColor: tokens.uiBase01,
      padding: '9px',
      flex: '1 1 auto',
    },
    summaryItemNarrow: {
      padding: '8px',
    },
  }),
);

export const TodaysCallsSummaryComponent: React.FC<TodaysCallsSummaryStateProps> = ({
  todaysCalls,
  isNarrowIntegration,
}) => {
  const classes = useStyles();

  if (!todaysCalls) {
    todaysCalls = [];
  }

  const callCount = todaysCalls.length;
  const missedCallCount = todaysCalls.filter((call) => call.type === CallType.MissedCall).length;
  const duration = getCallsDurationDisplay(todaysCalls);

  const summaryItemClasses = classNames(classes.summaryItem, isNarrowIntegration && classes.summaryItemNarrow);

  return (
    <Box mb={7}>
      <Box mb={3}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.CALL_HISTORY_TODAY_TITLE} />
        </Typography>
      </Box>
      <div className={classes.container}>
        <div className={summaryItemClasses}>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.CALLS_LABEL} />
            </b>
          </Typography>
          <Typography>{callCount}</Typography>
        </div>
        <div className={summaryItemClasses}>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.MISSED_LABEL} />
            </b>
          </Typography>
          <Typography>{missedCallCount}</Typography>
        </div>
        <div className={summaryItemClasses}>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.DURATION_LABEL} />
            </b>
          </Typography>
          <Typography>{duration}</Typography>
        </div>
      </div>
    </Box>
  );
};
