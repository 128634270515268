import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { ConnectWiseContactActionCreator } from './contact/connectwise.contact.actioncreator';
import { ConnectWiseNoteActionCreator } from './note/connectwise.note.actioncreator';
import { CrmFormActionCreator } from '../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { ConnectWiseCrmFormActionCreator } from './crmForm/connectwise.crmForm.actioncreator';
import { ConnectWiseNoteTypeActionCreator } from './note/notetype/connectwise.notetype.actioncreator';
import { ServiceTicketActionCreator } from './serviceTicket/connectwise.serviceTicket.actioncreator';
import { CompanyActionCreator } from './companyPicker/connectwise.company.actioncreator';

export default class ConnectWiseActionCreatorFactory extends ActionCreatorFactory {
  public createContactActionCreator(): ContactActionCreator {
    return new ConnectWiseContactActionCreator(this.store);
  }

  public createNoteActionCreator(): ConnectWiseNoteActionCreator {
    return new ConnectWiseNoteActionCreator(this.store);
  }

  public createCrmFormActionCreator(): CrmFormActionCreator {
    return new ConnectWiseCrmFormActionCreator(this.store);
  }

  public createNoteTypeActionCreator(): ConnectWiseNoteTypeActionCreator {
    return new ConnectWiseNoteTypeActionCreator(this.store);
  }

  public createServiceTicketActionCreator(): ServiceTicketActionCreator {
    return new ServiceTicketActionCreator(this.store);
  }

  public createCompanyActionCreator(): CompanyActionCreator {
    return new CompanyActionCreator(this.store);
  }
}
