import { Call } from '@jive/realtime-events';
import { makeActionCreator } from '../../actions/actionCreators';

export enum TestCallActionType {
  START_TEST_CALL = 'onboarding/startTestCall',
  TEST_CALL_SUCCEEDED = 'onboarding/startTestCall/success',
  TEST_CALL_FAILED = 'onboarding/startTestCall/failed',
}

export interface TestCallOptions {
  showBannerOnSuccess?: boolean;
}

export const startTestCallAction = makeActionCreator(TestCallActionType.START_TEST_CALL)<TestCallOptions>();
export const testCallSucceededAction = makeActionCreator(TestCallActionType.TEST_CALL_SUCCEEDED)<Call>();
export const testCallFailedAction = makeActionCreator(TestCallActionType.TEST_CALL_FAILED)();

export type TestCallActions =
  | ReturnType<typeof startTestCallAction>
  | ReturnType<typeof testCallSucceededAction>
  | ReturnType<typeof testCallFailedAction>;
