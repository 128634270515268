import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { default as featureFlag } from './featureFlag/featureFlag.reducer';
import init from './init/init.reducers';
import preferences from './preferences/preferences.reducers';
import sdk from './sdk.reducer';

const msteamsPersistConfig = {
  key: 'msteams',
  storage,
  whitelist: ['preferences'],
};

const msTeamsReducer = persistReducer(
  msteamsPersistConfig,
  combineReducers({
    sdk,
    preferences,
    featureFlag,
    init,
  }),
);

export type MsTeamsState = ReturnType<typeof msTeamsReducer>;

export default msTeamsReducer;
