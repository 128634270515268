import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { GetMoreNotesRequestState, GetNotesRequestState, Note } from './notes.models';

export const notesListSelector = (state: AppState): Note[] =>
  state.notes && state.notes.notesList && state.notes.notesList.notes;

export const notesCountSelector = createSelector(
  notesListSelector,
  (notesList) => (notesList && notesList.length) || 0,
);

export const getNotesRequestStateSelector = (state: AppState): GetNotesRequestState =>
  state.notes.notesList.requestState;

export const getMoreNotesRequestStateSelector = (state: AppState): GetMoreNotesRequestState =>
  state.notes.notesList.moreRequestState;

export const getNoteRequestMetaSelector = (state: AppState) => state.notes.notesList.requestMeta;

export const canLoadMoreNotesSelector = createSelector(getNoteRequestMetaSelector, (meta) => !!meta && !!meta.next);

export const allNotesCountSelector = (state: AppState) => state.notes.notesList.notesCount;
