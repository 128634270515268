import React, { FC } from 'react';
import { proxyLinkedSelector } from '../../ambassador/proxy/proxy.selector';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../authentication/authentication.selector';
import { LinkAccountButton } from './LinkAccountButton.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { linkAccountRequest } from './linkAccount.action';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  LINK_DIFFERENT_ACCOUNT_LINK: {
    id: 'Onboarding.Linking.LinkDifferentAccountLink',
    defaultMessage: 'Link a different account?',
  },
});

export const LinkButtonForOnboarding: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const linked = useSelector(proxyLinkedSelector);
  const dispatch = useDispatch();

  if (isAuthenticated && linked) {
    return (
      <Link onClick={() => dispatch(linkAccountRequest())} size="small" style={{ '--goto-button-padding': 0 } as any}>
        <FormattedMessage {...definedMessages.LINK_DIFFERENT_ACCOUNT_LINK} />
      </Link>
    );
  } else {
    return <LinkAccountButton />;
  }
};
