import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { Activity } from '../clio.models';
import { ActivitiesState, LoadActivityCategoriesRequestState } from './activity.models';

const activitesSelector = (state: AppState): ActivitiesState => state.clio.activities;

export const activityCategoriesSelector: (state: AppState) => Activity[] = createSelector(
  activitesSelector,
  (result) => result.activityCategories,
);

export const loadActivityCategoriesRequestStateSelector: (
  state: AppState,
) => LoadActivityCategoriesRequestState = createSelector(activitesSelector, (result) => result.requestState);
