import { makeActionCreator } from '../actions/actionCreators';
import { IndexedById } from '../models';
import { NormalizedDevice } from '../settings/devices.model';

export enum DevicesEntityActionsType {
  ADD = 'jiveEntities/devices/add',
  CLEAN = 'jiveEntities/devices/clean',
}

export const addDevices = makeActionCreator(DevicesEntityActionsType.ADD)<IndexedById<NormalizedDevice>>();
export const cleanDevices = makeActionCreator(DevicesEntityActionsType.CLEAN)();

export type DevicesEntityActions = ReturnType<typeof addDevices> | ReturnType<typeof cleanDevices>;
