import React from 'react';
import { goToRoute } from '../browserHistory';
import { isDesktopIntegrationSelector } from '../integrations/integrations.selector';
import { useSelector } from 'react-redux';
import { MenuItem, MenuItemProps } from '@getgo/chameleon-web/react';
import { MenuItemClickEvent } from '@getgo/chameleon-web/react/src/events';

export interface ContextMenuLinkItemProps extends MenuItemProps {
  isExternal: boolean;
  linkToOpen: string;
}

export const ContextMenuLinkItem: React.FC<ContextMenuLinkItemProps> = ({
  isExternal,
  linkToOpen,
  onClick,
  ...contextMenuItemProps
}) => {
  const isDesktopIntegration = useSelector(isDesktopIntegrationSelector);

  function handleClick(e: MenuItemClickEvent) {
    if (isExternal) {
      const features = isDesktopIntegration ? 'openInDefaultBrowser' : undefined;
      window.open(linkToOpen, '_blank', features);
    } else {
      goToRoute(linkToOpen);
    }

    if (onClick) {
      onClick(e);
    }
  }

  return <MenuItem onClick={handleClick} {...contextMenuItemProps} />;
};
