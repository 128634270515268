import { createSelector } from 'reselect';
import { AppState } from '../reducers';

export const internetConnectivitySelector = (state: AppState) => state.connectivity.internet;
export const realtimeConnectivitySelector = (state: AppState) => state.connectivity.realtime;

export const isOnlineSelector = createSelector(internetConnectivitySelector, ({ online }) => online);

export const isRealtimeOnlineSelector = createSelector(realtimeConnectivitySelector, ({ online }) => online);

export const isRealtimeReconnectingSelector = createSelector(
  realtimeConnectivitySelector,
  ({ reconnecting }) => reconnecting,
);
