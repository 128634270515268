import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { FormattedMessageWithMessageValues } from '../reactInltModels';
import { TextFieldProps } from '@material-ui/core/TextField';
import { DateTimePickerInputWrapper } from './DateTimePickerInputWrapper.component';
import { newTracker } from '../analytics-new/tracker-new';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { logger } from '../logging';
import { CalendarInactiveIcon } from '@getgo/chameleon-icons/react';
import { makeStyles } from '@material-ui/styles';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  FORM_GENERAL_CANCEL_BUTTON_TEXT: {
    id: 'Form.General.CancelButtonText',
    defaultMessage: 'Cancel',
    description: 'General Cancel button text',
  },
  FORM_GENERAL_OK_BUTTON_TEXT: {
    id: 'Form.General.OkButtonText',
    defaultMessage: 'Ok',
  },
});

const styles = makeStyles({
  dialog: {
    // The built in action button styling is broken: https://github.com/mui-org/material-ui-pickers/issues/1356
    '& [class*="MuiDialogActions-root"]': {
      '& [class*="MuiButtonBase-root"]': {
        color: tokens.interactive01,
      },
    },
  },
});

export interface DatePickerProps {
  label: FormattedMessageWithMessageValues;
  trackingEvent: TrackingEvent;
  format?: string;
  value: Date | null | undefined;
  onChange: (value: Date | null | undefined) => void;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  openTo?: 'date' | 'year' | 'month';
  views?: Array<'date' | 'year' | 'month'>;
  variant?: 'dialog' | 'inline' | 'static';
  open?: boolean;
  inputVariant?: 'outlined' | 'standard' | 'filled';
  InputProps?: TextFieldProps['InputProps'];
  name: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  variant,
  value,
  onChange,
  label,
  format,
  views,
  openTo,
  inputVariant,
  InputProps,
  minDate,
  maxDate,
  trackingEvent,
}) => {
  const intl = useIntl();
  const classes = styles();

  return (
    <MuiDatePicker
      label={intl.formatMessage(label)}
      format={format}
      TextFieldComponent={(props) => <DateTimePickerInputWrapper name="name" Icon={CalendarInactiveIcon} {...props} />}
      variant={variant}
      inputVariant={inputVariant || 'outlined'}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(date) => {
        try {
          newTracker.trackAnalyticsEvent(trackingEvent);
        } catch (error) {
          logger.error('Error while tracking date picker', error);
        }
        onChange(date);
      }}
      openTo={openTo}
      views={views}
      InputProps={InputProps}
      clearable={false}
      showTodayButton={false}
      DialogProps={{
        className: classes.dialog,
      }}
      okLabel={<FormattedMessage {...definedMessages.FORM_GENERAL_OK_BUTTON_TEXT} />}
      cancelLabel={<FormattedMessage {...definedMessages.FORM_GENERAL_CANCEL_BUTTON_TEXT} />}
    />
  );
};
