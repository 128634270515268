import { SettingsActionTypes } from '../constants';
import { DevicesEntityActionsType } from '../jiveEntities/devicesEntity.action';
import { devicesObjectSelector } from '../jiveEntities/devicesEntity.selector';
import { addListener } from '../middlewares/listener.middleware';
import { selectLine } from './settings.action';
import { selectedDeviceSelector, selectedLineIdSelector } from './settings.selector';

addListener(SettingsActionTypes.SELECT_DEVICE)((state, action) => {
  const devices = devicesObjectSelector(state);

  if (devices.byId[action.payload.deviceId]) {
    return selectLine(action.payload.lineId);
  }
});

addListener(DevicesEntityActionsType.ADD)((state) => {
  const selectedDevice = selectedDeviceSelector(state);
  const selectedLineId = selectedLineIdSelector(state);

  if (selectedDevice && selectedDevice.line.id !== selectedLineId) {
    return selectLine(selectedDevice.line.id);
  }
});
