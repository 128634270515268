import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { NoteActionCreator } from '../actionFacade/actionCreators/note/note.actioncreator';
import { DynamicsContactActionCreator } from './contact/dynamics.contact.actioncreator';
import { DynamicsStartupActionCreator } from './dynamics.startup.actioncreator';
import { StartupActionCreator } from '../startup/startup.actioncreator';
import { CrmFormActionCreator } from '../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { DynamicsCrmFormActionCreator } from './crmForm/dynamics.crmForm.actioncreator';
import { DynamicsCaseActionCreator } from './case/dynamics.case.actioncreator';
import { DynamicsLeadActionCreator } from './lead/dynamics.lead.actioncreator';
import { DynamicsOpportunityActionCreator } from './opportunity/dynamics.opportunity.actioncreator';

export default class DynamicsActionCreatorFactory extends ActionCreatorFactory {
  public createContactActionCreator(): ContactActionCreator {
    return new DynamicsContactActionCreator(this.store);
  }

  public createNoteActionCreator(): NoteActionCreator {
    throw new Error('Method should not be implemented and called.');
  }

  public createCrmFormActionCreator(): CrmFormActionCreator {
    return new DynamicsCrmFormActionCreator(this.store);
  }

  public createCaseActionCreator(): DynamicsCaseActionCreator {
    return new DynamicsCaseActionCreator(this.store);
  }

  public createLeadActionCreator(): DynamicsLeadActionCreator {
    return new DynamicsLeadActionCreator(this.store);
  }

  public createOpportunityActionCreator(): DynamicsOpportunityActionCreator {
    return new DynamicsOpportunityActionCreator(this.store);
  }

  createStartupActionCreator(): StartupActionCreator {
    return new DynamicsStartupActionCreator(this.store);
  }
}
