import { Call } from '@jive/realtime-events';
import { ActionCreatorFactory } from './actionCreator.factory';
import { ContactActionCreator } from './actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from './actionCreators/contact/contact.actioncreator.models';
import { Capability } from './action.facade.capabilities';
import { ScreenpopActionCreator } from './actionCreators/screenpop.actioncreator';
import { AuthenticationActionCreator } from '../authentication/authentication.actioncreator';
import { LoginData } from '../authentication/authentication.models';
import { LinesActionCreator } from '../lines/lines.actionCreator';
import { StartupActionCreator } from '../startup/startup.actioncreator';
import { CallHistoryActionCreator } from '../callHistoryPush/callHistory.actionCreator';
import { ChangelogActionCreator } from '../changelog/changelog.actionCreator';
import { AuthType } from '../authentication/authentication.actions';
import { PresenceAppearance } from '../presence/presence.models';
import { PresenceActionCreator } from '../presence/presence.actionCreator';
import { SoftphoneActionCreator } from '../softphone/softphone.actioncreator';

export abstract class ActionFacade {
  protected readonly contactActionCreator: ContactActionCreator;
  protected readonly screenpopActionCreator: ScreenpopActionCreator;
  protected readonly authenticationActionCreator: AuthenticationActionCreator;
  protected readonly linesActionCreator: LinesActionCreator;
  protected readonly startupActionCreator: StartupActionCreator;
  protected readonly callHistoryActionCreator: CallHistoryActionCreator;
  protected readonly changelogActionCreator: ChangelogActionCreator;
  protected readonly presenceActionCreator: PresenceActionCreator;
  protected readonly softphoneActionCreator: SoftphoneActionCreator;

  constructor(actionCreatorFactory: ActionCreatorFactory) {
    this.contactActionCreator = actionCreatorFactory.createContactActionCreator();
    this.screenpopActionCreator = actionCreatorFactory.createScreenpopActionCreator();
    this.authenticationActionCreator = actionCreatorFactory.createAuthenticationActionCreator();
    this.linesActionCreator = actionCreatorFactory.createLinesActionCreator();
    this.startupActionCreator = actionCreatorFactory.createStartupActionCreator();
    this.callHistoryActionCreator = actionCreatorFactory.createCallHistoryActionCreator();
    this.changelogActionCreator = actionCreatorFactory.createChangelogActionCreator();
    this.presenceActionCreator = actionCreatorFactory.createPresenceActionCreator();
    this.softphoneActionCreator = actionCreatorFactory.createSoftphoneActionCreator();
  }

  login(authType: AuthType = AuthType.IntegrationDefault): void {
    this.authenticationActionCreator.login(authType);
  }

  logout(): void {
    this.authenticationActionCreator.logout();
  }

  startAuthentication(): Promise<void> {
    return this.authenticationActionCreator.startAuthentication();
  }

  startMainApplication(): Promise<void> {
    return this.startupActionCreator.startMainApplication();
  }

  startOnboardingServices(): Promise<void> {
    return this.startupActionCreator.startOnboardingServices();
  }

  loadSettingsState(): Promise<void> {
    return this.startupActionCreator.loadSettingsFromStockPile();
  }

  loadSoftphoneCapability(): Promise<void> {
    return this.startupActionCreator.loadSoftphoneCapability();
  }

  startSoftphone(): Promise<void> {
    return this.startupActionCreator.startSoftphone();
  }

  stopSoftphone(): void {
    this.startupActionCreator.stopSoftphone();
  }

  completeAuthentication(loginData: LoginData): Promise<void> {
    return this.authenticationActionCreator.completeAuthentication(loginData);
  }

  completeTokenRefresh(): Promise<void> {
    return this.authenticationActionCreator.completeTokenRefresh();
  }

  loadLines(): Promise<void> {
    return this.linesActionCreator.loadLines();
  }

  isCapable(capability: Capability): boolean {
    return (this as any)[capability] as boolean;
  }

  setCapability(capability: Capability, isCapable: boolean): void {
    (this as any)[capability] = isCapable;
  }

  async createScreenpop(call: Call): Promise<void> {
    await this.screenpopActionCreator.createScreenpop(call);
    await this.contactActionCreator.searchContactForCall(call);
  }

  openContactInCrm(id: string): Promise<void> {
    return this.contactActionCreator.openContactInCrm(id);
  }

  searchContact(query: string): Promise<void> {
    return this.contactActionCreator.searchContact(query);
  }

  loadContactPhoneNumbers(contactId: string): Promise<void> {
    return this.contactActionCreator.loadContactPhoneNumbers(contactId);
  }

  updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    return this.contactActionCreator.updateContactPhoneNumber(contactId, phoneNumber);
  }

  createContact(contactData: ContactCreateModel): Promise<void> {
    return this.contactActionCreator.createContact(contactData);
  }

  loadCallHistoryItems(organizationId: string, pageNumber: number, startDate?: Date): Promise<void> {
    return this.callHistoryActionCreator.loadCallHistoryItems(organizationId, pageNumber, startDate);
  }

  loadLastSeenVersion(): Promise<void> {
    return this.changelogActionCreator.loadLastSeenVersion();
  }

  saveLastSeenVersion(): Promise<void> {
    return this.changelogActionCreator.saveLastSeenVersion();
  }

  loadLastSeenAnnouncement(): Promise<void> {
    return this.changelogActionCreator.loadLastSeenAnnouncement();
  }

  saveLastSeenAnnouncement(): Promise<void> {
    return this.changelogActionCreator.saveLastSeenAnnouncement();
  }

  setPresence(appearance: PresenceAppearance): Promise<void> {
    return this.presenceActionCreator.setPresence(appearance);
  }

  async answerCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.answerCall(callId);
  }

  async hangupCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.hangupCall(callId);
  }

  async holdCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.holdCall(callId);
  }

  async makeCall(callee: string): Promise<void> {
    return this.softphoneActionCreator.makeCall(callee);
  }

  async muteCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.muteCall(callId);
  }

  async rejectCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.rejectCall(callId);
  }

  async resumeCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.resumeCall(callId);
  }

  async unmuteCall(callId: string): Promise<void> {
    return this.softphoneActionCreator.unmuteCall(callId);
  }

  async sendDtmf(callId: string, value: string): Promise<void> {
    return this.softphoneActionCreator.sendDtmf(callId, value);
  }

  async openMicrophonePermissionPage(): Promise<void> {
    return this.softphoneActionCreator.openMicrophonePermissionPage();
  }
}
