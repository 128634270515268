import { Store } from 'redux';
import { AppState } from '../reducers';
import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';
import { logger } from '../logging';
import { definedMessages, SalesforceAuthActionCreator } from './salesforce.auth.actionCreator';
import { injectedIntlSelector } from '../settings/settings.selector';

export class LegacySalesforceAuthActionCreator extends SalesforceAuthActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store, salesforceLightningProxy);
  }

  protected async onOnboardingScreen(): Promise<void> {
    try {
      await this.salesforceLightningProxy.init();
      await this.updateSoftphonePanelStatus();
    } catch (error) {
      logger.warn('Salesforce initialization failed', error);
    } finally {
      await super.onOnboardingScreen();
    }
  }

  async logout(): Promise<void> {
    
    const intl = injectedIntlSelector(this.store.getState());

    try {
      super.logout();
      await this.salesforceLightningProxy.setSoftphonePanelLabel(intl.formatMessage(definedMessages.SOFTPHONE_LABEL_NOT_LOGGED_IN));
      await this.salesforceLightningProxy.setSoftphonePanelIcon('end_call');
    } catch (error) {
      logger.error('Failed to logout', error);
    }
  }
}
