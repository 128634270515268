import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { LabeledSwitch } from '../../components/LabeledSwitch.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../../theme/Typography.component';
import { useDispatch, useSelector } from 'react-redux';
import { zendeskSettingSelector } from '../../settings/settings.selector';
import { setZendeskSettings } from '../../settings/settings.action';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { makeStyles } from '@material-ui/core/styles';

const definedMessages = defineMessages({
  AUTO_CALL_LOG_SWITCH_LABEL: {
    id: 'Zendesk.Settings.AutoCallLogSwitch.Label',
    defaultMessage: 'Auto log calls',
  },
  AUTO_CALL_LOG_SWITCH_MESSAGE: {
    id: 'Zendesk.Settings.AutoCallLogSwitch.Message',
    defaultMessage: 'Automatically log your call details in Zendesk.',
  },
});

const trackingEvents = defineTrackingEvents({
  AUTO_CALL_LOG_SWITCHED_ON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Auto log call | toggle | ON',
  },
  AUTO_CALL_LOG_SWITCHED_OFF: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Auto log call | toggle | OFF',
  },
});

const useStyles = makeStyles({
  labelPlacement: {
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const ZendeskSettings: React.FC = () => {
  const classes = useStyles();

  const { isAutomaticCallLoggingEnabled } = useSelector(zendeskSettingSelector);
  const dispatch = useDispatch();

  const Heading: FC = ({ children }) => {
    const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
    const variant = isNarrowIntegration ? 'body-small' : 'heading-xsmall';
    return (
      <Typography variant={variant} style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    );
  };

  function AutoCallLogSwitch() {
    return (
      <>
        <Box mb={1}>
          <LabeledSwitch
            id="auto-call-log-switch"
            checked={isAutomaticCallLoggingEnabled}
            labelPlacement="start"
            onChange={({ currentTarget: { checked } }) =>
              dispatch(setZendeskSettings({ isAutomaticCallLoggingEnabled: checked }))
            }
            label={
              <Heading>
                <FormattedMessage {...definedMessages.AUTO_CALL_LOG_SWITCH_LABEL} />
              </Heading>
            }
            onTrackingEvent={trackingEvents.AUTO_CALL_LOG_SWITCHED_ON}
            offTrackingEvent={trackingEvents.AUTO_CALL_LOG_SWITCHED_OFF}
            controlLabelClasses={{
              labelPlacementStart: classes.labelPlacement,
            }}
          />
        </Box>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.AUTO_CALL_LOG_SWITCH_MESSAGE} />
        </Typography>
      </>
    );
  }

  return AutoCallLogSwitch();
};
