import React from 'react';
import { TrackingEvent } from './analytics.models';
import { newTracker } from './tracker-new';
import { logger } from '../logging';
import { Switch, SwitchProps } from '@getgo/chameleon-web/react';

export interface TrackedSwitchProps extends SwitchProps {
  onTrackingEvent?: TrackingEvent;
  offTrackingEvent?: TrackingEvent;
}

export const TrackedSwitch: React.FC<TrackedSwitchProps> = ({
  color,
  onChange,
  onTrackingEvent,
  offTrackingEvent,
  ...rest
}) => (
  <Switch
    aria-labelledby={`${rest.id}-label`}
    onChange={(event) => {
      const {
        currentTarget: { checked },
      } = event;
      try {
        if (checked && onTrackingEvent) {
          newTracker.trackAnalyticsEvent(onTrackingEvent);
        } else if (!checked && offTrackingEvent) {
          newTracker.trackAnalyticsEvent(offTrackingEvent);
        }
      } catch (error) {
        logger.error('Error while tracking switch', error);
      }
      if (onChange) {
        onChange(event);
      }
    }}
    color={color}
    {...rest}
  />
);
