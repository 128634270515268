import { CrmFormActionCreator } from '../../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { OpenCrmFormModel } from '../../actionFacade/actionCreators/crmForm/crmForm.model';
import { openDynamicsCrmForm } from '../dynamics.actionCreator.utils';

export class DynamicsCrmFormActionCreator extends CrmFormActionCreator {
  protected _openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    openDynamicsCrmForm(this.store.getState(), model.entityType, model.defaultFieldValues.id);
    return Promise.resolve();
  }
}
