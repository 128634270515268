import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { categoriesSelector } from './redtail.category.selectors';
import {
  CategoryPickerComponent,
  CategoryPickerProps,
  CategoryPickerStateProps,
} from './RedtailCategoryPicker.component';

const mapStateToProps = (state: AppState): CategoryPickerStateProps => ({
  categories: categoriesSelector(state).categories,
  requestState: categoriesSelector(state).requestState,
});

export const CategoryPicker = connect<CategoryPickerStateProps, {}, CategoryPickerProps, AppState>(mapStateToProps)(
  CategoryPickerComponent,
);
