import { Store } from 'redux';
import { AppState } from '../reducers';
import { ContactActionCreator } from './actionCreators/contact/contact.actioncreator';
import { NoteActionCreator } from './actionCreators/note/note.actioncreator';
import { ScreenpopActionCreator } from './actionCreators/screenpop.actioncreator';
import { AuthenticationActionCreator } from '../authentication/authentication.actioncreator';
import { LinesActionCreator } from '../lines/lines.actionCreator';
import { StartupActionCreator } from '../startup/startup.actioncreator';
import { CallHistoryActionCreator } from '../callHistoryPush/callHistory.actionCreator';
import { ChangelogActionCreator } from '../changelog/changelog.actionCreator';
import { PresenceActionCreator } from '../presence/presence.actionCreator';
import { SoftphoneActionCreator } from '../softphone/softphone.actioncreator';

export abstract class ActionCreatorFactory {
  constructor(protected readonly store: Store<AppState>) {}

  public createScreenpopActionCreator(): ScreenpopActionCreator {
    return new ScreenpopActionCreator(this.store);
  }

  public createAuthenticationActionCreator(): AuthenticationActionCreator {
    return new AuthenticationActionCreator(this.store);
  }

  public createLinesActionCreator(): LinesActionCreator {
    return new LinesActionCreator(this.store);
  }

  public createStartupActionCreator(): StartupActionCreator {
    return new StartupActionCreator(this.store);
  }

  public createCallHistoryActionCreator(): CallHistoryActionCreator {
    return new CallHistoryActionCreator(this.store);
  }

  public createChangelogActionCreator(): ChangelogActionCreator {
    return new ChangelogActionCreator(this.store);
  }

  public createPresenceActionCreator(): PresenceActionCreator {
    return new PresenceActionCreator(this.store);
  }

  public createSoftphoneActionCreator(): SoftphoneActionCreator {
    return new SoftphoneActionCreator();
  }

  public abstract createContactActionCreator(): ContactActionCreator;
  public abstract createNoteActionCreator(): NoteActionCreator;
}
