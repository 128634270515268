import {
  WebsocketConnectionErrorMessageDispatchProps,
  WebsocketConnectionErrorMessageComponent,
} from './WebsocketConnectionErrorMessage.component';
import { restartApp } from './connectivity.action';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { Dispatch } from 'redux';

const mapDispatch = (dispatch: Dispatch): WebsocketConnectionErrorMessageDispatchProps => ({
  onRestartApp: () => dispatch(restartApp()),
});

export default connect<{}, WebsocketConnectionErrorMessageDispatchProps, {}, AppState>(
  null,
  mapDispatch,
)(WebsocketConnectionErrorMessageComponent);
