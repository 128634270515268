import { Integrations } from '../models';

export enum MicrosoftTeamsHostClientType {
  Android = 'android',
  IOS = 'ios',
  Web = 'web',
  Desktop = 'desktop',
}

export enum SdkActionsType {
  INIT_REQUEST = 'msteams/sdk/init',
  INIT_SUCCESS = 'msteams/sdk/initSuccess',
  SET_FONT_SIZE = 'msteams/sdk/setFontSize',
  SET_THEME = 'msteams/sdk/setTheme',
  SET_USER_OBJECT_ID = 'msteams/sdk/setUserObjectId',
  SET_CURRENT_DEVICE = 'msteams/sdk/setCurrentDevice',
  SET_TENANT_ID = 'msteams/sdk/setTenantId',
  SET_SDK_STATE = 'msteams/sdk/setContextState',
  INIT_CONTEXT_REQUEST = 'msteams/sdk/initContextRequest',
  INIT_CONTEXT_SUCCESS = 'msteams/sdk/initContextSuccess',
}

export enum MsTeamsAuthenticationActionsType {
  SIGNIN_REQUEST = 'msteams/authentication/signIn',
  SIGNING_PROCESSING = 'msteams/authentication/signInProcessing',
  SIGNIN_SUCCESS = 'msteams/authentication/signInSuccess',
  SIGNIN_ERROR = 'msteams/authentication/signInError',
  SIGNOUT_REQUEST = 'msteams/authentication/signOut',
  SIGNOUT_REQUEST_ERROR = 'msteams/authentication/signOutRequestError',
  SIGNOUT_SUCCESS = 'msteams/authentication/signOutSuccess',
  REDIRECT_SIGN_IN = 'msteams/authentication/redirectSignIn',
  REDIRECT_SIGN_OUT = 'msteams/authentication/redirectSignOut',
}

export const MSTEAMS_PREFERENCES = `${Integrations.MsTeams}/preferences`;

export enum MsTeamsPreferencesActionsType {
  LOAD_AVAILABLE_LOCALES_REQUEST = 'msteams/preferences/loadLocalesRequest',
  LOAD_AVAILABLE_LOCALES_SUCCESS = 'msteams/preferences/loadLocalesSuccess',
  LOAD_REQUEST = 'msteams/preferences/loadRequest',
  LOAD_SUCCESS = 'msteams/preferences/loadSuccess',
  LOAD_ERROR = 'msteams/preferences/loadError',
  SAVE_REQUEST = 'msteams/preferences/saveRequest',
  SAVE_SUCCESS = 'msteams/preferences/saveSuccess',
  SAVE_ERROR = 'msteams/preferences/saveError',
  SET_VOICEMAIL_NOTIFICATION_ENABLED = 'msteams/preferences/setVoicemailNotificationEnabled',
  OPEN_ADMIN_CONSENT_PAGE = 'msteams/preferences/openAdminConsentPage',
  SET_LOCALE = 'msteams/preferences/setLocale',
}

export enum MsTeamsFeatureFlagActionsType {
  LOAD_REQUEST = 'msteams/featureFlag/loadRequest',
  LOAD_SUCCESS = 'msteams/featureFlag/loadSuccess',
  LOAD_ERROR = 'msteams/featureFlag/loadError',
}

// personal addressbook id is the same for everyone. Hardcoded here until using the new contacts API search endpoint to query all contacts from all orgs
export const PERSONAL_ADDRESSBOOK_ID = 'a3c6ea4c-5494-4366-bb4b-275926a4a147';
