import { NoteActions, NoteActionsType } from '../../notes/notes.actions';
import { Note } from '../../notes/notes.models';
import { ConnectWiseNoteActions, ConnectWiseNoteActionsType } from './connectwise.note.action';

export interface ConnectWiseNotesState {
  notes: Note[];
}

export const INITIAL_STATE: ConnectWiseNotesState = {
  notes: [],
};

export const connectWiseNotesReducer = (
  state = INITIAL_STATE,
  action: NoteActions | ConnectWiseNoteActions,
): ConnectWiseNotesState => {
  switch (action.type) {
    case ConnectWiseNoteActionsType.GET_NOTES_SUCCESS:
      return { ...state, notes: action.payload.notes };
    case NoteActionsType.RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
