import { Reducer } from 'redux';
import { ContactsActions, ContactActionsType } from '../actions/contacts';
import { SaveCallHistory, PostCallActionsType } from '../calls/callEvents.action';
import { CallWithContact, Contact } from '../calls/calls.reducer';
import { IndexedById } from '../models';
import { SalesforceActions } from '../salesforce/salesforce.actions';
import { EntitiesActions, EntitiesActionsType } from '../search/entities.action';
import { Call, CallEventsActions, CallEventsActionsType } from '@jive/realtime-events';
import { uniq } from 'lodash-es';
import { UnifiedCallHistoryActions, UnifiedCallHistoryActionsType } from './unifiedCallHistory.action';

export interface CallHistoryMetadataItem {
  contacts?: Contact;
  isClickToCall?: boolean;
  allIds?: string[];
}

export interface CallHistoryMetadataState {
  byId: IndexedById<CallHistoryMetadataItem>;
  allIds: string[];
  loadedBuckets: string[];
}

const initialState: CallHistoryMetadataState = {
  byId: {},
  allIds: [],
  loadedBuckets: [],
};
export const unifiedCallHistoryMetadataReducer: Reducer<
  CallHistoryMetadataState,
  | ContactsActions
  | SaveCallHistory
  | EntitiesActions
  | SalesforceActions
  | CallEventsActions
  | UnifiedCallHistoryActions
> = (state = initialState, action): CallHistoryMetadataState => {
  switch (action.type) {
    case PostCallActionsType.SAVE_CALL_HISTORY:
      const { call } = action.payload;
      for (const id of call.allIds) {
        if (!id) {
          continue;
        }
        const callToAdd: CallWithContact = { ...call, id };
        state = {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              allIds: uniq([...callToAdd.allIds, ...(state.byId[id].allIds ?? [])]),
              contacts: {
                ...state.byId[id]?.contacts,
                allMatches: callToAdd.allMatches,
                entitySelected: callToAdd.entitySelected,
              },
            },
          },
          allIds: uniq([id, ...state.allIds]),
        };
      }
      return state;
    case ContactActionsType.CONTACT_ASSOCIATE: {
      const { callId, entityId, entities, isAutoSingleMatch } = action.payload;
      const callIds = state.byId[callId]?.allIds ?? [callId];
      for (const id of callIds) {
        if (!id) {
          continue;
        }
        state = {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              contacts: { allMatches: entities, entitySelected: entityId, isAutoSingleMatch },
            },
          },
        };
      }
      return state;
    }
    case ContactActionsType.CONTACT_FOUND: {
      const { callId, allMatches, autoSingleMatch } = action.payload;
      const callIds = state.byId[callId]?.allIds ?? [callId];
      for (const id of callIds) {
        if (!id) {
          continue;
        }
        state = {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              contacts: { ...state.byId[id]?.contacts, allMatches, isAutoSingleMatch: !!autoSingleMatch },
            },
          },
        };
      }
      return state;
    }
    case EntitiesActionsType.CREATE_CONTACT_SUCCESS: {
      const { callId } = action.payload;
      const callIds = state.byId[callId]?.allIds ?? [callId];
      for (const id of callIds) {
        if (!id || !state.byId[id]) {
          continue;
        }
        state = {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...state.byId[id],
              contacts: {
                ...state.byId[id]?.contacts,
                allMatches: [...(state.byId[id]?.contacts?.allMatches ?? []), action.payload.entity],
              },
            },
          },
        };
      }
      return state;
    }
    case CallEventsActionsType.START_OUTGOING_CALL:
    case CallEventsActionsType.START_INCOMING_CALL:
    case CallEventsActionsType.START_OUTGOING_CONVERSATION:
    case CallEventsActionsType.START_INCOMING_CONVERSATION:
    case CallEventsActionsType.END_CONVERSATION: {
      const call = action.payload.call;
      for (const id of call.allIds) {
        const callToAdd: Call = { ...call, id };
        state = {
          ...state,
          byId: {
            ...state.byId,
            [callToAdd.id]: {
              ...state.byId[callToAdd.id],
              allIds: uniq([...(state.byId[callToAdd.id]?.allIds ?? []), ...call.allIds]),
              contacts: state.byId[callToAdd.id]?.contacts ?? { ...state.byId[call.id]?.contacts },
              isClickToCall: callToAdd.isClickToCall,
            },
          },
          allIds: uniq([callToAdd.id, ...state.allIds]),
        };
      }
      return state;
    }

    case UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_METADATA_SUCCESS: {
      const callIds = Object.keys(action.payload.callHistoryMetadata);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.callHistoryMetadata,
        },
        allIds: uniq(state.allIds.concat(callIds)),
        loadedBuckets: uniq(state.loadedBuckets.concat(action.payload.loadedBuckets)),
      };
    }
    default:
      return state;
  }
};
