import { EntityPhoneNumberType } from '../search/entities.model';
import { ConnectWisePhoneNumberType } from './connectwise.model';

const phoneNumberTypeMap: { [key: string]: EntityPhoneNumberType } = {
  [ConnectWisePhoneNumberType.Direct]: EntityPhoneNumberType.DIRECT,
  [ConnectWisePhoneNumberType.Cell]: EntityPhoneNumberType.MOBILE,
  [ConnectWisePhoneNumberType.Pager]: EntityPhoneNumberType.PAGER,
  [ConnectWisePhoneNumberType.Home]: EntityPhoneNumberType.HOME,
};

export const connectWisePhoneNumberTypeMapper = (phoneNumberType: string): EntityPhoneNumberType => {
  return phoneNumberTypeMap[phoneNumberType] || EntityPhoneNumberType.BUSINESS;
};
