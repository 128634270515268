import { Store } from 'redux';
import { AppState } from '../../reducers';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import {
  GetMoreCasesRequestMeta,
  getCasesRequest,
  getCasesError,
  getCasesSuccess,
  GetCasesSuccessPayload,
  getMoreCasesError,
  getMoreCasesSuccess,
  getMoreCasesRequest,
  createCaseRequest,
  createCaseSuccess,
  createCaseError,
  openCaseError,
  openCaseRequest,
  openCaseSuccess,
  getCasesCountError,
  getCasesCountRequest,
  getCasesCountSuccess,
} from './case.actions';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { getCaseRequestMetaSelector } from './case.selectors';
import { EntityType } from '../../constants';
import { SalesforceModalParams } from '../../../../salesforce-shared/salesforce-service.models';
import { Entity } from '../../models';

const CASES_PAGING_LIMIT = 2;

export const definedMessages = defineMessages({
  GET_CASES_ERROR: {
    id: 'Cases.Get.Error',
    defaultMessage: 'There was an error while downloading cases, please try again.',
  },
  CREATE_CASE_ERROR: {
    id: 'Case.Create.Error',
    defaultMessage: 'There was an error while creating case, please try again.',
  },
  OPEN_CASE_ERROR: {
    id: 'Case.Open.Error',
    defaultMessage: 'There was an error while opening case, please try again.',
  },
});

export class SalesforceLightningCaseActionCreator {
  constructor(private store: Store<AppState>, private salesforceLightningProxy: SalesforceServiceWindtalkProxy) {}

  public async loadMostRecentCaseOfContact(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose cases should be queried!');
      this.store.dispatch(getCasesError({ message: definedMessages.GET_CASES_ERROR }));
      return;
    }
    try {
      this.store.dispatch(getCasesRequest());
      const casesWithMeta = await this.getMostRecentCaseOfContact(contactId);
      this.store.dispatch(getCasesSuccess(casesWithMeta));
    } catch (error) {
      logger.error('Error getting cases of contact', error);
      this.store.dispatch(getCasesError({ message: definedMessages.GET_CASES_ERROR }));
    }
  }

  public async loadMoreCasesOfContact(): Promise<void> {
    const state = this.store.getState();

    try {
      const requestMeta = getCaseRequestMetaSelector(state);

      if (!requestMeta || !requestMeta.next) {
        logger.error('There is no next url stored to load more Cases from!');
        this.store.dispatch(
          getMoreCasesError({
            message: definedMessages.GET_CASES_ERROR,
          }),
        );
        return;
      }

      this.store.dispatch(getMoreCasesRequest());
      const casesWithMeta = await this.getMoreCasesOfContact(requestMeta);
      this.store.dispatch(getMoreCasesSuccess(casesWithMeta));
    } catch (error) {
      logger.error('Error getting cases of contact', error);
      this.store.dispatch(
        getMoreCasesError({
          message: definedMessages.GET_CASES_ERROR,
        }),
      );
    }
  }

  public async createCaseForContact(contact: Entity): Promise<void> {
    if (!contact) {
      logger.error('Contact is not specified when creating case.');
      this.store.dispatch(createCaseError({ message: definedMessages.CREATE_CASE_ERROR }));
      return;
    }

    try {
      this.store.dispatch(createCaseRequest());
      const fields: SalesforceModalParams['defaultFieldValues'] = {};

      let accountId: string | null = null;
      if (contact.type === EntityType.ACCOUNT) {
        accountId = contact.id;
      } else if (contact.type === EntityType.CONTACT) {
        accountId = await this.salesforceLightningProxy.getAccountIdOfContact(contact.id);
        fields.ContactId = contact.id;
      } else if (contact.type === EntityType.LEAD) {
        accountId = await this.salesforceLightningProxy.getAccountIdOfContact(contact.id);
      }

      if (accountId) {
        fields.AccountId = accountId;
      }

      await this.salesforceLightningProxy.createNewEntity({
        entityName: EntityType.CASE,
        defaultFieldValues: fields,
      });

      this.store.dispatch(createCaseSuccess());
    } catch (error) {
      logger.error('Error creating case', error);
      this.store.dispatch(createCaseError({ message: definedMessages.CREATE_CASE_ERROR }));
    }
  }

  public async openCase(id: string): Promise<void> {
    if (!id) {
      logger.error('Please specify the ID of the case to open it!');
      this.store.dispatch(openCaseError({ message: definedMessages.OPEN_CASE_ERROR }));
      return;
    }
    try {
      this.store.dispatch(openCaseRequest());
      await this.salesforceLightningProxy.openEntityPage(id);
      this.store.dispatch(openCaseSuccess());
    } catch (error) {
      logger.error('Error opening case', error);
      this.store.dispatch(openCaseError({ message: definedMessages.OPEN_CASE_ERROR }));
    }
  }

  public async getCasesCount(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose cases count should be queried!');
      this.store.dispatch(getCasesCountError({ message: definedMessages.GET_CASES_ERROR }));
      return;
    }
    try {
      this.store.dispatch(getCasesCountRequest());
      const casesCount = await this.salesforceLightningProxy.getCasesCount(contactId);
      this.store.dispatch(getCasesCountSuccess({ casesCount }));
    } catch (error) {
      logger.error('Error getting cases count', error);
      this.store.dispatch(getCasesCountError({ message: definedMessages.GET_CASES_ERROR }));
    }
  }

  private async getMostRecentCaseOfContact(contactId: string): Promise<GetCasesSuccessPayload> {
    const count = await this.salesforceLightningProxy.getCasesCount(contactId);
    const items = await this.salesforceLightningProxy.getCases(contactId, 0, CASES_PAGING_LIMIT);

    return {
      meta: {
        casesCount: count,
        next: count > 2 ? '1' : undefined,
        contactId,
      },
      cases: items,
    };
  }

  private async getMoreCasesOfContact(moreCasesMeta: GetMoreCasesRequestMeta): Promise<GetCasesSuccessPayload> {
    const contactId = moreCasesMeta.contactId || '';
    const offset = Number(moreCasesMeta.next);
    const casesCount = moreCasesMeta.casesCount || 0;

    const cases = await this.salesforceLightningProxy.getCases(contactId, offset, CASES_PAGING_LIMIT);
    let next: Optional<string>;

    // if there are less item than the limit then we are done, no need to update offset
    if (cases.length === CASES_PAGING_LIMIT) {
      if ((offset + 1) * CASES_PAGING_LIMIT < casesCount) {
        next = '' + (offset + 1);
      }
    }

    return {
      meta: {
        casesCount: moreCasesMeta.casesCount || 0,
        next,
        contactId,
      },
      cases,
    };
  }
}
