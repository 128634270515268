import { combineReducers } from 'redux';
import { customerReducer } from './contact/cox.customer.reducer';
import { partsReducer } from './parts/parts.reducer';
import { repairOrdersReducer } from './repairOrders/repairOrders.reducer';
import { serviceAppointmentsReducer } from './serviceAppointments/serviceAppointments.reducer';
import { vehiclesReducer } from './vehicles/vehicles.reducer';

export const coxReducer = combineReducers({
  vehicles: vehiclesReducer,
  serviceAppointments: serviceAppointmentsReducer,
  customer: customerReducer,
  repairOrders: repairOrdersReducer,
  parts: partsReducer,
});
