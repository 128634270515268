import { Store } from 'redux';
import { ClioActionFacade } from '../clio/clio.action.facade';
import ClioActionCreatorFactory from '../clio/clio.actionCreator.factory';
import { ConnectWiseActionFacade } from '../connectwise/connectwise.action.facade';
import ConnectWiseActionCreatorFactory from '../connectwise/connectwise.actionCreator.factory';
import { logger } from '../logging';
import { Integrations } from '../models';
import { RedtailActionFacade } from '../redtail/redtail.action.facade';
import RedtailActionCreatorFactory from '../redtail/redtail.actionCreator.factory';
import { AppState } from '../reducers';
import ZendeskActionCreatorFactory from '../zendesk/zendesk.actionCreator.factory';
import { ActionFacade } from './action.facade';
import { SalesforceLightningActionFacade } from '../salesforce/salesforceLightning.actionfacade';
import { ActionCreatorFactory } from './actionCreator.factory';
import { salesforceService } from '../salesforce/salesforceLightning.service';
import { ZendeskActionFacade } from '../zendesk/zendesk.actionfacade';
import { SalesforceClassicActionFacade } from '../salesforce/salesforceClassic.actionfacade';
import SalesforceClassicActionCreatorFactory from '../salesforce/salesforceClassic.actionCreator.factory';
import SalesforceLightningActionCreatorFactory from '../salesforce/salesforceLightning.actionCreator.factory';
import { CoxActionFacade } from '../cox/cox.action.facade';
import CoxActionCreatorFactory from '../cox/cox.actionCreator.factory';
import { storeActionFacade } from './action.facade.store';
import DynamicsActionCreatorFactory from '../dynamics/dynamics.actionCreator.factory';
import { DynamicsActionFacade } from '../dynamics/dynamics.actionfacade';

const createActionCreatorFactory = (store: Store<AppState>, integration: Integrations): ActionCreatorFactory => {
  switch (integration) {
    case Integrations.Clio:
      return new ClioActionCreatorFactory(store);
    case Integrations.SalesforceClassic:
      return new SalesforceClassicActionCreatorFactory(store, salesforceService);
    case Integrations.SalesforceLightning:
      return new SalesforceLightningActionCreatorFactory(store, salesforceService);
    case Integrations.Zendesk:
      return new ZendeskActionCreatorFactory(store);
    case Integrations.ConnectWise:
      return new ConnectWiseActionCreatorFactory(store);
    case Integrations.Redtail:
      return new RedtailActionCreatorFactory(store);
    case Integrations.Cox:
      return new CoxActionCreatorFactory(store);
    case Integrations.Dynamics:
      return new DynamicsActionCreatorFactory(store);
    default:
      logger.error(`ActionFacade could not be initialized, unknown integration`, { integration });
      throw Error("Integration is not identified, can't initialize ActionCreatorFactory");
  }
};

export const initializeActionFacade = (store: Store<AppState>, integration: Integrations) => {
  const actionCreatorFactory = createActionCreatorFactory(store, integration);
  let actionFacadeInstance: ActionFacade;

  switch (integration) {
    case Integrations.Zendesk:
      actionFacadeInstance = new ZendeskActionFacade(actionCreatorFactory as ZendeskActionCreatorFactory);
      break;
    case Integrations.ConnectWise:
      actionFacadeInstance = new ConnectWiseActionFacade(actionCreatorFactory as ConnectWiseActionCreatorFactory);
      break;
    case Integrations.SalesforceLightning:
      actionFacadeInstance = new SalesforceLightningActionFacade(
        actionCreatorFactory as SalesforceLightningActionCreatorFactory,
      );
      break;
    case Integrations.SalesforceClassic:
      actionFacadeInstance = new SalesforceClassicActionFacade(
        actionCreatorFactory as SalesforceClassicActionCreatorFactory,
      );
      break;
    case Integrations.Clio:
      actionFacadeInstance = new ClioActionFacade(actionCreatorFactory as ClioActionCreatorFactory);
      break;
    case Integrations.Redtail:
      actionFacadeInstance = new RedtailActionFacade(actionCreatorFactory as RedtailActionCreatorFactory);
      break;
    case Integrations.Cox:
      actionFacadeInstance = new CoxActionFacade(actionCreatorFactory);
      break;
    case Integrations.Dynamics:
      actionFacadeInstance = new DynamicsActionFacade(actionCreatorFactory as DynamicsActionCreatorFactory);
      break;
    default:
      logger.error(`ActionFacade could not be initialized, unknown integration`, { integration });
      throw Error("Integration is not identified, can't initialize ActionFacade");
  }

  storeActionFacade(actionFacadeInstance);
};
