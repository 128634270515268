import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { CompanyPickerState, GetCompaniesRequestState } from './companyPicker.models';
import { Company } from '../connectwise.model';

const companiesSelector = (state: AppState): CompanyPickerState => state.connectWise?.companies;

export const companyListSelector: (state: AppState) => Company[] = createSelector(
  companiesSelector,
  (result) => result?.companies || [],
);

export const getCompaniesRequestStateSelector: (state: AppState) => GetCompaniesRequestState = createSelector(
  companiesSelector,
  (result) => result?.requestState || GetCompaniesRequestState.INITIAL,
);
