import { Reducer } from 'redux';
import { ChangelogActions, ChangelogActionTypes } from './changelog.actions';

export enum ChangelogRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

interface ChangelogState {
  lastSeenVersion?: string;
  lastSeenAnnouncement?: string;
  lastSeenVersionLoadingState: ChangelogRequestState;
  lastSeenAnnouncementLoadingState: ChangelogRequestState;
  hasNewAnnouncement: boolean;
}

const INITIAL_STATE: ChangelogState = {
  lastSeenVersion: undefined,
  lastSeenAnnouncement: undefined,
  lastSeenVersionLoadingState: ChangelogRequestState.INITIAL,
  lastSeenAnnouncementLoadingState: ChangelogRequestState.INITIAL,
  hasNewAnnouncement: false,
};

export const changelogReducer: Reducer<ChangelogState, ChangelogActions> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ChangelogActionTypes.LOAD_LAST_SEEN_VERSION_SUCCESS:
    case ChangelogActionTypes.SAVE_LAST_SEEN_VERSION: {
      return {
        ...state,
        lastSeenVersion: action.payload,
        lastSeenVersionLoadingState: ChangelogRequestState.LOADED,
      };
    }
    case ChangelogActionTypes.LOAD_LAST_SEEN_VERSION: {
      return {
        ...state,
        lastSeenVersionLoadingState: ChangelogRequestState.LOADING,
      };
    }
    case ChangelogActionTypes.LOAD_LAST_SEEN_VERSION_ERROR: {
      return {
        ...state,
        lastSeenVersionLoadingState: ChangelogRequestState.FAILED,
      };
    }
    case ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT_SUCCESS:
    case ChangelogActionTypes.SAVE_LAST_SEEN_ANNOUNCEMENT: {
      return {
        ...state,
        lastSeenAnnouncement: action.payload,
        lastSeenAnnouncementLoadingState: ChangelogRequestState.LOADED,
      };
    }
    case ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT: {
      return {
        ...state,
        lastSeenAnnouncementLoadingState: ChangelogRequestState.LOADING,
      };
    }
    case ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT_ERROR: {
      return {
        ...state,
        lastSeenAnnouncementLoadingState: ChangelogRequestState.FAILED,
      };
    }
    case ChangelogActionTypes.SET_HAS_NEW_ANNOUNCEMENT: {
      return {
        ...state,
        hasNewAnnouncement: action.payload,
      };
    }
    default:
      return state;
  }
};
