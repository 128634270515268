import { Store } from 'redux';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { logger } from '../logging';
import { AppState } from '../reducers';
import {
  loadLastSeenAnnouncement,
  loadLastSeenAnnouncementError,
  loadLastSeenAnnouncementSuccess,
  loadLastSeenVersion,
  loadLastSeenVersionError,
  loadLastSeenVersionSuccess,
  saveLastSeenAnnouncement,
  saveLastSeenVersion,
  setHasNewAnnouncement,
} from './changelog.actions';
import { changelogVersionManager } from './changelog.versionmanager';

export class ChangelogActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async loadLastSeenVersion(): Promise<void> {
    this.store.dispatch(loadLastSeenVersion());

    try {
      const lastSeenVersion = await changelogVersionManager.getLastSeenVersion();
      this.store.dispatch(loadLastSeenVersionSuccess(lastSeenVersion));
    } catch (error) {
      logger.error('Unable to get the last seen changelog entry.', error);
      this.store.dispatch(loadLastSeenVersionError());
    }
  }

  public async saveLastSeenVersion(): Promise<void> {
    try {
      const integration = currentIntegrationSelector(this.store.getState());
      await changelogVersionManager.storeLastSeenVersion(integration);
      const latestVersion = await changelogVersionManager.getLatestVersion(integration);
      this.store.dispatch(saveLastSeenVersion(latestVersion));
    } catch (error) {
      logger.error('Unable to store the last seen version', error);
    }
  }

  public async loadLastSeenAnnouncement(): Promise<void> {
    this.store.dispatch(loadLastSeenAnnouncement());

    try {
      const lastSeenAnnouncement = await changelogVersionManager.getLastSeenAnnouncement();
      this.store.dispatch(loadLastSeenAnnouncementSuccess(lastSeenAnnouncement));
    } catch (error) {
      logger.error('Unable to get the last seen announcement.', error);
      this.store.dispatch(loadLastSeenAnnouncementError());
    }
  }

  public async saveLastSeenAnnouncement(): Promise<void> {
    try {
      const integration = currentIntegrationSelector(this.store.getState());
      await changelogVersionManager.storeLastSeenAnnouncement(integration);
      const latestVersion = await changelogVersionManager.getLatestAnnouncement(integration);
      this.store.dispatch(saveLastSeenAnnouncement(latestVersion));
      this.store.dispatch(setHasNewAnnouncement(false));
    } catch (error) {
      logger.error('Unable to store the last seen announcement', error);
    }
  }
}
