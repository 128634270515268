import React, { useEffect, useRef, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl, MessageDescriptor } from 'react-intl';
import { Autocomplete, Tooltip } from '@getgo/chameleon-material-ui';
import { makeStyles } from '@material-ui/styles';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { definedMessages as commonDefinedMessages } from '../../inAppNotification/message.content';
import { Entity } from '../../models';
import { uuid } from '../../uuid';
import { Matter, mattersSorterByClientId } from '../clio.models';
import { GetMattersRequestState } from './matter.models';

export interface MatterPickerProps {
  value?: number;
  onMatterSelected: (matterId?: number) => void;
  autoFocus?: boolean;
  entity?: Entity;
  callId?: string;
  trackingCategory: AnalyticsCategory;
  helperText?: MessageDescriptor;
}

export interface MatterPickerStateProps {
  matters: Matter[];
  requestState: GetMattersRequestState;
}

export interface MatterPickerDispatchProps {
  searchMatters: (query: string) => void;
  clearMatters: () => void;
}

const definedMessages = defineMessages({
  MATTER_PICKER_LOADING_MESSAGE: {
    id: 'MatterPicker.Loading',
    defaultMessage: 'Loading...',
    description: 'Message that is displayed while the list of matters is loaded from the CRM.',
  },
  MATTER_PICKER_EMPTY_MESSAGE: {
    id: 'MatterPicker.Empty',
    defaultMessage: 'No matter found',
    description: 'Message that is displayed when there are no matters in the CRM.',
  },
  MATTER_PICKER_MATTER: {
    id: 'MatterPicker.Matter',
    defaultMessage: 'Choose matter',
    description: 'Select the matter to assign the activity to.',
  },
});

const getTrackingEvents = (category: AnalyticsCategory) =>
  defineTrackingEvents({
    MATTER_LOAD_STARTED: {
      category,
      action: AnalyticsAction.MatterLoadStarted,
      label: '-',
    },
    MATTER_SEARCH_STARTED: {
      category,
      action: AnalyticsAction.MatterSearchStarted,
      label: '-', // will be updated dynamically
    },
    MATTER_SELECTED: {
      category,
      action: AnalyticsAction.ItemChanged,
      label: 'Matter | select',
    },
  });

const useStyles = makeStyles(() => ({
  option: {
    whiteSpace: 'nowrap',
  },
  optionText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

export const MatterPickerComponent: React.FC<MatterPickerStateProps &
  MatterPickerDispatchProps &
  MatterPickerProps> = ({
  searchMatters,
  clearMatters,
  requestState,
  matters,
  autoFocus,
  entity,
  value,
  onMatterSelected,
  trackingCategory,
  helperText,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const trackingEvents = useMemo(() => getTrackingEvents(trackingCategory), [trackingCategory]);

  useEffect(() => {
    searchMatters('');
    newTracker.trackAnalyticsEvent(trackingEvents.MATTER_LOAD_STARTED);
  }, [searchMatters, trackingEvents]);

  useEffect(() => {
    return () => {
      clearMatters();
    };
  }, [clearMatters]);

  useEffect(() => {
    if (canvasContextRef.current) {
      canvasContextRef.current.font = getComputedStyle(document.body, 'font').font;
    }
  }, []);

  const canvasContextRef = useRef<CanvasRenderingContext2D>(document.createElement('canvas').getContext('2d'));
  const autocompleteRef = useRef<HTMLElement>();

  const isLoading = requestState === GetMattersRequestState.LOADING;

  const id = uuid();

  if (entity) {
    matters.sort(mattersSorterByClientId(entity.id));
  }

  return (
    <Autocomplete
      id={`textfield-matter-picker-${id}`}
      autocompleteRef={autocompleteRef}
      classes={{ option: classes.option }}
      options={matters}
      getOptionSelected={(matters, selected) => matters.id === selected.id}
      value={matters.find((m) => m.id === value) ?? null}
      openOnFocus
      getOptionLabel={(matter) => matter.title}
      onInputChange={(_, value, reason) => {
        if (reason === 'input' && !!value) {
          searchMatters(value || '');
          newTracker.trackAnalyticsEvent({
            ...trackingEvents.MATTER_SEARCH_STARTED,
            label: (value || '').length.toString(),
          });
        } else if (reason === 'clear') {
          // reload all list when selected item is cleared
          searchMatters('');
        }
      }}
      loading={isLoading}
      onChange={(_, matter) => {
        onMatterSelected(matter?.id);
        newTracker.trackAnalyticsEvent(trackingEvents.MATTER_SELECTED);
      }}
      loadingText={<FormattedMessage {...definedMessages.MATTER_PICKER_LOADING_MESSAGE} />}
      noOptionsText={<FormattedMessage {...definedMessages.MATTER_PICKER_EMPTY_MESSAGE} />}
      filterOptions={(options) => options}
      TextFieldProps={{
        autoFocus,
        noMargin: true,
        placeholder: intl.formatMessage(commonDefinedMessages.FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER),
        label: intl.formatMessage(definedMessages.MATTER_PICKER_MATTER),
        name: `matter-picker-${id}`,
        helperText: helperText ? intl.formatMessage(helperText) : null,
      }}
      renderOption={(option) => {
        const textWidth = canvasContextRef.current?.measureText(option.title).width;
        const autoCompleteWidth = autocompleteRef.current?.offsetWidth;
        // Since I don't know how accurate these calculations are, I subtract this value from the
        // length of the autocomplete and if the text's width is bigger than that, I wrap it with a <Tooltip>
        const autoCompleteSafetyMargin = 20; // px

        const canCalculateOverflow = textWidth && autoCompleteWidth;
        // If we substitue 'autoCompleteWidth ? textWidth' with 'canCalculateOverflow' typescript cannot infer that in
        // the first part of the ternary 'textWidth' and 'autoCompleteWidth' cannot be undefined.
        const isTextOverflowing =
          textWidth && autoCompleteWidth ? textWidth > autoCompleteWidth - autoCompleteSafetyMargin : false;

        if (!canCalculateOverflow || isTextOverflowing) {
          return (
            <Tooltip title={option.title} enterDelay={800}>
              <p className={classes.optionText}>{option.title}</p>
            </Tooltip>
          );
        } else {
          return <p className={classes.optionText}>{option.title}</p>;
        }
      }}
    />
  );
};
