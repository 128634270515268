import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { VehiclesStateProps, VehiclesComponent } from './Vehicles.component';
import { loadVehiclesRequestStateSelector, vehiclesSelector } from './vehicles.selectors';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';
import { dealerTrackEntitiesSelector, selectedDateFnsLocaleSelector } from '../../settings/settings.selector';

const mapStateToProps = (state: AppState): VehiclesStateProps => ({
  dealerTrackSettings: dealerTrackEntitiesSelector(state),
  requestState: loadVehiclesRequestStateSelector(state),
  vehicles: vehiclesSelector(state),
  locale: selectedDateFnsLocaleSelector(state),
});

export const Vehicles = connect<VehiclesStateProps, {}, CallContextPanelProps, AppState>(mapStateToProps)(
  VehiclesComponent,
);
