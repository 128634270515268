import axios from 'axios';
import { Entitlements } from '../authentication/authentication.models';
import configuration from '../config';
import { logger } from '../logging';

export enum IdentityServicePath {
  WHO_AM_I = '/identity/v1/Users/me',
}

export interface WhoAmIResponse {
  id: string;
  displayName: string;
  userName: string;
  entitlements: Entitlements[];
}

async function whoAmI(token: string): Promise<WhoAmIResponse> {
  if (!token) {
    throw new Error('Token is mandatory to call the whoAmI API');
  }

  try {
    const { baseUrl } = configuration.identityService;
    const response = await axios.get<{
      id: string;
      displayName: string;
      userName: string;
      entitlements: Entitlements[];
    }>(IdentityServicePath.WHO_AM_I, {
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    logger.error('Error calling LMI whoAmI endpoint', e);
    throw e;
  }
}

export const IdentityService = {
  whoAmI,
};
