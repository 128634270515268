import { Reducer } from 'redux';
import { ActionTypes } from '../actions/actionCreators';
import * as settingsPersistenceActions from './settings.persistence.action';
import { AuthenticationActions, AuthenticationActionsType } from '../authentication/authentication.actions';
import { MsTeamsAuthenticationActionsType } from '../msteams/constants';
import { MsTeamsAuthenticationActions } from '../msteams/auth/authentication.actions';

export type SettingPersistence = 'initial' | 'loaded' | 'loading' | 'missing';

export interface SettingsPersistenceState {
  persistenceState: SettingPersistence;
  wasSettingsStateMissing: boolean;
}

const initialState: SettingsPersistenceState = { persistenceState: 'initial', wasSettingsStateMissing: false };

export const settingsPersistenceStateReducer: Reducer<
  SettingsPersistenceState,
  ActionTypes<typeof settingsPersistenceActions> | AuthenticationActions | MsTeamsAuthenticationActions
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActionsType.LOGOUT_SUCCESS:
    case MsTeamsAuthenticationActionsType.SIGNOUT_SUCCESS: {
      return { ...state, persistenceState: 'initial' };
    }
    case 'settingState/missing':
      return { ...state, persistenceState: 'missing', wasSettingsStateMissing: true };
    case 'settingState/loading':
      return { ...state, persistenceState: 'loading' };
    case 'settingState/loaded':
      return { ...state, persistenceState: 'loaded' };
  }
  return state;
};
