import { Store } from 'redux';
import { CAPABILITY_CONVERSATIONS } from '../actionFacade/action.facade.capabilities';
import { getActionFacade } from '../actionFacade/action.facade.store';
import {
  jiveIdSelector,
  displayNameSelector,
  externalUserKeySelector,
} from '../authentication/authentication.selector';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { selectedLanguageSelector } from '../settings/settings.selector';
import {
  messagingExperienceFirstMessageLogSelector,
  messagingExperienceFirstVisitSelector,
  messagingExperienceNumberOfLogsSelector,
  messagingExperienceNumberOfMessagesLoggedSelector,
} from './survey.selectors';

export class NpsProperties {
  constructor(private store: Store<AppState>) {}

  // Based on: https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/translate-survey/#AvailableLanguageCodes
  private languageCodeMaps: Record<string, string> = {
    'en-US': 'EN',
    'fr-CA': 'FR-CA',
    'es-MX': 'ES',
    'de-DE': 'DE',
    'pt-BR': 'PT-BR',
  };

  get email() {
    return jiveIdSelector(this.store.getState());
  }

  get name() {
    return displayNameSelector(this.store.getState());
  }

  get selectedLanguage() {
    const locale = selectedLanguageSelector(this.store.getState());
    return this.languageCodeMaps[locale] ?? 'EN';
  }

  get externalUserKey() {
    return externalUserKeySelector(this.store.getState());
  }

  get integrationName() {
    return currentIntegrationNameSelector(this.store.getState());
  }

  get isMessagingEnabled() {
    return getActionFacade().isCapable(CAPABILITY_CONVERSATIONS);
  }

  get firstMessagingExperienceVisit() {
    return messagingExperienceFirstVisitSelector(this.store.getState());
  }

  get firstMessageLog() {
    return messagingExperienceFirstMessageLogSelector(this.store.getState());
  }

  get numberOfMessagesLogged() {
    return messagingExperienceNumberOfMessagesLoggedSelector(this.store.getState());
  }

  get numberOfMessageLogOperations() {
    return messagingExperienceNumberOfLogsSelector(this.store.getState());
  }

  get useRebranding2021() {
    return true;
  }
}
