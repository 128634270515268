import { combineReducers } from 'redux';
import { OpportunityActions, OpportunityActionsType } from './opportunity.actions';
import {
  GetMoreOpportunitiesRequestState,
  GetOpportunitiesRequestState,
  GetOpportunitiesState,
} from './opportunity.models';

export const INITIAL_STATE: GetOpportunitiesState = {
  requestState: GetOpportunitiesRequestState.INITIAL,
  moreRequestState: GetMoreOpportunitiesRequestState.INITIAL,
  opportunities: [],
  opportunitiesCount: undefined,
};

export const opportunitiesListReducer = (
  state: GetOpportunitiesState = INITIAL_STATE,
  action: OpportunityActions,
): GetOpportunitiesState => {
  switch (action.type) {
    case OpportunityActionsType.RESET:
      return {
        ...INITIAL_STATE,
      };

    case OpportunityActionsType.GET_OPPORTUNITIES:
      return {
        ...state,
        moreRequestState: GetMoreOpportunitiesRequestState.INITIAL,
        requestState: GetOpportunitiesRequestState.LOADING,
      };

    case OpportunityActionsType.GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        moreRequestState: GetMoreOpportunitiesRequestState.INITIAL,
        requestState: GetOpportunitiesRequestState.LOADED,
        requestMeta: action.payload.meta,
        opportunities: action.payload.opportunities,
        opportunitiesCount: action.payload.meta.opportunitiesCount,
      };

    case OpportunityActionsType.GET_OPPORTUNITIES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreOpportunitiesRequestState.INITIAL,
        requestState: GetOpportunitiesRequestState.FAILED,
      };

    case OpportunityActionsType.GET_MORE_OPPORTUNITIES:
      return {
        ...state,
        moreRequestState: GetMoreOpportunitiesRequestState.LOADING,
      };

    case OpportunityActionsType.GET_MORE_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        requestMeta: action.payload.meta,
        opportunities: [...state.opportunities, ...action.payload.opportunities],
        moreRequestState: GetMoreOpportunitiesRequestState.LOADED,
      };

    case OpportunityActionsType.GET_MORE_OPPORTUNITIES_ERROR:
      return {
        ...state,
        moreRequestState: GetMoreOpportunitiesRequestState.FAILED,
      };
    case OpportunityActionsType.GET_OPPORTUNITIES_COUNT:
      return {
        ...state,
        opportunitiesCount: undefined,
      };
    case OpportunityActionsType.GET_OPPORTUNITIES_COUNT_SUCCESS:
      return {
        ...state,
        opportunitiesCount: action.payload.opportunitiesCount,
      };
    case OpportunityActionsType.GET_OPPORTUNITIES_COUNT_ERROR:
      return {
        ...state,
        opportunitiesCount: undefined,
      };

    default:
      return state;
  }
};

export default combineReducers({
  opportunitiesList: opportunitiesListReducer,
});
