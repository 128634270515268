import { TextField } from '@getgo/chameleon-material-ui';
import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { newTracker } from '../analytics-new/tracker-new';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { logger } from '../logging';

export interface TextInputFieldProps {
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  labelDescription: MessageDescriptor;
  value: string;
  maxLength?: number;
  autoFocus?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  trackingEvent?: TrackingEvent;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  helperText?: React.ReactNode;
  noMargin?: boolean;
  name: string;
}

export const TextInputField: React.FC<TextInputFieldProps> = ({
  required,
  onChange,
  labelDescription,
  value,
  maxLength,
  autoFocus,
  onBlur,
  trackingEvent,
  multiline,
  rows,
  error,
  helperText,
  noMargin,
  name,
}) => {
  const requiredSign = <sup>*</sup>;
  const label = <FormattedMessage {...labelDescription} />;
  const [hasValueChanged, setChanged] = useState<boolean>(false);
  const [isTrackedAlready, setTracked] = useState<boolean>(false);

  const isRequired = required === undefined ? true : required;

  return (
    <TextField
      name={name}
      noMargin={noMargin}
      error={error}
      required={isRequired}
      multiline={multiline}
      size={multiline ? 'area' : 'medium'}
      rows={rows}
      label={
        isRequired ? (
          <>
            {label} {requiredSign}
          </>
        ) : (
          label
        )
      }
      helperText={helperText}
      fullWidth
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        setChanged(true);
      }}
      onBlur={(event) => {
        if (trackingEvent && hasValueChanged && !isTrackedAlready) {
          try {
            newTracker.trackAnalyticsEvent(trackingEvent);
          } catch (error) {
            logger.error('Error while tracking text input', error);
          }
          setTracked(true);
        }
        if (onBlur) {
          onBlur(event);
        }
      }}
      inputProps={{
        maxLength: String(maxLength || 128),
      }}
      autoFocus={autoFocus}
    />
  );
};
