import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { Grid } from '@material-ui/core';
import React from 'react';
import { PhoneDownActiveIcon, PhoneUpActiveIcon } from '@getgo/chameleon-icons/react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { SoftphoneCall } from './softphone.model';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SalesforceLightningActionFacade } from '../salesforce/salesforceLightning.actionfacade';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';

export interface ScreenPopSoftphoneControlsProps {
  call: SoftphoneCall;
}

const trackingEvents = defineTrackingEvents({
  ANSWER: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Answer',
  },
  HANG_UP: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Hang up',
  },
});

const definedMessages = defineMessages({
  ANSWER: {
    id: 'Softphone.Answer',
    defaultMessage: 'Answer',
  },
  DECLINE: {
    id: 'Softphone.Decline',
    defaultMessage: 'Decline',
  },
  HANG_UP: {
    id: 'Softphone.HangUp',
    defaultMessage: 'Hang up',
  },
});

export const SoftphoneCallHandlingButtons: React.FC<ScreenPopSoftphoneControlsProps> = ({ call }) => {
  const isIncomingNotAnsweredCall = call.direction === 'incoming' && call.callState !== 'answered';

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <TrackedButton
          trackingEvent={trackingEvents.HANG_UP}
          onClick={() =>
            isIncomingNotAnsweredCall
              ? getActionFacade<SalesforceLightningActionFacade>().rejectCall(call.id)
              : getActionFacade<SalesforceLightningActionFacade>().hangupCall(call.id)
          }
          variant="danger"
          size="small"
          fullwidth
          leadingIcon={<PhoneDownActiveIcon />}
        >
          {isIncomingNotAnsweredCall ? (
            <FormattedMessage {...definedMessages.DECLINE} />
          ) : (
            <FormattedMessage {...definedMessages.HANG_UP} />
          )}
        </TrackedButton>
      </Grid>
      {isIncomingNotAnsweredCall ? (
        <Grid item xs>
          <TrackedButton
            trackingEvent={trackingEvents.ANSWER}
            onClick={() => getActionFacade<SalesforceLightningActionFacade>().answerCall(call.id)}
            variant="success"
            size="small"
            fullwidth
            leadingIcon={<PhoneUpActiveIcon />}
          >
            <FormattedMessage {...definedMessages.ANSWER} />
          </TrackedButton>
        </Grid>
      ) : null}
    </Grid>
  );
};
