import { combineReducers, Reducer } from 'redux';
import { MsTeamsAuthenticationActions } from '../msteams/auth/authentication.actions';
import { MsTeamsAuthenticationActionsType } from '../msteams/constants';
import { SalesforceActions, SalesforceActionType } from '../salesforce/salesforce.actions';
import { AuthenticationActions, AuthenticationActionsType } from './authentication.actions';
import { Entitlements } from './authentication.models';

interface AuthenticationState {
  authenticated: boolean;
  token: string;
  jiveId: string;
  externalUserKey: string;
  expiration: number;
  name: string;
  entitlements: Entitlements[];
  seenLicenseWarning?: boolean;
}
export const AUTHENTICATION_INITIAL_STATE: AuthenticationState = {
  authenticated: false,
  token: '',
  jiveId: '',
  externalUserKey: '',
  expiration: 0,
  name: '',
  entitlements: [],
  seenLicenseWarning: undefined,
};

const loginReducer: Reducer<
  AuthenticationState,
  AuthenticationActions | MsTeamsAuthenticationActions | SalesforceActions
> = (state = AUTHENTICATION_INITIAL_STATE, action) => {
  switch (action.type) {
    case MsTeamsAuthenticationActionsType.SIGNIN_SUCCESS:
      const { token, jiveId, externalUserKey, name, entitlements } = action.payload;
      return {
        authenticated: true,
        token,
        jiveId,
        externalUserKey,
        expiration: Date.now() + 604800000, // 7 days in ms,
        name,
        entitlements,
      };
    case AuthenticationActionsType.LOGIN_SUCCESS: {
      const { token, jiveId, externalUserKey, expiration, name, entitlements } = action.payload;
      return {
        authenticated: true,
        token,
        jiveId,
        externalUserKey,
        expiration,
        name,
        entitlements,
      };
    }
    case MsTeamsAuthenticationActionsType.SIGNIN_ERROR:
    case AuthenticationActionsType.LOGIN_ERROR:
      return {
        authenticated: false,
        token: '',
        jiveId: '',
        externalUserKey: '',
        expiration: 0,
        name: '',
        entitlements: [],
      };

    case MsTeamsAuthenticationActionsType.SIGNOUT_SUCCESS:
    case AuthenticationActionsType.LOGOUT_SUCCESS:
      return {
        authenticated: false,
        token: '',
        jiveId: '',
        externalUserKey: '',
        expiration: 0,
        name: '',
        entitlements: [],
      };
    case SalesforceActionType.SEE_LICENSE_WARNING:
      return {
        ...state,
        seenLicenseWarning: true,
      };
    default:
      return state;
  }
};

const authenticationReducer = combineReducers({
  result: loginReducer,
});

export default authenticationReducer;
