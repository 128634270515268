import React from 'react';
import { Box, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { TrackingEvent } from '../../analytics-new/analytics.models';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';
import { EntityType } from '../../constants';
import { Entity } from '../../models';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';
import { AppState } from '../../reducers';
import { createEntity } from '../../search/entities.action';
import { Typography } from '../../theme/Typography.component';
import { getNumberOfTheOtherParty } from '../call.helper';
import { CallWithContact } from '../calls.reducer';
import { callSelector } from '../calls.selector';
import { Dispatch } from 'redux';
import { Tooltip } from '@getgo/chameleon-material-ui';
import { reactStyling } from '@getgo/chameleon-core';
import { newTracker } from '../../analytics-new/tracker-new';
import { logger } from '../../logging';

export interface CallActionHandlerParams {
  contact?: Entity;
  call?: CallWithContact;
  dispatch: Dispatch<any>;
}

export interface CallActionProps {
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  label: MessageDescriptor;
  entityType?: EntityType;
  call?: CallWithContact;
  contact?: Entity;
  action?: (input: CallActionHandlerParams) => void;
  trackingEvent: TrackingEvent;
  variant?: 'primary' | 'secondary' | 'custom';
}

const useStyles = makeStyles((theme: Theme) => {
  const {
    button,
    buttonTertiaryNeutralSmall,
    buttonTertiaryNeutralSmallHover,
    buttonTertiaryNeutralSmallFocus,
    buttonTertiaryNeutralSmallActive,
  } = reactStyling(undefined as any);
  return createStyles({
    secondaryCallActionButton: ({ isNarrowIntegration }: { isNarrowIntegration: boolean }) => {
      const commonStyles = {
        ...button,
        ...buttonTertiaryNeutralSmall,
        '&:hover': {
          ...buttonTertiaryNeutralSmallHover,
        },
        '&:active': {
          ...buttonTertiaryNeutralSmallActive,
        },
        '&:focus': {
          ...buttonTertiaryNeutralSmallFocus,
        },
      };
      if (isNarrowIntegration) {
        return {
          ...commonStyles,
          height: 'auto',
          padding: theme.spacing(1),
          minWidth: '55px !important',
        };
      }

      return {
        ...commonStyles,
        height: 'auto',
        padding: theme.spacing(1),
      };
    },
    secondaryCallActionButtonIcon: {
      color: tokens.text02,
      '& path[fill]': {
        fill: tokens.text02,
      },
    },
    secondaryCallActionButtonLabel: ({ isNarrowIntegration }: { isNarrowIntegration: boolean }) => {
      const base = {
        fontSize: '0.7rem',
        lineHeight: '0.9rem',
      };

      if (!isNarrowIntegration) {
        return base;
      }

      return {
        ...base,
        maxWidth: '60px',
        overflow: 'hidden',
      };
    },
  });
});

export const CallActionButton: React.FC<CallActionProps> = ({
  leadingIcon,
  trailingIcon,
  label,
  action,
  entityType,
  call,
  contact,
  trackingEvent,
  variant = 'primary',
}) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });
  const dispatch = useDispatch();
  const store = useStore<AppState>();

  if (!call) {
    return null;
  }

  const state = store.getState();

  const activeCall = callSelector(state, call.id);
  const isActiveCall = Boolean(activeCall && !activeCall.endTime);
  const phoneNumber = getNumberOfTheOtherParty(call);

  const defaultAction = () => {
    if (!entityType) {
      return;
    }

    dispatch(
      createEntity({
        isActiveCall,
        entityName: entityType,
        defaultFieldValues: { Phone: phoneNumber },
        callId: call.id,
      }),
    );
  };
  const actionWithFallback = action ?? defaultAction;

  if (variant === 'custom') {
    return (
      <Tooltip arrow title={<FormattedMessage {...label} />} enterDelay={500}>
        <Box>
          <button
            className={classes.secondaryCallActionButton}
            onClick={() => {
              try {
                newTracker.trackAnalyticsEvent(trackingEvent);
              } catch (error) {
                logger.error('Error while tracking secondary call action button', error);
              }
              actionWithFallback({ contact, call, dispatch });
            }}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item className={classes.secondaryCallActionButtonIcon}>
                {leadingIcon}
              </Grid>
              <Grid item>
                <Typography variant="body-small">
                  <span className={classes.secondaryCallActionButtonLabel}>
                    <FormattedMessage {...label} />
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </button>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip arrow title={<FormattedMessage {...label} />} enterDelay={500}>
      <Box>
        <TrackedButton
          onClick={() => actionWithFallback({ contact, call, dispatch })}
          trackingEvent={trackingEvent}
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
          variant={variant}
          fullwidth
        >
          <FormattedMessage {...label} />
        </TrackedButton>
      </Box>
    </Tooltip>
  );
};
