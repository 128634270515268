import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { AppMenu } from '../menu/Menu.component';
import { AppLayoutProps } from './AppLayout.models';
import { useWideIntegrationDetector } from '../theme/wideIntegrationDetector';
import { Typography } from '../theme/Typography.component';
import { AppLogo } from './AppLogo';

const appContainerCssProperties = {
  display: 'grid',
  gridTemplateRows: '[header] 48px [top-margin] 8px [app] auto [bottom-margin] 0px',
  gridTemplateAreas: '"logo . header ."\n    "menu . . ."\n    "menu . body ."\n    "menu . . ."',
  width: '100%',
  height: '100%',
  minHeight: '420px',
  '&.no-header': {
    gridTemplateAreas: '"logo . body ."\n    "menu . body ."\n    "menu . body ."\n    "menu . body ."',
  },
};

interface LayoutStyleProps {
  layoutMargin: number;
}

const useStyles = makeStyles(() => ({
  appContainer: () => ({
    ...appContainerCssProperties,
    gridTemplateColumns: `[menu] 48px [left-margin] 0 [app] auto [right-margin] 0`,
  }),
  appContainerWithoutLogo: () => ({
    ...appContainerCssProperties,
    gridTemplateColumns: `[menu] 48px [left-margin] 0 [app] auto [right-margin] 0`,
    gridTemplateAreas: '"menu . header ."\n    "menu . . ."\n    "menu . body ."\n    "menu . . ."',
    '&.no-header': {
      gridTemplateAreas: '"menu . body ."\n    "menu . body ."\n    "menu . body ."\n    "menu . body ."',
    },
  }),
  appBody: { display: 'flex', gridArea: 'body', overflow: 'auto' },
  pageContainer: (props: LayoutStyleProps) => ({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    paddingTop: 4,
    paddingRight: `${props.layoutMargin}px`,
    paddingLeft: `${props.layoutMargin}px`,
  }),
  appHeader: (props: LayoutStyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: `${props.layoutMargin}px`,
    marginTop: '5px',
  }),
  logo: {
    width: '48px',
    height: '48px',
  },
  appMenu: { gridArea: 'menu', display: 'flex', flexDirection: 'column' },
}));

export const AppLayout: React.FC<AppLayoutProps> = ({
  title,
  main,
  routes,
  navigationDisabled,
  bottomRoutes,
  renderedPath,
  hideLogo,
}) => {
  const isWideIntegration = useWideIntegrationDetector();

  const classes = useStyles({
    layoutMargin: isWideIntegration ? 24 : 12,
  });

  return (
    <div
      data-cy="app-layout"
      className={classNames(hideLogo ? classes.appContainerWithoutLogo : classes.appContainer, !title && 'no-header')}
    >
      <AppLogo hideLogo={hideLogo} />
      {title && (
        <header role="banner" style={{ display: 'flex', gridArea: 'header' }}>
          <div className={classes.appHeader}>
            <Typography variant="heading-small" data-cy="app-title" tag="h1">
              {title}
            </Typography>
          </div>
        </header>
      )}

      <nav className={classes.appMenu} role="navigation">
        <AppMenu
          renderedPath={renderedPath}
          routes={routes}
          bottomRoutes={bottomRoutes}
          disabled={navigationDisabled}
        />
      </nav>

      <main role="main" className={classes.appBody}>
        <div data-cy="page-container" className={classes.pageContainer}>
          {main}
        </div>
      </main>
    </div>
  );
};
