import { ActionFacade } from '../actionFacade/action.facade';
import {
  AddNoteCapableFacade,
  DownloadNotesCapableFacade,
  CAPABILITY_ADD_NOTES,
  CAPABILITY_DOWNLOAD_NOTES,
} from '../actionFacade/action.facade.capabilities';
import { AddNotePayload } from '../notes/notes.models';
import { RedtailActivityActionCreator } from './activity/redtail.activity.actioncreator';
import { RedtailCategoryActionCreator } from './category/redtail.category.actioncreator';
import { RedtailNoteActionCreator } from './note/redtail.note.actioncreator';
import RedtailActionCreatorFactory from './redtail.actionCreator.factory';
import { RedtailActivityAddModel } from './redtail.model';

export class RedtailActionFacade extends ActionFacade implements DownloadNotesCapableFacade, AddNoteCapableFacade {
  [CAPABILITY_DOWNLOAD_NOTES]: true = true;
  [CAPABILITY_ADD_NOTES]: true = true;

  private noteActionCreator: RedtailNoteActionCreator;
  private categoriesActionCreator: RedtailCategoryActionCreator;
  private activityActionCreator: RedtailActivityActionCreator;

  constructor(actionCreatorFactory: RedtailActionCreatorFactory) {
    super(actionCreatorFactory);
    this.noteActionCreator = actionCreatorFactory.createNoteActionCreator();
    this.categoriesActionCreator = actionCreatorFactory.createCategoryActionCreator();
    this.activityActionCreator = actionCreatorFactory.createActivityActionCreator();
  }

  loadMostRecentNoteOfContact(contactId: string): Promise<void> {
    return this.noteActionCreator.loadMostRecentNoteOfContact(contactId);
  }

  loadMoreNotesOfContact(): Promise<void> {
    return this.noteActionCreator.loadMoreNotesOfContact();
  }

  getCategories(): Promise<void> {
    return this.categoriesActionCreator.getCategories();
  }

  addNote(note: AddNotePayload): Promise<void> {
    return this.noteActionCreator.addNote(note);
  }

  openActivityInCrm(activityId: string): void {
    this.activityActionCreator.openActivityInCrm(activityId);
  }

  addActivity(activity: RedtailActivityAddModel): Promise<void> {
    return this.activityActionCreator.addActivity(activity);
  }
}
