import { TrackingEvent } from './analytics.models';
import { newTracker } from './tracker-new';
import React from 'react';
import { logger } from '../logging';
import { Button, ButtonProps } from '@getgo/chameleon-web/react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface TrackedButtonProps extends ButtonProps {
  trackingEvent: TrackingEvent;
  loading?: boolean;
}

export const TrackedButton: React.FC<TrackedButtonProps> = ({
  trackingEvent,
  loading,
  onClick,
  ...otherButtonProps
}) => (
  <Button
    onClick={(event) => {
      try {
        newTracker.trackAnalyticsEvent(trackingEvent);
      } catch (error) {
        logger.error('Error while tracking button', error);
      }
      if (onClick) {
        onClick(event);
      }
    }}
    trailingIcon={loading ? <CircularProgress /> : otherButtonProps.trailingIcon}
    {...otherButtonProps}
  />
);
