import { AddNotePayload } from '../notes/notes.models';
import { Call } from '@jive/realtime-events';

export const CONNECTWISE_API_PATH = 'v4_6_release/apis/3.0';

export interface ConnectWiseContact {
  id: string;
  firstName: string;
  lastName: string;
  defaultPhoneType: string;
  defaultPhoneNbr: string;
}

export interface ConnectWiseCommunicationItemType {
  id: number;
  name: ConnectWisePhoneNumberType;
}

export interface ConnectWisePhoneNumber {
  id: number;
  type: ConnectWiseCommunicationItemType;
  value: string;
  extension?: string;
  defaultFlag: boolean;
}

export enum ConnectWisePhoneNumberType {
  Direct = 'Direct',
  Cell = 'Cell',
  Pager = 'Pager',
  Home = 'Home',
}

export interface ConnectWiseNote {
  id: number;
  contactId: number;
  text: string;
  type: ConnectWiseNoteType;
  _info: ConnectWiseNoteInfo;
}

export interface ConnectWiseNoteInfo {
  lastUpdated: string;
}

export interface ConnectWiseNoteType {
  id: number;
  name: string;
}

export interface ConnectWiseNewNote {
  contactId: number;
  text: string;
  type: ConnectWiseNoteType;
  flagged: boolean;
}

export enum GetNoteTypesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface Company {
  id: number;
  name: string;
}

export const ConnectWisePhoneNumberTypes = {
  [ConnectWisePhoneNumberType.Direct]: { id: 2, name: ConnectWisePhoneNumberType.Direct },
  [ConnectWisePhoneNumberType.Cell]: { id: 4, name: ConnectWisePhoneNumberType.Cell },
  [ConnectWisePhoneNumberType.Pager]: { id: 5, name: ConnectWisePhoneNumberType.Pager },
  [ConnectWisePhoneNumberType.Home]: { id: 6, name: ConnectWisePhoneNumberType.Home },
};

export interface AddConnectWiseNotePayload extends AddNotePayload {
  call: Call;
  noteTypeId: number;
  includeCallDetails: boolean;
}

export interface NewConnectWiseContact {
  firstName: string;
  lastName: string;
  communicationItems: ConnectWisePhoneNumber[];
}
