import { makeActionCreator } from '../actions/actionCreators';
import { CallWithContact } from './calls.reducer';

export enum PostCallActionsType {
  SAVE_CALL = 'callEvents/saveCall',
  SAVE_CALL_HISTORY = 'callEvents/saveCallHistory',
  UPDATE_ACTIVE_CALL = 'callEvents/updateActiveCall',
}

export interface CallLog {
  Id?: string;
  Subject: string;
  Description: string;
  EntityId: string;
  DurationInSeconds: number;
  CallId: string;
  CallDispositionValue?: string;
}

export interface SaveCallPayload {
  callLog?: CallLog;
  call: CallWithContact;
  shouldPersist: boolean;
}

export interface SaveCallHistoryPayload {
  call: CallWithContact;
}

export interface CallPayload {
  call: CallWithContact;
}

export const saveCall = makeActionCreator(PostCallActionsType.SAVE_CALL)<SaveCallPayload>();
export type SaveCallAction = ReturnType<typeof saveCall>;

export const saveCallHistory = makeActionCreator(PostCallActionsType.SAVE_CALL_HISTORY)<SaveCallHistoryPayload>();
export type SaveCallHistory = ReturnType<typeof saveCallHistory>;

export const updateActiveCall = makeActionCreator(PostCallActionsType.UPDATE_ACTIVE_CALL)<CallPayload>();
export type UpdateActiveCall = ReturnType<typeof updateActiveCall>;

export type PostCallActions = UpdateActiveCall | SaveCallAction | SaveCallHistory;
