import { createBrowserHistory, Location } from 'history';
import { match, matchPath } from 'react-router';
import { baseAppURI } from './config';
import {
  ApplicationRoute,
  CallActionType,
  PARAM_CALL_ACTION_ID,
  PARAM_CALLID,
  PARAM_CONVERSATION_ID,
} from './constants';
import { Integrations } from './models';

export const getBaseURIWithoutSlash = () => window.document.baseURI.replace(/\/$/, '');

export const history = createBrowserHistory({
  basename: baseAppURI,
});

history.listen((route) => console.info('history: route change', route));

export interface ActiveRoute {
  route: ApplicationRoute;
  match?: match;
}

export function getActiveRoute(location?: Location, exact: boolean = false): ActiveRoute {
  const locationPath = location?.pathname ?? history.location.pathname;

  for (const route of Object.values(ApplicationRoute)) {
    const match = matchPath(locationPath, { path: route, exact });

    if (match !== null) {
      return {
        route,
        match,
      };
    }
  }

  return { route: (location?.pathname as ApplicationRoute) ?? ApplicationRoute.UNKNOWN_ROUTE };
}

export function goToRoute(route: ApplicationRoute | string) {
  // Keep location search when route is changing. Mandatory for zendesk client and keep integration as well
  history.push({ pathname: route, search: history.location.search });
}

export function goToRouteWithParams(route: ApplicationRoute, params: { [id: string]: string }) {
  let routeComputed: string = route;
  Object.keys(params).forEach((param) => {
    routeComputed = routeComputed.replace(`:${param}`, params[param]);
  });

  goToRoute(routeComputed);
}

export const getRouteParams = (param: string) => {
  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get(param) as Optional<Integrations>;
};

export const getUrlIntegration = () => {
  // TODO: Find a better way to support other integrations, but it fixed it for msteams.
  const integrationUrlRegex = /^(\/embedded-integrations)?\/(msteams)\//;

  const matches = integrationUrlRegex.exec(window.location.pathname);

  if (matches?.some((m) => m === Integrations.MsTeams)) {
    return Integrations.MsTeams;
  }

  return undefined;
};

export const goToIntegration = (integration: string) => {
  history.push({ pathname: '/', search: `integration=${integration}` });
  // force to setup the app. Need to reacreate store and all of stuff
  // TODO find a smart solution to not do that
  window.location.reload();
};

export function getIntegration(): Integrations {
  return getRouteParams('integration') || getUrlIntegration() || Integrations.SalesforceLightning;
}

export function goToCallPage(callId: string) {
  const params = { [PARAM_CALLID]: callId };
  goToRouteWithParams(ApplicationRoute.CALL_ROUTE, params);
}

export function goToConversationPage(conversationId: string) {
  const params = { [PARAM_CONVERSATION_ID]: conversationId };
  goToRouteWithParams(ApplicationRoute.CONVERSATION_ROUTE, params);
}

export const goToCallActionRoute = (callId: string, actionType: CallActionType) => {
  const params = {
    [PARAM_CALLID]: callId,
    [PARAM_CALL_ACTION_ID]: actionType,
  };
  goToRouteWithParams(ApplicationRoute.CALL_ROUTE_WITH_ACTION, params);
};
