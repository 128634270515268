import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box } from '@material-ui/core';
import TrackedButton from '../../analytics/TrackedButton.container';
import { TrackingEventLabels, TrackingEventNames } from '../../analytics/tracker.models';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { Banner } from '../../inAppNotification/Banner';

const trackingEvents = defineTrackingEvents({
  CALL_LIMITATIONS_DO_NOT_CALL_CALL_ANYWAY: {
    category: AnalyticsCategory.CallLimitations,
    action: AnalyticsAction.ItemClicked,
    label: 'Call this number anyway | button',
  },
});

const definedMessages = defineMessages({
  CALL_DONOTCALL_TITLE: {
    id: 'Call.DoNotCall.Warning.Title',
    defaultMessage: 'You are about to call a "Do Not Call" number',
  },
  CALL_DONOTCALL_BODY: {
    id: 'Call.DoNotCall.Warning.Body',
    defaultMessage: 'This contact is marked as a "Do Not Call" number in Salesforce',
  },
  CALL_DONOTCALL_ANYWAY: {
    id: 'Call.DoNotCall.Warning.Button',
    defaultMessage: 'Call this number anyway',
  },
});

export interface DoNotCallMessageProps {
  contactName: string;
  phoneNumber: string;
}

export interface DoNotCallMessageDispatchProps {
  onCall(contactName: string, phoneNumber: string): void;
  onClose(): void;
}

export const DoNotCallMessageComponent: React.FC<DoNotCallMessageProps & DoNotCallMessageDispatchProps> = ({
  onCall,
  onClose,
  contactName,
  phoneNumber,
}) => {
  function callNumberAnyWay() {
    newTracker.trackAnalyticsEvent(trackingEvents.CALL_LIMITATIONS_DO_NOT_CALL_CALL_ANYWAY);
    onCall(contactName, phoneNumber);
  }

  const Message = () => (
    <>
      <Box fontWeight="bold">
        <FormattedMessage {...definedMessages.CALL_DONOTCALL_TITLE} />
      </Box>
      <Box>
        <FormattedMessage {...definedMessages.CALL_DONOTCALL_BODY} />
      </Box>
    </>
  );

  const Actions = () => (
    <TrackedButton
      inverse
      color="default"
      variant="outlined"
      size="small"
      eventLabel={TrackingEventLabels.ClickToCall}
      eventName={TrackingEventNames.Click}
      onClick={callNumberAnyWay}
    >
      <FormattedMessage {...definedMessages.CALL_DONOTCALL_ANYWAY} />
    </TrackedButton>
  );

  return <Banner variant="danger" isClosable={true} onClose={onClose} message={<Message />} actions={<Actions />} />;
};
