import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { ApplicationRouteType } from '../Main.component';
import { NavigationRail } from './NavigationRail.component';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      flexDirection: 'column',
      display: 'flex',
      overflowX: 'hidden',
      width: 48,
      backgroundColor: tokens.surfaceColorInverted,
      border: 'none',
      flex: 1,
      '--goto-icon-size': '24px',
    },
  }),
);

export interface AppMenuProps {
  routes: ApplicationRouteType[];
  bottomRoutes: ApplicationRouteType[];
  disabled?: boolean;
  renderedPath: string;
}
export const AppMenu: React.FC<AppMenuProps> = ({ routes, bottomRoutes, disabled, renderedPath }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-cy="menu">
      <NavigationRail
        renderedPath={renderedPath}
        orientation="vertical"
        routes={routes}
        secondaryRoutes={bottomRoutes}
        disabled={disabled}
      />
    </div>
  );
};
