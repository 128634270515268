import { ActionFacade } from '../actionFacade/action.facade';
import {
  AddNoteCapableFacade,
  DownloadNotesCapableFacade,
  CAPABILITY_DOWNLOAD_NOTES,
  CAPABILITY_ADD_NOTES,
} from '../actionFacade/action.facade.capabilities';
import { AddNotePayload } from '../notes/notes.models';
import ClioActionCreatorFactory from './clio.actionCreator.factory';
import { ClioNoteActionCreator } from './note/clio.note.actioncreator';
import { ClioActionCreator } from './clio.actioncreator';
import { ConversationsActionCreator, LogMessagesParams } from '../conversations/conversations.actioncreator';

export class ClioActionFacade extends ActionFacade implements DownloadNotesCapableFacade, AddNoteCapableFacade {
  [CAPABILITY_ADD_NOTES]: true = true;
  [CAPABILITY_DOWNLOAD_NOTES]: true = true;

  protected noteActionCreator: ClioNoteActionCreator;
  protected clioActionCreator: ClioActionCreator;
  protected conversationsActionCreator: ConversationsActionCreator;

  constructor(actionCreatorFactory: ClioActionCreatorFactory) {
    super(actionCreatorFactory);
    this.noteActionCreator = actionCreatorFactory.createNoteActionCreator();
    this.clioActionCreator = actionCreatorFactory.createClioActionCreator();
    this.conversationsActionCreator = actionCreatorFactory.createConversationsActionCreator();
  }

  loadMostRecentNoteOfContact(contactId: string): Promise<void> {
    return this.noteActionCreator.loadMostRecentNoteOfContact(contactId);
  }

  loadMoreNotesOfContact(): Promise<void> {
    return this.noteActionCreator.loadMoreNotesOfContact();
  }

  addNote(note: AddNotePayload): Promise<void> {
    return this.noteActionCreator.addNote(note);
  }

  searchMatters(query: string): Promise<void> {
    return this.clioActionCreator.searchMatters(query);
  }

  loadActivityCategories(): Promise<void> {
    return this.clioActionCreator.loadActivityCategories();
  }

  loadConversations(): Promise<void> {
    return this.conversationsActionCreator.loadConversations();
  }

  updateConversations(): Promise<void> {
    return this.conversationsActionCreator.updateConversations();
  }

  selectConversationsPhoneNumber(phoneNumber: string): Promise<void> {
    return this.conversationsActionCreator.selectConversationPhoneNumber(phoneNumber);
  }

  loadConversationMessages(conversationId: string): Promise<void> {
    return this.conversationsActionCreator.loadConversationMessages(conversationId);
  }

  logMessages(params: LogMessagesParams): Promise<void> {
    return this.conversationsActionCreator.logMessages(params);
  }
}
