import { ExternalLinkIcon } from '@getgo/chameleon-icons/react';
import { IconButton } from '@getgo/chameleon-material-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { TrackedLink } from '../analytics-new/TrackedLink.component';

export interface OpenInCRMFlatButtonProps {
  trackingEvent: TrackingEvent;
  onClick: () => void;
}

const definedMessages = defineMessages({
  OPEN_IN_CRM_BUTTON_TOOLTIP: {
    id: 'Open.Entity.InCRM',
    defaultMessage: 'Open in {integrationName}',
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: '150px',
    },
  }),
);

export const OpenInCRMButton: React.FC<OpenInCRMFlatButtonProps> = ({ trackingEvent, onClick }) => {
  const classes = useStyles();

  const integrationName = useSelector(currentIntegrationNameSelector);

  return (
    <Tooltip
      arrow
      placement="right-start"
      classes={{ tooltip: classes.tooltip }}
      title={<FormattedMessage {...definedMessages.OPEN_IN_CRM_BUTTON_TOOLTIP} values={{ integrationName }} />}
      enterDelay={500}
    >
      <TrackedLink component="span" onClick={() => onClick()} trackingEvent={trackingEvent}>
        <IconButton variant="secondary" size="xsmall">
          <ExternalLinkIcon />
        </IconButton>
      </TrackedLink>
    </Tooltip>
  );
};
