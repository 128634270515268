import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../components/LoadingSpinner';
import { EntityType } from '../constants';
import { createEntity, CreateEntityActionPayload } from '../search/entities.action';
import JiveForm from './JiveForm.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TextInputField } from './TextInputField.component';
import { newTracker } from '../analytics-new/tracker-new';
import { CallActionFormProps } from '../calls/CallPageForm.model';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box';
import { isCreateEntityLoadingSelector } from '../search/entities.selector';
import { allCallSelector, callSelector } from '../calls/calls.selector';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers';

export type NewCaseFormProps = CallActionFormProps;

const trackingEvents = defineTrackingEvents({
  TITLE_CHANGED: {
    category: AnalyticsCategory.Case,
    label: 'Title changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_CASE_CLICKED: {
    category: AnalyticsCategory.Case,
    label: 'Add case | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Case,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
  CASE_FORM_PAGE_LOADED: {
    category: AnalyticsCategory.Case,
    action: AnalyticsAction.PageLoaded,
    label: 'Create case',
  },
});

const definedMessages = defineMessages({
  CASE_FORM_TITLE: {
    id: 'Case.New.Form.Title',
    defaultMessage: 'Create Case',
  },
  CASE_FORM_SAVE_BUTTON_TEXT: {
    id: 'Case.New.Form.SaveButtonText',
    defaultMessage: 'Add Case',
  },
  CASE_FORM_TITLE_LABEL: {
    id: 'Case.New.Form.Title.Label',
    defaultMessage: 'Title',
  },
});

const NewCaseForm: React.FC<NewCaseFormProps> = (props) => {
  const { contactId, callId, onClose } = props;

  const [title, setTitle] = useState('');

  // Selectors
  const activeCall = useSelector((state: AppState) => callSelector(state, callId));
  const isCreateEntityLoading = useSelector(isCreateEntityLoadingSelector);
  const call = useSelector((state: AppState) => allCallSelector(state, callId));
  const isActiveCall = (activeCall && !activeCall.endTime) as boolean;

  const dispatch = useDispatch();
  const createCase = (payload: CreateEntityActionPayload) => dispatch(createEntity(payload));

  useEffect(() => {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.CASE_FORM_PAGE_LOADED);
  }, []);

  return (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => {
        createCase({
          isActiveCall,
          defaultFieldValues: {
            title,
            // eslint-disable-next-line
            contactId: contactId!, // New case button should only appear if contact is assigned
          },
          entityName: EntityType.CASE,
          callId: call.id,
        });
      }}
      canSubmit={!isCreateEntityLoading}
      saveButtonDescriptor={definedMessages.CASE_FORM_SAVE_BUTTON_TEXT}
      saveTrackingEvent={trackingEvents.ADD_CASE_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
    >
      <Box mb={2}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.CASE_FORM_TITLE} />
        </Typography>
      </Box>
      <TextInputField
        name="case-title"
        trackingEvent={trackingEvents.TITLE_CHANGED}
        labelDescription={definedMessages.CASE_FORM_TITLE_LABEL}
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        maxLength={300}
      />
      <LoadingSpinner isLoading={isCreateEntityLoading}>
        <></>
      </LoadingSpinner>
    </JiveForm>
  );
};

export default NewCaseForm;
