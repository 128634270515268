import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';
export type AppAction<T extends string = any, P = any> = Action<T> & { payload: P };

export function makeActionCreator<T extends string>(type: T) {
  return <P = void>() => (payload: P): AppAction<T, P> => ({
    type,
    payload,
  });
}

export function makeAsyncActionCreator<P, T extends string>(
  callback: ThunkAction<any, AppState, any, AppAction<T, P>>,
) {
  return () => callback;
}

export function createActionCreator<T extends string>(
  type: T,
): <P = void>() => ((payload: P) => AppAction<T, P>) & { type: T } {
  return <P = void>() => {
    const actionCreator = (payload: P) => ({
      type,
      payload,
    });
    actionCreator.type = type;
    return actionCreator;
  };
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
export type ActionTypes<TActions extends Record<string, (...args: any[]) => any>> = ReturnType<
  PropType<TActions, keyof TActions>
>;
