import { defineMessages } from 'react-intl';
import { selectedPbxSelector } from '../../jiveEntities/pbxesEntity.selector';
import { addListener, addSmartListener } from '../../middlewares/listener.middleware';
import { AmbassadorActionsTypes } from '../ambassador.action';
import { ambassadorProviderByNameSelector, ambassadorUserProviderByNameSelector } from '../ambassador.selector';

import {
  currentIntegrationNameSelector,
  currentIntegrationSelector,
  isEnterpriseLinkingNeededForIntegration,
  isPersonalLinkingNeededForIntegration,
} from '../../integrations/integrations.selector';
import { checkProxyLink, checkProxyLinkError, checkProxyLinkSuccess, ProxyActionsTypes } from './proxy.action';
import { IntegrationsActionsType } from '../../integrations/integrations.actions';

const definedMessages = defineMessages({
  INTEGRATION_STATUS_UNAVAILABLE: {
    id: 'SettingsContainer.Integration.Unavailable',
    defaultMessage: '{integrationName} is not enabled on this PBX',
  },
});

addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, { payload }) => {
  const linkingNeededForCurrentIntegration = isPersonalLinkingNeededForIntegration(payload);
  const enterpriseLinkingNeededForCurrentIntegration = isEnterpriseLinkingNeededForIntegration(payload);
  if (linkingNeededForCurrentIntegration || enterpriseLinkingNeededForCurrentIntegration) {
    registerProxyListeners();
  }
});

let registered = false;

function registerProxyListeners() {
  if (registered) {
    return;
  }

  registered = true;

  addListener(AmbassadorActionsTypes.GET_PROVIDERS_SUCCESS)((_, __) => {
    return checkProxyLink();
  });

  addListener(ProxyActionsTypes.PROXY_CHECK_LINK)(async (state, __) => {
    const selectedPbx = selectedPbxSelector(state);
    if (!selectedPbx) {
      return checkProxyLinkError({});
    }

    const integration = currentIntegrationSelector(state);
    const integrationProvider = ambassadorProviderByNameSelector(state, integration);

    if (!integrationProvider) {
      return checkProxyLinkError({
        message: {
          ...definedMessages.INTEGRATION_STATUS_UNAVAILABLE,
          values: { integrationName: currentIntegrationNameSelector(state) },
        },
      });
    }

    const integrationUserProvider = ambassadorUserProviderByNameSelector(state, integration);
    const linked = !!integrationUserProvider && integrationUserProvider.status === 'ACTIVE';

    return checkProxyLinkSuccess({
      providerAvailable: true,
      linked,
      provider: integrationProvider,
      userProvider: integrationUserProvider,
    });
  });
}
