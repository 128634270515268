import { SalesforceActionFacadeBase } from './salesforce.actionfacade-base';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';
import { SalesforceActionCreatorFactoryBase } from './salesforce.actionCreator.factory-base';

export class SalesforceLightningActionFacade extends SalesforceActionFacadeBase {
  [CAPABILITY_SOFTPHONE] = true;

  constructor(actionCreatorFactory: SalesforceActionCreatorFactoryBase) {
    super(actionCreatorFactory);
  }
}
