import Button from '@material-ui/core/Button';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { currentIntegrationSelector, isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { Integrations } from '../models';

interface Key {
  value: string;
  label: any;
}

const keys: Key[] = [
  { value: '1', label: <span>&nbsp;</span> },
  { value: '2', label: 'ABC' },
  { value: '3', label: 'DEF' },
  { value: '4', label: 'GHI' },
  { value: '5', label: 'JKL' },
  { value: '6', label: 'MNO' },
  { value: '7', label: 'PQRS' },
  { value: '8', label: 'TUV' },
  { value: '9', label: 'WXYZ' },
  { value: '*', label: <span>&nbsp;</span> },
  { value: '0', label: '+' },
  { value: '#', label: <span>&nbsp;</span> },
];

const useStyles = ({ isNarrowIntegration, isInMsTeams }) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      keypadContainer: {
        display: 'grid',
        placeItems: 'center center',
        padding: theme.spacing(1, 0),
      },
      keyPadGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 49px)',
        gridTemplateRows: 'repeat(4, auto)',
        gridGap: isInMsTeams ? '17px 25px' : isNarrowIntegration ? '4px 17px' : '25px',
      },
      key: {
        display: 'flex',
        flexDirection: 'column',
      },
      keyValue: {
        fontSize: '2em',
        fontWeight: 400,
        lineHeight: 1,
      },
      keyLabel: {
        fontSize: '0.8em',
        lineHeight: 1,
        color: tokens.text02,
        fontWeight: 400,
      },
      buttonRoot: {
        minWidth: 'initial',
        '&:focus': {
          boxShadow: `0 0 0 5px ${tokens.information02}`,
        },
      },
    });
  });

export interface KeyPadOwnProps {
  handleOnClick: (value: string) => void;
}

export const KeyPad: React.FC<KeyPadOwnProps> = ({ handleOnClick }) => {
  const isInMsTeams = useSelector(currentIntegrationSelector) === Integrations.MsTeams;
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({
    isInMsTeams,
    isNarrowIntegration,
  })();
  return (
    <div className={classes.keypadContainer}>
      <div className={classes.keyPadGrid}>
        {keys.map((key) => (
          <Button
            key={key.value}
            id={`keyPadButton-${key.value}`}
            onClick={() => handleOnClick(key.value)}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            <div className={classes.key}>
              <div className={classes.keyValue}>{key.value}</div>
              <div className={classes.keyLabel}>{key.label}</div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};
