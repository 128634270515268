import { createActionCreator } from '../actions/actionCreators';
import { Call } from '@jive/realtime-events';

export const openIntegrationPanel = createActionCreator('embedded/openIntegrationPanelRequest')<{
  reason: string;
  call?: Call;
}>();
export const integrationPanelOpenElsewhere = createActionCreator('embedded/integrationPanelOpenElsewhere')<{
  call: Call;
}>();
export const closeIntegrationPanel = createActionCreator('embedded/closeIntegrationPanelRequest')();
