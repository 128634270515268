import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addSmartListener, addListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { Store } from 'redux';
import { AppState } from '../reducers';
import { CoxAxiosInterceptor } from './cox.axios.interceptor';
import { EntitiesActionsType } from '../search/entities.action';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ProxyActionsTypes } from '../ambassador/proxy/proxy.action';
import { CoxActionFacade } from './cox.action.facade';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';
import { isIntegrationLinkedIfNecessarySelector } from '../integrations/integrations.selector';

addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (store, action) => {
  if (action.payload === Integrations.Cox) {
    registerCoxListener(store);
  }
});

let isListenerRegistered = false;

function registerCoxListener(store: Store<AppState>) {
  if (isListenerRegistered) {
    return;
  }

  isListenerRegistered = true;

  new CoxAxiosInterceptor(store).register();

  addCallStartListener(async (_, action) => {
    await getActionFacade().createScreenpop(action.payload.call);
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(action.payload.call);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const query = action.payload.query;
    await getActionFacade().searchContact(query);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });

  addListener(ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS)(async (state) => {
    const isCoxLinked = isIntegrationLinkedIfNecessarySelector(state);
    if (isCoxLinked) {
      await getActionFacade<CoxActionFacade>().loadServiceWriters();
    }
  });
}
