import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useState } from 'react';
import { ApplicationRouteType } from '../Main.component';
import { NavigationRailItem } from './NavigationRailItem.component';
import { LinkDuo } from './LinkDuo.component';
import { tokens } from '@getgo/chameleon-core';

export interface NavigationRailProps {
  renderedPath: string;
  orientation: 'vertical' | 'horizontal';
  routes: ApplicationRouteType[];
  secondaryRoutes?: ApplicationRouteType[];
  disabled?: boolean;
}

type NavigationRailStyleProps = Pick<NavigationRailProps, 'orientation'>;

const useStyles = makeStyles(() => ({
  navigationRail: {
    width: ({ orientation }: NavigationRailStyleProps) => (orientation === 'vertical' ? '48px' : 'auto'),
    height: ({ orientation }: NavigationRailStyleProps) => (orientation === 'vertical' ? 'auto' : '40px'),
    display: 'flex',
    flexDirection: ({ orientation }: NavigationRailStyleProps) => (orientation === 'vertical' ? 'column' : 'row'),
    flex: 1,
    borderBottom: 'none',
    position: 'relative',
  },
  activeItemMarker: {
    backgroundColor: tokens.navigationRailIconSelected,
    width: ({ orientation }: NavigationRailStyleProps) => (orientation === 'vertical' ? 2 : undefined),
    height: ({ orientation }: NavigationRailStyleProps) => (orientation === 'horizontal' ? 2 : undefined),
    position: 'absolute',
    right: ({ orientation }: NavigationRailStyleProps) => (orientation === 'vertical' ? 0 : undefined),
    top: ({ orientation }: NavigationRailStyleProps) => (orientation === 'horizontal' ? 0 : undefined),
    borderRadius: '4px',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

export const NavigationRail: FC<NavigationRailProps> = ({
  renderedPath,
  orientation,
  routes,
  secondaryRoutes,
  disabled,
}) => {
  const [activeRef, setActiveRef] = useState<HTMLDivElement | null>(null);
  const styles = useStyles({ orientation });

  const createNavigationRailItem = (route: ApplicationRouteType) => {
    const isActive = renderedPath.includes(route.path);
    const icon = isActive ? route.activeIcon() : route.icon();
    return (
      <NavigationRailItem
        key={route.path}
        data-cy={`menu-item-${route.name}`}
        isActive={isActive}
        ref={(element) => isActive && setActiveRef(element)}
        disabled={disabled === true && !route.isExternal}
        orientation={orientation}
      >
        <LinkDuo route={route}>{icon}</LinkDuo>
      </NavigationRailItem>
    );
  };

  const railItems = routes.filter((r) => r.isVisible).map(createNavigationRailItem);

  if (secondaryRoutes) {
    const Spacer: React.FC = () => <Box flexGrow={1} />;
    railItems.push(<Spacer key="spacer" />);
    railItems.push(...secondaryRoutes.map(createNavigationRailItem));
  }

  return (
    <Box className={styles.navigationRail}>
      {railItems}
      <span
        className={styles.activeItemMarker}
        style={{
          width: orientation === 'horizontal' ? activeRef?.offsetWidth : undefined,
          height: orientation === 'vertical' ? activeRef?.offsetHeight : undefined,
          top: orientation === 'vertical' ? activeRef?.offsetTop : undefined,
          left: orientation === 'horizontal' ? activeRef?.offsetLeft : undefined,
        }}
      />
    </Box>
  );
};
