import { proxyUserProviderSelector } from '../../ambassador/proxy/proxy.selector';
import { ClioEntity } from '../clio.models';
import { ClioService } from '../clio.service';
import { logger } from '../../logging';
import { entityResultsNotAuthorized } from '../../search/entities.action';
import { EntityPhoneNumber, phoneNumberTypeMapper } from '../../search/entities.model';
import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import {
  ContactCreateModel,
  ContactSearchError,
} from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';

export class ClioContactActionCreator extends ContactActionCreator {
  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const phoneNumbers = await ClioService.getContactPhoneNumbers(contactId);

    return phoneNumbers.map((clioPhoneNumber) => ({
      entityId: contactId,
      type: phoneNumberTypeMapper(clioPhoneNumber.name),
      phoneNumber: clioPhoneNumber.number,
    }));
  }

  protected _openCrmContactPage(contactId: string): Promise<void> {
    const userProvider = proxyUserProviderSelector(this.store.getState());
    const appUrl = userProvider && userProvider.customAttributes && userProvider.customAttributes.regionApiUrl;

    if (!appUrl) {
      throw Error('No region api url set for Clio.');
    }

    window.open(`${appUrl}/contacts/${contactId}`, '_blank', 'openInDefaultBrowser');

    return Promise.resolve();
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this._openCrmContactPage(contactId)
      .then(() => true)
      .catch(() => false);
  }

  protected async _searchContactByName(query: string): Promise<ClioEntity[]> {
    try {
      return await ClioService.searchContact(query);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.error('Clio returned 401', error);
        this.store.dispatch(entityResultsNotAuthorized({ error }));

        (error as ContactSearchError).handled = true;
      }

      throw error;
    }
  }

  protected async _searchContactByPhoneNumber(phoneNumber: string): Promise<ClioEntity[]> {
    try {
      return await ClioService.searchContact(phoneNumber);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logger.error('Clio returned 401', error);
        this.store.dispatch(entityResultsNotAuthorized({ error }));

        (error as ContactSearchError).handled = true;
      }

      throw error;
    }
  }

  protected _canUpdateContactPhoneNumber(): Promise<boolean> {
    return Promise.resolve(true);
  }

  protected async _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    await ClioService.updateContactPhoneNumber(contactId, phoneNumber);
  }

  protected async _createContact(contactData: ContactCreateModel): Promise<string> {
    const newId = await ClioService.createContact(contactData.firstName, contactData.lastName, contactData.phoneNumber);

    return newId;
  }
}
