import React from 'react';
import { Checkbox, CheckboxProps } from '@getgo/chameleon-web/react';
import { TrackingEvent } from './analytics.models';
import { newTracker } from './tracker-new';
import { logger } from '../logging';

export interface TrackedCheckboxProps extends CheckboxProps {
  onTrackingEvent: TrackingEvent;
  offTrackingEvent: TrackingEvent;
}

export const TrackedCheckbox: React.FC<TrackedCheckboxProps> = ({
  onChange,
  onTrackingEvent,
  offTrackingEvent,
  ...rest
}) => (
  <Checkbox
    onChange={(event) => {
      const checked = event.currentTarget.checked;
      try {
        if (checked && onTrackingEvent) {
          newTracker.trackAnalyticsEvent(onTrackingEvent);
        } else if (!checked && offTrackingEvent) {
          newTracker.trackAnalyticsEvent(offTrackingEvent);
        }
      } catch (error) {
        logger.error('Error while tracking checkbox', error);
      }
      if (onChange) {
        onChange(event);
      }
    }}
    {...rest}
  />
);
