import { combineReducers } from 'redux';
import { AUTHENTICATION } from '../constants';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';

const authenticationProgressReducer = combineReducers({
  isLoading: loadingReducer(AUTHENTICATION),
  hasError: errorReducer(AUTHENTICATION),
});

export default authenticationProgressReducer;
