import React, { FC, useMemo, useRef } from 'react';
import {
  createTheme,
  StylesProvider,
  createGenerateClassName,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { fontFamily, spacing, lightTheme, darkTheme, fonts } from '@getgo/chameleon-core';
import { overrides, shadows, lightPalette, darkPalette, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { colors, tokens } from '@getgo/chameleon-core';
import { Theme as ChameleonTokens } from '@getgo/chameleon-core';
import { Colors } from '@getgo/chameleon-core';
import { ThemeProvider as ChameleonWebThemeProvider } from '@getgo/chameleon-web/react';
import * as chameleon from '@getgo/chameleon-web';

// Prevents tree-shaking
/* eslint-disable */
chameleon;
/* eslint-enable */

declare module '@material-ui/core/styles' {
  interface Theme {
    tokens: ChameleonTokens;
    chameleonColors: Colors;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    tokens?: ChameleonTokens;
  }
}

export interface ThemeProviderProps {
  theme?: 'light' | 'dark';
  classNamePrefix?: string;
}

export const chameleonThemeBase = createTheme({
  chameleonColors: colors,
  shadows,
  typography: {
    fontFamily,
    body1: {
      font: fonts['body-small'],
      fontSize: 'unset',
      fontFamily: 'unset',
      fontWeight: 'unset',
      lineHeight: 'unset',
    },
  },
  overrides: {
    ...overrides,
    MuiButton: {
      root: {
        minWidth: 36,
        textTransform: 'unset',
        lineHeight: 'unset',
        fontWeight: 600,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: tokens.hover01,
        },
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        right: 4,
      },
      option: {
        '&[data-focus=true]': {
          backgroundColor: `${tokens.interactive01} !important`,
          color: `${tokens.text06} !important`,
          border: 'none',
          boxShadow: 'unset !important',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: tokens.interactive01,
      },
    },
    MuiPickersDay: {
      day: {
        color: tokens.text01,
        '&:hover': {
          backgroundColor: tokens.hoverSecondary,
        },
      },
      daySelected: {
        backgroundColor: tokens.interactive01,
        color: tokens.text04,
        '&:hover': {
          backgroundColor: tokens.interactive01,
        },
      },
      current: {
        color: tokens.link,
      },
    },
    MuiCircularProgress: {
      circle: {
        color: tokens.interactive01,
      },
    },
    MuiInput: {
      root: {
        '&$focused': {
          border: `1px solid ${tokens.interactive01} !important`,
          boxShadow: 'none !important',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          border: `1px solid ${tokens.interactive01} !important`,
          boxShadow: 'none !important',
        },
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&$focused': {
          backgroundColor: tokens.surfaceColorDefault,
          border: `1px solid ${tokens.focusColorHighlightDefault} !important`,
          borderRadius: '4px',
        },
      },
    },
  },
  spacing: spacing.map((space) => `${space}rem`),
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
} as any);

export const darkChameleonTheme: ExtendedTheme = {
  ...(createTheme({
    ...chameleonThemeBase,
    palette: darkPalette,
    tokens: darkTheme,
  } as any) as ExtendedTheme),
};

export const lightChameleonTheme: ExtendedTheme = {
  ...(createTheme({
    ...chameleonThemeBase,
    palette: lightPalette,
    tokens: lightTheme,
  } as any) as ExtendedTheme),
};

export const AppThemeProvider: FC<ThemeProviderProps> = ({
  children,
  theme,
  classNamePrefix = 'cham',
  ...otherProps
}) => {
  const themeProviderRef = useRef();

  const isDarkMode = theme === 'dark';
  const muiTheme = useMemo(() => {
    const themeToUse = isDarkMode ? darkChameleonTheme : lightChameleonTheme;

    // Extending the theme with container elements for Dialog and Dropdown popper.
    // This necessary in order to have the right CSS vars from the chameleon web theme.
    return createTheme(themeToUse, {
      props: {
        MuiDialog: {
          container: () => themeProviderRef.current,
        },
        MuiSelect: {
          MenuProps: {
            container: () => themeProviderRef.current,
          },
        },
      },
    });
  }, [isDarkMode]);

  // Prepend material-ui classes with a unique prefix
  const generateClassName = createGenerateClassName({
    seed: classNamePrefix,
  });

  // Setting the prefix globally
  //  @ts-ignore
  window.CLASSNAME_PREFIX = classNamePrefix;

  return (
    <StylesProvider generateClassName={generateClassName} {...otherProps}>
      <ChameleonWebThemeProvider theme={theme} rebranding2021 skin="gotoapp" ref={themeProviderRef}>
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </ChameleonWebThemeProvider>
    </StylesProvider>
  );
};
