import React, { useState } from 'react';
import { CallContextPanelProps } from '../calls/CallContextPanel.models';
import { CallWithContact } from '../calls/calls.reducer';
import { CallTypeIcon } from '../components/CallTypeIcon';
import { getCallCreationDateAndTime, getCallTypeDescriptor } from '../calls/call.helper';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { Typography } from '../theme/Typography.component';
import { Button } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';

const trackingEvents = defineTrackingEvents({
  SHOW_MORE_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Callhistory show more | button',
  },
  SHOW_LESS_CLICKED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.ItemClicked,
    label: 'Callhistory show less | button',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    callHistoryItem: {
      display: 'flex',
      flexDirection: 'column',
      background: tokens.uiBase01,
      border: `1px solid ${tokens.interactive01}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2, 3),
      marginBottom: theme.spacing(2),
    },
    header: {
      display: 'flex',
    },
    buttons: {
      display: 'flex',
    },
    showButton: {
      width: '100%',
    },
  }),
);

const definedMessages = defineMessages({
  SHOW_MORE: {
    id: 'Lists.ShowMore',
    defaultMessage: 'Show more',
  },
  SHOW_LESS: {
    id: 'Lists.ShowLess',
    defaultMessage: 'Show less',
  },
});

export interface ContactCallHistoryStateProps {
  callHistory: CallWithContact[];
  language: string;
}

const DEFAULT_VISIBLE_ITEM_COUNT = 2;

export const ContactCallHistoryComponent: React.FC<ContactCallHistoryStateProps & CallContextPanelProps> = ({
  language,
  callHistory,
}) => {
  const classes = useStyles();
  const [visibleItemCount, setVisibleItemCount] = useState(DEFAULT_VISIBLE_ITEM_COUNT);

  const renderCallHistoryItem = (call: CallWithContact) => {
    const descriptor = getCallTypeDescriptor(call.type);

    return (
      <div key={call.id} className={classes.callHistoryItem}>
        <div className={classes.header}>
          <CallTypeIcon type={call.type} />
          <Typography variant="caption-default-02">
            <FormattedMessage {...descriptor} />
          </Typography>
        </div>
        <Typography variant="caption-small-01">{getCallCreationDateAndTime(call, language)}</Typography>
      </div>
    );
  };

  const onShowMoreClick = () => {
    setVisibleItemCount((value) => Math.min(value + 2, callHistory?.length ?? DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_MORE_CLICKED);
  };

  const onShowLessClick = () => {
    setVisibleItemCount((value) => Math.max(value - 2, DEFAULT_VISIBLE_ITEM_COUNT));
    newTracker.trackAnalyticsEvent(trackingEvents.SHOW_LESS_CLICKED);
  };

  const renderShowButtons = () => {
    if (callHistory.length <= DEFAULT_VISIBLE_ITEM_COUNT) {
      return null;
    }

    return (
      <div className={classes.buttons}>
        <Button
          className={classes.showButton}
          color="primary"
          variant="text"
          onClick={onShowLessClick}
          disabled={visibleItemCount <= DEFAULT_VISIBLE_ITEM_COUNT}
        >
          <FormattedMessage {...definedMessages.SHOW_LESS} />
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={onShowMoreClick}
          disabled={visibleItemCount >= callHistory?.length}
          className={classes.showButton}
        >
          <FormattedMessage {...definedMessages.SHOW_MORE} />
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {callHistory.slice(0, visibleItemCount).map((call) => renderCallHistoryItem(call))}
      {renderShowButtons()}
    </div>
  );
};
