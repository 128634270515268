import { connect } from 'react-redux';
import { CallStateProps, CallComponentProps, CallComponent } from './Call.component';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { entitiesIsLoadingSelector } from '../search/entities.selector';
import {
  callSelector,
  contactsByCallIdSelector,
  getEntityInCallSelector,
  isContactSingleMatchSelector,
  isContactMatchingLoadingForCallSelector,
} from './calls.selector';
import {
  callHistoryElementByIdSelector,
  unifiedCallHistoryRequestStateSelector,
} from '../callHistoryPush/unifiedCallHistory.selector';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';

export default connect<CallStateProps, {}, CallComponentProps, AppState>(
  (state: AppState, { match }) => {
    const {
      params: { callId },
    } = match;
    const endedCall = callHistoryElementByIdSelector(callId)(state);
    const activeCall = callSelector(state, callId);

    return {
      call: endedCall || activeCall,
      isContactMatchingLoading: isContactMatchingLoadingForCallSelector(state, callId),
      contacts: contactsByCallIdSelector(state, callId),
      isSingleMatch: isContactSingleMatchSelector(state, callId),
      entity: getEntityInCallSelector(state, callId),
      isLoadingEntities: entitiesIsLoadingSelector(state),
      integration: currentIntegrationSelector(state),
      unifiedCallHistoryRequestState: unifiedCallHistoryRequestStateSelector(state),
      selectedOrganizationId: selectedOrganizationIdSelector(state),
    };
  },
  () => ({}),
)(CallComponent);
