import { Reducer } from 'redux';
import { AmbassadorActions, AmbassadorActionsTypes } from './ambassador.action';
import { AmbassadorProvider, AmbassadorUserProvider } from './ambassador.service';

export interface AmbassadorState {
  providers?: AmbassadorProvider[];
  userProviders?: AmbassadorUserProvider[];
}

export const AMBASSADOR_INITIAL_STATE: AmbassadorState = {
  providers: undefined,
  userProviders: undefined,
};

export const ambassadorReducer: Reducer<AmbassadorState, AmbassadorActions> = (
  state = AMBASSADOR_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AmbassadorActionsTypes.GET_PROVIDERS:
    case AmbassadorActionsTypes.GET_PROVIDERS_ERROR:
      return AMBASSADOR_INITIAL_STATE;
    case AmbassadorActionsTypes.GET_PROVIDERS_SUCCESS:
      return { ...state, providers: action.payload.providers, userProviders: action.payload.userProviders };
    default:
      return state;
  }
};
