import { ActiveCallsState, Call } from '@jive/realtime-events';
import { defineMessages, IntlShape } from 'react-intl';
import { createSelector } from 'reselect';
import { Entity, IndexedById } from '../models';
import { AppState } from '../reducers';
import { injectedIntlSelector } from '../settings/settings.selector';
import { CallWithContact, Contact, ContactState } from './calls.reducer';
import {
  callHistoryElementByIdSelector,
  callHistoryMetadataItemSelector,
} from '../callHistoryPush/unifiedCallHistory.selector';

const definedMessages = defineMessages({
  CALL_CONTACT_MULTIPLE_MATCH: {
    id: 'Call.Contact.MultipleMatch',
    defaultMessage: 'Multiple matches found',
    description: 'Call contact with multiple match for phone number',
  },
  CALL_CONTACT_NO_MATCH: {
    id: 'Call.Contact.NoMatch',
    defaultMessage: 'No match found',
    description: 'Call contact with no match for phone number',
  },
});

const callsSelector = (state: AppState): ActiveCallsState => state.calls.activeCalls;

const contactsSelector = (state: AppState): ContactState => state.calls.contacts;

const callsByIdSelector = createSelector<AppState, ActiveCallsState, IndexedById<Call>>(
  callsSelector,
  (calls) => calls.byId,
);

export const numberOfActiveCallsSelector = createSelector(
  callsSelector,
  callsByIdSelector,
  (calls, byId) => calls.allIds.filter((id) => !byId[id].endTime).length,
);

export const activeCallsSelector = createSelector(callsSelector, callsByIdSelector, (calls, byId) =>
  calls.allIds.map((id) => byId[id]).filter((call) => !call.endTime),
);

const contactsInActiveCallByIdSelector = createSelector<AppState, ContactState, IndexedById<Contact>>(
  contactsSelector,
  (contacts) => contacts.byId,
);

export const callSelector = createSelector<
  AppState,
  string,
  IndexedById<Call>,
  Contact,
  string,
  AppState,
  Call | undefined
>(
  callsByIdSelector,
  contactsInActiveCallByIdSelector,
  (_, callId) => callId,
  (state, _) => state,
  (callsById, contactsById, callId, state) => {
    const call = callsById[callId];

    if (!call) {
      return undefined;
    }
    const metadata = callHistoryMetadataItemSelector(callId)(state);
    return {
      ...call,
      ...contactsById[callId],
      allIds: metadata?.allIds ?? call.allIds,
      isClickToCall: metadata?.isClickToCall ?? call.isClickToCall,
    } as Call;
  },
);

export const allCallSelector = (state, callId) => {
  let call = callSelector(state, callId);
  if (!call) {
    call = callHistoryElementByIdSelector(callId)(state);
  }
  return call;
};

export const endedOrActiveCallSelector = (state: AppState, callId: string) => {
  const endedCall = callHistoryElementByIdSelector(callId)(state);
  const activeCall = callSelector(state, callId);
  return endedCall || activeCall;
};

export const contactsByCallIdSelector = (state: AppState, callId: string): Contact => {
  const call = callSelector(state, callId);
  if (!call) {
    return callHistoryElementByIdSelector(callId)(state);
  }
  return contactsSelector(state).byId[callId];
};

export const isContactSingleMatchSelector = createSelector<AppState, string, Contact, boolean>(
  contactsByCallIdSelector,
  (contacts) => !!getSelectedContactInCall(contacts),
);

export const getContactMatchTypeSelector = createSelector<AppState, string, Contact, ContactMatchType>(
  contactsByCallIdSelector,
  (contacts) => getMatchType(contacts?.allMatches ?? [], contacts?.entitySelected),
);

export const getEntityInCallSelector = createSelector<AppState, string, Contact, Entity | undefined>(
  contactsByCallIdSelector,
  (contacts) => getSelectedContactInCall(contacts),
);

export const isContactMatchingLoadingForCallSelector = (state: AppState, callId: string) => {
  const call = allCallSelector(state, callId);
  if (!call) {
    return false;
  }
  return contactsSelector(state).byId[call.id]?.isLoading;
};

export const getSelectedContactInCall = (contacts: Contact): Optional<Entity> => {
  if (contacts) {
    if (contacts.allMatches && contacts.entitySelected) {
      return contacts.allMatches.filter((match) => match.id === contacts.entitySelected)[0];
    }
    // single match
    if (contacts.allMatches && contacts.allMatches.length === 1) {
      return contacts.allMatches[0];
    }
  }
};

export const contactInCallSelector = createSelector<AppState, string, Entity | undefined, Entity | undefined>(
  getEntityInCallSelector,
  (entity) => entity,
);

export const getContactDisplayFromCall = createSelector<AppState, string, Contact, IntlShape, string>(
  contactsByCallIdSelector,
  injectedIntlSelector,
  (contacts, intl) => {
    if (contacts && contacts.allMatches) {
      if (contacts.entitySelected) {
        return getContactDisplay(intl, contacts.allMatches, contacts.entitySelected);
      } else {
        return getContactDisplay(intl, contacts.allMatches);
      }
    } else {
      return getContactDisplay(intl, []);
    }
  },
);

export enum ContactMatchType {
  NoMatch = 'no match',
  MultipleMatch = 'multiple match',
  SingleMatch = 'single match',
}

export const getContactDisplay = (intl: IntlShape, allMatches: Entity[], entitySelected?: string): string => {
  if (allMatches.length === 1 && !entitySelected) {
    return allMatches[0].name || allMatches[0].id;
  }

  if (allMatches.length >= 1) {
    if (!entitySelected) {
      return intl.formatMessage(definedMessages.CALL_CONTACT_MULTIPLE_MATCH);
    }

    const match = allMatches.filter((match) => match.id === entitySelected)[0];

    if (match) {
      return match.name || match.id;
    }
  }

  return intl.formatMessage(definedMessages.CALL_CONTACT_NO_MATCH);
};

const getMatchType = (allMatches: Entity[], entitySelected?: string): ContactMatchType => {
  if (allMatches.length === 1 && !entitySelected) {
    return ContactMatchType.SingleMatch;
  }

  if (allMatches.length >= 1) {
    if (!entitySelected) {
      return ContactMatchType.MultipleMatch;
    }

    const match = allMatches.filter((match) => match.id === entitySelected)[0];

    if (match) {
      return ContactMatchType.SingleMatch;
    }
  }

  return ContactMatchType.NoMatch;
};

export const getContactNameOrNumber = (call: CallWithContact): string => {
  if (!call) {
    return '';
  }

  const contact = getSelectedContactInCall(call);

  if (contact) {
    return contact.name;
  } else {
    return call.theOtherParty.name || call.theOtherParty.number;
  }
};

export const isContactCallable = (call: CallWithContact): boolean => {
  return !getSelectedContactInCall(call)?.doNotCall;
};

export const hasSingleContactSelector = (state: AppState, call: CallWithContact): boolean => {
  const entity = getEntityInCallSelector(state, call.id);

  const isNoMatch = !entity || !call?.allMatches || !call.allMatches.length;
  const hasNoSelectedEntity = !call.allMatches || (call.allMatches.length > 1 && !call.entitySelected);
  const hasSingleContact = !isNoMatch && !hasNoSelectedEntity;

  return hasSingleContact;
};
