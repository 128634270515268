import {
  GetNotesSuccessPayload,
  GetMoreNotesRequestMeta,
  openNoteRequest,
  openNoteError,
  openNoteSuccess,
  loadNotesCountRequest,
  loadNotesCountSuccess,
  loadNotesCountError,
} from '../../notes/notes.actions';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { SalesforceNote, PagedResult } from '../../../../salesforce-shared/salesforce-service.models';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import { NoteActionCreator } from '../../actionFacade/actionCreators/note/note.actioncreator';
import { AddNoteModel } from '../../actionFacade/actionCreators/note/note.actioncreator.models';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';

const NOTES_PAGING_LIMIT = 2;

export const definedMessages = defineMessages({
  OPEN_NOTE_ERROR: {
    id: 'Note.Open.Error',
    defaultMessage: 'There was an error while opening note, please try again.',
  },
});

export class SalesforceLightningNoteActionCreator extends NoteActionCreator {
  constructor(store: Store<AppState>, private salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }

  public async openNote(id: string): Promise<void> {
    if (!id) {
      logger.error('Please specify the ID of the note to open it!');
      this.store.dispatch(openNoteError({ message: definedMessages.OPEN_NOTE_ERROR }));
      return;
    }
    try {
      this.store.dispatch(openNoteRequest());
      await this.salesforceLightningProxy.openEntityPage(id);
      this.store.dispatch(openNoteSuccess());
    } catch (error) {
      logger.error('Error opening note', error);
      this.store.dispatch(openNoteError({ message: definedMessages.OPEN_NOTE_ERROR }));
    }
  }

  public async loadNotesCount(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose notes count should be queried!');
      return;
    }
    try {
      this.store.dispatch(loadNotesCountRequest());
      const notesCount = await this.salesforceLightningProxy.getNotesCount(contactId);
      this.store.dispatch(loadNotesCountSuccess({ notesCount }));
    } catch (error) {
      logger.error('Error opening note', error);
      this.store.dispatch(loadNotesCountError());
    }
  }

  protected async _loadMostRecentNoteOfContact(contactId: string): Promise<GetNotesSuccessPayload> {
    const result: any = {
      items: [],
      notesCount: 0,
      nextOffset: 0,
    };

    const noteCount = await this.salesforceLightningProxy.getNotesCount(contactId);

    result.notesCount = noteCount;

    if (noteCount > 2) {
      result.nextOffset = 1;
    }

    result.items = await this.salesforceLightningProxy.getNotes(contactId, 0, NOTES_PAGING_LIMIT);

    return this.mapNoteResultToPayload(contactId, result);
  }

  protected async _loadMoreNotesOfContact(moreNotesMeta: GetMoreNotesRequestMeta): Promise<GetNotesSuccessPayload> {
    const contactId = moreNotesMeta.contactId || '';
    const offset = Number(moreNotesMeta.next);
    const notesCount = moreNotesMeta.notesCount || 0;

    const result: any = {
      items: [],
      notesCount,
    };

    result.items = await this.salesforceLightningProxy.getNotes(contactId, offset, NOTES_PAGING_LIMIT);

    // if there are less item than the limit then we are done, no need to update offset
    if (result.items.length === NOTES_PAGING_LIMIT) {
      if ((offset + 1) * NOTES_PAGING_LIMIT < notesCount) {
        result.nextOffset = offset + 1;
      }
    }

    return this.mapNoteResultToPayload(contactId, result);
  }

  protected _addNote(note: AddNoteModel): Promise<Optional<{ noteId: string }>> {
    throw new Error('Method not implemented. ' + note.noteSubject);
  }

  private mapNoteResultToPayload(contactId: string, result: PagedResult<SalesforceNote>): GetNotesSuccessPayload {
    return {
      notes: result.items.map((sfn) => {
        return {
          id: sfn.ContentDocumentId,
          subject: sfn.Title,
          content: sfn.TextPreview || '',
          createdAtIso8601: sfn.ContentModifiedDate,
        };
      }),
      meta: {
        next: result.nextOffset ? String(result.nextOffset) : undefined,
        notesCount: result.notesCount,
        contactId,
      },
    };
  }
}
