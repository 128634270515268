import { RadioButton, RadioButtonProps } from "@getgo/chameleon-web/react";
import React, { FC } from "react";
import { logger } from "../logging";
import { TrackingEvent } from "./analytics.models";
import { newTracker } from "./tracker-new";

export interface TrackedRadioButtonProps extends RadioButtonProps {
    onTrackingEvent?: TrackingEvent;
    offTrackingEvent?: TrackingEvent;
  }

export const TrackedRadioButton: FC<TrackedRadioButtonProps> = ({
    onChange,
    onTrackingEvent,
    offTrackingEvent,
    ...rest
}) => (
    <RadioButton
        onChange={(event) => {
        const checked = event?.currentTarget?.checked;
        try {
            if (checked && onTrackingEvent) {
            newTracker.trackAnalyticsEvent(onTrackingEvent);
            } else if (!checked && offTrackingEvent) {
            newTracker.trackAnalyticsEvent(offTrackingEvent);
            }
        } catch (error: any) {
            logger.error('Error while tracking radio button', error);
        }
        if (onChange) {
            onChange(event);
        }
        }}
        {...rest}
    />
)