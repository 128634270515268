import { AppState } from '../reducers';
import { Store } from 'redux';
import { logger } from '../logging';
import { openDialog } from '../dialog/dialog.actions';
import { DialogBodyNames } from '../dialog/DialogBodies';
import { restartApp } from '../connectivity/connectivity.action';
import { defineMessages } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { numberOfActiveCallsSelector } from '../calls/calls.selector';
import { DesktopNotification } from '../listeners/desktopNotification';
import { injectedIntlSelector } from '../settings/settings.selector';
import configuration from '../config';

const definedMessages = defineMessages({
  UPDATE_AVAILABLE_TITLE: {
    id: 'UpdateAvailable.Title',
    defaultMessage: 'Update available',
  },
  UPDATE_AVAILABLE_RELOAD_APP_BUTTON_LABEL: {
    id: 'UpdateAvailable.RestartApp.Button.Label',
    defaultMessage: 'Restart app',
  },
  UPDATE_AVAILABLE_MESSAGE_2: {
    id: 'UpdateAvailable.Message.2',
    defaultMessage: 'Please update to the new version by restarting the app.',
  },
  DESKTOP_NOTIFICATION_TITLE: {
    id: 'UpdateAvailable.DesktopNotification.Title',
    defaultMessage: 'GoToConnect Integration update available',
  },
  DESKTOP_NOTIFICATION_BODY: {
    id: 'UpdateAvailable.DesktopNotification.Body',
    defaultMessage: 'Please update to the new version.',
  },
});

const trackingEvents = defineTrackingEvents({
  UPDATE_AVAILABLE_DIALOG_APPEARED: {
    category: AnalyticsCategory.Application,
    label: 'Update available',
    action: AnalyticsAction.PopupShown,
  },
  UPDATE_AVAILABLE_DIALOG_CANCELED: {
    category: AnalyticsCategory.Application,
    label: 'Cancel upgrade | button',
    action: AnalyticsAction.ItemClicked,
  },
  UPDATE_AVAILABLE_DIALOG_CONFIRMED: {
    category: AnalyticsCategory.Application,
    label: 'Restart app | button',
    action: AnalyticsAction.ItemClicked,
  },
});

let startedChecking = false;
export const startCheckingIfNewVersionIsAvailable = async (store: Store<AppState>) => {
  if (startedChecking) {
    return;
  }

  startedChecking = true;
  await startCheckingPeriodically(store);
  // let's start checking periodically every 4 hours
  setInterval(async () => {
    await startCheckingPeriodically(store);
  }, 4 * 60 * 60 * 1000);
};

let intervalId: number | undefined;
const startCheckingPeriodically = async (store: Store<AppState>) => {
  // do not start checking periodically again if the previous setInterval is still working
  if (intervalId) {
    return;
  }

  let isMessageShown = false;
  if (canShowNotification(store)) {
    // isMessageShown basically means that the dialog was closed, otherwise the app would reload
    isMessageShown = await showMessageIfNewVersionIsAvailable(store);
  }

  // let's not start checking periodically if message was shown
  if (isMessageShown) {
    return;
  }

  intervalId = window.setInterval(async () => {
    if (canShowNotification(store)) {
      const isMessageShown = await showMessageIfNewVersionIsAvailable(store);
      if (isMessageShown) {
        window.clearInterval(intervalId);
        intervalId = undefined;
      }
    }
  }, 60 * 1000); // callback called every minute
};

const checkIfNewVersionIsAvailable = async (): Promise<boolean> => {
  try {
    const res = await fetch(`${document.baseURI}version.txt`, { cache: 'no-cache' });
    const versionFile = await res.text();
    const version = versionFile.split('\n')[0];
    const currentVersion = configuration.version;
    return version.trim() !== currentVersion.trim(); // downgrade also possible
  } catch (e) {
    logger.warn('Could not get version file', e);
    return false;
  }
};

const showMessageIfNewVersionIsAvailable = async (store: Store<AppState>): Promise<boolean> => {
  const isNewVersionAvailable = await checkIfNewVersionIsAvailable();
  if (isNewVersionAvailable) {
    newTracker.trackAnalyticsEvent(trackingEvents.UPDATE_AVAILABLE_DIALOG_APPEARED);
    store.dispatch(
      openDialog({
        texts: {
          title: definedMessages.UPDATE_AVAILABLE_TITLE,
          confirm: definedMessages.UPDATE_AVAILABLE_RELOAD_APP_BUTTON_LABEL,
        },
        confirmAction: restartApp(),
        bodyName: DialogBodyNames.UpdateAvailableDialogBody,
        confirmTrackingEvent: trackingEvents.UPDATE_AVAILABLE_DIALOG_CONFIRMED,
        cancelTrackingEvent: trackingEvents.UPDATE_AVAILABLE_DIALOG_CANCELED,
        hideCancelButton: true,
      }),
    );
    const intl = injectedIntlSelector(store.getState());
    await DesktopNotification.show(intl, {
      tag: 'update-available-notification',
      showUntilClicked: true,
      title: definedMessages.DESKTOP_NOTIFICATION_TITLE,
      message: definedMessages.DESKTOP_NOTIFICATION_BODY,
    });
    return true;
  }
  return false;
};

const canShowNotification = (store: Store<AppState>): boolean => {
  return !numberOfActiveCallsSelector(store.getState());
};
