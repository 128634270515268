import { List, ListItem } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { goToIntegration } from '../browserHistory';
import { Integrations } from '../models';
import { Typography } from '../theme/Typography.component';

export class IntegrationPickerComponent extends Component {
  render() {
    const integrations = Object.values(Integrations);

    return (
      <Fragment>
        <Typography style={{ fontWeight: 'bold' }} gutterBottom>
          Choose your integration
        </Typography>

        <List>
          {integrations.map((integration) => (
            <ListItem key={integration} dense divider button onClick={() => goToIntegration(integration)}>
              {integration}
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  }
}
