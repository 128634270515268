import { createStyles, Menu, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { MoreVerticalMobileOnlyIcon, MoreHorizontalIcon, CloseIcon } from '@getgo/chameleon-icons/react';
import { IconButton } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';

const styles = () =>
  createStyles({
    menuList: {
      maxWidth: '225px',
      display: 'flex',
      flexDirection: 'column',
    },
    backdrop: {
      backgroundColor: tokens.uiBackground,
    },
  });

export interface ContextMenuProps {
  iconClass?: string;
  menuClass?: string;
  buttonSize?: 'xsmall' | 'small' | 'medium' | 'large';
  variant?: 'horizontal' | 'vertical';
  moreButtonLabel: string;
}

const ContextMenu: React.FC<ContextMenuProps & WithStyles<typeof styles>> = ({
  classes,
  children,
  iconClass,
  buttonSize,
  menuClass,
  variant = 'vertical',
  moreButtonLabel,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null as HTMLElement | null);
  const isOpen = Boolean(anchorEl);
  const ContextMenuIcon = variant === 'horizontal' ? MoreHorizontalIcon : MoreVerticalMobileOnlyIcon;

  function handleClick(event: MouseEvent) {
    setAnchorEl(event.currentTarget as HTMLElement);
    event.stopPropagation();
  }

  function handleClose(event: MouseEvent) {
    setAnchorEl(null);
    event.stopPropagation();
  }

  return (
    <div className={menuClass} data-cy="context-menu">
      <IconButton
        aria-label={moreButtonLabel}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={buttonSize}
        className={iconClass}
        variant="secondary"
      >
        {isOpen ? <CloseIcon /> : <ContextMenuIcon />}
      </IconButton>
      <Menu
        open={isOpen}
        onClick={handleClose}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        MenuListProps={{ className: classNames(classes.menuList, 'contextmenulist') }}
        BackdropProps={{ className: classes.backdrop }}
      >
        <div>{children}</div>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(ContextMenu);
