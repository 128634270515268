import { toClosestSupportedLocale } from './i18n';

let translationMessages: Record<string, string> = {};

export const loadTranslation = async (locale: string) => {
  const localeToLoad = toClosestSupportedLocale(locale);
  const translations: Record<string, string> = await import(`./locales/${localeToLoad}.json`);
  return (translationMessages = translations);
};

export function getMessages() {
  return translationMessages;
}
