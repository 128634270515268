import { AppState } from '../../reducers';
import {
  canLoadMoreCasesSelector,
  getMoreCasesRequestStateSelector,
  getCasesRequestStateSelector,
  casesListSelector,
} from './case.selectors';
import { CasesComponent, CasesStateProps } from './Cases.component';
import { connect } from 'react-redux';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';

const mapStateToProps = (state: AppState): CasesStateProps => ({
  cases: casesListSelector(state),
  requestState: getCasesRequestStateSelector(state),
  moreRequestState: getMoreCasesRequestStateSelector(state),
  canLoadMoreCases: canLoadMoreCasesSelector(state),
  openCaseInCrmSupported: false, // not implemented yet
  locale: selectedDateFnsLocaleSelector(state),
});

export const Cases = connect<CasesStateProps, {}, CallContextPanelProps, AppState>(mapStateToProps)(CasesComponent);
