import { Store } from 'redux';
import { ConfigsManager, DeviceActions, DevicesManager, IncomingCall, Session, Call as JiveCall } from '@jive/rtc';
import { jiveIdSelector, tokenSelector } from '../authentication/authentication.selector';
import { isSoftphoneEnabledSelector, selectedLineSelector } from '../settings/settings.selector';
import { AppState } from '../reducers';
import { CallUpdateCallback, SoftphoneSessionManager } from './SoftphoneSessionManager';
import configuration from '../config';
import { SoftphoneCall, SoftphoneCallState } from './softphone.model';
import { logger } from '../logging';
import { RingService } from './ringService';
import { selectSoftphoneCallsByCalleeSelector } from './softphoneCall.selector';
import { parseAndNormalizePhoneNumber } from './phone-number';
import { showMessage } from '../inAppNotification/message.action';
import { definedMessages } from '../inAppNotification/message.content';
import { MessageVariant } from '../models';

const audioElement = new Audio();

type Call = { jiveCall: JiveCall } & SoftphoneCall;

class JiveRtcSessionManager implements SoftphoneSessionManager {
  private store: Store<AppState> | undefined;
  private session: Session | undefined = undefined;
  private lineId: string | undefined;
  private calls: {
    [id: string]: Call;
  } = {};
  private callUpdateSubscriptions: CallUpdateCallback[] = [];
  private ringService: RingService = new RingService();

  public async init(store: Store<AppState>) {
    this.store = store;
    ConfigsManager.configs = configuration.rtcConfig;
    const actions = new DeviceActions();
    const devicesManager = new DevicesManager(store as any, actions);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (err) {
      logger.error('Failed to get microphone during session manager initialization');
    }

    await devicesManager.updateAvailableDevices();
    devicesManager.selectedInputDevice = await this.getDefaultAudioInput();
    devicesManager.selectedOutputDevice = await this.getDefaultAudioOutput();

    store.subscribe(() => {
      const state = store.getState();
      const line = selectedLineSelector(state);
      const isSoftphoneEnabled = isSoftphoneEnabledSelector(state);

      if (!line || (this.session && this.lineId === line?.id) || !isSoftphoneEnabled) {
        return;
      }

      this.lineId = line.id;

      const user = jiveIdSelector(state);
      const token = tokenSelector(state);

      if (user && token) {
        this.session = new Session(user, token, line.organization.id, line.number, store as any);
        this.subscribeToSessionEvents();
      }
    });
  }

  public stop() {
    this.session?.logout();
    this.session?.removeAllListeners();
    this.session = undefined;
  }

  private subscribeToSessionEvents = () => {
    if (!this.session) {
      return;
    }

    // Session Connection Restored Event:
    this.session.on(this.session.CONNECTION_RESTORED, () => {
      // Session connection to the server is established
      console.log('CONNECTION_RESTORED');
    });

    // Session Connection Lost Event:
    this.session.on(this.session.CONNECTION_LOST, (reason) => {
      // Session connection to the server was lost
      const errorCode = reason.errorCode;
      const message = reason.message;
      console.log('CONNECTION_LOST', errorCode, message);
    });

    this.session.on(this.session.REGISTERED, () => {
      // Session Registered
      console.log('REGISTERED');
    });

    this.session.on(this.session.REGISTERING, () => {
      // Session Registering
      console.log('REGISTERING');
    });

    this.session.on(this.session.UNREGISTERED, (reason) => {
      // Session Unregistered
      const errorCode = reason.errorCode;
      const message = reason.message;
      console.log('UNREGISTERED', errorCode, message);
    });

    this.session.on(this.session.ERROR, (error) => {
      // Session error
      const errorCode = error.errorCode;
      const message = error.message;
      console.log('ERROR', errorCode, message);
    });

    this.session.on(this.session.INVITE, async (incomingCall: IncomingCall) => {
      const call: Call = {
        jiveCall: incomingCall,
        id: incomingCall.id ?? '',
        startTime: Date.now(),
        answerTime: undefined,
        direction: 'incoming',
        isMuted: false,
        callState: 'initial',
        theOtherParty: { number: incomingCall.number, name: incomingCall.caller },
        isRemoteCall: false,
      };

      this.calls[call.id] = call;
      console.log('INCOMING CALL, INVITE', incomingCall);
      this.subscribeToCallEvents(incomingCall);
      this.fireCallUpdateEvent('started', call);

      if (this.hasOngoingCalls()) {
        await this.ringService.playBusyRingingSound(incomingCall.sipCallId!); // id is not uniq per call
      } else {
        await this.ringService.playRingingSound(incomingCall.sipCallId!); // id is not uniq per call
      }
    });
  };

  subscribeToCallUpdates(callback: CallUpdateCallback) {
    this.callUpdateSubscriptions.push(callback);
  }

  unsubscribeFromCallUpdates(): void {
    this.callUpdateSubscriptions = [];
  }

  async makeCall(callee: string): Promise<void> {
    if (!this.session) {
      logger.error('RtcJS session is not available when trying to make call');
      return;
    }

    const dialString = parseAndNormalizePhoneNumber(callee);
    if (!dialString) {
      this.store?.dispatch(
        showMessage({
          id: 'click_to_call_error_msg',
          message: definedMessages.CLICK_TO_CALL_ERROR_TITLE,
          type: MessageVariant.Error,
          params: {
            autoHide: true,
            dismissible: true,
          },
        }),
      );
      return;
    }

    const jiveCall = this.session.createCall(dialString);

    audioElement.srcObject = jiveCall.dtmfStream;
    audioElement.autoplay = true;
    audioElement.muted = false;
    audioElement.loop = false;
    await jiveCall.dial();
    this.subscribeToCallEvents(jiveCall);

    const call: Call = {
      jiveCall,
      id: jiveCall.id ?? '',
      startTime: Date.now(),
      answerTime: undefined,
      isMuted: false,
      callState: 'initial',
      direction: 'outgoing',
      theOtherParty: { number: dialString, name: '' },
      isRemoteCall: false,
    };
    if (!jiveCall.id) {
      logger.error('No call id after dial.');
      return;
    }
    this.calls[call.id] = call;
    this.fireCallUpdateEvent('started', call);
    await this.ringService.playDialSound();
  }

  async hangupCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    if (call) {
      await call.jiveCall.hangup();
      delete this.calls[callId];
    }
  }

  async answerCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    const incomingCall = call?.jiveCall as IncomingCall;

    if (!incomingCall || !incomingCall.answer) {
      return;
    }
    await incomingCall.answer();
  }

  async rejectCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    const incomingCall = call?.jiveCall as IncomingCall;

    if (!incomingCall || !incomingCall.reject) {
      return;
    }

    await incomingCall.reject();
    delete this.calls[callId];
  }

  async muteCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    if (!call) {
      return;
    }

    await call.jiveCall.mute();
    call.isMuted = true;
    this.fireCallUpdateEvent('updated', call);
  }

  async unmuteCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    if (!call) {
      return;
    }

    await call.jiveCall.unmute();
    call.isMuted = false;
    this.fireCallUpdateEvent('updated', call);
  }

  async holdCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    if (!call) {
      return;
    }

    await call.jiveCall.hold();
    call.callState = 'on_hold';
    this.fireCallUpdateEvent('updated', call);
  }

  async resumeCall(callId: string): Promise<void> {
    const call = this.calls[callId];
    if (!call) {
      return;
    }

    await call.jiveCall.resume();
    call.callState = 'answered';
    this.fireCallUpdateEvent('updated', call);
  }

  async sendDtmf(callId: string, value: string): Promise<void> {
    const call = this.calls[callId];
    if (!call) {
      return;
    }

    await call.jiveCall.dtmf(value);
  }

  async holdOtherCalls(callId?: string): Promise<void> {
    const callArray = Object.values(this.calls);
    const restOfTheCalls = callId ? callArray.filter((call) => call.id !== callId) : callArray;
    await Promise.all(restOfTheCalls.map((call) => this.holdCall(call.id)));
  }

  private subscribeToCallEvents = (jiveCall: JiveCall) => {
    let _callId = jiveCall.id; // this getter might get lost during events
    const getCallId = (): string | null => {
      _callId = jiveCall.id ? jiveCall.id : _callId;
      return _callId;
    };

    // Call Ringing Event:
    jiveCall.on(jiveCall.RINGING, async () => {
      // The call has reach the callee and is now ringing on the callee's device
      // The user can expect a voice mail if the callee does not answer
      const callId = getCallId();
      const call = callId ? this.calls[callId] : undefined;

      if (!call) {
        return;
      }
      const previousState = call.callState;

      call.id = callId ?? call.id;
      call.callState = 'ringing';
      this.fireCallUpdateEvent('updated', call);

      // This callback can fire multiple times. Making sure to play the sound only once
      if (previousState !== 'ringing') {
        await this.ringService.playOutgoingSound();
      }
    });

    // Call Connecting Event:
    jiveCall.on(jiveCall.CONNECTING, () => {
      // The call was sent and is being processed by the network
    });

    // Call Connected Event:
    jiveCall.on(jiveCall.CONNECTED, () => {
      this.ringService.stopRinging();
      const callId = getCallId();
      const call = callId ? this.calls[callId] : undefined;

      if (!call) {
        return;
      }

      call.id = callId ?? call.id;
      call.callState = 'answered';
      call.answerTime = Date.now();
      this.fireCallUpdateEvent('updated', call);
      // The call was answered and is now connected successfully
    });

    // Call Track Event:
    jiveCall.on(jiveCall.TRACK, (track) => {
      // The remote stream can be attached to the DOM
      // The type indicates if the stream includes video or is audio only
      const elem = document.createElement('audio');
      elem.autoplay = true;
      elem.srcObject = track;
      elem.onloadedmetadata = () => elem.play();

      document.body.appendChild(elem);
      window.addEventListener('beforeunload', this.onBeforeUnload);

      // See Track Listeners Section For Track Handling
    });

    // Call Voice Detection Event:
    jiveCall.on(jiveCall.CALLEE_TALKING, () => {
      // Callee talking with level (0 to 5)
    });

    // Call Voice Stopped Detection Event:
    jiveCall.on(jiveCall.CALLEE_STOPPED_TALKING, () => {
      // Callee stopped talking
    });

    // Call No Media Event:
    jiveCall.on(jiveCall.NO_MEDIA_RECEIVED, () => {
      // User is not receiving media on his stream, with interval (seconds)
    });

    // Call Disconnected Event:
    jiveCall.on(jiveCall.DISCONNECTED, async (reason) => {
      // detect pickup: reason.errorCode = CALL_COMPLETED_ELSEWHERE
      // hangup: reason.errorCode = HANGUP

      this.ringService.stopRinging();
      const callId = getCallId();
      const call = callId ? this.calls[callId] : undefined;

      if (!call) {
        return;
      }

      call.id = callId ?? call.id;
      call.callState = 'ended';
      this.fireCallUpdateEvent('ended', call);

      // The user is no longer in an active call
      // This event can be received by a graceful hangup from the user or by an error on the call connection
      const type = reason.errorCode;
      const error = reason.message;

      console.log('CALL_DISCONNECTED', type, error);

      if (callId) {
        delete this.calls[callId];
      } else {
        // TODO
        console.log('error removing call', jiveCall);
      }

      // remove the beforeunload handler if there is no other call to handle
      if (!Object.keys(this.calls).length) {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
      }

      setTimeout(async () => {
        if (!this.store) {
          return;
        }

        const remoteCall = selectSoftphoneCallsByCalleeSelector(call.theOtherParty.number)(this.store.getState());

        if (remoteCall.length > 0) {
          return;
        }

        await this.ringService.playHangupSound(call.theOtherParty.number);
      }, 200);
    });
  };

  private onBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  private fireCallUpdateEvent(event: 'started' | 'updated' | 'ended', call: Call) {
    if (!call.id) {
      return;
    }

    if (this.callUpdateSubscriptions) {
      for (const callback of this.callUpdateSubscriptions) {
        try {
          callback(event, this.mapCallToSoftphoneCall(call));
        } catch (error: any) {
          logger.error('Softphone call update handler error', error);
        }
      }
    }
  }

  private mapCallToSoftphoneCall(call: Call): SoftphoneCall {
    return {
      id: call.id,
      callState: call.callState,
      direction: call.direction,
      isMuted: call.isMuted,
      theOtherParty: call.theOtherParty,
      startTime: call.startTime,
      answerTime: call.answerTime,
      isRemoteCall: false,
    };
  }

  private hasOngoingCalls(): boolean {
    const activeCallStates: SoftphoneCallState[] = ['answered', 'on_hold', 'ringing'];
    return Object.values(this.calls).some((call) => activeCallStates.includes(call.callState));
  }

  private async getDefaultAudioInput() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter((device) => device.kind === 'audioinput');
    const defaultDevice = audioInputDevices.find((device) => device.deviceId === 'default') ?? audioInputDevices[0];
    return defaultDevice;
  }

  private async getDefaultAudioOutput() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter((device) => device.kind === 'audiooutput');
    const defaultDevice = audioInputDevices.find((device) => device.deviceId === 'default') ?? audioInputDevices[0];
    return defaultDevice;
  }
}

export const sessionManager = new JiveRtcSessionManager();
