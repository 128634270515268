import { Box, CircularProgress } from '@material-ui/core';
import React, { FC, useEffect, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TodaysCallsSummary } from '../components/TodaysCallsSummary.container';
import { Typography } from '../theme/Typography.component';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CallHistoryItem } from './CallHistoryItem.component';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import InfiniteLoader from 'react-window-infinite-loader';
import { getActionFacade } from '../actionFacade/action.facade.store';
import {
  callHistoryArraySelector,
  unifiedCallHistoryHasNextPageSelector,
  unifiedCallHistoryItemsPageSelector,
  isUnifiedCallHistoryLoadingSelector,
  unifiedCallHistoryInitialLoadDateSelector,
} from '../callHistoryPush/unifiedCallHistory.selector';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';

const definedMessages = defineMessages({
  CALL_HISTORY_HISTORY_TITLE: {
    id: 'CallHistory.History.Title',
    defaultMessage: 'Call History',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_HISTORY_LOAD_STARTED: {
    category: AnalyticsCategory.Callhistory,
    action: AnalyticsAction.LoadListStarted,
    label: 'New call history page load started',
  },
});

export const CallHistory: FC = () => {
  const history = useSelector(callHistoryArraySelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const page = useSelector(unifiedCallHistoryItemsPageSelector);
  const hasNextPage = useSelector(unifiedCallHistoryHasNextPageSelector);
  const isNextPageLoading = useSelector(isUnifiedCallHistoryLoadingSelector);
  const selectedOrganizationId = useSelector(selectedOrganizationIdSelector);
  const unifiedCallHistoryInitialLoadDate = useSelector(unifiedCallHistoryInitialLoadDateSelector);

  const isMounting = useRef(true);

  useEffect(() => {
    // This effect handles the initial data fetch, when the user opens the call history for the first time
    if (page === 0 && selectedOrganizationId) {
      void getActionFacade().loadCallHistoryItems(selectedOrganizationId, 0);
    }
  }, [page, selectedOrganizationId]);

  useEffect(() => {
    // This effect handles loading the top of the call history. This is necessary to keep the call history up-to-date when the user revisits the page.
    // We shouldn't trigger the two effects together, because this one will load a 0 sec interval which will trigger an HTTP 400 response. The isNextPageLoading check will help to avoid this.
    if (!isMounting.current || !unifiedCallHistoryInitialLoadDate || isNextPageLoading) {
      return;
    }

    void getActionFacade().loadCallHistoryItems(selectedOrganizationId, 0, unifiedCallHistoryInitialLoadDate);
    isMounting.current = false;
  }, [selectedOrganizationId, unifiedCallHistoryInitialLoadDate]);

  const isItemLoaded = (index) => !hasNextPage || index < history.length;

  const loadMoreItems = async () => {
    if (isNextPageLoading || !hasNextPage) {
      return;
    }

    newTracker.trackAnalyticsEvent({ ...trackingEvents.CALL_HISTORY_LOAD_STARTED, value: page + 1 });
    await getActionFacade().loadCallHistoryItems(selectedOrganizationId, page + 1);
  };

  const itemSize = isNarrowIntegration ? 100 : 85;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <TodaysCallsSummary />
      <Box display="flex" flexGrow={1} flexDirection="column">
        <Box mb={3} display="flex" alignItems="center">
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.CALL_HISTORY_HISTORY_TITLE} />
          </Typography>
          {isNextPageLoading && <CircularProgress size={17} style={{ marginLeft: 12 }} />}
        </Box>
        <Box flexGrow={1}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={hasNextPage ? Number.MAX_SAFE_INTEGER : history.length}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={50}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    width={width}
                    itemSize={itemSize}
                    itemCount={history.length}
                    itemData={history}
                  >
                    {CallHistoryItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </Box>
  );
};
