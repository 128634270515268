import React from 'react';
import { Theme, makeStyles, LinearProgress } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Integrations, IntegrationNames } from '../models/index';
import { formatPhoneNumber } from '../phone/phone-utils';
import { getNumberOfTheOtherParty } from './call.helper';
import { CallWithContact } from './calls.reducer';
import { Typography } from '../theme/Typography.component';

export const definedMessages = defineMessages({
  CHECKING_MATCHING_CONTACTS: {
    id: 'Call.Snackbar.ActiveCall.ContactMatchingInProgress',
    defaultMessage: 'Searching for {phoneNumber}',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: tokens.uiBase01,
  },
  textContainer: {
    textAlign: 'center',
    margin: theme.spacing(2, 1, 3, 1),
  },
  phoneNumber: {
    whiteSpace: 'nowrap',
  },
}));

export interface CallMatchingProgressComponentProps {
  integration: Integrations;
  call: CallWithContact;
}

export const CallMatchingProgressComponent: React.FC<CallMatchingProgressComponentProps> = ({ call, integration }) => {
  const classes = useStyles();

  const messageValues = {
    integrationName: IntegrationNames[integration],
    phoneNumber: <span className={classes.phoneNumber}>{formatPhoneNumber(getNumberOfTheOtherParty(call))}</span>,
  };

  return (
    <div className={classes.root}>
      <LinearProgress />
      <div className={classes.textContainer}>
        <Typography variant="caption-default-01" color="text-info">
          <FormattedMessage values={messageValues} {...definedMessages.CHECKING_MATCHING_CONTACTS} />
        </Typography>
      </div>
    </div>
  );
};
