import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { PhoneActionsType } from '../phone/phone.actions';
import { EntitiesActionsType } from '../search/entities.action';
import { getNotes, GetNotesPayload, NoteActionsType, resetNotes } from './notes.actions';

addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, action) => {
  const currentIntegration: string = action.payload;

  if (isCurrentIntegrationSupported(currentIntegration)) {
    registerNotesListeners();
  }
});

function isCurrentIntegrationSupported(currentIntegration: string): boolean {
  return (
    currentIntegration === Integrations.Clio ||
    currentIntegration === Integrations.SalesforceLightning ||
    currentIntegration === Integrations.Redtail ||
    currentIntegration === Integrations.ConnectWise
  );
}

let registered = false;

function registerNotesListeners() {
  if (registered) {
    return;
  }

  registered = true;

  // Refresh the note list when the user selects another contact from the search result list.
  addListener(PhoneActionsType.SET_SELECTED_ENTITY)((_, action) => {
    if (action.payload.entityId === undefined) {
      return resetNotes();
    }

    const entityId: string = '' + action.payload.entityId;

    if (entityId) {
      const payload: GetNotesPayload = {
        contactId: entityId,
      };
      return getNotes(payload);
    }
  });

  // Clear the notes list when the user initiates a new contact search.
  addListener(EntitiesActionsType.ENTITY_SEARCH)((_, __) => {
    return resetNotes();
  });

  addListener(NoteActionsType.ADD_NOTE_SUCCESS)(async (_, action) => {
    if (!action.payload.contactId) {
      return;
    }

    return getNotes({ contactId: action.payload.contactId });
  });
}
