import { goToRouteWithParams } from '../browserHistory';
import { PostCallActionsType } from '../calls/callEvents.action';
import { ApplicationRoute, PARAM_CALLID } from '../constants';
import { addListeners } from '../middlewares/listener.middleware';

addListeners([PostCallActionsType.UPDATE_ACTIVE_CALL])((_, action) => {
  const callId = action.payload.call.id;
  const params = { [PARAM_CALLID]: callId };

  goToRouteWithParams(ApplicationRoute.CALL_ROUTE, params);
});
