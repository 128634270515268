import { CallType, RealtimeActionTypes } from '@jive/realtime-events';
import { defineMessages, IntlShape } from 'react-intl';
import { ContactActionsType, ContactFoundAction } from '../actions/contacts';
import { getNameOfTheOtherParty, getNumberOfTheOtherParty } from '../calls/call.helper';
import { getContactDisplay, callSelector } from '../calls/calls.selector';
import { ConnectivityActionTypes } from '../connectivity/connectivity.action';
import { isOnlineSelector, isRealtimeOnlineSelector } from '../connectivity/connectivity.selector';
import { addListener, addListeners } from '../middlewares/listener.middleware';
import { injectedIntlSelector } from '../settings/settings.selector';
import { DesktopNotification } from './desktopNotification';
import { DesktopNotificationParams } from './desktopNotification.models';

const definedMessages = defineMessages({
  INCOMING_CALL_TITLE: {
    id: 'DesktopNotification.IncomingCall.Title',
    defaultMessage: 'Incoming call',
  },
  APP_OFFLINE_TITLE: {
    id: 'DesktopNotification.AppOffline.Title',
    defaultMessage: 'Connection Lost',
  },
  APP_OFFLINE_MESSAGE: {
    id: 'DesktopNotification.AppOffline.Message',
    defaultMessage: 'Please, check your internet connection!',
  },
});

const notificationTags = {
  CALL: 'call_notification', // in case the tag is `call` the notification won't show up in Chrome (probably a bug in Chrome)
  NETWORK: 'network_notification',
};

addListener(ContactActionsType.CONTACT_FOUND)(async (state, action) => {
  const call = callSelector(state, action.payload.callId);
  if (call?.type !== CallType.IncomingCall) {
    return;
  }

  const intl = injectedIntlSelector(state);
  await showIncomingCallNotification(intl, getIncomingCallNotificationMessage(intl, action), call.id);
});

addListener(ContactActionsType.CONTACT_NOT_FOUND)(async (state, action) => {
  const call = callSelector(state, action.payload.callId);
  if (call?.type !== CallType.IncomingCall) {
    return;
  }

  const intl = injectedIntlSelector(state);
  await showIncomingCallNotification(intl, action.payload.phoneNumber, call.id);
});

addListeners([ConnectivityActionTypes.APP_OFFLINE, RealtimeActionTypes.REALTIME_OFFLINE])(async (state, _) => {
  await showAppOfflineNotification(state);
});

addListeners([ConnectivityActionTypes.APP_ONLINE, RealtimeActionTypes.REALTIME_ONLINE])((state, __) => {
  const isRealtimeOnline = isRealtimeOnlineSelector(state);
  const isAppOnline = isOnlineSelector(state);

  if (isRealtimeOnline && isAppOnline) {
    hideAppOfflineNotifications();
  }
});

function showIncomingCallNotification(intl: IntlShape, message: string, callId: string): Promise<void> {
  const params: DesktopNotificationParams = {
    title: definedMessages.INCOMING_CALL_TITLE,
    tag: callId,
    message,
  };

  return DesktopNotification.show(intl, params);
}

function showAppOfflineNotification(state): Promise<void> {
  const intl = injectedIntlSelector(state);
  const params: DesktopNotificationParams = {
    title: definedMessages.APP_OFFLINE_TITLE,
    message: definedMessages.APP_OFFLINE_MESSAGE,
    tag: notificationTags.NETWORK,
    showUntilClicked: true,
  };

  return DesktopNotification.show(intl, params);
}

function getIncomingCallNotificationMessage(intl: IntlShape, action: ContactFoundAction) {
  const contactDisplay = getContactDisplay(intl, action.payload.allMatches);
  const [callerIdNumber, callerIdName] = [
    getNumberOfTheOtherParty(action.payload),
    getNameOfTheOtherParty(action.payload),
  ];
  if (callerIdName && callerIdNumber && action.payload.allMatches.length !== 1) {
    return `${contactDisplay} (${callerIdNumber} <${callerIdName}>)`;
  } else if (callerIdNumber) {
    return `${contactDisplay} (${callerIdNumber})`;
  }

  return contactDisplay;
}

function hideAppOfflineNotifications(): void {
  DesktopNotification.hide(notificationTags.NETWORK);
}
