import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ActionFacadeProvider } from './ActionFacadeProvider';
import LoginSuccess from './authentication/LoginSuccess.component';
import LogoutSuccess from './authentication/LogoutSuccess.component';
import { getIntegration, history } from './browserHistory';
import { IntegrationPickerComponent } from './components/IntegrationPicker.component';
import { NotificationPermissions } from './notificationPermission/NotificationPermissions.component';
import { getPersistor, getStore } from './configureStore';
import ConnectedIntlProvider from './ConnectedIntlProvider';
import { NoInternetConnectionComponent } from './connectivity/NoInternetConnection.component';
import { ApplicationRoute } from './constants';
import './debug/app-debugger';
import Dialog from './dialog/Dialog.container';
import EnvironmentsDisplayComponent from './EnvironmentsDisplay.component';
import Main from './Main.container';
import { Onboarding } from './onboarding/Onboarding.component';
import { InAppNotifications } from './inAppNotification/InAppNotifications.component';
import AppMuiPickersUtilsProvider from './AppMuiPickersUtilsProvider.container';
import { analyticsPageTypeListener } from './analytics-new/analyticsPageTypeListener';
import { LoginComponent } from './authentication/Login.component';
import { LogoutComponent } from './authentication/Logout.component';
import { Integrations } from './models';
import { storeCurrentRoute, checkAndNavigateToPersistedRoute } from './persistedRouteHandler';
import { splunkLogger } from './logging/splunk-logger';
import { GlobalStyle } from '@getgo/chameleon-material-ui';
import { AppThemeProvider } from './theme/AppThemeProvider.component';
import { setupQualtricsSurvey } from './qualtricsSurvey/qulatricsSurvey';
import { TranslationLoader } from './i18n/TranlationLoader';
import { FullPageAppLoading } from './components/FullPageAppLoading';
import { MicrophonePermissions } from './softphone/MicrophonePermissions.component';

const MsTeamsRoutes = React.lazy(() => import('./msteams/Routes'));

function initReactAxe() {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    import('react-axe')
      .then((module) => module.default)
      .then((axe) => axe(React, ReactDOM, 1000))
      .catch((error) => console.log('react-axe import error: ' + error));
  }
}

const App: React.FC = () => {
  const integration = getIntegration();

  useEffect(() => {
    initReactAxe();
  }, []);

  useEffect(() => {
    if (integration === Integrations.SalesforceClassic) {
      window.addEventListener('beforeunload', storeCurrentRoute);
      checkAndNavigateToPersistedRoute();
      return () => {
        window.removeEventListener('beforeunload', storeCurrentRoute);
      };
    }
  }, [integration]);

  const store = getStore(integration);
  splunkLogger.init(store.getState);
  analyticsPageTypeListener(history, store);

  if (integration !== Integrations.MsTeams) {
    setupQualtricsSurvey(store);
  }

  return (
    <>
      <Provider store={store as any}>
        <ActionFacadeProvider store={store} integration={integration}>
          <PersistGate persistor={getPersistor()}>
            <AppThemeProvider>
              <TranslationLoader>
                <ConnectedIntlProvider>
                  <>
                    <GlobalStyle />
                    <AppMuiPickersUtilsProvider>
                      <NoInternetConnectionComponent>
                        <InAppNotifications />
                        <Dialog />
                        <Suspense fallback={<FullPageAppLoading />}>
                          <Router history={history}>
                            <Switch>
                              {integration === Integrations.MsTeams ? (
                                <>
                                  <Route path="/msteams" component={MsTeamsRoutes} />
                                  <Route path="/embedded-integrations/msteams" component={MsTeamsRoutes} />
                                </>
                              ) : null}

                              <Route
                                exact
                                path={ApplicationRoute.NOTIFICATION_PERMISSION_ROUTE}
                                component={NotificationPermissions}
                              />
                              <Route
                                exact
                                path={ApplicationRoute.MICROPHONE_PERMISSION}
                                component={MicrophonePermissions}
                              />
                              <Route exact path={ApplicationRoute.LOGIN} component={LoginComponent} />
                              <Route exact path={ApplicationRoute.LOGIN_SUCCESS} component={LoginSuccess} />
                              <Route exact path={ApplicationRoute.LOGOUT} component={LogoutComponent} />
                              <Route
                                exact
                                path={ApplicationRoute.LOGOUT_SUCCESS}
                                component={(props) => <LogoutSuccess {...props} />}
                              />
                              <Route
                                exact
                                path={ApplicationRoute.INTEGRATION_PICKER_ROUTE}
                                component={IntegrationPickerComponent}
                              />
                              <Route
                                path={ApplicationRoute.ONBOARDING}
                                component={(props) => <Onboarding {...props} />}
                              />
                              <Route path="/" render={({ location }) => <Main location={location} />} />
                            </Switch>
                          </Router>
                        </Suspense>
                      </NoInternetConnectionComponent>
                    </AppMuiPickersUtilsProvider>
                  </>
                </ConnectedIntlProvider>
              </TranslationLoader>
            </AppThemeProvider>
          </PersistGate>
        </ActionFacadeProvider>
      </Provider>
      <EnvironmentsDisplayComponent />
    </>
  );
};

export default App;
