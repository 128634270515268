import { LaunchScreen } from '@getgo/chameleon-web/react';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageWithMessageValues } from '../reactInltModels';

const useStyles = makeStyles(() => ({
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flex: 1,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export interface FullPageAppLoadingProps {
  text?: FormattedMessageWithMessageValues;
}

export const FullPageAppLoading: FC<FullPageAppLoadingProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.centeredContainer}>
      <div className={classes.container}>
        <LaunchScreen data-cy="app-loading" />
        <p>{text ? <FormattedMessage {...text} /> : <span>&nbsp;</span>}</p>
      </div>
    </div>
  );
};
