import React, { FC, useEffect, useState } from 'react';
import { LoudSpeakerIcon } from '@getgo/chameleon-icons/react';
import { Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { lastSeenVersionLoadingStateSelector, lastSeenVersionSelector } from './changelog.selector';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ChangelogRequestState } from './changelog.reducer';
import { changelogVersionManager } from './changelog.versionmanager';

export const ChangelogIcon: FC = () => {
  const integration = useSelector(currentIntegrationSelector);
  const lastSeenVersion = useSelector(lastSeenVersionSelector);
  const loadingState = useSelector(lastSeenVersionLoadingStateSelector);
  const [isBadgeVisible, setBadgeVisible] = useState(false);

  useEffect(() => {
    if (loadingState === ChangelogRequestState.INITIAL) {
      void getActionFacade().loadLastSeenVersion();
    }

    if (loadingState === ChangelogRequestState.LOADED) {
      void (async () => {
        const hasNewerVersion = await changelogVersionManager.hasNewerVersion(integration, lastSeenVersion);
        setBadgeVisible(hasNewerVersion);
      })();
    }
  }, [loadingState, integration, lastSeenVersion]);

  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!isBadgeVisible}
      style={{ height: 'var(--goto-icon-size, 18px)', width: 'var(--goto-icon-size, 18px)' }}
    >
      <LoudSpeakerIcon />
    </Badge>
  );
};
