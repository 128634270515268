import { Reducer } from 'redux';
import { AmbassadorProvider, AmbassadorUserProvider } from '../ambassador.service';
import { ProxyActions, ProxyActionsTypes } from './proxy.action';
import './proxy.listener';

export interface ProxyState {
  linked?: boolean;
  provider?: AmbassadorProvider;
  userProvider?: AmbassadorUserProvider;
  providerAvailable?: boolean;
}

export const PROXY_INITIAL_STATE = {
  linked: undefined,
  provider: undefined,
  userProvider: undefined,
  providerAvailable: undefined,
};

export const proxyReducer: Reducer<ProxyState, ProxyActions> = (state = PROXY_INITIAL_STATE, action) => {
  switch (action.type) {
    case ProxyActionsTypes.PROXY_CHECK_LINK:
      return {
        ...state,
        linked: undefined,
        provider: undefined,
        userProvider: undefined,
        providerAvailable: undefined,
      };
    case ProxyActionsTypes.PROXY_CHECK_LINK_ERROR:
      return {
        ...state,
        linked: false,
        providerAvailable: false,
        provider: undefined,
        userProvider: undefined,
      };
    case ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS:
      return {
        ...state,
        linked: action.payload.linked,
        provider: action.payload.provider,
        userProvider: action.payload.userProvider,
        providerAvailable: action.payload.providerAvailable,
      };
    default:
      return state;
  }
};
