import type { JifTenantPbx, JifUserData, UserLineData } from '@jive/core';
import axios from 'axios';
import config from '../config';
import { IndexedById } from '../models';
import { NormalizedLine } from './lines.model';
import { Organization } from '../settings/organizations.model';

async function loadNormalizedLines(jiveId: string): Promise<{
  organizations: IndexedById<Organization>;
  lines: IndexedById<NormalizedLine>;
}> {
  const url = `${config.apiBaseUrl}/users/v1/users/${jiveId}/lines`;

  const lines = await axios.get<UserLineData>(url);
  const result = lines.data.items.reduce(
    (result, item) => {
      result.lines[item.id] = {
        ...item,
        organization: item.organization.id,
      };
      result.organizations[item.organization.id] = item.organization;

      return result;
    },
    { organizations: {}, lines: {} },
  );

  return result;
}

async function loadNormalizedPbxes(jiveId: string): Promise<IndexedById<JifTenantPbx>> {
  const url = `${config.apiBaseUrl}/jif/v4/user/jiveId/${jiveId}`;
  const newres = await axios.get<JifUserData>(url);

  const pbxes = newres.data.data.tenants.pbxes.reduce((normalized, pbx) => {
    normalized[pbx.id] = pbx;
    return normalized;
  }, {});

  return pbxes;
}

export const linesService = {
  loadNormalizedLines,
  loadNormalizedPbxes,
};
