import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../../actions/actionCreators';
import { FormattedMessageWithMessageValues } from '../../reactInltModels';
import { AmbassadorProvider, AmbassadorUserProvider } from '../ambassador.service';

export enum ProxyActionsTypes {
  PROXY_CHECK_LINK = 'proxy/check/link',
  PROXY_CHECK_LINK_SUCCESS = 'proxy/check/link/success',
  PROXY_CHECK_LINK_ERROR = 'proxy/check/link/error',
}

export const checkProxyLink = makeActionCreator(ProxyActionsTypes.PROXY_CHECK_LINK)();

export interface ProxyLinkPayload {
  linked: boolean;
  provider?: AmbassadorProvider;
  userProvider?: AmbassadorUserProvider;
  providerAvailable?: boolean;
  message?: MessageDescriptor;
}

export interface ProxyLinkErrorPayload {
  message?: FormattedMessageWithMessageValues;
}

export const checkProxyLinkSuccess = makeActionCreator(ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS)<ProxyLinkPayload>();
export const checkProxyLinkError = makeActionCreator(ProxyActionsTypes.PROXY_CHECK_LINK_ERROR)<ProxyLinkErrorPayload>();

export type ProxyActions =
  | ReturnType<typeof checkProxyLinkError>
  | ReturnType<typeof checkProxyLinkSuccess>
  | ReturnType<typeof checkProxyLink>;
