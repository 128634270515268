import { Store } from 'redux';
import { CAPABILITY_CONVERSATIONS } from '../actionFacade/action.facade.capabilities';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { AppState } from '../reducers';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { StartupActionCreator } from '../startup/startup.actioncreator';
import { ConversationCapability } from './conversationCapability';

export class ClioStartupActionCreator extends StartupActionCreator {
  constructor(store: Store<AppState>, private conversationCapability: ConversationCapability) {
    super(store);
  }

  protected async loadConversationCapability() {
    const state = this.store.getState();
    const organizationId = selectedOrganizationIdSelector(state);

    const isCapable = await this.conversationCapability.isCapableForConversation(organizationId);
    getActionFacade().setCapability(CAPABILITY_CONVERSATIONS, isCapable);
  }
}
