import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { GetNoteTypesRequestState, ConnectWiseNoteType } from '../../connectwise.model';
import { TrackingEvent } from '../../../analytics-new/analytics.models';
import { getActionFacade } from '../../../actionFacade/action.facade.store';
import { ConnectWiseActionFacade } from '../../connectwise.action.facade';
import { newTracker } from '../../../analytics-new/tracker-new';
import { Autocomplete } from '@getgo/chameleon-material-ui';
import { definedMessages as commonDefinedMessages } from '../../../inAppNotification/message.content';

export interface NoteTypePickerProps {
  noteTypeId: number;
  onNoteTypeSelected: (noteTypeId: number) => void;
  trackingEvent?: TrackingEvent;
}

export interface NoteTypePickerStateProps {
  noteTypes: ConnectWiseNoteType[];
  requestState: GetNoteTypesRequestState;
}

const definedMessages = defineMessages({
  NOTETYPE_PICKER_LOADING_MESSAGE: {
    id: 'Dropdown.Values.Loading.Message',
    defaultMessage: 'Loading...',
  },
  NOTETYPE_PICKER_EMPTY_MESSAGE: {
    id: 'ConnectWise.NoteTypePicker.Empty.Message',
    defaultMessage: 'No note type found',
  },
  NOTETYPE_PICKER_NOTETYPE: {
    id: 'ConnectWise.NoteTypePicker.NoteType',
    defaultMessage: 'Note Type',
  },
});

export const NoteTypePickerComponent: React.FC<NoteTypePickerProps & NoteTypePickerStateProps> = ({
  requestState,
  noteTypeId,
  onNoteTypeSelected,
  noteTypes,
  trackingEvent,
}) => {
  const intl = useIntl();

  useEffect(() => {
    void getActionFacade<ConnectWiseActionFacade>().getNoteTypes();
  }, []);
  const [isTrackedAlready, setTrackedAlready] = useState(false);

  useEffect(() => {
    if (noteTypes && noteTypes.length > 0 && noteTypeId === -1) {
      onNoteTypeSelected(noteTypes[0].id);
    }
  }, [noteTypes, noteTypeId, onNoteTypeSelected]);

  const isLoading = requestState === GetNoteTypesRequestState.LOADING;

  return (
    <Autocomplete
      options={noteTypes}
      openOnFocus
      disableClearable
      loading={isLoading}
      getOptionSelected={(type, selected) => type.id === selected.id}
      getOptionLabel={(nt) => nt.name}
      // @ts-ignore
      value={noteTypes.length && noteTypeId > -1 ? noteTypes.find((nt) => nt.id === noteTypeId) ?? null : null}
      onChange={(_, nt) => {
        onNoteTypeSelected(nt.id);
        if (!isTrackedAlready && trackingEvent) {
          setTrackedAlready(true);
          newTracker.trackAnalyticsEvent(trackingEvent);
        }
      }}
      loadingText={<FormattedMessage {...definedMessages.NOTETYPE_PICKER_LOADING_MESSAGE} />}
      noOptionsText={<FormattedMessage {...definedMessages.NOTETYPE_PICKER_EMPTY_MESSAGE} />}
      TextFieldProps={{
        noMargin: true,
        placeholder: intl.formatMessage(commonDefinedMessages.FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER),
        label: intl.formatMessage(definedMessages.NOTETYPE_PICKER_NOTETYPE),
      }}
    />
  );
};
