import { makeActionCreator } from '../../actions/actionCreators';
import { Note } from '../../notes/notes.models';

export enum ConnectWiseNoteActionsType {
  GET_NOTES_SUCCESS = 'connectwise/notes/get/success',
}

export interface GetConnectWiseNotesSuccessPayload {
  notes: Note[];
}

export const getConnectWiseNotesSuccess = makeActionCreator(ConnectWiseNoteActionsType.GET_NOTES_SUCCESS)<
  GetConnectWiseNotesSuccessPayload
>();

export type ConnectWiseNoteActions = ReturnType<typeof getConnectWiseNotesSuccess>;
