import { createSelector } from 'reselect';
import { proxyProviderSelector, proxyUserProviderSelector } from '../../ambassador/proxy/proxy.selector';
import { AppState } from '../../reducers';

export const clioProxyUserProviderRegionSelector: (state: AppState) => string = createSelector(
  (state) => proxyUserProviderSelector(state),
  (userProvider) => {
    if (!userProvider || !userProvider.customAttributes.regionApiUrl) {
      return 'US';
    }

    const apiUrl = userProvider.customAttributes.regionApiUrl;

    if (apiUrl.toLowerCase().includes('eu.app.clio.com')) {
      return 'EU';
    } else if (apiUrl.toLowerCase().includes('ca.app.clio.com')) {
      return 'CA';
    }

    return 'US';
  },
);

export const clioProxyProviderClientIdSelector: (state: AppState) => string | undefined = createSelector(
  (state) => proxyProviderSelector(state),
  clioProxyUserProviderRegionSelector,
  (provider, region) => provider && (provider.publicAuthKeys[`Client_Id_${region}`] as string),
);
