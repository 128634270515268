import { Call } from '@jive/realtime-events';
import { activeCallsReducer } from '@jive/realtime-events';
import { combineReducers, Reducer } from 'redux';
import {
  AssociateContactAction,
  ContactActionsType,
  ContactFoundAction,
  ContactNotFoundAction,
  ContactMatchingRequestedAction,
} from '../actions/contacts';
import { Entity, IndexedById } from '../models';
import { EntitiesActions, EntitiesActionsType } from '../search/entities.action';

export interface Contact {
  allMatches?: Entity[];
  entitySelected?: string | undefined;
  isAutoSingleMatch?: boolean;
}
export interface CallWithDuration extends Call {
  duration?: number;
}

export type CallWithContact = CallWithDuration & Contact;

export interface ContactMatchingProgress {
  isLoading: boolean;
}

export interface ContactState {
  byId: IndexedById<Contact & ContactMatchingProgress>;
}
export const CONTACTS_INITIAL_STATE: ContactState = {
  byId: {},
};

export const contactsReducer: Reducer<
  ContactState,
  ContactFoundAction | ContactNotFoundAction | AssociateContactAction | ContactMatchingRequestedAction | EntitiesActions
> = (state = CONTACTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case ContactActionsType.CONTACT_FOUND: {
      const { allMatches, callId, autoSingleMatch } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [callId]: {
            ...state.byId[callId],
            allMatches,
            isLoading: false,
            entitySelected: autoSingleMatch?.id,
            isAutoSingleMatch: !!autoSingleMatch,
          },
        },
      };
    }
    case ContactActionsType.CONTACT_NOT_FOUND: {
      const { callId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [callId]: { ...state.byId[callId], allMatches: [], isLoading: false },
        },
      };
    }
    case ContactActionsType.CONTACT_ASSOCIATE: {
      const { entityId, callId, entities, isAutoSingleMatch } = action.payload;
      if (state.byId[callId]) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [callId]: {
              ...state.byId[callId],
              allMatches: entities || [],
              entitySelected: entityId,
              isAutoSingleMatch,
            },
          },
        };
      } else {
        return state;
      }
    }
    case ContactActionsType.CONTACT_MATCHING_REQUESTED: {
      const { callId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [callId]: { ...state.byId[callId], isLoading: true },
        },
      };
    }
    case EntitiesActionsType.CREATE_CONTACT_SUCCESS: {
      const { callId } = action.payload;
      if (state.byId[callId]) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [callId]: {
              ...state.byId[callId],
              allMatches: [...(state.byId[callId].allMatches || []), action.payload.entity],
            },
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export const callsReducer = combineReducers({
  activeCalls: activeCallsReducer,
  contacts: contactsReducer,
});

export type CallsState = ReturnType<typeof callsReducer>;
export default callsReducer;
