import { realtimeStatusReducer } from '@jive/realtime-events';
import { combineReducers } from 'redux';
import { ConnectivityActions, ConnectivityActionTypes } from './connectivity.action';

export interface InternetStatusState {
  online: boolean;
}
export const INTERNET_STATUS_INITIAL_STATE: InternetStatusState = {
  online: navigator.onLine,
};

export const internetStatusReducer = (state = INTERNET_STATUS_INITIAL_STATE, action: ConnectivityActions) => {
  switch (action.type) {
    case ConnectivityActionTypes.APP_ONLINE: {
      return { ...state, online: true };
    }
    case ConnectivityActionTypes.APP_OFFLINE: {
      return { ...state, online: false };
    }
    default:
      return state;
  }
};

export const connectivityReducer = combineReducers({
  internet: internetStatusReducer,
  realtime: realtimeStatusReducer,
});

export default connectivityReducer;
