import { Message, PagedResults } from '@ucc/messaging-platform-service';
import { MessageBody } from '@ucc/messaging-service';
import { createActionCreator } from '../actions/actionCreators';

interface ConversationSelector {
  conversationId: string;
}

interface SelectConversationMessages extends ConversationSelector {
  conversationMessageIds: string[];
}

export const loadConversationMessagesAction = createActionCreator('conversationMessages/load')<ConversationSelector>();

export const clearConversationMessagesAction = createActionCreator('conversationMessages/clear')<
  ConversationSelector
>();

export const loadConversationMessagesSuccessAction = createActionCreator('conversationMessages/load/success')<
  PagedResults<Message<MessageBody>> & ConversationSelector
>();

export const loadConversationMessagesErrorAction = createActionCreator('conversationMessages/load/error')<
  ConversationSelector
>();

export const selectConversationMessages = createActionCreator('conversationMessages/select')<
  SelectConversationMessages
>();

export const deselectConversationMessages = createActionCreator('conversationMessages/deselect')<
  SelectConversationMessages
>();

export const deselectAllConversationMessages = createActionCreator('conversationessages/deselectAll')<
  ConversationSelector
>();

export const sendLogMessagesRequestAction = createActionCreator('conversationMessages/logMessages/load')();

export const sendLogMessagesRequestSuccessAction = createActionCreator('conversationMessages/logMessages/load/success')<
  number
>();

export const sendLogMessagesRequestErrorAction = createActionCreator('conversationMessages/logMessages/load/error')();
