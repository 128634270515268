import axios from 'axios';
import config from '../config';

export const CLICK_TO_CALL_TIMEOUT = 5_000;

export const clickToCallUrl = `${config.apiBaseUrl}/calls/v2/calls`;

export async function initiateCall(phoneNumber: string, lineId: string) {
  await axios.post(clickToCallUrl, {
    dialString: phoneNumber,
    from: {
      lineId,
    },
  });
}
