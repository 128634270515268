import React, { FC } from 'react';
import { OnboardingPage } from './OnboardingPage.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ApplicationRoute } from '../constants';
import { Box } from '@material-ui/core';
import { Typography } from '../theme/Typography.component';
import {
  currentIntegrationNameSelector,
  isIntegrationLinkedIfNecessarySelector,
  isNarrowIntegrationSelector,
} from '../integrations/integrations.selector';
import { useSelector } from 'react-redux';
import { StepReadySection } from './StepReadySection.component';
import { OnboardingNavigationButton } from './OnboardingNavigationButton.component';
import { RedirectKeepSearch } from '../components/RedirectKeepSearch.component';
import { RestartButton } from '../error/RestartButton.component';

const definedMessages = defineMessages({
  HEADING: {
    id: 'Onboarding.EnterpriseLinking.Header',
    defaultMessage: `Admin setup missing`,
  },
  LINK_HINT: {
    id: 'Onboarding.EnterpriseLinking.Hint',
    defaultMessage: 'Please ask your admin to setup the {integrationName} integration first',
  },
  RESTART_BUTTON_TEXT: {
    id: 'Onboarding.EnterpriseLinking.RestartButton.Text',
    defaultMessage: 'Restart app',
  },
});

export const OnboardingEnterpriseLinking: FC = () => {
  const isIntegrationLinked = useSelector(isIntegrationLinkedIfNecessarySelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const integrationName = useSelector(currentIntegrationNameSelector);

  if (isIntegrationLinked) {
    return <RedirectKeepSearch pathname={ApplicationRoute.SETTINGS_ROUTE} />;
  }

  return (
    <OnboardingPage
      content={
        <Box>
          <Box mb={6}>
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              <FormattedMessage {...definedMessages.HEADING} />
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Box mb={isIntegrationLinked ? 2 : 4}>
              <StepReadySection ready={false}>
                <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'}>
                  <FormattedMessage {...definedMessages.LINK_HINT} values={{ integrationName }} />
                </Typography>
              </StepReadySection>
            </Box>
          </Box>
        </Box>
      }
      navigation={
        <Box>
          <Box mb={1}>
            <RestartButton>
              <FormattedMessage {...definedMessages.RESTART_BUTTON_TEXT} />
            </RestartButton>
          </Box>
          <OnboardingNavigationButton variant="previous" route={ApplicationRoute.ONBOARDING_TEST_CALL} />
        </Box>
      }
    />
  );
};
