import { Box, createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CallWithContact } from '../calls/calls.reducer';
import { Typography } from '../theme/Typography.component';
import { Avatar } from '@getgo/chameleon-web/react';
import {
  PhoneUpActiveIcon,
  PauseActiveIcon,
  PhoneDownActiveIcon,
  PauseInactiveIcon,
  SwapIcon,
} from '@getgo/chameleon-icons/react';
import { tokens } from '@getgo/chameleon-core';
import { SoftphoneCall } from '../softphone/softphone.model';
import { formatPhoneNumber } from '../phone/phone-utils';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import { CallDuration } from './CallDuration.component';
import { contactsByCallIdSelector, getContactDisplay } from '../calls/calls.selector';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import clsx from 'clsx';
import { TrackedRoundButton } from '../analytics-new/TrackedRoundButton.component';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SoftphoneCapableFacade } from '../actionFacade/action.facade.capabilities';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { selectHasOtherAnsweredCall } from '../softphone/softphoneCall.selector';

type StyleParams = { isActive: boolean; avatarColor: string };
const useStyles = makeStyles((theme) =>
  createStyles({
    activeCall: (params: StyleParams) => ({
      width: '100%',
      height: '48px',
      minHeight: '48px',
      padding: theme.spacing(1, 3),
      lineHeight: '18px',
      borderRadius: '4px',
      position: 'relative',
      backgroundColor: params.isActive ? tokens.surfaceColorPersistent : tokens.backgroundColorSecondary,
    }),
    badge: {
      position: 'absolute',
      bottom: '12px',
      right: '2px',
      borderRadius: '4px',
    },
    badgeOngoing: {
      backgroundColor: tokens.badgeColorVoice,
    },
    badgeHold: {
      backgroundColor: tokens.badgeColorCustom,
    },
    controls: (params: StyleParams) => ({
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      gap: theme.spacing(3),
      background: params.isActive
        ? `linear-gradient(270deg, ${tokens.surfaceColorPersistent} 70%, rgba(0, 0, 0, 0) 100%)`
        : `linear-gradient(270deg, ${tokens.backgroundColorSecondary} 70%, rgba(242, 246, 247, 0) 100%)`,
      overflow: 'hidden',
      borderRadius: '4px',
      transition: 'max-width .3s ease-in-out, padding .3s ease-in-out',
      '& chameleon-round-button::part(control)': params.isActive
        ? {
            borderColor: tokens.inverse02,
          }
        : {},
    }),
    avatar: (params: StyleParams) => ({
      '--goto-avatar-bg-color': params.avatarColor,
    }),
  }),
);

const trackingEvents = defineTrackingEvents({
  HOLD_CALL: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Hold call',
  },
  RESUME_CALL: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Resume call',
  },
  HANG_UP: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Hang up',
  },
});

const definedMessages = defineMessages({
  HOLD: {
    id: 'Softphone.Hold',
    defaultMessage: 'Hold',
  },
  HANG_UP: {
    id: 'Softphone.HangUp',
    defaultMessage: 'Hang up',
  },
});

interface ActiveCallListItemProps {
  softphoneCall?: SoftphoneCall;
  realtimeCall: CallWithContact;
  avatarColor: string;
}

export const ActiveCallListItem: React.FC<ActiveCallListItemProps> = ({ realtimeCall, softphoneCall, avatarColor }) => {
  const intl = useIntl();
  const contacts = useSelector((state: AppState) => contactsByCallIdSelector(state, realtimeCall.id));
  const [isHovering, setIsHovering] = useState(false);
  const hasOtherActiveCall = useSelector(selectHasOtherAnsweredCall(softphoneCall?.id));

  const contactDisplay = getContactDisplay(intl, contacts?.allMatches ?? [], contacts?.entitySelected);
  const isSoftphoneCall = softphoneCall !== undefined;
  const isOnHold = softphoneCall?.callState === 'on_hold';
  const isActiveCall = !isOnHold && isSoftphoneCall;
  const isIncomingNotAnsweredCall = softphoneCall?.direction === 'incoming' && softphoneCall?.callState !== 'answered';
  const textColor = isActiveCall ? 'inverse-02' : 'text-01';
  const classes = useStyles({ isActive: isActiveCall, avatarColor });

  return (
    <Box
      className={classes.activeCall}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item style={{ position: 'relative' }} xs="auto">
          <Avatar size="medium" className={classes.avatar} />
          {isSoftphoneCall ? (
            <span className={clsx(classes.badge, isOnHold ? classes.badgeHold : classes.badgeOngoing)}>
              {isOnHold ? (
                <PauseActiveIcon size="16px" color={tokens.inverse02} />
              ) : (
                <PhoneUpActiveIcon size="16px" color={tokens.inverse02} />
              )}
            </span>
          ) : null}
        </Grid>
        <Grid item xs container direction="column">
          <Grid item>
            <Typography tag="span" variant="body-small" color={textColor} lineClamp={1}>
              {contactDisplay}
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" justifyContent="space-between">
              <Typography tag="span" variant="caption-default-01" color={textColor}>
                {formatPhoneNumber(getNumberOfTheOtherParty(realtimeCall))}
              </Typography>
              <Typography tag="span" variant="code-text-small" color={textColor}>
                <CallDuration call={realtimeCall} format="mmss" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {softphoneCall ? (
        <Box
          className={classes.controls}
          style={{
            maxWidth: isHovering ? '150px' : 0,
            padding: isHovering ? `${tokens.spacing03} ${tokens.spacing03} ${tokens.spacing03} ${tokens.spacing06}` : 0,
          }}
        >
          <TrackedRoundButton
            size="small"
            variant="primary"
            title={intl.formatMessage(definedMessages.HOLD)}
            trackingEvent={isOnHold ? trackingEvents.RESUME_CALL : trackingEvents.HOLD_CALL}
            onClick={(event) => {
              event.stopPropagation();
              isOnHold
                ? void getActionFacade<SoftphoneCapableFacade>().resumeCall(softphoneCall.id)
                : void getActionFacade<SoftphoneCapableFacade>().holdCall(softphoneCall.id);
            }}
          >
            {isActiveCall || !hasOtherActiveCall ? <PauseInactiveIcon /> : <SwapIcon />}
          </TrackedRoundButton>

          <TrackedRoundButton
            variant={isActiveCall ? 'primary' : 'secondary'}
            size="small"
            title={intl.formatMessage(definedMessages.HANG_UP)}
            trackingEvent={trackingEvents.HANG_UP}
            onClick={(event) => {
              event.stopPropagation();
              isIncomingNotAnsweredCall
                ? void getActionFacade<SoftphoneCapableFacade>().rejectCall(softphoneCall.id)
                : void getActionFacade<SoftphoneCapableFacade>().hangupCall(softphoneCall.id);
            }}
          >
            <PhoneDownActiveIcon />
          </TrackedRoundButton>
        </Box>
      ) : null}
    </Box>
  );
};
