import { MsTeamsFeatureFlagActionsType } from '../constants';
import { MsTeamsFeatureFlagActions } from './featureFlag.action';

export interface FeatureFlagState {
  enabled: boolean | null;
}

export const INITIAL_STATE: FeatureFlagState = {
  enabled: null,
};

function featureFlagReducer(state = INITIAL_STATE, action: MsTeamsFeatureFlagActions) {
  switch (action.type) {
    case MsTeamsFeatureFlagActionsType.LOAD_SUCCESS:
      return {
        ...state,
        enabled: action.payload,
      };
  }

  return state;
}

export default featureFlagReducer;
