import axios from 'axios';
import configuration from '../config';

export const subscribeToCallHistoryChannel = (organizationId: string) => async (channelId: string): Promise<void> => {
  const url = `${configuration.apiBaseUrl}/call-reports/v1/reports/user-call-history/subscriptions`;

  const formUrlEncodedBody = new URLSearchParams();
  formUrlEncodedBody.append('channelId', channelId);
  formUrlEncodedBody.append('organizationId', organizationId);

  await axios.post(url, formUrlEncodedBody, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
