import { Call } from '@jive/realtime-events';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { updateActiveCall } from '../calls/callEvents.action';
import {
  callSelector,
  getContactDisplayFromCall,
  isContactMatchingLoadingForCallSelector,
} from '../calls/calls.selector';
import { AppState } from '../reducers';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import {
  CallInformationSnackBarMessageDispatchProps,
  CallInformationSnackBarMessageOwnProps,
  CallInformationSnackBarMessageStateProps,
  DepracatedScreenPopComponent,
} from './DeprecatedScreenPop.component';

const mapState = (state: AppState, { callId }): CallInformationSnackBarMessageStateProps => ({
  call: callSelector(state, callId) as Call,
  isContactMatchingInProgress: isContactMatchingLoadingForCallSelector(state, callId),
  contactDisplayFromCall: getContactDisplayFromCall(state, callId),
  integrationName: currentIntegrationNameSelector(state),
});

const mapDispatch = (dispatch: Dispatch<AppAction>, { onClose }): CallInformationSnackBarMessageDispatchProps => ({
  updateActiveCall: (call: Call) => dispatch(updateActiveCall({ call })),
  onClose,
});

export const DeprecatedScreenPop = connect<
  CallInformationSnackBarMessageStateProps,
  CallInformationSnackBarMessageDispatchProps,
  CallInformationSnackBarMessageOwnProps,
  AppState
>(
  mapState,
  mapDispatch,
)(DepracatedScreenPopComponent);
