import { makeActionCreator } from '../actions/actionCreators';
import { PhoneMode } from './phone.model';
import { Entity } from '../models/index';

export enum PhoneActionsType {
  SET_MODE = 'phone/setMode',
  SET_SELECTED_ENTITY = 'phone/setSelectedEntity',
  SET_SELECTED_PHONE_NUMBER = 'phone/setSelectedPhoneNumber',
  CALL = 'phone/call',
  CLEAR = 'phone/clear',
  RESET_CALLED_CONTACT = 'phone/resetCalledContact',
}

export interface CallPayload {
  phoneNumber: string;
  contact?: Entity; // this is optional, since a call can be started from the dialer too
}

export interface SetSelectedEntityPayload {
  entityId: string | undefined;
  isAutoSingleMatch?: boolean;
}

export const setPhoneMode = makeActionCreator(PhoneActionsType.SET_MODE)<PhoneMode>();
export const setPhoneSelectedEntity = makeActionCreator(
  PhoneActionsType.SET_SELECTED_ENTITY,
)<SetSelectedEntityPayload>();
export const setPhoneSelectedNumber = makeActionCreator(PhoneActionsType.SET_SELECTED_PHONE_NUMBER)<string>();
export const call = makeActionCreator(PhoneActionsType.CALL)<CallPayload>();
export const resetCalledContact = makeActionCreator(PhoneActionsType.RESET_CALLED_CONTACT)();
export const phoneClear = makeActionCreator(PhoneActionsType.CLEAR)();

export type PhoneActions =
  | ReturnType<typeof setPhoneMode>
  | ReturnType<typeof setPhoneSelectedEntity>
  | ReturnType<typeof setPhoneSelectedNumber>
  | ReturnType<typeof call>
  | ReturnType<typeof resetCalledContact>
  | ReturnType<typeof phoneClear>;
