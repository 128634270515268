import {
  CallEventsActionsType,
  endConversation,
  startIncomingConversation,
  startOutgoingConversation,
} from '@jive/realtime-events';
import { Middleware } from 'redux';
import { associateContact, ContactActionsType, contactFound } from '../actions/contacts';
import { PostCallActionsType, saveCallHistory } from '../calls/callEvents.action';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { createContactSuccess, EntitiesActionsType } from '../search/entities.action';
import { syncService } from '../sync/sync.service';
import { callHistoryMetadataService } from './callHistoryMetadata.service';
import { callHistoryElementByIdSelector, callHistoryMetadataItemSelector } from './unifiedCallHistory.selector';
import { callSelector } from '../calls/calls.selector';

const actionTypesToListenFor = [
  PostCallActionsType.SAVE_CALL_HISTORY,
  ContactActionsType.CONTACT_ASSOCIATE,
  ContactActionsType.CONTACT_FOUND,
  EntitiesActionsType.CREATE_CONTACT_SUCCESS,
  CallEventsActionsType.START_INCOMING_CONVERSATION,
  CallEventsActionsType.START_OUTGOING_CONVERSATION,
  CallEventsActionsType.END_CONVERSATION,
];

type ActionType =
  | ReturnType<typeof saveCallHistory>
  | ReturnType<typeof associateContact>
  | ReturnType<typeof contactFound>
  | ReturnType<typeof createContactSuccess>
  | ReturnType<typeof startIncomingConversation>
  | ReturnType<typeof startOutgoingConversation>
  | ReturnType<typeof endConversation>;

export const callHistoryMetadataPersistMiddleware: Middleware<{}, AppState> = (store) => (next) => async (
  action: ActionType,
) => {
  next(action);

  try {
    if (!actionTypesToListenFor.includes(action.type)) {
      return;
    }

    const state = store.getState();
    const callId = getCallIdFromAction(action);
    const updatedCall = callHistoryElementByIdSelector(callId)(state) ?? callSelector(state, callId);
    const matchingMetadata = callHistoryMetadataItemSelector(callId)(state);

    if (!updatedCall) {
      return;
    }
    await syncService.executeOnce(getSyncPrefix(action.type), callId, async () => {
      const integration = currentIntegrationSelector(state);
      await callHistoryMetadataService.updateCallHistoryMetadataItem(integration, updatedCall, matchingMetadata);
    });
  } catch (error) {
    logger.error('Failed to update call history metadata in stockpile', error);
  }
};

function getCallIdFromAction(action: ActionType) {
  switch (action.type) {
    case CallEventsActionsType.START_INCOMING_CONVERSATION:
    case CallEventsActionsType.START_OUTGOING_CONVERSATION:
    case CallEventsActionsType.END_CONVERSATION:
    case PostCallActionsType.SAVE_CALL_HISTORY:
      return action.payload.call.id;
    default:
      return action.payload.callId;
  }
}

function getSyncPrefix(actionType: string) {
  return `call_metadata_persist_sync_${actionType}`;
}
