import { CrmFormActionCreator } from '../../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import { OpenCrmFormModel } from '../../actionFacade/actionCreators/crmForm/crmForm.model';
import { EntityType } from '../../constants';

export class SalesforceLightningCrmFormActionCreator extends CrmFormActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }

  protected async _openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    const salesforceSupportedEntities = [EntityType.CONTACT, EntityType.CASE, EntityType.ACCOUNT, EntityType.LEAD];

    if (salesforceSupportedEntities.includes(model.entityType)) {
      return await this.salesforceLightningProxy.createNewEntity({
        entityName: model.entityType,
        defaultFieldValues: model.defaultFieldValues,
      });
    } else {
      throw Error(`Unexpected entity type received when trying to open entity form: ${model.entityType}`);
    }
  }
}
