import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Fragment } from 'react';

const useStyles = (size?: number) =>
  makeStyles({
    progress: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: size ? undefined : '65px',
    },
  });

interface LoadingSpinnerProps {
  children;
  isLoading: boolean;
  size?: number;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ children, isLoading, size }) => {
  const classes = useStyles(size)();

  return (
    <Fragment>
      {isLoading ? (
        <div data-cy="loading-spinner" className={classes.progress}>
          <CircularProgress size={size} />
        </div>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};

export default LoadingSpinner;
