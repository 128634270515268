import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { AmbassadorService } from '../ambassador/ambassador.service';
import { AmbassadorProxyAxiosInterceptor } from '../ambassador/proxy/proxy.interceptor';
import { proxyUserProviderSelector } from '../ambassador/proxy/proxy.selector';
import { tokenSelector } from '../authentication/authentication.selector';
import { logger } from '../logging';
import { IntegrationNames, Integrations } from '../models';
import { ClioApiUri } from './clio.service';
import { clioProxyProviderClientIdSelector, clioProxyUserProviderRegionSelector } from './proxy/proxy.selector';

interface ClioAxiosRequestConfig extends AxiosRequestConfig {
  accessTokenRefreshed: boolean;
}

interface ClioAxiosResponse extends AxiosResponse {
  config: ClioAxiosRequestConfig;
}

export class ClioAxiosInterceptor extends AmbassadorProxyAxiosInterceptor {
  protected integrationName = IntegrationNames[Integrations.Clio];
  protected providerHeaders = {
    'Provider-Authorization': 'Bearer --ambassador--access_token--ambassador--',
  };

  isUrlToIntercept(url: string) {
    const clioApiUris = Object.values(ClioApiUri);
    return clioApiUris.some((clioUri) => url && url.includes(clioUri));
  }

  protected async unauthorizedResponseHandler(response: ClioAxiosResponse): Promise<AxiosResponse> {
    if (response.config && response.config.accessTokenRefreshed) {
      logger.error(`Unauthorized error after successfully refreshing ${this.integrationName} token`, response);
      return await super.unauthorizedResponseHandler(response); // let's ask the user to relink their Clio account
    }

    const state = this.store.getState();

    const token = tokenSelector(state);
    const userProvider = proxyUserProviderSelector(state);
    const clioRegion = clioProxyUserProviderRegionSelector(state);

    if (!userProvider) {
      logger.error(`UserProvider undefined during Clio token refresh attempt`, response);
      return await super.unauthorizedResponseHandler(response); // let's ask the user to relink their Clio account
    }

    const refreshTokenModel = {
      client_id: clioProxyProviderClientIdSelector(state) as string,
      client_secret: `--ambassador--Client_Secret_${clioRegion}--ambassador--`,
      grant_type: 'refresh_token',
      refresh_token: '--ambassador--refresh_token--ambassador--',
    };

    try {
      await AmbassadorService.refreshProviderAccessToken(
        token,
        userProvider.id,
        userProvider.providerUuid,
        refreshTokenModel,
      );
      return axios.request({ ...response.config, accessTokenRefreshed: true } as ClioAxiosRequestConfig);
    } catch (e) {
      logger.error('Could not refresh access_token for Clio app', e);
      return await super.unauthorizedResponseHandler(response); // let's ask the user to relink their Clio account
    }
  }
}
