import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AddNotePayload } from '../notes/notes.models';
import { TextArea } from './TextArea.component';
import JiveForm from './JiveForm.component';
import { TextInputField } from './TextInputField.component';
import { CallActionFormProps } from '../calls/CallPageForm.model';
import { Typography } from '../theme/Typography.component';
import { Box } from '@material-ui/core';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';

const definedMessages = defineMessages({
  NEW_NOTE_FORM_TITLE: {
    id: 'Notes.NewForm.Title',
    defaultMessage: 'Create Note',
  },
  NEW_NOTE_FORM_SUBJECT: {
    id: 'Notes.NewForm.Subject',
    defaultMessage: 'Subject',
  },
  NEW_NOTE_FORM_CONTENT: {
    id: 'Notes.NewForm.Content',
    defaultMessage: 'Add note',
  },
  NEW_NOTE_FORM_SAVE_BUTTON: {
    id: 'Notes.NewForm.SaveButtonText',
    defaultMessage: 'Add Note',
  },
});

const trackingEvents = defineTrackingEvents({
  ADD_NOTE_CLICKED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.ItemClicked,
    label: 'Add Note | button',
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.ItemClicked,
    label: 'Cancel | button',
  },
});

export interface NewNoteFormStateProps {
  contactId?: string;
  contactName?: string;
  isActiveCall: boolean;
}

export interface NewNoteFormDispatchProps {
  addNote: (payload: AddNotePayload) => void;
}

export type NewNoteFormProps = CallActionFormProps & { shouldAutoSave?: boolean };

interface NewNoteFormState {
  noteSubject: string;
  content: string;
}

class NewNoteForm extends Component<
  NewNoteFormStateProps & NewNoteFormDispatchProps & NewNoteFormProps,
  NewNoteFormState
> {
  state = {
    noteSubject: '',
    content: '',
  };
  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  close = () => {
    this.setState({
      noteSubject: '',
      content: '',
    });
    this.props.onClose();
  };

  componentDidUpdate(prevProps: NewNoteFormStateProps & NewNoteFormDispatchProps & NewNoteFormProps) {
    if (this.hasCallEnded(prevProps)) {
      this.handleCallEnded();
    }
  }

  canSubmitNote() {
    return this.state.noteSubject.length > 0 && this.state.content.length > 0;
  }

  hasCallEnded(prevProps: NewNoteFormStateProps) {
    return prevProps.isActiveCall === true && this.props.isActiveCall === false;
  }

  handleCallEnded() {
    if (!this.props.shouldAutoSave || !this.canSubmitNote()) {
      return;
    }

    this.submitNote();
  }

  submitNote() {
    const { noteSubject, content } = this.state;
    const { addNote, contactId, isActiveCall, contactName } = this.props;

    addNote({
      contactId: contactId!,
      contactName,
      noteSubject,
      content,
      isActiveCall,
    });
  }

  render() {
    const { noteSubject, content } = this.state;
    const { contactId } = this.props;

    if (!contactId) {
      this.props.onClose();
      return null;
    }

    return (
      <JiveForm
        onCancelClick={() => this.close()}
        onSaveClick={() => {
          this.submitNote();
          this.close();
        }}
        canSubmit={this.canSubmitNote()}
        saveButtonDescriptor={definedMessages.NEW_NOTE_FORM_SAVE_BUTTON}
        saveTrackingEvent={trackingEvents.ADD_NOTE_CLICKED}
        cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
      >
        <Box mb={2}>
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.NEW_NOTE_FORM_TITLE} />
          </Typography>
        </Box>
        <TextInputField
          name="note-subject"
          value={noteSubject}
          labelDescription={definedMessages.NEW_NOTE_FORM_SUBJECT}
          onChange={this.handleChange('noteSubject')}
        />
        <TextArea
          name="note-content"
          id="textfield-note-content"
          required={true}
          value={content}
          rows={4}
          labelDescription={definedMessages.NEW_NOTE_FORM_CONTENT}
          onChange={this.handleChange('content')}
        />
      </JiveForm>
    );
  }
}
export default NewNoteForm;
