import { createSelector } from 'reselect';
import { callHistoryArraySelector } from '../callHistoryPush/unifiedCallHistory.selector';

export const contactCallHistorySelector = (contactId: string | undefined) =>
  createSelector(callHistoryArraySelector, (history) =>
    contactId === undefined
      ? []
      : history.filter(
          (historyElement) =>
            historyElement.entitySelected === contactId ||
            (historyElement.allMatches?.length === 1 && historyElement.allMatches[0].id === contactId),
        ),
  );

export const contactCallHistoryCountSelector = (contactId: string | undefined) =>
  createSelector(contactCallHistorySelector(contactId), (history) => history.length);
