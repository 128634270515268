import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { ChevronDownIcon } from '@getgo/chameleon-icons/react';
import Box from '@material-ui/core/Box';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { CallContextPanelProps } from './CallContextPanel.models';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Typography } from '../theme/Typography.component';
import { FormattedMessageWithMessageValues } from '../reactInltModels';
import { ExtendedTheme } from '@getgo/chameleon-material-ui';
import { useWideIntegrationDetector } from '../theme/wideIntegrationDetector';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { Link } from '@getgo/chameleon-web/react';

export type CallContactPanelId = 'Notes' | 'Opportunities' | 'Cases' | 'Vehicles' | 'Call history';

export interface CallContextPanel {
  id: CallContactPanelId;
  trackingCategory: AnalyticsCategory;
  icon: React.ReactNode;
  title: FormattedMessageWithMessageValues;
  body: React.ReactElement<CallContextPanelProps>;
  itemCount?: number;
  isOpenByDefault: boolean;
}

export interface CallContextPanelsProps {
  callId: string;
  panels: CallContextPanel[];
}

const definedMessages = defineMessages({
  EXPAND_ALL: {
    id: 'ExpandAll.Message',
    defaultMessage: 'Expand All',
  },
  COLLAPSE_ALL: {
    id: 'CollapseAll.Message',
    defaultMessage: 'Collapse All',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    root: {
      width: '100%',
    },
    icon: {
      display: 'flex',
      marginRight: theme.spacing(2),
    },
    expandCollapseSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    detailsRoot: {
      padding: 0,
      marginBottom: theme.spacing(3),
    },
    accordionTitle: {
      margin: 0,
      fontSize: (props: { isNarrowIntegration: boolean }) => (props.isNarrowIntegration ? '0.8rem' : '0.875rem'),
    },
    smallLink: {
      '--goto-button-padding': 0,
      '&::part(control)': {
        height: 'auto',
      },
    },
  }),
);

const useAccordionSummaryStyles = makeStyles((theme: ExtendedTheme) => ({
  root: (params: { isNarrowIntegration: boolean; isWideIntegration: boolean }) => ({
    marginTop: 0,
    minHeight: 0,
    margin: params.isNarrowIntegration ? '0 -5px' : params.isWideIntegration ? '0 -24px' : '0 -12px',
    padding: params.isNarrowIntegration ? '0 10px' : params.isWideIntegration ? '0 20px' : '0 12px 0 18px',
    '&.Mui-expanded': {
      minHeight: 0,
      marginTop: 0,
      borderBottom: `1px solid ${tokens.border}`,
    },
    borderTop: `1px solid ${tokens.border}`,
  }),
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    marginRight: 0,
    padding: theme.spacing(2),
  },
  expanded: {},
}));

const useAccordionStyles = makeStyles(() => ({
  root: (params: { isNarrowIntegration: boolean; isWideIntegration: boolean }) => ({
    margin: 0,
    boxShadow: 'none',
    '&:before': {
      background: 'none',
    },
    '&.Mui-expanded': {
      // this was the only way to make margin work. &$expanded and others got overriden
      margin: '0 !important',
    },
    '&:last-of-type:not(.Mui-expanded) > .cham-MuiAccordionSummary-root': {
      borderRadius: 0,
      borderBottom: `1px solid ${tokens.border}`,
      margin: params.isNarrowIntegration ? '0 -5px' : params.isWideIntegration ? '0 -24px' : '0 -12px',
      padding: params.isNarrowIntegration ? '0 10px' : params.isWideIntegration ? '0 20px' : '0 12px 0 18px',
    },
  }),
}));

const trackingEvents = defineTrackingEvents({
  EXPAND_ALL_CLICKED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: 'Expand all call page accordion elements | button',
  },
  COLLAPSE_ALL_CLICKED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: 'Collapse all call page accordion elements | button',
  },
});

export const CallContextPanels: React.FC<CallContextPanelsProps> = ({ callId, panels }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const isWideIntegration = useWideIntegrationDetector();
  const classes = useStyles({ isNarrowIntegration });
  const accordionSummaryStyles = useAccordionSummaryStyles({ isNarrowIntegration, isWideIntegration });
  const accordionStyles = useAccordionStyles({ isNarrowIntegration, isWideIntegration });

  const [expanded, setExpanded] = React.useState<CallContactPanelId[]>(
    panels.filter((p) => p.isOpenByDefault).map((p) => p.id),
  );

  useEffect(() => {
    const defaultExpanded = panels.filter((p) => p.isOpenByDefault).map((p) => p.id);

    // no need to rely on array deep equalty here, just reset to defaults
    if (expanded !== defaultExpanded) {
      setExpanded(defaultExpanded);
    }

    // Expand state needs to be reset on callId change,
    // since a navigation can happen from a call history item to an active call with screenpop.
    // In this case the component would not be removed from the tree, and state would not be reset.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId, panels]);

  const handleChange = (panel: CallContactPanelId, trackingCategory: AnalyticsCategory) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? expanded.concat([panel]) : expanded.filter((p) => panel !== p));
    newTracker.trackAnalyticsEvent({
      category: trackingCategory,
      action: AnalyticsAction.ItemClicked,
      label: `${panel} section | accordion | ${isExpanded ? 'OPENED' : 'CLOSED'}`,
    });
  };

  const expandAll = () => {
    setExpanded(panels.map((p) => p.id));
    newTracker.trackAnalyticsEvent(trackingEvents.EXPAND_ALL_CLICKED);
  };
  const collapseAll = () => {
    setExpanded([]);
    newTracker.trackAnalyticsEvent(trackingEvents.COLLAPSE_ALL_CLICKED);
  };

  return (
    <div>
      {panels.length ? (
        <Box className={classes.expandCollapseSection} mb={2}>
          <Link onClick={expandAll} size="small" className={classes.smallLink}>
            <FormattedMessage {...definedMessages.EXPAND_ALL} />
          </Link>
          <Box component="span" mr={1} ml={1}>
            <Typography>/</Typography>
          </Box>
          <Link onClick={collapseAll} size="small" className={classes.smallLink}>
            <FormattedMessage {...definedMessages.COLLAPSE_ALL} />
          </Link>
        </Box>
      ) : null}
      {panels.map(({ id, icon, title, body, itemCount, trackingCategory }) => {
        const panelExpanded = expanded.includes(id);
        return (
          <Accordion
            key={id}
            expanded={panelExpanded}
            onChange={handleChange(id, trackingCategory)}
            classes={accordionStyles}
          >
            <AccordionSummary
              classes={accordionSummaryStyles}
              expandIcon={<ChevronDownIcon style={{ fontSize: '20px', color: tokens.icon04 }} />}
              aria-controls={`panel-${id}-content`}
              id={`panel-${id}-header`}
            >
              <Box display="flex" alignItems="center">
                {icon ? <span className={classes.icon}>{icon}</span> : null}
                <Typography>
                  <p className={classes.accordionTitle}>
                    <FormattedMessage {...title} />
                    &nbsp;
                    {itemCount != null ? `(${itemCount})` : null}
                  </p>
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.detailsRoot }}>{panelExpanded && body}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
