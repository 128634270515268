import { associateContact } from '../actions/contacts';
import { getActiveRoute } from '../browserHistory';
import { allCallSelector } from '../calls/calls.selector';
import { addListener } from '../middlewares/listener.middleware';
import { Entity } from '../models';
import { entitiesSelector } from './entities.selector';
import { PhoneActionsType } from '../phone/phone.actions';

addListener(PhoneActionsType.SET_SELECTED_ENTITY)((state, action) => {
  const activeRoute = getActiveRoute();
  const entityId: string | undefined = action.payload.entityId;
  const entities: Entity[] | undefined = entitiesSelector(state);
  if (activeRoute && activeRoute.match) {
    const { callId } = activeRoute.match.params as any;
    if (callId) {
      const call = allCallSelector(state, callId);
      if (call.id) {
        return associateContact({
          callId: call.id,
          entityId,
          entities,
          isAutoSingleMatch: !!action.payload.isAutoSingleMatch,
        });
      }
    }
  }
});
