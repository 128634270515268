import { Store } from 'redux';
import { AppState } from 'app/src/reducers';
import { isAuthenticatedSelector, jiveIdSelector } from '../authentication/authentication.selector';
import { logger } from '../logging/logger';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { linesService } from './lines.service';
import { loadLinesAction, loadLinesFailedAction, loadLinesSuccessAction } from './lines.actions';

const trackingEvents = defineTrackingEvents({
  LINES_LOAD_FAILED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.LineLoadingFailed,
    label: '-',
  },
});

export class LinesActionCreator {
  constructor(protected store: Store<AppState>) {}

  async loadLines(): Promise<void> {
    const state = this.store.getState();
    const isAuthenticated = isAuthenticatedSelector(state);
    const jiveId = jiveIdSelector(state);

    if (!isAuthenticated || !jiveId) {
      return;
    }

    this.store.dispatch(loadLinesAction());
    try {
      const { lines, organizations } = await linesService.loadNormalizedLines(jiveId);

      if (lines && Object.keys(lines).length) {
        this.store.dispatch(loadLinesSuccessAction({ lines, organizations }));
      } else {
        this.store.dispatch(loadLinesFailedAction());
        newTracker.trackAnalyticsEvent({
          ...trackingEvents.LINES_LOAD_FAILED,
          label: 'user has no lines',
        });
        logger.error('No lines or organizations for user', { lines, organizations });
        return;
      }
    } catch (error) {
      this.store.dispatch(loadLinesFailedAction());

      newTracker.trackAnalyticsEvent({
        ...trackingEvents.LINES_LOAD_FAILED,
        label: 'API error',
      });

      logger.error('Lines loading error', error);
    }
  }
}
