import Qs from 'qs';
import React, { useEffect } from 'react';
import { FullPageAppLoading } from '../components/FullPageAppLoading';
import { RedirectKeepSearch } from '../components/RedirectKeepSearch.component';
import { ApplicationRoute } from '../constants';
import { extractStateObject } from './loginHelper';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { LoginData } from './authentication.models';
import { AuthenticationEventMessages } from './authentication.events';
import { authenticationEventListener } from './authentication.eventListener';

interface LoginQueryParams {
  state: string;
  access_token: string;
  expires_in: string;
}

const LoginSuccessComponent: React.FC = () => {
  function pageIsInAPopupOrIframe() {
    return !!window.opener || !!window.frameElement;
  }

  function close() {
    // setTimeout is required to prevent Chrome deleting the iframe or the
    // window before its message has been fully processed by the parent window,
    // because that causes the parent window to freeze.
    setTimeout(() => {
      if (window.frameElement && window.frameElement.parentNode) {
        window.frameElement.parentNode.removeChild(window.frameElement);
      } else {
        window.close();
      }
    });
  }

  let hash = window.location.hash;
  if (hash.indexOf('#') === 0) {
    hash = hash.slice(1);
  }

  let search = window.location.search;
  if (search.indexOf('?') === 0) {
    search = search.slice(1);
  }

  const { state, access_token, expires_in } = Qs.parse(hash || search) as unknown as LoginQueryParams;
  const { pathToRedirect, isTokenRefresh } = extractStateObject<LoginData>(state);

  /* 
    This component handles the redirect from the LMI oauth service.
    Depending on the integration, receiving the tokens can continue different ways:
    - use the same window to redirect and process the token (for clio, redtail, connectwise)
    - post message back to opener window (zendesk, salesforce)
    - post message to the iframe's parent, since token refresh happens in an iframe
  */
  const postMessageOrCallFacade = async (messageToPost: any, facadeCall: () => Promise<void>) => {
    if (window.opener) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.opener.postMessage(messageToPost, '*');
    } else if (window.frameElement) {
      window.parent.postMessage(messageToPost, '*');
    } else {
      await facadeCall();
    }
  };

  const completeAuthentication = async (loginData: LoginData) => {
    await postMessageOrCallFacade({ type: AuthenticationEventMessages.LOGIN_PROCESSING, payload: loginData }, () =>
      getActionFacade().completeAuthentication(loginData),
    );
  };

  const handleTokenRefreshError = async () => {
    await postMessageOrCallFacade({ type: AuthenticationEventMessages.REFRESH_TOKEN_ERROR }, () =>
      getActionFacade().completeTokenRefresh(),
    );
  };

  const handleLoginError = async () => {
    await postMessageOrCallFacade(
      {
        type: AuthenticationEventMessages.LOGIN_ERROR,
        payload: { error: window.location.pathname + window.location.search },
      },
      () =>
        Promise.resolve(authenticationEventListener.fireLoginError(window.location.pathname + window.location.search)),
    );
  };

  useEffect(() => {
    void (async () => {
      if (access_token) {
        const loginData: LoginData = {
          token: access_token,
          expiration: Date.now() + Number(expires_in) * 1000,
          pathToRedirect,
          isTokenRefresh: isTokenRefresh ?? false,
        };

        await completeAuthentication(loginData);
      } else {
        if (isTokenRefresh) {
          await handleTokenRefreshError();
        } else {
          await handleLoginError();
        }
      }
      if (pageIsInAPopupOrIframe()) {
        close();
      }
    })();
  });

  return pageIsInAPopupOrIframe() || access_token ? (
    <FullPageAppLoading />
  ) : (
    <RedirectKeepSearch pathname={ApplicationRoute.ONBOARDING} />
  );
};

export default LoginSuccessComponent;
