import React, { FC } from 'react';
import { proxyLinkedSelector } from '../../ambassador/proxy/proxy.selector';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../authentication/authentication.selector';
import { UnlinkAccountButton } from './UnlinkAccountButton.component';
import { LinkAccountButton } from './LinkAccountButton.component';

export const LinkButton: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const linked = useSelector(proxyLinkedSelector);

  if (isAuthenticated && linked) {
    return <UnlinkAccountButton />;
  } else {
    return <LinkAccountButton />;
  }
};
