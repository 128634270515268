import { connect } from 'react-redux';
import {
  isDesktopIntegrationSelector,
  personalLinkingNeededForCurrentIntegrationSelector,
} from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { AccountSettingsComponent } from './AccountSettings.component';
import { SettingsAccountSectionStateProps } from './AccountSettings.component';
import { selectedLineSelector } from './settings.selector';
import { linesSelector } from '../lines/lines.selectors';

const mapStateToProps = (state): SettingsAccountSectionStateProps => ({
  lines: linesSelector(state),
  selectedLine: selectedLineSelector(state),
  isDesktopIntegration: isDesktopIntegrationSelector(state),
  linkingNeededForCurrentIntegration: personalLinkingNeededForCurrentIntegrationSelector(state),
});

export const AccountSettings = connect<SettingsAccountSectionStateProps, {}, {}, AppState>(mapStateToProps)(
  AccountSettingsComponent,
);
