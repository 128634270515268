import { combineReducers, Reducer } from 'redux';
import { ProxyActions, ProxyActionsTypes } from '../ambassador/proxy/proxy.action';
import { ClioActions, ClioActionsTypes } from './clio.action';
import { User } from './clio.models';
import { newCommunicationReducer } from './communication/communication.reducer';
import { savedCommunicationReducer } from './communication/saved-communication.reducer';
import { mattersReducer } from './matters/matter.reducer';
import { activitiesReducer } from './activities/activity.reducer';

export interface ClioAuthenticationState {
  self?: User;
}

export const CLIO_AUTHENTICATION_INITIAL_STATE = {
  self: undefined,
};

export const clioLoginReducer: Reducer<ClioAuthenticationState, ClioActions | ProxyActions> = (
  state = CLIO_AUTHENTICATION_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ProxyActionsTypes.PROXY_CHECK_LINK:
      return {
        ...CLIO_AUTHENTICATION_INITIAL_STATE,
      };
    case ClioActionsTypes.CLIO_WHO_AM_I_SUCCESS:
      return { ...state, self: action.payload };
    default:
      return state;
  }
};

export const clioReducer = combineReducers({
  login: clioLoginReducer,
  communicationPayload: newCommunicationReducer,
  savedCommunications: savedCommunicationReducer,
  matters: mattersReducer,
  activities: activitiesReducer,
});

export default clioReducer;
