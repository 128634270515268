import { MessageDescriptor } from 'react-intl';
import { Middleware } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { logger } from '../logging';
import { InAppNotificationMessage, MessageVariant, WithMessage } from '../models';
import { showMessage } from '../inAppNotification/message.action';
import { uuid } from '../uuid';

enum ActionResultType {
  None,
  Success,
  Error,
  Warning,
}

function getActionResultType(action: AppAction<string>): ActionResultType {
  const type = action.type.toLowerCase();

  if (type.includes(MessageVariant.Success)) {
    return ActionResultType.Success;
  }

  if (type.includes(MessageVariant.Error)) {
    return ActionResultType.Error;
  }

  if (type.includes(MessageVariant.Warning)) {
    return ActionResultType.Warning;
  }

  return ActionResultType.None;
}

function createInAppNotificationMessage(payload: InAppNotificationMessage): MessageDescriptor {
  if (payload.message!.id) {
    return payload.message!;
  }

  logger.error("No message id was provided, the text won't be localized", { message: payload.message });

  return {
    id: 'No.Id.Provided',
    defaultMessage: payload.message,
  } as MessageDescriptor;
}

function isTopLevelMessage(payload: WithMessage) {
  return payload.isTopLevel !== false;
}

function isActionWithMessage(action: AppAction<string, { message?: string }>) {
  return action && action.type && action.payload && action.payload.message;
}

function shouldInAppNotificationHandle(action): boolean {
  if (!isActionWithMessage(action)) {
    return false;
  }

  const actionResultType = getActionResultType(action);
  if (actionResultType === ActionResultType.None || !isTopLevelMessage(action.payload)) {
    return false;
  }

  return true;
}

export const inAppNotificationMiddleware: Middleware = (store) => (next) => (action) => {
  next(action);

  if (!shouldInAppNotificationHandle(action)) {
    return;
  }

  const actionResultType = getActionResultType(action);

  const type =
    actionResultType === ActionResultType.Success
      ? MessageVariant.Success
      : actionResultType === ActionResultType.Error
      ? MessageVariant.Error
      : MessageVariant.Warning;

  const message = createInAppNotificationMessage(action.payload);

  store.dispatch(
    showMessage({
      id: uuid(), // async function
      type,
      message,
      params: { autoHide: actionResultType !== ActionResultType.Warning },
    }),
  );
};
