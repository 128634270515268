import { combineReducers } from 'redux';
import { DevicesActionsType } from '../constants';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';

export interface DevicesState {
  isLoading: boolean;
  hasError: any;
}

export const INITIAL_STATE: DevicesState = {
  isLoading: false,
  hasError: false,
};

const devices = combineReducers({
  isLoading: loadingReducer(DevicesActionsType.LOAD),
  hasError: errorReducer(DevicesActionsType.LOAD),
});

export default devices;
