import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { tracker } from '../analytics/tracker';
import { openDialog } from '../dialog/dialog.actions';
import { embeddedBroadcastChannel } from '../embedded/embedded.broadcastChannel';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { StartupActionCreator } from '../startup/startup.actioncreator';
import {
  openSalesforceEditionsHelpPage,
  salesforceInitializationError,
  getTaskRelationsError,
  getTaskRelationsRequest,
  getTaskRelationsSuccess,
} from './salesforce.actions';
import { salesforceUserInformation } from './salesforceAnalytics.action';
import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';

const definedMessages = defineMessages({
  API_DISABLED_DIALOG_TITLE: {
    id: 'Salesforce.Dialog.ApiDisabled.Title',
    defaultMessage: 'Disabled API Access',
  },
  API_DISABLED_DIALOG_BODY: {
    id: 'Salesforce.Dialog.ApiDisabled.Body',
    defaultMessage:
      'Your Salesforce license does not support API access. Learn more about Salesforce editions or ask your admin for help.',
  },
  API_CURRENTLY_DISABLED_DIALOG_BODY: {
    id: 'Salesforce.Dialog.ApiCurrentlyDisabled.Body',
    defaultMessage:
      'API Access is disabled in your user profile. In order to be able to use GoToConnect Embedded Integration, please change your settings or ask your admin for help.',
  },
  API_DISABLED_DIALOG_CONFIRM: {
    id: 'Salesforce.Dialog.ApiDisabled.Confirm',
    defaultMessage: 'More about Salesforce editions',
  },
});

const trackingEvents = defineTrackingEvents({
  API_DISABLED_BY_LICENSE: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.CrmApiFail,
    label: 'Limited by license',
  },
  API_DISABLED_BY_ADMIN: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.CrmApiFail,
    label: 'Limited by admin',
  },
});

export class SalesforceStartupActionCreator extends StartupActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }

  public async startMainApplication(): Promise<void> {
    try {
      await this.salesforceLightningProxy.init();

      // integration panel synchronization for Salesforce
      embeddedBroadcastChannel.initialize(this.store);
    } catch (e) {
      if (e.faultcode === 'sf:API_DISABLED_FOR_ORG') {
        tracker.trackEvent('Limited by License', 'SFAPIDisabledError');
        newTracker.trackAnalyticsEvent(trackingEvents.API_DISABLED_BY_LICENSE);

        this.store.dispatch(
          openDialog({
            cancellable: false,
            confirmAction: openSalesforceEditionsHelpPage(),
            texts: {
              title: definedMessages.API_DISABLED_DIALOG_TITLE,
              body: definedMessages.API_DISABLED_DIALOG_BODY,
              confirm: definedMessages.API_DISABLED_DIALOG_CONFIRM,
            },
          }),
        );
      } else if (e.faultcode === 'sf:API_CURRENTLY_DISABLED') {
        tracker.trackEvent('Limited by Admin', 'SFAPIDisabledError');
        newTracker.trackAnalyticsEvent(trackingEvents.API_DISABLED_BY_ADMIN);

        this.store.dispatch(
          openDialog({
            cancellable: false,
            texts: {
              title: definedMessages.API_DISABLED_DIALOG_TITLE,
              body: definedMessages.API_CURRENTLY_DISABLED_DIALOG_BODY,
            },
          }),
        );
      } else {
        logger.error('Unknown error initializing Salesforce service', e);

        this.store.dispatch(salesforceInitializationError({ error: e.message }));
      }

      return;
    }

    this.store.dispatch(salesforceUserInformation());
    await this.loadTaskRelations();
    return super.startMainApplication();
  }

  private async loadTaskRelations(): Promise<void> {
    this.store.dispatch(getTaskRelationsRequest());
    try {
      const relations = await this.salesforceLightningProxy.getTaskRelations();
      this.store.dispatch(getTaskRelationsSuccess(relations));
    } catch (e) {
      logger.error('Could not get Salesforce task relations', e);
      this.store.dispatch(getTaskRelationsError());
    }
  }
}
