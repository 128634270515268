import { ISyncService } from './sync.service.model';

export class WebLocksSyncService implements ISyncService {
  canCurrentTabOperate(operationPrefix: string, id: string, holdLockForMilliseconds): Promise<boolean> {
    let resolveCleanupPromise: (value?: unknown) => void;
    const cleanupPromise = new Promise((res) => {
      resolveCleanupPromise = res;
    });

    return new Promise<boolean>((resolve) => {
      void navigator.locks.request(
        `${operationPrefix}-${id}`,
        {
          mode: 'exclusive',
          ifAvailable: true, // do not wait for the lock to become available (lock will be null in the callback)
        },
        async (lock) => {
          if (!lock) {
            resolve(false); // current tab can operate if the lock object defined here
            return;
          }

          setTimeout(() => resolveCleanupPromise(), holdLockForMilliseconds);
          resolve(true);
          return cleanupPromise;
        },
      );
    });
  }

  cleanUpSyncData(): Promise<void> {
    // there is nothing to clean up
    return Promise.resolve(undefined);
  }

  async isAvailable(): Promise<boolean> {
    return !!navigator.locks;
  }
}
