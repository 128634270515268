import { Store } from 'redux';
import { AppState } from '../reducers';
import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';
import { logger } from '../logging';
import { logoutSuccess } from '../authentication/authentication.actions';
import { authenticationEventListener } from '../authentication/authentication.eventListener';
import { SalesforceAuthActionCreator } from './salesforce.auth.actionCreator';

export class UnifiedSalesforceAuthActionCreator extends SalesforceAuthActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store, salesforceLightningProxy);
  }

  async login(): Promise<void> {
    // ancestorOrigins is not supported in FF, so we need to fallback to referrer.
    let baseUrl = document.referrer;
    if (window.location.ancestorOrigins) {
      baseUrl = Array.from(window.location.ancestorOrigins).pop() || baseUrl;
    }
    await this.salesforceLightningProxy.init();

    const namespace = await this.salesforceLightningProxy.getNamespace();
    const loginPath = namespace ? `/apex/${namespace}__GTM_Login?isAutoLogin=true` : '/apex/GTM_Login?isAutoLogin=true';
    const loginPageUrl = new URL(loginPath, baseUrl).toString();
    const loginWindow = window.open(loginPageUrl, '_blank');

    return new Promise(async (resolve) => {
      const waitForPopupClosed = async () => {
        if (loginWindow?.closed) {
          await this.salesforceLightningProxy.refreshView();
          await this.loadAuthToken();
          resolve();
        } else {
          setTimeout(() => waitForPopupClosed(), 500);
        }
      };

      await waitForPopupClosed();
    });
  }

  async logout(): Promise<void> {
    try {
      await this.salesforceLightningProxy.logout();
      this.store.dispatch(logoutSuccess());
      await this.salesforceLightningProxy.refreshView();
      await this.updateSoftphonePanelStatus();
    } catch (error) {
      logger.error('Failed to logout', error);
    }
  }

  async startAuthentication(): Promise<void> {
    try {
      await this.loadAuthToken();
      await this.updateSoftphonePanelStatus();
      return super.startAuthentication();
    } catch (error) {
      logger.error('Failed to authenticate in Salesforce', error);
    }
  }

  async loadAuthToken(): Promise<void> {
    try {
      await this.salesforceLightningProxy.init();
      const authToken = await this.salesforceLightningProxy.getAuthToken();
      if (authToken) {
        await this.completeAuthentication({
          token: authToken,
          expiration: Date.now() + 604800000, // 7 days in ms,
          isTokenRefresh: false,
        });
      } else {
        this.store.dispatch(logoutSuccess());
      }
    } catch (error) {
      logger.error('Failed to load auth token from Salesforce', error);
    }
  }

  protected async onOnboardingScreen(): Promise<void> {
    try {
      await this.salesforceLightningProxy.init();
      await this.updateSoftphonePanelStatus();
    } catch (error) {
      logger.warn('Salesforce initialization failed', error);
    } finally {
      await super.onOnboardingScreen();
    }
  }

  protected async startTokenRefresh(): Promise<void> {
    try {
      await this.salesforceLightningProxy.init();
      await this.updateSoftphonePanelStatus();
      const authToken = await this.salesforceLightningProxy.getAuthToken();
      if (authToken) {
        await this.completeAuthentication({
          token: authToken,
          expiration: Date.now() + 604800000, // 7 days in ms,
          isTokenRefresh: true,
        });
      } else {
        authenticationEventListener.fireTokenRefreshError();
        this.store.dispatch(logoutSuccess());
      }
      await this.completeTokenRefresh();
    } catch (error) {
      logger.error('Failed to refresh token in Salesforce', error);
    }
  }
}
