export enum EntityPhoneNumberType {
  MOBILE = 'mobile',
  BUSINESS = 'business',
  HOME = 'home',
  FAX = 'fax',
  PAGER = 'pager',
  SKYPE = 'skype',
  OTHER = 'other',
  EXTENSION = 'extension',
  ASSISTANT = 'assistant',
  DIRECT = 'direct',
}

export interface EntityPhoneNumber {
  entityId: string;
  type: EntityPhoneNumberType;
  phoneNumber: string;
}

export const phoneNumberTypeMap: { [key: string]: EntityPhoneNumberType } = {
  Work: EntityPhoneNumberType.BUSINESS,
  Mobile: EntityPhoneNumberType.MOBILE,
  Home: EntityPhoneNumberType.HOME,
  Fax: EntityPhoneNumberType.FAX,
  Pager: EntityPhoneNumberType.PAGER,
  Skype: EntityPhoneNumberType.SKYPE,
  Other: EntityPhoneNumberType.OTHER,
  Extension: EntityPhoneNumberType.EXTENSION,
  Assistant: EntityPhoneNumberType.ASSISTANT,
  Direct: EntityPhoneNumberType.DIRECT,
};

export const phoneNumberTypeMapper = (phoneNumberType: string): EntityPhoneNumberType => {
  return phoneNumberTypeMap[phoneNumberType] || EntityPhoneNumberType.BUSINESS;
};
