import { Store } from 'redux';
import { AuthenticationActionCreator } from '../authentication/authentication.actioncreator';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';

import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { defineMessages } from '@formatjs/intl';
import { injectedIntlSelector } from '../settings/settings.selector';

export const definedMessages = defineMessages({
  SOFTPHONE_LABEL_NOT_LOGGED_IN: {
    id: 'Salesforce.Presence.Login.Text',
    defaultMessage: 'GoTo - Please log in',
  },
  SOFTPHONE_LABEL_LOGGED_IN: {
    id: 'Salesforce.Presence.Online.Text',
    defaultMessage: 'GoTo',
  },
});

export abstract class SalesforceAuthActionCreator extends AuthenticationActionCreator {
  constructor(store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }

  protected async updateSoftphonePanelStatus() {
    const isAuthenticated = isAuthenticatedSelector(this.store.getState());
    const intl = injectedIntlSelector(this.store.getState());
    const updatedLabel = isAuthenticated
      ? intl.formatMessage(definedMessages.SOFTPHONE_LABEL_LOGGED_IN)
      : intl.formatMessage(definedMessages.SOFTPHONE_LABEL_NOT_LOGGED_IN);
    const updatedIcon = isAuthenticated ? 'call' : 'end_call';
    try {
      await this.salesforceLightningProxy.setSoftphonePanelLabel(updatedLabel);
      await this.salesforceLightningProxy.setSoftphonePanelIcon(updatedIcon);
    } catch (error) {
      logger.warn('Unable to update softphone panel status text', error);
    }
  }
}
