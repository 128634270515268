import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { activityCategoriesSelector, loadActivityCategoriesRequestStateSelector } from './activity.selectors';
import {
  ActivityCategoryPickerComponent,
  ActivityCategoryPickerProps,
  ActivityCategoryPickerStateProps,
} from './ActivityCategoryPicker.component';

const mapStateToProps = (
  state: AppState,
  ownProps: ActivityCategoryPickerProps,
): ActivityCategoryPickerStateProps & ActivityCategoryPickerProps => ({
  ...ownProps,
  activities: activityCategoriesSelector(state),
  requestState: loadActivityCategoriesRequestStateSelector(state),
});

export const ActivityCategoryPicker = connect<
  ActivityCategoryPickerStateProps,
  {},
  ActivityCategoryPickerProps,
  AppState
>(mapStateToProps)(ActivityCategoryPickerComponent);
