import { makeActionCreator } from '../../actions/actionCreators';
import { ActivityCategory } from '../clio.models';

export enum ActivityActionsType {
  LOAD_ACTIVITY_CATEGORIES = 'clio/activityCategories/load',
  LOAD_ACTIVITY_CATEGORIES_SUCCESS = 'clio/activityCategories/load/success',
  LOAD_ACTIVITY_CATEGORIES_ERROR = 'clio/activityCategories/load/error',
}

export interface LoadActivityCategoriesSuccessPayload {
  activityCategories: ActivityCategory[];
}
export interface LoadActivityCategoriesErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export const loadActivityCategories = makeActionCreator(ActivityActionsType.LOAD_ACTIVITY_CATEGORIES)();
export const loadActivityCategoriesSuccess = makeActionCreator(ActivityActionsType.LOAD_ACTIVITY_CATEGORIES_SUCCESS)<
  LoadActivityCategoriesSuccessPayload
>();
export const loadActivityCategoriesError = makeActionCreator(ActivityActionsType.LOAD_ACTIVITY_CATEGORIES_ERROR)<
  LoadActivityCategoriesErrorPayload
>();

export type ActivityActions =
  | ReturnType<typeof loadActivityCategories>
  | ReturnType<typeof loadActivityCategoriesSuccess>
  | ReturnType<typeof loadActivityCategoriesError>;
