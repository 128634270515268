import { Call } from '@jive/realtime-events';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CallDetails } from '../../calls/CallDetails.component';
import JiveForm from '../../entityForms/JiveForm.component';
import { NewNoteFormProps, NewNoteFormStateProps } from '../../entityForms/NewNoteForm.component';
import { NoteTypePicker } from './notetype/ConnectWiseNoteTypePicker.container';
import { AnalyticsCategory, AnalyticsAction, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { TextArea } from '../../entityForms/TextArea.component';
import { LabeledCheckbox } from '../../components/LabeledCheckbox.component';
import { newTracker } from '../../analytics-new/tracker-new';
import { AddConnectWiseNotePayload } from '../connectwise.model';
import { Typography } from '../../theme/Typography.component';
import Box from '@material-ui/core/Box';

const trackingEvents = defineTrackingEvents({
  NOTE_FORM_LOADED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.PageLoaded,
    label: 'Create note',
  },
  NOTE_CONTENT_CHANGE: {
    category: AnalyticsCategory.Note,
    label: 'Note content changed | textarea',
    action: AnalyticsAction.ItemChanged,
  },
  NOTE_TYPE_CHANGE: {
    category: AnalyticsCategory.Note,
    label: 'Type for the note | selected',
    action: AnalyticsAction.ItemChanged,
  },
  CALL_DETAILS_CHECKED: {
    category: AnalyticsCategory.Note,
    label: 'Add call details to the note | checked',
    action: AnalyticsAction.ItemChanged,
  },
  CALL_DETAILS_UNCHECKED: {
    category: AnalyticsCategory.Note,
    label: 'Add call details to the note | unchecked',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_NOTE_CLICKED: {
    category: AnalyticsCategory.Note,
    label: 'Add note | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Note,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
});

const definedMessages = defineMessages({
  NEW_NOTE_FORM_TITLE: {
    id: 'Notes.NewForm.Title',
    defaultMessage: 'Create Note',
  },
  NEW_NOTE_FORM_CONTENT: {
    id: 'Notes.NewForm.Content',
    defaultMessage: 'Add note',
  },
  NEW_NOTE_FORM_SAVE_BUTTON: {
    id: 'Notes.NewForm.SaveButtonText',
    defaultMessage: 'Add Note',
  },
  NEW_NOTE_FORM_ADD_CALL_DETAILS_CHECKBOX_LABEL: {
    id: 'ConnectWise.NewNote.AddCallDetails.Checkbox.Label',
    defaultMessage: 'Add call details to the note',
  },
});

export interface NewConnectWiseFormState {
  note: string;
  noteTypeId: number;
  includeCallDetails: boolean;
}

export interface NewConnectWiseNoteFormStateProps extends NewNoteFormStateProps {
  call: Call;
  isActiveCall: boolean;
}

export interface NewConnectWiseNoteFormDispatchProps {
  addConnectWiseNote: (payload: AddConnectWiseNotePayload) => void;
}

const DEFAULT_NOTETYPE_ID = -1;

export default class NewConnectWiseNoteForm extends Component<
  NewConnectWiseNoteFormStateProps & NewConnectWiseNoteFormDispatchProps & NewNoteFormProps,
  NewConnectWiseFormState
> {
  noteInputRef = React.createRef<HTMLInputElement>();
  state = {
    note: '',
    noteTypeId: DEFAULT_NOTETYPE_ID,
    includeCallDetails: true,
  };

  componentDidMount() {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.NOTE_FORM_LOADED);
  }

  close = () => {
    this.setState({
      note: '',
      noteTypeId: DEFAULT_NOTETYPE_ID,
      includeCallDetails: true,
    });
    this.props.onClose();
  };

  onNoteTypeSelected = (noteTypeId) => {
    this.setState({
      ...this.state,
      noteTypeId,
    });

    if (this.noteInputRef.current) {
      this.noteInputRef.current.focus();
    }
  };

  render() {
    const { noteTypeId, note, includeCallDetails } = this.state;
    const { addConnectWiseNote, contactId, call, isActiveCall } = this.props;
    const canSubmitValidation = noteTypeId !== DEFAULT_NOTETYPE_ID && (note.trim().length > 0 || includeCallDetails);

    if (!contactId) {
      this.props.onClose();
      return null;
    }

    return (
      <JiveForm
        onCancelClick={() => this.close()}
        onSaveClick={() => {
          addConnectWiseNote({
            contactId,
            content: note,
            noteSubject: '',
            noteTypeId,
            includeCallDetails,
            call,
            isActiveCall,
          });
          this.props.onClose();
        }}
        canSubmit={canSubmitValidation}
        saveButtonDescriptor={definedMessages.NEW_NOTE_FORM_SAVE_BUTTON}
        cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
        saveTrackingEvent={trackingEvents.ADD_NOTE_CLICKED}
      >
        <Box mb={4}>
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.NEW_NOTE_FORM_TITLE} />
          </Typography>
        </Box>
        <Box mb={2}>
          <NoteTypePicker
            noteTypeId={noteTypeId}
            trackingEvent={trackingEvents.NOTE_TYPE_CHANGE}
            onNoteTypeSelected={(noteTypeId) => this.onNoteTypeSelected(noteTypeId)}
          />
        </Box>
        <TextArea
          name="note-content"
          trackingEvent={trackingEvents.NOTE_CONTENT_CHANGE}
          inputRef={this.noteInputRef}
          autoFocus={true}
          required={!includeCallDetails}
          value={note}
          labelDescription={definedMessages.NEW_NOTE_FORM_CONTENT}
          onChange={({ target: { value } }) => this.setState({ ...this.state, note: value ?? '' })}
        />
        <Box mb={4}>
          <LabeledCheckbox
            onChange={({ target: { checked } }) => this.setState({ ...this.state, includeCallDetails: checked })}
            onTrackingEvent={trackingEvents.CALL_DETAILS_CHECKED}
            offTrackingEvent={trackingEvents.CALL_DETAILS_UNCHECKED}
            labelPlacement="end"
            label={<FormattedMessage {...definedMessages.NEW_NOTE_FORM_ADD_CALL_DETAILS_CHECKBOX_LABEL} />}
            checked={includeCallDetails}
            name="includeCallDetails"
          />
        </Box>
        {includeCallDetails ? <CallDetails call={call} /> : null}
      </JiveForm>
    );
  }
}
