import axios from 'axios';
import { ContactCreateModel } from '../actionFacade/actionCreators/contact/contact.actioncreator.models';
import {
  REDTAIL_API_PATH,
  RedtailActivity,
  RedtailActivityAddModel,
  RedtailCategory,
  RedtailContact,
  RedtailEntity,
  CreateRedtailNote,
  RedtailPhone,
  RedtailPhoneNumberTypeID,
  RedtailPhoneNumberUpdate,
  RedtailNote,
} from './redtail.model';
import { getLabelFromContact } from './redtail.utils';
import { isExtension } from './../phone/phone-utils';

// Available methods: https://help.redtailtechnology.com/hc/en-us/articles/203964590-List-of-Available-Methods
export const RedtailApiUri = {
  CONTACTS: `${REDTAIL_API_PATH}/contacts/search`,
  CREATE_CONTACT: `${REDTAIL_API_PATH}/contacts/0`,
  PHONES: (contactId: string) => `${REDTAIL_API_PATH}/contacts/${contactId}/phones`,
  UPDATE_PHONE: (contactId: string) => `${REDTAIL_API_PATH}/contacts/${contactId}/phones/0`,
  CATEGORIES: `${REDTAIL_API_PATH}/settings/mcl`,
  NEW_NOTE: (contactId: string) => `${REDTAIL_API_PATH}/contacts/${contactId}/notes/0`,
  NOTES: (contactId: string) => `${REDTAIL_API_PATH}/contacts/${contactId}/notes?page=1`,
  NEW_ACTIVITY: `${REDTAIL_API_PATH}/calendar/activities/0`,
};

// contact search documentation:
// https://help.redtailtechnology.com/hc/en-us/articles/203964640-Contacts-Search-contacts-search-POST-method
const REDTAIL_SEARCH_FIELDS = {
  PHONE_NUMBER: '3',
  FULL_NAME: '1',
  FULL_TEXT: '17',
};

const REDTAIL_SEARCH_OPERATOR = {
  EQUALS: '0',
  LIKE: '4',
};

const extractContactsFromResponse = (contacts: RedtailContact[]): RedtailEntity[] => {
  if (!contacts) {
    return [];
  }

  return contacts.map(
    (contact) =>
      ({
        id: contact.ClientID,
        label: getLabelFromContact(contact),
        name: getLabelFromContact(contact),
        type: contact.Type,
        phoneNumbers: [],
      } as RedtailEntity),
  );
};

const searchContactsByName = async (query: string): Promise<RedtailEntity[]> => {
  const response = await axios.post<{ Contacts: RedtailContact[] }>(`${RedtailApiUri.CONTACTS}?page=1`, [
    {
      Field: REDTAIL_SEARCH_FIELDS.FULL_NAME,
      Operand: REDTAIL_SEARCH_OPERATOR.LIKE,
      Value: query,
    },
  ]);

  return extractContactsFromResponse(response.data.Contacts);
};

const searchContactsByNumber = async (phoneNumber: string): Promise<RedtailEntity[]> => {
  // When calling an extension we use the EQUALS operator, because the LIKE operator
  // would return contacts with full phone number partial matching the extension's number.
  const operand: string = isExtension(phoneNumber) ? REDTAIL_SEARCH_OPERATOR.EQUALS : REDTAIL_SEARCH_OPERATOR.LIKE;

  // Unfortunately Redtail does not support OR conditions in contact search (only AND),
  // so we cannot add multiple phone number criteria in one query.
  // See: https://help.redtailtechnology.com/hc/en-us/articles/203964640-Contacts-Search-contacts-search-POST-method
  const response = await axios.post<{ Contacts: RedtailContact[] }>(`${RedtailApiUri.CONTACTS}?page=1`, [
    {
      Field: REDTAIL_SEARCH_FIELDS.PHONE_NUMBER,
      Operand: operand,
      Value: phoneNumber,
    },
  ]);

  return extractContactsFromResponse(response.data.Contacts);
};

const getPhonesOfContact = async (contactId: string): Promise<RedtailPhone[]> => {
  const response = await axios.get<RedtailPhone[]>(`${RedtailApiUri.PHONES(contactId)}`);

  return response.data || [];
};

// https://help.redtailtechnology.com/hc/en-us/articles/203964480-Phones-By-ContactID-Methods-contacts-contactid-phones-#PUT
const updateContactPhoneNumber = async (contactId: string, phoneNumber: string): Promise<void> => {
  const data: RedtailPhoneNumberUpdate = {
    ClientID: contactId,
    TypeID: RedtailPhoneNumberTypeID.Work,
    // eslint-disable-next-line
    Number: phoneNumber,
  };

  await axios.put(RedtailApiUri.UPDATE_PHONE(contactId), data);
};

// https://help.redtailtechnology.com/hc/en-us/articles/203964470-Contact-By-ContactID-Methods-contacts-contactid-#PUT
const createContact = async (contactData: ContactCreateModel) => {
  const response = await axios.put<{ ClientID: number }>(RedtailApiUri.CREATE_CONTACT, {
    Firstname: contactData.firstName,
    Lastname: contactData.lastName,
  });

  return String(response.data.ClientID.toString());
};

const getCategories = async (): Promise<RedtailCategory[]> => {
  const response = await axios.get<Array<{ MCLCode: number; Code: string }>>(RedtailApiUri.CATEGORIES);
  return response.data ? response.data.map(({ MCLCode, Code }) => ({ id: MCLCode, label: Code })) : [];
};

const addNote = async (contactId: string, note: CreateRedtailNote): Promise<{ noteId: string }> => {
  const data = { ...note, TypeID: 1 }; // Redtail TypeID=1 => Create a Note
  const response = await axios.put<{ RecID: number }>(RedtailApiUri.NEW_NOTE(contactId), data);
  return { noteId: String(response.data.RecID) };
};

const addActivity = async (activity: RedtailActivityAddModel): Promise<{ activityId: string }> => {
  const activityBody: RedtailActivity = {
    ClientID: activity.contactId,
    Subject: activity.subject,
    AllDayEvent: activity.isAllDayEvent,
    StartDate: dateToRedtailDate(activity.startDate),
    EndDate: dateToRedtailDate(activity.endDate),
  };

  const response = await axios.put<{ RecID: number }>(RedtailApiUri.NEW_ACTIVITY, activityBody);
  return { activityId: String(response.data.RecID) };
};

const dateToRedtailDate = (date: Date): string => `/Date(${date.getTime()})/`;

const getNotesOfContact = async (contactId: string): Promise<RedtailNote[]> => {
  const response = await axios.get<{ Notes: RedtailNote[] }>(RedtailApiUri.NOTES(contactId));
  return response.data.Notes;
};

export const redtailService = {
  createContact,
  searchContactsByName,
  searchContactsByNumber,
  getPhonesOfContact,
  updateContactPhoneNumber,
  getCategories,
  addNote,
  addActivity,
  getNotesOfContact,
};
