import { makeActionCreator } from '../actions/actionCreators';

export enum ConnectivityActionTypes {
  APP_RESTART = 'connectivity/app/restart',
  APP_ONLINE = 'connectivity/app/online',
  APP_OFFLINE = 'connectivity/app/offline',
}

export const restartApp = makeActionCreator(ConnectivityActionTypes.APP_RESTART)();
export const appOnline = makeActionCreator(ConnectivityActionTypes.APP_ONLINE)();
export const appOffline = makeActionCreator(ConnectivityActionTypes.APP_OFFLINE)();

export type ConnectivityActions =
  | ReturnType<typeof restartApp>
  | ReturnType<typeof appOnline>
  | ReturnType<typeof appOffline>;
