import { Store } from 'redux';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { ZendeskService } from '../zendesk.service';
import { openTicket, searchTicket, searchTicketError, searchTicketSuccess } from './tickets.action';
import { defineMessages } from 'react-intl';
import {
  allCallSelector,
  ContactMatchType,
  getContactMatchTypeSelector,
  getEntityInCallSelector,
} from '../../calls/calls.selector';
import { calculateCallDuration, getCallLogObject } from '../../calls/callLogger.utilities';
import { injectedIntlSelector, selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import {
  callHistoryElementByCallSelector,
  selectUserCallHistoryItemById,
} from '../../callHistoryPush/unifiedCallHistory.selector';
import { format } from 'date-fns';
import { CallWithContact } from '../../calls/calls.reducer';
import { Entity } from '../../models';

const definedMessages = defineMessages({
  SEARCH_TICKETS_ERROR: {
    id: 'Zendesk.SearchTickets.ErrorMessage',
    defaultMessage: 'Error searching tickets for requester in Zendesk',
  },
  NEW_TICKET: {
    id: 'Call.NewTicket',
    defaultMessage: 'New ticket',
  },
  NEW_CONTACT: {
    id: 'Call.NewContact',
    defaultMessage: 'New contact',
  },
  DIALOG_MULTIPLE_CONTACT_CONFIRMATION_TITLE: {
    id: 'Zendesk.Multiple.Match',
    defaultMessage: 'Are you sure',
  },
  DIALOG_MULTIPLE_CONTACT_CONFIRMATION_BODY: {
    id: 'Zendesk.Multiple.Match.Body',
    defaultMessage: 'Confirm to auto create a new contact again?',
  },
  DIALOG_MULTIPLE_CONTACT_CONFIRMATION_CONFIRM: {
    id: 'Zendesk.Multiple.Match.Body',
    defaultMessage: 'Save',
  },
});

export class TicketActionCreator {
  constructor(private store: Store<AppState>, private zendeskService: ZendeskService) {}

  async searchTickets(requesterId?: string, searchString?: string): Promise<void> {
    try {
      this.store.dispatch(searchTicket());
      const tickets = await this.zendeskService.searchTickets(requesterId, searchString);
      this.store.dispatch(searchTicketSuccess({ tickets }));
    } catch (e) {
      logger.error('Error searching ticket for user', e);
      this.store.dispatch(searchTicketError({ message: definedMessages.SEARCH_TICKETS_ERROR }));
    }
  }

  async openTicket(ticketId: string): Promise<void> {
    try {
      this.store.dispatch(openTicket({ ticketId }));
    } catch (e) {
      logger.error('Error opening ticket for user', e);
    }
  }

  async upsertAndOpenTicket(callId: string, shouldAddCallDetails: boolean, ticketId: string | undefined) {
    try {
      const state = this.store.getState();
      const call = allCallSelector(state, callId);
      const contact = getEntityInCallSelector(state, callId);
      const intl = injectedIntlSelector(state);
      const locale = selectedDateFnsLocaleSelector(state);
      const hasCallEnded = call.endTime !== undefined;
      const subject = `${intl.formatMessage(definedMessages.NEW_TICKET)} ${format(call.creationTime, 'PP', {
        locale,
      })}`;
      const newContactDefaultName = intl.formatMessage(definedMessages.NEW_CONTACT);

      let requesterId: string | undefined = contact?.id;
      let ticketIdToOpen: string = ticketId ?? '';

      if (!call) {
        return;
      }
      const matchType = getContactMatchTypeSelector(state, call.id);

      if (matchType === ContactMatchType.NoMatch) {
        requesterId = await this.zendeskService.createContact(
          call.theOtherParty.name || newContactDefaultName,
          call.theOtherParty.number,
        );
      }

      const callLog = this.getCallLogObjectForTicket(call, contact, requesterId);
      let comment = callLog.Subject;
      if (hasCallEnded && shouldAddCallDetails) {
        comment += '\n' + callLog.Description;
      }

      if (ticketId) {
        if (shouldAddCallDetails) {
          await this.zendeskService.addTicketComment({
            ticketId,
            comment,
            authorId: requesterId,
          });
        }
      } else {
        ticketIdToOpen = await this.zendeskService.createTicket({
          userId: requesterId,
          subject,
          description: comment,
          isCommentPublic: false,
        });
      }

      if (!hasCallEnded && shouldAddCallDetails) {
        this.addCallDetailsToTicketWhenCallEnds(call, contact, ticketIdToOpen, requesterId);
      }

      return this.openTicket(ticketIdToOpen);
    } catch (e) {
      logger.error('Error in Zendesk upsertAndOpenTicket', e);
    }
  }

  private addCallDetailsToTicketWhenCallEnds(
    call,
    contact: Entity | undefined,
    ticketIdToOpen: string,
    requesterId?: string,
  ) {
    const unsubscribe = this.store.subscribe(async () => {
      try {
        const endedCall = callHistoryElementByCallSelector(call)(this.store.getState());
        if (endedCall) {
          unsubscribe();
          const callLog = this.getCallLogObjectForTicket(endedCall, contact, requesterId);
          await this.zendeskService.addTicketComment({
            ticketId: ticketIdToOpen,
            comment: callLog.Subject + '\n' + callLog.Description,
            authorId: requesterId,
          });
        }
      } catch (error) {
        logger.error('Failed to add ticket comment', error);
      }
    });
  }

  private getCallLogObjectForTicket(call: CallWithContact, contact: Entity | undefined, requesterId?: string) {
    const state = this.store.getState();
    const intl = injectedIntlSelector(state);
    const locale = selectedDateFnsLocaleSelector(state);
    const hasCallEnded = call.endTime !== undefined;
    const callHistoryItem = call.answeredLegId ? selectUserCallHistoryItemById(state, call.answeredLegId) : undefined;
    const callDurationInSeconds = hasCallEnded ? calculateCallDuration(call, callHistoryItem) : 0;

    return getCallLogObject({
      call,
      contact: contact ?? {
        id: requesterId ?? '',
        label: call.theOtherParty.name ?? 'Unknown caller',
        name: call.theOtherParty.name ?? 'Unknown caller',
        phoneNumber: call.theOtherParty.number,
        type: 'Contact',
      },
      intl,
      locale,
      callDurationInSeconds,
    });
  }
}
