import { applyMiddleware, compose, createStore, Store, StoreEnhancer } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import { authenticationEventListener } from './authentication/authentication.eventListener';
import { configureMiddlewares } from './configureMiddlewares';
import { setCurrentIntegration } from './integrations/integrations.actions';
import { Integrations } from './models';
import rootReducer, { AppState } from './reducers';
import { initAnalytics } from './routerAnalytics';
import { salesforceLightningEventListener } from './salesforce/salesforceLightning.eventListener';
import { Environment } from './config';
import { registerAuthTokenInterceptor } from './authentication/authentication.interceptor';
import axios from 'axios';
import { storageService } from './storage/storage.service';
import { applyPersistReducer, initReduxPersistStorage } from './configurePersist';
import { syncService } from './sync/sync.service';

const composeEnhancers: typeof compose =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

function configureStore(integration, preloadedState, otherEnhancers?: []) {
  const middlewareEnhancer = applyMiddleware(...configureMiddlewares());
  let enhancers;
  if (otherEnhancers) {
    enhancers = [middlewareEnhancer, ...otherEnhancers];
  } else {
    enhancers = [middlewareEnhancer];
  }

  initReduxPersistStorage();
  storageService.init();

  const composedEnhancers = composeEnhancers(...enhancers) as StoreEnhancer<Store<AppState>, AppState>;
  const persistedReducer = applyPersistReducer(rootReducer);
  const store = createStore(persistedReducer, preloadedState, composedEnhancers) as Store<AppState>;
  const persistor = persistStore(store);

  registerAuthTokenInterceptor(axios, store);
  authenticationEventListener.listen(store);

  if (integration === Integrations.SalesforceLightning || integration === Integrations.SalesforceClassic) {
    salesforceLightningEventListener(store);
  }

  initAnalytics(store.getState);

  if (window.environment !== Environment.PROD) {
    moduleHotAccept(module, store);
  }

  store.dispatch(setCurrentIntegration(integration));

  setTimeout(async () => {
    try {
      await syncService.init();
    } catch (e) {
      console.error('error initializing sync service', e);
    }
  });

  return { store, persistor };
}

export function moduleHotAccept(mod, store: Store<any, any>) {
  if (mod && mod.hot) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    mod.hot.accept('./authentication/authentication.reducer', () => store.replaceReducer(rootReducer));
  }
}

let singletonStore: Store<AppState>;
let singletonPersistor: Persistor | undefined;

export const getStore = (integration?, enhancers?) => {
  if (!singletonStore) {
    const { store, persistor } = configureStore(integration, undefined, enhancers);
    singletonStore = store;
    singletonPersistor = persistor;
  }
  return singletonStore;
};

export const getPersistor = () => singletonPersistor as Persistor;
