import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import {
  ServiceAppointmentsStateProps,
  ServiceAppointmentsComponent,
  ServiceAppointmentsProps,
} from './ServiceAppointments.component';
import {
  serviceAppointmentLoadRequestStateByVINSelector,
  serviceAppointmentByVINSelector,
} from './serviceAppointments.selectors';

const mapStateToProps = (state: AppState, { VIN }: ServiceAppointmentsProps): ServiceAppointmentsStateProps => ({
  requestState: serviceAppointmentLoadRequestStateByVINSelector(VIN)(state),
  serviceAppointments: serviceAppointmentByVINSelector(VIN)(state),
  locale: selectedDateFnsLocaleSelector(state),
});

export const ServiceAppointments = connect<ServiceAppointmentsStateProps, {}, ServiceAppointmentsProps, AppState>(
  mapStateToProps,
)(ServiceAppointmentsComponent);
