import { RepairOrderDetails, RepairOrderDetailsLineType } from '../repairOrders/repairOrders.models';

export interface RepairOrderPart extends RepairOrderDetails {
  lineType: RepairOrderDetailsLineType.Parts;
  partNumber: string;
  manufacturer: string;
}

export interface InventoryPartId {
  manufacturer: string;
  partNumber: string;
}

export interface InventoryPartItem extends InventoryPartId {
  partDescription: string;
}

export type InventoryParts = Record<
  string,
  { loadRequestState: LoadInventoryPartRequestState; part?: InventoryPartItem }
>;

export enum LoadInventoryPartRequestState {
  LOADING,
  LOADED,
  FAILED,
}

export function getPartId(part: InventoryPartId): string {
  return `${part.manufacturer}:${part.partNumber}`;
}
