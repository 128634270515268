import { CallStates, CallType } from '@jive/realtime-events';
import { memoize } from 'lodash-es';
import { createSelector } from 'reselect';
import { CallWithContact } from '../calls/calls.reducer';
import { AppState } from '../reducers';
import { UserCallHistoryItem } from './callHistory.models';
import { CallHistoryMetadataItem, CallHistoryMetadataState } from './persistedCallHistoryMetadata.reducer';
import { UnifiedCallHistoryState, UnifiedCallHistoryStateRequestState } from './unifiedCallHistory.reducer';

const unifiedCallHistoryStateSelector = (state: AppState): UnifiedCallHistoryState => state.unifiedCallHistory;

const callHistoryMetadataSelector = (state: AppState): CallHistoryMetadataState => state.persistedCallHistoryMetadata;

export const callHistoryArraySelector = createSelector(
  unifiedCallHistoryStateSelector,
  (state) => state,
  ({ allIds }, state) => allIds.map((id) => callHistoryElementByIdSelector(id)(state)),
);

export const callHistoryUCHArraySelector = createSelector(unifiedCallHistoryStateSelector, ({ allIds, byId }) =>
  allIds.map((id) => byId[id]),
);

export const selectUserCallHistoryItemById = (state: AppState, legId: string): UserCallHistoryItem => {
  return unifiedCallHistoryStateSelector(state).byId[legId];
};

export const unifiedCallHistoryItemsPageSelector = createSelector(
  unifiedCallHistoryStateSelector,
  (unifiedCallHistoryState) => unifiedCallHistoryState.page,
);

export const isUnifiedCallHistoryLoadingSelector = createSelector(
  unifiedCallHistoryStateSelector,
  (unifiedCallHistoryState) => unifiedCallHistoryState.requestState === UnifiedCallHistoryStateRequestState.LOADING,
);

export const unifiedCallHistoryRequestStateSelector = createSelector(
  unifiedCallHistoryStateSelector,
  (unifiedCallHistoryState) => unifiedCallHistoryState.requestState,
);

export const unifiedCallHistoryHasNextPageSelector = createSelector(
  unifiedCallHistoryStateSelector,
  (unifiedCallHistoryState) => unifiedCallHistoryState.hasNextPage,
);

export const unifiedCallHistoryInitialLoadDateSelector = createSelector(
  unifiedCallHistoryStateSelector,
  (unifiedCallHistoryState) => unifiedCallHistoryState.initialRequestDate,
);

export const loadedCallHistoryMetadataBucketsSelector = createSelector(
  callHistoryMetadataSelector,
  (metadata) => metadata.loadedBuckets,
);

export const callHistoryMetadataItemSelector = (callId: string) =>
  createSelector(callHistoryMetadataSelector, (metadata) => {
    return metadata.byId[callId];
  });

export const callHistoryItemsAsCallsSelector = createSelector(
  (s) => s,
  unifiedCallHistoryStateSelector,
  (state, unifiedCallHistory) => {
    return unifiedCallHistory.allIds.map((id) => {
      const historyItem = unifiedCallHistory.byId[id];

      const metadata = callHistoryMetadataItemSelector(id)(state);
      const matchingMetadataItems =
        metadata?.allIds?.map((id) => callHistoryMetadataItemSelector(id)(state)) ?? metadata ? [metadata] : [];
      return mapCallHistoryItemToCall(historyItem, matchingMetadataItems);
    });
  },
);

const callHistoryElementByIdSelectorBase = (callId: string) =>
  createSelector(
    unifiedCallHistoryStateSelector,
    (s) => s,
    callHistoryMetadataItemSelector(callId),
    (unifiedCallHistory, state, metadata): CallWithContact => {
      const callHistoryItem = unifiedCallHistory.byId[callId];

      if (!callHistoryItem) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return undefined as any;
      }
      const matchingMetadataItems =
        metadata?.allIds?.map((id) => callHistoryMetadataItemSelector(id)(state)) ?? metadata ? [metadata] : [];
      return mapCallHistoryItemToCall(callHistoryItem, matchingMetadataItems);
    },
  );

// IMPORTANT: if possible do not call this function directly. Use callHistoryElementByCallSelector instead.
export const callHistoryElementByIdSelector = memoize(callHistoryElementByIdSelectorBase);

export const callHistoryElementByCallSelector =
  (call: CallWithContact) =>
  (state: AppState): CallWithContact | undefined => {
    for (const callId of call.allIds) {
      const callInHistory = callHistoryElementByIdSelector(callId)(state);

      if (!callInHistory) {
        continue;
      }

      return callInHistory;
    }
  };

export const todaysCallsSelector = createSelector(callHistoryItemsAsCallsSelector, (calls) => {
  const now = new Date();
  return calls.filter((call) => {
    const startDate = new Date(call.creationTime);
    return (
      startDate.getFullYear() === now.getFullYear() &&
      startDate.getMonth() === now.getMonth() &&
      startDate.getDate() === now.getDate()
    );
  });
});

function mapCallHistoryItemToCall(
  historyItem: UserCallHistoryItem,
  matchingMetadataItems: CallHistoryMetadataItem[],
): CallWithContact {
  const isMissedCall = !historyItem.answerTime || !historyItem.duration;
  const isClickToCall = matchingMetadataItems.some((meta) => meta.isClickToCall) ?? false;
  const direction = isClickToCall ? 'INBOUND' : historyItem.direction;
  const durationInSec = historyItem.duration * 1000;
  const theOtherParty = isClickToCall || direction === 'OUTBOUND' ? historyItem.caller : historyItem.callee;
  const endTime = historyItem.answerTime
    ? historyItem.answerTime?.getTime() + durationInSec
    : historyItem.startTime.getTime() + durationInSec;

  const allIds = matchingMetadataItems.reduce<string[]>(
    (ids, meta) => ids.concat(meta.allIds ?? []),
    [historyItem.legId],
  );
  const metaWithContacts = matchingMetadataItems.find((meta) => !!meta.contacts);
  return {
    id: historyItem.legId,
    allIds,
    answeredLegId: historyItem.legId,
    creationTime: historyItem.startTime.getTime(),
    endTime,
    caller: historyItem.caller,
    callee: historyItem.callee,
    duration: historyItem.duration,
    theOtherParty,
    type: isMissedCall
      ? direction === 'OUTBOUND'
        ? CallType.MissedCall
        : CallType.DeclinedCall
      : direction === 'OUTBOUND'
      ? CallType.IncomingCall
      : CallType.OutgoingCall,
    state: CallStates.Standing,
    previousStates: [],
    isClickToCall,
    allMatches: metaWithContacts?.contacts?.allMatches,
    entitySelected: metaWithContacts?.contacts?.entitySelected,
    isAutoSingleMatch: metaWithContacts?.contacts?.isAutoSingleMatch,
    participant: 'unknown',
  };
}
