import { AppState } from '../../reducers';
import {
  canLoadMoreOpportunitiesSelector,
  getMoreOpportunitiesRequestStateSelector,
  getOpportunitiesRequestStateSelector,
  opportunitiesListSelector,
} from './opportunity.selectors';
import { OpportunitiesComponent, OpportunitiesStateProps } from './Opportunities.component';
import { connect } from 'react-redux';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';

const mapStateToProps = (state: AppState): OpportunitiesStateProps => ({
  opportunities: opportunitiesListSelector(state),
  requestState: getOpportunitiesRequestStateSelector(state),
  moreRequestState: getMoreOpportunitiesRequestStateSelector(state),
  canLoadMoreOpportunities: canLoadMoreOpportunitiesSelector(state),
  openOpportunityInCrmSupported: false, // not implemented yet
  locale: selectedDateFnsLocaleSelector(state),
});

export const Opportunities = connect<OpportunitiesStateProps, {}, CallContextPanelProps, AppState>(mapStateToProps)(
  OpportunitiesComponent,
);
