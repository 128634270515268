import React from 'react';
import { getNameOfTheOtherParty, getNumberOfTheOtherParty } from './call.helper';
import { CallWithContact } from './calls.reducer';
import { Typography, TypographyProps } from '../theme/Typography.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AppState } from '../reducers/index';
import { useSelector } from 'react-redux';
import { hasSingleContactSelector } from './calls.selector';

const definedMessages = defineMessages({
  CALLER_ID_LABEL: {
    id: 'Call.Contact.CallerId',
    defaultMessage: 'Caller ID:',
  },
});

interface DeprecatedCallerIdComponentProps {
  call: CallWithContact;
  typographyProps?: TypographyProps;
}

export const DeprecatedCallerIdComponent: React.FC<DeprecatedCallerIdComponentProps> = ({ call, typographyProps }) => {
  const displayName = getNameOfTheOtherParty(call);
  const displayNumber = getNumberOfTheOtherParty(call);

  const hasSingleContact = useSelector((state: AppState) => hasSingleContactSelector(state, call));

  const renderCallerIdNumberLine = () => {
    return (
      <Typography {...typographyProps} variant="caption-default-01">
        {hasSingleContact ? null : (
          <FormattedMessage {...definedMessages.CALLER_ID_LABEL}>
            {(message) => <span>{message}&nbsp;</span>}
          </FormattedMessage>
        )}
        {displayNumber}
      </Typography>
    );
  };

  const renderCallerIdNameLine = () => {
    if (hasSingleContact || !displayName) {
      return null;
    }

    return (
      <Typography {...typographyProps} variant="caption-default-01">
        &lt;{displayName}&gt;
      </Typography>
    );
  };

  return (
    <>
      {renderCallerIdNumberLine()}
      {renderCallerIdNameLine()}
    </>
  );
};
