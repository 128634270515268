import { AppState } from '../reducers';
import { Store } from 'redux';
import { History, Location } from 'history';
import { ApplicationRoute } from '../constants';
import { match } from 'react-router';
import { callSelector } from '../calls/calls.selector';
import { logger } from '../logging';
import { AnalyticsPageType } from './analytics.models';
import { setAnalyticsCurrentPageType } from './analytics.action';
import { getActiveRoute } from '../browserHistory';

interface ActiveRoute {
  route: ApplicationRoute | string;
  match?: match;
}

export const analyticsPageTypeListener = (history: History, store: Store<AppState>) => {
  // set page type initially
  updateCurrentPageType(history.location, store);
  history.listen((location) => updateCurrentPageType(location, store));
};

function updateCurrentPageType(location: Location, store: Store<AppState>) {
  // We try to check exact match and fallback if there's no match found.
  let activeRoute = getActiveRoute(location, true);
  if (!activeRoute.match) {
    activeRoute = getActiveRoute(location);
  }

  const analyticsPageType = getPageTypeOfApplicationRoute(activeRoute, store.getState());
  store.dispatch(setAnalyticsCurrentPageType(analyticsPageType));
}

const applicationRouteTypeMap: { [key: string]: AnalyticsPageType } = {
  '/': AnalyticsPageType.Home,
  [ApplicationRoute.PHONE_ROUTE]: AnalyticsPageType.Phone,
  [ApplicationRoute.CALL_HISTORY_ROUTE]: AnalyticsPageType.CallHistory,
  [ApplicationRoute.CONVERSATIONS_ROUTE]: AnalyticsPageType.TextMessages,
  [ApplicationRoute.CONVERSATION_ROUTE]: AnalyticsPageType.TextMessageThread,
  [ApplicationRoute.CONVERSATION_LOG_ROUTE]: AnalyticsPageType.TextMessageLog,
  [ApplicationRoute.ACTIVE_CALLS_ROUTE]: AnalyticsPageType.ActiveCalls,
  [ApplicationRoute.LOGIN]: AnalyticsPageType.SignIn,
  [ApplicationRoute.LOGIN_SUCCESS]: AnalyticsPageType.SignIn,
  [ApplicationRoute.LOGOUT]: AnalyticsPageType.SignOut,
  [ApplicationRoute.LOGOUT_SUCCESS]: AnalyticsPageType.SignOut,
  [ApplicationRoute.NOTIFICATION_PERMISSION_ROUTE]: AnalyticsPageType.NotificationSettings,
  [ApplicationRoute.MICROPHONE_PERMISSION]: AnalyticsPageType.MicrophoneSettings,
  [ApplicationRoute.SETTINGS_ROUTE]: AnalyticsPageType.Settings,
  [ApplicationRoute.ONBOARDING]: AnalyticsPageType.OnboardingLanguage,
  [ApplicationRoute.ONBOARDING_TOS]: AnalyticsPageType.OnboardingTermsOfService,
  [ApplicationRoute.ONBOARDING_LOGIN]: AnalyticsPageType.OnboardingSignIn,
  [ApplicationRoute.ONBOARDING_TEST_CALL]: AnalyticsPageType.OnboardingTestCall,
  [ApplicationRoute.ONBOARDING_LINKING]: AnalyticsPageType.OnboardingLinking,
  [ApplicationRoute.CHANGELOG]: AnalyticsPageType.Changelog,
};

export function getPageTypeOfApplicationRoute(activeRoute: ActiveRoute, state: AppState): AnalyticsPageType {
  const { route, match } = activeRoute;

  // we don't track msteams related events
  if (route.startsWith('/msteams')) {
    return AnalyticsPageType.None;
  }

  // when we are on the call route we need to determine if the call selected on the route is active or ended
  // to do that we need to check if we have a call in the calls state with the given callId
  // if we have a call with the current ID and the call is not ended; the page type is ActiveCall otherwise EndedCall
  if (route === ApplicationRoute.CALL_ROUTE || route === ApplicationRoute.CALL_ROUTE_WITH_ACTION) {
    const callId = (match?.params as { callId: string })?.callId;
    try {
      if (callId) {
        const activeCall = callSelector(state, callId);
        return activeCall && !activeCall.endTime ? AnalyticsPageType.ActiveCall : AnalyticsPageType.EndedCall;
      } else {
        logger.error("Couldn't determine the callId of the call route", { match });
        return AnalyticsPageType.Unknown;
      }
    } catch (e) {
      logger.error('Error determining call type of call route', e);
      return AnalyticsPageType.Unknown;
    }
  }

  const pageType = applicationRouteTypeMap[route];
  if (!pageType) {
    logger.error("Couldn't determine pageType of the current route", { route });
  }
  return pageType || AnalyticsPageType.Unknown;
}
