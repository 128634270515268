import { Box } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Typography } from '../../theme/Typography.component';
import { OpenRepairOrderComponent } from './OpenRepairOrder.component';
import {
  OpenRepairOrdersRequestState,
  OpenRepairOrder,
  ServiceWritersRequestState,
  ServiceWriter,
} from './repairOrders.models';

const definedMessages = defineMessages({
  LIST_TITLE: {
    id: 'Cox.OpenRepairOrders.List.Title',
    defaultMessage: 'Repair orders',
  },
  EMPTY_MESSAGE: {
    id: 'Cox.OpenRepairOrders.List.Empty',
    defaultMessage: 'There is no open repair order for this vehicle.',
  },
});

export interface OpenRepairOrdersStateProps {
  requestState?: OpenRepairOrdersRequestState;
  openRepairOrders?: OpenRepairOrder[];
  locale: Locale;
  serviceWritersRequestState: ServiceWritersRequestState;
  serviceWriters?: ServiceWriter[];
}

export interface OpenRepairOrdersProps {
  VIN: string;
}

export const OpenRepairOrdersComponent: React.FC<OpenRepairOrdersProps & OpenRepairOrdersStateProps> = ({
  requestState,
  openRepairOrders,
  locale,
  serviceWritersRequestState,
  serviceWriters,
}) => {
  const renderBody = () => {
    if (requestState === undefined || requestState === OpenRepairOrdersRequestState.LOADING) {
      return (
        <LoadingSpinner isLoading={true}>
          <></>
        </LoadingSpinner>
      );
    }

    if (!openRepairOrders || !openRepairOrders.length) {
      return (
        <Typography variant="caption-small-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      );
    }

    return openRepairOrders.map((repairOrder) => (
      <OpenRepairOrderComponent
        key={repairOrder.repairOrderNumber}
        repairOrder={repairOrder}
        locale={locale}
        serviceWritersRequestState={serviceWritersRequestState}
        serviceWriters={serviceWriters}
      />
    ));
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.LIST_TITLE} />
        </Typography>
      </Box>
      {renderBody()}
    </div>
  );
};
