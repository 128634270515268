import React from 'react';
import { EntityType } from '../../constants';
import { BusinessInactiveIcon } from '@getgo/chameleon-icons/react';

import { CallActionButton, CallActionProps } from './CallActionButton.component';
import { AnalyticsAction, AnalyticsCategory } from '../../analytics-new/analytics.models';
import { defineMessages } from 'react-intl';

const definedMessage = defineMessages({
  NEW_ACCOUNT: {
    id: 'Call.NewAccount',
    defaultMessage: 'New account',
    description: 'Open new account create view',
  },
});

export const NewAccountButton: React.FC<Partial<CallActionProps>> = (props) => {
  return (
    <CallActionButton
      leadingIcon={<BusinessInactiveIcon aria-hidden />}
      label={definedMessage.NEW_ACCOUNT}
      entityType={EntityType.ACCOUNT}
      variant="custom"
      trackingEvent={{
        category: AnalyticsCategory.Call,
        action: AnalyticsAction.ItemClicked,
        label: 'New account | button',
      }}
      {...props}
    />
  );
};
