import { TrackingEvent } from './analytics.models';
import { newTracker } from './tracker-new';
import React from 'react';
import { logger } from '../logging';
import { RoundButton, RoundButtonProps } from '@getgo/chameleon-web/react';

export interface TrackedRoundButtonProps extends RoundButtonProps {
  trackingEvent: TrackingEvent;
}

export const TrackedRoundButton: React.FC<TrackedRoundButtonProps> = ({
  trackingEvent,
  onClick,
  children,
  ...otherProps
}) => (
  <RoundButton
    onClick={(event) => {
      try {
        newTracker.trackAnalyticsEvent(trackingEvent);
      } catch (error) {
        logger.error('Error while tracking round button', error);
      }
      if (onClick) {
        onClick(event);
      }
    }}
    {...otherProps}
  >
    {children}
  </RoundButton>
);
