import { Store } from 'redux';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { sessionManager } from './JiveRtcSessionManager';
import { SoftphoneCall } from './softphone.model';
import { softphoneCallEnded, softphoneCallUpdated } from './softphoneCall.action';
import {
  selectLocalSoftphoneCallsSelector,
  selectSoftphoneCallsByCalleeSelector,
  selectSoftphoneCallByIdSelector,
} from './softphoneCall.selector';
import { SoftphoneCallUpdateType, SoftphoneSessionManager } from './SoftphoneSessionManager';

export class SoftphoneSynchronizer
  implements
    Omit<SoftphoneSessionManager, 'subscribeToCallUpdates' | 'unsubscribeFromCallUpdates' | 'makeCall' | 'answerCall'>
{
  private broadcastChannel: BroadcastChannel | undefined;
  private store: Store<AppState> | undefined;

  initialize(store: Store<AppState>): void {
    this.broadcastChannel = window.BroadcastChannel
      ? new window.BroadcastChannel('goto-embedded-softphone-synchronizer')
      : undefined;
    this.store = store;
    try {
      this.listenToBroadcastChannelEvents();
    } catch (e) {
      logger.error('error initializing softphone synchronizer', e);
    }
  }

  private listenToBroadcastChannelEvents(): void {
    if (!this.broadcastChannel) {
      return;
    }
    this.broadcastChannel.addEventListener('message', async (event) => {
      if (!this.store || !event.data) {
        return;
      }

      try {
        switch (event.data.type) {
          case 'rejectCall': {
            return await this.handleRejectCallMessage(event.data.callee);
          }

          case 'callUpdate': {
            return this.handleCallUpdate(event.data.event, event.data.call);
          }

          case 'muteCall': {
            return await sessionManager.muteCall(event.data.callId);
          }

          case 'unmuteCall': {
            return await sessionManager.unmuteCall(event.data.callId);
          }

          case 'holdCall': {
            return await sessionManager.holdCall(event.data.callId);
          }

          case 'resumeCall': {
            return await sessionManager.resumeCall(event.data.callId);
          }

          case 'sendDtmf': {
            return await sessionManager.sendDtmf(event.data.callId, event.data.value);
          }

          case 'hangupCall': {
            return await sessionManager.hangupCall(event.data.callId);
          }

          case 'requestCallState': {
            return this.broadcastCallState();
          }

          case 'syncCallState': {
            return this.handleCallStateSync(event.data.calls);
          }

          case 'holdCalls': {
            return await sessionManager.holdOtherCalls();
          }
        }
      } catch (err) {
        logger.error('Error handling softphone broadcast channel command', err);
      }
    });
  }

  private async handleRejectCallMessage(callee: string): Promise<void> {
    if (!this.store) {
      return;
    }

    try {
      const softphoneCalls: SoftphoneCall[] = selectSoftphoneCallsByCalleeSelector(callee)(this.store.getState());
      for (const call of softphoneCalls) {
        try {
          await sessionManager.rejectCall(call.id);
        } catch (err) {
          logger.error('Error handling reject call message on softphone broadcast channel', err);
        }
      }
    } catch (err) {
      logger.error('Error in handleRejectCallMessage', err);
    }
  }

  private handleCallUpdate(updateType: SoftphoneCallUpdateType, call: SoftphoneCall) {
    if (!this.store) {
      return;
    }

    const updatedCall: SoftphoneCall = {
      ...call,
      isRemoteCall: true,
    };
    switch (updateType) {
      case 'updated':
        return this.store.dispatch(softphoneCallUpdated(updatedCall));
      case 'ended':
        return this.store.dispatch(softphoneCallEnded(updatedCall));
    }
  }

  async hangupCall(callId: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'hangupCall', callId });
  }
  async rejectCall(callId: string): Promise<void> {
    if (!this.store) {
      return;
    }

    const softphoneCall = selectSoftphoneCallByIdSelector(callId)(this.store.getState());

    if (!softphoneCall) {
      return;
    }

    return this.broadcastChannel?.postMessage({ type: 'rejectCall', callee: softphoneCall.theOtherParty.number });
  }

  async muteCall(callId: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'muteCall', callId });
  }
  async unmuteCall(callId: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'unmuteCall', callId });
  }
  async holdCall(callId: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'holdCall', callId });
  }
  async resumeCall(callId: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'resumeCall', callId });
  }
  async sendDtmf(callId: string, value: string): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'sendDtmf', callId, value });
  }
  async holdOtherCalls(): Promise<void> {
    return this.broadcastChannel?.postMessage({ type: 'holdCalls' });
  }

  broadcastCallUpdate(event: SoftphoneCallUpdateType, call: SoftphoneCall) {
    if (!this.broadcastChannel) {
      return;
    }

    this.broadcastChannel.postMessage({ type: 'callUpdate', event, call });
  }

  requestCallStateSync() {
    if (!this.broadcastChannel) {
      return;
    }

    this.broadcastChannel.postMessage({ type: 'requestCallState' });
  }

  private broadcastCallState() {
    if (!this.broadcastChannel || !this.store) {
      return;
    }

    const calls = selectLocalSoftphoneCallsSelector(this.store.getState());

    if (!calls || calls.length === 0) {
      return;
    }

    this.broadcastChannel.postMessage({ type: 'syncCallState', calls });
  }

  private handleCallStateSync(calls: SoftphoneCall[]) {
    for (const call of calls) {
      this.handleCallUpdate('updated', call);
    }
  }
}

export const softphoneSynchronizer = new SoftphoneSynchronizer();
