import { createSelector } from "@reduxjs/toolkit";
import { EntityPhoneNumber } from "../search/entities.model";
import { jiveEntityResourceSelector } from "./jiveEntities.selector";
import { relationshipResourceSelector } from "./relationships/relationships.selector";


export const entityPhoneNumbersSelector = (entityId: string) => 
    createSelector(
        relationshipResourceSelector('entityPhoneNumbers'),
        jiveEntityResourceSelector<EntityPhoneNumber>('phoneNumbers'),
        (relationships, phoneNumbers) => 
            relationships.byId[entityId]?.map((phoneNumberId) => phoneNumbers.byId[phoneNumberId])
    );
