import { connect } from 'react-redux';
import { setPhoneSelectedNumber } from '../phone/phone.actions';
import { selectedPhoneNumberSelector } from '../phone/phone.selector';
import { AppState } from '../reducers';
import { entityPhoneNumberErrorMessageSelector, entityPhoneNumberIsLoadingSelector } from './entities.selector';
import {
  SearchResultPhoneNumbersComponent,
  SearchResultPhoneNumbersProps,
  SearchResultPhoneNumbersPropsDispatch,
  SearchResultPhoneNumbersStateProps,
} from './SearchResultPhoneNumbers.component';

export default connect<
  SearchResultPhoneNumbersStateProps,
  SearchResultPhoneNumbersPropsDispatch,
  SearchResultPhoneNumbersProps,
  AppState
>(
  (state) => ({
    selectedPhoneNumber: selectedPhoneNumberSelector(state),
    isLoading: entityPhoneNumberIsLoadingSelector(state),
    errorMessage: entityPhoneNumberErrorMessageSelector(state),
  }),
  (dispatch) => ({
    selectPhoneNumber: (phoneNumber) => dispatch(setPhoneSelectedNumber(phoneNumber)),
  }),
)(SearchResultPhoneNumbersComponent);
