// Make sure not to import anything unnecessary here, since this file is referenced to changelog and app bundle too.
// Code splitting will most probably include imported chunks here to both bundles

const supportedLocales = ['en-US', 'de-DE', 'es-ES', 'fr-FR', 'pt-BR', 'it-IT'] as const;
export type SupportedLocaleType = typeof supportedLocales[number];
export const isLocaleSupported = (locale: string) => supportedLocales.includes(locale as any);

const DEFAULT_LOCALE: SupportedLocaleType = 'en-US';

// this is the list being displayed in the language selector
export const getSelectableLanguages = () =>
  ({
    en: 'English (International)',
    'en-US': 'English (USA)',
    'en-CA': 'English (Canada)',
    'en-GB': 'English (Great Britain)',
    'en-IE': 'English (Ireland)',
    'en-AU': 'English (Australia)',
    'fr-FR': 'Français (France)',
    'es-ES': 'Español (Spain)',
    'de-DE': 'Deutsch (Deutschland)',
    'it-IT': 'Italiano (Italia)',
    'pt-BR': 'Português (Brasil)',
  } as const);

// All locale request should go through this method.
// This will for example make fr-CA fallback to fr-FR which is the only supported fr locale
// currently declared. This function will become unneeded if we fix the currently supported
// locales by having default locales per language (without country codes).
export function toClosestSupportedLocale(locale: string): SupportedLocaleType {
  if (isLocaleSupported(locale)) {
    return locale as SupportedLocaleType;
  }

  if (locale && locale.length >= 2) {
    const languageCode = locale.substr(0, 2);
    const sameLanguageLocale = supportedLocales.find((supportedLanguage) => supportedLanguage.startsWith(languageCode));
    if (sameLanguageLocale) {
      return sameLanguageLocale;
    }
  }

  return DEFAULT_LOCALE;
}
