import { defineMessages } from 'react-intl';
import { getAmbassadorProviders } from '../../ambassador/ambassador.action';
import { ProxyActionsTypes } from '../../ambassador/proxy/proxy.action';
import { addListenerForOneTimeExecution, addSmartListener } from '../../middlewares/listener.middleware';
import { linkOrUnlinkAccounts } from './IntegrationAuthentication';
import { LinkAccountActionsTypes, linkAccountSuccess } from './linkAccount.action';
import { closeDialog } from '../../dialog/dialog.actions';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';

const definedClioMessages = defineMessages({
  INTEGRATION_STATUS_LINKED_SUCCESS: {
    id: 'SettingsContainer.Account.Linked.Success',
    defaultMessage: 'Your account is linked and you are ready to work!',
  },
});

const trackingEvents = defineTrackingEvents({
  LINK_ACCOUNT_SUCCEEDED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.LinkAccountSucceeded,
    label: '-',
  },
});

addSmartListener(LinkAccountActionsTypes.LINK_ACCOUNT_REQUEST)(async (store) => {
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await store.dispatch(closeDialog({}));
  await linkOrUnlinkAccounts(store.getState());
  addListenerForOneTimeExecution(ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS)((_, action) => {
    const { linked } = action.payload;
    if (linked) {
      newTracker.trackAnalyticsEvent(trackingEvents.LINK_ACCOUNT_SUCCEEDED);
      return linkAccountSuccess({
        message: definedClioMessages.INTEGRATION_STATUS_LINKED_SUCCESS,
      });
    }
  });
  // eslint-disable-next-line @typescript-eslint/await-thenable
  await store.dispatch(getAmbassadorProviders());
});
