/* eslint-disable @typescript-eslint/no-unsafe-call */
import appLogger from '@getgo/logger';
import loggerTargetLoggingServicePlugin from '@getgo/logger-target-logging-service';
import globalErrorHandler from '@getgo/logger/inputs/global_error_handler';
import { name, version, os, layout, description, product, manufacturer } from 'platform';
import { AppState } from '../reducers';
import { externalUserKeySelector } from '../authentication/authentication.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { getActiveRoute } from '../browserHistory';
import { selectedLanguageSelector } from '../settings/settings.selector';
import configuration, { Environment } from '../config';

class SplunkLogger {
  private loggingServiceTarget = loggerTargetLoggingServicePlugin();
  private logger = appLogger.createRoot(this.loggingServiceTarget);
  private getState?: () => AppState;

  constructor() {
    globalErrorHandler.setup(window, this.logger, { rethrowErrors: true });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    window.addEventListener('beforeunload', () => this.logger.flush.call(this.logger));
  }

  init(getState: () => AppState) {
    this.logger.logToConsole = false;
    this.getState = getState;
    this.loggingServiceTarget.start({
      server: configuration.loggingServiceUrl,
      context: {
        ls_source: 'g2c-integrations-embedded',
        ls_sourcetype: 'g2c-integrations-embedded',
        env: window.environment,
        hosting_env: window.environment ?? Environment.PROD,
        browser: description || 'unknown',
        browser_name: name || 'unknown',
        browser_version: version || 'unknown',
        browser_layout: layout || 'unknown',
        browser_language: navigator.language,
        machine_product: product || 'unknown',
        machine_manufacturer: manufacturer || 'unknown',
        os_architecture: os?.architecture || 'unknown',
        os_family: os?.family || 'unknown',
        os_version: os?.version || 'unknown',
        app_version: process.env.REACT_APP_JIF_APP_VERSION,
      },
    });
    this.setDynamicContext();
  }

  log(...args: Array<{} | string | number | Error | undefined>) {
    this.setDynamicContext();
    this.logger.log(...args);
  }
  debug(...args: Array<{} | string | number | Error | undefined>) {
    this.setDynamicContext();
    this.logger.debug(...args);
  }
  info(...args: Array<{} | string | number | Error | undefined>) {
    this.setDynamicContext();
    this.logger.info(...args);
  }
  warn(...args: Array<{} | string | number | Error | undefined>) {
    this.setDynamicContext();
    this.logger.warn(...args);
  }
  error(...args: Array<{} | string | number | Error | undefined>) {
    this.setDynamicContext();
    this.logger.error(...args);
  }
  flush() {
    this.logger.flush();
  }
  setContext(key: string, data: any) {
    this.logger.setContext(key, data);
  }

  private setDynamicContext() {
    if (!this.getState) {
      console.warn('Splunk logger not initialized to set dynamic context for logging');
      return;
    }

    try {
      const state = this.getState();
      this.setContext('userId', externalUserKeySelector(state));
      this.setContext('integration', currentIntegrationSelector(state));
      this.setContext('app_language', selectedLanguageSelector(state));
      const { route, match } = getActiveRoute(undefined, true);
      this.setContext('route', route);
      if (match) {
        this.setContext('match_params', match.params);
        this.setContext('match_path', match.path);
        this.setContext('match_isExact', match.isExact);
        this.setContext('match_url', match.url);
      }
    } catch (e) {
      console.error('Error setting dynamic context', e);
    }
  }
}

export const splunkLogger = new SplunkLogger();
