import { AmbassadorProxyAxiosInterceptor } from '../ambassador/proxy/proxy.interceptor';
import { proxyUserProviderSelector } from '../ambassador/proxy/proxy.selector';
import { IntegrationNames, Integrations } from '../models';
import { REDTAIL_API_PATH } from './redtail.model';

interface RedtailCustomAttributes {
  isUserKeyAuthModeEnabled: string;
}

export class RedtailAxiosInterceptor extends AmbassadorProxyAxiosInterceptor {
  protected integrationName = IntegrationNames[Integrations.Redtail];

  protected get providerHeaders() {
    const state = this.store.getState();
    const userProvider = proxyUserProviderSelector(state);
    const customAttributes = userProvider?.customAttributes as RedtailCustomAttributes | undefined;

    if (customAttributes?.isUserKeyAuthModeEnabled) {
      return {
        'Provider-Authorization': 'Userkeyauth --ambassador--encodedUserKeyAuth--ambassador--',
      };
    }

    return {
      'Provider-Authorization':
        'Basic --ambassador--APIKey--ambassador--:--ambassador--username--ambassador--:--ambassador--password--ambassador--',
    };
  }

  protected isUrlToIntercept(url: string): boolean {
    return url.includes(REDTAIL_API_PATH);
  }
}
