import { CreateNewLeadModel } from '../dynamics.model';
import { DynamicsService } from '../dynamics.service';
import { createEntityError, createEntitySuccess } from '../../search/entities.action';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { goToCallPage } from '../../browserHistory';
import { EntityType } from '../../constants';
import { Store } from 'redux';
import { AppState } from '../../reducers';

export const definedMessages = defineMessages({
  CREATE_LEAD_ERROR: {
    id: 'Create.Lead.Error',
    defaultMessage: 'There was an error while creating the lead, please try again.',
    description: 'error message lead create',
  },
  CREATE_LEAD_SUCCESS: {
    id: 'Create.Lead.Success',
    defaultMessage: 'Lead created successfully!',
    description: 'success message lead create',
  },
});

export class DynamicsLeadActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async createLead({ firstName, lastName, topic, phoneNumber, callId }: CreateNewLeadModel): Promise<void> {
    try {
      const leadId = await DynamicsService.createLead(firstName, lastName, topic, phoneNumber);

      this.store.dispatch(
        createEntitySuccess({
          message: definedMessages.CREATE_LEAD_SUCCESS,
          callId,
          entity: {
            id: leadId,
            label: 'Lead',
            name: 'Lead',
            type: EntityType.LEAD,
          },
        }),
      );
    } catch (error) {
      logger.error('Error during lead creation', error);

      this.store.dispatch(
        createEntityError({
          message: definedMessages.CREATE_LEAD_ERROR,
        }),
      );
    }

    goToCallPage(callId);
  }
}
