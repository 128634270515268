import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { integrationStartupStateSelector } from '../integrations/integrations.selector';
import { FullScreenPageWithLogo } from '../theme/FullScreenPageWithLogo.component';
import { Typography } from '../theme/Typography.component';
import { isOnlineSelector } from './connectivity.selector';
import { StartupState } from '../integrations/integrations.actions';
import { WifiInactiveIcon } from '@getgo/chameleon-icons/react';

const definedMessages = defineMessages({
  NO_INTERNET_CONNECTION_HEADING: {
    id: 'No.Internet.Connection.Heading',
    defaultMessage: 'No internet connection found',
  },
  NO_INTERNET_CONNECTION_INSTRUCTION: {
    id: 'No.Internet.Connection.Instruction',
    defaultMessage: 'Please, check your internet connection!',
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
    },
    noInternetSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      textAlign: 'center',
    },
    noInternetImage: {
      margin: '15px',
    },
    childrenContainer: {
      width: '100%',
      height: '100%',
    },
  }),
);

export const NoInternetConnectionComponent: React.FC = ({ children }) => {
  const classes = useStyles();
  const isOnline = useSelector(isOnlineSelector);
  const startupState = useSelector(integrationStartupStateSelector);

  function renderChildrenForAlreadyStartedApp() {
    return (
      <div className={classes.childrenContainer} style={{ display: isOnline ? 'flex' : 'none' }}>
        {children}
      </div>
    );
  }

  function renderChildrenForStartingApp() {
    return isOnline ? children : null;
  }

  return (
    <>
      <div style={{ display: isOnline ? 'none' : 'flex' }} className={classes.wrapper}>
        <FullScreenPageWithLogo fullHeight>
          <div className={classes.noInternetSection}>
            <WifiInactiveIcon className={classes.noInternetImage} size="85px" />
            <Typography variant="heading-small" color="text-info">
              <FormattedMessage {...definedMessages.NO_INTERNET_CONNECTION_HEADING} />
            </Typography>
            <Typography>
              <FormattedMessage {...definedMessages.NO_INTERNET_CONNECTION_INSTRUCTION} />
            </Typography>
          </div>
        </FullScreenPageWithLogo>
      </div>

      {/* Do not start the app if there is no internet connection; if it's already started hide it */}
      {startupState === StartupState.Started ? renderChildrenForAlreadyStartedApp() : renderChildrenForStartingApp()}
    </>
  );
};
