import React from 'react';
import { useSelector } from 'react-redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { AuthButtonComponent } from './AuthButton.component';
import { isAuthenticatedSelector } from './authentication.selector';

export const AuthButton: React.FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <AuthButtonComponent
      isAuthenticated={isAuthenticated}
      signIn={() => getActionFacade().login()}
      signOut={() => getActionFacade().logout()}
    />
  );
};
