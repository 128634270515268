class ReconnectHandler {
  private reconnectAttempts: number = 0;

  constructor(private scalingDuration: number = 1000, private maxReconnections: number = 10) {}

  async tryReconnect(reconnectCallback: () => void, errorCallback: () => void) {
    return new Promise<void>((resolve) => {
      if (++this.reconnectAttempts <= this.maxReconnections) {
        setTimeout(() => {
          reconnectCallback();
          resolve();
        }, this.reconnectAttempts * this.scalingDuration);
      } else {
        errorCallback();
        resolve();
      }
    });
  }

  reset() {
    this.reconnectAttempts = 0;
  }
}

export const ReconnectHandlerFactory = {
  getReconnectHandler: (scalingDuration: number = 1000, maxReconnections: number = 10) =>
    new ReconnectHandler(scalingDuration, maxReconnections),
};
