import { dynamicsSelfSelector } from './dynamics.selector';
import { DynamicsService } from './dynamics.service';
import { EntityType } from '../constants';
import { AppState } from '../reducers';

export const openDynamicsCrmForm = (state: AppState, entityType: EntityType, entityId: string) => {
  const self = dynamicsSelfSelector(state);
  const organization = self?.organizationTag;
  const location = self?.locationTag;

  if (!organization || !location) {
    throw Error('Organization or location is missing');
  }

  DynamicsService.openForm(organization, location, entityType, entityId);
};
