import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Environment } from './config';

const useStyles = makeStyles({
  sign: {
    position: 'fixed',
    top: '5px',
    right: '-30px',
    width: '120px',
    textAlign: 'center',
    transform: 'rotateZ(45deg)',
    fontSize: '10pt',
  },
  hosting: {
    width: '100%',
    background: 'rgba(0,0,255,0.1)',
    color: 'blue',
    padding: '3px',
  },
  config: {
    width: '100%',
    background: 'rgba(0,255,0,0.1)',
    color: 'green',
    padding: '3px',
  },
});

type environment = typeof window.environment;

export const EnvironmentsDisplayComponent: React.FC = () => {
  const classes = useStyles();

  const configEnvironment: environment = window.environment;
  const hostingEnvironment = window.environment;

  const [configDisplay, setConfigDisplay] = useState<string>(configEnvironment as string);
  const [hostingDisplay, setHostingDisplay] = useState<string>(window.environment as string);

  if (!hostingEnvironment || hostingEnvironment === Environment.PROD) {
    return null; // don't render the component in production environment
  }

  return (
    <div id="environments-display" className={classes.sign}>
      <div
        className={classes.hosting}
        onMouseOver={() => setHostingDisplay('HOST')}
        onMouseLeave={() => setHostingDisplay(hostingEnvironment)}
      >
        {hostingDisplay}
      </div>
      <div
        className={classes.config}
        onMouseOver={() => setConfigDisplay('CONFIG')}
        onMouseLeave={() => setConfigDisplay(configEnvironment as string)}
      >
        {configDisplay}
      </div>
    </div>
  );
};

export default EnvironmentsDisplayComponent;
