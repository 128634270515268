import { combineReducers, Reducer } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { IndexedById } from '../models';
import { JiveEntity } from './jiveEntities.model';

export interface JiveEntityReducerActionsTypesOptions {
  add?: string;
  replace?: string;
  clean?: string;
}

function isSameActionType(firstActionType: string | undefined, secondActionType: string) {
  return firstActionType && firstActionType === secondActionType;
}

const byIdReducer = <T, U extends AppAction<string, IndexedById<T> | void>>(
  actionsTypesOptions: JiveEntityReducerActionsTypesOptions,
) => (state: IndexedById<T> = {}, action: U): IndexedById<T> => {
  if (isSameActionType(actionsTypesOptions.add, action.type)) {
    return {
      ...state,
      ...Object.entries(action.payload as IndexedById<T>).reduce((accumulator, [key, value]) => {
        accumulator[key] = {
          ...state[key],
          ...value,
        };
        return accumulator;
      }, {}),
    };
  }

  if (isSameActionType(actionsTypesOptions.replace, action.type)) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (isSameActionType(actionsTypesOptions.clean, action.type)) {
    return {};
  }

  return state;
};

const allIdsReducer = <T, U extends AppAction<string, IndexedById<T> | void>>(
  actionsTypesOptions: JiveEntityReducerActionsTypesOptions,
) => (state: string[] = [], action: U): string[] => {
  if (
    isSameActionType(actionsTypesOptions.add, action.type) ||
    isSameActionType(actionsTypesOptions.replace, action.type)
  ) {
    return [...new Set([...state, ...Object.keys(action.payload as IndexedById<T>)])];
  }

  if (isSameActionType(actionsTypesOptions.clean, action.type)) {
    return [];
  }

  return state;
};

const jiveEntityReducer = <T, U extends AppAction<string, IndexedById<T> | void>>(
  actionsTypesOptions: JiveEntityReducerActionsTypesOptions,
) =>
  combineReducers({
    byId: byIdReducer<T, U>(actionsTypesOptions),
    allIds: allIdsReducer<T, U>(actionsTypesOptions),
  }) as Reducer<JiveEntity<T>, U>;

export default jiveEntityReducer;
