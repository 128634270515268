import { Integrations } from '../models';

export const LAST_SEEN_ENTRY_KEY = 'changelog_last_seen_version';
export const LAST_SEEN_ANNOUNCEMENT_KEY = 'changelog_last_seen_announcement';
export const NEXT_VERSION_PLACEHOLDER = '0.0.0-vnext';

export enum ChangelogType {
  Bugfix = 'bugfix',
  Feature = 'feature',
}

export interface ChangelogContentItem {
  type: ChangelogType;
  message: string;
}

export interface ChangelogEntry {
  isAnnouncement: boolean;
  announcementName?: string;
  version: string;
  date: string;
  integrations: Array<Integrations | 'all'>;
  title?: string;
  content?: ChangelogContentItem[];
  component: string;
}

export type VersionInfo = Partial<Record<Integrations | 'all', string>>;
