import { addListener } from '../middlewares/listener.middleware';
import { MessageVariant } from '../models';
import { uuid } from '../uuid';
import { showMessage } from './message.action';
import { MessagesActionsType } from './message.action';

addListener(MessagesActionsType.SHOW_SUCCESS_MESSAGE)((_, action) =>
  showMessage({
    id: uuid(),
    type: MessageVariant.Success,
    message: action.payload,
    params: { autoHide: true },
  }),
);

addListener(MessagesActionsType.SHOW_WARNING_MESSAGE)((_, action) =>
  showMessage({
    id: uuid(),
    type: MessageVariant.Warning,
    message: action.payload,
    params: { autoHide: true },
  }),
);

addListener(MessagesActionsType.SHOW_ERROR_MESSAGE)((_, action) =>
  showMessage({
    id: uuid(),
    type: MessageVariant.Error,
    message: action.payload,
    params: { autoHide: false, dismissible: true },
  }),
);
