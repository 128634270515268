import axios from 'axios';
import { sortBy } from 'lodash-es';
import configuration from '../config';
import { Line } from '../lines/lines.model';
import {
  CallHistoryQueryParams,
  CallHistoryQueryResult,
  CallHistoryRawRecord,
  UserCallHistoryItem,
  UserCallHistoryRawItem,
} from './callHistory.models';
import { areIdenticalCallHistoryItems, arePhoneNumbersEqual } from './callHistoryItemUtils';

// Docs
// https://developer-internal.goto.com/apis/call-reports/v1/#tag/User-Call-History

// GTC mobile implementation:
// https://github.com/jive/gotoconnect-mobile/blob/develop/packages/app/src/lmi-sdk/call-history/call-history.service.ts

// UCC Call History Service
// https://bitbucket.ops.expertcity.com/projects/JIVE/repos/connectsdk/browse/packages/call-history-service

const CALL_HISTORY_BATCH_SIZE = 50;

export const getCallHistoryItems = async (params: CallHistoryQueryParams): Promise<CallHistoryQueryResult> => {
  const batchItems: UserCallHistoryItem[] = [];
  let batchHasNextPage = false;

  let page = params.page;
  while (batchItems.length < CALL_HISTORY_BATCH_SIZE) {
    const { items, hasNextPage } = await queryCallHistoryItems({ ...params, page });
    batchItems.push(...items);
    batchHasNextPage = hasNextPage;

    if (!hasNextPage) {
      return {
        items: batchItems,
        hasNextPage,
        page,
      };
    }

    if (batchItems.length < CALL_HISTORY_BATCH_SIZE) {
      page++;
    }
  }

  return {
    hasNextPage: batchHasNextPage,
    items: batchItems,
    page,
  };
};

const queryCallHistoryItems = async (params: CallHistoryQueryParams): Promise<CallHistoryQueryResult> => {
  if (params.pageSize <= 1 || params.pageSize > 100) {
    throw Error('pageSize should be between 1 and 100');
  }

  if (params.page < 0) {
    throw Error('page should be a non negative number');
  }

  if (params.endTime <= params.startTime) {
    throw Error('endTime should be after startTime');
  }

  // The selectedLine object is not needed for the API, were filtering the result based on it.
  const { selectedLine: _, ...requestParams } = params;

  const response = await axios.request<{ items: CallHistoryRawRecord[] }>({
    url: `${configuration.apiBaseUrl}/call-reports/v1/reports/user-call-history`,
    params: requestParams,
  });

  const interactions = response.data;

  // The page size is indicating all of the interaction items.
  // The outer array can have less item if the user has multiple lines in the organization.
  const allItems = interactions.items.flatMap((interaction) => interaction.items);
  const filteredItems = filterAssociatedUserRecord(interactions.items, params.selectedLine);
  const sanitizedItems = removeDuplicatedItems(filteredItems);
  return {
    items: sanitizedItems,
    hasNextPage: !!allItems.length && allItems.length === params.pageSize,
    page: params.page,
  };
};

const filterAssociatedUserRecord = (records: readonly CallHistoryRawRecord[], selectedLine: Line) => {
  return records
    .map((record) => ({
      interactionId: record.interactionId,
      // Interaction items include all lines for the selected organization. Filtering it to contain just the selected line.
      items: record.items.filter((item) => {
        if (item.direction === 'OUTBOUND') {
          return arePhoneNumbersEqual(item.callee.number, selectedLine.number);
        }
        return arePhoneNumbersEqual(item.caller.number, selectedLine.number);
      }),
    }))
    .filter((record) => record.items.length)
    .map((record) => {
      if (record.items.length === 1) {
        return extractCallHistoryRecord(record.interactionId, record.items[0]);
      }

      // Look for the 1st answered leg.
      const answeredItem = sortBy(
        record.items.filter((item) => !!item.answerTime),
        (item) => new Date(item.answerTime!),
      )[0];
      if (answeredItem) {
        return extractCallHistoryRecord(record.interactionId, answeredItem);
      }

      // Will be displayed as an unanswered call with the info from the 1st leg
      return extractCallHistoryRecord(record.interactionId, record.items[record.items.length - 1]);
    });
};

const removeDuplicatedItems = (items: UserCallHistoryItem[]): UserCallHistoryItem[] => {
  const sanitizedItems: UserCallHistoryItem[] = [];

  for (const item of items) {
    if (sanitizedItems.some((i) => areIdenticalCallHistoryItems(i, item))) {
      continue;
    }

    const identicalItems = items.filter((i) => areIdenticalCallHistoryItems(i, item));
    const itemToPush = (identicalItems.length === 1 ? item : identicalItems.find((i) => !!i.answerTime)) ?? item;
    sanitizedItems.push(itemToPush);
  }

  return sanitizedItems;
};

const extractCallHistoryRecord = (
  interactionId: string,
  userCallHistoryItem: UserCallHistoryRawItem,
): UserCallHistoryItem => {
  const startTime = new Date(userCallHistoryItem.startTime);
  const answerTime = userCallHistoryItem.answerTime ? new Date(userCallHistoryItem.answerTime) : undefined;
  const isMissedCall = !userCallHistoryItem.answerTime || !userCallHistoryItem.duration;

  return {
    ...userCallHistoryItem,
    interactionId,
    startTime,
    answerTime,
    duration: isMissedCall ? 0 : Math.ceil(userCallHistoryItem.duration / 1000),
  };
};
