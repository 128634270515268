import { ISyncService } from './sync.service.model';
import { DummySyncService } from './sync.service.dummy';
import { WebLocksSyncService } from './sync.service.web-locks';
import { IDBSyncService } from './sync.service.idb';
import { LocalStorageSyncService } from './sync.service.local-storage';
import { logger } from '../logging/logger';

class SyncService {
  private static HOLD_LOCK_FOR_MILLISECONDS: number = 5000;
  private selectedImplementation: ISyncService | undefined;

  /**
   * Synchronises between multiple open tabs and announces exactly one tab to operate
   * @param operationPrefix Name of the operation that will be used to separate the operation types
   * @param id Uniq identifier of the exact operation that will match for multiple tabs
   * @param holdLockForMilliseconds the amount of time after the lock should be released to allow the same operation to run again
   */
  async canCurrentTabOperate(
    operationPrefix: string,
    id: string,
    holdLockForMilliseconds: number = SyncService.HOLD_LOCK_FOR_MILLISECONDS,
  ): Promise<boolean> {
    return this.selectedImplementation
      ? this.selectedImplementation.canCurrentTabOperate(operationPrefix, id, holdLockForMilliseconds)
      : false;
  }

  /**
   * Synchronises between multiple open tabs and executes the operation only once
   * @param operationPrefix Name of the operation that will be used to separate the operation types
   * @param id Uniq identifier of the exact operation that will match for multiple tabs
   * @param callback Function that will be invoked if the current tab can execute the operation
   * @param holdLockForMilliseconds the amount of time after the lock should be released to allow the same operation to run again
   */
  async executeOnce(
    operationPrefix: string,
    id: string,
    callback: () => void | Promise<void>,
    holdLockForMilliseconds: number = SyncService.HOLD_LOCK_FOR_MILLISECONDS,
  ) {
    const canOperate = await this.canCurrentTabOperate(operationPrefix, id, holdLockForMilliseconds);
    if (!canOperate) {
      return;
    }

    return callback();
  }

  async isAvailable(): Promise<boolean> {
    return !!this.selectedImplementation;
  }

  async init(): Promise<void> {
    try {
      const implementations: ISyncService[] = [
        new DummySyncService(),
        new WebLocksSyncService(),
        new IDBSyncService(),
        new LocalStorageSyncService(),
      ];

      for (const implementation of implementations) {
        if (await implementation.isAvailable()) {
          this.selectedImplementation = implementation;
          await this.selectedImplementation.cleanUpSyncData();
          break;
        }
      }
    } catch (e) {
      logger.error('Error during sync service initialization', e);
    }
  }
}

export const syncService = new SyncService();
