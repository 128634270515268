import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import classNames from 'classnames';
import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { EntityPhoneNumber, EntityPhoneNumberType } from './entities.model';

const definedMessages = defineMessages({
  TYPE_BUSINESS: {
    id: 'SearchResultPhoneNumbers.TypeBusiness',
    defaultMessage: 'Business',
  },
  TYPE_MOBILE: {
    id: 'SearchResultPhoneNumbers.TypeMobile',
    defaultMessage: 'Mobile',
  },
  TYPE_HOME: {
    id: 'SearchResultPhoneNumbers.TypeHome',
    defaultMessage: 'Home',
  },
  TYPE_FAX: {
    id: 'SearchResultPhoneNumbers.TypeFax',
    defaultMessage: 'Fax',
  },
  TYPE_SKYPE: {
    id: 'SearchResultPhoneNumbers.TypeSkype',
    defaultMessage: 'Skype',
  },
  TYPE_PAGER: {
    id: 'SearchResultPhoneNumbers.TypePager',
    defaultMessage: 'Pager',
  },
  TYPE_OTHER: {
    id: 'SearchResultPhoneNumbers.TypeOther',
    defaultMessage: 'Other',
  },
  TYPE_EXTENSION: {
    id: 'SearchResultPhoneNumbers.TypeExtension',
    defaultMessage: 'Extension',
  },
  TYPE_ASSISTANT: {
    id: 'SearchResultPhoneNumbers.TypeAssistant',
    defaultMessage: 'Assistant',
  },
  TYPE_DIRECT: {
    id: 'SearchResultPhoneNumbers.TypeDirect',
    defaultMessage: 'Direct',
  },
  EMPTY_MESSAGE: {
    id: 'SearchResultPhoneNumbers.EmptyMessage',
    defaultMessage: 'This contact has no phone numbers.',
  },
});

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
      },
      tile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: tokens.uiBase01,
        color: tokens.text01,
        padding: theme.spacing(2, 4),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(4),
      },
      selected: {
        backgroundColor: tokens.hoverPrimary,
        color: tokens.text06,
      },
      loading: {
        display: 'flex',
        justifyContent: 'center',
        padding: '3px',
        marginBottom: '5px',
      },
    }),
  { name: 'SearchResultPhoneNumbers' },
);

export interface SearchResultPhoneNumbersStateProps {
  selectedPhoneNumber: string;
  isLoading: boolean;
  errorMessage: MessageDescriptor | null;
}

export interface SearchResultPhoneNumbersPropsDispatch {
  selectPhoneNumber: (phoneNumber: string) => void;
}

export interface SearchResultPhoneNumbersProps {
  entityId: string;
  phoneNumbers: EntityPhoneNumber[];
}

function getDefinedMessageForType(type: EntityPhoneNumberType) {
  return (
    {
      [EntityPhoneNumberType.MOBILE]: definedMessages.TYPE_MOBILE,
      [EntityPhoneNumberType.BUSINESS]: definedMessages.TYPE_BUSINESS,
      [EntityPhoneNumberType.EXTENSION]: definedMessages.TYPE_EXTENSION,
      [EntityPhoneNumberType.HOME]: definedMessages.TYPE_HOME,
      [EntityPhoneNumberType.FAX]: definedMessages.TYPE_FAX,
      [EntityPhoneNumberType.PAGER]: definedMessages.TYPE_PAGER,
      [EntityPhoneNumberType.SKYPE]: definedMessages.TYPE_SKYPE,
      [EntityPhoneNumberType.OTHER]: definedMessages.TYPE_OTHER,
      [EntityPhoneNumberType.ASSISTANT]: definedMessages.TYPE_ASSISTANT,
      [EntityPhoneNumberType.DIRECT]: definedMessages.TYPE_DIRECT,
    }[type] || definedMessages.TYPE_BUSINESS
  );
}

export const SearchResultPhoneNumbersComponent: React.FC<SearchResultPhoneNumbersStateProps &
  SearchResultPhoneNumbersPropsDispatch &
  SearchResultPhoneNumbersProps> = ({
  selectedPhoneNumber,
  selectPhoneNumber,
  phoneNumbers,
  isLoading,
  errorMessage,
}) => {
  const classes = useStyles();

  const emptyTile = (
    <div className={classes.tile}>
      <FormattedMessage {...(errorMessage ? errorMessage : definedMessages.EMPTY_MESSAGE)} />
    </div>
  );

  const phoneNumberList = phoneNumbers.map((phoneNumberDetail) => (
    <div
      key={`phoneNumber-${phoneNumberDetail.phoneNumber}`}
      className={
        selectedPhoneNumber === phoneNumberDetail.phoneNumber
          ? classNames(classes.tile, classes.selected)
          : classes.tile
      }
      onClick={() => selectPhoneNumber(phoneNumberDetail.phoneNumber)}
    >
      <span>
        <FormattedMessage {...getDefinedMessageForType(phoneNumberDetail.type)} />
      </span>
      <span>{phoneNumberDetail.phoneNumber}</span>
    </div>
  ));

  const progress = (
    <div className={classes.loading}>
      <CircularProgress size={20} />
    </div>
  );

  return (
    <div className={classes.root}>
      {phoneNumbers.length === 0 && !selectedPhoneNumber ? emptyTile : phoneNumberList}
      {isLoading ? progress : null}
    </div>
  );
};
