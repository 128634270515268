export interface LoginData {
  token: string;
  expiration: number;
  isTokenRefresh: boolean;
  pathToRedirect?: string;
}

/**
 * See entitlement descriptions here: https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=~johnspa&title=GoToConnect+Licenses
 */
export enum Entitlements {
  G2M = 'g2m',
  GoToConnect = 'gotoconnect',
  Jive = 'jive',
  Hvoice = 'hvoice',
}
