import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { EmptyStateWithLogo } from './EmptyStateWithLogo';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { BlockIcon } from '@getgo/chameleon-icons/react';

export const definedMessages = defineMessages({
  TITLE: {
    id: 'Startup.Error.BlockedCookies.Title',
    defaultMessage: 'Blocked third-party cookies',
  },
  SUBTITLE: {
    id: 'Startup.Error.BlockedCookies.Message',
    defaultMessage: 'To use this integration, contact your admin about unblocking third party cookies on your browser.',
  },
});

const trackingEvents = defineTrackingEvents({
  PAGE_LOADED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PageLoaded,
    label: 'Third party cookies blocked',
  },
});

export const CookiesUnavailablePage = () => {
  useEffect(() => {
    newTracker.trackAnalyticsEvent(trackingEvents.PAGE_LOADED);
  }, []);

  return <EmptyStateWithLogo icon={<BlockIcon />} title={definedMessages.TITLE} subtitle={definedMessages.SUBTITLE} />;
};
