export enum AnalyticsPageType {
  None = 'Not defined',
  Home = 'Home',
  SignIn = 'Sign-in',
  SignOut = 'Sign-out',
  Phone = 'Phone',
  ActiveCall = 'Active call',
  EndedCall = 'Ended call',
  CallHistory = 'Call history',
  ActiveCalls = 'Active calls',
  NotificationSettings = 'Notification settings',
  MicrophoneSettings = 'Microphone settings',
  Settings = 'Settings',
  SalesforceInitError = 'SFInitError',
  Unknown = 'Unknown',
  OnboardingLanguage = 'Onboarding - Language',
  OnboardingTermsOfService = 'Onboarding - Terms of Service',
  OnboardingSignIn = 'Onboarding - Sign in',
  OnboardingTestCall = 'Onboarding - Test call',
  OnboardingLinking = 'Onboarding - Linking',
  Changelog = 'Changelog',
  TextMessages = 'Text Messages',
  TextMessageThread = 'Text Message Thread',
  TextMessageLog = 'Text Message Log',
}

export enum AnalyticsCategory {
  Note = 'Note',
  Contact = 'Contact',
  CallLog = 'Call log',
  Activity = 'Activity',
  Application = 'Application',
  Call = 'Call',
  Callhistory = 'Callhistory',
  Information = 'Information',
  Navigation = 'Navigation',
  Configuration = 'Configuration',
  Opportunity = 'Opportunity',
  Case = 'Case',
  Vehicle = 'Vehicle',
  CallLimitations = 'Call limitations',
  CommmunicationLog = 'Communication log',
  ContentList = 'Content list',
  Services = 'Services',
  Authentication = 'Authentication',
  ServiceTicket = 'ServiceTicket',
  Ticket = 'Ticket',
  Changelog = 'Changelog',
  TextMessage = 'Text Message',
  ConversationLog = 'Conversation log',
  Softphone = 'Softphone',
  Lead = 'Lead',
}

export enum AnalyticsAction {
  PageLoaded = 'page.loaded',
  ItemChanged = 'item.changed',
  ItemClicked = 'item.clicked',
  ContactCreationSucceeded = 'create_contact.succeeded',
  ContactCreationFailed = 'create_contact.failed',
  NoteCreationSucceeded = 'create_note.succeeded',
  NoteCreationFailed = 'create_note.failed',
  ActivityCreationSucceeded = 'create_activity.succeeded',
  ActivityCreationFailed = 'create_activity.failed',
  PopupShown = 'popup.shown',
  PopupDisappeared = 'popup.disappeared',
  ContactMatchFailed = 'match_contact.failed',
  ContactMatchSuccess = 'match_contact.succeeded',
  ContactMatchDuration = 'match_contact.duration',
  ContactMatchPulled = 'match_contact.pulled',
  ContactTabOpened = 'contact_tab.opened',
  ContactTabBlocked = 'contact_tab.blocked',
  CrmApiFail = 'crm_API.failed',
  CallDispositionLoadFailed = 'load_call_disposition.failed',
  CallLogCreationFailed = 'create_call_log.failed',
  CallLogCreationSucceeded = 'create_call_log.succeeded',
  MatterLoadStarted = 'matter_load.started',
  MatterSearchStarted = 'matter_search.started',
  MatterSearchSucceeded = 'matter_search.succeeded',
  MatterSearchFailed = 'matter_search.failed',
  CreateCommLogSucceeded = 'create_comm_log.succeeded',
  CreateCommLogFailed = 'create_comm_log.failed',
  ActivityCategoriesLoadStarted = 'activity_categories_load.started',
  ActivityCategoriesLoadSucceeded = 'activity_categories_load.succeeded',
  ActivityCategoriesLoadFailed = 'activity_categories_load.failed',
  SalesforceUserLoaded = 'salesforce_user.loaded',
  LoadListStarted = 'load_list.started',
  LoadListSucceeded = 'load_list.succeeded',
  LoadListFailed = 'load_list.failed',
  LineLoadingFailed = 'lines_loading.failed',
  TestCallSucceeded = 'testcall.succeeded',
  TestCallFailed = 'testcall.failed',
  LoginSucceeded = 'login.succeeded',
  LoginFailed = 'login.failed',
  LinkAccountSucceeded = 'link_account.succeeded',
  LinkAccountFailed = 'link_account.failed',
  MigrationSucceeded = 'migration.succeeded',
  MigrationFailed = 'migration.failed',
  ConversationLogSucceeded = 'conversation_log.succeeded',
  ConversationLogFailed = 'conversation_log.failed',
  IntegrationPanelShown = 'integration_panel.shown',
  AuthMethodSelected = 'auth_method.selected',
}

export interface TrackingEvent {
  category: AnalyticsCategory;
  action: AnalyticsAction;
  label: string;
  value?: number;
}

export interface TrackingEvents {
  [key: string]: TrackingEvent;
}

// utility function for better code completion
export function defineTrackingEvents<T extends TrackingEvents>(events: T): T {
  return events;
}
