import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EntityType } from '../../constants';
import { currentIntegrationSelector } from '../../integrations/integrations.selector';
import { logger } from '../../logging';
import { Entity, Integrations } from '../../models';
import { AppState } from '../../reducers';
import { salesForceCallActionsProvider } from '../../salesforce/calls/callActions.salesforce';
import { dynamicsCallActionsProvider } from '../../dynamics/calls/callActions.dynamics';
import { mimissingObjectAccessRightsSelector } from '../../salesforce/salesforce.selectors';
import { CallWithContact } from '../calls.reducer';
import { getEntityInCallSelector } from '../calls.selector';

export interface UseCallActionsResult {
  primaryAction: JSX.Element | null;
  secondaryActions: JSX.Element[];
}

export function useCallActions(call: CallWithContact): UseCallActionsResult {
  const integration = useSelector(currentIntegrationSelector);
  const entityInCall = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));
  const mimissingObjectAccessRights = useSelector(mimissingObjectAccessRightsSelector);

  const callActions = useMemo(
    () => getActionsByIntegration(integration, entityInCall, mimissingObjectAccessRights),
    [integration, entityInCall, mimissingObjectAccessRights],
  );

  return callActions;
}

function getActionsByIntegration(
  integration: Integrations,
  entityInCall: Entity | undefined,
  mimissingObjectAccessRights: EntityType[],
) {
  switch (integration) {
    case Integrations.SalesforceLightning:
    case Integrations.SalesforceClassic:
      return {
        primaryAction: salesForceCallActionsProvider.getPrimaryAction(entityInCall),
        secondaryActions: salesForceCallActionsProvider.getSecondaryActions(mimissingObjectAccessRights, entityInCall),
      };

    case Integrations.Dynamics:
      return {
        primaryAction: dynamicsCallActionsProvider.getPrimaryAction(entityInCall),
        secondaryActions: dynamicsCallActionsProvider.getSecondaryActions([], entityInCall), // We don't know of possible missing object rights for now.
      };

    default:
      logger.error(`useCallActions was called with unsupported integration: ${integration}`);

      return {
        primaryAction: null,
        secondaryActions: [] as JSX.Element[],
      };
  }
}
