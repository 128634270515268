import React, { FC, useCallback, useEffect, useState } from 'react';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SyncIcon, SynchronizedIcon } from '@getgo/chameleon-icons/react';
import { Entity } from '../models';
import { CallWithContact } from './calls.reducer';
import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { entityPhoneNumbersSelector } from '../jiveEntities/phoneNumbers.selector';
import { getNumberOfTheOtherParty, stringifyCallType } from './call.helper';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Tooltip } from '@getgo/chameleon-material-ui';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { getContactMatchTypeSelector } from './calls.selector';
import { EntityType } from '../constants';
import { IconButton } from '@getgo/chameleon-web/react';
import { tokens } from '@getgo/chameleon-core';

const definedMessages = defineMessages({
  SYNC_OK_TOOLTIP_CONTACT: {
    id: 'PhoneNumberSyncButton.Tooltip.OK.Contact',
    defaultMessage: 'This number is synced with the contact.',
  },
  SYNC_OK_TOOLTIP_ACCOUNT: {
    id: 'PhoneNumberSyncButton.Tooltip.OK.Account',
    defaultMessage: 'This number is synced with the account.',
  },
  SYNC_OK_TOOLTIP_LEAD: {
    id: 'PhoneNumberSyncButton.Tooltip.OK.Lead',
    defaultMessage: 'This number is synced with the lead.',
  },
  SYNC_ERROR_TOOLTIP_CONTACT: {
    id: 'PhoneNumberSyncButton.Tooltip.Error.Contact',
    defaultMessage: 'Sync this number with the existing contact.',
  },
  SYNC_ERROR_TOOLTIP_ACCOUNT: {
    id: 'PhoneNumberSyncButton.Tooltip.Error.Account',
    defaultMessage: 'Sync this number with the existing account.',
  },
  SYNC_ERROR_TOOLTIP_LEAD: {
    id: 'PhoneNumberSyncButton.Tooltip.Error.Lead',
    defaultMessage: 'Sync this number with the existing lead.',
  },
});

const useStyles = makeStyles({
  iconButton: {
    '&::part(content)': {
      '--goto-icon-color': tokens.danger01,
    },
  },
});

interface PhoneNumberSyncButtonProps {
  call: CallWithContact;
  entity: Entity;
  className?: string;
  style?: any;
}

enum PhoneNumberLoadingState {
  INITIAL,
  LOADING,
  LOADED,
}

export const PhoneNumberSyncIcon: FC<PhoneNumberSyncButtonProps> = ({ call, entity, className, style }) => {
  const entityPhoneNumbers = useSelector((state: AppState) => entityPhoneNumbersSelector(entity.id)(state));
  const matchType = useSelector((state: AppState) => getContactMatchTypeSelector(state, call.id));
  const [loadingState, setLoadingState] = useState(PhoneNumberLoadingState.INITIAL);
  const classes = useStyles();

  const loadPhoneNumbers = useCallback(async () => {
    setLoadingState(PhoneNumberLoadingState.LOADING);
    await getActionFacade().loadContactPhoneNumbers(entity.id);
    setLoadingState(PhoneNumberLoadingState.LOADED);
  }, [entity]);

  useEffect(() => {
    void loadPhoneNumbers();
  }, [loadPhoneNumbers]);

  const isPhoneNumberMatching = () => {
    return entityPhoneNumbers?.some((phoneNumber) => phoneNumber.phoneNumber === getNumberOfTheOtherParty(call));
  };

  const updatePhoneNumber = async () => {
    setLoadingState(PhoneNumberLoadingState.LOADING);
    await getActionFacade().updateContactPhoneNumber(entity.id, getNumberOfTheOtherParty(call));
    await loadPhoneNumbers();
    setLoadingState(PhoneNumberLoadingState.LOADED);
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Contact,
      action: AnalyticsAction.ItemClicked,
      label: `Add this number to user | button | ${stringifyCallType(call.type)} | ${matchType}`,
    });
  };

  const getTooltipMessageDescriptor = (showOkOrError: 'ok' | 'error'): MessageDescriptor => {
    switch (showOkOrError) {
      case 'ok': {
        switch (entity.type) {
          case EntityType.ACCOUNT:
            return definedMessages.SYNC_OK_TOOLTIP_ACCOUNT;
          case EntityType.LEAD:
            return definedMessages.SYNC_OK_TOOLTIP_LEAD;
          default:
            return definedMessages.SYNC_OK_TOOLTIP_CONTACT;
        }
      }
      case 'error': {
        switch (entity.type) {
          case EntityType.ACCOUNT:
            return definedMessages.SYNC_ERROR_TOOLTIP_ACCOUNT;
          case EntityType.LEAD:
            return definedMessages.SYNC_ERROR_TOOLTIP_LEAD;
          default:
            return definedMessages.SYNC_ERROR_TOOLTIP_CONTACT;
        }
      }
    }
  };

  const renderPhoneNumberSyncIcon = () => {
    if (isPhoneNumberMatching()) {
      const title = <FormattedMessage {...getTooltipMessageDescriptor('ok')} />;

      return (
        <Tooltip arrow placement="bottom" title={title} enterDelay={500}>
          <span className={className} style={style} data-cy="phone-number-sync">
            <SynchronizedIcon size="16px" color="var(--goto-icon-color-success)" />
          </span>
        </Tooltip>
      );
    } else {
      const title = <FormattedMessage {...getTooltipMessageDescriptor('error')} />;

      return (
        <Tooltip arrow placement="bottom" title={title} enterDelay={500}>
          <IconButton
            className={classes.iconButton}
            style={style}
            data-cy="phone-number-sync"
            onClick={updatePhoneNumber}
            size="xsmall"
          >
            <SyncIcon size="16px" />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <>
      {loadingState === PhoneNumberLoadingState.LOADING ? (
        <CircularProgress className={className} style={style} size={14} />
      ) : (
        renderPhoneNumberSyncIcon()
      )}
    </>
  );
};
