import { ISyncService } from './sync.service.model';
import { uuid } from '../uuid';
import { logger } from '../logging';
import { isLocalStorageAvailable } from '../utils';

export const PREFIX = 'SYNC-SERVICE';
export const CHOSEN_PREFIX = 'CHOSEN';

interface CallLogStorageData {
  value: string;
  holdLockForMilliseconds: number;
  timeStamp: number;
}

export class LocalStorageSyncService implements ISyncService {
  async canCurrentTabOperate(operationPrefix: string, id: string, holdLockForMilliseconds): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const value = uuid();
      const key = `${PREFIX}-${operationPrefix}-${id}-${value}`;
      const chosenStorageId = `${PREFIX}-${CHOSEN_PREFIX}-${operationPrefix}-${id}`;
      const storedData: CallLogStorageData = {
        value,
        holdLockForMilliseconds,
        timeStamp: Date.now(),
      };

      // if someone else already logged this call, lets stop here
      if (localStorage.getItem(chosenStorageId)) {
        resolve(false);
        return;
      }

      // lets say that this instance would like do something alone
      localStorage.setItem(key, JSON.stringify(storedData));

      setTimeout(() => {
        // let's see who else wants to do it with the same prefix, and choose a winner
        const chosenCallLogKey = Object.keys(localStorage)
          .filter((key) => key.startsWith(`${PREFIX}-${operationPrefix}-${id}`))
          .sort((a, b) => a.localeCompare(b))[0];

        const hasChosenKeyAlready = localStorage.getItem(chosenStorageId);
        if (chosenCallLogKey !== key || hasChosenKeyAlready) {
          // this tab is not the one chosen one
          // some other tab is already in the logging process
          resolve(false);
          localStorage.removeItem(key);
          return;
        }

        // lets record that this instance logs/logged the call
        const chosenLoggerData: CallLogStorageData = {
          value: key,
          holdLockForMilliseconds,
          timeStamp: Date.now(),
        };
        localStorage.setItem(chosenStorageId, JSON.stringify(chosenLoggerData));

        // come back in 500ms and do the actual logging if it's still the chosen instance to log
        setTimeout(() => {
          const chosenLoggerData = this.extractDataFromLocalStorage(chosenStorageId);
          if (!chosenLoggerData || chosenLoggerData.value !== key) {
            resolve(false);
            localStorage.removeItem(key);
            return;
          }

          resolve(true);
          setTimeout(() => {
            localStorage.removeItem(chosenStorageId);
            localStorage.removeItem(key);
          }, holdLockForMilliseconds - 1000); // we need 1000ms to determine who is the winner
        }, 500);
      }, 500);
    });
  }

  async cleanUpSyncData(): Promise<void> {
    const keysToRemove = Object.keys(localStorage).filter((key) => {
      if (key.startsWith(PREFIX)) {
        const entry = this.extractDataFromLocalStorage(key);
        if (!entry) {
          return false;
        }
        return Date.now() - Number(entry.timeStamp) > entry.holdLockForMilliseconds ?? 5000;
      }

      return false;
    });

    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
  }

  async isAvailable(): Promise<boolean> {
    return isLocalStorageAvailable();
  }

  private extractDataFromLocalStorage(key: string): CallLogStorageData | undefined {
    const entry = localStorage.getItem(key);

    if (!entry) {
      return undefined;
    }

    try {
      return JSON.parse(entry) as CallLogStorageData;
    } catch (e) {
      logger.error('Error parsing local storage sync data', e, { entry });
      return undefined;
    }
  }
}
