import { DynamicsService } from '../dynamics.service';
import { EntityType } from '../../constants';
import { createEntityError, createEntitySuccess } from '../../search/entities.action';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { CreateNewCaseModel } from '../dynamics.model';
import { goToCallPage } from '../../browserHistory';

const definedMessages = defineMessages({
  CREATE_CASE_ERROR: {
    id: 'Create.Case.Error',
    defaultMessage: 'There was an error while creating the case, please try again.',
    description: 'error message case create',
  },
  CREATE_CASE_SUCCESS: {
    id: 'Create.Case.Success',
    defaultMessage: 'Case created successfully!',
    description: 'success message case create',
  },
});

export class DynamicsCaseActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async createCaseForContact({ title, contactId, callId }: CreateNewCaseModel): Promise<void> {
    try {
      const incidentId = await DynamicsService.createCase(title, contactId);

      this.store.dispatch(
        createEntitySuccess({
          message: definedMessages.CREATE_CASE_SUCCESS,
          callId,
          entity: {
            id: incidentId,
            label: 'Case',
            name: 'Case',
            type: EntityType.CASE,
          },
        }),
      );
    } catch (error) {
      logger.error('Error during case creation', error);

      this.store.dispatch(
        createEntityError({
          message: definedMessages.CREATE_CASE_ERROR,
        }),
      );
    }

    goToCallPage(callId);
  }
}
