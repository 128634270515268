import { connect } from 'react-redux';
import { deleteAllMessages } from '../../inAppNotification/message.action';
import {
  CallDispositionErrorMessageComponent,
  CallDispositionErrorMessageDispatchProps,
  CallDispositionErrorMessageStateProps,
} from './CallDispositionErrorMessage.component';
import { isDesktopIntegrationSelector } from '../../integrations/integrations.selector';
import { Dispatch } from 'redux';

const mapState = (state) => ({
  isDesktopIntegration: isDesktopIntegrationSelector(state),
});

const mapDispatch = (dispatch: Dispatch): CallDispositionErrorMessageDispatchProps => ({
  onClose: () => dispatch(deleteAllMessages()),
});

export const CallDispositionErrorMessage = connect<
  CallDispositionErrorMessageStateProps,
  CallDispositionErrorMessageDispatchProps
>(
  mapState,
  mapDispatch,
)(CallDispositionErrorMessageComponent);
