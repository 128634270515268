import { defineMessages, FormattedMessage } from 'react-intl';
import React, { FC } from 'react';
import { Button } from '@getgo/chameleon-web/react';
import { useDispatch, useSelector } from 'react-redux';
import { linkAccountRequest } from './linkAccount.action';
import { isAuthenticatedSelector } from '../../authentication/authentication.selector';
import { proxyProviderAvailableSelector } from '../../ambassador/proxy/proxy.selector';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';

const definedMessages = defineMessages({
  LINK_ACCOUNT_BUTTON_LABEL: {
    id: 'LinkAccount.Button.Label',
    defaultMessage: 'Link Account',
  },
});

const trackingEvents = defineTrackingEvents({
  LINK_ACCOUNT_BUTTON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Link account | button',
  },
});

export const LinkAccountButton: FC = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const providerAvailable = useSelector(proxyProviderAvailableSelector);
  const dispatch = useDispatch();

  return (
    <Button
      disabled={!isAuthenticated || !providerAvailable}
      variant="primary"
      onClick={() => {
        dispatch(linkAccountRequest());
        newTracker.trackAnalyticsEvent(trackingEvents.LINK_ACCOUNT_BUTTON);
      }}
      fullwidth
    >
      <FormattedMessage {...definedMessages.LINK_ACCOUNT_BUTTON_LABEL} />
    </Button>
  );
};
