import { Store } from 'redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { AppState } from '../reducers';
import { EntitiesActionsType } from '../search/entities.action';
import { ConnectWiseActionFacade } from './connectwise.action.facade';
import { ConnectWiseAxiosInterceptor } from './connectwise.axios.interceptor';
import { NoteActionsType } from '../notes/notes.actions';
import { ContactActionsType } from '../actions/contacts';
import { EntityType } from '../constants';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';

addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (store, action) => {
  if (action.payload === Integrations.ConnectWise) {
    // let's wait for the current integration to be saved in the store,
    // otherwise getActionFacade will not know which integration to use
    registerConnectWiseListener(store);
  }
});

let isListenerRegistered = false;

function registerConnectWiseListener(store: Store<AppState>) {
  if (isListenerRegistered) {
    return;
  }

  isListenerRegistered = true;

  new ConnectWiseAxiosInterceptor(store).register();

  addCallStartListener(async (_, action) => {
    await getActionFacade().createScreenpop(action.payload.call);
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(action.payload.call);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const query = action.payload.query;
    await getActionFacade().searchContact(query);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, action) => {
    if (!action.payload.entity) {
      return;
    }

    await getActionFacade().openContactInCrm(action.payload.entity.id);
  });

  addListener(NoteActionsType.GET_NOTES)(async (_, { payload }) => {
    await getActionFacade<ConnectWiseActionFacade>().loadMostRecentNoteOfContact(payload.contactId);
  });

  addListener(NoteActionsType.GET_MORE_NOTES)(async (_, __) => {
    await getActionFacade<ConnectWiseActionFacade>().loadMoreNotesOfContact();
  });

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, { payload: { contactId, phoneNumber } }) => {
    await getActionFacade().updateContactPhoneNumber(contactId, phoneNumber);
  });

  addListener(EntitiesActionsType.CREATE_ENTITY)(
    async (_, { payload: { entityName, defaultFieldValues, isActiveCall, callId } }) => {
      if (entityName === EntityType.CONTACT) {
        const { firstName, lastName, phoneNumber } = defaultFieldValues;

        await getActionFacade().createContact({
          firstName,
          lastName,
          phoneNumber,
          isActiveCall: isActiveCall || false,
          callId,
        });
      } else {
        await getActionFacade<ConnectWiseActionFacade>().openFormInCrm({
          entityType: entityName,
          defaultFieldValues,
        });
      }
    },
  );

  addListener(NoteActionsType.ADD_NOTE)(async (_, { payload }) => {
    await getActionFacade<ConnectWiseActionFacade>().addNote(payload);
  });
}
