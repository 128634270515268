import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import React, { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { TextField, IconButton } from '@getgo/chameleon-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactDatePicker from 'react-datepicker';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import { Locale } from 'date-fns';
import { newTracker } from '../analytics-new/tracker-new';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { logger } from '../logging';
import { ClockInactiveIcon } from '@getgo/chameleon-icons/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1,
      cursor: 'pointer',
      '& .react-datepicker__header': {
        display: 'none',
      },
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: '4px 0',
        width: '100%',
        maxHeight: '182px',
        overflowY: 'scroll',
      },
      '& li': {
        '&:hover': {
          backgroundColor: tokens.interactive01,
          color: tokens.text04,
        },
        '&.react-datepicker__time-list-item--selected': {
          backgroundColor: tokens.interactive01,
          color: tokens.text04,
        },
        ...theme.typography.body1,
        width: 'auto',
        listStyle: 'none',
        overflow: 'hidden',
        boxSizing: 'content-box',
        padding: '11px 16px',
      },
    },
    paper: {
      maxHeight: '200px',
      overflow: 'auto',
    },
    denseInput: {
      marginRight: '-12px',
    },
  }),
);

export interface TimePickerStateProps {
  locale: Locale;
}

export interface TimePickerProps {
  label: MessageDescriptor;
  trackingEvent: TrackingEvent;
  value: Date | null;
  onChange: (date: Date | null) => void;
  margin?: 'normal' | 'dense';
  stepsInMinutes?: number;
  dense?: boolean;
  name: string;
}

export const TimePicker: React.FC<TimePickerStateProps & TimePickerProps> = ({
  value,
  onChange,
  label,
  margin,
  locale,
  dense,
  trackingEvent,
  name,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ReactDatePicker
      open={Boolean(anchorEl)}
      popperClassName={classes.popper}
      selected={value}
      minDate={value}
      maxDate={value}
      onChange={(value, event) => {
        // if not keyboard change
        if (!event) {
          try {
            newTracker.trackAnalyticsEvent(trackingEvent);
          } catch (error) {
            logger.error('Error while tracking timepicker', error);
          }

          onChange(value);
          handleClose();
        }
      }}
      // onSelect fires on keyboard changes
      onSelect={(value) => {
        try {
          newTracker.trackAnalyticsEvent(trackingEvent);
        } catch (error) {
          logger.error('Error while tracking timepicker', error);
        }
        onChange(value);
      }}
      customInputRef="inputRef"
      customInput={
        <TextField
          name={name}
          margin={margin}
          variant="outlined"
          label={intl.formatMessage(label)}
          endAdornment={
            <InputAdornment position="end">
              <ClickAwayListener onClickAway={handleClose}>
                <IconButton
                  size="small"
                  aria-controls="time-picker-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  variant="secondary"
                >
                  <ClockInactiveIcon />
                </IconButton>
              </ClickAwayListener>
            </InputAdornment>
          }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: dense ? classes.denseInput : undefined,
            },
            onKeyDown: (e: KeyboardEvent) => {
              // prevent react-datepicker throwing errors because the calendar is not open
              // https://github.com/Hacker0x01/react-datepicker/issues/2082
              if (e.keyCode === 38 || e.keyCode === 40) {
                e.stopPropagation();
              }
            },
          }}
        />
      }
      showTimeSelect
      preventOpenOnFocus
      showTimeSelectOnly
      timeIntervals={15}
      locale={locale}
      timeFormat="p"
      dateFormat="p"
      calendarContainer={({ children }) => <Paper className={classes.paper}>{children}</Paper>}
      popperPlacement="left-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '65px, -65px',
        },
      }}
    />
  );
};
