import { AppState } from '../reducers';
import { Store } from 'redux';
import {
  integrationStarted,
  integrationStarting,
  integrationUninitialized,
} from '../integrations/integrations.actions';
import {
  externalUserKeySelector,
  isAuthenticatedSelector,
  jiveIdSelector,
} from '../authentication/authentication.selector';
import { PresenceService } from '../presence/presence.service';
import { setPresence } from '../presence/presence.action';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { isSoftphoneCapableAndEnabledSelector, selectedNormalizedLineSelector } from '../settings/settings.selector';
import { goToRoute } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { tryStartRealtimeService } from '../settings/realtimeService.action';
import { configureScope } from '@sentry/browser';
import { startCheckingIfNewVersionIsAvailable } from '../versionChecker/versionChecker';
import { linesService } from '../lines/lines.service';
import { addPbxes } from '../jiveEntities/pbxesEntity.action';
import { logger } from '../logging';

import { currentIntegrationSelector } from '../integrations/integrations.selector';

import { startNotificationChannel } from '../notification-channel/notification-channel.manager';
import { CAPABILITY_CONVERSATIONS } from '../actionFacade/action.facade.capabilities';
import { lastSeenAnnouncementSelector } from '../changelog/changelog.selector';
import { changelogVersionManager } from '../changelog/changelog.versionmanager';
import { setHasNewAnnouncement } from '../changelog/changelog.actions';
import { pendoService } from '../pendo/pendo.service';
import { getPersistedSettingState } from '../settings/settings.persistence.service';
import {
  settingsStateLoaded,
  settingsStateLoading,
  settingsStateMissing,
} from '../settings/settings.persistence.action';
import { hasLinesLoadingErrorSelector } from '../lines/lines.selectors';
import { sessionManager } from '../softphone/JiveRtcSessionManager';
import { isNotMicrophonePermissionDenied } from '../softphone/microphonePermission.service';
import { softphoneCallStarted, softphoneCallUpdated, softphoneCallEnded } from '../softphone/softphoneCall.action';
import { softphoneSynchronizer } from '../softphone/SoftphoneSynchronizer';

export class StartupActionCreator {
  protected isSoftphoneStarted: boolean = false;

  constructor(protected store: Store<AppState>) {}

  public async startMainApplication(): Promise<void> {
    try {
      const state = this.store.getState();
      this.store.dispatch(integrationStarting());
      if (isAuthenticatedSelector(state)) {
        await this.loadSettingsFromStockPile();
        await Promise.all([this.loadPresence(), getActionFacade().loadLines()]);

        // If lines could not be loaded do not try to start integration
        if (hasLinesLoadingErrorSelector(this.store.getState())) {
          return;
        }

        // redirect to onboarding if the previously line is missing
        if (!selectedNormalizedLineSelector(this.store.getState())) {
          // We have to go back to the onboarding, setting back the application state to uninitialized so we can rerun startup later.
          this.store.dispatch(integrationUninitialized());
          return goToRoute(ApplicationRoute.ONBOARDING_LOGIN);
        }

        const loadConversationCapabilityPromise = this.loadConversationCapability();
        const loadSoftphoneCapabilityPromise = this.loadSoftphoneCapability();

        await this.loadPbxes();

        if (isSoftphoneCapableAndEnabledSelector(this.store.getState())) {
          await this.startSoftphone();
        }

        await this.loadRealtimeConnection();
        await this.loadNotificationChannelConnection();
        await this.checkNewAnnouncements();

        const jiveId = jiveIdSelector(state);

        configureScope((scope) => {
          scope.setUser({ id: jiveId });
        });
        setTimeout(() => {
          pendoService.initialize(this.store.getState);
        }, 5000);

        // let's wait a little bit before start checking for new version
        // we need some time until first messages arrive from Realtime API
        setTimeout(() => this.loadNewVersionChecker(), 3000);

        await Promise.all([loadConversationCapabilityPromise, loadSoftphoneCapabilityPromise]);
      }
      this.store.dispatch(integrationStarted());
    } catch (e) {
      logger.error('error in startMainApplication', e);
    }
  }

  public async startOnboardingServices(): Promise<void> {
    try {
      await this.loadPbxes();
      await this.loadRealtimeConnection();
    } catch (e) {
      logger.error('error in startOnboardingServices', e);
    }
  }

  public async loadSoftphoneCapability() {
    getActionFacade().setCapability(CAPABILITY_CONVERSATIONS, false);
  }

  public async startSoftphone(): Promise<void> {
    if (this.isSoftphoneStarted) {
      return;
    }

    const hasMicrophonePermission = await isNotMicrophonePermissionDenied();
    if (!hasMicrophonePermission) {
      return;
    }

    try {
      await sessionManager.init(this.store);
      softphoneSynchronizer.initialize(this.store);
      softphoneSynchronizer.requestCallStateSync();
      sessionManager.subscribeToCallUpdates((event, call) => {
        try {
          softphoneSynchronizer.broadcastCallUpdate(event, call);

          switch (event) {
            case 'started':
              return this.store.dispatch(softphoneCallStarted(call));
            case 'updated':
              return this.store.dispatch(softphoneCallUpdated(call));
            case 'ended':
              return this.store.dispatch(softphoneCallEnded(call));
          }
        } catch (e) {
          logger.error('Error handling softphone call update', e);
        }
      });
      this.isSoftphoneStarted = true;
    } catch (e) {
      logger.error('Cannot start softphone', e);
    }
  }

  public stopSoftphone(): void {
    if (!this.isSoftphoneStarted) {
      return;
    }
    try {
      sessionManager.stop();
    } catch (error) {
      // Stopping the softphone is no an idempotent operation, calling it twice should be threated as an error.
      logger.warn('Cannot stop softphone', error);
    }
    this.isSoftphoneStarted = false;
  }

  public async loadSettingsFromStockPile() {
    try {
      this.store.dispatch(settingsStateLoading());

      const integration = currentIntegrationSelector(this.store.getState());
      const persistedSettingsState = await getPersistedSettingState(integration);

      if (!persistedSettingsState) {
        // after login select line settings action is dispatched so stockpile will be updated
        this.store.dispatch(settingsStateMissing());
        return;
      }

      this.store.dispatch(settingsStateLoaded(persistedSettingsState));
    } catch (e) {
      this.store.dispatch(settingsStateMissing());
      logger.error('Could not load settings state from stockpile, fallback to redux persist', e);
    }
  }

  private async loadPbxes() {
    const state = this.store.getState();
    const jiveId = jiveIdSelector(state);

    try {
      const pbxes = await linesService.loadNormalizedPbxes(jiveId);
      this.store.dispatch(addPbxes(pbxes));
    } catch (error) {
      // TODO: display error message if PBX/Linking info cannot be loaded
      logger.error('PBX loading error', error);
    }
  }

  protected async loadConversationCapability() {
    getActionFacade().setCapability(CAPABILITY_CONVERSATIONS, false);
  }

  private async loadPresence(): Promise<void> {
    try {
      const externalUserKey = externalUserKeySelector(this.store.getState());
      const { appearance } = await PresenceService.getPresence(externalUserKey);

      this.store.dispatch(setPresence({ appearance }));
    } catch (error) {
      logger.error('Cannot load presence during application startup', error);
    }
  }

  private async loadRealtimeConnection() {
    this.store.dispatch(tryStartRealtimeService());
  }

  private async loadNotificationChannelConnection() {
    try {
      await startNotificationChannel(this.store);
    } catch (e) {
      logger.error('Failed to start notification channel');
    }
  }

  private async loadNewVersionChecker() {
    return startCheckingIfNewVersionIsAvailable(this.store);
  }

  private async checkNewAnnouncements() {
    try {
      await getActionFacade().loadLastSeenAnnouncement();
      const lastSeenAnnouncement = lastSeenAnnouncementSelector(this.store.getState());
      const currentIntegration = currentIntegrationSelector(this.store.getState());
      const hasNewAnnouncement = await changelogVersionManager.hasNewerAnnouncement(
        currentIntegration,
        lastSeenAnnouncement,
      );
      this.store.dispatch(setHasNewAnnouncement(hasNewAnnouncement));
    } catch (e) {
      logger.error('Failed to check if new announcement is available', e);
      this.store.dispatch(setHasNewAnnouncement(false));
    }
  }
}
