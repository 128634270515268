import React, { FC } from 'react';
import { SettingsActiveIcon, SettingsInactiveIcon } from '@getgo/chameleon-icons/react';
import { Badge } from '@material-ui/core';
import { hasSeenSoftphoneSettingsSelector } from './settings.selector';
import { useSelector } from 'react-redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';

export const SettingsIcon: FC<{ active?: boolean }> = ({ active }) => {
  const hasSeenSoftphoneSettings = useSelector(hasSeenSoftphoneSettingsSelector);
  const isSoftphoneCapable = getActionFacade().isCapable(CAPABILITY_SOFTPHONE);

  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!isSoftphoneCapable || hasSeenSoftphoneSettings}
      style={{ height: 'var(--goto-icon-size, 18px)', width: 'var(--goto-icon-size, 18px)' }}
    >
      {active ? <SettingsActiveIcon /> : <SettingsInactiveIcon />}
    </Badge>
  );
};
