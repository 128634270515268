import { connect } from 'react-redux';
import { TodaysCallsSummaryComponent, TodaysCallsSummaryStateProps } from './TodaysCallsSummary.component';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { todaysCallsSelector } from '../callHistoryPush/unifiedCallHistory.selector';

const mapStateToProps = (state): TodaysCallsSummaryStateProps => ({
  todaysCalls: todaysCallsSelector(state),
  isNarrowIntegration: isNarrowIntegrationSelector(state),
});

export const TodaysCallsSummary = connect<TodaysCallsSummaryStateProps>(mapStateToProps)(TodaysCallsSummaryComponent);
