import { connect } from 'react-redux';
import { isDesktopIntegrationSelector } from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { deleteAllMessages } from '../inAppNotification/message.action';
import {
  ClickToCallErrorMessageComponent,
  ClickToCallErrorMessageDispatchProps,
  ClickToCallErrorMessageStateProps,
} from './ClickToCallErrorMessage.component';
import { Dispatch } from 'redux';

const mapState = (state): ClickToCallErrorMessageStateProps => ({
  isDesktopIntegration: isDesktopIntegrationSelector(state),
});

const mapDispatch = (dispatch: Dispatch): ClickToCallErrorMessageDispatchProps => ({
  onClose: () => dispatch(deleteAllMessages()),
});

export default connect<ClickToCallErrorMessageStateProps, ClickToCallErrorMessageDispatchProps, {}, AppState>(
  mapState,
  mapDispatch,
)(ClickToCallErrorMessageComponent);
