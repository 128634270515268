import { LoadActivityCategoriesRequestState, ActivitiesState } from './activity.models';
import { ActivityActionsType, ActivityActions } from './activity.actions';

export const INITIAL_STATE: ActivitiesState = {
  requestState: LoadActivityCategoriesRequestState.INITIAL,
  activityCategories: [],
};

export const activitiesReducer = (state: ActivitiesState = INITIAL_STATE, action: ActivityActions): ActivitiesState => {
  switch (action.type) {
    case ActivityActionsType.LOAD_ACTIVITY_CATEGORIES:
      return {
        ...state,
        activityCategories: [],
        requestState: LoadActivityCategoriesRequestState.LOADING,
      };

    case ActivityActionsType.LOAD_ACTIVITY_CATEGORIES_SUCCESS:
      return {
        ...state,
        requestState: LoadActivityCategoriesRequestState.LOADED,
        activityCategories: action.payload.activityCategories,
      };

    case ActivityActionsType.LOAD_ACTIVITY_CATEGORIES_ERROR:
      return {
        ...state,
        activityCategories: [],
        requestState: LoadActivityCategoriesRequestState.FAILED,
      };
    default:
      return state;
  }
};
