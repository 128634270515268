import { Company } from '../connectwise.model';

export enum GetCompaniesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface CompanyPickerState {
  requestState: GetCompaniesRequestState;

  companies: Company[];
}
