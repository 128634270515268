import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { CoxService } from '../cox.service';
import { loadInventoryPart, loadInventoryPartError, loadInventoryPartSuccess } from './parts.actions';

const definedMessages = defineMessages({
  INVENTORY_PART_LOAD_ERROR: {
    id: 'Cox.Parts.InventoryPart.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading part. Please try again later.',
  },
});

export class PartsActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async loadPart(partNumber: string, manufacturer: string): Promise<void> {
    this.store.dispatch(loadInventoryPart({ partNumber, manufacturer }));

    try {
      const part = await CoxService.getPart(partNumber, manufacturer);
      this.store.dispatch(loadInventoryPartSuccess({ partNumber, manufacturer, part }));
    } catch (error) {
      logger.error('Failed to load part', error);
      this.store.dispatch(
        loadInventoryPartError({ partNumber, manufacturer, message: definedMessages.INVENTORY_PART_LOAD_ERROR }),
      );
    }
  }
}
