// The models used by the Clio API.

export enum ClioType {
  Contact = 'Contact',
  User = 'User',
  PhoneCommunication = 'PhoneCommunication',
  TimeEntry = 'TimeEntry',
}

export interface ClioGetResponse<T> {
  data: T[];
}

export interface ClioGetSingleResponse<T> {
  data: T;
}

export interface ClioGetPageResponse<T> extends ClioGetResponse<T> {
  meta: {
    paging: {
      next?: string;
    };
    records: number;
  };
}

export interface ClioNote {
  id: number;
  subject: string;
  detail: string;
  created_at: string;
}

export enum ClioSubscriptionType {
  Attorney = 'ATTORNEY',
  NonAttorney = 'NON_ATTORNEY',
}

export enum ClioMatterStatus {
  Pending = 'PENDING',
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum ClioBillingMethod {
  Flat = 'FLAT',
  Contingency = 'CONTINGENCY',
  Hourly = 'HOURLY',
}

export interface ClioUser {
  // Whether the User is the owner of the account
  account_owner: boolean;
  //  Whether the User is a Clio Connect user
  clio_connect: boolean;
  // Whether the User is a default attendee for court rules events
  court_rules_default_attendee: boolean;
  // Default calendar id for User.
  default_calendar_id: number;
  // The email of the User
  email: string;
  // Whether the User is allowed to log in
  enabled: boolean;
  // ETag for the User
  etag: string;
  // The first name of the User
  first_name: string;
  // Unique identifier for the User
  id: number;
  // The initials of the User
  initials: string;
  // The last name of the User
  last_name: string;
  // The full name of the User
  name: string;
  // The primary phone number for the User.
  phone_number: string;
  // Default user activity rate for User.
  rate: number;
  // The subscription type of the User
  // Enum:"Attorney" "NonAttorney"
  subscription_type: ClioSubscriptionType;
  // The selected time zone of the User
  time_zone: string;
  // Array of string
  // An array of roles assigned to this User
  roles: string[];
  // The time the User was created (as a ISO-8601 timestamp)
  created_at: string;

  updated_at: string;
}

export interface ClioContactPhoneNumber {
  name: string;
  number: string;
  default_number: boolean;
}

export interface ClioContactPhoneUpdate {
  phone_numbers: ClioContactPhoneNumber[];
}

export interface ClioClient extends ClioContactPhoneUpdate {
  id: number;
  name: string;
  type: string;
}

export interface ClioMatter {
  id: number;
  display_number: string;
  description: string;
  client: ClioClient;
  updated_at: string;
}

export interface ClioActivity {
  date: string; // ISO-8601
  note: string; // A custom note to describe what the Activity is for.
  non_billable: boolean; // Whether or not this Activity is prevented from appearing as a line item in a bill. Only valid for non-billed TimeEntries.
  user?: { id: number }; // if null the currently authenticated user is used by the API
  communication?: { id: number };
  quantity: number; // in seconds
  matter?: { id: number };
  type: ClioType.TimeEntry;
  activity_description?: { id: number };
}

export interface ClioActivityWithId extends ClioActivity {
  id: number;
}

export interface ClioCommunication {
  date: string;
  body: string;
  subject: string;
  matter?: { id: number };
  external_properties: Array<{ name: string; value: string }>;
  receivers?: Array<{ id: number; type: ClioType }>;
  senders?: Array<{ id: number; type: ClioType }>;
  type: ClioType.PhoneCommunication;
}

export interface ClioCommunicationWithId extends ClioCommunication {
  id: number;
}

export interface ClioRateLimit {
  rateLimit: number;
  rateLimitRemaining: number;
  rateLimitReset: number;
}

export interface ClioRateLimitedResponse<T> {
  data: T;
  rateLimit: ClioRateLimit;
}
