import { PhoneActions, PhoneActionsType } from './phone.actions';
import { PhoneMode } from './phone.model';
import { Entity } from '../models';
import { ContactActionsType, ContactsActions } from '../actions/contacts';
import { ClickToCallActionTypes } from '../constants';
import { ClickToCallActions } from '../calls/click-to-call.action';

export interface PhoneState {
  mode: PhoneMode;
  contactCalled?: Entity;
  selectedEntityId: string;
  selectedPhoneNumber: string;
}

export const INITIAL_STATE: PhoneState = {
  mode: PhoneMode.Dialpad,
  contactCalled: undefined,
  selectedEntityId: '',
  selectedPhoneNumber: '',
};

export const phoneReducer = (
  state = INITIAL_STATE,
  action: PhoneActions | ContactsActions | ClickToCallActions,
): PhoneState => {
  switch (action.type) {
    case PhoneActionsType.CALL: {
      return {
        ...state,
        contactCalled: action.payload.contact,
      };
    }
    case PhoneActionsType.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
        selectedPhoneNumber: '',
        selectedEntityId: '',
      };
    }
    case PhoneActionsType.SET_SELECTED_ENTITY: {
      return {
        ...state,
        selectedEntityId: action.payload.entityId ?? '',
      };
    }
    case PhoneActionsType.SET_SELECTED_PHONE_NUMBER: {
      return {
        ...state,
        selectedPhoneNumber: action.payload,
      };
    }
    case PhoneActionsType.CLEAR: {
      return {
        ...state,
        selectedEntityId: '',
        selectedPhoneNumber: '',
      };
    }
    case PhoneActionsType.RESET_CALLED_CONTACT:
    case ClickToCallActionTypes.CALL_ERROR:
    case ContactActionsType.CONTACT_FOUND:
    case ContactActionsType.CONTACT_NOT_FOUND: {
      return {
        ...state,
        contactCalled: undefined,
      };
    }
  }

  return state;
};

export default phoneReducer;
