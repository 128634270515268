import { makeStyles, createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import React from 'react';
import { ApplicationRouteType } from '../Main.component';
import { BottomMenu } from '../menu/BottomMenu.component';
import { Typography } from '../theme/Typography.component';
import { AppLayoutProps } from './AppLayout.models';
import { AppLogo } from './AppLogo';

const useStyles = makeStyles(() =>
  createStyles({
    appContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '480px',
    },

    appBody: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      maxWidth: '100vw',
    },
    appMenu: { display: 'flex', flexDirection: 'row' },
  }),
);

interface NarrowAppLayoutProps extends AppLayoutProps {
  primaryRoutes: ApplicationRouteType[];
  secondaryRoutes: ApplicationRouteType[];
}

export const NarrowAppLayout: React.FC<NarrowAppLayoutProps> = ({
  title,
  main,
  primaryRoutes,
  secondaryRoutes,
  navigationDisabled,
  renderedPath,
}) => {
  const classes = useStyles();
  return (
    <div data-cy="app-narrow-layout" className={classNames(classes.appContainer)}>
      <div data-cy="page-container" className={classes.appBody}>
        <Box mb={title ? 3 : 0} display="inline-flex" gridGap={8} alignItems="center" component="header" role="banner">
          <AppLogo variant="narrow" />
          {title && (
            <Typography variant="heading-xsmall" data-cy="app-title" tag="h1">
              {title}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexGrow="1" flexDirection="column" component="main" role="main">
          {main}
        </Box>
      </div>
      <nav role="navigation" className={classes.appMenu}>
        <BottomMenu
          primaryRoutes={primaryRoutes}
          secondaryRoutes={secondaryRoutes}
          disabled={navigationDisabled}
          renderedPath={renderedPath}
        />
      </nav>
    </div>
  );
};
