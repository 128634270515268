import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configuration from './config';
import { initializeLogging } from './logging';
import installPolyfills from './polyfills';
import { generateSentryMetadata } from './sentry';

installPolyfills();

initializeLogging(
  configuration.sentry.dsn,
  configuration.sentry.environment,
  configuration.sentry.release,
  generateSentryMetadata,
);

ReactDOM.render(<App />, document.getElementById('root'));
