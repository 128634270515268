import { makeActionCreator } from '../../actions/actionCreators';
import { JiveEntityRelationshipsOneToMany } from './relationshipsOneToMany.reducer';

export enum EntityPhoneNumbersRelationshipActionsType {
  REPLACE = 'jiveEntities/relationships/entityPhoneNumbers/replace',
  CLEAN = 'jiveEntities/relationships/entityPhoneNumbers/clean',
}

export const replaceEntityPhoneNumberRelationship = makeActionCreator(
  EntityPhoneNumbersRelationshipActionsType.REPLACE,
)<JiveEntityRelationshipsOneToMany>();
export const cleanEntityPhoneNumbersRelationship = makeActionCreator(EntityPhoneNumbersRelationshipActionsType.CLEAN)();

export type EntityPhoneNumbersRelationshipActions =
  | ReturnType<typeof replaceEntityPhoneNumberRelationship>
  | ReturnType<typeof cleanEntityPhoneNumbersRelationship>;
