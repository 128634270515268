import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';

export enum RedtailActivityActionsType {
  ADD_ACTIVITY_REQUEST = 'redtail/activity/add/request',
  ADD_ACTIVITY_SUCCESS = 'redtail/activity/success',
  ADD_ACTIVITY_ERROR = 'redtail/activity/error',

  OPEN_ACTIVITY_ERROR = 'redtail/activity/open/error',
}

export const addRedtailActivityRequest = makeActionCreator(RedtailActivityActionsType.ADD_ACTIVITY_REQUEST)();
export const addRedtailActivitySuccess = makeActionCreator(RedtailActivityActionsType.ADD_ACTIVITY_SUCCESS)<
  WithMessage
>();
export const addRedtailActivityError = makeActionCreator(RedtailActivityActionsType.ADD_ACTIVITY_ERROR)<WithMessage>();

export const openRedtailActivityError = makeActionCreator(RedtailActivityActionsType.OPEN_ACTIVITY_ERROR)<
  WithMessage
>();

export type RedtailActivityActions =
  | ReturnType<typeof addRedtailActivityRequest>
  | ReturnType<typeof addRedtailActivitySuccess>
  | ReturnType<typeof addRedtailActivityError>
  | ReturnType<typeof openRedtailActivityError>;
