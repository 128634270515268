import { createStyles, Divider, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AuthButton } from '../authentication/AuthButton.container';
import { SignedInAs } from '../authentication/SignedInAs.container';
import { Line } from '../lines/lines.model';
import { LinesSelect } from './LinesSelect.component';
import { LineOrganization } from './organizations.model';
import { selectLine } from './settings.action';
import SettingsLinkingSection from './SettingsLinkingSection.container';
import { IndexedById } from '../models';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box/Box';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@getgo/chameleon-web/react';
import { LanguageSelector } from './LanguageSelector.component';
import clsx from 'clsx';
import Push from 'push.js';
import { openNotificationPermissionsPage } from '../notificationPermission/notificationPermissions.service';
import { useDispatch } from 'react-redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';
import { SoftphoneSettings } from '../softphone/SoftphoneSettings.component';
import { TestCall } from '../onboarding/testCall/TestCall.component';

const definedMessages = defineMessages({
  SECTION_LINE: {
    id: 'SettingsContainer.SectionLine',
    defaultMessage: 'Line',
  },
  SECTION_NOTIFICATIONS: {
    id: 'SettingsContainer.SectionNotifications',
    defaultMessage: 'Notifications',
  },
  SECTION_LANGUAGE: {
    id: 'SettingsContainer.SectionLanguage',
    defaultMessage: 'Language',
  },
  SECTION_MANAGE_CALLS: {
    id: 'SettingsContainer.SectionManageCalls',
    defaultMessage: 'How to manage your calls?',
  },
  ALLOW_NOTIF_PERMS: {
    id: 'SettingsContainer.AllowNotifPerms',
    defaultMessage: 'Notification permission',
  },
  ALLOW_NOTIF_PERMS_MESSAGE: {
    id: 'SettingsContainer.AllowNotifPermsMessage',
    defaultMessage: 'Notification permission is required to receive call notifications.',
  },
  NOTIFICATION_BUTTON: {
    id: 'SettingsContainer.NotificationButton',
    defaultMessage: 'Enable',
  },
  NOTIFICATION_ENABLED: {
    id: 'SettingsContainer.NotificationEnabled',
    defaultMessage: 'Enabled',
  },
  NOTIFICATION_DISABLED: {
    id: 'SettingsContainer.NotificationDisabled',
    defaultMessage: 'Disabled',
  },
  SIGN_OUT: {
    id: 'SettingsContainer.SignOut',
    defaultMessage: 'Sign out',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      '&&:last-child': {
        flexGrow: 1,
      },
      marginBottom: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
    },
    linkingSection: {
      marginBottom: 10,
    },
    notificationPermissionRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 8,
    },
    notificationPermissionSection: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    labelPlacement: {
      marginLeft: 0,
      marginRight: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

export interface SettingsAccountSectionStateProps {
  selectedLine: LineOrganization;
  lines: IndexedById<Line>;
  isDesktopIntegration: boolean;
  linkingNeededForCurrentIntegration: boolean;
}

export const AccountSettingsComponent: React.FC<SettingsAccountSectionStateProps> = ({
  selectedLine,
  lines,
  isDesktopIntegration,
  linkingNeededForCurrentIntegration,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const linesArray = Object.values(lines);
  const hasPushPermissions = Push.Permission.get() === Push.Permission.GRANTED;
  const isSoftphoneCapable = getActionFacade().isCapable(CAPABILITY_SOFTPHONE);

  useEffect(() => {
    if (linesArray.length > 0 && !selectedLine) {
      dispatch(selectLine(linesArray[0].id));
    }
  }, [dispatch, linesArray, selectedLine]);

  return (
    <>
      <section className={isSoftphoneCapable ? undefined : classes.section}>
        <Box mb={3}>
          <Typography variant="heading-xsmall" id="line-select-label">
            <FormattedMessage {...definedMessages.SECTION_LINE} />
          </Typography>
        </Box>
        <Box mb={3}>
          <LinesSelect />
        </Box>
        {!isSoftphoneCapable && <TestCall showBannerOnSuccess />}
      </section>

      {isSoftphoneCapable && (
        <section className={classes.section}>
          <Box mb={4}>
            <Typography variant="heading-xsmall">
              <FormattedMessage {...definedMessages.SECTION_MANAGE_CALLS} />
            </Typography>

            <Box mt={2}>
              <SoftphoneSettings showBannerOnTestCallSuccess />
            </Box>
          </Box>
          <Divider />
        </section>
      )}

      <section className={classes.section}>
        <Box mb={3}>
          <SignedInAs align="left" />
        </Box>
        <AuthButton />
      </section>

      {!isDesktopIntegration && (
        <section className={classes.section}>
          <div className={classes.notificationPermissionSection}>
            <Box mr={4}>
              <Typography variant="heading-xsmall">
                <FormattedMessage {...definedMessages.SECTION_NOTIFICATIONS} />
              </Typography>
            </Box>
            {!hasPushPermissions ? (
              <>
                <Typography>
                  <FormattedMessage {...definedMessages.NOTIFICATION_DISABLED} />
                </Typography>
                <div className={classes.notificationPermissionRow}>
                  <Typography variant="caption-default-01">
                    <FormattedMessage {...definedMessages.ALLOW_NOTIF_PERMS} />
                  </Typography>
                  <Button variant="primary" onClick={() => openNotificationPermissionsPage()}>
                    <FormattedMessage {...definedMessages.NOTIFICATION_BUTTON} />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography>
                  <FormattedMessage {...definedMessages.NOTIFICATION_ENABLED} />
                </Typography>
              </>
            )}
          </div>
        </section>
      )}
      <section className={classes.section}>
        <Box mb={3} id="language-picker-label">
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.SECTION_LANGUAGE} />
          </Typography>
        </Box>
        <LanguageSelector />
      </section>
      {linkingNeededForCurrentIntegration && (
        <section className={clsx(classes.section, classes.linkingSection)}>
          <SettingsLinkingSection />
        </section>
      )}
    </>
  );
};
