import { NoteActions, NoteActionsType } from '../../notes/notes.actions';
import { Note } from '../../notes/notes.models';
import { RedtailNoteActions, RedtailNoteActionsType } from './redtail.note.action';

export interface RedtailNotesState {
  notes: Note[];
}

export const INITIAL_STATE: RedtailNotesState = {
  notes: [],
};

export const redtailNotesReducer = (
  state = INITIAL_STATE,
  action: NoteActions | RedtailNoteActions,
): RedtailNotesState => {
  switch (action.type) {
    case RedtailNoteActionsType.GET_NOTES_SUCCESS:
      return { ...state, notes: action.payload.notes };
    case NoteActionsType.RESET:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
