import { connect } from 'react-redux';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { clearEntity, debouncedSearchEntity } from './entities.action';
import EntitiesSearch, {
  EntitiesSearchDispatchProps,
  EntitiesSearchProps,
  EntitiesSearchStateProps,
} from './EntitiesSearch.component';

export default connect<EntitiesSearchStateProps, EntitiesSearchDispatchProps, EntitiesSearchProps, AppState>(
  (state) => ({
    integrationName: currentIntegrationNameSelector(state),
  }),
  (dispatch) => ({
    search: (payload) => debouncedSearchEntity(dispatch, payload),
    clearEntity: () => dispatch(clearEntity()),
  }),
)(EntitiesSearch);
