import { Call, CallType } from '@jive/realtime-events';
import { defineMessages, MessageDescriptor } from 'react-intl';

const definedMessages = defineMessages({
  CALL_HISTORY_CALLTYPE_INCOMING: {
    id: 'CallHistory.CallType.Incoming',
    defaultMessage: 'Incoming call',
  },
  CALL_HISTORY_CALLTYPE_OUTGOING: {
    id: 'CallHistory.CallType.Outgoing',
    defaultMessage: 'Outgoing call',
  },
  CALL_HISTORY_CALLTYPE_MISSED: {
    id: 'CallHistory.CallType.Missed',
    defaultMessage: 'Missed call',
  },
  CALL_HISTORY_CALLTYPE_DECLINED: {
    id: 'CallHistory.CallType.Declined',
    defaultMessage: 'Declined call',
  },
});

export function getNameOfTheOtherParty(call: Partial<Call>) {
  const displayname = call.theOtherParty?.name ?? '';

  if (['unknown', 'anonymous', 'unavailable'].includes(displayname.toLowerCase())) {
    return '';
  }

  return displayname;
}

export const getNumberOfTheOtherParty = (call: Partial<Call>) => call.theOtherParty?.number ?? '';

export function getCallCreationDateAndTime(call: Call, language: string): string {
  return call && call.creationTime
    ? new Date(call.creationTime).toLocaleString(language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';
}

export function getCallTypeDescriptor(callType: CallType): MessageDescriptor {
  switch (callType) {
    case CallType.IncomingCall:
      return definedMessages.CALL_HISTORY_CALLTYPE_INCOMING;
    case CallType.OutgoingCall:
      return definedMessages.CALL_HISTORY_CALLTYPE_OUTGOING;
    case CallType.MissedCall:
      return definedMessages.CALL_HISTORY_CALLTYPE_MISSED;
    case CallType.DeclinedCall:
      return definedMessages.CALL_HISTORY_CALLTYPE_DECLINED;
  }
}

export function stringifyCallType(callTypeEnum: CallType): string {
  switch (callTypeEnum) {
    case CallType.IncomingCall:
      return 'Incoming Call';
    case CallType.OutgoingCall:
      return 'Outgoing Call';
    case CallType.MissedCall:
      return 'Missed Call';
    case CallType.DeclinedCall:
      return 'Declined Call';
    default:
      return 'undefined';
  }
}
