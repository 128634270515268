import { createStyles, makeStyles } from '@material-ui/core';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import classNames from 'classnames';
import React from 'react';
import { tokens } from '@getgo/chameleon-core';

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      width: '36px',
      height: '36px',
      backgroundColor: tokens.iconColorBrandDefault,
    },
  }),
);


export const StepsBubble: React.FC<AvatarProps> = ({ children, ...properties }) => {
  const { className, ...props } = properties;
  
  const classes = useStyles();

  return (
    <Avatar className={classNames(classes.avatar, className)} {...props}>
      {children}
    </Avatar>
  );
};
