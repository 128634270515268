import { RepairOrderActions, RepairOrderActionsType } from './repairOrders.actions';
import { ServiceWriter } from './repairOrders.models';
import {
  ClosedRepairOrdersRequestState,
  OpenRepairOrdersRequestState,
  OpenRepairOrder,
  ClosedRepairOrder,
  ClosedRepairOrderDetailsState,
  ClosedRepairOrderDetailsRequestState,
  ServiceWritersRequestState,
} from './repairOrders.models';

export interface RepairOrdersState {
  serviceWritersRequestState: ServiceWritersRequestState;
  serviceWriters?: ServiceWriter[];
  byVIN: {
    [id: string]: {
      openRepairOrdersRequestState?: OpenRepairOrdersRequestState;
      openRepairOrders?: OpenRepairOrder[];
      closedRepairOrdersRequestState?: ClosedRepairOrdersRequestState;
      closedRepairOrders?: ClosedRepairOrder[];
      closedRepairOrderDetails?: ClosedRepairOrderDetailsState;
    };
  };
}

const INITIAL_STATE: RepairOrdersState = {
  serviceWritersRequestState: ServiceWritersRequestState.INITIAL,
  serviceWriters: undefined,
  byVIN: {},
};

export const repairOrdersReducer = (
  state: RepairOrdersState = INITIAL_STATE,
  action: RepairOrderActions,
): RepairOrdersState => {
  switch (action.type) {
    case RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            openRepairOrdersRequestState: OpenRepairOrdersRequestState.LOADING,
            openRepairOrders: [],
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS_SUCCESS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            openRepairOrdersRequestState: OpenRepairOrdersRequestState.LOADED,
            openRepairOrders: action.payload.repairOrders,
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS_ERROR: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            openRepairOrdersRequestState: OpenRepairOrdersRequestState.FAILED,
            openRepairOrders: [],
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrdersRequestState: ClosedRepairOrdersRequestState.LOADING,
            closedRepairOrders: [],
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS_SUCCESS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrdersRequestState: ClosedRepairOrdersRequestState.LOADED,
            closedRepairOrders: action.payload.repairOrders,
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS_ERROR: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrdersRequestState: ClosedRepairOrdersRequestState.FAILED,
            closedRepairOrders: [],
          },
        },
      };
    }

    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrderDetails: {
              ...state.byVIN[action.payload.VIN]?.closedRepairOrderDetails,
              [action.payload.repairOrderNumber]: {
                requestState: ClosedRepairOrderDetailsRequestState.LOADING,
                closedRepairOrderDetail: undefined,
              },
            },
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrderDetails: {
              ...state.byVIN[action.payload.VIN]?.closedRepairOrderDetails,
              [action.payload.repairOrder.repairOrderNumber]: {
                requestState: ClosedRepairOrderDetailsRequestState.LOADED,
                closedRepairOrderDetail: action.payload.repairOrder,
              },
            },
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS_ERROR: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            ...state.byVIN[action.payload.VIN],
            closedRepairOrderDetails: {
              ...state.byVIN[action.payload.VIN]?.closedRepairOrderDetails,
              [action.payload.repairOrderNumber]: {
                requestState: ClosedRepairOrderDetailsRequestState.FAILED,
                closedRepairOrderDetail: undefined,
              },
            },
          },
        },
      };
    }
    case RepairOrderActionsType.LOAD_SERVICE_WRITERS: {
      return {
        ...state,
        serviceWritersRequestState: ServiceWritersRequestState.LOADING,
        serviceWriters: undefined,
      };
    }
    case RepairOrderActionsType.LOAD_SERVICE_WRITERS_SUCCESS: {
      return {
        ...state,
        serviceWritersRequestState: ServiceWritersRequestState.LOADED,
        serviceWriters: action.payload.serviceWriters,
      };
    }
    case RepairOrderActionsType.LOAD_SERVICE_WRITERS_ERROR: {
      return {
        ...state,
        serviceWritersRequestState: ServiceWritersRequestState.FAILED,
        serviceWriters: undefined,
      };
    }
    default:
      return state;
  }
};
