import { Reducer } from 'redux';
import { sendLogMessagesRequestSuccessAction } from '../conversations/conversationMessages.actions';
import { loadConversationsAction } from '../conversations/conversations.actions';

type IsoDateString = string;

interface MessagingExperience {
  firstVisit?: IsoDateString;
  firstMessageLog?: IsoDateString;
  numberOfLoggedMessages: number;
  numberOfLogs: number;
}

interface SurveyState {
  messagingExperience: MessagingExperience;
}

const INITIAL_STATE: SurveyState = {
  messagingExperience: {
    numberOfLoggedMessages: 0,
    numberOfLogs: 0,
  },
};

type SurveyActions = ReturnType<typeof sendLogMessagesRequestSuccessAction | typeof loadConversationsAction>;

export const surveyReducer: Reducer<SurveyState, SurveyActions> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sendLogMessagesRequestSuccessAction.type:
      return {
        ...state,
        messagingExperience: {
          ...state.messagingExperience,
          firstMessageLog: state.messagingExperience.firstMessageLog ?? new Date().toISOString(),
          numberOfLoggedMessages: state.messagingExperience.numberOfLoggedMessages + action.payload,
          numberOfLogs: state.messagingExperience.numberOfLogs + 1,
        },
      };
    case loadConversationsAction.type:
      if (state.messagingExperience.firstVisit) {
        return state;
      }

      return {
        ...state,
        messagingExperience: {
          ...state.messagingExperience,
          firstVisit: new Date().toISOString(),
        },
      };
  }

  return state;
};
