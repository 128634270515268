import { useLayoutEffect, useState } from 'react';

export function useWideIntegrationDetector() {
  const [isWideIntegration, setIsWideIntegration] = useState(window.innerWidth > 364);
  useLayoutEffect(() => {
    function updateValue() {
      setIsWideIntegration(window.innerWidth > 364);
    }
    window.addEventListener('resize', updateValue);
    updateValue();
    return () => window.removeEventListener('resize', updateValue);
  }, []);
  return isWideIntegration;
}
