import { Call } from '@jive/realtime-events';
import { makeStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { getCallDetails, getCallLogDescriptionMessageDescriptors } from './callLogger.utilities';
import { Typography } from '../theme/Typography.component';
import { ExtendedTheme } from '@getgo/chameleon-material-ui';
import { useSelector } from 'react-redux';
import { selectedDateFnsLocaleSelector } from '../settings/settings.selector';

const definedMessages = defineMessages({
  CALL_DETAILS_HEADER: {
    id: 'CallDetails.Header',
    defaultMessage: 'Call Details',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  list: {
    padding: theme.spacing(0, 0, 0, 2),
    margin: theme.spacing(0, 0, 0, 2),
  },
  listItem: {
    '&::marker': {
      color: tokens.interactive01,
    },
  },
  callDetails: {
    whiteSpace: 'pre-wrap',
  },
}));

export interface CallDetailsProps {
  call: Call;
  variant?: 'short' | 'long';
  size?: 'small' | 'normal';
  className?: string;
}

export const CallDetails: React.FC<CallDetailsProps> = ({ call, variant, size, className }) => {
  const styles = useStyles();
  const intl = useIntl();
  const locale = useSelector(selectedDateFnsLocaleSelector);
  const selectedVariant = variant ?? 'long';
  const textVariant = (size ?? 'small') === 'small' ? 'caption-small-01' : 'caption-default-01';

  if (selectedVariant === 'long') {
    return (
      <div className={className}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.CALL_DETAILS_HEADER} />
        </Typography>
        <Typography variant={textVariant} className={styles.callDetails}>
          {getCallDetails({ call, intl, locale, lineBreak: '\n' })}
        </Typography>
      </div>
    );
  } else {
    const { start, end, duration } = getCallLogDescriptionMessageDescriptors(call, locale);
    return (
      <div className={className}>
        <div className={styles.callDetails}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <Typography variant={textVariant}>
                <FormattedMessage {...start} />
              </Typography>
            </li>
            <li className={styles.listItem}>
              <Typography variant={textVariant}>
                <FormattedMessage {...end} />
              </Typography>
            </li>
            <li className={styles.listItem}>
              <Typography variant={textVariant}>
                <FormattedMessage {...duration} />
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    );
  }
};
