import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';
import InlineActions from '../components/InlineActions.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  ACTIONS: {
    id: 'Call.Actions',
    defaultMessage: 'Actions',
  },
});

interface ContactCardProps {
  actions: ReactNode[];
}

export const CallActionsBox: React.FC<ContactCardProps> = ({ actions }) => {
  return (
    <Box component="div" mb={6}>
      <Box mb={3}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.ACTIONS} />
        </Typography>
      </Box>
      <div>
        <InlineActions actions={actions} />
      </div>
    </Box>
  );
};
