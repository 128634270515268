import { ActionFacade } from '../actionFacade/action.facade';
import { CAPABILITY_VEHICLES, VehiclesCapableFacade } from '../actionFacade/action.facade.capabilities';
import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { VehiclesActionCreator } from './vehicles/vehicles.actionCreator';
import { ServiceAppointmentsActionCreator } from './serviceAppointments/serviceAppointments.actionCreator';
import CoxActionCreatorFactory from './cox.actionCreator.factory';
import { CoxContactActionCreator } from './contact/cox.contact.actioncreator';
import { RepairOrdersActionCreator } from './repairOrders/repairOrders.actionCreator';
import { PartsActionCreator } from './parts/parts.actionCreator';

export class CoxActionFacade extends ActionFacade implements VehiclesCapableFacade {
  [CAPABILITY_VEHICLES]: true = true;

  private vehiclesActionCreator: VehiclesActionCreator;
  private serviceAppointmentsActionCreator: ServiceAppointmentsActionCreator;
  private repairOrdersActionCreator: RepairOrdersActionCreator;
  private partsActionCreator: PartsActionCreator;

  constructor(actionCreatorFactory: ActionCreatorFactory) {
    super(actionCreatorFactory);
    this.vehiclesActionCreator = (actionCreatorFactory as CoxActionCreatorFactory).createVehiclesActionCreator();
    this.serviceAppointmentsActionCreator = (actionCreatorFactory as CoxActionCreatorFactory).createServiceAppointmentsActionCreator();
    this.repairOrdersActionCreator = (actionCreatorFactory as CoxActionCreatorFactory).createRepairOrdersActionCreator();
    this.partsActionCreator = (actionCreatorFactory as CoxActionCreatorFactory).createPartsActionCreator();
  }

  loadCustomer(customerNumber: string): Promise<void> {
    return (this.contactActionCreator as CoxContactActionCreator).loadCustomerById(customerNumber);
  }

  loadVehicles(VINs: string[]): Promise<void> {
    return this.vehiclesActionCreator.loadVehicles(VINs);
  }

  loadServiceAppointmentsForVehicle(VIN: string): Promise<void> {
    return this.serviceAppointmentsActionCreator.loadServiceAppointmentsForVehicle(VIN);
  }

  loadOpenRepairOrdersForVehicles(VINs: string[]): Promise<void> {
    return this.repairOrdersActionCreator.loadOpenRepairOrdersForVehicles(VINs);
  }

  loadClosedRepairOrdersForVehicle(VIN: string): Promise<void> {
    return this.repairOrdersActionCreator.loadClosedRepairOrdersForVehicle(VIN);
  }

  loadClosedRepairOrderDetails(VIN: string, repairOrderNumber: string): Promise<void> {
    return this.repairOrdersActionCreator.loadClosedRepairOrderDetails(VIN, repairOrderNumber);
  }

  loadPart(partNumber: string, manufacturer: string): Promise<void> {
    return this.partsActionCreator.loadPart(partNumber, manufacturer);
  }

  loadServiceWriters(): Promise<void> {
    return this.repairOrdersActionCreator.loadServiceWriters();
  }
}
