import { connect } from 'react-redux';
import {
  OpenRepairOrdersComponent,
  OpenRepairOrdersProps,
  OpenRepairOrdersStateProps,
} from './OpenRepairOrders.component';
import { AppState } from '../../reducers/index';
import {
  openRepairOrderByVINSelector,
  openRepairOrdersLoadRequestStateByVINSelector,
  serviceWritersRequestStateSelector,
  serviceWritersSelector,
} from './repairOrders.selectors';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';

const mapStateToProps = (state: AppState, { VIN }: OpenRepairOrdersProps): OpenRepairOrdersStateProps => ({
  requestState: openRepairOrdersLoadRequestStateByVINSelector(VIN)(state),
  openRepairOrders: openRepairOrderByVINSelector(VIN)(state),
  locale: selectedDateFnsLocaleSelector(state),
  serviceWritersRequestState: serviceWritersRequestStateSelector(state),
  serviceWriters: serviceWritersSelector(state),
});

export const OpenRepairOrders = connect<OpenRepairOrdersStateProps, {}, OpenRepairOrdersProps, AppState>(
  mapStateToProps,
)(OpenRepairOrdersComponent);
