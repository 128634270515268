import { Reducer } from 'redux';
import { Communication } from '../clio.models';
import { SavedCommunicationActions, SavedCommunicationActionTypes } from './saved-communication.action';

export interface SavedCommunicationState {
  [callId: string]: Communication | undefined;
}

export const SAVED_COMMUNICATION_INITIAL_STATE = {};

export const savedCommunicationReducer: Reducer<SavedCommunicationState, SavedCommunicationActions> = (
  state = SAVED_COMMUNICATION_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SavedCommunicationActionTypes.GET_SAVED_COMMUNICATION_SUCCESS:
      return {
        ...state,
        [action.payload.callId]: action.payload.communication,
      };

    default:
      return state;
  }
};
