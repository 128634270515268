import { Store } from 'redux';
import { AppState } from '../../../reducers';
import { OpenCrmFormModel } from './crmForm.model';
import { logger } from '../../../logging';
import { createEntityError, createEntityFormOpened } from '../../../search/entities.action';
import { defineMessages } from 'react-intl';

const definedMessages = defineMessages({
  CREATE_ENTITY_ERROR: {
    id: 'Create.Entity.Error',
    defaultMessage: 'An error has occurred',
    description: 'error message entity create',
  },
});

export abstract class CrmFormActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    try {
      await this._openFormInCrm(model);
      this.store.dispatch(createEntityFormOpened());
    } catch (e) {
      logger.error('Could not open entity form in CRM', e);
      this.store.dispatch(
        createEntityError({
          message: definedMessages.CREATE_ENTITY_ERROR,
        }),
      );
    }
  }

  protected abstract _openFormInCrm(model: OpenCrmFormModel): Promise<void>;
}
