import { Box } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TrackedButton from '../analytics/TrackedButton.container';
import { TrackingEventLabels, TrackingEventNames } from '../analytics/tracker.models';
import { Banner } from '../inAppNotification/Banner';
import { definedMessages } from '../inAppNotification/message.content';
import { openGoToConnectApp } from '../utils';

export interface ClickToCallErrorMessageDispatchProps {
  onClose(): void;
}

export interface ClickToCallErrorMessageStateProps {
  isDesktopIntegration: boolean;
}

export const ClickToCallErrorMessageComponent: React.FC<ClickToCallErrorMessageStateProps &
  ClickToCallErrorMessageDispatchProps> = ({ onClose, isDesktopIntegration }) => {
  function openGoToConnectAndCloseMessage() {
    const features = isDesktopIntegration ? ['openInDefaultBrowser'] : [];
    openGoToConnectApp(features);
    onClose();
  }

  const Message = () => (
    <>
      <Box fontWeight="bold">
        <FormattedMessage {...definedMessages.CLICK_TO_CALL_ERROR_TITLE} />
      </Box>
      <Box>
        <FormattedMessage {...definedMessages.CLICK_TO_CALL_ERROR_MESSAGE} />
      </Box>
    </>
  );

  const Actions = () => (
    <TrackedButton
      inverse
      color="default"
      variant="outlined"
      size="small"
      eventLabel={TrackingEventLabels.ClickToCall}
      eventName={TrackingEventNames.OpenGoToConnectSuccess}
      onClick={openGoToConnectAndCloseMessage}
    >
      <FormattedMessage {...definedMessages.OPEN_APP_BUTTON_LABEL} />
    </TrackedButton>
  );

  return <Banner variant="danger" isClosable={true} onClose={onClose} message={<Message />} actions={<Actions />} />;
};
