import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Box } from '@material-ui/core';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { Banner } from '../../inAppNotification/Banner';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';

export interface CallDispositionErrorMessageStateProps {
  isDesktopIntegration: boolean;
}

export interface CallDispositionErrorMessageDispatchProps {
  onClose(): void;
}

const definedMessages = defineMessages({
  TITLE: {
    id: 'Salesforce.CallDisposition.Snackbar.SetupError.Title',
    defaultMessage: 'Set up call dispositions with your admin',
  },
  MESSAGE: {
    id: 'Salesforce.CallDisposition.Snackbar.SetupError.Message',
    defaultMessage: 'Your Salesforce admin needs to set this up.',
  },
  LINK: {
    id: 'Salesforce.CallDisposition.Snackbar.SetupError.Link',
    defaultMessage: 'More about call dispositions',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_DISPOSITION_ERROR_MESSAGE_SHOWN: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.PopupShown,
    label: 'Set up call disposition with your admin',
  },
  CALL_DISPOSITION_ERROR_MESSAGE_CLOSE_CLICKED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Call disposition alert | Close | button',
  },
  CALL_DISPOSITION_ERROR_MESSAGE_LINK_CLICKED: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Call disposition alert | More about call dispositions | link',
  },
});

export const CallDispositionErrorMessageComponent: React.FC<CallDispositionErrorMessageStateProps &
  CallDispositionErrorMessageDispatchProps> = ({ isDesktopIntegration, onClose }) => {
  useEffect(() => {
    newTracker.trackAnalyticsEvent(trackingEvents.CALL_DISPOSITION_ERROR_MESSAGE_SHOWN);
  }, []);

  function openSupportPageAndCloseMessage(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    const features = isDesktopIntegration ? 'openInDefaultBrowser' : undefined;
    window.open(
      'https://support.goto.com/connect/help/how-do-i-set-up-custom-call-dispositions-in-the-salesforce-integration',
      '_blank',
      features,
    );
    onClose();
  }

  const Message = () => (
    <>
      <Box fontWeight="bold">
        <FormattedMessage {...definedMessages.TITLE} />
      </Box>
      <Box>
        <FormattedMessage {...definedMessages.MESSAGE} />
      </Box>
    </>
  );

  const Actions = () => (
    <TrackedButton
      variant="neutral-inverse"
      size="small"
      color="default"
      onClick={openSupportPageAndCloseMessage}
      trackingEvent={trackingEvents.CALL_DISPOSITION_ERROR_MESSAGE_LINK_CLICKED}
    >
      <FormattedMessage {...definedMessages.LINK} />
    </TrackedButton>
  );

  return (
    <Banner
      variant="danger"
      isClosable={true}
      onClose={() => {
        newTracker.trackAnalyticsEvent(trackingEvents.CALL_DISPOSITION_ERROR_MESSAGE_CLOSE_CLICKED);
        onClose();
      }}
      message={<Message />}
      actions={<Actions />}
    />
  );
};
