import { Store } from 'redux';
import { AppState } from '../../reducers';
import { contactMatchingRequested } from '../../actions/contacts';
import { showMessage } from '../../inAppNotification/message.action';
import { MessageVariant } from '../../models';
import { defineMessages } from 'react-intl';
import { isIntegrationLinkedIfNecessarySelector } from '../../integrations/integrations.selector';
import { Call, CallType } from '@jive/realtime-events';
import { newTracker } from '../../analytics-new/tracker-new';
import {
  AnalyticsAction,
  AnalyticsCategory,
  defineTrackingEvents,
  TrackingEvent,
} from '../../analytics-new/analytics.models';
import { isSoftphoneCapableAndEnabledSelector } from '../../settings/settings.selector';

const CALL_SYNC_PREFIX = 'call_analytics_sync';

const SCREENPOP_MESSAGE_ID = 'screen-pop-in-app-notification';

const definedMessages = defineMessages({
  UPDATE_ACTIVE_CALL_WARNING: {
    id: 'Update.Active.Call.Warning',
    defaultMessage: 'Switch to new call',
    description: 'warning message update active call',
  },
});

const trackingEvents = defineTrackingEvents({
  SHOW_INCOMING_CALL_NOTIFICATION: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.PopupShown,
    label: 'Incoming call',
  },
  SHOW_OUTGOING_CALL_NOTIFICATION: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.PopupShown,
    label: 'Outgoing call',
  },
});

const callNotificationTrackingEvents: Partial<Record<CallType, TrackingEvent>> = {
  [CallType.IncomingCall]: trackingEvents.SHOW_INCOMING_CALL_NOTIFICATION,
  [CallType.OutgoingCall]: trackingEvents.SHOW_OUTGOING_CALL_NOTIFICATION,
};

export class ScreenpopActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async createScreenpop(call: Call): Promise<void> {
    const isLinkedIfNecessary = isIntegrationLinkedIfNecessarySelector(this.store.getState());
    if (!isLinkedIfNecessary) {
      return;
    }

    this.store.dispatch(contactMatchingRequested({ callId: call.id }));
    this.store.dispatch(
      showMessage({
        id: SCREENPOP_MESSAGE_ID,
        type: MessageVariant.CallInfo,
        message: definedMessages.UPDATE_ACTIVE_CALL_WARNING,
        params: {
          autoHide: false,
          entityId: call.id,
        },
      }),
    );

    void this.trackCall(call);
  }

  private async trackCall(call: Call) {
    const trackingEvent = callNotificationTrackingEvents[call.type];
    if (!trackingEvent) {
      return;
    }

    const isSoftphoneEnabled = isSoftphoneCapableAndEnabledSelector(this.store.getState());
    if (!isSoftphoneEnabled) {
      return newTracker.trackAnalyticsEventOnce(trackingEvent, CALL_SYNC_PREFIX, call.id);
    } else {
      return newTracker.trackAnalyticsEventOnce(
        { ...trackingEvent, label: `${trackingEvent.label} | softphone` },
        CALL_SYNC_PREFIX,
        call.id,
      );
    }
  }
}
