import { CallType } from '@jive/realtime-events';

interface CallDuration {
  creationTime: number;
  endTime?: number;
  duration?: number;
}
interface CallWithDuration extends CallDuration {
  type: CallType;
}

type CallDurationFormat = 'hhmmss' | 'mmss';

const leftPad = (digits: number): string => digits.toString().padStart(2, '0');

const transformTohhmmss = (totalSeconds: number): string | undefined => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);

  let result: string | undefined;
  if (!(isNaN(hours) || isNaN(minutes) || isNaN(seconds))) {
    const parts: string[] = [];

    parts.push(leftPad(hours));
    parts.push(leftPad(minutes));
    parts.push(leftPad(seconds));

    result = parts.join(':');
  }

  return result;
};

const transformTommss = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${leftPad(minutes)}:${leftPad(seconds)}`;
};

const formatDuration = (durationSeconds: number, format: CallDurationFormat) => {
  const duration = format === 'hhmmss' ? transformTohhmmss(durationSeconds) : transformTommss(durationSeconds);

  if (!duration) {
    return '';
  }

  return duration.length > 8 ? duration.slice(0, -(duration.length - 8)) : duration;
};

const getDurationDisplay = (call: CallWithDuration, format: CallDurationFormat = 'hhmmss'): string => {
  const seconds = getTotalSeconds(call);
  return formatDuration(seconds, format);
};

export const getCallsDurationDisplay = (calls: CallWithDuration[], format: CallDurationFormat = 'hhmmss'): string => {
  const seconds = calls.reduce((sum, call) => sum + getTotalSeconds(call), 0);
  return formatDuration(seconds, format);
};

function getTotalSeconds(call: CallWithDuration): number {
  if (call.duration) {
    return call.duration;
  }

  const [startTime, endTime] = [call.creationTime, call.endTime || Date.now()];
  return call.type === CallType.MissedCall || call.type === CallType.DeclinedCall ? 0 : (endTime - startTime) / 1000;
}

export default getDurationDisplay;
