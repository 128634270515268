import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { SaveCommunicationPayload } from './communication.action';
import { NewCommunicationState } from './communication.reducer';

const clioNewCommunicationsSelector = (state: AppState): NewCommunicationState =>
  state.clio && state.clio.communicationPayload;

export const storedCommunicationSelector: (state: AppState, callId: string) => SaveCommunicationPayload | undefined =
  createSelector(
    clioNewCommunicationsSelector,
    (_, callId: string) => callId,
    (communicationPayloads, callId) => communicationPayloads && communicationPayloads[callId],
  );
