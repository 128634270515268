export enum ZendeskEntityType {
  Ticket = 'ticket',
  User = 'user',
}

// https://developer.zendesk.com/rest_api/docs/support/user_fields
export interface ZendeskUser {
  id: number;
  name: string;
  phone: string;
  type: ZendeskEntityType.User;
}

export interface ZendeskIdentity {
  url: string;
  id: number;
  user_id: number;
  type: 'phone_number' | 'email' | 'twitter' | 'facebook' | 'google' | 'agent_forwarding' | 'sdk';
  value: string;
  verified: boolean;
  primary: boolean;
  created_at: string;
  updated_at: string;
}

// https://developer.zendesk.com/rest_api/docs/support/ticket_fields
export interface ZendeskTicket {
  id: number;
  subject: string;
  type: ZendeskEntityType.Ticket;
}

export type ZendeskEntity = ZendeskUser | ZendeskTicket;

// https://developer.zendesk.com/rest_api/docs/support/search#json-format
export interface ZendeskSearchResult<T extends ZendeskEntity> {
  results: T[];
  count: number;
}

export interface ZendeskVia {
  channel: string | number;
  source: {
    to: any;
    from: any;
  };
  rel: string;
}

// https://developer.zendesk.com/documentation/ticketing/reference-guides/conditions-reference/#via-types
// api_phone_call_inbound = 45
// api_phone_call_outbound = 46

export enum ZendeskViaType {
  Incoming = 45,
  Outgoing = 46,
}

export type ZendeskTicketStatus = 'new' | 'open' | 'pending' | 'hold' | 'solved' | 'closed';

export interface CreateTicketParams {
  userId?: string;
  subject: string;
  description: string | undefined;
  via?: ZendeskVia;
  isCommentPublic?: boolean;
  ticketStatus?: ZendeskTicketStatus;
}

export interface CreateTicketCommentParams {
  ticketId: string;
  authorId?: string;
  comment: string;
  isPublic?: boolean;
}

export interface ZendeskTicketSearchResultItem extends ZendeskTicket {
  searchResultType: 'related' | 'other';
}
