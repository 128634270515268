import {
  AccessibilityIcon,
  AddPersonInactiveIcon,
  AddressBookContactsActiveIcon,
  BusinessActiveIcon,
  ExternalLinkIcon,
  BulbInactiveIcon,
} from '@getgo/chameleon-icons/react';
import { Box } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnalyticsAction,
  AnalyticsCategory,
  defineTrackingEvents,
  TrackingEvent,
} from '../analytics-new/analytics.models';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { goToCallActionRoute } from '../browserHistory';
import { CallActionRoutes, EntityType } from '../constants';
import { Integrations } from '../models';
import { AppState } from '../reducers';
import { hasAccessToEntity } from '../salesforce/salesforce.selectors';
import { createEntity, openEntity } from '../search/entities.action';
import { stringifyCallType } from './call.helper';
import { CallWithContact } from './calls.reducer';
import { ContactMatchType, getEntityInCallSelector } from './calls.selector';

export interface ScreenPopCallActionsProps {
  currentIntegration: Integrations;
  matchType: ContactMatchType;
  call: CallWithContact;
  switchActiveCall: () => void;
  closeScreenPop: () => void;
}

interface ScreenPopAction {
  text: MessageDescriptor;
  method: () => void;
  icon: JSX.Element;
  trackingEvent: TrackingEvent;
  shouldDisplay?: boolean;
}

const definedMessages = defineMessages({
  CREATE_CONTACT: {
    id: 'Screenpop.Action.CreateNewContact',
    defaultMessage: 'Create new contact',
  },
  ASSIGN_TO_EXISTING: {
    id: 'Screenpop.Action.AssignToExisting',
    defaultMessage: 'Assign to existing contact',
  },
  CREATE_NEW_LEAD: {
    id: 'Screenpop.Action.CreateNewLead',
    defaultMessage: 'Create new lead',
  },
  CREATE_NEW_ACCOUNT: {
    id: 'Screenpop.Action.CreateNewAccount',
    defaultMessage: 'Create new account',
  },
  OPEN_IN_CRM: {
    id: 'SearchResult.OpenInCRM',
    defaultMessage: 'Open contact in CRM',
    description: 'Open contact in the CRM',
  },
  CREATE_NEW_OPPORTUNITY: {
    id: 'ScreenPop.Action.CreateNewOpportunity',
    defaultMessage: 'Create new opportunity',
  },
});

const getTrackingEvents = (call: CallWithContact, matchType: ContactMatchType) =>
  defineTrackingEvents({
    CREATE_CONTACT_CLICKED: {
      category: AnalyticsCategory.Call,
      action: AnalyticsAction.ItemClicked,
      label: 'Create contact | button | Screenpop',
    },
    NEW_ACCOUNT_CLICKED: {
      category: AnalyticsCategory.Call,
      action: AnalyticsAction.ItemClicked,
      label: 'New account | button | Screenpop',
    },
    NEW_LEAD_CLICKED: {
      category: AnalyticsCategory.Call,
      action: AnalyticsAction.ItemClicked,
      label: 'New lead | button | Screenpop',
    },
    NEW_OPPORTUNITY_CLICKED: {
      category: AnalyticsCategory.Call,
      action: AnalyticsAction.ItemClicked,
      label: 'New opportunity | button | Screenpop',
    },
    OPEN_CONTACT_IN_CRM_CLICKED: {
      category: AnalyticsCategory.Contact,
      action: AnalyticsAction.ItemClicked,
      label: `Open contact in CRM | button | section screenpop${call.type ? ` | ${call.type}` : ''}`,
    },
    ASSIGN_CONTACT_TO_CALL_CLICKED: {
      category: AnalyticsCategory.Contact,
      action: AnalyticsAction.ItemClicked,
      label: `Assign contact to call | menuitem | ${stringifyCallType(call.type)} | ${matchType} | Screenpop`,
    },
  });

export const ScreenPopCallActions: React.FC<ScreenPopCallActionsProps> = ({
  currentIntegration,
  matchType,
  call,
  switchActiveCall,
  closeScreenPop,
}) => {
  const dispatch = useDispatch();
  const entity = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));
  const hasSalesforceLeadAccess = useSelector(hasAccessToEntity(EntityType.LEAD));

  const trackingEvents = getTrackingEvents(call, matchType);

  const createEntityForCall = (type: EntityType) =>
    dispatch(
      createEntity({
        isActiveCall: true,
        entityName: type,
        defaultFieldValues: { Phone: call.theOtherParty.number },
        callId: call.id,
      }),
    );

  const navigateAfterAction = (action: () => void) => () => {
    action();
    switchActiveCall();
  };

  const closeAfterAction = (action: () => void) => () => {
    action();
    closeScreenPop();
  };

  const salesforceActions = [
    {
      text: definedMessages.CREATE_CONTACT,
      method: navigateAfterAction(() => createEntityForCall(EntityType.CONTACT)),
      icon: <AddPersonInactiveIcon />,
      trackingEvent: trackingEvents.CREATE_CONTACT_CLICKED,
    },
    {
      text: definedMessages.CREATE_NEW_ACCOUNT,
      method: navigateAfterAction(() => createEntityForCall(EntityType.ACCOUNT)),
      icon: <BusinessActiveIcon />,
      trackingEvent: trackingEvents.NEW_ACCOUNT_CLICKED,
    },
    {
      text: definedMessages.CREATE_NEW_LEAD,
      method: navigateAfterAction(() => createEntityForCall(EntityType.LEAD)),
      icon: <AccessibilityIcon />,
      trackingEvent: trackingEvents.NEW_LEAD_CLICKED,
      shouldDisplay: hasSalesforceLeadAccess,
    },
    {
      text: definedMessages.ASSIGN_TO_EXISTING,
      method: () => switchActiveCall(),
      icon: <AddressBookContactsActiveIcon />,
      trackingEvent: trackingEvents.ASSIGN_CONTACT_TO_CALL_CLICKED,
    },
  ];

  const actions = {
    [ContactMatchType.NoMatch]: {
      [Integrations.Clio]: [
        {
          text: definedMessages.CREATE_CONTACT,
          method: closeAfterAction(() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])),
          icon: <AddPersonInactiveIcon />,
          trackingEvent: trackingEvents.CREATE_CONTACT_CLICKED,
        },
      ],
      [Integrations.SalesforceLightning]: salesforceActions,
      [Integrations.SalesforceClassic]: salesforceActions,
      [Integrations.Redtail]: [
        {
          text: definedMessages.CREATE_CONTACT,
          method: closeAfterAction(() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])),
          icon: <AddPersonInactiveIcon />,
          trackingEvent: trackingEvents.CREATE_CONTACT_CLICKED,
        },
      ],
      [Integrations.Zendesk]: [],
      [Integrations.ConnectWise]: [
        {
          text: definedMessages.CREATE_CONTACT,
          method: closeAfterAction(() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])),
          icon: <AddPersonInactiveIcon />,
          trackingEvent: trackingEvents.CREATE_CONTACT_CLICKED,
        },
        {
          text: definedMessages.CREATE_NEW_OPPORTUNITY,
          method: navigateAfterAction(() => createEntityForCall(EntityType.CONNECTWISE_OPPORTUNITY)),
          icon: <BulbInactiveIcon />,
          trackingEvent: trackingEvents.NEW_OPPORTUNITY_CLICKED,
        },
      ],
      // Currently we don't have actions for cox so we just show the "Show more" button.
      [Integrations.Cox]: [],
      [Integrations.Dynamics]: [
        {
          text: definedMessages.CREATE_CONTACT,
          method: closeAfterAction(() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.CONTACT])),
          icon: <AddPersonInactiveIcon />,
          trackingEvent: trackingEvents.CREATE_CONTACT_CLICKED,
        },
        {
          text: definedMessages.CREATE_NEW_LEAD,
          method: closeAfterAction(() => goToCallActionRoute(call.id, CallActionRoutes[EntityType.LEAD])),
          icon: <AccessibilityIcon />,
          trackingEvent: trackingEvents.NEW_LEAD_CLICKED,
        },
      ],
    },
    [ContactMatchType.MultipleMatch]: [
      {
        text: definedMessages.ASSIGN_TO_EXISTING,
        method: () => switchActiveCall(),
        icon: <AddressBookContactsActiveIcon />,
        trackingEvent: trackingEvents.ASSIGN_CONTACT_TO_CALL_CLICKED,
      },
    ],
    [ContactMatchType.SingleMatch]: [
      {
        text: definedMessages.OPEN_IN_CRM,
        method: navigateAfterAction(() => dispatch(openEntity({ entity: entity! }))),
        icon: <ExternalLinkIcon />,
        trackingEvent: trackingEvents.OPEN_CONTACT_IN_CRM_CLICKED,
      },
    ],
  };

  const actionList: ScreenPopAction[] =
    matchType === ContactMatchType.NoMatch ? actions[matchType][currentIntegration] : actions[matchType];

  return (
    <>
      {actionList.map((action, index) => {
        if (action?.shouldDisplay === false) {
          return null;
        } else {
          return (
            <Box my={2} key={index}>
              <TrackedButton
                trackingEvent={action.trackingEvent}
                size="small"
                variant="secondary"
                fullwidth
                onClick={action.method}
                leadingIcon={action.icon}
              >
                <FormattedMessage {...action.text} />
              </TrackedButton>
            </Box>
          );
        }
      })}
    </>
  );
};
