import { Vehicle } from './vehicles.models';
import React from 'react';
import { defineMessages } from 'react-intl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { DealerTrackEntitiesSettings } from '../settings/cox.settings.models';
import { toDateStringOrDefault, toLocaleStringOrDefault } from '../cox.utils';
import { Parts } from '../parts/Parts.container';
import { VehicleStatus } from './VehicleStatus.container';
import { CommuterModeIcon } from '@getgo/chameleon-icons/react';
import { ServiceListsComponent } from '../servicelists/ServiceLists.component';
import { Typography } from '../../theme/Typography.component';
import { DataRow } from '../DataRow.component';
import { Box, SvgIcon } from '@material-ui/core';

const definedMessages = defineMessages({
  VEHICLE_COST: {
    id: 'Cox.Vehicles.Detail.VehicleCost',
    defaultMessage: 'Value:',
  },
  ODOMETER: {
    id: 'Cox.Vehicles.Detail.Odometer',
    defaultMessage: 'Odometer:',
  },
  DATE_DELIVERED: {
    id: 'Cox.Vehicles.Detail.DateDelivered',
    defaultMessage: 'Purchased:',
  },
  LAST_SERVICE_DATE: {
    id: 'Cox.Vehicles.Detail.LastServiceDate',
    defaultMessage: 'Last service:',
  },
  WARRANTY_MONTHS: {
    id: 'Cox.Vehicles.Detail.WarrantyMonths',
    defaultMessage: 'Warranty months:',
  },
  WARRANTY_MILES: {
    id: 'Cox.Vehicles.Detail.WarrantyMiles',
    defaultMessage: 'Warranty miles:',
  },
  STATUS: {
    id: 'Cox.Vehicles.Detail.Status',
    defaultMessage: 'Status:',
  },
});

export interface VehiclesProps {
  dealerTrackSettings: DealerTrackEntitiesSettings;
  vehicle: Vehicle;
  locale: Locale;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vehicleListItemRoot: {
      display: 'flex',
      borderTop: `solid ${tokens.border} 1px`,
      padding: theme.spacing(4, 0, 0, 0),
      '&:first-of-type': {
        borderTop: 'none',
      },
    },
    vehicleDetails: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
  }),
);

export const VehicleComponent: React.FC<VehiclesProps> = ({
  vehicle,
  locale,
  dealerTrackSettings: dealertrackSettings,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.vehicleListItemRoot}>
      <Box mr={2}>
        <SvgIcon color="primary">
          <CommuterModeIcon />
        </SvgIcon>
      </Box>
      <div className={classes.vehicleDetails}>
        <Typography variant="body-small">
          <b style={{ lineHeight: '24px' }}>
            {vehicle.Make} {vehicle.Model} {vehicle.ModelYear}
          </b>
        </Typography>
        <Box mb={4}>
          <DataRow label={definedMessages.STATUS} value={<VehicleStatus vehicle={vehicle} />} />
          <DataRow
            label={definedMessages.VEHICLE_COST}
            value={`$${toLocaleStringOrDefault(vehicle.VehicleCost, locale)}`}
          />
          <DataRow label={definedMessages.ODOMETER} value={toLocaleStringOrDefault(vehicle.Odometer, locale)} />
          <DataRow
            label={definedMessages.DATE_DELIVERED}
            value={toDateStringOrDefault(vehicle.DateDelivered, locale)}
          />
          <DataRow
            label={definedMessages.LAST_SERVICE_DATE}
            value={toDateStringOrDefault(vehicle.LastServiceDate, locale)}
          />
          <DataRow
            label={definedMessages.WARRANTY_MONTHS}
            value={toLocaleStringOrDefault(vehicle.WarrantyMonths, locale)}
          />
          <DataRow
            label={definedMessages.WARRANTY_MILES}
            value={toLocaleStringOrDefault(vehicle.WarrantyMiles, locale)}
          />
        </Box>
        {dealertrackSettings.services.show ? (
          <ServiceListsComponent VIN={vehicle.VIN} isExpandedDefault={dealertrackSettings.services.expand} />
        ) : null}
        {dealertrackSettings.parts.show ? (
          <Parts VIN={vehicle.VIN} isExpandedDefault={dealertrackSettings.parts.expand} />
        ) : null}
      </div>
    </div>
  );
};
