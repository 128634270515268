import React from 'react';
import { TextField, TextFieldProps } from '@getgo/chameleon-material-ui/dist/components/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@getgo/chameleon-material-ui';

export const DateTimePickerInputWrapper: React.FC<TextFieldProps & { Icon: React.ComponentType<any> }> = ({
  Icon,
  ...props
}) => {
  return (
    <TextField
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <IconButton size="small" variant="secondary">
            <Icon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
