import { Reducer } from 'redux';
import { User } from './dynamics.model';
import { DynamicsActions, DynamicsActionsTypes } from './dynamics.action';
import { ProxyActions, ProxyActionsTypes } from '../ambassador/proxy/proxy.action';

export interface DynamicsState {
  self?: User;
}

export const DYNAMICS_INITIAL_STATE = {
  self: undefined,
};

export const dynamicsReducer: Reducer<DynamicsState, DynamicsActions | ProxyActions> = (
  state = DYNAMICS_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ProxyActionsTypes.PROXY_CHECK_LINK:
      return {
        ...DYNAMICS_INITIAL_STATE,
      };
    case DynamicsActionsTypes.DYNAMICS_WHO_AM_I_SUCCESS:
      return { ...state, self: action.payload };
    default:
      return state;
  }
};

export default dynamicsReducer;
