import React, { useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { LoadActivityCategoriesRequestState } from './activity.models';
import { Activity } from '../clio.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { ClioActionFacade } from '../clio.action.facade';
import { newTracker } from '../../analytics-new/tracker-new';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../../analytics-new/analytics.models';
import { Autocomplete } from '@getgo/chameleon-material-ui';
import { definedMessages as commonDefinedMessages } from '../../inAppNotification/message.content';
import { uuid } from '../../uuid';

const definedMessages = defineMessages({
  ACTIVITY_CATEGORY_LOADING_MESSAGE: {
    id: 'ActivityCategoryPicker.Loading',
    defaultMessage: 'Loading...',
  },
  ACTICITY_CATEGORY_PICKER_EMPTY_MESSAGE: {
    id: 'ActivityCategoryPicker.Empty',
    defaultMessage: 'No Activity category found',
  },
  ACTICITY_CATEGORY_PICKER_TITLE: {
    id: 'ActivityCategoryPicker.Title',
    defaultMessage: 'Choose category',
  },
});

const getTrackingEvents = (category: AnalyticsCategory) =>
  defineTrackingEvents({
    ACTIVITY_CATEGORY_SELECTED: {
      category,
      action: AnalyticsAction.ItemChanged,
      label: 'Activity category | select',
    },
  });

export interface ActivityCategoryPickerProps {
  selectedActivityId?: number;
  onActivitySelected: (activityId?: number) => void;
  autoFocus?: boolean;
  inputRef?: React.RefObject<any>;
  trackingCategory: AnalyticsCategory;
}

export interface ActivityCategoryPickerStateProps {
  activities: Activity[];
  requestState: LoadActivityCategoriesRequestState;
}

export const ActivityCategoryPickerComponent: React.FC<ActivityCategoryPickerProps &
  ActivityCategoryPickerStateProps> = ({
  requestState,
  activities,
  onActivitySelected,
  selectedActivityId,
  inputRef,
  autoFocus,
  trackingCategory,
}) => {
  const intl = useIntl();
  const trackingEvents = useMemo(() => getTrackingEvents(trackingCategory), [trackingCategory]);

  useEffect(() => {
    void getActionFacade<ClioActionFacade>().loadActivityCategories();
  }, []);

  const isLoading = requestState === LoadActivityCategoriesRequestState.LOADING;

  const id = uuid();

  return (
    <Autocomplete
      id={`textfield-activity-picker-${id}`}
      openOnFocus
      options={activities}
      getOptionLabel={(activity) => activity.title}
      loading={isLoading}
      getOptionSelected={(activity, selected) => activity.id === selected.id}
      onChange={(_, activity) => {
        onActivitySelected(activity?.id);
        newTracker.trackAnalyticsEvent(trackingEvents.ACTIVITY_CATEGORY_SELECTED);
      }}
      value={activities.find((a) => a.id === selectedActivityId) ?? null}
      loadingText={<FormattedMessage {...definedMessages.ACTIVITY_CATEGORY_LOADING_MESSAGE} />}
      noOptionsText={<FormattedMessage {...definedMessages.ACTICITY_CATEGORY_PICKER_EMPTY_MESSAGE} />}
      TextFieldProps={{
        inputRef,
        autoFocus,
        noMargin: true,
        placeholder: intl.formatMessage(commonDefinedMessages.FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER),
        label: intl.formatMessage(definedMessages.ACTICITY_CATEGORY_PICKER_TITLE),
        name: `activity-picker-${id}`,
      }}
    />
  );
};
