import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../actions/actionCreators';
import { InAppNotificationMessage } from '../models';

export enum MessagesActionsType {
  UPDATE_ACTIVE_CALL_MESSAGE = 'message/WarningActiveCallChange',
  SHOW_MESSAGE = 'message/show',
  SHOW_SUCCESS_MESSAGE = 'message/success/show',
  SHOW_WARNING_MESSAGE = 'message/warning/show',
  SHOW_ERROR_MESSAGE = 'message/error/show',
  DELETE_ALL_MESSAGES = 'message/deleteAll',
  DELETE_MESSAGE = 'message/delete',
  SWITCH_CALL_MESSAGE = 'message/switchCall',
}

export const deleteAllMessages = makeActionCreator(MessagesActionsType.DELETE_ALL_MESSAGES)();
export const deleteMessage = makeActionCreator(MessagesActionsType.DELETE_MESSAGE)<string>();

export const switchCallMessage = makeActionCreator(MessagesActionsType.SWITCH_CALL_MESSAGE)<any>();

export const showMessage = makeActionCreator(MessagesActionsType.SHOW_MESSAGE)<InAppNotificationMessage>();
export const showWarningMessage = makeActionCreator(MessagesActionsType.SHOW_WARNING_MESSAGE)<MessageDescriptor>();
export const showSuccessMessage = makeActionCreator(MessagesActionsType.SHOW_SUCCESS_MESSAGE)<MessageDescriptor>();
export const showErrorMessage = makeActionCreator(MessagesActionsType.SHOW_ERROR_MESSAGE)<MessageDescriptor>();

export const updateActiveCallMessage = makeActionCreator(MessagesActionsType.UPDATE_ACTIVE_CALL_MESSAGE)<
  InAppNotificationMessage
>();

export type MessageActions =
  | ReturnType<typeof deleteAllMessages>
  | ReturnType<typeof deleteMessage>
  | ReturnType<typeof switchCallMessage>
  | ReturnType<typeof showMessage>
  | ReturnType<typeof showSuccessMessage>
  | ReturnType<typeof showWarningMessage>
  | ReturnType<typeof showErrorMessage>
  | ReturnType<typeof updateActiveCallMessage>;
