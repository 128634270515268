import React, { FC } from 'react';
import { CommunicationLogConfirmation } from '../clio/communication/CommunicationLogConfirmation.component';
import UpdateAvailableDialogBody from '../versionChecker/UpdateAvailableDialogBody.container';
import { PhoneNumberUpdateConfirmation } from './bodies/PhoneNumberUpdateConfirmation.component';
import { SFInitializationError } from './bodies/SFInitializationError.component';
import { CallLogErrorContinueInSalesforce } from './bodies/CallLogErrorContinueInSalesforce.component';
import { SoftphoneSettingsReadMore } from '../softphone/SoftphoneSettingsReadMore.component';
import { NoMicPermission } from './bodies/NoMicPermission.component';

export enum DialogBodyNames {
  ClioSaveCommunicationLogConfirmation = 'ClioSaveCommunicationLogConfirmation',
  UpdateAvailableDialogBody = 'UpdateAvailableDialogBody',
  PhoneNumberUpdateConfirmation = 'PhoneNumberUpdateConfirmation',
  SFInitializationError = 'SFInitializationError',
  CallLogErrorContinueInSalesforce = 'CallLogErrorContinueInSalesforce',
  SoftphoneSettingsReadMore = 'SoftphoneSettingsReadMore',
  NoMicPermission = 'NoMicPermission',
}

const Components: { [key: string]: FC<any> } = {
  [DialogBodyNames.ClioSaveCommunicationLogConfirmation]: CommunicationLogConfirmation,
  [DialogBodyNames.UpdateAvailableDialogBody]: UpdateAvailableDialogBody,
  [DialogBodyNames.PhoneNumberUpdateConfirmation]: PhoneNumberUpdateConfirmation,
  [DialogBodyNames.SFInitializationError]: SFInitializationError,
  [DialogBodyNames.CallLogErrorContinueInSalesforce]: CallLogErrorContinueInSalesforce,
  [DialogBodyNames.SoftphoneSettingsReadMore]: SoftphoneSettingsReadMore,
  [DialogBodyNames.NoMicPermission]: NoMicPermission,
};

export interface Props {
  bodyName: DialogBodyNames;
}

export const DialogBody: React.FC<Props> = ({ bodyName }) => {
  return React.createElement(Components[bodyName]);
};
