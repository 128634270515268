import { proxyProviderSelector, proxyUserProviderSelector } from '../ambassador/proxy/proxy.selector';
import { createSelector } from 'reselect';

export const connectWiseApiUrlSelector = createSelector(
  proxyUserProviderSelector,
  proxyProviderSelector,
  (userProvider, provider) => {
    if (!userProvider || !provider) {
      return '';
    }
    const { selectedProviderId, isOnPremiseInstallation, onPremiseApiUrl } = userProvider.customAttributes;

    if (isOnPremiseInstallation === 'true') {
      return onPremiseApiUrl;
    }

    if (provider.appId === selectedProviderId) {
      return provider.apiUrl;
    } else {
      return provider.config.find((p) => p.appId === selectedProviderId)?.apiUrl ?? '';
    }
  },
);
