export enum ThemeActionsType {
  SET = 'theme/set',
}

export enum JiveThemes {
  Light = 0,
  Dark = 1,
  HighContrast = 2,
}

export interface ThemeState {
  selected: JiveThemes;
}
