import React from 'react';
import { defineMessages } from 'react-intl';
import { CallActionProps, NewCaseButton, NewContactButton, NewOpportunityButton } from '../../calls/callActions';
import { NewLeadButton } from '../../calls/callActions/NewLeadButton.component';
import { CallActionRoutes, EntityType } from '../../constants';
import { Entity } from '../../models';
import { goToCallActionRoute } from '../../browserHistory';

const definedMessage = defineMessages({
  NEW_CONTACT: {
    id: 'Call.NewContact',
    defaultMessage: 'New contact',
  },
  CREATE_NEW_CONTACT: {
    id: 'Call.FullWidth.NewContact',
    defaultMessage: 'Create a new contact',
  },
  CREATE_NEW_CASE: {
    id: 'Call.FullWidth.NewCase',
    defaultMessage: 'Create a new case',
  },
  CREATE_NEW_LEAD: {
    id: 'Call.FullWidth.NewLead',
    defaultMessage: 'Create a new lead',
  },
  CREATE_NEW_OPPORTUNITY: {
    id: 'Call.FullWidth.NewOpportunity',
    defaultMessage: 'Create a new opportunity',
  },
});

const routeToFormActionFor =
  (entityType: EntityType): CallActionProps['action'] =>
  ({ call }) => {
    if (!call) {
      return;
    }

    goToCallActionRoute(call.id, CallActionRoutes[entityType]);
  };

/* eslint-disable react/jsx-key */
const availableActions = [
  // eslint-disable-next-line react/jsx-key
  <NewContactButton action={routeToFormActionFor(EntityType.CONTACT)} />,
  <NewCaseButton action={routeToFormActionFor(EntityType.CASE)} />,
  <NewLeadButton action={routeToFormActionFor(EntityType.LEAD)} />,
  <NewOpportunityButton action={routeToFormActionFor(EntityType.OPPORTUNITY)} />,
];

const noMatchActions = [
  <NewContactButton
    action={routeToFormActionFor(EntityType.CONTACT)}
    variant={'secondary'}
    label={definedMessage.CREATE_NEW_CONTACT}
  />,
  <NewLeadButton
    action={routeToFormActionFor(EntityType.LEAD)}
    variant="secondary"
    label={definedMessage.CREATE_NEW_LEAD}
  />,
];
/* eslint-enable react/jsx-key */

export const dynamicsCallActionsProvider = {
  getPrimaryAction: (_entityInCall?: Entity) => null,
  getSecondaryActions: (missingObjectAccessRights: EntityType[], entityInCall?: Entity) => {
    if (!entityInCall) {
      return noMatchActions;
    }

    if (entityInCall.type.toLowerCase() === EntityType.CONTACT.toLowerCase()) {
      return availableActions;
    }

    return [];
  },
};
