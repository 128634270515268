import { ContactActionCreator } from '../../actionFacade/actionCreators/contact/contact.actioncreator';
import { ContactCreateModel } from '../../actionFacade/actionCreators/contact/contact.actioncreator.models';
import { Entity } from '../../models';
import { EntityPhoneNumber } from '../../search/entities.model';
import { DynamicsService } from '../dynamics.service';
import { DynamicsContact } from '../dynamics.service.model';
import { EntityType } from '../../constants';
import { openDynamicsCrmForm } from '../dynamics.actionCreator.utils';

const contactToEntity = (contact: DynamicsContact): Entity =>
  ({
    id: contact.contactid,
    label: contact.fullname,
    name: contact.fullname,
    phoneNumber: contact.telephone1 || contact.mobilephone,
    type: EntityType.CONTACT,
  } as Entity);

export class DynamicsContactActionCreator extends ContactActionCreator {
  protected async _searchContactByName(name: string): Promise<Entity[]> {
    const contacts = await DynamicsService.searchContactsByName(name);
    return Object.keys(contacts).map((contactKey) => {
      return contactToEntity(contacts[contactKey]);
    });
  }

  protected async _searchContactByPhoneNumber(phoneNumber: string): Promise<Entity[]> {
    const contacts = await DynamicsService.searchContactsByPhoneNumber(phoneNumber);
    return Object.keys(contacts).map((contactKey) => {
      return contactToEntity(contacts[contactKey]);
    });
  }

  protected async _getPhoneNumbersOfContact(contactId: string): Promise<EntityPhoneNumber[]> {
    const phoneNumbers: DynamicsContact = await DynamicsService.getDynamicsContact(contactId);

    return Object.entries(phoneNumbers)
      .filter((entry) => DynamicsService.isValidPhoneNumberField(entry))
      .map((entry) => {
        return {
          entityId: contactId,
          type: DynamicsService.dynamicsToEntityPhoneNumberType(entry[0]),
          phoneNumber: entry[1],
        } as EntityPhoneNumber;
      });
  }

  protected _openCrmContactPage(contactId: string): Promise<void> {
    openDynamicsCrmForm(this.store.getState(), EntityType.CONTACT, contactId);
    return Promise.resolve();
  }

  protected _openCrmContactTab(contactId: string): Promise<boolean> {
    return this._openCrmContactPage(contactId)
      .then(() => true)
      .catch(() => false);
  }

  protected _canUpdateContactPhoneNumber(): Promise<boolean> {
    return Promise.resolve(true);
  }

  protected async _updateContactPhoneNumber(contactId: string, phoneNumber: string): Promise<void> {
    await DynamicsService.updateContactPhoneNumber(contactId, phoneNumber);
  }

  protected async _createContact(contact: ContactCreateModel): Promise<string> {
    return await DynamicsService.createContact(
      contact.firstName,
      contact.lastName,
      contact.phoneNumber,
      contact.phoneNumber,
    );
  }
}
