import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import { format, Locale } from 'date-fns';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { SalesforceOpportunity } from '../../../../salesforce-shared/salesforce-service.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';
import { CallContextListItem } from '../../calls/CallContextListItem.component';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';
import LoadingSpinner from '../../components/LoadingSpinner';
import { OpenInCRMButton } from '../../components/OpenInCRMButton.component';
import { Typography } from '../../theme/Typography.component';
import { SalesforceLightningActionFacade } from '../salesforceLightning.actionfacade';
import { GetMoreOpportunitiesRequestState, GetOpportunitiesRequestState } from './opportunity.models';

const definedMessages = defineMessages({
  LOAD_MORE_BUTTON_LABEL: {
    id: 'Opportunities.List.LoadMore',
    defaultMessage: 'Load more opportunities',
  },
  EMPTY_MESSAGE: {
    id: 'Opportunities.List.Empty',
    defaultMessage: 'This contact has no opportunities yet.',
  },
  CREATE_OPPORTUNITY_BUTTON: {
    id: 'Opportunities.List.Create.Button.Label',
    defaultMessage: 'Create opportunity',
  },
  STAGE_LABEL: {
    id: 'Opportunities.List.Stage.Label',
    defaultMessage: 'Stage:',
  },
  CLOSE_DATE_LABEL: {
    id: 'Opportunities.List.CloseDate.Label',
    defaultMessage: 'Close date:',
  },
});

const trackingEvents = defineTrackingEvents({
  CREATE_OPPORTUNITY_BUTTON_CLICKED: {
    category: AnalyticsCategory.Opportunity,
    action: AnalyticsAction.ItemClicked,
    label: 'Create opportunity | button',
  },
  LOAD_MORE_OPPORTUNITY_BUTTON_CLICKED: {
    category: AnalyticsCategory.Opportunity,
    action: AnalyticsAction.ItemClicked,
    label: 'Load more opportunities | button',
  },
  OPEN_OPPORTUNITY_BUTTON_CLICKED: {
    category: AnalyticsCategory.Opportunity,
    action: AnalyticsAction.ItemClicked,
    label: 'Open opportunity in CRM | button',
  },
});

export interface OpportunitiesStateProps {
  opportunities: SalesforceOpportunity[];
  requestState: GetOpportunitiesRequestState;
  moreRequestState: GetMoreOpportunitiesRequestState;
  canLoadMoreOpportunities: boolean;
  openOpportunityInCrmSupported: boolean;
  locale: Locale;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    meta: {
      fontSize: '11px',
      fontWeight: 300,
    },
    createLink: {
      display: 'flex',
      alignItems: 'center',
      height: '30px',
    },
    createOpportunityText: {
      fontWeight: 600,
    },
    addIcon: {
      marginRight: theme.spacing(2),
    },
    fieldName: {
      fontSize: '0.75rem',
      color: tokens.text02,
      fontWeight: 500,
    },
    fieldValue: {
      fontSize: '0.75rem',
      color: tokens.text05,
    },
    loadMoreButton: {
      marginTop: '10px',
    },
  }),
);

export const OpportunitiesComponent: React.FC<OpportunitiesStateProps & CallContextPanelProps> = ({
  opportunities,
  requestState,
  contact,
  canLoadMoreOpportunities,
  moreRequestState,
  locale,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (contact) {
      void getActionFacade<SalesforceLightningActionFacade>().loadMostRecentOpportunityOfContact(contact.id);
    }
  }, [contact]);

  function renderLoadMoreOpportunitiesButton() {
    if (!canLoadMoreOpportunities) {
      return;
    }

    return (
      <TrackedButton
        fullwidth
        className={classes.loadMoreButton}
        id="loadMoreOpportunities"
        variant="secondary"
        onClick={() => getActionFacade<SalesforceLightningActionFacade>().loadMoreOpportunitiesOfContact()}
        disabled={moreRequestState === GetMoreOpportunitiesRequestState.LOADING}
        trackingEvent={trackingEvents.LOAD_MORE_OPPORTUNITY_BUTTON_CLICKED}
      >
        {moreRequestState === GetMoreOpportunitiesRequestState.LOADING ? (
          <CircularProgress size={26} />
        ) : (
          <FormattedMessage {...definedMessages.LOAD_MORE_BUTTON_LABEL} />
        )}
      </TrackedButton>
    );
  }

  function renderList() {
    return opportunities?.length ? (
      <>
        <List dense disablePadding>
          {opportunities.map((opportunity: SalesforceOpportunity) => (
            <CallContextListItem
              key={opportunity.id}
              id={opportunity.id}
              className={'opportunity'}
              primary={
                <div>
                  <Box mb={1}>
                    <Typography variant="body-medium">{opportunity.name}</Typography>
                  </Box>

                  <div className={classes.meta}>
                    <span className={classes.fieldName}>
                      <FormattedMessage {...definedMessages.CLOSE_DATE_LABEL} />
                    </span>
                    &nbsp;
                    <span className={classes.fieldValue}>
                      {format(new Date(opportunity.closeDate), 'PPP', { locale })}
                    </span>
                  </div>
                </div>
              }
              secondary={
                <>
                  <span className={classes.fieldName}>
                    <FormattedMessage {...definedMessages.STAGE_LABEL} />
                  </span>
                  &nbsp;<span className={classes.fieldValue}>{opportunity.stage}</span>
                </>
              }
              secondaryActionButton={
                <Box mt={2} mr={2}>
                  <OpenInCRMButton
                    onClick={() => getActionFacade<SalesforceLightningActionFacade>().openOpportunity(opportunity.id)}
                    trackingEvent={trackingEvents.OPEN_OPPORTUNITY_BUTTON_CLICKED}
                  />
                </Box>
              }
            />
          ))}
        </List>
        {renderLoadMoreOpportunitiesButton()}
      </>
    ) : (
      <Box mt={1} mr={1} ml={1} mb={4}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      </Box>
    );
  }

  function renderLoadedState() {
    return <Box mt={2}>{renderList()}</Box>;
  }

  function render() {
    switch (requestState) {
      case GetOpportunitiesRequestState.INITIAL:
        return null;
      case GetOpportunitiesRequestState.LOADING:
        return (
          <LoadingSpinner isLoading={true}>
            <></>
          </LoadingSpinner>
        );
      case GetOpportunitiesRequestState.LOADED:
        return renderLoadedState();
      case GetOpportunitiesRequestState.FAILED:
        return null; // The snack bar will display the error message.;
    }
  }

  return <div className={classes.container}>{render()}</div>;
};
