import { Store } from 'redux';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { AppState } from '../reducers';
import { EntitiesActionsType } from '../search/entities.action';
import { ContactActionsType } from '../actions/contacts';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';
import { DynamicsService } from './dynamics.service';
import { DynamicsActionsTypes, dynamicsWhoAmI, dynamicsWhoAmIError, dynamicsWhoAmISuccess } from './dynamics.action';
import { User } from './dynamics.model';
import { DynamicsUser } from './dynamics.service.model';
import { ProxyActionsTypes } from '../ambassador/proxy/proxy.action';
import { isIntegrationLinkedIfNecessarySelector } from '../integrations/integrations.selector';
import { DynamicsAxiosInterceptor } from './dynamics.axios.interceptor';
import { EntityType } from '../constants';
import { DynamicsActionFacade } from './dynamics.actionfacade';

addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (store, action) => {
  if (action.payload === Integrations.Dynamics) {
    // let's wait for the current integration to be saved in the store,
    // otherwise getActionFacade will not know which integration to use
    registerDynamicsListener(store);
  }
});

let isListenerRegistered = false;

function registerDynamicsListener(store: Store<AppState>) {
  if (isListenerRegistered) {
    return;
  }

  isListenerRegistered = true;

  new DynamicsAxiosInterceptor(store).register();

  addListener(ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS)((state) => {
    const isDynamicsLinked = isIntegrationLinkedIfNecessarySelector(state);
    if (isDynamicsLinked) {
      return dynamicsWhoAmI();
    }
  });

  addListener(DynamicsActionsTypes.DYNAMICS_WHO_AM_I)(async (_, __) => {
    try {
      const dynamicsUser: DynamicsUser = await DynamicsService.whoAmI();

      const odatacontext = dynamicsUser['@odata.context'];
      const regex = /(org[0-9a-f]{8})\.(.*)\.dynamics\.com/g;
      const match = regex.exec(odatacontext);

      const self: User = {
        businessUnitId: dynamicsUser.BusinessUnitId,
        userId: dynamicsUser.UserId,
        organizationId: dynamicsUser.OrganizationId,
        organizationTag: match?.[1],
        locationTag: match?.[2],
      };

      return dynamicsWhoAmISuccess(self);
    } catch (e) {
      return dynamicsWhoAmIError();
    }
  });

  addCallStartListener(async (_, action) => {
    await getActionFacade().createScreenpop(action.payload.call);
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(action.payload.call);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const query = action.payload.query;
    await getActionFacade().searchContact(query);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });

  addListener(EntitiesActionsType.CREATE_ENTITY)(async (_, { payload }) => {
    const { callId } = payload;
    const { firstName, lastName, phoneNumber, topic, title, contactId } = payload.defaultFieldValues;

    switch (payload.entityName) {
      case EntityType.CONTACT:
        await getActionFacade().createContact({
          isActiveCall: payload.isActiveCall,
          callId,
          firstName,
          lastName,
          phoneNumber,
        });

        break;
      case EntityType.CASE:
        await getActionFacade<DynamicsActionFacade>().createCaseForContact({
          callId,
          title,
          contactId,
        });

        break;
      case EntityType.LEAD:
        await getActionFacade<DynamicsActionFacade>().createLead({
          callId,
          firstName,
          lastName,
          topic,
          phoneNumber,
        });

        break;
      case EntityType.OPPORTUNITY:
        await getActionFacade<DynamicsActionFacade>().createOpportunity({
          callId,
          topic,
          contactId,
        });

        break;
    }
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, { payload }) => {
    if (!payload.entity) {
      return;
    }

    if (payload.entity.type === EntityType.CONTACT) {
      await getActionFacade().openContactInCrm(payload.entity.id);
    } else {
      await getActionFacade<DynamicsActionFacade>().openFormInCrm({
        entityType: payload.entity.type as EntityType,
        defaultFieldValues: {
          id: payload.entity.id,
        },
      });
    }
  });

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, { payload: { contactId, phoneNumber } }) => {
    await getActionFacade().updateContactPhoneNumber(contactId, phoneNumber);
  });
}
