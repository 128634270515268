import { Reducer } from 'redux';
import { ZendeskTicketSearchResultItem } from '../zendesk.models';
import {
  searchTicket,
  searchTicketClear,
  searchTicketError,
  searchTicketSuccess,
  TicketsActionsType,
} from './tickets.action';

interface TicketState {
  ticketLoadingState: 'initial' | 'loading' | 'loaded';
  tickets: ZendeskTicketSearchResultItem[];
}

export const initialState: TicketState = {
  ticketLoadingState: 'initial',
  tickets: [],
};

type TicketActions =
  | ReturnType<typeof searchTicket>
  | ReturnType<typeof searchTicketSuccess>
  | ReturnType<typeof searchTicketError>
  | ReturnType<typeof searchTicketClear>;

export const zendeskTicketsReducer: Reducer<TicketState, TicketActions> = (state = initialState, action) => {
  switch (action.type) {
    case TicketsActionsType.SEARCH_TICKETS:
      return { ...state, ticketLoadingState: 'loading', tickets: [] };
    case TicketsActionsType.SEARCH_TICKETS_SUCCESS:
      return { ...state, ticketLoadingState: 'loaded', tickets: action.payload.tickets };
    case TicketsActionsType.SEARCH_TICKETS_ERROR:
      return { ...state, ticketLoadingState: 'loaded', tickets: [] };
    case TicketsActionsType.SEARCH_TICKETS_CLEAR:
      return {
        ...initialState,
      };
  }
  return state;
};
