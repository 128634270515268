import { EntityPhoneNumberType } from '../search/entities.model';
import { RedtailPhoneNumberType } from './redtail.model';

const phoneNumberTypeMap: { [key: string]: EntityPhoneNumberType } = {
  [RedtailPhoneNumberType.Work]: EntityPhoneNumberType.BUSINESS,
  [RedtailPhoneNumberType.Mobile]: EntityPhoneNumberType.MOBILE,
  [RedtailPhoneNumberType.Home]: EntityPhoneNumberType.HOME,
  [RedtailPhoneNumberType.Fax]: EntityPhoneNumberType.FAX,
  [RedtailPhoneNumberType.Other]: EntityPhoneNumberType.OTHER,
  [RedtailPhoneNumberType.DirectDial]: EntityPhoneNumberType.OTHER,
  [RedtailPhoneNumberType.TollFree]: EntityPhoneNumberType.OTHER,
};

export const redtailPhoneNumberTypeMapper = (phoneNumberType: string): EntityPhoneNumberType => {
  return phoneNumberTypeMap[phoneNumberType] || EntityPhoneNumberType.BUSINESS;
};
