import {
  CallContextSectionComponent,
  CallContextSectionProps,
  CallContextSectionStateProps,
} from './CallContextSection.component';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { contactInCallSelector } from './calls.selector';
import {
  isCallHistoryExpandedByDefaultSelector,
  isCasesEnabledSelector,
  isDisplayingCallHistoryEnabledSelector,
  isOpportunitiesEnabledSelector,
} from '../settings/settings.selector';
import { allNotesCountSelector } from '../notes/notes.selectors';
import { allOpportunitiesCountSelector } from '../salesforce/opportunities/opportunity.selectors';
import { allCasesCountSelector } from '../salesforce/cases/case.selectors';
import { allVehiclesCountSelector } from '../cox/vehicles/vehicles.selectors';
import { customerSelector } from '../cox/contact/cox.customer.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { contactCallHistoryCountSelector } from '../contactCallHistory/contactCallHistory.selectors';

const mapState = (state, { callId }): CallContextSectionStateProps => {
  return {
    contact: contactInCallSelector(state, callId),
    currentIntegration: currentIntegrationSelector(state),
    contactCallHistoryCount: contactCallHistoryCountSelector(contactInCallSelector(state, callId)?.id)(state),

    isDisplayingCasesEnabled: isCasesEnabledSelector(state),
    isDisplayingOpportunitiesEnabled: isOpportunitiesEnabledSelector(state),
    isDisplayingCallHistoryEnabled: isDisplayingCallHistoryEnabledSelector(state),

    notesCount: allNotesCountSelector(state),
    opportunitiesCount: allOpportunitiesCountSelector(state),
    casesCount: allCasesCountSelector(state),
    vehicleCount: allVehiclesCountSelector(state),
    coxCustomer: customerSelector(state),

    isCallHistoryExpandedByDefault: isCallHistoryExpandedByDefaultSelector(state),
  };
};

export const CallContextSection = connect<CallContextSectionStateProps, {}, CallContextSectionProps, AppState>(
  mapState,
)(CallContextSectionComponent);
