import { isAfter } from 'date-fns';
import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { OpenRepairOrder, RepairOrderStatus } from './repairOrders.models';

const repairOrdersSelector = (state: AppState) => state.cox.repairOrders;

export const openRepairOrdersLoadRequestStateByVINSelector = (VIN: string) =>
  createSelector(repairOrdersSelector, (repairOrders) => repairOrders.byVIN[VIN]?.openRepairOrdersRequestState);

export const openRepairOrderByVINSelector = (VIN: string) =>
  createSelector(repairOrdersSelector, (repairOrders) => repairOrders.byVIN[VIN]?.openRepairOrders);

export const closedRepairOrdersLoadRequestStateByVINSelector = (VIN: string) =>
  createSelector(repairOrdersSelector, (repairOrders) => repairOrders.byVIN[VIN]?.closedRepairOrdersRequestState);

export const closedRepairOrderByVINSelector = (VIN: string) =>
  createSelector(repairOrdersSelector, (repairOrders) => repairOrders.byVIN[VIN]?.closedRepairOrders ?? []);

export const closedRepairOrderDetailsSelectorByVINSelector = (VIN: string) =>
  createSelector(repairOrdersSelector, (repairOrders) => repairOrders.byVIN[VIN]?.closedRepairOrderDetails ?? {});

export const latestRepairOrderByVINSelector = (VIN: string) =>
  createSelector(
    repairOrdersSelector,
    (ros): Optional<RepairOrderStatus> => {
      const repairOrders = ros.byVIN[VIN]?.openRepairOrders ?? [];
      const latestRepairOrder = repairOrders.reduce(
        (max: OpenRepairOrder, current: OpenRepairOrder) =>
          isAfter(current.lastModified ?? 0, max.lastModified ?? 0) ? current : max,
        repairOrders[0],
      );
      return latestRepairOrder?.status;
    },
  );

export const serviceWritersRequestStateSelector = createSelector(
  repairOrdersSelector,
  (repairOrders) => repairOrders.serviceWritersRequestState,
);

export const serviceWritersSelector = createSelector(
  repairOrdersSelector,
  (repairOrders) => repairOrders.serviceWriters,
);
