import { Store } from 'redux';
import { logger } from '../../../logging';
import { AppState } from '../../../reducers';
import { definedMessages } from '../connectwise.note.actioncreator';
import { ConnectWiseService } from '../../connectwise.service';
import {
  getConnectWiseNoteTypesSuccess,
  getConnectWiseNoteTypes,
  getConnectWiseNoteTypesError,
} from './connectwise.notetype.actions';

export class ConnectWiseNoteTypeActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async getNoteTypes(): Promise<void> {
    this.store.dispatch(getConnectWiseNoteTypes());
    try {
      this.store.dispatch(
        getConnectWiseNoteTypesSuccess({
          noteTypes: await ConnectWiseService.getNoteTypes(),
        }),
      );
    } catch (error) {
      logger.error('Error getting connectwise note types', error);
      this.store.dispatch(getConnectWiseNoteTypesError({ message: definedMessages.CONNECTWISE_NOTE_TYPES_GET_ERROR }));
    }
  }
}
