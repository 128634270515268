import { connect } from 'react-redux';
import { AppState } from '../reducers';

import { AppAction } from '../actions/actionCreators';
import { closeDialog } from './dialog.actions';
import { DialogComponent } from './Dialog.component';
import { DialogDispatchProps, DialogStateProps } from './Dialog.component';
import { dialogStateSelector } from './dialog.selector';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';

export default connect<DialogStateProps, DialogDispatchProps, {}, AppState>(
  (state) => ({
    isOpen: dialogStateSelector(state).isOpen,
    confirmActionToDispatch: dialogStateSelector(state).confirmAction,
    texts: dialogStateSelector(state).texts,
    body: dialogStateSelector(state).body,
    bodyName: dialogStateSelector(state).bodyName,
    cancellable: dialogStateSelector(state).cancellable,
    cancelTrackingEvent: dialogStateSelector(state).cancelTrackingEvent,
    confirmTrackingEvent: dialogStateSelector(state).confirmTrackingEvent,
    hideCancelButton: dialogStateSelector(state).hideCancelButton,
    badge: dialogStateSelector(state).badge,
    closeCallback: dialogStateSelector(state).closeCallback,
    isNarrowIntegration: isNarrowIntegrationSelector(state),
    dialogStyles: dialogStateSelector(state).dialogStyles,
  }),
  (dispatch) => ({
    confirmAction: (action?: AppAction, trackingEvent?: TrackingEvent) => {
      if (trackingEvent) {
        newTracker.trackAnalyticsEvent(trackingEvent);
      }

      if (action) {
        dispatch(action);
      }
    },
    cancelAction: (trackingEvent?: TrackingEvent) => {
      if (trackingEvent) {
        newTracker.trackAnalyticsEvent(trackingEvent);
      }
      dispatch(closeDialog({}));
    },
  }),
)(DialogComponent);
