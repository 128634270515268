import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { updateActiveCall } from '../calls/callEvents.action';
import { activeCallsSelector, numberOfActiveCallsSelector } from '../calls/calls.selector';
import { AppState } from '../reducers';
import ActiveCallsComponent, { ActiveCallsDispatchProps, ActiveCallsStateProps } from './ActiveCalls.component';

const mapState = (state: AppState) => ({
  numberOfActiveCalls: numberOfActiveCallsSelector(state),
  activeCalls: activeCallsSelector(state),
});

const mapDispatch = (dispatch: Dispatch<AppAction>) => ({
  updateActiveCall: (payload) => dispatch(updateActiveCall(payload)),
});

export default connect<ActiveCallsStateProps, ActiveCallsDispatchProps, {}, AppState>(
  mapState,
  mapDispatch,
)(ActiveCallsComponent);
