import { ActionCreatorFactory } from '../actionFacade/actionCreator.factory';
import { ContactActionCreator } from '../actionFacade/actionCreators/contact/contact.actioncreator';
import { RedtailContactActionCreator } from '../redtail/contact/redtail.contact.actioncreator';
import { RedtailActivityActionCreator } from './activity/redtail.activity.actioncreator';
import { RedtailCategoryActionCreator } from './category/redtail.category.actioncreator';
import { RedtailNoteActionCreator } from './note/redtail.note.actioncreator';

export default class RedtailActionCreatorFactory extends ActionCreatorFactory {
  public createContactActionCreator(): ContactActionCreator {
    return new RedtailContactActionCreator(this.store);
  }

  public createNoteActionCreator(): RedtailNoteActionCreator {
    return new RedtailNoteActionCreator(this.store);
  }

  public createCategoryActionCreator(): RedtailCategoryActionCreator {
    return new RedtailCategoryActionCreator(this.store);
  }

  public createActivityActionCreator(): RedtailActivityActionCreator {
    return new RedtailActivityActionCreator(this.store);
  }
}
