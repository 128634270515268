import { makeActionCreator } from '../../../actions/actionCreators';
import { WithMessage } from '../../../models';
import { ConnectWiseNoteType } from '../../connectwise.model';

export enum ConnectWiseNoteTypeActionsType {
  NOTETYPE_REQUEST = 'connectwise/notetypes/get/request',
  NOTETYPE_GET_SUCCESS = 'connectwise/notetypes/get/success',
  NOTETYPE_GET_ERROR = 'connectwise/notetypes/get/error',
}

export const getConnectWiseNoteTypes = makeActionCreator(ConnectWiseNoteTypeActionsType.NOTETYPE_REQUEST)();

interface GetConnectWiseNoteTypeSuccessPayload {
  noteTypes: ConnectWiseNoteType[];
}

export const getConnectWiseNoteTypesSuccess = makeActionCreator(ConnectWiseNoteTypeActionsType.NOTETYPE_GET_SUCCESS)<
  GetConnectWiseNoteTypeSuccessPayload
>();

export const getConnectWiseNoteTypesError = makeActionCreator(ConnectWiseNoteTypeActionsType.NOTETYPE_GET_ERROR)<
  WithMessage
>();

export type ConnectWiseNoteTypeActions =
  | ReturnType<typeof getConnectWiseNoteTypes>
  | ReturnType<typeof getConnectWiseNoteTypesSuccess>
  | ReturnType<typeof getConnectWiseNoteTypesError>;
