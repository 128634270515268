import { Reducer } from 'redux';
import { ActionTypes } from '../actions/actionCreators';
import { SoftphoneCall } from './softphone.model';
import * as softphoneCallActions from './softphoneCall.action';

export interface SoftphoneCallsState {
  [callId: string]: SoftphoneCall;
}

export const softphoneCallReducer: Reducer<SoftphoneCallsState, ActionTypes<typeof softphoneCallActions>> = (
  state = {},
  action,
) => {
  const call = action.payload;
  switch (action.type) {
    case 'softphoneCall/started':
    case 'softphoneCall/updated':
      return { ...state, [call.id]: call };
    case 'softphoneCall/ended': {
      const newState = { ...state };
      delete newState[call.id];
      return newState;
    }
  }
  return state;
};
