import {
  SalesforceSettingsComponent,
  SalesforceSettingsDispatchProps,
  SalesforceSettingsStateProps,
} from './SalesforceSettings.component';
import { Dispatch } from 'redux';
import { AppAction } from '../../actions/actionCreators';
import { connect } from 'react-redux';
import {
  setAutomaticCallLogging,
  setShowCases,
  setShowOpportunities,
  setShowCallDispositionForm,
  setOpenContactInNewTab,
  setOpenCallLogAfterCall,
  setWidgetAutoPopup,
} from '../../settings/settings.action';
import { isCallDispositionConfiguredSelector } from '../salesforce.selectors';
import { showMessage } from '../../inAppNotification/message.action';
import { MessageVariant } from '../../models';
import {
  isAutomaticCallLoggingEnabledSelector,
  isCasesEnabledSelector,
  isOpenCallLogAfterCallEnabledSelector,
  isOpenContactInNewTabEnabledSelector,
  isOpportunitiesEnabledSelector,
  isShowCallDispositionFormEnabledSelector,
  isWidgetAutoPopupEnabledSelector,
} from '../../settings/settings.selector';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';

const mapStateToProps = (state): SalesforceSettingsStateProps => ({
  isAutomaticCallLoggingEnabled: isAutomaticCallLoggingEnabledSelector(state),
  isOpenCallLogAfterCallEnabled: isOpenCallLogAfterCallEnabledSelector(state),
  isShowCallDispositionFormEnabled: isShowCallDispositionFormEnabledSelector(state),
  isCallDispositionConfigured: isCallDispositionConfiguredSelector(state),
  isOpportunitiesEnabled: isOpportunitiesEnabledSelector(state),
  isCasesEnabled: isCasesEnabledSelector(state),
  isOpenContactInNewTabEnabled: isOpenContactInNewTabEnabledSelector(state),
  isNarrowIntegration: isNarrowIntegrationSelector(state),
  isWidgetAutoPopupEnabled: isWidgetAutoPopupEnabledSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppAction>): SalesforceSettingsDispatchProps => ({
  setAutomaticCallLogging: (enabled) => dispatch(setAutomaticCallLogging(enabled)),
  setOpenCallLogAfterCall: (enabled) => dispatch(setOpenCallLogAfterCall(enabled)),
  setShowCallDispositionForm: (enabled) => dispatch(setShowCallDispositionForm(enabled)),
  setOpportunities: (enabled) => dispatch(setShowOpportunities(enabled)),
  setCases: (enabled) => dispatch(setShowCases(enabled)),
  setOpenContactInNewTab: (enabled) => dispatch(setOpenContactInNewTab(enabled)),
  setWidgetAutoPopupEnabled: (enabled) => dispatch(setWidgetAutoPopup(enabled)),
  showCallDispositionConfigurationErrorMessage: (content: React.ReactElement) => {
    dispatch(
      showMessage({
        id: 'click_to_call_error_msg',
        type: MessageVariant.Custom,
        params: {
          dismissible: true,
          autoHide: false,
          customContent: content,
        },
      }),
    );
  },
});

export const SalesforceSettings = connect<SalesforceSettingsStateProps, SalesforceSettingsDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SalesforceSettingsComponent);
