import { Box, createStyles, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { getNumberOfTheOtherParty } from './call.helper';
import { CallWithContact, Contact } from './calls.reducer';
import { ApplicationRoute } from '../constants';
import { Entity, Integrations } from '../models';
import EntitiesSearch from '../search/EntitiesSearch.container';
import { SearchResultType } from '../search/SearchResult.model';
import SearchResults from '../search/SearchResults.container';
import { CallActionsComponent } from './CallActions.component';
import { CallCard } from './CallCard.component';
import { RedirectKeepSearch } from '../components/RedirectKeepSearch.component';
import { CallActionForm } from './CallActionForm.component';
import { CallContextSection } from './CallContextSection.container';
import { CallMatchingProgressComponent } from './CallMatchingProgressComponent.component';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { UnifiedCallHistoryStateRequestState } from '../callHistoryPush/unifiedCallHistory.reducer';
import { FullPageAppLoading } from '../components/FullPageAppLoading';
import { CallActionsComponentV2 } from './callActions/CallActionsV2.component';
import { CallHeader } from './CallHeader.component';
import { SoftphoneContainer } from '../softphone/SoftphoneContainer';
import { SoftphoneCallControl } from '../softphone/SoftphoneCallControl';
import { tokens } from '@getgo/chameleon-core';
import { ZendeskContextSection } from '../zendesk/ZendeskContextSection.component';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    callHeader: {
      display: 'flex',
      minHeight: 40,
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    callHeaderForNarrowIntegration: {
      marginTop: 30,
      minHeight: 30,
    },
    buttonCreateContact: {
      width: '100%',
    },
    bodyWithActions: {
      margin: theme.spacing(0, 0, 2),
    },
    searchResults: {
      margin: theme.spacing(2, 0),
    },
  }),
);

export interface CallStateProps {
  call: CallWithContact;
  contacts: Contact;
  isSingleMatch: boolean;
  entity: Entity | undefined;
  isLoadingEntities: boolean;
  integration: Integrations;
  isContactMatchingLoading: boolean;
  unifiedCallHistoryRequestState: UnifiedCallHistoryStateRequestState;
  selectedOrganizationId?: string;
}

export type CallComponentProps = RouteComponentProps<{ callId: string }>;

export const CallComponent: FC<CallComponentProps & CallStateProps> = ({
  call,
  entity,
  isLoadingEntities,
  isSingleMatch,
  integration,
  isContactMatchingLoading,
  unifiedCallHistoryRequestState,
  selectedOrganizationId,
}) => {
  const classes = useStyles();

  const renderCallRoot = () => {
    const searchString = isSingleMatch ? '' : getNumberOfTheOtherParty(call);

    return (
      <div className={classes.bodyWithActions} data-cy="call-details-body">
        <Box mb={6}>
          <EntitiesSearch defaultValue={searchString} />
          <SearchResults
            className={classes.searchResults}
            loading={isLoadingEntities}
            searchResultType={SearchResultType.Call}
            callType={call.type}
            analyticsSection="contact-search"
          />
        </Box>
        <Box>
          {[Integrations.SalesforceClassic, Integrations.SalesforceLightning, Integrations.Dynamics].includes(
            integration,
          ) ? (
            <CallActionsComponentV2 call={call} integration={integration} />
          ) : integration === Integrations.Zendesk ? null : (
            <CallActionsComponent call={call} integration={integration} />
          )}
          {integration === Integrations.Zendesk ? (
            <ZendeskContextSection call={call} key={call.id} />
          ) : (
            <CallContextSection callId={call.id} />
          )}
        </Box>
      </div>
    );
  };

  // The call history is no longer in browser storage, it's loaded asynchronously.
  // In SF classic there's a redirect to the last open page, so we can arrive here at app start.
  // If the call is not an active call, and the application initialization is finished, we have the PBX id, we trigger the call history loading.
  const shouldLoadCallHistory =
    !call && selectedOrganizationId && unifiedCallHistoryRequestState === UnifiedCallHistoryStateRequestState.INITIAL;
  const isCallLoading =
    !selectedOrganizationId ||
    (!call && unifiedCallHistoryRequestState === UnifiedCallHistoryStateRequestState.LOADING);

  if (shouldLoadCallHistory) {
    void getActionFacade().loadCallHistoryItems(selectedOrganizationId!, 0);
    return <FullPageAppLoading />;
  }

  if (isCallLoading) {
    return <FullPageAppLoading />;
  }

  if (!call) {
    return <RedirectKeepSearch pathname={ApplicationRoute.ACTIVE_CALLS_ROUTE} />;
  }

  return (
    <div className={classes.root}>
      <CallHeader call={call} />
      <SoftphoneContainer
        realtimeCall={call}
        render={(softphoneCall) => (
          <Box mx={-6} px={6} mb={6} bgcolor={tokens.backgroundColorSecondary}>
            <SoftphoneCallControl call={softphoneCall} />
          </Box>
        )}
      />
      <Box mb={4}>
        {isContactMatchingLoading ? (
          <CallMatchingProgressComponent integration={integration} call={call} />
        ) : (
          <CallCard call={call} />
        )}
      </Box>
      <Route path={ApplicationRoute.CALL_ROUTE_WITH_ACTION}>
        <CallActionForm integration={integration} callId={call.id} contactId={entity?.id} />
      </Route>
      <Route exact path={ApplicationRoute.CALL_ROUTE} render={() => renderCallRoot()} />
    </div>
  );
};
