import axios, { AxiosResponse } from 'axios';
import { Note } from '../notes/notes.models';
import { ClioEntity, Communication, Matter, ActivityCategory, ClioActivityDescription } from './clio.models';
import {
  ClioActivity,
  ClioActivityWithId,
  ClioClient,
  ClioCommunication,
  ClioCommunicationWithId,
  ClioContactPhoneNumber,
  ClioContactPhoneUpdate,
  ClioGetPageResponse,
  ClioGetResponse,
  ClioGetSingleResponse,
  ClioMatter,
  ClioNote,
  ClioRateLimitedResponse,
} from './clio.service.models';
import { phoneNumberTypeMapper } from '../search/entities.model';
import { logger } from '../logging';
import { getDigitsFromString } from '../phone/phone-utils';

export enum ClioApiUri {
  WHO_AM_I = '/api/v4/users/who_am_i.json',
  COMMUNICATIONS = '/api/v4/communications.json',
  ACTIVITIES = '/api/v4/activities.json',
  ACTIVITY_DESCRIPTIONS = '/api/v4/activity_descriptions.json',
  NOTES = '/api/v4/notes.json',
  MATTERS = '/api/v4/matters.json',
  CONTACTS = '/api/v4/contacts.json',
  CONTACT = '/api/v4/contacts/',
}

const CONTACT_FIELDS = 'id,name,phone_numbers{name,number},type';
const COMMUNICATION_FIELDS = 'id,subject,body,type,date,user,matter,senders,receivers,external_properties';
const ACTIVITY_FIELDS = 'id,type,date,quantity,note,non_billable,communication';
const ACTIVITY_DESCRIPTION_FIELDS = 'id,name';
const MATTER_FIELDS = 'id,display_number,description,client{id,name,type},updated_at';

function getSearchContactUrl(query: string) {
  return `${ClioApiUri.CONTACTS}?fields=${CONTACT_FIELDS}&query=${query}`;
}

function convertClioClientToEntity(contact: ClioClient): ClioEntity | undefined {
  if (!contact) {
    return undefined;
  }
  return {
    id: `${contact.id}`,
    name: contact.name,
    label: contact.name,
    phoneNumber: (contact.phone_numbers && contact.phone_numbers.length) > 0 ? contact.phone_numbers[0].number : '',
    phoneNumbers: (contact.phone_numbers || []).map((pn) => ({
      name: pn.name,
      number: pn.number,
    })),
    type: phoneNumberTypeMapper(contact.type),
  };
}

function filterClioContactsByNameOrPhoneNumber(contact: ClioClient, query: string): boolean {
  return contact?.phone_numbers?.some(
    (pn) =>
      getDigitsFromString(pn.number) === getDigitsFromString(query) ||
      contact.name.toLowerCase().trim().includes(query.toLowerCase().trim()),
  );
}

const searchContact = async (query: string): Promise<ClioEntity[]> => {
  const response = await axios.get<ClioGetPageResponse<ClioClient>>(getSearchContactUrl(query));
  return response.data.data
    .filter((contact) => filterClioContactsByNameOrPhoneNumber(contact, query))
    .map(convertClioClientToEntity)
    .filter((e): e is ClioEntity => e !== null);
};

const createContact = async (firstName, lastName, phoneNumber): Promise<string> => {
  const data = {
    data: {
      first_name: firstName,
      last_name: lastName,
      type: 'Person',
      phone_numbers: [
        {
          number: phoneNumber,
          name: 'Work',
          default_number: true,
        },
      ],
    },
  };

  const response = await axios.post(ClioApiUri.CONTACTS, data);

  return String(response.data.data.id);
};

const getContactUrl = (contactId: string): string => {
  return `${ClioApiUri.CONTACT}/${contactId}.json`;
};

const updateContactPhoneNumber = async (contactId: string, phoneNumber: string, defaultNumber: boolean = false) => {
  if (!isValidContactId(contactId)) {
    throw new Error('Invalid contact id');
  }

  const payload: ClioContactPhoneUpdate = {
    phone_numbers: [
      {
        default_number: defaultNumber,
        name: 'Work',
        number: phoneNumber,
      },
    ],
  };

  const data = {
    data: payload,
  };

  const url = getContactUrl(contactId);
  return axios.patch(url, data);
};

const extractOriginalHeader = (response: AxiosResponse<unknown>, header: string): string => {
  return response.headers.originalHeaders[header][0] as string;
};

const extractRateLimitHeaders = (response: AxiosResponse<unknown>) => {
  return {
    rateLimit: Number(extractOriginalHeader(response, 'X-RateLimit-Limit')),
    rateLimitRemaining: Number(extractOriginalHeader(response, 'X-RateLimit-Remaining')),
    rateLimitReset: Number(extractOriginalHeader(response, 'X-RateLimit-Reset')),
  };
};

const createCommunication = async (
  communication: ClioCommunication,
): Promise<ClioRateLimitedResponse<{ id: number }>> => {
  const response = await axios.post<{ data: { id: number } }>(ClioApiUri.COMMUNICATIONS, { data: communication });
  const rateLimit = extractRateLimitHeaders(response);
  return {
    data: response.data.data,
    rateLimit,
  };
};

const createActivity = async (activity: ClioActivity): Promise<ClioRateLimitedResponse<{ id: number }>> => {
  const response = await axios.post<{ data: { id: number } }>(ClioApiUri.ACTIVITIES, { data: activity });
  const rateLimit = extractRateLimitHeaders(response);
  return {
    data: response.data.data,
    rateLimit,
  };
};

const addNote = async (contactId: string, subject: string, detail: string) => {
  const now = new Date();
  const isoDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();

  const data = {
    data: {
      type: 'Contact',
      contact: { id: contactId },
      subject,
      detail,
      date: isoDate,
    },
  };
  const response = await axios.post<{ data: { id: string } }>(`${ClioApiUri.NOTES}`, data);
  return response.data.data.id;
};

async function whoAmI() {
  return await axios.get(`${ClioApiUri.WHO_AM_I}`);
}

export function getClioNotesUrl(contactId: string) {
  return `${ClioApiUri.NOTES}?type=Contact&fields=id,subject,detail,created_at&limit=1&order=date(desc)&contact_id=${contactId}`;
}

export interface GetNotesMeta {
  next?: string;
  recordsCount?: number;
}
export interface GetNotesResponse extends GetNotesMeta {
  notes: Note[];
}

function getNotesMeta(rawResponse: ClioGetPageResponse<ClioNote>): GetNotesMeta | undefined {
  if (!rawResponse.meta || !rawResponse.meta.paging) {
    throw new Error('No paging information in getNotes response!');
  }

  if (!rawResponse.meta.paging.next) {
    return undefined;
  }

  const nextUrl = new URL(rawResponse.meta.paging.next);
  const nextUrlForAmbassador = (nextUrl.pathname + nextUrl.search).replace('limit=1', 'limit=2');

  return {
    next: nextUrlForAmbassador,
    recordsCount: rawResponse.meta.records,
  };
}

function createGetNotesResponse(rawResponse: AxiosResponse<ClioGetPageResponse<ClioNote>>) {
  const notes = rawResponse.data.data.map((note: ClioNote) => {
    if (note.subject == null || note.detail == null) {
      // null or undefined
      logger.error('Subject or detail of note returned by Clio is null or undefined', { ...rawResponse, note });
    }

    return {
      id: String(note.id),
      subject: note.subject || '',
      content: note.detail || '',
      createdAtIso8601: note.created_at,
    } as Note;
  });

  const meta = getNotesMeta(rawResponse.data);

  if (!meta) {
    return { notes };
  }

  return {
    notes,
    ...meta,
  };
}

const getNotes = async (contactId: string): Promise<GetNotesResponse> => {
  if (!contactId) {
    throw new Error('Please specify the ID of the contact whose notes should be retrieved!');
  }

  if (!isValidContactId(contactId)) {
    throw new Error('Please specify a valid contact ID to get its notes!');
  }

  const url = getClioNotesUrl(contactId);
  const rawResponse = await axios.get<ClioGetPageResponse<ClioNote>>(url);

  return createGetNotesResponse(rawResponse);
};

const getMoreNotes = async (nextUrl: string): Promise<GetNotesResponse> => {
  const url = nextUrl;
  const rawResponse = await axios.get<ClioGetPageResponse<ClioNote>>(url);

  return createGetNotesResponse(rawResponse);
};

export const getMattersUrl = (params: { clientId?: string; query?: string }): string => {
  const { clientId, query } = params;

  const queryParams = new URLSearchParams({
    status: 'open,pending',
    order: 'updated_at(desc)',
    fields: MATTER_FIELDS,
  });

  if (clientId) {
    queryParams.append('client_id', clientId);
  }

  if (query) {
    queryParams.append('query', query);
  }

  return `${ClioApiUri.MATTERS}?${queryParams.toString()}`;
};

const searchMatters = async (query: string): Promise<Matter[]> => {
  const url = getMattersUrl({ query });
  const rawResponse: AxiosResponse<ClioGetResponse<ClioMatter>> = await axios.get<ClioGetResponse<ClioMatter>>(url);

  return rawResponse.data.data.map((matter: ClioMatter) => {
    return {
      id: matter.id,
      title: `${matter.display_number}: ${matter.description}`,
      client: convertClioClientToEntity(matter.client),
      updatedAt: matter.updated_at,
    };
  });
};

const getActivityCategories = async (): Promise<ActivityCategory[]> => {
  const rawResponse: AxiosResponse<ClioGetResponse<ClioActivityDescription>> = await axios.get<
    ClioGetResponse<ClioActivityDescription>
  >(ClioApiUri.ACTIVITY_DESCRIPTIONS, {
    params: {
      fields: ACTIVITY_DESCRIPTION_FIELDS,
    },
  });

  return rawResponse.data.data.map((activityDescription: ClioActivityDescription) => {
    return {
      id: activityDescription.id,
      title: activityDescription.name,
    };
  });
};

export function getCommunicationSearchUrl(params: { userId?: number; callId?: string }) {
  const { userId, callId } = params;

  const queryParams = new URLSearchParams({
    fields: COMMUNICATION_FIELDS,
  });

  if (userId) {
    queryParams.append('user_id', `${userId}`);
  }

  if (callId) {
    queryParams.append('external_property_name', 'jive_call_id');
    queryParams.append('external_property_value', callId);
  }

  return `${ClioApiUri.COMMUNICATIONS}?${queryParams.toString()}`;
}

export function getActivitySearchUrl(params: { communicationId: number }) {
  const { communicationId } = params;

  if (!communicationId) {
    throw Error('communicationId should be specified to get the activityUrl');
  }

  const queryParams = new URLSearchParams({
    fields: ACTIVITY_FIELDS,
  });

  queryParams.append('communication_id', `${communicationId}`);

  return `${ClioApiUri.ACTIVITIES}?${queryParams.toString()}`;
}

const getCommunicationByUserAndCallId = async (userId: number, callId: string): Promise<Communication | undefined> => {
  const clioCommunicationResponse = await axios.get<ClioGetPageResponse<ClioCommunicationWithId>>(
    getCommunicationSearchUrl({
      userId,
      callId,
    }),
  );
  const clioCommunication = clioCommunicationResponse.data.data ? clioCommunicationResponse.data.data[0] : undefined;
  if (clioCommunication) {
    const clioActivityResponse = await axios.get<ClioGetResponse<ClioActivityWithId>>(
      getActivitySearchUrl({ communicationId: clioCommunication.id }),
    );
    const clioActivity = clioActivityResponse.data.data ? clioActivityResponse.data.data[0] : undefined;

    return {
      id: clioCommunication.id,
      description: clioCommunication.body,
      subject: clioCommunication.subject,
      senders: clioCommunication.senders,
      receivers: clioCommunication.receivers,
      matterId: clioCommunication.matter ? clioCommunication.matter.id : undefined,
      isBillable: clioActivity ? !clioActivity.non_billable : undefined,
      activityId: clioActivity ? clioActivity.id : undefined,
      duration: clioActivity ? clioActivity.quantity : undefined,
    };
  }
};

const isValidContactId = (contactId: string): boolean => {
  // Clio contact IDs are 64-bit integers (19 numeric characters).
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  return !!contactId && !!('' + contactId).match(/^[0-9]{0,19}$/);
};

const getContactPhoneNumbers = async (contactId: string): Promise<ClioContactPhoneNumber[]> => {
  const url = `${getContactUrl(contactId)}?fields=${CONTACT_FIELDS}`;
  const response = await axios.get<ClioGetSingleResponse<ClioClient>>(url);

  if (!response.data || !response.data.data) {
    return [];
  }

  return response.data.data.phone_numbers;
};

export const ClioService = {
  createActivity,
  createCommunication,
  createContact,
  searchContact,
  getSearchContactUrl,
  whoAmI,
  addNote,
  getMoreNotes,
  getNotes,
  searchMatters,
  getActivityCategories,
  updateContactPhoneNumber,
  getCommunicationByUserAndCallId,
  getContactPhoneNumbers,
};
