import { makeActionCreator } from '../../actions/actionCreators';
import { MsTeamsAuthenticationActionsType } from '../constants';
import './authentication.listeners';
import * as authentication from './authentication.service';

export const signInRequest = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNIN_REQUEST)();
export const signInProcessing = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNING_PROCESSING)<
  authentication.SignInResult
>();
export const signInSuccess = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNIN_SUCCESS)<
  authentication.SignInDetails
>();
export const signInError = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNIN_ERROR)();

export const signOutRequest = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNOUT_REQUEST)();
export const signOutRequestError = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNOUT_REQUEST_ERROR)();
export const signOutSuccess = makeActionCreator(MsTeamsAuthenticationActionsType.SIGNOUT_SUCCESS)();

export const redirectSignIn = makeActionCreator(MsTeamsAuthenticationActionsType.REDIRECT_SIGN_IN)();
export const redirectSignOut = makeActionCreator(MsTeamsAuthenticationActionsType.REDIRECT_SIGN_OUT)();

export type MsTeamsAuthenticationActions =
  | ReturnType<typeof signInRequest>
  | ReturnType<typeof signInSuccess>
  | ReturnType<typeof signInError>
  | ReturnType<typeof signOutRequest>
  | ReturnType<typeof signOutRequestError>
  | ReturnType<typeof signOutSuccess>
  | ReturnType<typeof redirectSignIn>
  | ReturnType<typeof redirectSignOut>;
