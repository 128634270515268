import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { UpdateAvailableDialogBody, UpdateAvailableDialogBodyStateProps } from './UpdateAvailableDialogBody.component';

const mapStateToProps = (state): UpdateAvailableDialogBodyStateProps => ({
  integrationName: currentIntegrationNameSelector(state),
});

export default connect<UpdateAvailableDialogBodyStateProps, {}, AppState>(mapStateToProps)(UpdateAvailableDialogBody);
