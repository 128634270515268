import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../../actions/actionCreators';
import { callSelector, endedOrActiveCallSelector, getEntityInCallSelector } from '../../calls/calls.selector';
import { NewNoteFormProps } from '../../entityForms/NewNoteForm.component';
import { addNote } from '../../notes/notes.actions';
import { AppState } from '../../reducers';
import NewConnectWiseNoteForm, {
  NewConnectWiseNoteFormStateProps,
  NewConnectWiseNoteFormDispatchProps,
} from './NewConnectWiseNoteForm.component';

export default connect<
  NewConnectWiseNoteFormStateProps,
  NewConnectWiseNoteFormDispatchProps,
  NewNoteFormProps,
  AppState
>(
  (state: AppState, { callId }: NewNoteFormProps) => {
    const activeCall = callSelector(state, callId);

    return {
      isActiveCall: Boolean(activeCall && !activeCall.endTime),
      contactId: getEntityInCallSelector(state, callId)?.id,
      call: endedOrActiveCallSelector(state, callId),
    };
  },
  (dispatch: Dispatch<AppAction>) => ({
    addConnectWiseNote: (payload) => dispatch(addNote(payload)),
  }),
)(NewConnectWiseNoteForm);
