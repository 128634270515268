import { ActionFacade } from '../actionFacade/action.facade';
import { ZendeskCallLogActionCreator } from './callLog/zendesk.callLog.actionCreator';
import ZendeskActionCreatorFactory from './zendesk.actionCreator.factory';
import { CallLog } from '../calls/callEvents.action';
import { CallWithContact } from '../calls/calls.reducer';
import { TicketActionCreator } from './tickets/ticket.actioncreator';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';

export class ZendeskActionFacade extends ActionFacade {
  [CAPABILITY_SOFTPHONE] = true;

  private callLogActionCreator: ZendeskCallLogActionCreator;
  private ticketActionCreator: TicketActionCreator;

  constructor(actionCreatorFactory: ZendeskActionCreatorFactory) {
    super(actionCreatorFactory);
    this.callLogActionCreator = actionCreatorFactory.createCallLogActionCreator();
    this.ticketActionCreator = actionCreatorFactory.createTicketActionCreator();
  }

  async createCallLog(callLogToCreate: CallLog, call: CallWithContact): Promise<void> {
    return this.callLogActionCreator.createCallLog(callLogToCreate, call);
  }

  async searchTickets(requesterId?: string, searchString?: string): Promise<void> {
    return this.ticketActionCreator.searchTickets(requesterId, searchString);
  }

  async upsertAndOpenTicket(callId: string, shouldAddCallDetails: boolean, ticketId?: string): Promise<void> {
    return this.ticketActionCreator.upsertAndOpenTicket(callId, shouldAddCallDetails, ticketId);
  }

  async openTicket(ticketId: string): Promise<void> {
    return this.ticketActionCreator.openTicket(ticketId);
  }
}
