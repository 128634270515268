import * as embeddedActions from './embedded.action';
import { Reducer } from 'redux';
import { ActionTypes } from '../actions/actionCreators';

interface EmbeddedState {
  integrationPanelShownForCalls: string[];
}

export const EMBEDDED_INITIAL_STATE: EmbeddedState = {
  integrationPanelShownForCalls: [],
};

export const embeddedReducer: Reducer<EmbeddedState, ActionTypes<typeof embeddedActions>> = (
  state = EMBEDDED_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case embeddedActions.openIntegrationPanel.type:
    case embeddedActions.integrationPanelOpenElsewhere.type: {
      if (!action.payload?.call) {
        return state;
      }
      return {
        integrationPanelShownForCalls: [
          ...new Set([...state.integrationPanelShownForCalls, ...action.payload.call.allIds]),
        ],
      };
    }
  }
  return state;
};
