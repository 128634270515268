import { createMigrate, MigrationManifest, PersistedState } from 'redux-persist';
import { AUTHENTICATION_INITIAL_STATE } from './authentication/authentication.reducer';
import { AppState } from './reducers';

export const STORE_VERSION = 1;

const migrations: MigrationManifest = {
  0: (state) => {
    return state;
  },
  1: (state) => {
    const appState = state as AppState & PersistedState;
    const result =
      !appState || !appState.authentication || !appState.authentication.result
        ? AUTHENTICATION_INITIAL_STATE
        : appState.authentication.result;
    return {
      ...appState,
      authentication: { result },
    };
  },
};

export const migrator = createMigrate(migrations, { debug: true });
