import type { JifTenantPbx } from '@jive/core';
import { makeActionCreator } from '../actions/actionCreators';
import { IndexedById } from '../models';

export enum PbxesEntityActionsType {
  ADD = 'jiveEntities/pbxes/add',
  CLEAN = 'jiveEntities/pbxes/clean',
}

export const addPbxes = makeActionCreator(PbxesEntityActionsType.ADD)<IndexedById<JifTenantPbx>>();
export const cleanPbxes = makeActionCreator(PbxesEntityActionsType.CLEAN)();

export type PbxesEntityActions = ReturnType<typeof addPbxes> | ReturnType<typeof cleanPbxes>;
