import { makeActionCreator } from '../actions/actionCreators';
import { AmbassadorProvider, AmbassadorUserProvider } from './ambassador.service';

export enum AmbassadorActionsTypes {
  GET_PROVIDERS = 'ambassador/providers/get',
  GET_PROVIDERS_ERROR = 'ambassador/providers/get/error',
  GET_PROVIDERS_SUCCESS = 'ambassador/providers/get/success',
}

export const getAmbassadorProviders = makeActionCreator(AmbassadorActionsTypes.GET_PROVIDERS)();
export const getAmbassadorProvidersError = makeActionCreator(AmbassadorActionsTypes.GET_PROVIDERS_ERROR)();
export interface GetAmbassadorProvidersPayload {
  providers: AmbassadorProvider[];
  userProviders: AmbassadorUserProvider[];
}

export const getAmbassadorProvidersSuccess = makeActionCreator(AmbassadorActionsTypes.GET_PROVIDERS_SUCCESS)<
  GetAmbassadorProvidersPayload
>();

export type AmbassadorActions =
  | ReturnType<typeof getAmbassadorProviders>
  | ReturnType<typeof getAmbassadorProvidersSuccess>
  | ReturnType<typeof getAmbassadorProvidersError>;
