import { ActivityCategory } from '../clio.models';

export enum LoadActivityCategoriesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface ActivitiesState {
  requestState: LoadActivityCategoriesRequestState;
  activityCategories: ActivityCategory[];
}
