import configuration from '../../config';
import { currentIntegrationNameSelector, currentIntegrationSelector } from '../../integrations/integrations.selector';
import { selectedPbxSelector } from '../../jiveEntities/pbxesEntity.selector';
import { AppState } from '../../reducers';

export function getAuthorizationUri(state: AppState): string {
  const pbx = selectedPbxSelector(state);
  const currentIntegration = currentIntegrationSelector(state);
  if (!pbx) {
    throw new Error('Selected pbx is not found, cannot open integrations page');
  }
  return `${configuration.jiveUrl}/integrations/${pbx.domain}/${currentIntegration}?clientId=${configuration.ambassador.clientAppId}`;
}

export async function linkOrUnlinkAccounts(state: AppState): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const uri = getAuthorizationUri(state);
    const integrationName = currentIntegrationNameSelector(state);
    const popup = window.open(
      uri,
      `${integrationName} Authentication`,
      'width=850,height=1000,toolbar=0,menubar=0,location=0',
    );
    if (popup == null) {
      return reject('Cannot open popup window');
    }

    popup.focus();
    // addEventListener is not available here
    // only these are available: https://electronjs.org/docs/api/browser-window-proxy
    // => we need to poll the closed property to know when to check the ambassador link status
    // check the popup closed state every 1 seconds until it's closed
    (function checkIfPopupClosedAndResolve() {
      if (popup.closed) {
        resolve();
      } else {
        setTimeout(() => checkIfPopupClosedAndResolve(), 500);
      }
    })();
  });
}
