import { CallType } from '@jive/realtime-events';
import { format } from 'date-fns';
import { defineMessages, IntlShape } from 'react-intl';
import getDurationDisplay from '../formatCallDuration';
import { logger } from '../logging';
import { Entity } from '../models';
import { CallLog } from './callEvents.action';
import { CallWithContact, CallWithDuration } from './calls.reducer';
import { Locale } from 'date-fns';
import { UserCallHistoryItem } from '../callHistoryPush/callHistory.models';
import { FormattedMessageWithMessageValues } from '../reactInltModels';

const ONE_DAY_IN_SECONDS = 86400;

const definedMessages = defineMessages({
  CALL_LOG_OUTGOING_SUBJECT: {
    id: 'CallLog.Outgoing.Subject',
    defaultMessage: 'You called {name} on {date}',
  },
  CALL_LOG_INCOMING_SUBJECT: {
    id: 'CallLog.Incoming.Subject',
    defaultMessage: '{name} called you on {date}',
  },
  CALL_LOG_MISSED_SUBJECT: {
    id: 'CallLog.Missed.Subject',
    defaultMessage: '{name}: Missed call on {date}',
  },
  CALL_LOG_DECLINED_SUBJECT: {
    id: 'CallLog.Declined.Subject',
    defaultMessage: '{name} declined your call on {date}',
  },
  CALL_LOG_MESSAGE_STARTED: {
    id: 'CallLog.Message.Started',
    defaultMessage: 'Started: {startDateDisplay}',
  },
  CALL_LOG_MESSAGE_ENDED: {
    id: 'CallLog.Message.Ended',
    defaultMessage: 'Ended: {endDateDisplay}',
  },
  CALL_LOG_MESSAGE_DURATION: {
    id: 'CallLog.Message.Duration',
    defaultMessage: 'Duration: {duration}',
  },
  CALL_LOG_MESSAGE_DIRECTION: {
    id: 'CallLog.Message.Direction',
    defaultMessage: 'Direction: {direction}',
  },
  CALL_LOG_MESSAGE_FROM: {
    id: 'CallLog.Message.From',
    defaultMessage: 'From: {fromNumber}',
  },
  CALL_LOG_MESSAGE_TO: {
    id: 'CallLog.Message.To',
    defaultMessage: 'To: {toNumber}',
  },
  CALL_DETAILS_HEADER: {
    id: 'CallDetails.Header',
    defaultMessage: 'Call Details',
  },
  CALL_TYPE_INCOMING: {
    id: 'Call.Type.Incoming',
    defaultMessage: 'Incoming Call',
  },
  CALL_TYPE_OUTGOING: {
    id: 'Call.Type.Outgoing',
    defaultMessage: 'Outgoing Call',
  },
  CALL_TYPE_MISSING: {
    id: 'Call.Type.Missed',
    defaultMessage: 'Missed Call',
  },
  CALL_TYPE_DECLINED: {
    id: 'Call.Type.Declined',
    defaultMessage: 'Declined Call',
  },
});

export interface CallLogParams {
  callType: CallType;
  date: Date;
  contactName: string;
  intl: IntlShape;
  locale: Locale;
}

export function getCallLogObject(params: {
  call: CallWithContact;
  contact: Entity;
  intl: IntlShape;
  locale: Locale;
  callDurationInSeconds: number;
}): CallLog {
  const { call, intl, contact, callDurationInSeconds, locale } = params;

  const contactName = contact ? contact.name : call.theOtherParty.name || call.theOtherParty.number;

  return {
    EntityId: contact.id,
    Subject: getCallLogSubject({
      callType: call.type,
      date: new Date(call.creationTime),
      contactName,
      intl,
      locale,
    }),
    DurationInSeconds: callDurationInSeconds,
    Description: getCallLogDescription({ call, intl, locale, lineBreak: '\n' }),
    CallId: call.id,
  };
}

export function getCallLogObjectWithNoEntity(params: {
  call: CallWithContact;
  intl: IntlShape;
  locale: Locale;
  callDurationInSeconds: number;
}): Omit<CallLog, 'EntityId'> {
  const { call, intl, callDurationInSeconds, locale } = params;

  const contactName = call.theOtherParty.name || call.theOtherParty.number;

  return {
    Subject: getCallLogSubject({
      callType: call.type,
      date: new Date(call.creationTime),
      contactName,
      intl,
      locale,
    }),
    DurationInSeconds: callDurationInSeconds,
    Description: getCallLogDescription({ call, intl, locale, lineBreak: '\n' }),
    CallId: call.id,
  };
}

export function calculateCallDuration(call: CallWithContact, callHistoryItem: UserCallHistoryItem | undefined): number {
  if (callHistoryItem) {
    const { duration, startTime, answerTime } = callHistoryItem;
    if (!call.isClickToCall) {
      return Math.round(duration);
    } else {
      const answerTimeSeconds = answerTime ? answerTime.getTime() : 0;
      const startTimeSeconds = startTime ? startTime.getTime() : 0;
      if (answerTimeSeconds === 0 || startTimeSeconds === 0) {
        return Math.round(duration);
      }
      const normalizedCallDuration = duration - (answerTimeSeconds - startTimeSeconds) / 1000;
      // Call duration is longer when the call was initiated with clickToCall
      // Let's sanity check the calculated duration. Make sure it's not more than one day
      return Math.round(
        normalizedCallDuration > 0 && normalizedCallDuration <= ONE_DAY_IN_SECONDS ? normalizedCallDuration : duration,
      );
    }
  }
  const [startTime, endTime] = [call.creationTime, call.endTime || Date.now()];
  return Math.round(
    call.type === CallType.MissedCall || call.type === CallType.DeclinedCall ? 0 : (endTime - startTime) / 1000,
  );
}

export function getCallLogSubject(params: CallLogParams) {
  const { callType, intl, date, contactName, locale } = params;
  const displayDate = formatToDateString(date, locale);
  switch (callType) {
    case CallType.OutgoingCall: {
      return intl.formatMessage(definedMessages.CALL_LOG_OUTGOING_SUBJECT, { name: contactName, date: displayDate });
    }
    case CallType.IncomingCall: {
      return intl.formatMessage(definedMessages.CALL_LOG_INCOMING_SUBJECT, { name: contactName, date: displayDate });
    }
    case CallType.MissedCall: {
      return intl.formatMessage(definedMessages.CALL_LOG_MISSED_SUBJECT, { name: contactName, date: displayDate });
    }
    case CallType.DeclinedCall: {
      return intl.formatMessage(definedMessages.CALL_LOG_DECLINED_SUBJECT, { name: contactName, date: displayDate });
    }
    default:
      return '';
  }
}

export function getCallLogDescription(params: {
  call: CallWithDuration;
  intl: IntlShape;
  locale: Locale;
  lineBreak: string;
}) {
  const { call, intl, lineBreak, locale } = params;
  const { creationTime, endTime } = call;

  const startDate = new Date(creationTime);
  const startDateDisplay = formatToDateAndTimeString(startDate, locale);
  const endDateDisplay = endTime ? formatToDateAndTimeString(new Date(endTime), locale) : '-';
  const duration = endTime ? getDurationDisplay(call) : '-';

  const start = `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_STARTED, { startDateDisplay })}${lineBreak}`;
  const end = endTime
    ? `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_ENDED, { endDateDisplay })}${lineBreak}`
    : '';
  const durationString = endTime
    ? `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_DURATION, { duration })}`
    : '';

  return `${start}${end}${durationString}`;
}

export interface CallLogMessageDescriptions {
  start: FormattedMessageWithMessageValues;
  end: FormattedMessageWithMessageValues;
  duration: FormattedMessageWithMessageValues;
}

export function getCallLogDescriptionMessageDescriptors(
  call: CallWithDuration,
  locale: Locale,
): CallLogMessageDescriptions {
  const { creationTime, endTime } = call;

  const startDate = new Date(creationTime);
  const startDateDisplay = formatToDateAndTimeString(startDate, locale);
  const endDateDisplay = endTime ? formatToDateAndTimeString(new Date(endTime), locale) : '-';
  const duration = endTime ? getDurationDisplay(call) : '-';

  return {
    start: { ...definedMessages.CALL_LOG_MESSAGE_STARTED, values: { startDateDisplay } },
    end: { ...definedMessages.CALL_LOG_MESSAGE_ENDED, values: { endDateDisplay } },
    duration: { ...definedMessages.CALL_LOG_MESSAGE_DURATION, values: { duration } },
  };
}

export function getCallDetails(params: { call: CallWithDuration; intl: IntlShape; locale: Locale; lineBreak: string }) {
  const { call, intl, lineBreak } = params;

  const callLog = getCallLogDescription(params);

  const direction = `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_DIRECTION, {
    direction: getCallTypeMessage(params),
  })}${lineBreak}`;
  const from = `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_FROM, {
    fromNumber: call.caller.number,
  })}${lineBreak}`;
  const to = `- ${intl.formatMessage(definedMessages.CALL_LOG_MESSAGE_TO, {
    toNumber: call.callee.number,
  })}${lineBreak}`;
  const callDetails = `${direction}${from}${to}`;

  return `${callDetails}${callLog}`;
}

export function getCallTypeMessage(params: { call: CallWithDuration; intl: IntlShape }): string {
  switch (params.call.type) {
    case CallType.IncomingCall:
      return params.intl.formatMessage(definedMessages.CALL_TYPE_INCOMING);
    case CallType.OutgoingCall:
      return params.intl.formatMessage(definedMessages.CALL_TYPE_OUTGOING);
    case CallType.MissedCall:
      return params.intl.formatMessage(definedMessages.CALL_TYPE_MISSING);
    case CallType.DeclinedCall:
      return params.intl.formatMessage(definedMessages.CALL_TYPE_DECLINED);
    default:
      logger.error('Unexpected call type received', { callType: params.call.type });
      return 'Unknown Call';
  }
}

export function formatToDateAndTimeString(date: Date, locale: Locale): string {
  return format(date, 'PPPPpp', { locale });
}

export function formatToDateString(date: Date, locale: Locale): string {
  return format(date, 'PPPP', { locale });
}
