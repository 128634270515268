import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { LinkButton as NormalLinkButton } from './LinkButton.component';
import { Typography } from '../../theme/Typography.component';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentIntegrationNameSelector } from '../../integrations/integrations.selector';
import { proxyProviderAvailableSelector } from '../../ambassador/proxy/proxy.selector';
import { LinkButtonForOnboarding } from './LinkButtonForOnboarding.component';

const definedMessages = defineMessages({
  INTEGRATION_STATUS_UNAVAILABLE: {
    id: 'SettingsContainer.Integration.Unavailable',
    defaultMessage: '{integrationName} is not enabled on this PBX. Contact your admin.',
  },
});

export interface LinkAccountProps {
  variant?: 'normal' | 'onboarding';
}

export const LinkAccount: FC<LinkAccountProps> = ({ variant }) => {
  const providerAvailable = useSelector(proxyProviderAvailableSelector);
  const integrationName = useSelector(currentIntegrationNameSelector);

  const LinkButton: FC = () => {
    const variantToDisplay = variant ?? 'normal';
    switch (variantToDisplay) {
      case 'normal':
        return <NormalLinkButton />;
      case 'onboarding':
        return <LinkButtonForOnboarding />;
    }
  };

  return (
    <Box>
      <LinkButton />
      {providerAvailable === false && (
        <Box mt={1}>
          <Typography color="text-error-01" variant="caption-default-01">
            <FormattedMessage
              {...definedMessages.INTEGRATION_STATUS_UNAVAILABLE}
              values={{
                integrationName,
              }}
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
