import { Store } from 'redux';
import { AppState } from '../reducers';
import { ZendeskService } from '../zendesk/zendesk.service';
import { PresenceActionCreator } from './presence.actionCreator';
import { PresenceAppearance } from './presence.models';
import { isOffline, shouldDisplayPresenceOfflinePopup } from './presence.utils';

export class ZendeskPresenceActionCreator extends PresenceActionCreator {
  constructor(protected store: Store<AppState>, protected zendeskService: ZendeskService) {
    super(store);
  }
  async setPresence(appearance: PresenceAppearance): Promise<void> {
    await super.setPresence(appearance);
    if (isOffline(appearance) && shouldDisplayPresenceOfflinePopup()) {
      this.zendeskService.showIntegration();
    }
  }
}
