import { Entity, Integrations } from '../models';
import axios from 'axios';
import { CoxError } from './errors/cox.error';
import { CoxContactSearchError } from './errors/cox.contactSearchError';
import { format, parse, parseISO } from 'date-fns';
import {
  CoxCustomer,
  CoxCustomerExtended,
  CoxPartsInventoryRequestResult,
  CoxPartsInventoryResult,
  CoxVehicleLookupResult,
  CustomerSearchResult,
  AppointmentLookupResult,
  OpenRepairOrderLookupResult,
  GetClosedRepairOrdersResult,
  GetClosedRepairOrderDetailsResult,
  ClosedRepairOrderWithDetails,
  ClosedRepairOrder as CoxClosedRepairOrder,
  CoxServiceWritersTableOutput,
  CoxOpenRepairOrderLookupResult,
} from './cox.service.models';
import { Vehicle } from './vehicles/vehicles.models';
import { arrayify, sanitizeVIN } from './cox.utils';
import { ServiceAppointment, ServiceAppointmentStatus } from './serviceAppointments/serviceAppointments.models';
import { Customer } from './contact/cox.customer.models';
import {
  ClosedRepairOrder,
  OpenRepairOrder,
  RepairOrderStatus,
  RepairOrderDetails,
  RepairOrderDetailsLineType,
  ClosedRepairOrderDetails,
  ServiceWriter,
} from './repairOrders/repairOrders.models';

import { InventoryPartItem } from './parts/parts.models';

export const CoxApiUri = {
  CUSTOMER_API: '/customerapi.asmx',
  VEHICLE_API: '/vehicleapi.asmx',
  SERVICE_API: '/serviceapi.asmx',
  PARTS_API: '/partsapi.asmx',
};

const textOrDefault = (input: { _text?: string }): string => input?._text || '';

const numberOrDefault = (input: { _text?: string }): Optional<number> => {
  const textValue = textOrDefault(input);
  if (!textValue || textValue === '0') {
    return undefined;
  }

  return Number(textValue);
};

const dateOrDefaultBase = (input: { _text?: string }, formatString: string): Optional<Date> => {
  const textValue = textOrDefault(input);
  if (!textValue || textValue === '0') {
    return undefined;
  }

  const date = parse(textValue, formatString, 0);

  if (date.valueOf() === 0) {
    return undefined;
  }

  return date;
};

const dateOrDefault = (input: { _text?: string }): Optional<Date> => {
  return dateOrDefaultBase(input, 'yyyyMMdd');
};

const dateTimeOrDefault = (input: { _text?: string }): Optional<Date> => {
  return dateOrDefaultBase(input, 'yyyyMMddHHmm');
};

const dateTimeOrDefaultISO = (input: { _text?: string }): Optional<Date> => {
  const textValue = textOrDefault(input);

  if (!textValue || textValue === '0') {
    return undefined;
  }

  const date = parseISO(textValue);
  if (date.valueOf() === 0) {
    return undefined;
  }

  return date;
};

const createBody = (action: string, params: string, dealerTag: string = 'Dealer'): string => {
  return `
    <${action} xmlns="opentrack.dealertrack.com">
      <${dealerTag}>
        <EnterpriseCode>{{enterprise-code}}</EnterpriseCode>
        <CompanyNumber>{{company-number}}</CompanyNumber>
      </${dealerTag}>
      ${params}
    </${action}>    
  `;
};

const createHeaders = (action: string): Object => ({
  SOAPAction: `opentrack.dealertrack.com/${action}`,
});

const searchCustomerByName = async (name: string): Promise<Entity[]> => {
  const nameParts = name.split(' ').filter((i) => i);
  const lastName = nameParts.length > 1 ? nameParts[1] : nameParts[0];
  const firstName = nameParts.length > 1 ? nameParts[0] : '';

  const response = await axios.request({
    url: CoxApiUri.CUSTOMER_API,
    method: 'POST',
    data: createBody(
      'CustomerSearch',
      `<SearchParms> 
            <LastName>${lastName}</LastName>
            <FirstName>${firstName}</FirstName>
        </SearchParms>`,
    ),
    headers: createHeaders('CustomerSearch'),
  });
  const result = response.data.CustomerSearchResult as CustomerSearchResult;
  return handleCustomerSearchResult(result);
};

const searchCustomerByPhone = async (phone: string): Promise<Entity[]> => {
  // cox handles phone numbers as numbers => only digits are allowed
  // See: https://otwiki.dms.dealertrack.com/opentrack/index.php/CustomerSearch_Request
  const sanitizedNumber = phone.replace(/\D/g, '');

  if (sanitizedNumber.length > 10) {
    throw new CoxContactSearchError('Cox phone numbers are maximum 10 digit numbers.', sanitizedNumber);
  }

  const response = await axios.request({
    url: CoxApiUri.CUSTOMER_API,
    method: 'POST',
    data: createBody(
      'CustomerSearch',
      `<SearchParms> 
            <Phone>${sanitizedNumber}</Phone>
        </SearchParms>`,
    ),
    headers: createHeaders('CustomerSearch'),
  });
  const result = response.data.CustomerSearchResult as CustomerSearchResult;
  return handleCustomerSearchResult(result);
};

const lookupCustomerById = async (id: string): Promise<Customer> => {
  const response = await axios.request({
    url: CoxApiUri.CUSTOMER_API,
    method: 'POST',
    data: createBody('CustomerLookup', `<CustomerNumber>${id}</CustomerNumber>`),
    headers: createHeaders('CustomerLookup'),
  });

  const result = response.data.CustomerLookupResult;
  if (result.Result) {
    const customer = result.Result as CoxCustomerExtended;
    return {
      id: customer.CustomerNumber._text,
      vehicles: customer.Vehicles?.VIN ? arrayify(customer.Vehicles.VIN).map((v) => v._text) : [],
      assignedSalesPerson: customer.AssignedSalesperson?._text,
      cellPhone: customer.CellPhone?._text,
      businessPhone: customer.BusinessPhone?._text,
      phoneNumber: customer.PhoneNumber?._text,
      otherPhone: customer.OtherPhone?._text,
      pagePhone: customer.PagePhone?._text,
    } as Customer;
  } else {
    throw new CoxError('Error looking up Cox customer', result.Errors);
  }
};

const handleCustomerSearchResult = (result: CustomerSearchResult): Entity[] => {
  if (result.Errors) {
    if (result.Errors?.Error?.Code?._text === 'CS02') {
      // no matching customer
    } else {
      throw new CoxError('Error searching Cox customers', result.Errors);
    }
    return [];
  }

  if (!result.Result) {
    return [];
  }

  const customers = arrayify(result.Result);
  return customers.map(convertCustomerToEntity);
};

const convertCustomerToEntity = (customer: CoxCustomer): Entity => {
  const name = customer.FirstName._text
    ? `${customer.FirstName._text} ${customer.LastName._text}`
    : customer.LastName._text ?? '';
  return {
    id: customer.CustomerNumber._text,
    label: name,
    name,
    phoneNumber: customer.BusinessPhone._text || customer.CellPhone._text || customer.HomePhone._text,
    type: 'Contact',
    source: Integrations.Cox,
  };
};

const getVehicle = async (VIN: string): Promise<Vehicle> => {
  const sanitizedVIN = sanitizeVIN(VIN);

  const response = await axios.request<CoxVehicleLookupResult>({
    url: CoxApiUri.VEHICLE_API,
    method: 'POST',
    data: createBody(
      'VehicleLookup',
      `<LookupParms>
        <VIN>${sanitizedVIN}</VIN>
      </LookupParms>`,
    ),
    headers: createHeaders('VehicleLookup'),
  });

  if (response.data.VehicleLookupResult.Errors?.Error) {
    const errors = arrayify(response.data.VehicleLookupResult.Errors.Error);
    throw new CoxError(`Failed to load vehicle ${VIN}.`, errors);
  } else if (response.data.VehicleLookupResult.Result) {
    const result = response.data.VehicleLookupResult.Result;

    return {
      VIN: textOrDefault(result.VIN),
      Make: textOrDefault(result.Make),
      Model: textOrDefault(result.Model),
      DateDelivered: dateOrDefault(result.DateDelivered),
      LastServiceDate: dateOrDefault(result.LastServiceDate),
      ModelYear: textOrDefault(result.ModelYear),
      Odometer: numberOrDefault(result.Odometer),
      VehicleCost: numberOrDefault(result.VehicleCost),
      WarrantyMiles: numberOrDefault(result.WarrantyMiles),
      WarrantyMonths: numberOrDefault(result.WarrantyMonths),
    };
  }

  throw Error('Unexpected xml returned from VehicleLookup');
};

const createVehicle = async (customerKey: string, vehicle: Vehicle): Promise<void> => {
  const sanitizedVIN = sanitizeVIN(vehicle.VIN);

  await axios.request<CoxVehicleLookupResult>({
    url: CoxApiUri.VEHICLE_API,
    method: 'POST',
    data: createBody(
      'VehicleAdd',
      `<Vehicle>
          <CompanyNumber>ZE7</CompanyNumber>
          <VIN>${sanitizedVIN}</VIN>
          <Status>C</Status>
          <GLApplied>Y</GLApplied>
          <TypeNU>U</TypeNU>
          <ModelYear>${vehicle.ModelYear}</ModelYear>
          <Make>${vehicle.Make}</Make>
          <Model>${vehicle.Model}</Model>
          <Odometer>${vehicle.Odometer}</Odometer>
          <DateDelivered>${format(vehicle.DateDelivered!, 'yyyyMMdd')}</DateDelivered>
          <LastServiceDate>${format(vehicle.LastServiceDate!, 'yyyyMMdd')}</LastServiceDate>
          <WarrantyMonths>${vehicle.WarrantyMonths}</WarrantyMonths>
          <WarrantyMiles>${vehicle.WarrantyMiles}</WarrantyMiles>
          <VehicleCost>${vehicle.VehicleCost}</VehicleCost>
          <CustomerKey>${customerKey}</CustomerKey>
      </Vehicle>`,
    ),
    headers: createHeaders('VehicleAdd'),
  });
};

const getServiceAppointmentsByVIN = async (VIN: string): Promise<ServiceAppointment[]> => {
  const sanitizedVIN = sanitizeVIN(VIN);
  const today = format(new Date(), 'yyyyMMdd');
  const response = await axios.request({
    url: CoxApiUri.SERVICE_API,
    method: 'POST',
    data: createBody(
      'AppointmentLookup',
      `<LookupParms>
        <VIN>${sanitizedVIN}</VIN>
        <DateFrom>${today}</DateFrom>
      </LookupParms>`,
    ),
    headers: createHeaders('AppointmentLookup'),
  });

  const result = response.data.AppointmentLookupResult as AppointmentLookupResult;
  if (result.Result) {
    return arrayify(result.Result).map<ServiceAppointment>((appointment) => ({
      id: textOrDefault(appointment.AppointmentId),
      // Based on API docs AppointmentDateTime is required.
      appointmentDateTime: dateTimeOrDefault(appointment.AppointmentDateTime)!,
      appointmentNumber: textOrDefault(appointment.AppointmentNumber),
      totalEstimate: numberOrDefault(appointment.TotalEstimate),
      status: textOrDefault(appointment.ROStatus) as ServiceAppointmentStatus,
    }));
  } else {
    // AL01 No appointments found.
    if (result.Errors?.Error.Code._text === 'AL01') {
      return [];
    }

    throw new CoxError('Error looking up Cox service appointment', result.Errors?.Error);
  }
};

const getOpenRepairOrdersByVIN = async (VIN: string): Promise<OpenRepairOrder[]> => {
  const sanitizedVIN = sanitizeVIN(VIN);

  const response = await axios.request({
    url: CoxApiUri.SERVICE_API,
    method: 'POST',
    data: createBody(
      'OpenRepairOrderLookup',
      `<LookupParms>
        <VIN>${sanitizedVIN}</VIN>
      </LookupParms>`,
    ),
    headers: createHeaders('OpenRepairOrderLookup'),
  });

  const result = response.data.OpenRepairOrderLookupResult as OpenRepairOrderLookupResult;

  if (result.Result) {
    return arrayify(result.Result).map((result) => ({
      repairOrderNumber: textOrDefault(result.RepairOrderNumber),
      status: textOrDefault(result.ROStatus) as RepairOrderStatus,
      promisedDateTime: dateTimeOrDefault(result.PromisedDateTime),
      lastModified: dateTimeOrDefaultISO(result.LastModified),
      totalEstimate: sumOpenRepairOrderCosts(result),
      serviceWriterId: textOrDefault(result.ServiceWriterID),
      details: result.Details?.OpenRepairOrderDetail
        ? arrayify(result.Details.OpenRepairOrderDetail).map<RepairOrderDetails>((details) => ({
            lineType: textOrDefault(details.LineType) as RepairOrderDetailsLineType,
            manufacturer: textOrDefault(details.Manufacturer),
            partNumber: textOrDefault(details.PartNumber),
            price: numberOrDefault(details.NetPrice),
            dateTimeLineCompleted: dateOrDefault(details.DateTimeLineCompleted),
            transDate: dateOrDefault(details.TransDate),
          }))
        : [],
    }));
  } else {
    // OROL02 No open repair orders found.
    if (result.Errors?.Error.Code._text === 'OROL02') {
      return [];
    }

    throw new CoxError('Error looking up Cox open repair order', result.Errors?.Error);
  }
};

const sumOpenRepairOrderCosts = (openRepairOrder: CoxOpenRepairOrderLookupResult) => {
  const keysToSum: Array<keyof CoxOpenRepairOrderLookupResult> = [
    'LaborTotal',
    'PartsTotal',
    'SubletTotal',
    'SCDeductPaid',
    'WarrantyDeduct',
    'CustomerPayShopSup',
    'CustomerPayHazardousMaterial',
    'CustomerPayTax1',
    'CustomerPayTax2',
    'CustomerPayTax3',
    'CustomerPayTax4',
    'SpecialOrderDeposit',
    'TotalCouponDiscount',
  ];

  const roLevelCost = keysToSum.reduce((sum, key: string) => sum + (numberOrDefault(openRepairOrder[key]) || 0), 0);

  const repairOrderFees = arrayify(openRepairOrder.Fees?.RepairOrderFee ?? []);
  const detailsLevelCost = repairOrderFees
    .filter((fee) => fee?.LinePaymentMethod?._text === 'C') // detailed in https://getjive.atlassian.net/browse/JIF-4826
    .reduce((sum, fee) => sum + (numberOrDefault(fee.TotalFeesAmount) || 0), 0);

  const paintAndMaterialCost = arrayify(openRepairOrder.Details?.OpenRepairOrderDetail ?? [])
    .filter((detail) => detail.LinePaymentMethod?._text === 'C' && detail.TransCode?._text === 'PM')
    .reduce((sum, price) => sum + (numberOrDefault(price.NetPrice) || 0), 0);

  return roLevelCost + detailsLevelCost + paintAndMaterialCost;
};

const getClosedRepairOrdersByVIN = async (VIN: string): Promise<ClosedRepairOrder[]> => {
  const sanitizedVIN = sanitizeVIN(VIN);

  const response = await axios.request({
    url: CoxApiUri.SERVICE_API,
    method: 'POST',
    data: createBody(
      'GetClosedRepairOrders',
      `<request>
        <VIN>${sanitizedVIN}</VIN>
      </request>`,
      'dealer',
    ),
    headers: createHeaders('GetClosedRepairOrders'),
  });

  const result = response.data.GetClosedRepairOrdersResponse
    ?.GetClosedRepairOrdersResult as GetClosedRepairOrdersResult;

  if (result.ClosedRepairOrders) {
    return arrayify(result.ClosedRepairOrders.ClosedRepairOrder || []).map((result) => ({
      repairOrderNumber: textOrDefault(result.RepairOrderNumber),
      closeDate: dateOrDefault(result.CloseDate),
      serviceWriterId: textOrDefault(result.ServiceWriterID),
    }));
  } else {
    // GCRO02 No closed repair orders found.
    if (result.Errors?.Error.Code._text === 'GCRO02') {
      return [];
    }

    throw new CoxError('Error looking up Cox closed repair order', result.Errors?.Error);
  }
};

const sumClosedRepairOrderCosts = (closedRepairOrder: ClosedRepairOrderWithDetails) => {
  const keysToSum: Array<keyof CoxClosedRepairOrder> = [
    'LaborTotal',
    'PartsTotal',
    'SubletTotal',
    'ServiceContractDeductPaid',
    'WarrantyDeduct',
    'CustomerPayShopSup',
    'CustomerPayHazardousMaterials',
    'CustomerPaySaleTax',
    'CustomerPaySaleTax2',
    'CustomerPaySaleTax3',
    'CustomerPaySaleTax4',
    'SpecialOrderDeposits',
    'TotalCouponDiscount',
  ];

  const roLevelCost = keysToSum.reduce((sum, key) => sum + (numberOrDefault(closedRepairOrder[key]) || 0), 0);

  const repairOrderFees = arrayify(closedRepairOrder.Fees?.RepairOrderFee ?? []);
  const detailsLevelCost = repairOrderFees
    .filter((fee) => fee?.LinePaymentMethod?._text === 'C') // detailed in https://getjive.atlassian.net/browse/JIF-4826
    .reduce((sum, fee) => sum + (numberOrDefault(fee.TotalFeesAmount) || 0), 0);

  // get all Labor with LinePaymentMethod=C that was about Paints & Materials
  const paintAndMaterialCost = arrayify(closedRepairOrder.Details?.ClosedRepairOrderDetail ?? []).reduce(
    (sum, closedRODetail) => {
      return (
        sum +
        arrayify(closedRODetail.LaborDetails?.Labor ?? [])
          .filter((labor) => labor.LinePaymentMethod?._text === 'C' && labor.TransactionCode?._text === 'PM')
          .reduce((laborSum, labor) => laborSum + (numberOrDefault(labor.LaborAmount) || 0), 0)
      );
    },
    0,
  );

  return roLevelCost + detailsLevelCost + paintAndMaterialCost;
};

const getClosedRepairOrderDetails = async (requestOrderNumber: string): Promise<ClosedRepairOrderDetails> => {
  const response = await axios.request({
    url: CoxApiUri.SERVICE_API,
    method: 'POST',
    data: createBody(
      'GetClosedRepairOrderDetails',
      `<request>
        <RepairOrderNumber>${requestOrderNumber}</RepairOrderNumber>
      </request>`,
      'dealer',
    ),
    headers: createHeaders('GetClosedRepairOrderDetails'),
  });

  const result = response.data.GetClosedRepairOrderDetailsResponse as GetClosedRepairOrderDetailsResult;
  const closedRepairOrder = result?.GetClosedRepairOrderDetailsResult?.ClosedRepairOrder;

  if (!closedRepairOrder) {
    throw new CoxError('Error loading up Cox closed repair order details', result.Errors?.Error);
  }

  return {
    repairOrderNumber: textOrDefault(closedRepairOrder.RepairOrderNumber),
    priceCharged: sumClosedRepairOrderCosts(closedRepairOrder),
  };
};

const getPart = async (partNumber: string, manufacturer: string): Promise<InventoryPartItem | undefined> => {
  const response = await axios.request({
    url: CoxApiUri.PARTS_API,
    method: 'POST',
    data: createBody(
      'PartsInventory',
      `<InventoryParms>
        <Manufacturer>${manufacturer}</Manufacturer>
        <PartNumber>${partNumber}</PartNumber>
      </InventoryParms>`,
    ),
    headers: createHeaders('PartsInventory'),
  });
  const result = response.data.PartsInventoryResult as CoxPartsInventoryRequestResult;

  // Based on API doc, if we specify a part number, we'll only get a single part.
  const part = result.Result as CoxPartsInventoryResult | undefined;
  if (part) {
    return {
      partNumber: textOrDefault(part.PartNumber)!,
      partDescription: textOrDefault(part.PartDescription),
      manufacturer: textOrDefault(part.Manufacturer),
    };
  } else {
    // PI01 No records found.
    if (result.Errors?.Error.Code._text === 'PI01') {
      return undefined;
    }

    throw new CoxError(
      `Error looking up Cox part with partNumber ${partNumber} and manufacturer ${manufacturer}`,
      result.Errors?.Error,
    );
  }
};

const getServiceWriters = async (): Promise<ServiceWriter[]> => {
  const response = await axios.request({
    url: CoxApiUri.SERVICE_API,
    method: 'POST',
    data: createBody('ServiceWritersTableRequest', ''),
    headers: createHeaders('ServiceWritersTable'),
  });

  const result = response.data.ServiceWritersTableResult as CoxServiceWritersTableOutput;

  if (result?.Result) {
    const serviceWriters = arrayify(result.Result);
    return serviceWriters.map((sw) => ({
      id: textOrDefault(sw.ID),
      name: textOrDefault(sw.Name),
    }));
  } else {
    // SWT01 No records found.
    if (result?.Errors?.Error?.Code?._text === 'SWT01') {
      return [];
    }

    throw new CoxError('Error looking up Cox service writers.', result.Errors.Error);
  }
};

export const CoxService = {
  searchCustomerByName,
  searchCustomerByPhone,
  lookupCustomerById,
  getVehicle,
  createVehicle,
  getServiceAppointmentsByVIN,
  getServiceWriters,
  getOpenRepairOrdersByVIN,
  getClosedRepairOrdersByVIN,
  getClosedRepairOrderDetails,
  getPart,
};
