import { AddNotePayload } from '../notes/notes.models';
import { OpenCrmFormModel } from './actionCreators/crmForm/crmForm.model';
import { ActionFacade } from './action.facade';

export const CAPABILITY_DOWNLOAD_NOTES = 'CAPABILITY_DOWNLOAD_NOTES';
export const CAPABILITY_LOAD_NOTES_COUNT = 'CAPABILITY_LOAD_NOTES_COUNT';
export const CAPABILITY_ADD_NOTES = 'CAPABILITY_ADD_NOTES';
export const CAPABILITY_OPEN_NOTES = 'CAPABILITY_OPEN_NOTES';
export const CAPABILITY_OPEN_FORM_IN_CRM = 'CAPABILITY_OPEN_FORM_IN_CRM';
export const CAPABILITY_DOWNLOAD_OPPORTUNITIES = 'CAPABILITY_DOWNLOAD_OPPORTUNITIES';
export const CAPABILITY_DOWNLOAD_CASES = 'CAPABILITY_DOWNLOAD_CASES';
export const CAPABILITY_VEHICLES = 'CAPABILITY_VEHICLES';
export const CAPABILITY_CONVERSATIONS = 'CAPABILITY_CONVERSATIONS';
export const CAPABILITY_ACCESS_LEAD = 'CAPABILITY_ACCESS_LEAD';
export const CAPABILITY_SOFTPHONE = 'CAPABILITY_SOFTPHONE';
export const CAPABILITY_AUTO_CALL_ASSIGNMENT = 'CAPABILITY_AUTO_CALL_ASSIGNMENT';

export type Capability =
  | typeof CAPABILITY_DOWNLOAD_NOTES
  | typeof CAPABILITY_LOAD_NOTES_COUNT
  | typeof CAPABILITY_ADD_NOTES
  | typeof CAPABILITY_OPEN_NOTES
  | typeof CAPABILITY_OPEN_FORM_IN_CRM
  | typeof CAPABILITY_DOWNLOAD_OPPORTUNITIES
  | typeof CAPABILITY_DOWNLOAD_CASES
  | typeof CAPABILITY_VEHICLES
  | typeof CAPABILITY_CONVERSATIONS
  | typeof CAPABILITY_SOFTPHONE
  | typeof CAPABILITY_AUTO_CALL_ASSIGNMENT
  | typeof CAPABILITY_ACCESS_LEAD;

export interface DownloadNotesCapableFacade extends ActionFacade {
  [CAPABILITY_DOWNLOAD_NOTES]: true;

  loadMostRecentNoteOfContact(contactId: string): Promise<void>;
  loadMoreNotesOfContact(): Promise<void>;
}

export interface LoadNotesCountCapableFacade extends ActionFacade {
  [CAPABILITY_LOAD_NOTES_COUNT]: true;

  loadNotesCount(contactId: string): Promise<void>;
}

export interface AddNoteCapableFacade extends ActionFacade {
  [CAPABILITY_ADD_NOTES]: true;

  addNote(note: AddNotePayload): Promise<void>;
}

export interface OpenNotesCapableFacade extends ActionFacade {
  [CAPABILITY_OPEN_NOTES]: true;

  openNote(id: string): Promise<void>;
}

export interface OpenFormInCrmCapableFacade extends ActionFacade {
  [CAPABILITY_OPEN_FORM_IN_CRM]: true;

  openFormInCrm(model: OpenCrmFormModel): Promise<void>;
}

export interface DownloadCasesCapableFacade extends ActionFacade {
  [CAPABILITY_DOWNLOAD_CASES]: true;

  getCasesCount(contactId: string): Promise<void>;
}

export interface DownloadOpportunitiesCapableFacade extends ActionFacade {
  [CAPABILITY_DOWNLOAD_OPPORTUNITIES]: true;

  getOpportunitiesCount(contactId: string): Promise<void>;
}

export interface VehiclesCapableFacade extends ActionFacade {
  [CAPABILITY_VEHICLES]: true;

  loadVehicles(VINs: string[]): Promise<void>;
  loadServiceAppointmentsForVehicle(VIN: string): Promise<void>;
  loadOpenRepairOrdersForVehicles(VINs: string[]): Promise<void>;
  loadClosedRepairOrdersForVehicle(VIN: string): Promise<void>;
  loadClosedRepairOrderDetails(VIN: string, repairOrderNumber: string): Promise<void>;
  loadCustomer(customerNumber: string): Promise<void>;
  loadPart(partNumber: string, manufacturer: string): Promise<void>;
}

export interface SoftphoneCapableFacade extends ActionFacade {
  makeCall(callee: string): Promise<void>;
  answerCall(callId: string): Promise<void>;
  hangupCall(callId: string): Promise<void>;
  rejectCall(callId: string): Promise<void>;
  muteCall(callId: string): Promise<void>;
  unmuteCall(callId: string): Promise<void>;
  holdCall(callId: string): Promise<void>;
  resumeCall(callId: string): Promise<void>;
  sendDtmf(callId: string, value: string): Promise<void>;
  openMicrophonePermissionPage(): Promise<void>;
}
