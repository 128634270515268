import { createStyles, Theme, makeStyles, Grid, Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React, { useMemo } from 'react';
import { CallWithContact } from './calls.reducer';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Typography } from '../theme/Typography.component';
import { useDispatch, useSelector } from 'react-redux';
import { getContactDisplayFromCall, getEntityInCallSelector, getSelectedContactInCall } from './calls.selector';
import { AppState } from '../reducers';
import { CallerIdComponent } from './CallerId.component';
import { CallActionButton } from './callActions';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { ExternalLinkIcon } from '@getgo/chameleon-icons/react';
import { openEntity } from '../search/entities.action';
import { newTracker } from '../analytics-new/tracker-new';
import { call as callAction } from '../phone/phone.actions';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { Integrations } from '../models';
import { EntityType } from '../constants';
import ContextMenu from '../components/ContextMenu';
import { CallCardMore } from './CallCardMore.component';

const definedMessages = defineMessages({
  OPEN_CONTACT_IN_CRM: {
    id: 'SearchResult.OpenInCRM',
    defaultMessage: 'Open contact in CRM',
  },
  ENTITY_TYPE_CONTACT: {
    id: 'Call.EntityType.Contact',
    defaultMessage: 'Contact',
  },
  ENTITY_TYPE_ACCOUNT: {
    id: 'Call.EntityType.Account',
    defaultMessage: 'Account',
  },
  ENTITY_TYPE_LEAD: {
    id: 'Call.EntityType.Lead',
    defaultMessage: 'Lead',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3),
      borderRadius: '5px',
      backgroundColor: tokens.uiBase01,
    },
    container: {
      marginBottom: theme.spacing(1),
    },
    moreIconButton: {
      height: '22px',
    },
  }),
);

export interface CallCardProps {
  call: CallWithContact;
}

export const CallCard: React.FC<CallCardProps> = ({ call }) => {
  const classes = useStyles();
  const entity = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));
  const label = useSelector((state: AppState) => getContactDisplayFromCall(state, call.id));
  const integration = useSelector(currentIntegrationSelector);
  const dispatch = useDispatch();

  const matchTypeMessage = useMemo(() => {
    if (![Integrations.SalesforceClassic, Integrations.SalesforceLightning].includes(integration)) {
      return undefined;
    }

    switch (entity?.type) {
      case EntityType.ACCOUNT:
        return definedMessages.ENTITY_TYPE_ACCOUNT;
      case EntityType.CONTACT:
        return definedMessages.ENTITY_TYPE_CONTACT;
      case EntityType.LEAD:
        return definedMessages.ENTITY_TYPE_LEAD;
      default:
        return undefined;
    }
  }, [integration, entity]);

  const callback = (selectedCall: CallWithContact) => {
    dispatch(
      callAction({
        phoneNumber: selectedCall.theOtherParty.number,
        contact: getSelectedContactInCall(selectedCall),
      }),
    );

    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Call,
      action: AnalyticsAction.ItemClicked,
      label: 'Callback icon | Button',
    });
  };

  return (
    <Box className={`${classes.root} callcard`}>
      <Box mb={3}>
        <Grid container>
          <Grid container item className={classes.container}>
            <Grid item xs>
              <Box mb={1}>
                <Typography variant="body-medium">
                  <b>{label}</b>
                </Typography>
              </Box>
            </Grid>
            {entity === undefined ? null : (
              <Grid item xs="auto">
                <ContextMenu
                  variant="horizontal"
                  buttonSize="small"
                  iconClass={classes.moreIconButton}
                  moreButtonLabel=""
                >
                  <CallCardMore entity={entity} />
                </ContextMenu>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs>
            <CallerIdComponent call={call} onPhoneNumberClick={() => callback(call)} />
          </Grid>
          {matchTypeMessage ? (
            <Grid item xs="auto">
              <Typography variant="body-xsmall" color="text-02">
                <FormattedMessage {...matchTypeMessage} />
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      {entity ? (
        <CallActionButton
          trailingIcon={<ExternalLinkIcon />}
          label={definedMessages.OPEN_CONTACT_IN_CRM}
          call={call}
          contact={entity}
          action={({ contact }) => dispatch(openEntity({ entity: contact! }))}
          variant="secondary"
          trackingEvent={{
            label: `Open contact in CRM | button | section contact-details | ${call.type}`,
            action: AnalyticsAction.ItemClicked,
            category: AnalyticsCategory.Contact,
          }}
        />
      ) : null}
    </Box>
  );
};
