import { getActiveRoute } from '../browserHistory';
import config, { baseAppURI, Environment } from '../config';
import { Integrations } from '../models';
import { AuthType } from './authentication.actions';
import { ApplicationRoute } from '../constants';

type UrlOpener = (url: string) => void;

if (!window.addIframe) {
  window.addIframe = (iframe: HTMLIFrameElement) => {
    document.body.appendChild(iframe);
  };
}

window.setLocation = (url: string) => {
  window.location.href = url;
};

const openInCurrentTab: UrlOpener = (url) => window.setLocation(url);
const openInNewTab: UrlOpener = (url) => window.open(url, '_blank');
const openInIframe = (url: string) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.position = 'fixed';
  iframe.style.top = '-100px';
  iframe.style.left = '-100px';

  window.addIframe(iframe);
};

const doAuth = {
  [AuthType.Redirect]: openInCurrentTab,
  [AuthType.NewTab]: openInNewTab,
};

const integrationDefaultAuthType = {
  [Integrations.Clio]: AuthType.Redirect,
  [Integrations.Redtail]: AuthType.Redirect,
  [Integrations.ConnectWise]: AuthType.Redirect,
  [Integrations.SalesforceLightning]: AuthType.NewTab,
  [Integrations.SalesforceClassic]: AuthType.NewTab,
  [Integrations.Zendesk]: AuthType.NewTab,
  [Integrations.Dynamics]: AuthType.NewTab,
  [Integrations.Cox]: AuthType.Redirect,
};

function getAuthType(requestedAuthType: AuthType, integration: Integrations): AuthType {
  if (requestedAuthType !== AuthType.IntegrationDefault) {
    return requestedAuthType;
  }
  return integrationDefaultAuthType[integration] as AuthType;
}

const getRedirectUrl = (path: 'login' | 'logout'): string => {
  // for feature branch deployments
  if (
    window.environment === Environment.DEV &&
    window.location.host.includes('embedded-integrations.dev.gtc.goto.com')
  ) {
    return `https://embedded-integrations.dev.gtc.goto.com/${path}`;
  }
  // for feature branch deployments
  if (
    window.environment === Environment.DEV &&
    window.location.host.includes('embedded-integrations.ed.gtc.goto.com')
  ) {
    return `https://embedded-integrations.ed.gtc.goto.com/${path}`;
  }
  return `${document.baseURI}${path}`;
};

function getOAuthUrl(
  baseUrl: string,
  params: {
    clientId: string;
    state?: string;
    redirectUrl: string;
    responseType?: 'token' | 'code';
    gateway?: boolean;
  },
): string {
  const { clientId, state, redirectUrl, responseType, gateway } = params;
  const searchParams = new URLSearchParams();
  searchParams.append('client_id', clientId);
  searchParams.append('redirect_uri', redirectUrl);

  if (responseType) {
    searchParams.append('response_type', responseType);
  }

  if (state) {
    searchParams.append('state', state);
  }

  // the gateway param tells the Auth server to return an error instead redirecting to the Login
  // when the user in not logged in
  if (gateway) {
    searchParams.append('gateway', 'true');
  }

  const url = new URL(baseUrl);
  url.search = searchParams.toString();
  return url.toString();
}

function getLoginUrl(params: {
  integration: Integrations;
  gateway?: boolean;
  redirectToCurrentPath?: boolean;
}): string {
  const { clientId, loginUrl } = config.auth;
  const { integration, gateway, redirectToCurrentPath } = params;

  const { route } = getActiveRoute(undefined, true);
  const state = JSON.stringify({
    pathToRedirect: redirectToCurrentPath ? route : undefined,
    integration,
    loginSuccessPath: `${baseAppURI}login-success`,
    loginSuccessOrigin: window.location.origin,
    isTokenRefresh: !!gateway,
  });

  return getOAuthUrl(loginUrl, {
    clientId,
    state,
    redirectUrl: getRedirectUrl('login'),
    responseType: 'token',
    gateway,
  });
}

function startLogin(integration: Integrations, requestedAuthType: AuthType): void {
  const authType = getAuthType(requestedAuthType, integration);
  const loginUrl = getLoginUrl({ integration, redirectToCurrentPath: authType === AuthType.Redirect });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  doAuth[authType](loginUrl);
}

function startLogout(integration: Integrations, requestedAuthType: AuthType): void {
  const { clientId, logoutUrl } = config.auth;
  const state = JSON.stringify({
    pathToRedirect: ApplicationRoute.ONBOARDING_LOGIN,
    integration,
    logoutSuccessOrigin: window.location.origin,
    logoutSuccessPath: `${baseAppURI}logout-success`,
  });
  const logoutUrlWithParams = getOAuthUrl(logoutUrl, {
    clientId,
    state,
    redirectUrl: getRedirectUrl('logout'),
  });
  const authType = getAuthType(requestedAuthType, integration);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  doAuth[authType](logoutUrlWithParams);
}

function startTokenRefresh(integration: Integrations): void {
  const loginUrl = getLoginUrl({ integration, gateway: true });
  openInIframe(loginUrl);
}

export const OAuthService = {
  startLogin,
  startLogout,
  startTokenRefresh,
};
