import { Button, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { createStyles, makeStyles, Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React, { useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { logger } from '../../logging/logger';
import { salesforceLastCallLogErrorSelector } from '../../salesforce/salesforce.selectors';
import { CopyInactiveIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '../../theme/Typography.component';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';

const definedMessages = defineMessages({
  BODY: {
    id: 'Salesforce.CallLog.Failed.Dialog.Body',
    defaultMessage: "We couldn't record your activity. Please finish editing your call log in Salesforce.",
  },
  SEND_TO_ADMIN: {
    id: 'Salesforce.Dialog.InitError.SendToAdmin',
    defaultMessage: 'You can send this error message to your IT Admin:',
  },
  COPY_BUTTON: {
    id: 'Salesforce.Dialog.InitError.CopyButton',
    defaultMessage: 'Copy text to clipboard',
  },
  COPY_BUTTON_SHORT: {
    id: 'Salesforce.Dialog.InitError.CopyButtonShort',
    defaultMessage: 'Copy to clipboard',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    hidden: {
      height: 0,
      position: 'absolute',
      top: '-200px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    description: {
      marginBottom: theme.spacing(4),
    },
    error: {
      maxHeight: '100px',
      maxWidth: '100%',
      overflow: 'hidden auto',
      overflowWrap: 'anywhere',
      background: tokens.uiBase01,
    },
  }),
);

const useNarrowStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    hidden: {
      height: 0,
      position: 'absolute',
      top: '-200px',
      visibility: 'hidden',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
    description: {
      marginBottom: theme.spacing(4),
    },
    error: {
      maxHeight: '100px',
      maxWidth: '100%',
      fontSize: '12px',
      lineHeight: '1.4',
      overflow: 'hidden auto',
      overflowWrap: 'anywhere',
    },
  }),
);

export const CallLogErrorContinueInSalesforce: React.FC = () => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const error = useSelector(salesforceLastCallLogErrorSelector);
  const errorRef = useRef<HTMLTextAreaElement>(null);

  const normalClasses = useStyles();
  const narrowClasses = useNarrowStyles();
  const classes = isNarrowIntegration ? narrowClasses : normalClasses;

  if (!error) {
    logger.error('SF Call log error dialog showed when no error was recorded.');
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const errorMessage = error.toString();

  return (
    <div className={classes.container}>
      <textarea className={classes.hidden} ref={errorRef} value={error} readOnly />
      <Box className={classes.description}>
        <Typography variant="body-small">
          <FormattedMessage {...definedMessages.BODY} />
        </Typography>
      </Box>
      <Box>
        <Box mb={1}>
          <Typography>
            <b>
              <FormattedMessage {...definedMessages.SEND_TO_ADMIN} />
            </b>
          </Typography>
        </Box>
        <Box className={classes.error}>
          <Typography variant="caption-default-01">
            <code>{errorMessage}</code>
          </Typography>
        </Box>
      </Box>
      <Box marginTop={2}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          fullWidth
          onClick={() => {
            if (!errorRef.current) {
              return;
            }
            errorRef.current?.select();
            console.log('copy', document.execCommand('copy'));
          }}
        >
          <Box display="inline-flex" gridGap={4} alignItems="center">
            {isNarrowIntegration ? null : <CopyInactiveIcon size="14px" />}
            <FormattedMessage
              {...(isNarrowIntegration ? definedMessages.COPY_BUTTON_SHORT : definedMessages.COPY_BUTTON)}
            />
          </Box>
        </Button>
      </Box>
    </div>
  );
};
