import { MessagingAdapter, MessagingService } from '@ucc/messaging-service';
import configuration from '../config';
import { axiosConnectHttpClient } from './connect-http-client';

export const messagingService = new MessagingService(
  `${configuration.apiBaseUrl}/messaging/v1`,
  axiosConnectHttpClient,
);

export const createConversationsService = (ownerSmsPhoneNumbers: string[]) =>
  new MessagingAdapter(messagingService, ownerSmsPhoneNumbers);
