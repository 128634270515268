import { ContactCallHistoryComponent, ContactCallHistoryStateProps } from './ContactCallHistory.component';
import { AppState } from '../reducers/index';
import { connect } from 'react-redux';
import { CallContextPanelProps } from '../calls/CallContextPanel.models';
import { contactCallHistorySelector } from './contactCallHistory.selectors';
import { selectedLanguageSelector } from '../settings/settings.selector';

const mapStateToProps = (state: AppState, { contact }: CallContextPanelProps): ContactCallHistoryStateProps => ({
  callHistory: contactCallHistorySelector(contact.id)(state),
  language: selectedLanguageSelector(state),
});

export const ContactCallHistory = connect<ContactCallHistoryStateProps, {}, CallContextPanelProps, AppState>(
  mapStateToProps,
)(ContactCallHistoryComponent);
