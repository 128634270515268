import { GetCategoriesRequestState, RedtailCategory } from '../redtail.model';
import { RedtailCategoryActions, RedtailCategoryActionsType } from './redtail.category.actions';

export interface CategoriesState {
  requestState: GetCategoriesRequestState;
  categories: RedtailCategory[];
}
export const INITIAL_STATE: CategoriesState = {
  requestState: GetCategoriesRequestState.INITIAL,
  categories: [],
};

export const redtailCategoriesReducer = (state = INITIAL_STATE, action: RedtailCategoryActions): CategoriesState => {
  switch (action.type) {
    case RedtailCategoryActionsType.CATEGORIES_REQUEST:
      return {
        ...state,
        categories: [],
        requestState: GetCategoriesRequestState.LOADING,
      };
    case RedtailCategoryActionsType.CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        requestState: GetCategoriesRequestState.LOADED,
      };
    case RedtailCategoryActionsType.CATEGORIES_GET_ERROR:
      return {
        ...state,
        categories: [],
        requestState: GetCategoriesRequestState.FAILED,
      };
    default:
      return state;
  }
};
