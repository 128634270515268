import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { DealerTrackEntitiesSettings } from './DealertrackEntities.container';
import { Typography } from '../../theme/Typography.component';
import Box from '@material-ui/core/Box';

const definedMessages = defineMessages({
  ENTITIES_TITLE: {
    id: 'Cox.Settings.Entities.Title',
    defaultMessage: 'Show DealerTrack entities',
  },
  ENTITIES_MESSAGE: {
    id: 'Cox.Settings.Entities.Message',
    defaultMessage: 'Customize visibility of entities.',
  },
});

export const CoxSettings: React.FC = () => {
  return (
    <Box pl={1} pr={1}>
      <div>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.ENTITIES_TITLE} />
        </Typography>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.ENTITIES_MESSAGE} />
        </Typography>
      </div>

      <DealerTrackEntitiesSettings />
    </Box>
  );
};
