import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';

const definedMessages = defineMessages({
  OPEN_IN_CLIO: {
    id: 'ClioNotes.OpenNoteInClio.Button.Label',
    defaultMessage: 'Open in clio',
  },
});

const trackingEvents = defineTrackingEvents({
  OPEN_IN_CLIO_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Open in Clio | button',
  },
});

type ClioNoteAutosaveProps = {
  noteUrl: string;
};

export const OpenNoteInClio: React.FC<ClioNoteAutosaveProps> = ({ noteUrl }: ClioNoteAutosaveProps) => {
  const openInClio = () => {
    window.open(noteUrl, '_blank', 'openInDefaultBrowser');
  };

  return (
    <TrackedButton
      color="default"
      variant="neutral-inverse"
      size="small"
      trackingEvent={trackingEvents.OPEN_IN_CLIO_CLICKED}
      onClick={openInClio}
    >
      <FormattedMessage {...definedMessages.OPEN_IN_CLIO} />
    </TrackedButton>
  );
};

export const openNoteInClioFactory = (noteUrl: string) => <OpenNoteInClio noteUrl={noteUrl} />;
