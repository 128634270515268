import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { debouncedSearchEntity } from '../search/entities.action';
import { entitiesIsLoadingSelector, entitiesSelector } from '../search/entities.selector';
import { call, phoneClear, setPhoneMode, setPhoneSelectedNumber } from './phone.actions';
import PhoneComponent, { PhoneDispatchProps, PhoneStateProps } from './Phone.component';
import { phoneModeSelector, selectedEntitySelector, selectedPhoneNumberSelector } from './phone.selector';
import { currentIntegrationSelector, isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { isSoftphoneCapableAndEnabledSelector } from '../settings/settings.selector';

export default connect<PhoneStateProps, PhoneDispatchProps, any, AppState>(
  (state) => ({
    selectedEntity: selectedEntitySelector(state),
    loading: entitiesIsLoadingSelector(state),
    mode: phoneModeSelector(state),
    selectedPhoneNumber: selectedPhoneNumberSelector(state),
    entities: entitiesSelector(state),
    isNarrowIntegration: isNarrowIntegrationSelector(state),
    integration: currentIntegrationSelector(state),
    isSoftphoneCapableAndEnabled: isSoftphoneCapableAndEnabledSelector(state),
  }),
  (dispatch) => ({
    callContact: (payload) => dispatch(call(payload)),
    search: (payload) => debouncedSearchEntity(dispatch, payload),
    setMode: (mode) => dispatch(setPhoneMode(mode)),
    setSelectedPhoneNumber: (phoneNumber) => dispatch(setPhoneSelectedNumber(phoneNumber)),
    clear: () => dispatch(phoneClear()),
  }),
)(PhoneComponent);
