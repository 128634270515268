import React, { FC, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { GetCategoriesRequestState, RedtailCategory } from '../redtail.model';
import { TrackingEvent } from '../../analytics-new/analytics.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { RedtailActionFacade } from '../redtail.action.facade';
import { newTracker } from '../../analytics-new/tracker-new';
import { Autocomplete } from '@getgo/chameleon-material-ui';
import { definedMessages as commonDefinedMessages } from '../../inAppNotification/message.content';

export interface CategoryPickerProps {
  categoryId: number;
  onCategorySelected: (categoryId: number) => void;
  trackingEvent?: TrackingEvent;
}

export interface CategoryPickerStateProps {
  categories: RedtailCategory[];
  requestState: GetCategoriesRequestState;
}

const definedMessages = defineMessages({
  CATEGORY_PICKER_LOADING_MESSAGE: {
    id: 'Dropdown.Values.Loading.Message',
    defaultMessage: 'Loading...',
  },
  CATEGORY_PICKER_EMPTY_MESSAGE: {
    id: 'Redtail.CategoryPicker.Empty.Message',
    defaultMessage: 'No category found',
  },
  CATEGORY_PICKER_CATEGORY: {
    id: 'Redtail.CategoryPicker.Category',
    defaultMessage: 'Category',
  },
});

export const CategoryPickerComponent: FC<CategoryPickerProps & CategoryPickerStateProps> = ({
  categoryId,
  categories,
  requestState,
  onCategorySelected,
  trackingEvent,
}) => {
  const intl = useIntl();
  const [isTrackedAlready, setTrackedAlready] = useState(false);

  useEffect(() => {
    void getActionFacade<RedtailActionFacade>().getCategories();
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0 && categoryId === -1) {
      onCategorySelected(categories[0].id);
    }
  }, [categories, categoryId, onCategorySelected]);

  const isLoading = requestState === GetCategoriesRequestState.LOADING;

  return (
    <Autocomplete
      options={categories}
      openOnFocus
      disableClearable
      loading={isLoading}
      getOptionSelected={(category, selected) => category.id === selected.id}
      getOptionLabel={(c) => c.label}
      // @ts-ignore
      value={categories.length && categoryId > -1 ? categories.find((nt) => nt.id === categoryId) ?? null : null}
      onChange={(_, category) => {
        onCategorySelected(category?.id);
        if (!isTrackedAlready && trackingEvent) {
          setTrackedAlready(true);
          newTracker.trackAnalyticsEvent(trackingEvent);
        }
      }}
      loadingText={<FormattedMessage {...definedMessages.CATEGORY_PICKER_LOADING_MESSAGE} />}
      noOptionsText={<FormattedMessage {...definedMessages.CATEGORY_PICKER_EMPTY_MESSAGE} />}
      TextFieldProps={{
        noMargin: true,
        placeholder: intl.formatMessage(commonDefinedMessages.FILTER_AS_YOU_TYPE_SELECT_PLACEHOLDER),
        label: intl.formatMessage(definedMessages.CATEGORY_PICKER_CATEGORY),
      }}
    />
  );
};
