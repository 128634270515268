import { AvailableConfigs } from '@jive/rtc';
import { LogLevel } from './logging/model';
import { Integrations } from './models';

const LMI_LOGIN_ED = 'https://authenticationed1.lmiinc.test.expertcity.com';
const LMI_LOGIN_STAGE = 'https://authenticationstage.logmeininc.com';
const LMI_LOGIN_PROD = 'https://authentication.logmeininc.com';
const LMI_LOGIN_AUTHORIZE_PATH = '/oauth/authorize';

const JIVE_APPS_PROD = 'https://my.jive.com';
const JIVE_APPS_STAGING = 'https://my.staging.jive.com';
const API_PROD = 'https://api.jive.com';
const API_STAGING = 'https://api.staging.jive.com';
const API_DEVELOPMENT = 'https://api.dev.jive.com';

export enum Environment {
  STAGING = 'staging',
  PROD = 'prod',
  DEV = 'dev',
  LOCAL = 'local',
}

const RTC_CONFIG_BASIC = {
  name: 'JiveRTC',
  gatewayVersion: 'v3',
  VOICE_ACTIVITY_LEVELS: 10,
};

const RTC_CONFIG_DEV = {
  environment: 'dev',
  // logging: true,
  socketUrl: 'dev',
  notificationUrl: 'dev',
  ...RTC_CONFIG_BASIC,
} as Partial<AvailableConfigs>;

const RTC_CONFIG_STAGING = {
  environment: 'staging',
  // logging: true,
  socketUrl: 'staging',
  notificationUrl: 'staging',
  ...RTC_CONFIG_BASIC,
} as Partial<AvailableConfigs>;

const RTC_CONFIG_PROD = {
  environment: 'default',
  logging: false,
  socketUrl: 'default',
  notificationUrl: 'default',
  ...RTC_CONFIG_BASIC,
} as Partial<AvailableConfigs>;

const baseConfig = {
  [Integrations.Clio]: {
    userReportId: '830302b6-6da8-416e-b42f-5fa83c06b297',
  },
  [Integrations.Redtail]: {
    userReportId: '7d5fc5bd-9fdd-494d-8b38-96fa9bb5f827',
  },
  [Integrations.ConnectWise]: {
    userReportId: '421baa60-78aa-4cc6-b6a1-9aac249a8d8f',
  },
  [Integrations.Zendesk]: {
    userReportId: '5c35ee6b-cdfa-4b74-a677-5bcd9efc4051',
  },
  [Integrations.SalesforceLightning]: {
    userReportId: '761dfd4c-a7ed-43fe-912d-792cab6b1130',
  },
  [Integrations.SalesforceClassic]: {
    userReportId: '34f36667-1dc5-42e6-98ea-119c507b3860',
  },
  [Integrations.MsTeams]: {
    userReportId: '0d140f4b-4499-4eac-bfa5-14fcab8c9e4f',
    integrationPageUri: '/integrations/_/msteams',
    logoutPath: '/oauth/logout',
    featureFlag: 'jci.ambassador.v1.provider.msteams',
    canOpenContact: false,
    storeAppName: 'Jive',
    apiPathUri: '/msteams/v1',
  },
  [Integrations.Cox]: {
    userReportId: '86402811-0107-4e9c-8eb6-fa48ad38b74c',
    canOpenContact: false,
  },
  [Integrations.Dynamics]: {
    userReportId: '1d7d0e6e-3f9f-4428-9042-519cb0e00453',
    canOpenContact: false,
  },
  routeChangeLogging: false,
  integrationClientId: 'QHp4xE9qt8rzdMkK72bOkRW4xwBN6PH9NFGhdfgFDGH',
  ambassador: {
    url: 'https://api.dev.jive.com/ambassador/v1',
    clientAppId: 'gGAFB9bWaXUAkbOznkW1riN_aCuUFRrAoTsitzKhqFA',
  },
  sentry: {
    dsn: 'https://b9d44789ef5c4776bea3b719fa0ede7d@o173976.ingest.sentry.io/5678169',
    environment: 'development',
    logLevel: LogLevel.Debug,
    release: process.env.REACT_APP_JIF_APP_VERSION || 'development',
  },
  analytics: {
    appVersion: process.env.REACT_APP_JIF_APP_VERSION || 'development',
    trackingId: '',
  },
  goToAppUrl: 'https://app.dev.gtc.goto.com',
  identityService: {},
  rtcConfig: RTC_CONFIG_PROD,
  version: process.env.REACT_APP_JIF_APP_VERSION || '0.0.0-development-SNAPSHOT',
};

const baseAuthConfig = {
  clientId: 'a7f6d3fa-f956-4f12-807c-028c22c672b1',
};

// No need to list scopes here any more, here are the scopes that are auto approved:
// 'contacts.v4.read',
// 'pbx.v1.profile',
// 'pbx.v1.admin',
// 'features.v1.read',
// 'calls.v1.originate',
// 'pbx.v1.user',
// 'ambassador.v1.read',
// 'ambassador.v1.write',
// 'users.v1.lines.read',
// 'calls.v2.initiate',

// LMI scopes: read, trust, write

const devConfig = {
  ...baseConfig,
  production: false,
  auth: {
    ...baseAuthConfig,
    loginUrl: `${LMI_LOGIN_ED}${LMI_LOGIN_AUTHORIZE_PATH}`,
    logoutUrl: `${LMI_LOGIN_ED}/oauth/logout`,
  },
  jiveUrl: 'https://my.dev.jive.com',
  apiBaseUrl: API_DEVELOPMENT,
  goToAppUrl: 'https://app.dev.gtc.goto.com',
  notificationChannelUrl: `${API_DEVELOPMENT}/notification-channel/v1`,
  realtimeApiUrl: 'https://realtime.dev.jive.com',
  loggingServiceUrl: 'https://loggingrc1.getgo.test.expertcity.com',
  sentry: {
    ...baseConfig.sentry,
    environment: 'development',
    logLevel: LogLevel.Debug,
  },
  [Integrations.MsTeams]: {
    ...baseConfig[Integrations.MsTeams],
    // The App Id of the Jive MS Teams PVU app in the JIVE INTEGRATIONS tenant. See the com.jive.jip.msteams readme for details.
    adminConsentClientId: '0efb99a5-2995-429c-b666-5b8e9eb308bd',
    apiBaseUrl: `${API_DEVELOPMENT}${baseConfig[Integrations.MsTeams].apiPathUri}`,
    enablementClientId: 'f1a53094-d57d-4f27-9e47-d40448d87298',
    lmiOAuthBase: LMI_LOGIN_ED,
  },
  analytics: {
    ...baseConfig.analytics,
    trackingId: 'UA-126153964-8',
    newTrackingId: 'UA-126153964-24',
  },
  identityService: {
    baseUrl: 'https://iamed1.serversdev.getgo.com',
  },
  rtcConfig: RTC_CONFIG_DEV,
};

const stagingConfig = {
  ...baseConfig,
  production: false,
  auth: {
    ...baseAuthConfig,
    clientId: '7d6c45af-4560-4c2a-bd3f-788eaf8744f6',
    loginUrl: `${LMI_LOGIN_STAGE}${LMI_LOGIN_AUTHORIZE_PATH}`,
    logoutUrl: `${LMI_LOGIN_STAGE}/oauth/logout`,
  },
  jiveUrl: JIVE_APPS_STAGING,
  apiBaseUrl: API_STAGING,
  goToAppUrl: 'https://app.staging.gtc.goto.com',
  notificationChannelUrl: `${API_STAGING}/notification-channel/v1`,
  realtimeApiUrl: 'https://realtime.staging.jive.com',
  loggingServiceUrl: 'https://loggingstage.getgo.com',
  sentry: {
    ...baseConfig.sentry,
    environment: 'staging',
    logLevel: LogLevel.Debug,
  },
  [Integrations.MsTeams]: {
    ...baseConfig[Integrations.MsTeams],
    // The App Id of the Jive MS Teams GEG app in the JIVE INTEGRATIONS tenant. See the com.jive.jip.msteams readme for details.
    adminConsentClientId: 'd14019ea-0379-4144-ba07-8d1d058c0624',
    apiBaseUrl: `${API_STAGING}${baseConfig[Integrations.MsTeams].apiPathUri}`,
    enablementClientId: 'f83f8ea3-3e8f-4b9a-8b4d-f58d90b095e4',
    lmiOAuthBase: LMI_LOGIN_STAGE,
  },
  ambassador: {
    ...baseConfig.ambassador,
    url: 'https://api.staging.jive.com/ambassador/v1',
  },
  analytics: {
    ...baseConfig.analytics,
    trackingId: 'UA-126153964-7',
    newTrackingId: 'UA-126153964-23',
  },
  identityService: {
    baseUrl: 'https://iamstage.servers.getgo.com',
  },
  rtcConfig: RTC_CONFIG_STAGING,
};

const productionConfig = {
  ...baseConfig,
  production: true,
  auth: {
    ...baseAuthConfig,
    clientId: 'b30b2da1-c69e-499a-ab4d-312a6e6eda29',
    loginUrl: `${LMI_LOGIN_PROD}${LMI_LOGIN_AUTHORIZE_PATH}`,
    logoutUrl: `${LMI_LOGIN_PROD}/oauth/logout`,
  },
  jiveUrl: JIVE_APPS_PROD,
  apiBaseUrl: API_PROD,
  goToAppUrl: 'https://app.goto.com',
  notificationChannelUrl: `${API_PROD}/notification-channel/v1`,
  realtimeApiUrl: 'https://realtime.jive.com',
  loggingServiceUrl: 'https://logging.getgo.com',
  sentry: {
    ...baseConfig.sentry,
    environment: 'production',
    logLevel: LogLevel.Warn,
  },
  [Integrations.MsTeams]: {
    ...baseConfig[Integrations.MsTeams],
    // The App Id of the Jive MS Teams app in the JIVE INTEGRATIONS tenant. See the com.jive.jip.msteams readme for details.
    adminConsentClientId: '2807ec31-009d-4cb1-8a17-2e65a4a5af7d',
    apiBaseUrl: `${API_PROD}${baseConfig[Integrations.MsTeams].apiPathUri}`,
    enablementClientId: '004e9e1b-fb39-4d04-b141-ce25cde481de',
    lmiOAuthBase: LMI_LOGIN_PROD,
  },
  ambassador: {
    ...baseConfig.ambassador,
    url: 'https://api.jive.com/ambassador/v1',
  },
  analytics: {
    ...baseConfig.analytics,
    trackingId: 'UA-143909-45',
    newTrackingId: 'UA-126153964-22',
  },
  identityService: {
    baseUrl: 'https://iam.servers.getgo.com',
  },
  rtcConfig: RTC_CONFIG_PROD,
};

const localConfig = {
  ...devConfig,
  jiveUrl: 'http://localhost:4200',
};

if (process.env.REACT_APP_ENVIRONMENT) {
  window.environment = process.env.REACT_APP_ENVIRONMENT as typeof window.environment;
}

if (!window.environment && window.location.host.includes('goto.com')) {
  const getGotoEnvironment = (host: string): Environment => {
    const environmentMap = {
      'embedded-integrations.dev.gtc.goto.com': Environment.DEV,
      'embedded-integrations.ed.gtc.goto.com': Environment.DEV,
      'embedded-integrations.staging.gtc.goto.com': Environment.STAGING,
      'embedded-integrations.stage.gtc.goto.com': Environment.STAGING,
      'embedded-integrations.goto.com': Environment.PROD,
    };
    return environmentMap[Object.keys(environmentMap).find((key) => host.includes(key)) ?? ''] as Environment;
  };
  window.environment = getGotoEnvironment(window.location.host);
}

if (!window.environment && window.location.host.includes('jive.com')) {
  const environmentMap = {
    'my.dev.jive.com': Environment.DEV,
    'my.staging.jive.com': Environment.STAGING,
    'my.jive.com': Environment.PROD,
  };
  window.environment = environmentMap[window.location.host];
}

if (!window.environment && window.location.hostname === 'localhost') {
  window.environment = Environment.STAGING;
}

const getConfiguration = () => {
  switch (window.environment) {
    case Environment.LOCAL:
      return {
        ...localConfig,
      };
    case Environment.DEV:
      return {
        ...devConfig,
      };
    case Environment.STAGING:
      return {
        ...stagingConfig,
      };
    case Environment.PROD:
      return {
        ...productionConfig,
      };
    default:
      throw new Error(`Unexpected environment: ${window.environment}`);
  }
};

const configuration = getConfiguration();

export const baseAppURI = new URL(document.baseURI).pathname.replace(/\/?$/, '/');

export default configuration;
