import { ActionFacade } from '../actionFacade/action.facade';
import DynamicsActionCreatorFactory from './dynamics.actionCreator.factory';
import { CAPABILITY_OPEN_FORM_IN_CRM, OpenFormInCrmCapableFacade } from '../actionFacade/action.facade.capabilities';
import { OpenCrmFormModel } from '../actionFacade/actionCreators/crmForm/crmForm.model';
import { CrmFormActionCreator } from '../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { DynamicsCaseActionCreator } from './case/dynamics.case.actioncreator';
import { CreateNewCaseModel, CreateNewLeadModel, CreateNewOpportunityModel } from './dynamics.model';
import { DynamicsLeadActionCreator } from './lead/dynamics.lead.actioncreator';
import { DynamicsOpportunityActionCreator } from './opportunity/dynamics.opportunity.actioncreator';

export class DynamicsActionFacade extends ActionFacade implements OpenFormInCrmCapableFacade {
  [CAPABILITY_OPEN_FORM_IN_CRM]: true = true;

  private crmFormActionCreator: CrmFormActionCreator;
  private caseActionCreator: DynamicsCaseActionCreator;
  private leadActionCreator: DynamicsLeadActionCreator;
  private opportunityActionCreator: DynamicsOpportunityActionCreator;

  constructor(actionCreatorFactory: DynamicsActionCreatorFactory) {
    super(actionCreatorFactory);

    this.crmFormActionCreator = actionCreatorFactory.createCrmFormActionCreator();
    this.caseActionCreator = actionCreatorFactory.createCaseActionCreator();
    this.leadActionCreator = actionCreatorFactory.createLeadActionCreator();
    this.opportunityActionCreator = actionCreatorFactory.createOpportunityActionCreator();
  }

  openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    return this.crmFormActionCreator.openFormInCrm(model);
  }

  createCaseForContact(model: CreateNewCaseModel): Promise<void> {
    return this.caseActionCreator.createCaseForContact(model);
  }

  createLead(model: CreateNewLeadModel): Promise<void> {
    return this.leadActionCreator.createLead(model);
  }

  createOpportunity(model: CreateNewOpportunityModel): Promise<void> {
    return this.opportunityActionCreator.createOpportunity(model);
  }
}
