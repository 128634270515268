import React from 'react';
import { Call } from '@jive/realtime-events';
import { LinearProgress, Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AppAction } from '../actions/actionCreators';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import { CallWithContact } from '../calls/calls.reducer';
import { CallTypeIcon } from '../components/CallTypeIcon';
import { formatPhoneNumber } from '../phone/phone-utils';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsCategory, defineTrackingEvents, AnalyticsAction } from '../analytics-new/analytics.models';
import { Typography } from '../theme/Typography.component';
import makeStyles from '@material-ui/styles/makeStyles';
import { IconButton } from '@getgo/chameleon-material-ui';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { DeprecatedCallerIdComponent } from '../calls/DeprecatedCallerId.component';
import { Link } from '@getgo/chameleon-web/react';

export const definedMessages = defineMessages({
  CALL_SNACKBAR_SHOW_MORE: {
    id: 'Call.Snackbar.ActiveCall.ShowMore',
    defaultMessage: 'Show more',
  },
  CHECKING_MATCHING_CONTACTS: {
    id: 'Call.Snackbar.ActiveCall.ContactMatchingInProgress',
    defaultMessage: 'Searching for {phoneNumber}',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenPop: {
      backgroundColor: tokens.uiBackground,
      margin: theme.spacing(4),
      padding: theme.spacing(4),
      maxWidth: '95%',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      flex: 1,
      position: 'relative',
    },
    icon: {
      borderRadius: 8,
      fontSize: 24,
      padding: 4,
      color: 'white',
      marginTop: 3,
      marginRight: theme.spacing(3),
    },
    iconWrapper: {
      fontSize: '28px',
      paddingTop: '13px',
      paddingRight: '10px',
    },
    closeButton: {
      position: 'absolute',
      top: 6,
      right: 6,
    },
  }),
);

const trackingEvents = defineTrackingEvents({
  INCOMING_CALL_SHOW_MORE_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Incoming call show more | button',
  },
  INCOMING_CALL_CLOSE_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Incoming call close | button',
  },
});

export interface CallInformationSnackBarMessageOwnProps {
  callId: string;
  onClose: (...args: any[]) => any;
}

export interface CallInformationSnackBarMessageStateProps {
  call: CallWithContact;
  contactDisplayFromCall: string;
  isContactMatchingInProgress: boolean;
  integrationName: string;
}

export interface CallInformationSnackBarMessageDispatchProps {
  updateActiveCall: (call: Call) => AppAction;
  onClose: (...args: any[]) => any;
}

export const DepracatedScreenPopComponent: React.FC<CallInformationSnackBarMessageOwnProps &
  CallInformationSnackBarMessageStateProps &
  CallInformationSnackBarMessageDispatchProps> = ({
  onClose,
  call,
  contactDisplayFromCall,
  isContactMatchingInProgress,
  integrationName,
  updateActiveCall,
}) => {
  const classes = useStyles();

  function switchActiveCall() {
    newTracker.trackAnalyticsEvent(trackingEvents.INCOMING_CALL_SHOW_MORE_CLICKED);
    updateActiveCall(call);
    onClose();
  }

  const contactInfo = (
    <>
      <Typography variant="body-medium" noWrap style={{ fontWeight: 'bold' }}>
        {contactDisplayFromCall}
      </Typography>
      <DeprecatedCallerIdComponent call={call} typographyProps={{ color: 'text-02' }} />
    </>
  );

  function getContent() {
    if (isContactMatchingInProgress) {
      const messageValues = {
        integrationName,
        phoneNumber: (
          <Typography variant="caption-default-02" tag="span">
            {formatPhoneNumber(getNumberOfTheOtherParty(call))}
          </Typography>
        ),
      };

      return (
        <>
          <Box mb={2}>
            <LinearProgress />
          </Box>
          <Typography variant="caption-default-01">
            <FormattedMessage values={messageValues} {...definedMessages.CHECKING_MATCHING_CONTACTS} />
          </Typography>
        </>
      );
    } else {
      return contactInfo;
    }
  }

  return (
    <Box data-cy="screenpop" className={classes.screenPop}>
      <Box display="flex">
        <CallTypeIcon className={classes.icon} type={call.type} size="24px" />
        <Box flexGrow={1} mr={4} maxWidth="75%">
          {getContent()}
          <Box mt={2}>
            <Link data-cy="show-more" onClick={() => switchActiveCall()} style={{ font: tokens.buttonTextMedium }}>
              <FormattedMessage {...definedMessages.CALL_SNACKBAR_SHOW_MORE} />
            </Link>
          </Box>
        </Box>
      </Box>
      <IconButton
        size="xsmall"
        variant="secondary"
        data-cy="close-button"
        onClick={() => {
          newTracker.trackAnalyticsEvent(trackingEvents.INCOMING_CALL_CLOSE_CLICKED);
          onClose();
        }}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
