import { AxiosResponse } from 'axios';
import { defineMessages } from 'react-intl';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ContactActionsType } from '../actions/contacts';
import { ProxyActionsTypes } from '../ambassador/proxy/proxy.action';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { isIntegrationLinkedIfNecessarySelector } from '../integrations/integrations.selector';
import { MenuActionTypes } from '../menu/menu.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { NoteActionsType } from '../notes/notes.actions';
import { CreateEntityActionPayload, EntitiesActionsType } from '../search/entities.action';
import { showErrorMessage } from '../inAppNotification/message.action';
import { ClioActionsTypes, clioWhoAmI, clioWhoAmIError, clioWhoAmISuccess } from './clio.action';
import { ClioActionFacade } from './clio.action.facade';
import { User } from './clio.models';
import { ClioService } from './clio.service';
import { ClioUser } from './clio.service.models';
import { ClioAxiosInterceptor } from './clioAxios.interceptor';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';

const definedClioMessages = defineMessages({
  MENU_DISABLED_LINK_ERROR: {
    id: 'Menu.Disabled.Link.Error',
    defaultMessage: 'Please link your account first!',
  },
});

// initialize all Clio listeners and interceptor
addSmartListener(IntegrationsActionsType.SET_CURRENT)(async (store, action) => {
  if (action.payload === Integrations.Clio) {
    registerClio(store);
  }
});

let registered = false;

function registerClio(store: Store<AppState>) {
  if (registered) {
    return;
  }

  registered = true;

  new ClioAxiosInterceptor(store).register();

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, action) => {
    const { contactId, phoneNumber } = action.payload;

    await getActionFacade<ClioActionFacade>().updateContactPhoneNumber(contactId, phoneNumber);
  });

  addListener(ProxyActionsTypes.PROXY_CHECK_LINK_SUCCESS)((state) => {
    const isClioLinked = isIntegrationLinkedIfNecessarySelector(state);
    if (isClioLinked) {
      return clioWhoAmI();
    }
  });

  addListener(ClioActionsTypes.CLIO_WHO_AM_I)(async (_, __) => {
    try {
      const response: AxiosResponse<{ data: ClioUser }> = await ClioService.whoAmI();
      const clioUser: ClioUser = response.data.data;

      const self: User = {
        id: clioUser.id,
        name: clioUser.name,
        rate: clioUser.rate,
      };

      return clioWhoAmISuccess(self);
    } catch (e) {
      return clioWhoAmIError();
    }
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    const query = action.payload.query;
    await getActionFacade().searchContact(query);
  });

  addListener(EntitiesActionsType.CREATE_ENTITY)(async (_, action) => {
    const createContact: CreateEntityActionPayload = action.payload;
    const { firstName, lastName, phoneNumber } = createContact.defaultFieldValues;

    await getActionFacade().createContact({
      isActiveCall: action.payload.isActiveCall,
      callId: action.payload.callId,
      firstName,
      lastName,
      phoneNumber,
    });
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, action) => {
    const contact = action.payload.entity;

    await getActionFacade().openContactInCrm(contact.id);
  });

  addListener(NoteActionsType.ADD_NOTE)(async (_, { payload }) => {
    await getActionFacade<ClioActionFacade>().addNote(payload);
  });

  addCallStartListener(async (_, action) => {
    await getActionFacade().createScreenpop(action.payload.call);
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(call);
  });

  addListener(NoteActionsType.GET_NOTES)(async (_, { payload: { contactId } }) => {
    await getActionFacade<ClioActionFacade>().loadMostRecentNoteOfContact(contactId);
  });

  addListener(NoteActionsType.GET_MORE_NOTES)(async () => {
    await getActionFacade<ClioActionFacade>().loadMoreNotesOfContact();
  });

  addListener(MenuActionTypes.MENU_DISABLED_CLICK)(() => {
    return showErrorMessage(definedClioMessages.MENU_DISABLED_LINK_ERROR);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });
}
