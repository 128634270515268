import { makeActionCreator } from '../../actions/actionCreators';
import { Company } from '../connectwise.model';
import { WithMessage } from '../../models';

export enum CompanyPickerActionsType {
  GET_COMPANIES = 'connectwise/companies/get',
  GET_COMPANIES_SUCCESS = 'connectwise/companies/get/success',
  GET_COMPANIES_ERROR = 'connectwise/companies/get/error',
  CLEAR_COMPANIES = 'connectwise/companies/clear',
}

export const getCompanies = makeActionCreator(CompanyPickerActionsType.GET_COMPANIES)();
export const clearCompanies = makeActionCreator(CompanyPickerActionsType.CLEAR_COMPANIES)();

export interface GetCompaniesSuccessPayload {
  companies: Company[];
}

export const getCompaniesSuccess = makeActionCreator(CompanyPickerActionsType.GET_COMPANIES_SUCCESS)<
  GetCompaniesSuccessPayload
>();

export const getCompaniesError = makeActionCreator(CompanyPickerActionsType.GET_COMPANIES_ERROR)<WithMessage>();

export type CompanyPickerActions =
  | ReturnType<typeof getCompanies>
  | ReturnType<typeof getCompaniesSuccess>
  | ReturnType<typeof getCompaniesError>
  | ReturnType<typeof clearCompanies>;
