import { IndexedById } from '../models/index';
import { createSelector } from 'reselect';
import { devicesArraySelector } from '../jiveEntities/devicesEntity.selector';
import { AppState } from '../reducers/index';
import { Device, NormalizedDevice } from '../settings/devices.model';
import { Line, NormalizedLine } from './lines.model';
import { LinesByOrganization } from '../settings/organizations.model';

const linesStateSelector = (state: AppState) => state.lines;

export const linesSelector = createSelector(linesStateSelector, (lines) => lines.lines);

export const organizationsSelector = createSelector(linesStateSelector, (lines) => lines.organizations);

export const areLinesLoadingSelector = createSelector(linesStateSelector, (lines) => lines.isLoading);

export const hasLinesLoadingErrorSelector = createSelector(linesStateSelector, (lines) => lines.hasError);

export const hasLinesLoadedSuccessfulSelector = createSelector(
  areLinesLoadingSelector,
  hasLinesLoadingErrorSelector,
  (areLinesLoading, hasLinesLoadingError) => !areLinesLoading && !hasLinesLoadingError,
);

export const lineSelector = (lineId: string) => createSelector(linesSelector, (lines) => lines[lineId]);

export const lineOrganizationIdSelector = (lineId: string) =>
  createSelector(linesSelector, (lines) => lines[lineId]?.organization);

function getOrganizationLines(lines: IndexedById<NormalizedLine>, organizationId: string): Line[] {
  return Object.values(lines).filter((line) => line.organization === organizationId);
}

function getLineDevices(devices: NormalizedDevice[], lineId: string): Device[] {
  return devices.filter((device) => device.lineIds && device.lineIds.some((id) => id === lineId));
}

export const linesByOrganizationsSelector: (state: AppState) => LinesByOrganization[] = createSelector(
  organizationsSelector,
  linesSelector,
  devicesArraySelector,
  (organizations, lines, devices) =>
    Object.keys(organizations).map(
      (orgId) =>
        ({
          ...organizations[orgId],
          lines: getOrganizationLines(lines, orgId).map((line) => ({
            ...line,
            organization: organizations[orgId],
            devices: getLineDevices(devices, line.id),
          })),
        } as LinesByOrganization),
    ),
);
