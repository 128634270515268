import {
  ChatActiveIcon,
  ChatInactiveIcon,
  HistoryIcon,
  PhoneUpActiveIcon,
  PhoneUpInactiveIcon,
  HelpCircleInactiveIcon,
} from '@getgo/chameleon-icons/react';
import React, { Suspense, useEffect, useMemo } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import ActiveCallsContainer from './activeCalls/ActiveCalls.container';
import { ActiveCallsMenuItem } from './activeCalls/ActiveCallsMenuItem.component';
import CallContainer from './calls/Call.container';
import { AppLayout } from './components/AppLayout';
import { NarrowAppLayout } from './components/AppLayout.narrow';
import { FullPageAppLoading } from './components/FullPageAppLoading';
import { RedirectKeepSearch } from './components/RedirectKeepSearch.component';
import { ApplicationRoute } from './constants';
import './index.css';
import { Integrations } from './models';
import PhoneContainer from './phone/Phone.container';
import { SettingsComponent } from './settings/settings.component';
import {
  AnalyticsAction,
  AnalyticsCategory,
  defineTrackingEvents,
  TrackingEvent,
} from './analytics-new/analytics.models';
import { Location } from 'history';
import { getActionFacade } from './actionFacade/action.facade.store';
import { SFInitializationError } from './dialog/bodies/SFInitializationError.component';
import { CallHistory } from './calls/CallHistory.component';
import { ChangelogIcon } from './changelog/ChangelogIcon.component';
import { CookiesUnavailablePage } from './error/CookiesUnavailablePage.component';
import { isLocalStorageAvailable } from './utils';
import { CenteredLoadingSpinner } from './components/CenteredLoadingSpinner.component';
import { CAPABILITY_CONVERSATIONS } from './actionFacade/action.facade.capabilities';
import { StartupState } from './integrations/integrations.actions';
import { LocalStorageUnavailablePage } from './error/LocalStorageUnavailablePage.component';
import { LinesLoadingErrorPage } from './error/LinesLoadingErrorPage.component';
import { SvgIcon } from '@getgo/chameleon-web/react';
import { SettingsIcon } from './settings/SettingsIcon.component';

const ChangelogComponent = React.lazy(() => import('./changelog/Changelog.component'));
const ConversationsComponent = React.lazy(() => import('./conversations/Conversations.component'));
const Announcement = React.lazy(() => import('./changelog/Announcement.component'));

export interface ApplicationRouteType {
  name: string;
  path: string;
  icon: () => React.ReactElement;
  activeIcon: () => React.ReactElement;
  description: MessageDescriptor;
  isExternal: boolean;
  trackingEvent?: TrackingEvent;
  isVisible: boolean;
}

const trackingEvents = defineTrackingEvents({
  MENUITEM_PHONE_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Phone | menuitem',
  },
  MENUITEM_CALLHISTORY_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'History | menuitem',
  },
  MENUITEM_CONVERSATIONS_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Text messages | menuitem',
  },
  MENUITEM_ACTIVECALLS_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Active calls | menuitem',
  },
  MENUITEM_SETTINGS_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Settings | menuitem',
  },
  MENUITEM_CHANGELOG_CLICKED: {
    category: AnalyticsCategory.Navigation,
    action: AnalyticsAction.ItemClicked,
    label: 'Changelog | menuitem',
  },
});

const definedMessages = defineMessages({
  PHONE_TITLE: { id: 'Phone.Title', defaultMessage: 'Phone', description: 'header section' },
  ACTIVECALLS_TITLE: { id: 'ActiveCalls.Title', defaultMessage: 'Active Calls', description: 'header section' },
  HISTORY_TITLE: { id: 'History.Title', defaultMessage: 'History', description: 'header section' },
  CONVERSATIONS_TITLE: { id: 'Conversations.Title', defaultMessage: 'Text messages', description: 'header section' },
  SETTINGS_TITLE: { id: 'Settings.Title', defaultMessage: 'Settings', description: 'header section' },
  WHATSNEW_TITLE: { id: 'WhatsNew.Title', defaultMessage: 'Changelog', description: 'header section' },
  CONTACT_TITLE: { id: 'Contact.Title', defaultMessage: 'Contact', description: 'header section' },
  SUPPORT: { id: 'Support.Tooltip', defaultMessage: 'Support', description: 'Tooltip' },
  INTEGRATION_PAGE_LOADING: {
    id: 'Integration.Linked.Loading',
    defaultMessage: '{integrationName} linking is loading',
  },
  LINE_LOADING: { id: 'Lines.Loading.Message', defaultMessage: 'Lines are loading' },
});

const PAGE_TITLES = {
  [ApplicationRoute.PHONE_ROUTE]: definedMessages.PHONE_TITLE,
  [ApplicationRoute.SETTINGS_ROUTE]: definedMessages.SETTINGS_TITLE,
  [ApplicationRoute.CALL_HISTORY_ROUTE]: definedMessages.HISTORY_TITLE,
  [ApplicationRoute.ACTIVE_CALLS_ROUTE]: definedMessages.ACTIVECALLS_TITLE,
  [ApplicationRoute.CHANGELOG]: definedMessages.WHATSNEW_TITLE,
};

const supportRoute: ApplicationRouteType = {
  name: 'support',
  path: 'https://support.goto.com/connect/help/what-integrations-are-supported',
  icon: () => <HelpCircleInactiveIcon aria-hidden />,
  activeIcon: () => <HelpCircleInactiveIcon aria-hidden />,
  isExternal: true,
  description: definedMessages.SUPPORT,
  isVisible: true,
};

const whatsNewRoute: ApplicationRouteType = {
  path: ApplicationRoute.CHANGELOG,
  icon: () => (
    <SvgIcon aria-hidden>
      <ChangelogIcon />
    </SvgIcon>
  ),
  activeIcon: () => (
    <SvgIcon aria-hidden>
      <ChangelogIcon />
    </SvgIcon>
  ),
  description: definedMessages.WHATSNEW_TITLE,
  isExternal: false,
  trackingEvent: trackingEvents.MENUITEM_CHANGELOG_CLICKED,
  name: 'changelog',
  isVisible: true,
};

const settingsRouteType: ApplicationRouteType = {
  path: ApplicationRoute.SETTINGS_ROUTE,
  icon: () => <SettingsIcon aria-hidden />,
  activeIcon: () => <SettingsIcon active aria-hidden />,
  description: definedMessages.SETTINGS_TITLE,
  isExternal: false,
  trackingEvent: trackingEvents.MENUITEM_SETTINGS_CLICKED,
  name: 'settings',
  isVisible: true,
};

const getRoutes = (_: Integrations): ApplicationRouteType[] => {
  return [
    {
      path: ApplicationRoute.PHONE_ROUTE,
      icon: () => <PhoneUpInactiveIcon aria-hidden />,
      activeIcon: () => <PhoneUpActiveIcon aria-hidden />,
      description: definedMessages.PHONE_TITLE,
      isExternal: false,
      trackingEvent: trackingEvents.MENUITEM_PHONE_CLICKED,
      name: 'phone',
      isVisible: true,
    },
    {
      path: ApplicationRoute.CONVERSATIONS_ROUTE,
      icon: () => <ChatInactiveIcon data-cy="conversations" aria-hidden />,
      activeIcon: () => <ChatActiveIcon data-cy="conversations" aria-hidden />,
      description: definedMessages.CONVERSATIONS_TITLE,
      isExternal: false,
      trackingEvent: trackingEvents.MENUITEM_CONVERSATIONS_CLICKED,
      name: 'conversations',
      isVisible: getActionFacade().isCapable(CAPABILITY_CONVERSATIONS),
    },
    {
      path: ApplicationRoute.CALL_HISTORY_ROUTE,
      icon: () => <HistoryIcon data-cy="callHistory" aria-hidden />,
      activeIcon: () => <HistoryIcon data-cy="callHistory" aria-hidden />,
      description: definedMessages.HISTORY_TITLE,
      isExternal: false,
      trackingEvent: trackingEvents.MENUITEM_CALLHISTORY_CLICKED,
      name: 'callhistory',
      isVisible: true,
    },
    {
      path: ApplicationRoute.ACTIVE_CALLS_ROUTE,
      icon: () => <ActiveCallsMenuItem isActive={false} aria-hidden />,
      activeIcon: () => <ActiveCallsMenuItem isActive={true} aria-hidden />,
      description: definedMessages.ACTIVECALLS_TITLE,
      isExternal: false,
      trackingEvent: trackingEvents.MENUITEM_ACTIVECALLS_CLICKED,
      name: 'activecalls',
      isVisible: true,
    },
  ];
};

const getBottomRoutes = (): ApplicationRouteType[] => [whatsNewRoute, supportRoute, settingsRouteType];

const getPrimaryRoutes = (integration: Integrations): ApplicationRouteType[] =>
  getRoutes(integration).concat(settingsRouteType);

const getSecondaryRoutes = (): ApplicationRouteType[] => [whatsNewRoute, supportRoute];

const settingsRoute: AppRoute = {
  title: definedMessages.SETTINGS_TITLE,
  path: ApplicationRoute.SETTINGS_ROUTE,
  renderComponent: () => <SettingsComponent aria-hidden />,
  exact: true,
};

interface AppRoute {
  title?: MessageDescriptor;
  path: ApplicationRoute;
  renderComponent: (props: RouteComponentProps<any>) => React.ReactNode;
  exact: boolean;
}

const routes: AppRoute[] = [
  {
    title: definedMessages.PHONE_TITLE,
    path: ApplicationRoute.PHONE_ROUTE,
    renderComponent: (routeParams) => <PhoneContainer {...routeParams} />,
    exact: true,
  },
  {
    path: ApplicationRoute.CALL_ROUTE,
    renderComponent: (routeParams) => <CallContainer {...routeParams} />,
    exact: false,
  },
  {
    title: definedMessages.ACTIVECALLS_TITLE,
    path: ApplicationRoute.ACTIVE_CALLS_ROUTE,
    renderComponent: () => <ActiveCallsContainer />,
    exact: true,
  },
  {
    title: definedMessages.HISTORY_TITLE,
    path: ApplicationRoute.CALL_HISTORY_ROUTE,
    renderComponent: () => <CallHistory />,
    exact: true,
  },
  {
    title: definedMessages.CONVERSATIONS_TITLE,
    path: ApplicationRoute.CONVERSATIONS_ROUTE,
    renderComponent: (routeParams) => <ConversationsComponent {...routeParams} />,
    exact: false,
  },
  {
    title: definedMessages.WHATSNEW_TITLE,
    path: ApplicationRoute.CHANGELOG,
    renderComponent: () => <ChangelogComponent />,
    exact: true,
  },
  settingsRoute,
];

export interface MainOwnProps {
  location: Location;
}

export interface MainStateProps {
  currentIntegration: Integrations;
  areLinesLoading: boolean;
  hasLinesLoadingError: boolean;
  isIntegrationLinkedIfNecessary: Optional<boolean>;
  startupState: StartupState;
  isTermsOfServiceAccepted: boolean;
  isPersonalLinkingNeededForCurrentIntegration: boolean;
  isEnterpriseLinkingNeededForCurrentIntegration: boolean;
  integrationName: string;
  isNarrowIntegration: boolean;
  renderedPath: string;
  salesforcefInitializationError?: string;
  isAuthenticated: boolean;
  showTitle: boolean;
  shouldShowAnnouncement: boolean;
}

export const MainComponent: React.FC<MainOwnProps & MainStateProps> = (props) => {
  const intl = useIntl();
  const {
    startupState,
    isIntegrationLinkedIfNecessary,
    isPersonalLinkingNeededForCurrentIntegration,
    areLinesLoading,
    hasLinesLoadingError,
    isTermsOfServiceAccepted,
    salesforcefInitializationError,
    isAuthenticated,
    shouldShowAnnouncement,
    isEnterpriseLinkingNeededForCurrentIntegration,
  } = props;

  useEffect(() => {
    if (startupState === StartupState.Uninitialized) {
      void getActionFacade().startAuthentication();
    }
  }, [startupState]);

  const canUseLocalStorage = useMemo(isLocalStorageAvailable, []);
  const isNavigationDisabled = isIntegrationLinkedIfNecessary === false;

  const renderRoute = (route: AppRoute) => {
    return (
      <Route
        key={route.path}
        exact={route.exact}
        path={route.path}
        render={({ ...rest }) => {
          return route.renderComponent(rest);
        }}
      />
    );
  };

  const renderAllAvailableRoutes = () => {
    const { isNarrowIntegration, renderedPath, showTitle, currentIntegration, ...rest } = props;

    const Layout = isNarrowIntegration ? NarrowAppLayout : AppLayout;
    const pageTitle = showTitle ? PAGE_TITLES[renderedPath] : undefined;

    return (
      <Layout
        routes={getRoutes(currentIntegration)}
        bottomRoutes={getBottomRoutes()}
        primaryRoutes={getPrimaryRoutes(currentIntegration)}
        secondaryRoutes={getSecondaryRoutes()}
        renderedPath={renderedPath}
        title={pageTitle ? intl.formatMessage(pageTitle) : undefined}
        main={
          <Suspense fallback={<CenteredLoadingSpinner />}>
            <Switch>
              {routes.map((route) => renderRoute(route))}
              <RedirectKeepSearch pathname={ApplicationRoute.PHONE_ROUTE} />
            </Switch>
          </Suspense>
        }
        navigationDisabled={isNavigationDisabled}
        hideLogo={currentIntegration === Integrations.Zendesk}
        {...rest}
      />
    );
  };

  const renderRoutesForLinking = () => {
    const { isIntegrationLinkedIfNecessary, integrationName, isNarrowIntegration, currentIntegration, ...rest } = props;

    const Layout = isNarrowIntegration ? NarrowAppLayout : AppLayout;
    const pageTitle = PAGE_TITLES[ApplicationRoute.SETTINGS_ROUTE];

    if (isIntegrationLinkedIfNecessary) {
      return renderAllAvailableRoutes();
    }

    if (isIntegrationLinkedIfNecessary === false) {
      return (
        <Layout
          routes={getRoutes(currentIntegration)}
          bottomRoutes={getBottomRoutes()}
          primaryRoutes={getPrimaryRoutes(currentIntegration)}
          secondaryRoutes={getSecondaryRoutes()}
          title={pageTitle ? intl.formatMessage(pageTitle) : undefined}
          main={
            <Switch>
              {renderRoute(settingsRoute)}
              <RedirectKeepSearch pathname={ApplicationRoute.SETTINGS_ROUTE} />;
            </Switch>
          }
          navigationDisabled={isNavigationDisabled}
          {...rest}
          renderedPath={ApplicationRoute.SETTINGS_ROUTE}
        />
      );
    }

    return <FullPageAppLoading text={{ ...definedMessages.INTEGRATION_PAGE_LOADING, values: { integrationName } }} />;
  };

  if (!navigator.cookieEnabled) {
    return <CookiesUnavailablePage />;
  }

  if (!canUseLocalStorage) {
    return <LocalStorageUnavailablePage />;
  }

  if (!isTermsOfServiceAccepted) {
    return <RedirectKeepSearch pathname={ApplicationRoute.ONBOARDING} />;
  }

  // In case when logout is handled in new tab, the redirect won't happen, making sure the user is redirected.
  if (!isAuthenticated) {
    return <RedirectKeepSearch pathname={ApplicationRoute.ONBOARDING_LOGIN} />;
  }

  if (salesforcefInitializationError) {
    return <SFInitializationError />;
  }

  if (areLinesLoading) {
    return <FullPageAppLoading text={definedMessages.LINE_LOADING} />;
  }

  if (hasLinesLoadingError) {
    return <LinesLoadingErrorPage />;
  }

  if (startupState !== StartupState.Started) {
    return <FullPageAppLoading />;
  }

  if (shouldShowAnnouncement) {
    return (
      <Suspense fallback={<CenteredLoadingSpinner />}>
        <Announcement />
      </Suspense>
    );
  }

  if (isEnterpriseLinkingNeededForCurrentIntegration && isIntegrationLinkedIfNecessary === false) {
    return <RedirectKeepSearch pathname={ApplicationRoute.ONBOARDING_ENTERPRISE_LINKING} />;
  }

  if (isPersonalLinkingNeededForCurrentIntegration) {
    return renderRoutesForLinking();
  }

  return renderAllAvailableRoutes();
};
