import { Store } from 'redux';
import { AppState } from '../../reducers';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import {
  GetMoreOpportunitiesRequestMeta,
  getOpportunitiesRequest,
  getOpportunitiesError,
  getOpportunitiesSuccess,
  GetOpportunitiesSuccessPayload,
  getMoreOpportunitiesError,
  getMoreOpportunitiesSuccess,
  getMoreOpportunitiesRequest,
  createOpportunityRequest,
  createOpportunitySuccess,
  createOpportunityError,
  openOpportunityRequest,
  openOpportunitySuccess,
  openOpportunityError,
  getOpportunitiesCountSuccess,
  getOpportunitiesCountError,
  getOpportunitiesCountRequest,
} from './opportunity.actions';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { getOpportunityRequestMetaSelector } from './opportunity.selectors';
import { EntityType } from '../../constants';
import { SalesforceModalParams } from '../../../../salesforce-shared/salesforce-service.models';
import { Entity } from '../../models';

const OPPORTUNITIES_PAGING_LIMIT = 2;

export const definedMessages = defineMessages({
  GET_OPPORTUNITIES_ERROR: {
    id: 'Opportunities.Get.Error',
    defaultMessage: 'There was an error while downloading opportunities, please try again.',
  },
  CREATE_OPPORTUNITY_ERROR: {
    id: 'Opportunity.Create.Error',
    defaultMessage: 'There was an error while creating opportunity, please try again.',
  },
  OPEN_OPPORTUNITY_ERROR: {
    id: 'Opportunity.Open.Error',
    defaultMessage: 'There was an error while opening opportunity, please try again.',
  },
});

export class SalesforceLightningOpportunityActionCreator {
  constructor(private store: Store<AppState>, private salesforceLightningProxy: SalesforceServiceWindtalkProxy) {}

  public async loadMostRecentOpportunityOfContact(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose opportunities should be queried!');
      this.store.dispatch(getOpportunitiesError({ message: definedMessages.GET_OPPORTUNITIES_ERROR }));
      return;
    }
    try {
      this.store.dispatch(getOpportunitiesRequest());
      const opportunitiesWithMeta = await this.getMostRecentOpportunityOfContact(contactId);
      this.store.dispatch(getOpportunitiesSuccess(opportunitiesWithMeta));
    } catch (error) {
      logger.error('Error getting opportunities of contact', error);
      this.store.dispatch(getOpportunitiesError({ message: definedMessages.GET_OPPORTUNITIES_ERROR }));
    }
  }

  public async loadMoreOpportunitiesOfContact(): Promise<void> {
    const state = this.store.getState();

    try {
      const requestMeta = getOpportunityRequestMetaSelector(state);

      if (!requestMeta || !requestMeta.next) {
        logger.error('There is no next url stored to load more opportunities from!');
        this.store.dispatch(
          getMoreOpportunitiesError({
            message: definedMessages.GET_OPPORTUNITIES_ERROR,
          }),
        );
        return;
      }

      this.store.dispatch(getMoreOpportunitiesRequest());
      const opportunitiesWithMeta = await this.getMoreOpportunitiesOfContact(requestMeta);
      this.store.dispatch(getMoreOpportunitiesSuccess(opportunitiesWithMeta));
    } catch (error) {
      logger.error('Error getting opportunities of contact', error);
      this.store.dispatch(
        getMoreOpportunitiesError({
          message: definedMessages.GET_OPPORTUNITIES_ERROR,
        }),
      );
    }
  }

  public async createOpportunityForContact(contact: Entity): Promise<void> {
    if (!contact) {
      logger.error('Contact not specified when creating new opportunity.');
      this.store.dispatch(createOpportunityError({ message: definedMessages.CREATE_OPPORTUNITY_ERROR }));
      return;
    }

    try {
      this.store.dispatch(createOpportunityRequest());

      const fields: SalesforceModalParams['defaultFieldValues'] = {};
      const accountId =
        contact.type === EntityType.ACCOUNT
          ? contact.id
          : await this.salesforceLightningProxy.getAccountIdOfContact(contact.id);

      if (accountId) {
        fields.AccountId = accountId;
      }

      if (contact.type === EntityType.CONTACT) {
        fields.ContactId = contact.id;
      }

      await this.salesforceLightningProxy.createNewEntity({
        entityName: EntityType.OPPORTUNITY,
        defaultFieldValues: fields,
        inContextOfRecordId: contact.id,
      });

      this.store.dispatch(createOpportunitySuccess());
    } catch (error) {
      logger.error('Error creating opportunity', error);
      this.store.dispatch(createOpportunityError({ message: definedMessages.CREATE_OPPORTUNITY_ERROR }));
    }
  }

  public async openOpportunity(id: string): Promise<void> {
    if (!id) {
      logger.error('Please specify the ID of the opportunity to open it!');
      this.store.dispatch(openOpportunityError({ message: definedMessages.OPEN_OPPORTUNITY_ERROR }));
      return;
    }
    try {
      this.store.dispatch(openOpportunityRequest());
      await this.salesforceLightningProxy.openEntityPage(id);
      this.store.dispatch(openOpportunitySuccess());
    } catch (error) {
      logger.error('Error opening opportunity', error);
      this.store.dispatch(openOpportunityError({ message: definedMessages.OPEN_OPPORTUNITY_ERROR }));
    }
  }

  public async getOpportunitiesCount(contactId: string): Promise<void> {
    if (!contactId) {
      logger.error('Please specify the ID of the contact whose opportunities count should be queried!');
      this.store.dispatch(getOpportunitiesCountError({ message: definedMessages.GET_OPPORTUNITIES_ERROR }));
      return;
    }
    try {
      this.store.dispatch(getOpportunitiesCountRequest());
      const opportunitiesCount = await this.salesforceLightningProxy.getOpportunitiesCount(contactId);
      this.store.dispatch(getOpportunitiesCountSuccess({ opportunitiesCount }));
    } catch (error) {
      logger.error('Error getting opportunities count', error);
      this.store.dispatch(getOpportunitiesCountError({ message: definedMessages.GET_OPPORTUNITIES_ERROR }));
    }
  }

  private async getMostRecentOpportunityOfContact(contactId: string): Promise<GetOpportunitiesSuccessPayload> {
    const count = await this.salesforceLightningProxy.getOpportunitiesCount(contactId);
    const items = await this.salesforceLightningProxy.getOpportunities(contactId, 0, OPPORTUNITIES_PAGING_LIMIT);

    return {
      meta: {
        opportunitiesCount: count,
        next: count > 2 ? '1' : undefined,
        contactId,
      },
      opportunities: items,
    };
  }

  private async getMoreOpportunitiesOfContact(
    moreOpportunitiesMeta: GetMoreOpportunitiesRequestMeta,
  ): Promise<GetOpportunitiesSuccessPayload> {
    const contactId = moreOpportunitiesMeta.contactId || '';
    const offset = Number(moreOpportunitiesMeta.next);
    const opportunitiesCount = moreOpportunitiesMeta.opportunitiesCount || 0;

    const opportunities = await this.salesforceLightningProxy.getOpportunities(
      contactId,
      offset,
      OPPORTUNITIES_PAGING_LIMIT,
    );
    let next: Optional<string>;

    // if there are less item than the limit then we are done, no need to update offset
    if (opportunities.length === OPPORTUNITIES_PAGING_LIMIT) {
      if ((offset + 1) * OPPORTUNITIES_PAGING_LIMIT < opportunitiesCount) {
        next = '' + (offset + 1);
      }
    }

    return {
      meta: {
        opportunitiesCount: moreOpportunitiesMeta.opportunitiesCount || 0,
        next,
        contactId,
      },
      opportunities,
    };
  }
}
