import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button } from '@getgo/chameleon-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAllMessages } from '../../inAppNotification/message.action';
import { openGoToConnectApp } from '../../utils';
import { isDesktopIntegrationSelector } from '../../integrations/integrations.selector';
import { Banner } from '../../inAppNotification/Banner';

const definedMessages = defineMessages({
  TEST_CALL_FAILURE_MESSAGE: {
    id: 'Onboarding.TestCall.FailureMessage',
    defaultMessage: "Couldn't test a call. Make sure GoToConnect is running and try again.",
  },
  TEST_CALL_OPEN_APP_LABEL: {
    id: 'Open.App.Button.Label',
    defaultMessage: 'Open App',
  },
});

export const TestCallError: React.FC = () => {
  const dispatch = useDispatch();
  const isDesktopIntegration = useSelector(isDesktopIntegrationSelector);

  const dismissNotificationHandler = () => {
    dispatch(deleteAllMessages());
  };

  const onOpenAppClicked = () => {
    dismissNotificationHandler();
    const features = isDesktopIntegration ? ['openInDefaultBrowser'] : [];
    openGoToConnectApp(features);
  };

  return (
    <Banner
      variant="danger"
      isClosable={true}
      onClose={() => dismissNotificationHandler()}
      message={<FormattedMessage {...definedMessages.TEST_CALL_FAILURE_MESSAGE} />}
      actions={
        <Button inverse color="default" variant="outlined" size="small" onClick={onOpenAppClicked}>
          <FormattedMessage {...definedMessages.TEST_CALL_OPEN_APP_LABEL} />
        </Button>
      }
    />
  );
};
