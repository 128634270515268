import { Reducer } from 'redux';
import { CommunicationActions, CommunicationActionTypes, SaveCommunicationPayload } from './communication.action';

export interface NewCommunicationState {
  [callId: string]: SaveCommunicationPayload | undefined;
}

export const NEW_COMMUNICATION_INITIAL_STATE = {};

export const newCommunicationReducer: Reducer<NewCommunicationState, CommunicationActions> = (
  state = NEW_COMMUNICATION_INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CommunicationActionTypes.STORE_COMMUNICATION:
      return {
        ...state,
        [action.payload.call.id]: action.payload,
      };
    case CommunicationActionTypes.SAVE_COMMUNICATION_SUCCESS:
      const { callId } = action.payload;
      const nextState = {
        ...state,
      };
      delete nextState[callId];
      return nextState;

    default:
      return state;
  }
};
