import { createSelector } from 'reselect';
import { jiveEntityResourceSelector, jiveEntitySelector, jiveEntityTypes } from '../jiveEntities.selector';
import { relationshipResourceSelector } from './relationships.selector';

export const relationshipOneToManyGetChildrenIds = (
  relationshipName: 'lineVoicemails' | 'entityPhoneNumbers',
  parentEntityId: string,
) =>
  createSelector(
    relationshipResourceSelector(relationshipName),
    (relationship) => relationship.byId[parentEntityId] || [],
  );

export const relationshipOneToManyGetChildren = <T>(
  relationshipName: 'lineVoicemails' | 'entityPhoneNumbers',
  parentEntityId: string,
  childrenEntityName: jiveEntityTypes,
) =>
  createSelector(
    relationshipOneToManyGetChildrenIds(relationshipName, parentEntityId),
    jiveEntityResourceSelector<T>(childrenEntityName),
    (ids, childrenEntities) => {
      return ids.map((id) => {
        return childrenEntities.byId[id];
      });
    },
  );

export const relationshipOneToManyGetParentWithChildrenIds = <
  ParentModel,
  ParentWithChildrenIdsModel extends ParentModel,
>(
  relationshipName: 'lineVoicemails' | 'entityPhoneNumbers',
  parentEntityId: string,
  parentEntityName: jiveEntityTypes,
  parentChildrenKey: string,
) =>
  createSelector(
    jiveEntitySelector<ParentModel>(parentEntityName, parentEntityId),
    relationshipOneToManyGetChildrenIds(relationshipName, parentEntityId),
    (parent, childrenIds) => ({ ...parent, [parentChildrenKey]: childrenIds } as any as ParentWithChildrenIdsModel),
  );

export const relationshipOneToManyGetParentWithChildren = <
  ParentModel,
  ChildrenModel,
  ParentWithChildrenModel extends ParentModel,
>(
  relationshipName: 'lineVoicemails' | 'entityPhoneNumbers',
  parentEntityId: string,
  parentEntityName: jiveEntityTypes,
  parentChildrenKey: string,
  childrenEntityName: jiveEntityTypes,
) =>
  createSelector(
    jiveEntitySelector<ParentModel>(parentEntityName, parentEntityId),
    relationshipOneToManyGetChildren<ChildrenModel>(relationshipName, parentEntityId, childrenEntityName),
    (parent, children) => ({ ...parent, [parentChildrenKey]: children } as any as ParentWithChildrenModel),
  );
