import { ReactNode, forwardRef } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRouteType } from '../Main.component';
import { newTracker } from '../analytics-new/tracker-new';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';

export interface LinkDuoProps {
  children: ReactNode;
  route: ApplicationRouteType;
}

const LinkDuoComponent = forwardRef<HTMLAnchorElement, LinkDuoProps>(({ route, children, ...rest }, ref) => {
  const intl = useIntl();
  return route.isExternal ? (
    <a
      href={route.path}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={intl.formatMessage(route.description)}
      ref={ref as any}
      onClick={() => {
        if (route.trackingEvent) {
          newTracker.trackAnalyticsEvent(route.trackingEvent);
        }
      }}
      {...rest}
    >
      {children}
    </a>
  ) : (
      <Link
        to={{ pathname: route.path, search: window.location.search }}
        ref={ref as any}
        aria-label={intl.formatMessage(route.description)}
        onClick={() => {
          if (route.trackingEvent) {
            newTracker.trackAnalyticsEvent(route.trackingEvent);
          }
        }}
        {...rest}
      >
        {children}
      </Link>
    );
});

export const LinkDuo = forwardRef<{}, LinkDuoProps>((props, ref) => {
  return (
    <Tooltip ref={ref} title={<FormattedMessage {...props.route.description} />}>
      <LinkDuoComponent {...props} />
    </Tooltip>
  );
});
