import { AppState } from '../../reducers';
import { TestCallOptions } from './testCall.actions';
import { TestCallRequestState, TestCallState } from './testCall.reducer';

const testCallSelector = (state: AppState): TestCallState => state.testCall;

export const isTestCallPendingSelector = (state: AppState): boolean =>
  testCallSelector(state).testCallState === TestCallRequestState.Loading;

export const hasTestCallSucceededSelector = (state: AppState): boolean =>
  testCallSelector(state).testCallState === TestCallRequestState.Succeeded;

export const isTestCallSelector = (callId: string) => (state: AppState): boolean =>
  testCallSelector(state).testCallId === callId;

export const testCallOptionsSelector = (state: AppState): TestCallOptions | undefined =>
  testCallSelector(state).testCallOptions;
