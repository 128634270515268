import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { noteTypesSelector } from './connectwise.notetype.selectors';
import {
  NoteTypePickerStateProps,
  NoteTypePickerProps,
  NoteTypePickerComponent,
} from './ConnectWiseNoteTypePicker.component';

const mapStateToProps = (state: AppState): NoteTypePickerStateProps => ({
  noteTypes: noteTypesSelector(state).noteTypes,
  requestState: noteTypesSelector(state).requestState,
});

export const NoteTypePicker = connect<NoteTypePickerStateProps, {}, NoteTypePickerProps, AppState>(mapStateToProps)(
  NoteTypePickerComponent,
);
