import { AppState } from '../reducers/index';
import { externalUserKeySelector } from '../authentication/authentication.selector';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { selectedLanguageSelector } from '../settings/settings.selector';

class PendoService {
  // private store: Store<AppState>;

  public initialize(getState: () => AppState) {
    // this.store = store;
    const state = getState();

    const userId = externalUserKeySelector(state);
    const integration = currentIntegrationNameSelector(state);
    // pendo expects the language identifier to be separeted by '_' instead of '-'
    // e.g.: instead of us-en it should be us_en
    const language = selectedLanguageSelector(state).replace('-', '_');

    if (!userId) {
      return;
    }

    pendo.initialize({
      visitor: {
        id: userId,
        jif_integration: integration,
        locale: language,
      },
    });
  }

  updateLocale(locale: string) {
    pendo.updateOptions({ visitor: { locale: locale.replace('-', '_') } });
  }
}

export const pendoService = new PendoService();
