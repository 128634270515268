import { connect } from 'react-redux';
import './index.css';
import {
  currentIntegrationNameSelector,
  currentIntegrationSelector,
  enterpriseLinkingNeededForCurrentIntegrationSelector,
  integrationStartupStateSelector,
  isIntegrationLinkedIfNecessarySelector,
  isNarrowIntegrationSelector,
  personalLinkingNeededForCurrentIntegrationSelector,
} from './integrations/integrations.selector';
import { MainComponent, MainOwnProps, MainStateProps } from './Main.component';
import { areLinesLoadingSelector, hasLinesLoadingErrorSelector } from './lines/lines.selectors';
import { isTermsOfServiceAcceptedSelector } from './termsOfService/termsOfService.selector';
import { endedOrActiveCallSelector } from './calls/calls.selector';
import { ApplicationRoute } from './constants';
import { getActiveRoute } from './browserHistory';
import { AppState } from './reducers';
import { salesforceInitializationErrorSelector } from './salesforce/salesforce.selectors';
import { isAuthenticatedSelector } from './authentication/authentication.selector';
import { hasNewAnnouncementSelector } from './changelog/changelog.selector';

const mapStateToProps: (state: AppState, ownProps: MainOwnProps) => MainStateProps = (state, { location }) => {
  // renderedPath values here must be paths of the tabs that are displayed in the app bar
  let renderedPath = location.pathname;
  const { route, match } = getActiveRoute(location);
  let showTitle = true;

  // let's show call history or active calls selected when call details page is rendered
  // TODO: calculation of renderedPath should go somewhere else. Probably it should go near to the user of this prop (LinkTabsComponent)
  if (
    (route === ApplicationRoute.CALL_ROUTE || route === ApplicationRoute.CALL_ROUTE_WITH_ACTION) &&
    !!match &&
    !!(match?.params as any)?.callId
  ) {
    const { callId } = match.params as { callId: string };
    const endedOrActiveCall = endedOrActiveCallSelector(state, callId);
    renderedPath =
      endedOrActiveCall === undefined || endedOrActiveCall.endTime
        ? ApplicationRoute.CALL_HISTORY_ROUTE
        : ApplicationRoute.ACTIVE_CALLS_ROUTE;
    showTitle = false; // do not show the History / Active Call title when call details page is rendered
  } else if (
    route === ApplicationRoute.CONVERSATIONS_ROUTE ||
    route === ApplicationRoute.CONVERSATION_ROUTE ||
    route === ApplicationRoute.CONVERSATION_LOG_ROUTE
  ) {
    renderedPath = ApplicationRoute.CONVERSATIONS_ROUTE;
  } else if (location.pathname === '/') {
    renderedPath = ApplicationRoute.PHONE_ROUTE; // default
  }

  return {
    isIntegrationLinkedIfNecessary: isIntegrationLinkedIfNecessarySelector(state),
    areLinesLoading: areLinesLoadingSelector(state),
    hasLinesLoadingError: hasLinesLoadingErrorSelector(state),
    currentIntegration: currentIntegrationSelector(state),
    startupState: integrationStartupStateSelector(state),
    isTermsOfServiceAccepted: isTermsOfServiceAcceptedSelector(state),
    isPersonalLinkingNeededForCurrentIntegration: personalLinkingNeededForCurrentIntegrationSelector(state),
    isEnterpriseLinkingNeededForCurrentIntegration: enterpriseLinkingNeededForCurrentIntegrationSelector(state),
    integrationName: currentIntegrationNameSelector(state),
    isNarrowIntegration: isNarrowIntegrationSelector(state),
    salesforcefInitializationError: salesforceInitializationErrorSelector(state),
    isAuthenticated: isAuthenticatedSelector(state),
    renderedPath,
    showTitle,
    shouldShowAnnouncement: hasNewAnnouncementSelector(state),
  };
};

export default connect<MainStateProps, {}, MainOwnProps, AppState>(mapStateToProps)(MainComponent);
