import { makeActionCreator } from '../../actions/actionCreators';
import { Matter } from '../clio.models';

export enum MattersActionsType {
  SEARCH_MATTERS = 'clio/matters/search',
  SEARCH_MATTERS_SUCCESS = 'clio/matters/search/success',
  SEARCH_MATTERS_ERROR = 'clio/matters/search/error',
  CLEAR_MATTERS = 'clio/matters/clear',
}

export interface SearchMattersPayload {
  query: string;
}
export interface SearchMattersSuccessPayload {
  matters: Matter[];
}
export interface SearchMattersErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export const clearMatters = makeActionCreator(MattersActionsType.CLEAR_MATTERS)();
export const searchMatters = makeActionCreator(MattersActionsType.SEARCH_MATTERS)<SearchMattersPayload>();
export const searchMattersSuccess = makeActionCreator(MattersActionsType.SEARCH_MATTERS_SUCCESS)<
  SearchMattersSuccessPayload
>();
export const searchMattersError = makeActionCreator(MattersActionsType.SEARCH_MATTERS_ERROR)<
  SearchMattersErrorPayload
>();

export type MatterActions =
  | ReturnType<typeof searchMatters>
  | ReturnType<typeof searchMattersSuccess>
  | ReturnType<typeof searchMattersError>
  | ReturnType<typeof clearMatters>;
