import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { AppState } from '../reducers';
import { getMoreNotes, getNotes } from './notes.actions';
import { GetMoreNotesRequestState, GetNotesRequestState, Note } from './notes.models';
import {
  canLoadMoreNotesSelector,
  getMoreNotesRequestStateSelector,
  getNotesRequestStateSelector,
  notesListSelector,
} from './notes.selectors';
import NotesListComponent from './NotesList.component';
import { CallContextPanelProps } from '../calls/CallContextPanel.models';

export interface NotesListStateProps {
  notes: Note[];
  requestState: GetNotesRequestState;
  moreRequestState: GetMoreNotesRequestState;
  canLoadMoreNotes: boolean;
}

export interface NotesListDispatchProps {
  getMoreNotesAction: () => void;
  getNotes: (contactId: string) => void;
}

const mapStateToProps = (state: AppState): NotesListStateProps => ({
  notes: notesListSelector(state),
  requestState: getNotesRequestStateSelector(state),
  moreRequestState: getMoreNotesRequestStateSelector(state),
  canLoadMoreNotes: canLoadMoreNotesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppAction>): NotesListDispatchProps => ({
  getMoreNotesAction: () => dispatch(getMoreNotes()),
  getNotes: (contactId) => dispatch(getNotes({ contactId })),
});

export default connect<NotesListStateProps, NotesListDispatchProps, CallContextPanelProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(NotesListComponent);
