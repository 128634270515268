import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { CoxService } from '../cox.service';
import { logger } from '../../logging/logger';
import { newTracker } from '../../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import {
  loadServiceAppointments,
  loadServiceAppointmentsError,
  loadServiceAppointmentsSuccess,
} from '../serviceAppointments/serviceAppointments.actions';

const definedMessages = defineMessages({
  SERVICE_APPOINTMENT_LIST_LOAD_ERROR: {
    id: 'Cox.ServiceAppointments.List.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading service appointments. Please try again later.',
  },
});

const trackingEvents = defineTrackingEvents({
  SERVICE_APPOINTMENTS_LOAD_STARTED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListStarted,
    label: 'Service appointments',
  },
  SERVICE_APPOINTMENTS_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Service appointments',
  },
  SERVICE_APPOINTMENTS_LOAD_FAILED: {
    category: AnalyticsCategory.ContentList,
    action: AnalyticsAction.LoadListFailed,
    label: 'Service appointments',
  },
});

export class ServiceAppointmentsActionCreator {
  constructor(protected store: Store<AppState>) {}

  async loadServiceAppointmentsForVehicle(VIN: string): Promise<void> {
    newTracker.trackAnalyticsEvent(trackingEvents.SERVICE_APPOINTMENTS_LOAD_STARTED);
    this.store.dispatch(loadServiceAppointments({ VIN }));

    try {
      const serviceAppointments = await CoxService.getServiceAppointmentsByVIN(VIN);
      serviceAppointments.sort(
        (appointment1, appointment2) =>
          appointment2.appointmentDateTime.valueOf() - appointment1.appointmentDateTime?.valueOf(),
      );
      this.store.dispatch(loadServiceAppointmentsSuccess({ VIN, serviceAppointments }));
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.SERVICE_APPOINTMENTS_LOAD_SUCCEEDED,
        label: `Service appointments | ${serviceAppointments.length}`,
      });
    } catch (error) {
      logger.error('Service appointment loading error', error);
      this.store.dispatch(
        loadServiceAppointmentsError({ VIN, message: definedMessages.SERVICE_APPOINTMENT_LIST_LOAD_ERROR }),
      );
      newTracker.trackAnalyticsEvent(trackingEvents.SERVICE_APPOINTMENTS_LOAD_FAILED);
    }
  }
}
