import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { Communication } from '../clio.models';
import { SavedCommunicationState } from './saved-communication.reducer';

const savedCommunicationsSelector = (state: AppState): SavedCommunicationState =>
  state.clio && state.clio.savedCommunications;

export const savedCommunicationByCallIdSelector: (state: AppState, callId: string) => Communication | undefined =
  createSelector(
    savedCommunicationsSelector,
    (_, callId: string) => callId,
    (communication, callId) => communication && communication[callId],
  );
