import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../../actions/actionCreators';

export enum LinkAccountActionsTypes {
  LINK_ACCOUNT_REQUEST = 'linkAccount/request',
  LINK_ACCOUNT_SUCCESS = 'linkAccount/success',
}

export const linkAccountRequest = makeActionCreator(LinkAccountActionsTypes.LINK_ACCOUNT_REQUEST)();

interface LinkAccountSuccessPayload {
  message: MessageDescriptor;
}

export const linkAccountSuccess = makeActionCreator(LinkAccountActionsTypes.LINK_ACCOUNT_SUCCESS)<
  LinkAccountSuccessPayload
>();
