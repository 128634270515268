import { makeActionCreator } from '../actions/actionCreators';
import { AnalyticsPageType } from './analytics.models';

export enum AnalyticsActionTypes {
  SET_ANALYTICS_CURRENT_PAGE_TYPE = 'analytics/currentPageType/set',
}

export const setAnalyticsCurrentPageType = makeActionCreator(AnalyticsActionTypes.SET_ANALYTICS_CURRENT_PAGE_TYPE)<
  AnalyticsPageType
>();

export type AnalyticsActions = ReturnType<typeof setAnalyticsCurrentPageType>;
