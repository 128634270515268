import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { AppState } from '../reducers';
import { AppAction } from '../actions/actionCreators';
import { logger } from '../logging';
import { SettingsActionTypes } from '../constants';
import { syncService } from '../sync/sync.service';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { stockPileService, StockpileTtl } from '../stockPile/stockPile.service';
import { Integrations } from '../models';
import { SettingsState } from './settings.reducer';

const settingActionTypeValues: string[] = Object.values(SettingsActionTypes);

export const SETTINGS_PERSISTENCE_KEY_PREFIX = 'settings_state_';

export const settingsPersistenceMiddleware: Middleware =
  ({ getState }: MiddlewareAPI<Dispatch, AppState>) =>
  (next) =>
  async (action: AppAction<string>) => {
    try {
      next(action); // let's wait all middleware and reducer to finish

      if (!settingActionTypeValues.includes(action.type)) {
        return;
      }
      const state = getState();
      const settings = { ...state.settings };

      const currentIntegration = currentIntegrationSelector(state);

      await syncService.executeOnce(
        `setting_state_persist_sync`,
        action.type,
        async () => {
          const key = `${SETTINGS_PERSISTENCE_KEY_PREFIX}${currentIntegration}`;
          await stockPileService.updateBucket(key, settings, StockpileTtl.Forever);
        },
        250,
      );
    } catch (e) {
      logger.error('could not store settings state', e);
    }
  };

export const getPersistedSettingState = async (Integration: Integrations): Promise<SettingsState | undefined> => {
  try {
    return await stockPileService.getBucket(`${SETTINGS_PERSISTENCE_KEY_PREFIX}${Integration}`);
  } catch (e) {
    logger.error('could not get persisted settings', e);
  }
};
