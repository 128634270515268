import { Store } from 'redux';
import { AppState } from '../../reducers';
import { SalesforceServiceWindtalkProxy } from '../salesforceLightning.proxy.model';
import {
  loadSavedCallLogError,
  loadSavedCallLogRequest,
  loadSavedCallLogSuccess,
  salesforceCallLogError,
  salesforceCallLogSaved,
  salesforceCallLogStarted,
  salesforceCallLogUpdateStarted,
  storeSalesforceCallLog,
  searchWhatObjectsError,
  searchWhatObjectsRequest,
  searchWhatObjectsSuccess,
  searchWhoObjectsError,
  searchWhoObjectsRequest,
  searchWhoObjectsSuccess,
} from '../salesforce.actions';
import { logger } from '../../logging';
import { defineMessages } from 'react-intl';
import { savedTaskRecordIdSelector, selectSalesforceObjectNameByObjectId } from '../salesforce.selectors';
import { showErrorMessage, showMessage, showSuccessMessage } from '../../inAppNotification/message.action';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { allCallSelector, contactInCallSelector } from '../../calls/calls.selector';
import { injectedIntlSelector, selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import {
  callHistoryElementByCallSelector,
  selectUserCallHistoryItemById,
} from '../../callHistoryPush/unifiedCallHistory.selector';
import {
  calculateCallDuration,
  getCallLogObject,
  getCallLogObjectWithNoEntity,
} from '../../calls/callLogger.utilities';
import { closeDialog, openDialog } from '../../dialog/dialog.actions';
import { MessageVariant } from '../../models';
import { SalesforceCallLog } from '../../../../salesforce-shared/salesforce-service.models';
import { DialogBodyNames } from '../../dialog/DialogBodies';

const definedMessages = defineMessages({
  CALL_LOG_SAVE_SUCCESS_MESSAGE: {
    id: 'Salesforce.CallLog.SaveSuccess.Message',
    defaultMessage: 'Call logged in Salesforce',
  },
  CALL_LOG_SAVE_ERROR_MESSAGE: {
    id: 'Salesforce.CallLog.SaveError.Message',
    defaultMessage: 'There was an error while saving the call log, please try again.',
  },
  SEARCH_CALL_LOG_ERROR_MESSAGE: {
    id: 'Salesforce.CallLog.SearchError.Message',
    defaultMessage: 'There was an error while searching for call log in Salesforce, please try again.',
  },
  LOG_SAVE_FAILED_DIALOG_TITLE: {
    id: 'Salesforce.CallLog.Failed.Dialog.Title',
    defaultMessage: 'Continue in Salesforce',
  },
  LOG_SAVE_FAILED_DIALOG_CONFIRM: {
    id: 'Salesforce.CallLog.Failed.Dialog.Confirm',
    defaultMessage: 'Continue in Salesforce',
  },
  LOG_SAVE_FAILED_DIALOG_CANCEL: {
    id: 'Salesforce.CallLog.Failed.Dialog.Cancel',
    defaultMessage: 'Discard changes',
  },
  LOG_CALL_SAVE_LATER: {
    id: 'Salesforce.CallLogForm.Create.Delayed',
    defaultMessage: 'This call will be logged in Salesforce when the call is ended.',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_LOG_SAVED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.CallLogCreationSucceeded,
    label: '-',
  },
  CALL_LOG_SAVE_FAILED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.CallLogCreationFailed,
    label: '-',
  },
  CALL_LOG_CONTINUE_MODAL_CANCEL: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Continue in Salesforce Modal | Discard Changes | button',
  },
  CALL_LOG_CONTINUE_MODAL_CONFIRM: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Continue in Salesforce Modal | Continue in Salesforce | button',
  },
});

export class SalesforceLightningCallLogActionCreator {
  constructor(private store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {}

  public async loadSavedCallLog(callId: string): Promise<void> {
    try {
      this.store.dispatch(loadSavedCallLogRequest({ callId }));

      const savedTaskRecordId = savedTaskRecordIdSelector(this.store.getState(), callId);
      const callLog = savedTaskRecordId
        ? await this.salesforceLightningProxy.getCallLogByTaskId(savedTaskRecordId)
        : await this.salesforceLightningProxy.getCallLogByCallId(callId);

      const who = !!callLog?.WhoId ? await this.salesforceLightningProxy.getObjectById(callLog.WhoId) : undefined;
      const what = !!callLog?.WhatId ? await this.salesforceLightningProxy.getObjectById(callLog.WhatId) : undefined;

      this.store.dispatch(
        loadSavedCallLogSuccess({
          callId,
          callLog: callLog ? { ...callLog, WhatName: what?.name, WhoName: who?.name } : undefined,
        }),
      );
    } catch (e) {
      logger.error('Could not get Salesforce call log', e);
      this.store.dispatch(
        loadSavedCallLogError({
          callId,
          message: definedMessages.SEARCH_CALL_LOG_ERROR_MESSAGE,
        }),
      );
    }
  }

  public async createCallLog(
    callLogToCreate: Pick<SalesforceCallLog, 'CallId' | 'Description' | 'CallDispositionValue' | 'WhoId' | 'WhatId'>,
  ): Promise<void> {
    const { CallId, Description, CallDispositionValue, WhoId, WhatId } = callLogToCreate;
    try {
      this.store.dispatch(salesforceCallLogStarted());
      const state = this.store.getState();
      const call = allCallSelector(state, CallId);
      const contact = contactInCallSelector(state, CallId);
      const intl = injectedIntlSelector(state);
      const locale = selectedDateFnsLocaleSelector(state);
      const callHistoryItem = call.answeredLegId ? selectUserCallHistoryItemById(state, call.answeredLegId) : undefined;
      const callDurationInSeconds = calculateCallDuration(call, callHistoryItem);
      const receivedCallHistoryElement = callHistoryElementByCallSelector(call)(state);

      if (contact || WhoId || WhatId) {
        const callLogParams = {
          call,
          intl,
          locale,
          callDurationInSeconds,
        };

        const { EntityId, ...callLog } = contact
          ? getCallLogObject({ ...callLogParams, contact })
          : { ...getCallLogObjectWithNoEntity(callLogParams), EntityId: undefined };

        const hasCallEnded = receivedCallHistoryElement || call.endTime;

        // If the call is still active we store the log in memory and save it in sf when the call is ended.
        if (hasCallEnded) {
          await this.saveCallLog({
            ...callLog,
            CallDispositionValue,
            Description,
            EntityId,
            WhoId,
            WhatId,
          } as SalesforceCallLog);
        } else {
          this.store.dispatch(
            storeSalesforceCallLog({
              callId: CallId,
              callLog: {
                ...callLog,
                CallDispositionValue,
                Description,
                EntityId,
                WhoId,
                WhatId,
              } as SalesforceCallLog,
            }),
          );
          this.store.dispatch(
            showMessage({
              id: 'salesforce_call_log_saved_later',
              message: definedMessages.LOG_CALL_SAVE_LATER,
              type: MessageVariant.Info,
              params: {
                autoHide: true,
                dismissible: true,
              },
            }),
          );
        }
      }
    } catch (e) {
      // we'll only land here if something goes wrong with the selectors or the call log mapping
      this.store.dispatch(showErrorMessage(definedMessages.CALL_LOG_SAVE_ERROR_MESSAGE));
      logger.error('Could not create Salesforce call log', e);
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CALL_LOG_SAVE_FAILED,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        label: e?.message?.toString() || 'Unknown issues',
      });
    }
  }

  public async updateCallLog(
    callLogToUpdate: Pick<SalesforceCallLog, 'CallId' | 'Description' | 'CallDispositionValue' | 'WhatId' | 'WhoId'> & {
      Id: string;
    },
  ): Promise<void> {
    try {
      this.store.dispatch(salesforceCallLogUpdateStarted());
      const recordId = await this.salesforceLightningProxy.updateCallLog(callLogToUpdate);
      this.store.dispatch(showSuccessMessage(definedMessages.CALL_LOG_SAVE_SUCCESS_MESSAGE));
      this.store.dispatch(
        salesforceCallLogSaved({
          callId: callLogToUpdate.CallId,
          taskRecordId: recordId,
        }),
      );
    } catch (e) {
      this.store.dispatch(salesforceCallLogError({ error: e }));
      logger.error('Could not update Salesforce call log', e);
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CALL_LOG_SAVE_FAILED,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        label: e?.message?.toString() || 'Unknown issues',
      });
      this.handleCallLogError(() => this.salesforceLightningProxy.editCallInSalesforce(callLogToUpdate));
    }
  }

  public async searchWhatObjects(what: string, query?: string): Promise<void> {
    try {
      this.store.dispatch(searchWhatObjectsRequest());
      const result = await this.salesforceLightningProxy.searchTaskWhatObjects(what, query);
      this.store.dispatch(searchWhatObjectsSuccess(result));
    } catch (e) {
      this.store.dispatch(searchWhatObjectsError());
      logger.error('error searching what object', e);
    }
  }

  public async searchWhoObjects(who: string, query?: string): Promise<void> {
    try {
      this.store.dispatch(searchWhoObjectsRequest());
      const result = await this.salesforceLightningProxy.searchTaskWhoObjects(who, query);
      this.store.dispatch(searchWhoObjectsSuccess(result));
    } catch (e) {
      this.store.dispatch(searchWhoObjectsError());
      logger.error('error searching who object', e);
    }
  }

  private handleCallLogError(fallbackFunctionToSaveCallLog: () => Promise<void>): void {
    this.store.dispatch(
      openDialog({
        dialogId: 'save-log-failed-dialog',
        texts: {
          title: definedMessages.LOG_SAVE_FAILED_DIALOG_TITLE,
          confirm: definedMessages.LOG_SAVE_FAILED_DIALOG_CONFIRM,
          cancel: definedMessages.LOG_SAVE_FAILED_DIALOG_CANCEL,
        },
        bodyName: DialogBodyNames.CallLogErrorContinueInSalesforce,
        cancellable: true,
        confirmAction: closeDialog({}),
        hideCancelButton: false,
        confirmTrackingEvent: trackingEvents.CALL_LOG_CONTINUE_MODAL_CONFIRM,
        cancelTrackingEvent: trackingEvents.CALL_LOG_CONTINUE_MODAL_CANCEL,
        closeCallback: async ({ isConfirmed }) => {
          if (!isConfirmed) {
            return;
          }

          try {
            await fallbackFunctionToSaveCallLog();
          } catch (e) {
            logger.error('Failed to open call log forms in Salesforce', e);
            this.store.dispatch(showErrorMessage(definedMessages.CALL_LOG_SAVE_ERROR_MESSAGE));
          }
        },
      }),
    );
  }

  private async saveCallLog(callLog: SalesforceCallLog): Promise<void> {
    try {
      const recordId = await this.salesforceLightningProxy.createCallLog(callLog);

      this.store.dispatch(
        salesforceCallLogSaved({
          callId: callLog.CallId,
          taskRecordId: recordId,
        }),
      );

      this.store.dispatch(showSuccessMessage(definedMessages.CALL_LOG_SAVE_SUCCESS_MESSAGE));
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CALL_LOG_SAVED,
        label: await this.getCallLogTrackingLabel(callLog),
      });
    } catch (e) {
      this.store.dispatch(salesforceCallLogError({ error: e }));
      logger.error('Could not save Salesforce call log', e);
      newTracker.trackAnalyticsEvent({
        ...trackingEvents.CALL_LOG_SAVE_FAILED,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        label: e?.message?.toString() || 'Unknown issues',
      });
      this.handleCallLogError(() => this.salesforceLightningProxy.saveCallInSalesforce(callLog));
    }
  }

  private async getCallLogTrackingLabel(callLog: SalesforceCallLog) {
    const state = this.store.getState();
    const hasWho = !!callLog.WhoId;
    const hasWhat = !!callLog.WhatId;
    const whoName = hasWho ? selectSalesforceObjectNameByObjectId(callLog.WhoId)(state) : '-';
    const whatName = hasWhat ? selectSalesforceObjectNameByObjectId(callLog.WhatId)(state) : '-';
    return `${hasWho ? '1' : '0'}${hasWhat ? '1' : '0'} | ${whoName} | ${whatName}`;
  }
}
