import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { EmptyStateWithLogo } from './EmptyStateWithLogo';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { BlockIcon } from '@getgo/chameleon-icons/react';

export const definedMessages = defineMessages({
  TITLE: {
    id: 'Startup.Error.BlockedStorage.Title',
    defaultMessage: 'No access to local storage',
  },
  SUBTITLE: {
    id: 'Startup.Error.BlockedStorage.Message',
    defaultMessage:
      'To use this integration, contact your admin about granting access to the local storage on your browser.',
  },
});

const trackingEvents = defineTrackingEvents({
  PAGE_LOADED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PageLoaded,
    label: 'Local storage unavailable',
  },
});

export const LocalStorageUnavailablePage = () => {
  useEffect(() => {
    newTracker.trackAnalyticsEvent(trackingEvents.PAGE_LOADED);
  }, []);

  return <EmptyStateWithLogo icon={<BlockIcon />} title={definedMessages.TITLE} subtitle={definedMessages.SUBTITLE} />;
};
