import { AppAction } from '../actions/actionCreators';
import { DialogActions, DialogActionsType } from './dialog.actions';
import { DialogBodyNames } from './DialogBodies';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { FormattedMessageWithMessageValues } from '../reactInltModels';
import { ButtonVariant } from '@getgo/chameleon-core';
import { BadgeProps } from '@getgo/chameleon-web/react';

export interface DialogTexts {
  title: FormattedMessageWithMessageValues;
  body?: FormattedMessageWithMessageValues;
  confirm?: FormattedMessageWithMessageValues;
  cancel?: FormattedMessageWithMessageValues;
}

export interface DialogStyles {
  confirmButtonVariant: ButtonVariant;
}

export interface DialogBadge {
  variant: BadgeProps['variant'];
  content: FormattedMessageWithMessageValues;
}

export interface DialogState {
  dialogId?: string;
  isOpen: boolean;
  confirmAction?: AppAction;
  confirmTrackingEvent?: TrackingEvent;
  body?: JSX.Element; // this takes priority over texts.body
  bodyName?: DialogBodyNames;
  texts?: DialogTexts;
  cancellable?: boolean;
  cancelTrackingEvent?: TrackingEvent;
  hideCancelButton?: boolean;
  badge?: DialogBadge;
  closeCallback?: (params: { isConfirmed: boolean }) => void;
  dialogStyles?: DialogStyles;
}

export const INITIAL_STATE: DialogState = {
  dialogId: undefined,
  isOpen: false,
  confirmAction: undefined,
  texts: undefined,
  cancellable: true,
  bodyName: undefined,
  cancelTrackingEvent: undefined,
  confirmTrackingEvent: undefined,
  hideCancelButton: undefined,
  closeCallback: undefined,
  dialogStyles: undefined,
  badge: undefined,
};

export const dialogReducer = (state = INITIAL_STATE, action: DialogActions): DialogState => {
  switch (action.type) {
    case DialogActionsType.OPEN: {
      const { cancellable } = action.payload;
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        cancellable: cancellable == null ? true : cancellable,
      };
    }
    case DialogActionsType.CLOSE: {
      // do not close dialog if dialogId does not match
      if (!!action.payload.dialogId && state.dialogId !== action.payload.dialogId) {
        return state;
      } else {
        return {
          ...state,
          closeCallback: undefined,
          isOpen: false,
        };
      }
    }
    case DialogActionsType.RESET: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};

export default dialogReducer;
