import { RedtailActivityActions, RedtailActivityActionsType } from './redtail.activity.actions';

export interface RedtailActivitiesState {
  isActivitySaving: boolean;
}

export const INITIAL_STATE: RedtailActivitiesState = {
  isActivitySaving: false,
};

export const redtailActivitiesReducer = (
  state = INITIAL_STATE,
  action: RedtailActivityActions,
): RedtailActivitiesState => {
  switch (action.type) {
    case RedtailActivityActionsType.ADD_ACTIVITY_REQUEST:
      return {
        ...state,
        isActivitySaving: true,
      };
    case RedtailActivityActionsType.ADD_ACTIVITY_SUCCESS:
    case RedtailActivityActionsType.ADD_ACTIVITY_ERROR:
      return {
        ...state,
        isActivitySaving: false,
      };
    default:
      return state;
  }
};
