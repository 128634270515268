import { makeActionCreator } from '../actions/actionCreators';
import { Entitlements } from './authentication.models';

export enum AuthenticationActionsType {
  LOGIN_PROCESSING = 'authentication/loginProcessing',
  LOGIN_SUCCESS = 'authentication/loginSuccess',
  LOGIN_ERROR = 'authentication/loginError',
  LOGOUT_SUCCESS = 'authentication/logoutSuccess',
}

export enum AuthType {
  Redirect = 'redirect',
  NewTab = 'newTab',
  // IntegrationDefault means the default auth type used per integration.
  // See: integrationDefaultAuthType map in authentication.service.ts
  IntegrationDefault = 'integrationDefault',
}

export interface AuthRequestPayload {
  authType: AuthType;
}

export const logoutSuccess = makeActionCreator(AuthenticationActionsType.LOGOUT_SUCCESS)();

interface LoginSuccessPayload {
  externalUserKey: string;
  token: string;
  jiveId: string;
  expiration: number;
  name: string;
  entitlements: Entitlements[];
}

export const loginSuccess = makeActionCreator(AuthenticationActionsType.LOGIN_SUCCESS)<LoginSuccessPayload>();

interface LoginErrorPayload {
  error: string;
}
export const loginError = makeActionCreator(AuthenticationActionsType.LOGIN_ERROR)<LoginErrorPayload>();

export type AuthenticationActions =
  | ReturnType<typeof logoutSuccess>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof loginError>;
