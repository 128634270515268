import { Call, CallType } from '@jive/realtime-events';
import React from 'react';
import { tracker } from '../analytics/tracker';
import { TrackingEventLabels, TrackingEventNames } from '../analytics/tracker.models';
import { ClickToCallActionTypes } from '../constants';
import { logger } from '../logging';
import { addSmartListener } from '../middlewares/listener.middleware';
import { MessageVariant, Integrations } from '../models';
import { selectedLineIdSelector } from '../settings/settings.selector';
import { showMessage } from '../inAppNotification/message.action';
import { definedMessages } from '../inAppNotification/message.content';
import { activeCallsSelector } from './calls.selector';
import { clickToCallError, clickToCallSuccess } from './click-to-call.action';
import ClickToCallErrorMessage from './ClickToCallErrorMessage.container';
import ClickToCallCallingMessage from './ClickToCallCallingMessage.container';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { resetCalledContact } from '../phone/phone.actions';
import { CLICK_TO_CALL_TIMEOUT, initiateCall } from './click-to-call.service';
import { callHistoryArraySelector } from '../callHistoryPush/unifiedCallHistory.selector';

addSmartListener(ClickToCallActionTypes.CALL_REQUEST)(async (store, action) => {
  const state = store.getState();
  const selectedLineId = selectedLineIdSelector(state);

  try {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await store.dispatch(
      showMessage({
        id: 'click_to_call_calling_msg',
        type: MessageVariant.Custom,
        params: {
          autoHide: false, // to prevent hiding the notification about the outgoing call
          customContent: (
            <ClickToCallCallingMessage
              phoneNumber={action.payload.phoneNumber}
              contactName={action.payload.contactName}
            />
          ),
        },
      }),
    );
    await initiateCall(action.payload.phoneNumber, selectedLineId);

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await store.dispatch(clickToCallSuccess());
  } catch (error) {
    logger.error('Failed to start click-to-call.', error);
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await store.dispatch(clickToCallError({ message: definedMessages.CLICK_TO_CALL_ERROR_TITLE }));
  }
});

addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, action) => {
  if (action.payload !== Integrations.MsTeams) {
    registerClickToCallListener();
  }
});

let registered = false;

function registerClickToCallListener() {
  if (registered) {
    return;
  }

  registered = true;

  addSmartListener(ClickToCallActionTypes.CALL_SUCCESS)(async (store) => {
    setTimeout(() => {
      const { getState, dispatch } = store;
      const calls: Call[] = [...activeCallsSelector(getState()), ...callHistoryArraySelector(getState())];
      if (!calls.some(isRecentMissedOrOutgoingCall)) {
        tracker.trackEvent(TrackingEventLabels.ClickToCall, TrackingEventNames.NoCallEstablishedWarning);

        dispatch(resetCalledContact());
        dispatch(
          showMessage({
            id: 'click_to_call_error_msg',
            message: definedMessages.CLICK_TO_CALL_ERROR_MESSAGE,
            type: MessageVariant.Custom,
            params: {
              autoHide: false,
              customContent: <ClickToCallErrorMessage />,
            },
          }),
        );
      }
    }, CLICK_TO_CALL_TIMEOUT);
  });
}

function isRecentMissedOrOutgoingCall(call: Call) {
  const fewSecsBeforeNow = new Date(Date.now() - 30000);
  return (
    (call.type === CallType.OutgoingCall || call.type === CallType.MissedCall || call.type === CallType.DeclinedCall) &&
    new Date(call.creationTime) > fewSecsBeforeNow
  );
}
