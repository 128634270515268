import React from 'react';
import { Call } from '@jive/realtime-events';
import { LinearProgress, Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { createStyles } from '@material-ui/core/styles';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AppAction } from '../actions/actionCreators';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import { CallWithContact } from '../calls/calls.reducer';
import { CallTypeIcon } from '../components/CallTypeIcon';
import { formatPhoneNumber } from '../phone/phone-utils';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsCategory, defineTrackingEvents, AnalyticsAction } from '../analytics-new/analytics.models';
import { Typography } from '../theme/Typography.component';
import makeStyles from '@material-ui/styles/makeStyles';
import { IconButton, Button, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { CallerIdComponent } from '../calls/CallerId.component';
import { ContactMatchType } from '../calls/calls.selector';
import { ScreenPopCallActions } from '../calls/ScreenPopCallActions.component';
import { Integrations, MessageVariant } from '../models';
import { SoftphoneContainer } from '../softphone/SoftphoneContainer';
import { SoftphoneCallControl } from '../softphone/SoftphoneCallControl';
import { useDispatch } from 'react-redux';
import { deleteMessage, showMessage } from './message.action';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { dismissService } from '../dismissService';

export const definedMessages = defineMessages({
  CALL_SNACKBAR_SHOW_MORE: {
    id: 'Call.Snackbar.ActiveCall.ShowMore',
    defaultMessage: 'Show more',
  },
  CHECKING_MATCHING_CONTACTS: {
    id: 'Call.Snackbar.ActiveCall.ContactMatchingInProgress',
    defaultMessage: 'Searching for {phoneNumber}',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning',
    defaultMessage:
      'Future calls will be associated with this contact. You can change this in the menu next to your contact’s name.',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING_DISMISS: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning.Dismiss',
    defaultMessage: 'Thanks, got it',
  },
  DIALOG_AUTO_SINGLE_MATCH_WARNING_DONT_REMIND_ME: {
    id: 'Call.Snackbar.Dialog.MultipleMatch.Warning.DontRemindMe',
    defaultMessage: 'Don’t remind me again',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    screenPop: {
      backgroundColor: tokens.uiBackground,
      margin: theme.spacing(4),
      maxWidth: 340,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      flex: 1,
      position: 'relative',
    },
    icon: {
      borderRadius: 8,
      '--goto-icon-size': '24px',
      padding: 4,
      color: 'white',
      marginTop: 3,
      marginRight: theme.spacing(3),
    },
    iconWrapper: {
      fontSize: '28px',
      paddingTop: '13px',
      paddingRight: '10px',
    },
    closeButton: {
      position: 'absolute',
      top: 6,
      right: 6,
    },
    showMoreLink: {
      fontWeight: 'bold',
    },
    screenPopHeader: (props: { isContactMatchingInProgress: boolean }) => ({
      borderBottom: props.isContactMatchingInProgress ? '' : `1px solid ${tokens.border}`,
    }),
    screenPopBody: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 122px)',
    },
  }),
);

const trackingEvents = defineTrackingEvents({
  INCOMING_CALL_SHOW_MORE_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Incoming call show more | button',
  },
  INCOMING_CALL_CLOSE_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Incoming call close | button',
  },
  DISMISS_AUTO_SINGLE_MATCH_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Auto single match thanks | button',
  },
  DONT_REMIND_ME_AUTO_SINGLE_MATCH_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Don’t remind me again | button',
  },
});

export interface CallInformationSnackBarMessageOwnProps {
  callId: string;
  onClose: (...args: any[]) => any;
}

export interface CallInformationSnackBarMessageStateProps {
  call: CallWithContact;
  contactDisplayFromCall: string;
  isContactMatchingInProgress: boolean;
  matchType: ContactMatchType;
  currentIntegration: Integrations;
}

export interface CallInformationSnackBarMessageDispatchProps {
  updateActiveCall: (call: Call) => AppAction;
  onClose: (...args: any[]) => any;
}

export const ScreenPopComponent: React.FC<
  CallInformationSnackBarMessageOwnProps &
    CallInformationSnackBarMessageStateProps &
    CallInformationSnackBarMessageDispatchProps
> = ({
  onClose,
  call,
  contactDisplayFromCall,
  isContactMatchingInProgress,
  currentIntegration,
  updateActiveCall,
  matchType,
}) => {
  const classes = useStyles({ isContactMatchingInProgress });
  const dispatch = useDispatch();

  function switchActiveCall() {
    newTracker.trackAnalyticsEvent(trackingEvents.INCOMING_CALL_SHOW_MORE_CLICKED);
    updateActiveCall(call);
    onClose();
    if (call.isAutoSingleMatch && !dismissService.isDismissed('auto_single_match_warning_msg')) {
      dispatch(
        showMessage({
          id: 'auto_single_match_warning_msg',
          type: MessageVariant.Info,
          message: definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING,
          params: {
            autoHide: true,
            dismissible: false,
            actions: (
              <Box display="flex" gridGap={8}>
                <TrackedButton
                  trackingEvent={trackingEvents.DISMISS_AUTO_SINGLE_MATCH_CLICKED}
                  variant="neutral-inverse"
                  size="small"
                  onClick={() => dispatch(deleteMessage('auto_single_match_warning_msg'))}
                >
                  <FormattedMessage {...definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING_DISMISS} />
                </TrackedButton>
                <TrackedButton
                  trackingEvent={trackingEvents.DONT_REMIND_ME_AUTO_SINGLE_MATCH_CLICKED}
                  variant="neutral-inverse"
                  size="small"
                  onClick={() => {
                    dispatch(deleteMessage('auto_single_match_warning_msg'));
                    const dismissDuration = 1000 * 60 * 60 * 24 * 365 * 5; // 5 years ¯\_(ツ)_/¯
                    dismissService.dismiss('auto_single_match_warning_msg', dismissDuration);
                  }}
                >
                  <FormattedMessage {...definedMessages.DIALOG_AUTO_SINGLE_MATCH_WARNING_DONT_REMIND_ME} />
                </TrackedButton>
              </Box>
            ),
          },
        }),
      );
    }
  }

  const contactInfo = (
    <>
      <Typography variant="body-medium" noWrap>
        {contactDisplayFromCall}
      </Typography>
    </>
  );

  function getContent() {
    if (isContactMatchingInProgress) {
      const messageValues = {
        phoneNumber: (
          <Typography variant="caption-default-02" tag="span">
            {formatPhoneNumber(getNumberOfTheOtherParty(call))}
          </Typography>
        ),
      };

      return (
        <>
          <Typography variant="caption-default-01">
            <FormattedMessage values={messageValues} {...definedMessages.CHECKING_MATCHING_CONTACTS} />
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} mr={2}>
              <LinearProgress />
            </Box>
            <Box>
              <IconButton
                size="xsmall"
                variant="secondary"
                data-cy="close-button"
                onClick={() => {
                  newTracker.trackAnalyticsEvent(trackingEvents.INCOMING_CALL_CLOSE_CLICKED);
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      );
    } else {
      return contactInfo;
    }
  }

  return (
    <Box data-cy="screenpop" className={classes.screenPop}>
      <Box>
        <Box
          pt={4}
          pr={4}
          pl={4}
          pb={2}
          display="flex"
          alignItems={isContactMatchingInProgress ? 'flexStart' : 'center'}
          justifyContent="center"
          className={classes.screenPopHeader}
        >
          <CallTypeIcon className={classes.icon} type={call.type} size="24px" />
          <Box flexGrow={1}>{getContent()}</Box>
          {isContactMatchingInProgress ? null : (
            <IconButton
              size="xsmall"
              variant="secondary"
              data-cy="close-button"
              onClick={() => {
                newTracker.trackAnalyticsEvent(trackingEvents.INCOMING_CALL_CLOSE_CLICKED);
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Box className={classes.screenPopBody} pt={2} pr={4} pl={4} pb={4}>
          {!isContactMatchingInProgress ? (
            <>
              <Box mb={4}>
                <CallerIdComponent call={call} typographyProps={{ color: 'text-02' }} />
              </Box>
              <SoftphoneContainer
                realtimeCall={call}
                render={(softphoneCall) => (
                  <Box mx={-4} px={4} mb={4} bgcolor={tokens.backgroundColorSecondary}>
                    <SoftphoneCallControl call={softphoneCall} />
                  </Box>
                )}
              />
              <ScreenPopCallActions
                matchType={matchType}
                currentIntegration={currentIntegration}
                call={call}
                switchActiveCall={() => {
                  updateActiveCall(call);
                  onClose();
                }}
                closeScreenPop={onClose}
              />
            </>
          ) : (
            <SoftphoneContainer
              realtimeCall={call}
              render={(softphoneCall) => (
                <Box mx={-4} px={4} mb={4} bgcolor={tokens.backgroundColorSecondary}>
                  <SoftphoneCallControl call={softphoneCall} />
                </Box>
              )}
            />
          )}
          <Button
            fullWidth
            data-cy="show-more"
            size="small"
            variant="outlined"
            onClick={() => switchActiveCall()}
            className={classes.showMoreLink}
          >
            <FormattedMessage {...definedMessages.CALL_SNACKBAR_SHOW_MORE} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
