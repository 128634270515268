import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { Integrations } from '../models';
import { PresenceActionType } from './presence.action';
import { isDesktopIntegrationSelector } from '../integrations/integrations.selector';
import { openGoToConnectApp } from '../utils';
import { clearPresenceOfflinePopupDismissal } from './presence.utils';
import { SettingsActionTypes } from '../constants';

addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, action) => {
  if (action.payload !== Integrations.MsTeams) {
    registerPresenceListeners();
  }
});

let registered = false;

function registerPresenceListeners() {
  if (registered) {
    return;
  }

  registered = true;

  addListener(PresenceActionType.OPEN_GTC)((state) => {
    const isDesktopIntegration = isDesktopIntegrationSelector(state);
    const features = isDesktopIntegration ? ['openInDefaultBrowser'] : [];
    openGoToConnectApp(features);
  });

  addListener(SettingsActionTypes.SELECT_LINE)(() => {
    clearPresenceOfflinePopupDismissal();
  });
}
