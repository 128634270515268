import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { RedtailCategory } from '../redtail.model';

export enum RedtailCategoryActionsType {
  CATEGORIES_REQUEST = 'redtail/categories/get/request',
  CATEGORIES_GET_SUCCESS = 'redtail/categories/get/success',
  CATEGORIES_GET_ERROR = 'redtail/categories/get/error',
}

export const getRedtailCategories = makeActionCreator(RedtailCategoryActionsType.CATEGORIES_REQUEST)();

interface GetRedtailCategoriesSuccessPayload {
  categories: RedtailCategory[];
}

export const getRedtailCategoriesSuccess = makeActionCreator(RedtailCategoryActionsType.CATEGORIES_GET_SUCCESS)<
  GetRedtailCategoriesSuccessPayload
>();

export const getRedtailCategoriesError = makeActionCreator(RedtailCategoryActionsType.CATEGORIES_GET_ERROR)<
  WithMessage
>();

export type RedtailCategoryActions =
  | ReturnType<typeof getRedtailCategories>
  | ReturnType<typeof getRedtailCategoriesSuccess>
  | ReturnType<typeof getRedtailCategoriesError>;
