import React, { useEffect, useState } from 'react';
import { Integrations } from '../models';
import { AccountSettings } from './AccountSettings.container';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SalesforceSettings } from '../salesforce/settings/SalesforceSettings.container';
import { defineMessages, FormattedMessage } from 'react-intl';
import { createStyles, Theme } from '@material-ui/core';
import { CoxSettings } from '../cox/settings/cox.settings.component';
import clsx from 'clsx';
import { Tab, Tabs } from '@getgo/chameleon-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { openSettingsPage } from './settings.action';
import { ZendeskSettings } from '../zendesk/settings/ZendeskSettings.component';

const definedMessages = defineMessages({
  ACCOUNT_TAB_LABEL: {
    id: 'SettingsContainer.AccountTab.Label',
    defaultMessage: 'Account',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWrapper: {
      paddingTop: theme.spacing(7),
    },
    fullHeightBox: {
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Box
      display={value === index ? 'flex' : 'none'}
      className={clsx(classes.tabWrapper, classes.fullHeightBox)}
      role="tabpanel"
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box className={classes.fullHeightBox} display="flex" p={0}>
        {children}
      </Box>
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export const SettingsComponent: React.FC = () => {
  const integration = useSelector(currentIntegrationSelector);
  const dispatch = useDispatch();
  const [tab, setTab] = useState<string>('AccountTab');

  useEffect(() => {
    dispatch(openSettingsPage());
  }, []);

  const handleChange = (_, newTab: string) => {
    setTab(newTab);
  };

  const hasIntegrationSettings = () => {
    switch (integration) {
      case Integrations.SalesforceClassic:
      case Integrations.SalesforceLightning:
      case Integrations.Cox:
      case Integrations.Zendesk:
        return true;
      default:
        return false;
    }
  };

  if (!hasIntegrationSettings()) {
    return <AccountSettings />;
  }

  interface IntegrationSettingsProps {
    name: string;
    label: React.ReactNode;
    tabContent: React.ReactNode;
  }

  const IntegrationSettings = (props: IntegrationSettingsProps) => (
    <>
      <Tabs value={tab} onChange={handleChange} variant="fullWidth">
        <Tab
          label={<FormattedMessage {...definedMessages.ACCOUNT_TAB_LABEL} />}
          value="AccountTab"
          {...a11yProps('AccountTab')}
        />
        <Tab label={props.label} value={`${props.name}Tab`} {...a11yProps(`${props.name}Tab`)} />
      </Tabs>

      <TabPanel value={tab} index="AccountTab">
        <AccountSettings />
      </TabPanel>
      <TabPanel value={tab} index={`${props.name}Tab`}>
        {props.tabContent}
      </TabPanel>
    </>
  );

  switch (integration) {
    case Integrations.Cox:
      return <IntegrationSettings name="Cox" label="Cox Automotive" tabContent={<CoxSettings />} />;
    case Integrations.Zendesk:
      return <IntegrationSettings name="Zendesk" label="Zendesk" tabContent={<ZendeskSettings />} />;
    case Integrations.SalesforceLightning:
    case Integrations.SalesforceClassic:
    default:
      return <IntegrationSettings name="Salesforce" label="Salesforce" tabContent={<SalesforceSettings />} />;
  }
};
