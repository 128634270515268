import {
  AppMuiPickersUtilsProvider,
  AppMuiPickersUtilsProviderStateProps,
} from './AppMuiPickersUtilsProvider.component';
import { connect } from 'react-redux';
import { selectedDateFnsLocaleSelector } from './settings/settings.selector';

const mapStateToProps = (state) => ({
  locale: selectedDateFnsLocaleSelector(state),
});

export default connect<AppMuiPickersUtilsProviderStateProps>(mapStateToProps)(AppMuiPickersUtilsProvider);
