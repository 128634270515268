import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StepsBubble } from './StepsBubble.component';
import { ErrorPage } from './ErrorPage.component';
import { RestartButton } from './RestartButton.component';
import { Typography } from '../theme/Typography.component';
import { useSelector } from 'react-redux';
import { jiveIdSelector } from '../authentication/authentication.selector';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  ERROR_PAGE_TITLE: {
    id: 'LinesLoading.Error.Title',
    defaultMessage: 'Your account is no longer connected to GoToConnect',
  },
  ERROR_PAGE_SUBTITLE: {
    id: 'LinesLoading.Error.Subtitle',
    defaultMessage: 'Please see possible reasons:',
  },
  ERROR_PAGE_INSTRUCTIONS_1: {
    id: 'LinesLoading.Error.Instructions.1',
    defaultMessage: "You're logged in as {jiveEmail}. If this account is incorrect please {signOut}.",
  },
  ERROR_PAGE_INSTRUCTIONS_1_SIGNOUT: {
    id: 'LinesLoading.Error.Instructions.1.Signout',
    defaultMessage: 'Sign out',
  },
  ERROR_PAGE_INSTRUCTIONS_2: {
    id: 'LinesLoading.Error.Instructions.2',
    defaultMessage: 'Your System Administrator has not added you to your corporate GoToConnect account.',
  },
  ERROR_PAGE_INSTRUCTIONS_3: {
    id: 'LinesLoading.Error.Instructions.3',
    defaultMessage: 'Your System Administrator entered your email address incorrectly.',
  },
  ERROR_PAGE_NONE_OF_THE_ABOVE: {
    id: 'LinesLoading.Error.NonOfTheAbove',
    defaultMessage: 'If none of the above is correct, you can restart the application.',
  },
});

const shortLineHeight = 1.3;
const useStyles = makeStyles({
  topMargin: {
    marginTop: '30px',
  },
  restartButton: {
    marginTop: '5px',
  },
  stepsBubble: {
    marginTop: '15px',
  },
  shortLineHeight: {
    lineHeight: shortLineHeight,
  },
});

export const LinesLoadingErrorPage: React.FC = () => {
  const jiveId = useSelector(jiveIdSelector);
  const classes = useStyles();
  return (
    <ErrorPage title={definedMessages.ERROR_PAGE_TITLE} message={definedMessages.ERROR_PAGE_SUBTITLE}>
      <StepsBubble className={classes.topMargin}>1</StepsBubble>
      <Typography color="text-02">
        <span className={classes.shortLineHeight}>
          <FormattedMessage
            {...definedMessages.ERROR_PAGE_INSTRUCTIONS_1}
            values={{
              jiveEmail: <strong>{jiveId}</strong>,
              signOut: (
                <Link onClick={() => getActionFacade().logout()}>
                  <FormattedMessage {...definedMessages.ERROR_PAGE_INSTRUCTIONS_1_SIGNOUT} />
                </Link>
              ),
            }}
          />
        </span>
      </Typography>
      <StepsBubble className={classes.stepsBubble}>2</StepsBubble>
      <Typography color="text-02">
        <span className={classes.shortLineHeight}>
          <FormattedMessage {...definedMessages.ERROR_PAGE_INSTRUCTIONS_2} />
        </span>
      </Typography>

      <StepsBubble className={classes.stepsBubble}>3</StepsBubble>
      <Typography color="text-02">
        <span className={classes.shortLineHeight}>
          <FormattedMessage {...definedMessages.ERROR_PAGE_INSTRUCTIONS_3} />
        </span>
      </Typography>
      <Box mt={4}>
        <Typography color="text-02">
          <b>
            <FormattedMessage {...definedMessages.ERROR_PAGE_NONE_OF_THE_ABOVE} />
          </b>
        </Typography>
      </Box>
      <RestartButton className={classes.restartButton} />
    </ErrorPage>
  );
};
