import { Reducer } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { AppState } from '../reducers';
import { DebugActions, DebugActionTypes } from './debug.actions';

export function withDebugReducer(rootReducer: Reducer<AppState, AppAction>) {
  const debugReducer: Reducer<AppState, DebugActions> = (state, action) => {
    if (!state) {
      return rootReducer(state, action);
    }

    switch (action.type) {
      case DebugActionTypes.INVALIDATE_TOKEN:
        return {
          ...state,
          authentication: {
            ...state.authentication,
            result: {
              ...state.authentication.result,
              token: 'invalid_token',
            },
          },
        };
      case DebugActionTypes.EXPIRE_TOKEN:
        return {
          ...state,
          authentication: {
            ...state.authentication,
            result: {
              ...state.authentication.result,
              expiration: 0,
            },
          },
        };
      case DebugActionTypes.SET_TOKEN_VALIDITY:
        const { tokenExpirationInDays } = action.payload;
        return {
          ...state,
          authentication: {
            ...state.authentication,
            result: {
              ...state.authentication.result,
              expiration: Date.now() + tokenExpirationInDays * 24 * 60 * 60 * 1000,
            },
          },
        };
      case DebugActionTypes.ADD_FAKE_CALL:
        const { call } = action.payload;

        return {
          ...state,
          calls: {
            ...state.calls,
            activeCalls: {
              allIds: state.calls.activeCalls.allIds.includes('test')
                ? state.calls.activeCalls.allIds
                : [...state.calls.activeCalls.allIds, 'test'],
              byId: {
                ...state.calls.activeCalls.byId,
                test: call,
              },
            },
          },
        };
      case DebugActionTypes.STOP_CONTACT_MATCHING:
        const { callId } = action.payload;
        return {
          ...state,
          calls: {
            ...state.calls,
            contacts: {
              ...state.calls.contacts,
              byId: {
                ...state.calls.contacts.byId,
                [callId]: {
                  ...state.calls.contacts.byId[callId],
                  isLoading: false,
                },
              },
            },
          },
        };
      case DebugActionTypes.REMOVE_FAKE_CALL_LOGS:
        const callIds = state.callHistory.allIds.filter((c) => !c.startsWith('fake-call-'));
        return {
          ...state,
          callHistory: {
            ...state.callHistory,
            allIds: callIds,
            byId: Object.fromEntries(Object.entries(state.callHistory.byId).filter(([id]) => callIds.includes(id))),
          },
        };
      case DebugActionTypes.CLEAR_SELECTED_LINE: {
        return {
          ...state,
          settings: {
            ...state.settings,
            selectedLine: '',
          },
        };
      }
      case DebugActionTypes.RESET_HAS_SEEN_SOFTPHONE_SETTINGS: {
        return {
          ...state,
          settings: {
            ...state.settings,
            hasSeenSoftphoneSettings: undefined,
          },
        };
      }
    }

    return rootReducer(state, action);
  };
  return debugReducer;
}
