import React, { FC, useEffect, useState } from 'react';
import { CallWithContact } from '../calls/calls.reducer';
import { RadioGroup } from '@getgo/chameleon-web/react';
import { ZendeskTicketSearchResultItem } from './zendesk.models';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { Typography } from '../theme/Typography.component';
import { useDispatch, useSelector } from 'react-redux';
import { ticketsLoadingSelector, ticketsSelector } from './tickets/ticket.selector';
import LinearProgress from '@material-ui/core/LinearProgress';
import { searchTicketClear } from './tickets/tickets.action';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ZendeskActionFacade } from './zendesk.actionfacade';
import { TicketsSelect } from './tickets/TicketSelect.component';
import { ExternalLinkIcon } from '@getgo/chameleon-icons/react';
import { AppState } from '../reducers';
import { getEntityInCallSelector } from '../calls/calls.selector';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TrackedRadioButton } from '../analytics-new/TrackedRadioButton.component';
import { TrackedCheckbox } from '../analytics-new/TrackedCheckbox.component';
import { TrackedButton } from '../analytics-new/TrackedButton.component';

const definedMessages = defineMessages({
  CALL_LOG_FORM_LOADING_MESSAGE: {
    id: 'Zendesk.Tickets.Loading.Message',
    defaultMessage: 'Searching in Zendesk for existing tickets...',
  },
  TICKET: {
    id: 'Zendesk.Ticket.Form.Title',
    defaultMessage: 'Ticket',
  },
  CREATE_NEW_TICKET: {
    id: 'Zendesk.Ticket.Form.RadioGroup.NewTicket.Label',
    defaultMessage: 'Create a new ticket',
  },
  UPDATE_TICKET: {
    id: 'Zendesk.Ticket.Form.RadioGroup.UpdateTicket.Label',
    defaultMessage: 'Update an existing ticket',
  },
  ADD_CALL_DETAILS_LABEL: {
    id: 'Zendesk.Ticket.Form.CallDetails.Checkbox.Label',
    defaultMessage: 'Add call details as note',
  },
  ADD_CALL_DETAILS_CAPTION: {
    id: 'Zendesk.Ticket.Form.CallDetails.Checkbox.Caption',
    defaultMessage: 'All call details will be added right after the call. ',
  },
  CREATE_AND_OPEN_TICKET: {
    id: 'Zendesk.Ticket.Form.CreateAndOpen.Button.Label',
    defaultMessage: 'Create and open ticket',
  },
  OPEN_TICKET: {
    id: 'Zendesk.Ticket.Form.Open.Button.Label',
    defaultMessage: 'Open ticket',
  },
});

const trackingEvents = defineTrackingEvents({
  CREATE_NEW_TICKET_SELECTED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Create new ticket | toggle | ON',
  },
  UPDATE_EXISTING_TICKET_SELECTED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Update an existing ticket | toggle | ON',
  },
  ADD_CALL_DETAILS_ON: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Add call details | toggle | ON',
  },
  ADD_CALL_DETAILS_OFF: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Add call details | toggle | OFF',
  },
  OPEN_TICKET_CLICKED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemClicked,
    label: 'Open ticket | button',
  },
});

const useStyles = makeStyles((theme) => ({
  addNoteRow: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export const ZendeskContextSection: FC<{ call: CallWithContact }> = ({ call }) => {
  const tickets = useSelector(ticketsSelector);
  const ticketLoadingState = useSelector(ticketsLoadingSelector);
  const entityInCall = useSelector((state: AppState) => getEntityInCallSelector(state, call.id));
  const dispatch = useDispatch();
  const styles = useStyles();

  const [shouldAddCallDetails, setShouldAddCallDetails] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState<ZendeskTicketSearchResultItem | undefined>();
  const [selectedContextOption, setSelectedContextOption] = useState<'CreateNew' | 'UpdateExisting'>(
    tickets.length ? 'UpdateExisting' : 'CreateNew',
  );
  const [areTicketsFirstTimeLoaded, setAreTicketsFirstTimeLoaded] = useState(false);

  useEffect(() => {
    dispatch(searchTicketClear);
    void getActionFacade<ZendeskActionFacade>().searchTickets(entityInCall?.id);
  }, [dispatch, entityInCall]);

  useEffect(() => {
    if (ticketLoadingState === 'loaded') {
      setAreTicketsFirstTimeLoaded(true);
    }
  }, [ticketLoadingState]);

  const LoadingMessage = () => {
    return (
      <>
        <Box my={1}>
          <LinearProgress />
        </Box>
        <Typography color="text-info" style={{ textAlign: 'center' }}>
          <FormattedMessage {...definedMessages.CALL_LOG_FORM_LOADING_MESSAGE} />
        </Typography>
      </>
    );
  };

  if (call.entitySelected && !areTicketsFirstTimeLoaded) {
    return <LoadingMessage />;
  }

  return (
    <Box>
      <Box mb={4}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.TICKET} />
        </Typography>
      </Box>

      <Box mb={4}>
        <RadioGroup
          value={selectedContextOption}
          onChange={({ currentTarget: { value } }) => setSelectedContextOption(value as any)}
        >
          <TrackedRadioButton value="CreateNew" onTrackingEvent={trackingEvents.CREATE_NEW_TICKET_SELECTED}>
            <FormattedMessage {...definedMessages.CREATE_NEW_TICKET} />
          </TrackedRadioButton>
          <TrackedRadioButton value="UpdateExisting" onTrackingEvent={trackingEvents.UPDATE_EXISTING_TICKET_SELECTED}>
            <FormattedMessage {...definedMessages.UPDATE_TICKET} />
          </TrackedRadioButton>
        </RadioGroup>
      </Box>
      {selectedContextOption === 'UpdateExisting' ? (
        <Box mb={4}>
          <TicketsSelect
            selectedTicket={selectedTicket}
            onTicketChange={(ticket) => {
              setSelectedTicket(ticket);
            }}
            requesterId={entityInCall?.id}
          />
        </Box>
      ) : null}

      <Box my={4}>
        <Divider variant="middle" />
      </Box>

      <Box className={styles.addNoteRow}>
        <Box>
          <TrackedCheckbox
            checked={shouldAddCallDetails}
            onTrackingEvent={trackingEvents.ADD_CALL_DETAILS_ON}
            offTrackingEvent={trackingEvents.ADD_CALL_DETAILS_OFF}
            onClick={() => setShouldAddCallDetails(!shouldAddCallDetails)}
          />
        </Box>
        <Box>
          <Typography>
            <FormattedMessage {...definedMessages.ADD_CALL_DETAILS_LABEL} />
          </Typography>
          {!call.endTime && (
            <Typography variant="caption-default-01">
              <FormattedMessage {...definedMessages.ADD_CALL_DETAILS_CAPTION} />
            </Typography>
          )}
        </Box>
      </Box>

      <Box mb={4}>
        <TrackedButton
          trailingIcon={<ExternalLinkIcon />}
          fullwidth
          onClick={() =>
            void getActionFacade<ZendeskActionFacade>().upsertAndOpenTicket(
              call.id,
              shouldAddCallDetails,
              selectedContextOption === 'CreateNew' ? undefined : selectedTicket?.id.toString(),
            )
          }
          trackingEvent={trackingEvents.OPEN_TICKET_CLICKED}
          disabled={selectedContextOption === 'UpdateExisting' && !selectedTicket}
        >
          {selectedContextOption === 'CreateNew' ? (
            <FormattedMessage {...definedMessages.CREATE_AND_OPEN_TICKET} />
          ) : (
            <FormattedMessage {...definedMessages.OPEN_TICKET} />
          )}
        </TrackedButton>
      </Box>
    </Box>
  );
};
