import { combineReducers } from 'redux';
import { VoicemailsActionsType } from '../constants';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';
import voicemailPlayerReducer from './voicemailPlayer.reducer';

const voicemailsReducer = combineReducers({
  player: voicemailPlayerReducer,
  call: combineReducers({
    isLoading: loadingReducer(VoicemailsActionsType.CALL),
    hasError: errorReducer(VoicemailsActionsType.CALL),
  }),
  delete: combineReducers({
    isLoading: loadingReducer(VoicemailsActionsType.DELETE),
  }),
  toggleReadState: combineReducers({
    isLoading: loadingReducer(VoicemailsActionsType.TOGGLE_READ_STATE),
  }),
});

export default voicemailsReducer;
