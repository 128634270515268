import { combineReducers } from 'redux';
import loadingReducer from '../../reducers/loading';
import { InitActionsType } from './init.model';

const init = combineReducers({
  selectedLine: combineReducers({
    isLoading: loadingReducer(InitActionsType.SELECTED_LINE),
  }),
});

export default init;
