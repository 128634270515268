import { Reducer } from 'redux';
import { PresenceActions, PresenceActionType } from './presence.action';
import { PresenceAppearance } from './presence.models';

interface PresenceState {
  appearance?: PresenceAppearance;
}

const PRESENCE_INITIAL_STATE: PresenceState = {
  appearance: undefined,
};

export const presenceReducer: Reducer<PresenceState, PresenceActions> = (state = PRESENCE_INITIAL_STATE, action) => {
  switch (action.type) {
    case PresenceActionType.SET: {
      return { ...state, appearance: action.payload.appearance };
    }
    default: {
      return state;
    }
  }
};
