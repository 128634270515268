import { normalize, schema } from 'normalizr';
import { combineReducers } from 'redux';
import { ContactActionsType, ContactsActions } from '../actions/contacts';
import { ENTITY } from '../constants';
import { Entity, IndexedById } from '../models';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';
import messageReducer from '../reducers/message';
import { EntitiesActions, EntitiesActionsType } from './entities.action';
import { SalesforceActionType, SalesforceActions } from '../salesforce/salesforce.actions';

const entity = new schema.Entity('entity');

export interface EntitiesState {
  query: string;
  byId: IndexedById<Entity>;
  allIds: string[];
  entities: Entity[] | undefined;
  createEntityIsLoading: boolean;
}

export const ENTITIES_INITIAL_STATE: EntitiesState = {
  createEntityIsLoading: false,
  query: '',
  byId: {},
  allIds: [],
  entities: undefined,
};

const entitiesReducer = (
  state: EntitiesState = ENTITIES_INITIAL_STATE,
  action: EntitiesActions | ContactsActions | SalesforceActions,
): EntitiesState => {
  switch (action.type) {
    case EntitiesActionsType.ENTITY_SEARCH: {
      return {
        ...state,
        query: action.payload.query,
      };
    }
    case EntitiesActionsType.ENTITY_CLEAR: {
      return ENTITIES_INITIAL_STATE;
    }
    case EntitiesActionsType.ENTITY_SEARCH_SUCCESS: {
      const { results } = action.payload;
      const normalized = normalize(results, [entity]);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...normalized.entities.entity,
        },
        allIds: results.map((result) => result.id),
        entities: results,
      };
    }
    case EntitiesActionsType.ENTITY_SEARCH_ERROR: {
      return {
        ...ENTITIES_INITIAL_STATE,
        query: state.query,
      };
    }
    case EntitiesActionsType.CREATE_ENTITY: {
      return {
        ...state,
        createEntityIsLoading: true,
      };
    }
    case EntitiesActionsType.CREATE_CONTACT_SUCCESS: {
      const normalized = normalize(action.payload.entity, entity);
      const entities = state.entities || [];
      return {
        ...state,
        createEntityIsLoading: false,
        entities: [...entities, action.payload.entity],
        allIds: [...state.allIds, action.payload.contactId],
        byId: {
          ...state.byId,
          ...normalized.entities.entity,
        },
      };
    }
    case EntitiesActionsType.CREATE_ENTITY_SUCCESS: {
      return {
        ...state,
        createEntityIsLoading: false,
      };
    }
    case SalesforceActionType.CONTACT_CREATE_FORM_OPENED: {
      return {
        ...state,
        createEntityIsLoading: false,
      };
    }
    case ContactActionsType.CREATE_CONTACT_ERROR: {
      return {
        ...state,
        createEntityIsLoading: false,
      };
    }
    default:
      return state;
  }
};

const entities = combineReducers({
  isLoading: loadingReducer(ENTITY),
  hasError: errorReducer(ENTITY),
  errorMessage: messageReducer(EntitiesActionsType.ENTITY_SEARCH_NOT_AUTHORIZED, EntitiesActionsType.ENTITY_SEARCH),
  result: entitiesReducer,
  phoneNumbers: combineReducers({
    isLoading: loadingReducer(EntitiesActionsType.ENTITY_LOAD_PHONES),
    errorMessage: messageReducer(
      EntitiesActionsType.ENTITY_LOAD_PHONES_NOT_AUTHORIZED,
      EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST,
    ),
  }),
});

export default entities;
