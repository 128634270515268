import { makeActionCreator } from '../actions/actionCreators';
import { ClickToCallActionTypes } from '../constants';
import './click-to-call.listeners';

export interface ClickToCallRequestPayload {
  phoneNumber: string;
  contactName?: string;
}

export const clickToCallRequest = makeActionCreator(ClickToCallActionTypes.CALL_REQUEST)<ClickToCallRequestPayload>();
export const clickToCallSuccess = makeActionCreator(ClickToCallActionTypes.CALL_SUCCESS)();
export const clickToCallError = makeActionCreator(ClickToCallActionTypes.CALL_ERROR)<any>();

export type ClickToCallActions =
  | ReturnType<typeof clickToCallRequest>
  | ReturnType<typeof clickToCallSuccess>
  | ReturnType<typeof clickToCallError>;
