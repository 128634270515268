import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { getActiveRoute } from '../browserHistory';
import { ApplicationRoute, InAppNotificationVariantType } from '../constants';
import { InAppNotificationMessage } from '../models';
import { InAppNotification } from '../inAppNotification/InAppNotification.component';
import Push from 'push.js';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { AlertInactiveIcon } from '@getgo/chameleon-icons/react';
import { Box, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles } from '@material-ui/styles';
import { openNotificationPermissionsPage } from './notificationPermissions.service';
import { dismissService } from '../dismissService';

const PERMISSION_REQUEST_STORAGE_KEY = 'notification-permission-last-reminder';

const trackingEvents = defineTrackingEvents({
  SHOW_NOTIFICATIONS_DEFAULT: {
    category: AnalyticsCategory.Information,
    label: 'Notifications request warning',
    action: AnalyticsAction.PopupShown,
  },
  SHOW_NOTIFICATIONS_DISABLED: {
    category: AnalyticsCategory.Information,
    label: 'Notifications disabled warning',
    action: AnalyticsAction.PopupShown,
  },
  ENABLE_NOTIFICATIONS_CLICKED: {
    category: AnalyticsCategory.Information,
    label: 'Notifications enable | button',
    action: AnalyticsAction.ItemClicked,
  },
  DISMISS_NOTIFICATIONS_CLICKED: {
    category: AnalyticsCategory.Information,
    label: 'Notifications dismiss | button',
    action: AnalyticsAction.ItemClicked,
  },
});

export const definedMessages = defineMessages({
  MESSAGE: {
    id: 'Notification.Permission.Snackbar.Message',
    defaultMessage: 'To receive notifications about calls, enable web notifications.',
  },
  UPDATE_BUTTONTEXT: {
    id: 'Notification.Permission.Snackbar.UpdateButton',
    defaultMessage: 'Update settings',
  },
  NOTNOW_BUTTONTEXT: {
    id: 'Notification.Permission.Snackbar.NotnowButton',
    defaultMessage: 'Not now',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }),
);

export const NotificationPermissionsSnackbarComponent: React.FC = () => {
  const classes = useStyles();

  const [pushPermissions, setPushPermissions] = useState(Push.Permission.get());
  const hasPushPermissions = pushPermissions === Push.Permission.GRANTED;
  const isOnNotificationPermissionPage = getActiveRoute()?.route === ApplicationRoute.NOTIFICATION_PERMISSION_ROUTE;
  const [isDismissed, setIsDismissed] = useState(dismissService.isDismissed(PERMISSION_REQUEST_STORAGE_KEY));
  const shouldDisplayNotificationPermissionSnackbar =
    !isDismissed && !hasPushPermissions && !isOnNotificationPermissionPage;

  useEffect(() => {
    const updatePermissions = () => {
      setPushPermissions(Push.Permission.get());
    };

    window.addEventListener('focus', updatePermissions);

    return () => {
      window.removeEventListener('focus', updatePermissions);
    };
  }, []);

  useEffect(() => {
    if (shouldDisplayNotificationPermissionSnackbar) {
      const trackingEvent =
        pushPermissions === Push.Permission.DEFAULT
          ? trackingEvents.SHOW_NOTIFICATIONS_DEFAULT
          : trackingEvents.SHOW_NOTIFICATIONS_DISABLED;
      newTracker.trackAnalyticsEvent(trackingEvent);
    }
  }, [pushPermissions, shouldDisplayNotificationPermissionSnackbar]);

  if (!shouldDisplayNotificationPermissionSnackbar) {
    return null;
  }

  const notificationMessage: InAppNotificationMessage = {
    id: 'definedMessages.NOTIFICATION_PERMISSION_DEFAULT_MESSAGE', // this id is used in a repeater only
    message: definedMessages.MESSAGE,
    params: {
      autoHide: false,
      dismissible: false,
      icon: <AlertInactiveIcon />,
      actions: (
        <Box display="flex" className={classes.buttonContainer} justifyContent="space-between" flex={2}>
          <TrackedButton
            trackingEvent={trackingEvents.DISMISS_NOTIFICATIONS_CLICKED}
            variant="neutral-inverse"
            size="small"
            onClick={() => {
              dismissService.dismiss(PERMISSION_REQUEST_STORAGE_KEY);
              setIsDismissed(true);
            }}
          >
            <FormattedMessage {...definedMessages.NOTNOW_BUTTONTEXT} />
          </TrackedButton>
          <TrackedButton
            trackingEvent={trackingEvents.ENABLE_NOTIFICATIONS_CLICKED}
            variant="neutral-inverse"
            size="small"
            onClick={() => {
              openNotificationPermissionsPage();
            }}
          >
            <FormattedMessage {...definedMessages.UPDATE_BUTTONTEXT} />
          </TrackedButton>
        </Box>
      ),
    },
    type:
      pushPermissions === Push.Permission.DEFAULT
        ? InAppNotificationVariantType.NEUTRAL
        : InAppNotificationVariantType.ERROR,
  };

  return <InAppNotification message={notificationMessage} close={() => undefined} />;
};
