import { GetMoreOpportunitiesRequestMeta } from './opportunity.actions';
import { SalesforceOpportunity } from '../../../../salesforce-shared/salesforce-service.models';

export enum GetOpportunitiesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export enum GetMoreOpportunitiesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface GetOpportunitiesState {
  requestState: GetOpportunitiesRequestState;
  moreRequestState: GetMoreOpportunitiesRequestState;

  requestMeta?: GetMoreOpportunitiesRequestMeta;
  opportunities: SalesforceOpportunity[];
  opportunitiesCount?: number;
}
