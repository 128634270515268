import Push from 'push.js';
import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsCategory, defineTrackingEvents, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Button, ExtendedTheme } from '@getgo/chameleon-material-ui';
import { BellInactiveIcon, LockedActiveIcon, MicrophoneActiveIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '../theme/Typography.component';
import { GotoIntegrationsLogo } from '../components/GotoIntegrationsLogo.component';
import { tokens } from '@getgo/chameleon-core';
import { isNotMicrophonePermissionDenied } from './microphonePermission.service';

const definedMessages = defineMessages({
  MICROPHONE_PERMISSION_TITLE: {
    id: 'Microphone.Permission.Page.Title',
    defaultMessage: 'Microphone permission needed',
  },
  MICROPHONE_PERMISSION_DESCRIPTION: {
    id: 'Microphone.Permission.Page.Description',
    defaultMessage:
      'To manage calls with GoTo, give microphone permission for this site. Click the button below to give microphone permission.',
  },
  MICROPHONE_PERMISSION_BUTTON_LABEL: {
    id: 'Microphone.Permission.Button.Label',
    defaultMessage: 'Give microphone permission',
  },
  MICROPHONE_PERMISSION_DISABLED_DESCRIPTION: {
    id: 'Microphone.Permission.Page.Disabled.Description',
    defaultMessage:
      'To receive calls with GoTo, enable microphone permissions on this site. Follow the steps below or change your browser settings.',
  },
  MICROPHONE_PERMISSION_DISABLED_STEPS_1: {
    id: 'Microphone.Permission.Page.Disabled.Steps.1',
    defaultMessage: '1. Click on the lock icon before the URL.',
  },
  MICROPHONE_PERMISSION_DISABLED_STEPS_2: {
    id: 'Microphone.Permission.Page.Disabled.Steps.2',
    defaultMessage: '2. Open the microphone settings.',
  },
  MICROPHONE_PERMISSION_DISABLED_STEPS_3: {
    id: 'Microphone.Permission.Page.Disabled.Steps.3',
    defaultMessage: '3. Allow microphone access.',
  },
});

const trackingEvents = defineTrackingEvents({
  SET_PERMISSION_CLICKED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemClicked,
    label: 'Set microphone permissions | button',
  },
  PERMISSIONS_ENABLED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemChanged,
    label: 'Microphone permissions | enabled',
  },
  PERMISSIONS_DISABLED: {
    category: AnalyticsCategory.Information,
    action: AnalyticsAction.ItemChanged,
    label: 'Microphone permissions | disabled',
  },
});

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    rightContainer: {
      backgroundColor: tokens.neutral050,
    },
    video: {
      maxWidth: '80%',
    },
    buttonIcon: {
      width: 20,
      height: 20,
    },
    steps: {
      '& > p': {
        marginBottom: theme.spacing(2),
      },
    },
    stepsIcon: {
      verticalAlign: 'middle',
      display: 'inline-flex',
    },
  }),
);

const helperAnimation = require('../assets/mic-permissions.webm');

export const MicrophonePermissions: React.FC = () => {
  const classes = useStyles();

  const [isMicrophoneEnabled, setMicrophoneEnabled] = useState(false);
  const [isRequestingPermission, setIsRequestingPermission] = useState(false);

  const queryPermissions = useCallback(async () => {
    const hasMicrophone = await isNotMicrophonePermissionDenied();
    setMicrophoneEnabled(hasMicrophone);
  }, [setMicrophoneEnabled]);

  useEffect(() => {
    void queryPermissions();
  }, [queryPermissions]);

  if (isMicrophoneEnabled) {
    window.close();
    return null;
  }

  const onGranted = () => {
    setIsRequestingPermission(false);
    newTracker.trackAnalyticsEvent(trackingEvents.PERMISSIONS_ENABLED);
    setMicrophoneEnabled(true);
    window.close();
  };

  const onDenied = () => {
    setIsRequestingPermission(false);
    newTracker.trackAnalyticsEvent(trackingEvents.PERMISSIONS_DISABLED);
    setMicrophoneEnabled(false);
  };

  const allowMicrophone = () => {
    newTracker.trackAnalyticsEvent(trackingEvents.SET_PERMISSION_CLICKED);

    setIsRequestingPermission(true);
    Push.Permission.request(onGranted, onDenied);
  };

  const renderPermissionRequest = () => {
    return (
      <>
        <Typography variant="heading-small">
          <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_TITLE} />
        </Typography>
        <Box mt={4} mb={4} maxWidth="50%">
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_DESCRIPTION} />
          </Typography>
        </Box>
        <Button variant="contained" color="primary" disabled={isRequestingPermission} onClick={() => allowMicrophone()}>
          <Box mr={1} flex>
            <BellInactiveIcon className={classes.buttonIcon} />
          </Box>
          <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_BUTTON_LABEL} />
        </Button>
      </>
    );
  };

  const renderPermissionDenied = () => {
    return (
      <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <Typography variant="heading-small">
          <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_TITLE} />
        </Typography>
        <Box mt={4} mb={4} maxWidth="50%">
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_DISABLED_DESCRIPTION} />
          </Typography>
        </Box>
        <Box className={classes.steps}>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage
              {...definedMessages.MICROPHONE_PERMISSION_DISABLED_STEPS_1}
              values={{
                icon: (
                  <Box display="inline-block">
                    <LockedActiveIcon size="16px" />
                  </Box>
                ),
              }}
            />
          </Typography>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_DISABLED_STEPS_2} />
          </Typography>
          <Typography variant="body-small" color="text-02">
            <FormattedMessage {...definedMessages.MICROPHONE_PERMISSION_DISABLED_STEPS_3} />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" width="100%" height="100%">
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={8}>
          <GotoIntegrationsLogo width={215} />
        </Box>
        <Box mb={2}>
          <MicrophoneActiveIcon size="64px" color="var(--goto-icon-04)" />
        </Box>
        {isMicrophoneEnabled ? renderPermissionRequest() : renderPermissionDenied()}
      </Box>
      <Box width="100%" display="flex" justifyContent="center" className={classes.rightContainer}>
        <video src={helperAnimation} autoPlay loop muted className={classes.video} />
      </Box>
    </Box>
  );
};
