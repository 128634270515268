import { makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonAction } from '../search/SearchResults.component';
import classNames from 'classnames';
import { TrackedRoundButton } from '../analytics-new/TrackedRoundButton.component';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { Tooltip as ChamTooltip } from '@getgo/chameleon-material-ui';

const Tooltip = withStyles({
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
})(ChamTooltip);

interface ActionButtonProps {
  action: ButtonAction;
  actionClass: string;
  trackingEvent: TrackingEvent;
}

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 5px',
    '&:first-child': {
      margin: '0 5px 0 0',
    },
    '&:last-child': {
      margin: '0 0 0 5px',
    },
  },
}));

export const ActionButton: React.FC<ActionButtonProps> = ({ action, actionClass, trackingEvent }) => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      placement="bottom"
      title={<FormattedMessage {...action.description} />}
      disableHoverListener={!action.description}
      disableTouchListener={!action.description}
      disableFocusListener={!action.description}
    >
      <div className={classNames(classes.root, actionClass)}>
        <TrackedRoundButton size="small" variant="primary" onClick={action.action} trackingEvent={trackingEvent}>
          {action.icon}
        </TrackedRoundButton>
      </div>
    </Tooltip>
  );
};
