export enum RepairOrderStatus {
  ServiceDepartment = 'A',
  BodyShop = 'B',
  WP = 'C',
  FirstVisit = 'D',
  BookedFromTheWeb = 'E',
  GLError = 'L',
  WaitingForSpecialOrderPart = 'S',
  Open = '1',
  InProcess = '2',
  ApprovedByPartsDepartment = '3',
  Cashier = '4',
  CashierDelayedClose = '5',
  PreInvoice = '6',
  OdomRequired = '7',
  WaitingForParts = '8',
  PartsApprovalRequired = '9',
}

export interface OpenRepairOrder {
  repairOrderNumber: string;
  status: RepairOrderStatus;
  lastModified: Optional<Date>;
  promisedDateTime: Optional<Date>;
  totalEstimate: number;
  serviceWriterId: string;
  details: RepairOrderDetails[];
}

export interface RepairOrderDetails {
  lineType: RepairOrderDetailsLineType;
  manufacturer?: string;
  partNumber?: string;
  price?: number;
  dateTimeLineCompleted?: Date;
  transDate?: Date;
}

export enum RepairOrderDetailsLineType {
  Labor = 'A',
  Estimate = 'E',
  ActualLaborAmount = 'F',
  LaborAmount = 'L',
  PaintAndMaterials = 'M',
  Sublet = 'N',
  Parts = 'P',
  Coupon = 'Q',
  Return = 'R',
  HazardousMaterials = 'W',
  Feedback = 'X',
  CustomerNotification = 'Y',
  Comment = 'Z',
}

export type ClosedRepairOrderDetailsState = Record<
  string,
  {
    requestState: ClosedRepairOrderDetailsRequestState;
    closedRepairOrderDetail?: ClosedRepairOrderDetails;
  }
>;

export interface ClosedRepairOrder {
  repairOrderNumber: string;
  closeDate: Optional<Date>;
  serviceWriterId: string;
}

export interface ClosedRepairOrderDetails {
  repairOrderNumber: string;
  priceCharged: number;
}

export enum OpenRepairOrdersRequestState {
  LOADING,
  LOADED,
  FAILED,
}

export enum ClosedRepairOrdersRequestState {
  LOADING,
  LOADED,
  FAILED,
}

export enum ClosedRepairOrderDetailsRequestState {
  LOADING,
  LOADED,
  FAILED,
}

export interface ServiceWriter {
  id: string;
  name: string;
}

export enum ServiceWritersRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}
