import { AppState } from '../../reducers/index';
import { createSelector } from 'reselect';

const serviceAppointmentsSelector = (state: AppState) => state.cox.serviceAppointments;

export const serviceAppointmentLoadRequestStateByVINSelector = (VIN: string) =>
  createSelector(
    serviceAppointmentsSelector,
    (serviceAppointments) => serviceAppointments.byVIN[VIN]?.loadRequestState,
  );

export const serviceAppointmentByVINSelector = (VIN: string) =>
  createSelector(
    serviceAppointmentsSelector,
    (serviceAppointments) => serviceAppointments.byVIN[VIN]?.serviceAppointments,
  );
