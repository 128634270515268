import { format } from 'date-fns';

export const arrayify = <T>(element: T | T[]): T[] => (Array.isArray(element) ? element : [element]);

export const sanitizeVIN = (VIN: string) => VIN?.replace(/[^a-zA-Z0-9]/g, '');

export const toDateStringOrDefault = (input: Date | undefined, locale: Locale) =>
  input ? format(input, 'P', { locale }) : 'n/a';

export const toDateTimeStringOrDefault = (input: Date | undefined, locale: Locale) =>
  input ? format(input, 'Pp', { locale }) : 'n/a';

export const toLocaleStringOrDefault = (input: number | undefined, locale: Locale) =>
  input?.toLocaleString(locale.code) ?? 'n/a';
