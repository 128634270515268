import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { getMessages } from './i18n/i18n-messages';
import { selectedLanguageSelector } from './settings/settings.selector';
import { AppState } from './reducers/index';

const mapStateToProps = (state: AppState) => ({
  locale: selectedLanguageSelector(state),
  messages: getMessages(),
  textComponent: React.Fragment,
});

export default connect<IntlProvider['props'], {}, any, AppState>(mapStateToProps)(IntlProvider);
