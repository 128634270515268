import axios from 'axios';
import { DynamicsApiUri } from './dynamics.config';
import { DynamicsContact, DynamicsArrayResponse, DynamicsUser } from './dynamics.service.model';
import { EntityPhoneNumberType } from '../search/entities.model';
import { EntityType } from '../constants';

const CONTACT_FIELDS = 'fullname,telephone1,mobilephone';

const getContactUrl = (contactId: string): string => {
  return `${DynamicsApiUri.CONTACTS}(${contactId})`;
};

const isValidPhoneNumberField = ([entryname, entryvalue]: string[]): boolean => {
  return !!entryvalue && ['telephone1', 'mobilephone'].includes(entryname);
};

const dynamicsToEntityPhoneNumberType = (dynamicsPhoneNumberType: string): EntityPhoneNumberType => {
  switch (dynamicsPhoneNumberType) {
    case 'telephone1':
      return EntityPhoneNumberType.BUSINESS;
    case 'mobilephone':
      return EntityPhoneNumberType.MOBILE;
  }

  return EntityPhoneNumberType.OTHER;
};

const emptyStringToUndefined = (str?: string): string | undefined => {
  if (!str) {
    return undefined;
  }

  return str.length > 0 && str.trim().length > 0 ? str : undefined;
};

const whoAmI = async (): Promise<DynamicsUser> => {
  const response = await axios.get<DynamicsUser>(`${DynamicsApiUri.WHO_AM_I}`);
  return response.data;
};

const getDynamicsContact = async (contactId: string): Promise<DynamicsContact> => {
  const url = `${getContactUrl(contactId)}?$select=${CONTACT_FIELDS}`;
  const response = await axios.get<DynamicsContact>(url);
  return response.data;
};

const searchContactsByName = async (name: string): Promise<DynamicsContact[]> => {
  const filter = encodeURIComponent(`contains(fullname, '${name}')`);
  const url = `${DynamicsApiUri.CONTACTS}?$select=${CONTACT_FIELDS}&$filter=${filter}`;
  const response = await axios.get<DynamicsArrayResponse<DynamicsContact>>(url);
  return response.data.value;
};

const searchContactsByPhoneNumber = async (phoneNumber: string): Promise<DynamicsContact[]> => {
  const filter = encodeURIComponent(
    `contains(telephone1, '${phoneNumber}') or contains(mobilephone, '${phoneNumber}')`,
  );
  const url = `${DynamicsApiUri.CONTACTS}?$select=${CONTACT_FIELDS}&$filter=${filter}`;
  const response = await axios.get<DynamicsArrayResponse<DynamicsContact>>(url);
  return response.data.value;
};

const updateContactPhoneNumber = async (contactId: string, phoneNumber: string): Promise<void> => {
  const url = getContactUrl(contactId);
  const data = {
    telephone1: phoneNumber,
  };
  await axios.patch(url, data);
};

const createContact = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  mobilePhoneNumber: string,
): Promise<string> => {
  const data = {
    firstname: firstName,
    lastname: lastName,
    telephone1: phoneNumber,
    mobilephone: mobilePhoneNumber,
  };

  const response = await axios.post<{ contactid: string }>(DynamicsApiUri.CONTACTS, data, {
    headers: {
      Prefer: 'return=representation', // This will return the created entity.
    },
    params: {
      $select: 'contactid', // Only the listed properties are returned from the entity.
    },
  });

  return response.data.contactid;
};

const createCase = async (title: string, customerId: string): Promise<string> => {
  const data = {
    'customerid_contact@odata.bind': `/contacts(${customerId})`,
    title,
  };

  // Headers and params are added based on this documentation:
  // https://docs.microsoft.com/en-us/power-apps/developer/data-platform/webapi/create-entity-web-api#create-with-data-returned
  const response = await axios.post<{ incidentid: string }>(DynamicsApiUri.INCIDENTS, data, {
    headers: {
      Prefer: 'return=representation', // This will return the created entity.
    },
    params: {
      $select: 'incidentid', // Only the listed properties are returned from the entity.
    },
  });

  return response.data.incidentid;
};

const createOpportunity = async (topic: string, customerId?: string): Promise<string> => {
  const data = {
    name: topic,
    ...(customerId ? { 'customerid_contact@odata.bind': `/contacts(${customerId})` } : {}),
  };

  const response = await axios.post<{ opportunityid: string }>(DynamicsApiUri.OPPORTUNITIES, data, {
    headers: {
      Prefer: 'return=representation', // This will return the created entity.
    },
    params: {
      $select: 'opportunityid', // Only the listed properties are returned from the entity.
    },
  });

  return response.data.opportunityid;
};

const createLead = async (firstName: string, lastName: string, topic: string, phoneNumber: string): Promise<string> => {
  const data = {
    subject: topic,
    firstname: emptyStringToUndefined(firstName),
    lastname: lastName,
    telephone1: phoneNumber, // "Business Phone"
  };

  const response = await axios.post<{ leadid: string }>(DynamicsApiUri.LEADS, data, {
    headers: {
      Prefer: 'return=representation', // This will return the created entity.
    },
    params: {
      $select: 'leadid', // Only the listed properties are returned from the entity.
    },
  });

  return response.data.leadid;
};

const getEtnString = (entityType: EntityType): string => {
  switch (entityType) {
    case EntityType.CONTACT:
      return 'contact';
    case EntityType.CASE:
      return 'incident';
    case EntityType.OPPORTUNITY:
      return 'opportunity';
    case EntityType.LEAD:
      return 'lead';

    default:
      throw Error(`Unknown entity type for Dynamics ETN string: ${entityType}`);
  }
};

// CRM Form URLs are constructed based on this documentation:
// https://docs.microsoft.com/en-us/dynamics365/customerengagement/on-premises/developer/open-forms-views-dialogs-reports-url?view=op-9-1#url-addressable-forms-and-views
const getQueryStringForCrmFormURL = (entityType: EntityType, id: string): string =>
  `pagetype=entityrecord&etn=${getEtnString(entityType)}&id=${id}`;

const openForm = (organization: string, location: string, entityType: EntityType, entityId: string) => {
  const baseURL = `https://${organization}.${location}.dynamics.com/main.aspx`;
  const queryParams = DynamicsService.getQueryStringForCrmFormURL(entityType, entityId);

  const urlToOpen = `${baseURL}?${queryParams}`;
  window.open(urlToOpen, '_blank', 'openInDefaultBrowser');
};

export const DynamicsService = {
  isValidPhoneNumberField,
  dynamicsToEntityPhoneNumberType,
  whoAmI,
  getDynamicsContact,
  searchContactsByName,
  searchContactsByPhoneNumber,
  updateContactPhoneNumber,
  createContact,
  createCase,
  getQueryStringForCrmFormURL,
  createLead,
  createOpportunity,
  openForm,
};
