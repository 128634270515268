import { getBaseURIWithoutSlash } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { dismissService } from '../dismissService';

export const PERMISSION_REQUEST_STORAGE_KEY = 'microphone-permission-last-reminder';

export function openMicrophonePermissionsPage() {
  const permissionEnableUrl = getBaseURIWithoutSlash() + ApplicationRoute.MICROPHONE_PERMISSION;
  window.open(permissionEnableUrl.toString());
}

export const isNotMicrophonePermissionDenied = async (): Promise<boolean> => {
  try {
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
    return permissionStatus.state !== 'denied';
  } catch (err) {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (getUserMediaError) {
      return false;
    }
  }
};

export const dismissPermissionNotification = () => dismissService.dismiss(PERMISSION_REQUEST_STORAGE_KEY);

export const isPermissionNotificationDismissed = () => dismissService.isDismissed(PERMISSION_REQUEST_STORAGE_KEY);
