import { UserCallHistoryItem } from './callHistory.models';
import { differenceInSeconds } from 'date-fns';
import { CallWithDuration } from '../calls/calls.reducer';

type CallHistoryItemComparisonFields = Pick<UserCallHistoryItem, 'legId' | 'callee' | 'caller' | 'startTime'>;

export const areIdenticalCallHistoryItems = (
  i1: CallHistoryItemComparisonFields,
  i2: CallHistoryItemComparisonFields,
): boolean => {
  if (i1.legId === i2.legId) {
    return true;
  }

  const startTimeDifferenceInSeconds = Math.abs(differenceInSeconds(i1.startTime, i2.startTime));
  return (
    startTimeDifferenceInSeconds < 2 &&
    arePhoneNumbersEqual(i1.callee.number, i2.callee.number) &&
    arePhoneNumbersEqual(i1.caller.number, i2.caller.number)
  );
};

export const areIdenticalCallItems = (
  callHistoryItem: CallHistoryItemComparisonFields,
  call: CallWithDuration,
): boolean => {
  return areIdenticalCallHistoryItems(callHistoryItem, {
    ...call,
    legId: call.id,
    startTime: new Date(call.creationTime),
  });
};

export const arePhoneNumbersEqual = (phoneNumber1: string, phoneNumber2: string) => {
  return Number(phoneNumber1) === Number(phoneNumber2);
};
