import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { Vehicle } from './vehicles.models';

export enum VehiclesActionsType {
  LOAD_VEHICLES = 'vehicles/load',
  LOAD_VEHICLES_SUCCESS = 'vehicles/load/success',
  LOAD_VEHICLES_ERROR = 'vehicles/load/error',
}

export const loadVehicles = makeActionCreator(VehiclesActionsType.LOAD_VEHICLES)();
export const loadVehiclesSuccess = makeActionCreator(VehiclesActionsType.LOAD_VEHICLES_SUCCESS)<Vehicle[]>();
export const loadVehiclesError = makeActionCreator(VehiclesActionsType.LOAD_VEHICLES_ERROR)<WithMessage>();

export type VehiclesActions =
  | ReturnType<typeof loadVehicles>
  | ReturnType<typeof loadVehiclesSuccess>
  | ReturnType<typeof loadVehiclesError>;
