import { Call as RealtimeCall, CallType } from '@jive/realtime-events';
import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { SoftphoneCall } from './softphone.model';
import { SoftphoneCallsState } from './softphoneCall.reducer';

export const softphoneCallsSelector = (state: AppState): SoftphoneCallsState => state.softphoneCalls;

const areIdenticalCalls = (softphoneCall: SoftphoneCall, realtimeCall: RealtimeCall): boolean => {
  const isSameDirection =
    (realtimeCall.type === CallType.IncomingCall && softphoneCall.direction === 'incoming') ||
    (realtimeCall.type === CallType.OutgoingCall &&
      realtimeCall.isClickToCall &&
      softphoneCall.direction === 'incoming') ||
    (realtimeCall.type === CallType.OutgoingCall && softphoneCall.direction === 'outgoing');

  const isSameOtherParty = realtimeCall.theOtherParty.number === softphoneCall.theOtherParty.number;

  const isStartTimeClose = Math.abs(realtimeCall.creationTime - softphoneCall.startTime) < 60_000;

  return isSameDirection && isSameOtherParty && isStartTimeClose;
};

export const selectSoftphoneCallsByCalleeSelector = (callee: string) =>
  createSelector(softphoneCallsSelector, (softphoneCalls): SoftphoneCall[] =>
    Object.values(softphoneCalls).filter((softphoneCall) => softphoneCall.theOtherParty.number === callee),
  );

export const selectSoftphoneCallByRealtimeCallSelector = (realtimeCall: RealtimeCall) =>
  createSelector(softphoneCallsSelector, (softphoneCalls): SoftphoneCall | undefined =>
    Object.values(softphoneCalls)
      .filter((softphoneCall) => areIdenticalCalls(softphoneCall, realtimeCall))
      // let's find the last call if multiple calls match
      .sort((call1, call2) => call1.startTime - call2.startTime)
      .pop(),
  );

export const selectSoftphoneCallByIdSelector = (callId: string) =>
  createSelector(softphoneCallsSelector, (softphoneCalls): SoftphoneCall | undefined => softphoneCalls[callId]);

export const selectLocalSoftphoneCallsSelector = createSelector(softphoneCallsSelector, (softphoneCalls) =>
  Object.values(softphoneCalls).filter((softphoneCall) => !softphoneCall.isRemoteCall),
);

export const selectHasOtherAnsweredCall = (callId: string | undefined) =>
  createSelector(softphoneCallsSelector, (softphoneCalls) => {
    if (callId === undefined) {
      return false;
    }

    const activeCalls = Object.values(softphoneCalls).filter(
      (call) => call.id !== callId && call.callState === 'answered',
    );
    return activeCalls.length > 0;
  });
