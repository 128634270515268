import { makeActionCreator } from '../actions/actionCreators';
import { IndexedById } from '../models/index';
import { NormalizedLine } from './lines.model';
import { Organization } from '../settings/organizations.model';

export enum LineActionTypes {
  LOAD_LINES = 'lines/load',
  LOAD_LINES_SUCCESS = 'lines/load/success',
  LOAD_LINES_FAILED = 'lines/load/failed',
}

export const loadLinesAction = makeActionCreator(LineActionTypes.LOAD_LINES)();
export const loadLinesSuccessAction = makeActionCreator(LineActionTypes.LOAD_LINES_SUCCESS)<{
  lines: IndexedById<NormalizedLine>;
  organizations: IndexedById<Organization>;
}>();
export const loadLinesFailedAction = makeActionCreator(LineActionTypes.LOAD_LINES_FAILED)();

export type LinesActions =
  | ReturnType<typeof loadLinesAction>
  | ReturnType<typeof loadLinesSuccessAction>
  | ReturnType<typeof loadLinesFailedAction>;
