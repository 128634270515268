import { MessageBody } from '@ucc/messaging-service';
import { PagedConversations } from '@ucc/messaging-platform-service';
import { createActionCreator } from '../actions/actionCreators';

export const clearConversationsAction = createActionCreator('conversations/clear')();
export const loadConversationsAction = createActionCreator('conversations/load')();
export const loadConversationsSuccessAction = createActionCreator('conversations/load/success')<
  PagedConversations<MessageBody>
>();
export const loadConversationsErrorAction = createActionCreator('conversations/load/error')();
export const dismissConversationsLoadErrorAction = createActionCreator('conversations/load/dismissError')();
export const selectConversationsPhoneNumberAction = createActionCreator('conversations/selectPhoneNumber')<string>();
