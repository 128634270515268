import { CustomerActions, CustomerActionsType } from './cox.customer.actions';
import { Customer, LoadCustomerRequestState } from './cox.customer.models';

export interface CustomerState {
  loadRequestState: LoadCustomerRequestState;
  customer?: Customer;
}

export const INITIAL_STATE: CustomerState = {
  loadRequestState: LoadCustomerRequestState.INITIAL,
  customer: undefined,
};

export const customerReducer = (state: CustomerState = INITIAL_STATE, action: CustomerActions): CustomerState => {
  switch (action.type) {
    case CustomerActionsType.LOAD_CUSTOMER: {
      return {
        ...state,
        customer: undefined,
        loadRequestState: LoadCustomerRequestState.LOADING,
      };
    }
    case CustomerActionsType.LOAD_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loadRequestState: LoadCustomerRequestState.LOADED,
        customer: action.payload.customer,
      };
    }
    case CustomerActionsType.LOAD_CUSTOMER_ERROR: {
      return {
        ...state,
        loadRequestState: LoadCustomerRequestState.FAILED,
        customer: undefined,
      };
    }
    default:
      return state;
  }
};
