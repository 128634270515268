import { Call } from '@jive/realtime-events';
import { Entity } from '../models';
import { AddNotePayload } from '../notes/notes.models';

export const REDTAIL_API_PATH = 'crm/v1/rest';
export const REDTAIL_CRM_PATH = 'https://smf.crm3.redtailtechnology.com';

export const RedtailCrmUri = {
  CONTACT: (contactId: string) => `${REDTAIL_CRM_PATH}/contacts/${contactId}`,
  NOTES: (contactId: string) => `${REDTAIL_CRM_PATH}/contacts/${contactId}/notes`,
  ACTIVITY: (activityId: string) => `${REDTAIL_CRM_PATH}/activities/${activityId}`,
};

// https://help.redtailtechnology.com/hc/en-us/articles/203964480-Phones-By-ContactID-Methods-contacts-contactid-phones-
export enum RedtailPhoneNumberTypeID {
  Home = 'HM',
  Home2 = 'H2',
  Cellular = 'CL',
  Cellular2 = 'C2',
  Work = 'WK',
  Work2 = 'W2',
  DirectDial = 'DD',
  HomeFax = 'HF',
  Other = 'OT',
  Other2 = 'O2',
  Pager = 'PG',
  TollFree = 'TF',
  WorkFax = 'WF',
}

export interface RedtailPhoneNumberUpdate {
  ClientID: string;
  TypeID: RedtailPhoneNumberTypeID;
  // eslint-disable-next-line
  Number: string;
}

export enum RedtailPhoneNumberType {
  Work = 'Work',
  Home = 'Home',
  DirectDial = 'Direct Dial',
  Fax = 'Work Fax',
  Mobile = 'Cellular',
  Other = 'Other',
  TollFree = 'Toll Free',
}

export enum RedtailContactTypes {
  Business = 'Business',
  Individual = 'Individual',
  Association = 'Association',
  Trust = 'Trust',
  Union = 'Union',
}

export interface RedtailContact {
  ClientID: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  Name: string;
  Type: string;
}

export interface RedtailPhone {
  // eslint-disable-next-line
  Number: string;
  Type: string;
}

export interface RedtailEntity extends Entity {
  phoneNumbers: RedtailPhone[];
}

export enum GetCategoriesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface RedtailCategory {
  id: number;
  label: string;
}

export interface RedtailNote {
  RecID: number;
  RecAdd: string;
  Deleted: boolean;
  Category: string;
  Note: string;
}

export interface CreateRedtailNote {
  Note: string;
  CategoryID: number;
}

export interface RedtailActivityAddModel {
  subject: string;
  contactId: string;
  startDate: Date;
  endDate: Date;
  isAllDayEvent: boolean;
  isActiveCall: boolean;
  callId: string;
}

export interface RedtailActivity {
  ClientID: string;
  Subject: string;
  AllDayEvent: boolean;
  StartDate: string; // .net date /Date(<timestamp>)/
  EndDate: string; // .net date /Date(<timestamp>)/
}

export interface AddRedtailNotePayload extends AddNotePayload {
  call: Call;
  categoryId: number;
  includeCallDetails: boolean;
}
