import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { Entitlements } from './authentication.models';

const authenticationResultSelector = (state: AppState) => state.authentication.result;
const authenticationLoadingSelector = (state: AppState) => state.authenticationProgress.isLoading;
const authenticationErrorSelector = (state: AppState) => state.authenticationProgress.hasError;

const isTokenExpiredSelector: (state: AppState) => boolean = createSelector(
  authenticationResultSelector,
  (result) => !result || new Date(result.expiration) < new Date(),
);

export const tokenSelector: (state: AppState) => string = createSelector(
  authenticationResultSelector,
  (result) => result.token,
);

export const isAuthenticatedSelector: (state: AppState) => boolean = createSelector(
  authenticationResultSelector,
  isTokenExpiredSelector,
  (result, tokenExpired) => result.authenticated && !tokenExpired,
);

export const jiveIdSelector: (state: AppState) => string = createSelector(
  authenticationResultSelector,
  (result) => result.jiveId,
);

export const externalUserKeySelector: (state: AppState) => string = createSelector(
  authenticationResultSelector,
  (result) => result.externalUserKey,
);

export const isAuthenticationLoadingSelector: (state: AppState) => boolean = createSelector(
  authenticationLoadingSelector,
  (result) => result,
);

export const hasAuthenticationErrorSelector: (state: AppState) => boolean = createSelector(
  authenticationErrorSelector,
  (result) => result,
);

export const displayNameSelector: (state: AppState) => string = createSelector(
  authenticationResultSelector,
  (result) => result.name,
);

export const tokenExpirationInDaysSelector: (state: AppState) => number = createSelector(
  authenticationResultSelector,
  ({ expiration }) =>
    expiration === 0 ? 0 : (new Date(expiration).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24,
);

export const entitlementsSelector: (state: AppState) => Entitlements[] = createSelector(
  authenticationResultSelector,
  (state) => state.entitlements,
);

export const hasSeenLicenseWarningSelector: (state: AppState) => boolean | undefined = createSelector(
  authenticationResultSelector,
  (state) => state.seenLicenseWarning,
);
