import { Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Locale } from 'date-fns';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { SalesforceCase } from '../../../../salesforce-shared/salesforce-service.models';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { TrackedButton } from '../../analytics-new/TrackedButton.component';
import { CallContextListItem } from '../../calls/CallContextListItem.component';
import { CallContextPanelProps } from '../../calls/CallContextPanel.models';
import LoadingSpinner from '../../components/LoadingSpinner';
import { OpenInCRMButton } from '../../components/OpenInCRMButton.component';
import { isNarrowIntegrationSelector } from '../../integrations/integrations.selector';
import { Typography } from '../../theme/Typography.component';
import { SalesforceLightningActionFacade } from '../salesforceLightning.actionfacade';
import { GetCasesRequestState, GetMoreCasesRequestState } from './case.models';
import { useSelector } from 'react-redux';

const definedMessages = defineMessages({
  LOAD_MORE_BUTTON_LABEL: {
    id: 'Cases.List.LoadMore',
    defaultMessage: 'Load more cases',
  },
  EMPTY_MESSAGE: {
    id: 'Cases.List.Empty',
    defaultMessage: 'This contact has no cases yet.',
  },
  CREATE_CASE_BUTTON: {
    id: 'Cases.List.Create.Button.Label',
    defaultMessage: 'Create case',
  },
  STATUS_LABEL: {
    id: 'Cases.List.Status.Label',
    defaultMessage: 'Status:',
  },
  PRIORITY_LABEL: {
    id: 'Cases.List.Priority.Label',
    defaultMessage: 'Priority:',
  },
});

const trackingEvents = defineTrackingEvents({
  CREATE_CASE_BUTTON_CLICKED: {
    category: AnalyticsCategory.Case,
    action: AnalyticsAction.ItemClicked,
    label: 'Create case | button',
  },
  LOAD_MORE_CASES_BUTTON_CLICKED: {
    category: AnalyticsCategory.Case,
    action: AnalyticsAction.ItemClicked,
    label: 'Load more cases | button',
  },
  OPEN_CASE_BUTTON_CLICKED: {
    category: AnalyticsCategory.Case,
    action: AnalyticsAction.ItemClicked,
    label: 'Open case in CRM | button',
  },
});

export interface CasesStateProps {
  cases: SalesforceCase[];
  requestState: GetCasesRequestState;
  moreRequestState: GetMoreCasesRequestState;
  canLoadMoreCases: boolean;
  openCaseInCrmSupported: boolean;
  locale: Locale;
}

interface UseSytylesProps {
  isNarrowIntegration: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    meta: {
      fontSize: '0.75rem',
      color: tokens.inverse01,
    },
    createLink: {
      display: 'flex',
      alignItems: 'center',
      height: '30px',
    },
    createCaseText: {
      fontWeight: 600,
    },
    addIcon: {
      marginRight: theme.spacing(2),
    },
    secondaryItem: {
      '&:not(:first-child)': {
        marginLeft: '5px',
      },
    },
    fieldValue: {
      color: tokens.text05,
      fontSize: ({ isNarrowIntegration }: UseSytylesProps) => (isNarrowIntegration ? '0.68rem' : '0.75rem'),
    },
    fieldName: {
      color: tokens.text02,
      fontSize: ({ isNarrowIntegration }: UseSytylesProps) => (isNarrowIntegration ? '0.68rem' : '0.75rem'),
      fontWeight: 500,
    },
    loadMoreButton: {
      marginTop: '10px',
    },
  }),
);

export const CasesComponent: React.FC<CasesStateProps & CallContextPanelProps> = ({
  cases,
  requestState,
  contact,
  canLoadMoreCases,
  moreRequestState,
}) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });

  useEffect(() => {
    if (contact) {
      void getActionFacade<SalesforceLightningActionFacade>().loadMostRecentCaseOfContact(contact.id);
    }
  }, [contact]);

  function renderLoadMoreCasesButton() {
    if (!canLoadMoreCases) {
      return;
    }

    return (
      <TrackedButton
        fullwidth
        className={classes.loadMoreButton}
        id="loadMoreCases"
        variant="secondary"
        onClick={() => getActionFacade<SalesforceLightningActionFacade>().loadMoreCasesOfContact()}
        disabled={moreRequestState === GetMoreCasesRequestState.LOADING}
        trackingEvent={trackingEvents.LOAD_MORE_CASES_BUTTON_CLICKED}
      >
        {moreRequestState === GetMoreCasesRequestState.LOADING ? (
          <CircularProgress size={26} />
        ) : (
          <FormattedMessage {...definedMessages.LOAD_MORE_BUTTON_LABEL} />
        )}
      </TrackedButton>
    );
  }

  function renderList() {
    return cases?.length ? (
      <>
        <List dense disablePadding>
          {cases.map((sfCase: SalesforceCase) => (
            <CallContextListItem
              key={sfCase.id}
              id={sfCase.id}
              className={'case'}
              primary={
                <>
                  <Box mb={1}>
                    <Typography variant="body-medium">#{sfCase.caseNumber}</Typography>
                  </Box>
                  <span className={classes.meta}>{sfCase.subject}</span>
                </>
              }
              secondary={
                <>
                  {sfCase.status ? (
                    <span className={classes.secondaryItem}>
                      <span className={classes.fieldName}>
                        <FormattedMessage {...definedMessages.STATUS_LABEL} />
                      </span>
                      &nbsp;<span className={classes.fieldValue}>{sfCase.status}</span>
                    </span>
                  ) : null}
                  {sfCase.priority ? (
                    <span className={classes.secondaryItem}>
                      <span className={classes.fieldName}>
                        <FormattedMessage {...definedMessages.PRIORITY_LABEL} />
                      </span>
                      &nbsp;<span className={classes.fieldValue}>{sfCase.priority}</span>
                    </span>
                  ) : null}
                </>
              }
              secondaryActionButton={
                <Box mt={2} mr={2}>
                  <OpenInCRMButton
                    onClick={() => getActionFacade<SalesforceLightningActionFacade>().openCase(sfCase.id)}
                    trackingEvent={trackingEvents.OPEN_CASE_BUTTON_CLICKED}
                  />
                </Box>
              }
            />
          ))}
        </List>
        {renderLoadMoreCasesButton()}
      </>
    ) : (
      <Box mt={1} mr={1} mb={4} ml={1}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      </Box>
    );
  }

  function renderLoadedState() {
    return <Box mt={2}>{renderList()}</Box>;
  }

  function render() {
    switch (requestState) {
      case GetCasesRequestState.INITIAL:
        return null;
      case GetCasesRequestState.LOADING:
        return (
          <LoadingSpinner isLoading={true}>
            <></>
          </LoadingSpinner>
        );
      case GetCasesRequestState.LOADED:
        return renderLoadedState();
      case GetCasesRequestState.FAILED:
        return null; // The snack bar will display the error message.;
    }
  }

  return <div className={classes.container}>{render()}</div>;
};
