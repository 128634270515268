import { CallType } from '@jive/realtime-events';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import LoadingSpinner from '../components/LoadingSpinner';
import { AnalyticsSection } from '../entityActions/OpenEntityContextMenuItem.component';
import { Entity } from '../models';
import SearchNoResults from './SearchNoResults.container';
import SearchResult from './SearchResult.container';
import { SearchResultType } from './SearchResult.model';
import { SearchResultTile } from './SearchResultTile.component';

export interface SearchResultsStateProps {
  entities: Entity[] | undefined;
}

export interface SearchResultsProps {
  loading: boolean;
  errorMessage?: MessageDescriptor | null;
  searchResultType: SearchResultType;
  className?: string;
  callType?: CallType;
  analyticsSection?: AnalyticsSection;
}

export interface ButtonAction {
  icon: ReactNode;
  action: () => void;
  description: MessageDescriptor;
}

class SearchResults extends Component<SearchResultsStateProps & SearchResultsProps> {
  render() {
    const { entities, loading, errorMessage, searchResultType, className, callType, analyticsSection } = this.props;

    function renderResults() {
      if (!entities) {
        return null;
      }

      if (entities.length) {
        return entities.map((entity) => (
          <SearchResult
            key={entity.id}
            entity={entity}
            type={searchResultType}
            callType={callType}
            analyticsSection={analyticsSection}
          />
        ));
      }
      return <SearchNoResults searchType={searchResultType} />;
    }

    return (
      <div className={className}>
        <LoadingSpinner isLoading={loading}>
          <>
            {errorMessage ? (
              <SearchResultTile>
                <FormattedMessage {...errorMessage} />
              </SearchResultTile>
            ) : (
              renderResults()
            )}
          </>
        </LoadingSpinner>
      </div>
    );
  }
}

export default SearchResults;
