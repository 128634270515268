import { externalUserKeySelector } from '../authentication/authentication.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { GATracker, GoogleAnalyticsPayload, NewTracker } from '../analytics/tracker.models';
import { AnalyticsPageType, TrackingEvent } from './analytics.models';
import { analyticsCurrentPageTypeSelector } from './analytics.selector';
import { syncService } from '../sync/sync.service';

const UNKNOWN_USER = 'Unknown user';
const UNKNOWN_PBX = 'Unknown PBX';

interface AnalyticsTrackingMetaData {
  pageType: AnalyticsPageType;
  integration: string;
  organizationId: string;
  userId: string;
  appVersion: string;
}

export class NewGoogleAnalyticsTracker implements NewTracker {
  private userId: string;
  private getState?: () => AppState;
  private trackerName?: string;
  private appVersion?: string;
  private gaTracker?: GATracker;

  constructor() {
    this.userId = '';
    this.getState = undefined;
  }

  initialize(gaTracker: GATracker, trackerName: string, appVersion: string, getState: () => AppState): void {
    this.gaTracker = gaTracker;
    this.getState = getState;
    this.trackerName = trackerName;
    this.appVersion = appVersion;
  }

  trackAnalyticsEvent(event: TrackingEvent): void {
    const { category, action, label, value } = event;

    try {
      const metaData = this.getTrackingMetadata();
      const { userId } = metaData;

      // in case user sign-out and -in again with another user
      if (this.userId !== userId) {
        this.userId = userId;
        if (this.gaTracker) {
          this.gaTracker.set({ userId }, [this.trackerName!]);
        }
      }

      const analyticsPayload = this.createAnalyticsPayloadFromAction(category, label, action, value, metaData);

      if (!analyticsPayload) {
        logger.error('Could not construct the Google Analytics payload!', {
          payload: JSON.stringify(analyticsPayload),
        });
        return;
      }

      if (this.gaTracker) {
        this.gaTracker.event(analyticsPayload, [this.trackerName!]);
      }
    } catch (e) {
      logger.error('Error sending GA event, ', e);
    }
  }

  async trackAnalyticsEventOnce(
    event: TrackingEvent,
    synchronizationId: string,
    synchronizationUniqueId: string,
  ): Promise<void> {
    try {
      const canTrack = await syncService.canCurrentTabOperate(synchronizationId, synchronizationUniqueId);
      if (canTrack) {
        this.trackAnalyticsEvent(event);
      }
    } catch (e) {
      logger.error('Error synchronizing tabs for analytics', e, {
        synchronizationId,
      });
    }
  }

  private createAnalyticsPayloadFromAction(
    category: string,
    label: string,
    action: string,
    value: number | undefined,
    meta: AnalyticsTrackingMetaData,
  ): GoogleAnalyticsPayload | null {
    if (!category || !action || !meta) {
      return null;
    }

    if (typeof label !== 'string') {
      logger.error('Analytics event label is not a string', { value: label, type: typeof label });
    }

    return {
      label: `${label}`, // converting label to string if it's something else (ex: number)
      action,
      category,
      value,
      dimension1: meta.integration,
      dimension2: meta.organizationId,
      dimension3: this.userId,
      dimension4: meta.appVersion,
      dimension5: meta.pageType,
    };
  }

  private getTrackingMetadata(): AnalyticsTrackingMetaData {
    if (!this.getState) {
      throw Error('tracker initialized with no state getter');
    }
    if (!this.appVersion) {
      throw Error('tracker initialized with no appVersion');
    }

    const state = this.getState();

    // Ensure that we have an organization ID even for actions that occur before the user's lines are downloaded.
    // Ensure that we have a userID even if the user is not logged in yet.
    return {
      userId: externalUserKeySelector(state) || UNKNOWN_USER,
      organizationId: selectedOrganizationIdSelector(state) || UNKNOWN_PBX,
      integration: currentIntegrationSelector(state),
      appVersion: this.appVersion,
      pageType: analyticsCurrentPageTypeSelector(state),
    };
  }

  trackPageView(): void {
    const location = window.location;
    if (this.gaTracker) {
      this.gaTracker.pageview(location.pathname + location.search, [this.trackerName!]);
    }
  }
}

export const newTracker = new NewGoogleAnalyticsTracker();
