import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import gtcNewLogo from '../assets/goto-integrations-horizontal.svg';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { Box } from '@material-ui/core';

interface GotoIntegrationsLogoProps {
  width: string | number;
  narrowWidth?: string | number;
}

export const GotoIntegrationsLogo: FC<GotoIntegrationsLogoProps> = ({ narrowWidth, width }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const widthToUse = isNarrowIntegration ? narrowWidth ?? width : width;
  return (
    <Box width={widthToUse} display="flex" gridGap={4} alignItems="center">
      <img style={{ width: '100%' }} src={gtcNewLogo} alt="logo" />
    </Box>
  );
};
