import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { ZendeskTicketSearchResultItem } from '../zendesk.models';

export enum TicketsActionsType {
  TICKET = 'ticket',
  OPEN_TICKET = 'ticket/openRequest',
  OPEN_TICKET_ERROR = 'ticket/openError',
  OPEN_TICKET_SUCCESS = 'ticket/openSuccess',
  CREATE_TICKET = 'ticket/create',
  CREATE_TICKET_ERROR = 'ticket/createError',
  CREATE_TICKET_SUCCESS = 'ticket/createSuccess',
  SEARCH_TICKETS = 'tickets/search/request',
  SEARCH_TICKETS_SUCCESS = 'tickets/search/success',
  SEARCH_TICKETS_ERROR = 'tickets/search/error',
  SEARCH_TICKETS_CLEAR = 'tickets/search/clear',
}

export interface CreateTicketPayload {
  subject: string;
  description: string;
  userId: string;
}

interface OpenTicketPayload {
  ticketId: string;
}

interface CreateTicketSuccessPayload {
  ticketId: string;
}

export interface SearchTicketPayload {
  tickets: ZendeskTicketSearchResultItem[];
}

export const openTicket = makeActionCreator(TicketsActionsType.OPEN_TICKET)<OpenTicketPayload>();
export const openTicketSuccess = makeActionCreator(TicketsActionsType.OPEN_TICKET_SUCCESS)();
export const openTicketError = makeActionCreator(TicketsActionsType.OPEN_TICKET_ERROR)<WithMessage>();
export const createTicket = makeActionCreator(TicketsActionsType.CREATE_TICKET)<CreateTicketPayload>();
export const createTicketSuccess = makeActionCreator(TicketsActionsType.CREATE_TICKET_SUCCESS)<
  CreateTicketSuccessPayload & WithMessage
>();
export const createTicketError = makeActionCreator(TicketsActionsType.CREATE_TICKET_ERROR)<WithMessage>();

export const searchTicket = makeActionCreator(TicketsActionsType.SEARCH_TICKETS)();
export const searchTicketSuccess = makeActionCreator(TicketsActionsType.SEARCH_TICKETS_SUCCESS)<SearchTicketPayload>();
export const searchTicketError = makeActionCreator(TicketsActionsType.SEARCH_TICKETS_ERROR)<WithMessage>();
export const searchTicketClear = makeActionCreator(TicketsActionsType.SEARCH_TICKETS_CLEAR)();

export type TicketsActions =
  | ReturnType<typeof openTicket>
  | ReturnType<typeof openTicketError>
  | ReturnType<typeof createTicket>
  | ReturnType<typeof createTicketSuccess>
  | ReturnType<typeof createTicketError>
  | ReturnType<typeof searchTicket>
  | ReturnType<typeof searchTicketSuccess>
  | ReturnType<typeof searchTicketError>
  | ReturnType<typeof searchTicketClear>;
