import { combineReducers } from 'redux';
import { VoicemailPlayerActionsType } from '../constants';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';
import { VoicemailsPlayerActions } from './voicemailPlayer.actions';

export interface VoicemailsPlayerState {
  voicemailId: string;
  isPlaying: boolean;
  currentTime: number;
}

export const INITIAL_STATE: VoicemailsPlayerState = {
  voicemailId: '',
  isPlaying: false,
  currentTime: 0,
};

export const voicemailPlayerReducer = (state = INITIAL_STATE, action: VoicemailsPlayerActions) => {
  switch (action.type) {
    case VoicemailPlayerActionsType.OPEN: {
      return {
        ...INITIAL_STATE,
        voicemailId: action.payload,
        isPlaying: false,
      };
    }
    case VoicemailPlayerActionsType.CLOSE: {
      return {
        ...state,
        voicemailId: '',
      };
    }
    case VoicemailPlayerActionsType.SET_VOICEMAIL_ID: {
      return {
        ...state,
        voicemailId: action.payload,
        isPlaying: false,
      };
    }
    case VoicemailPlayerActionsType.PLAY: {
      return {
        ...state,
        isPlaying: true,
      };
    }
    case VoicemailPlayerActionsType.PAUSE: {
      return {
        ...state,
        isPlaying: false,
      };
    }
    case VoicemailPlayerActionsType.SEEK:
    case VoicemailPlayerActionsType.UPDATE_CURRENT_TIME: {
      return {
        ...state,
        currentTime: action.payload,
      };
    }
    case VoicemailPlayerActionsType.ENDED: {
      return {
        ...state,
        isPlaying: false,
        currentTime: 0,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  current: voicemailPlayerReducer,
  loadFile: combineReducers({
    isLoading: loadingReducer(VoicemailPlayerActionsType.LOAD_FILE),
    hasError: errorReducer(VoicemailPlayerActionsType.LOAD_FILE),
  }),
  loadVoicemail: combineReducers({
    isLoading: loadingReducer(VoicemailPlayerActionsType.LOAD_VOICEMAIL),
    hasError: errorReducer(VoicemailPlayerActionsType.LOAD_VOICEMAIL),
  }),
});
