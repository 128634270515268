import { ReactNode } from 'react';
import { FormattedMessageWithMessageValues } from '../reactInltModels';

export interface IndexedById<T> {
  [id: string]: T;
}

export interface Entity {
  id: string;
  label: string;
  name: string;
  phoneNumber?: string;
  type: string;
  // Eventually make it required, but since we don't want to pass over all integration, it's optional
  source?: Integrations;
  doNotCall?: boolean;
}

export enum Integrations {
  None = '',
  Zendesk = 'zendesk',
  SalesforceLightning = 'salesforce-lightning',
  SalesforceClassic = 'salesforce-classic',
  MsTeams = 'msteams',
  Clio = 'clio',
  ConnectWise = 'connectwise',
  Redtail = 'redtail',
  Cox = 'cox',
  Dynamics = 'dynamics',
}

export const IntegrationNames = {
  [Integrations.Zendesk]: 'Zendesk',
  [Integrations.SalesforceLightning]: 'Salesforce Lightning',
  [Integrations.SalesforceClassic]: 'Salesforce Classic',
  [Integrations.MsTeams]: 'Microsoft Teams',
  [Integrations.Clio]: 'Clio',
  [Integrations.ConnectWise]: 'ConnectWise',
  [Integrations.Redtail]: 'Redtail',
  [Integrations.Cox]: 'Cox Automotive',
  [Integrations.Dynamics]: 'Microsoft Dynamics',
};

export enum MessageVariant {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  CallInfo = 'callInfo',
  Custom = 'custom',
}

export interface InAppNotificationMessage {
  id: string;
  type: string;
  message?: FormattedMessageWithMessageValues;
  params: {
    autoHide: boolean;
    actions?: ReactNode;
    icon?: ReactNode;
    dismissible?: boolean;
    entityId?: string;
    customContent?: React.ReactElement;
  };
}

export interface WithMessage {
  message: FormattedMessageWithMessageValues;
  /**
   * [Optional - `true` by default] True when the message should be rendered in the snack bar/toast, any top level message handler
   */
  isTopLevel?: boolean;
}

export interface ErrorWithMessage extends WithMessage {
  error: any;
}

export enum ErrorMessageDisplay {
  PushNotification = 'PushNotificationPermission',
  ThirdParty = 'ThirdPartyPermission',
}
