export interface ZafClientRequestOptions {
  url: string;
  type?: 'GET' | 'PUT' | 'POST' | 'DELETE';
  contentType?: string; // most probably 'application/json'
  data?: string;
  timeout?: number; // Timeout period in milliseconds for the request
}

// TODO: extend when needed
// https://developer.zendesk.com/apps/docs/core-api/client_api#client-object
export interface ZafClient {
  init(): ZafClient;
  invoke(name: string, ...args: any);
  invoke(obj: { [key: string]: any[] });
  instance(guid: string): ZafClient;
  on(event: string, callback: () => void);
  off(event: string, callback: () => void);
  request<T = void>(options: ZafClientRequestOptions): Promise<T>;
}

// !!!!!!
// In case of login page, the zaf client is not available yet
export const configureZafClient = (): ZafClient | false => {
  const zafClient = window.ZAFClient!.init();
  if (zafClient) {
    zafClient.invoke('resize', { width: '340px', height: '480px' });
  }
  return zafClient;
};
