import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import { TrackedCheckbox, TrackedCheckboxProps } from '../analytics-new/TrackedCheckbox.component';
import { FormControlLabelClassKey } from '@material-ui/core';

export interface LabeledCheckboxProps extends TrackedCheckboxProps {
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  label: React.ReactNode;
  controlLabelClasses?: Partial<Record<FormControlLabelClassKey, string>>;
}
export const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  labelPlacement,
  label,
  controlLabelClasses,
  ...switchProps
}) => (
  <FormControlLabel
    labelPlacement={labelPlacement}
    control={<TrackedCheckbox {...switchProps} />}
    label={label}
    classes={controlLabelClasses}
  />
);
