import { TrackingEvent } from './analytics.models';
import { newTracker } from './tracker-new';
import React from 'react';
import { logger } from '../logging';
import { Link, LinkProps } from '@getgo/chameleon-web/react';

export interface TrackedLinkProps extends LinkProps {
  trackingEvent: TrackingEvent;
  component: React.ElementType;
}

export const TrackedLink: React.FC<TrackedLinkProps> = React.forwardRef<any, TrackedLinkProps>(
  ({ trackingEvent, onClick, ...otherLinkProps }, ref) => (
    <Link
      ref={ref}
      onClick={(event) => {
        try {
          newTracker.trackAnalyticsEvent(trackingEvent);
        } catch (error) {
          logger.error('Error while tracking link', error);
        }
        if (onClick) {
          onClick(event);
        }
      }}
      {...otherLinkProps}
    />
  ),
);
