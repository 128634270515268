import { Autocomplete } from '@getgo/chameleon-material-ui';
import React from 'react';
import { ZendeskTicketSearchResultItem } from '../zendesk.models';
import { useSelector } from 'react-redux';
import { ticketsLoadingSelector, ticketsSelector } from './ticket.selector';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { ZendeskActionFacade } from '../zendesk.actionfacade';
import { debounce } from 'lodash-es';
import { AutocompleteRenderGroupParams } from '@material-ui/lab';
import { Divider, ListSubheader } from '@material-ui/core';
import { MessageDescriptor } from '@formatjs/intl';

interface TicketSelectorProps {
  selectedTicket?: ZendeskTicketSearchResultItem;
  onTicketChange: (ticket?: ZendeskTicketSearchResultItem) => void;
  requesterId?: string;
}

const definedMessages = defineMessages({
  TICKET_SEARCH_PLACEHOLDER: {
    id: 'Zendesk.TicketSearch.Placeholder',
    defaultMessage: 'Search tickets',
  },
  TICKETS_LOADING_MESSAGE: {
    id: 'Zendesk.Tickets.Loading',
    defaultMessage: 'Loading...',
  },
  TICKETS_RELATED_TO_LABEL: {
    id: 'Zendesk.Tickets.RelatedTickets.Label',
    defaultMessage: 'Related tickets',
  },
  TICKETS_OTHER_LABEL: {
    id: 'Zendesk.Tickets.OtherTickets.Label',
    defaultMessage: 'Other tickets',
  },
});

const searchResultItemDefinedMessages: {
  [key in ZendeskTicketSearchResultItem['searchResultType']]: MessageDescriptor;
} = {
  other: definedMessages.TICKETS_OTHER_LABEL,
  related: definedMessages.TICKETS_RELATED_TO_LABEL,
};
const useStyles = makeStyles(() => ({
  textFieldRoot: {
    background: 'white',
    '& input': {
      paddingRight: '20px !important',
    },
    '--goto-icon-01': 'var(--goto-icon-04)',
    '& [class*="MuiAutocomplete-inputRoot"].Mui-disabled': {
      color: 'rgba(37, 40, 45, 0.36)',
      borderColor: 'rgba(37, 40, 45, 0.24)',
      backgroundColor: 'white',
    },
  },
  option: {
    borderWidth: 0,
    '&:hover': {
      background: 'var(--goto-option-background-hover) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
    '&[data-focus="true"]': {
      background: 'var(--goto-option-background-hover) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
    '&[aria-selected="true"]': {
      background: 'var(--goto-option-background-selected) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
  },
  listSubheader: {
    background: 'white',
  },
}));
export const TicketsSelect: React.FC<TicketSelectorProps> = ({ selectedTicket, onTicketChange, requesterId }) => {
  const styles = useStyles();

  const tickets = useSelector(ticketsSelector);
  const isTicketsLoading = useSelector(ticketsLoadingSelector);
  const intl = useIntl();

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <>
      <ListSubheader key={params.key} component="div" classes={{ sticky: styles.listSubheader }}>
        <FormattedMessage {...searchResultItemDefinedMessages[params.group]} />
      </ListSubheader>
      <Divider />
    </>,
    params.children,
  ];
  return (
    <Autocomplete
      debug
      options={tickets.sort((a, b) => -a.searchResultType.localeCompare(b.searchResultType))}
      renderGroup={renderGroup}
      groupBy={(option) => option.searchResultType}
      openOnFocus
      forcePopupIcon={false}
      getOptionSelected={(option, selected) => option.id === selected.id}
      getOptionLabel={(option) => `#${option.id} - ${option.subject}`}
      renderOption={(option) => (
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          #{option.id} - {option.subject}
        </span>
      )}
      value={selectedTicket ?? null}
      id="zendesk-ticket-picker"
      onChange={(_, option) => {
        onTicketChange(option ?? undefined);
      }}
      onInputChange={debounce((_, value, reason) => {
        if (reason === 'input') {
          void getActionFacade<ZendeskActionFacade>().searchTickets(requesterId, value);
        } else if (reason === 'clear') {
          void getActionFacade<ZendeskActionFacade>().searchTickets(requesterId);
        }
      }, 500)}
      loading={isTicketsLoading === 'loading'}
      classes={{ option: styles.option }}
      TextFieldProps={{
        classes: { root: styles.textFieldRoot },
        noMargin: true,
        placeholder: intl.formatMessage(definedMessages.TICKET_SEARCH_PLACEHOLDER),
      }}
      loadingText={<FormattedMessage {...definedMessages.TICKETS_LOADING_MESSAGE} />}
    />
  );
};
