export enum ServiceAppointmentStatus {
  ServiceDepartment = 'A',
  BodyShop = 'B',
  WP = 'C',
  FirstVisit = 'D',
  BookedFromTheWeb = 'E',
}

export interface ServiceAppointment {
  id: string;
  appointmentNumber: string;
  appointmentDateTime: Date;
  totalEstimate?: number;
  status: ServiceAppointmentStatus;
}

export enum LoadServiceAppointmentsRequestState {
  LOADING,
  LOADED,
  FAILED,
}
