import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { entitiesResultSelector } from '../search/entities.selector';

export const phoneResourceSelector = (state: AppState) => state.phone;

export const phoneModeSelector = createSelector(phoneResourceSelector, (phone) => phone.mode);

export const selectedPhoneNumberSelector = createSelector(phoneResourceSelector, (phone) => phone.selectedPhoneNumber);

export const selectedEntityIdSelector = createSelector(phoneResourceSelector, (phone) => phone.selectedEntityId);

export const selectedEntitySelector = createSelector(
  selectedEntityIdSelector,
  entitiesResultSelector,
  (selectedEntityId, entityResults) => {
    return entityResults?.byId?.[selectedEntityId];
  },
);

export const calledContactSelector = createSelector(phoneResourceSelector, (phone) => phone.contactCalled);
