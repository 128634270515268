import { createSelector } from 'reselect';
import { proxyLinkedSelector } from '../ambassador/proxy/proxy.selector';
import { IntegrationNames, Integrations } from '../models';
import { AppState } from '../reducers';
import { salesforceCommonSelector } from '../salesforce/salesforce.selectors';
import { isDesktopIntegration } from '../utils';

const integrationsResourceSelector = (state: AppState) => state.integrations;

export const currentIntegrationSelector = createSelector(integrationsResourceSelector, (result) => result.current);

export const currentIntegrationNameSelector = createSelector(currentIntegrationSelector, (integration) => {
  switch (integration) {
    case Integrations.Clio:
    case Integrations.Zendesk:
    case Integrations.SalesforceLightning:
    case Integrations.SalesforceClassic:
    case Integrations.MsTeams:
    case Integrations.Redtail:
    case Integrations.ConnectWise:
    case Integrations.Cox:
    case Integrations.Dynamics:
      return IntegrationNames[integration];
    default:
      throw new Error(`Unexpected integration: ${integration}`);
  }
});

export const integrationStartupStateSelector = createSelector(
  integrationsResourceSelector,
  (integrations) => integrations.startupState,
);

export function isPersonalLinkingNeededForIntegration(integration: Integrations): boolean {
  return (
    integration === Integrations.Clio ||
    integration === Integrations.ConnectWise ||
    integration === Integrations.Redtail ||
    integration === Integrations.Dynamics
  );
}

export function isEnterpriseLinkingNeededForIntegration(integration: Integrations): boolean {
  return integration === Integrations.Cox;
}

export const personalLinkingNeededForCurrentIntegrationSelector = createSelector(
  currentIntegrationSelector,
  (integration) => isPersonalLinkingNeededForIntegration(integration),
);

export const enterpriseLinkingNeededForCurrentIntegrationSelector = createSelector(
  currentIntegrationSelector,
  (integration) => isEnterpriseLinkingNeededForIntegration(integration),
);

export const isIntegrationLinkedIfNecessarySelector = createSelector(
  (state) => state,
  personalLinkingNeededForCurrentIntegrationSelector,
  enterpriseLinkingNeededForCurrentIntegrationSelector,
  (state, personalLinkingNeededForCurrentIntegration, enterpriseLinkingNeededForCurrentIntegration) => {
    if (personalLinkingNeededForCurrentIntegration || enterpriseLinkingNeededForCurrentIntegration) {
      return proxyLinkedSelector(state);
    }
    return true;
  },
);

export const isDesktopIntegrationSelector = createSelector(currentIntegrationSelector, (integration) =>
  isDesktopIntegration(integration),
);

export const isNarrowIntegrationSelector = createSelector(
  currentIntegrationSelector,
  salesforceCommonSelector,
  (integration, salesforceCommon) =>
    integration === Integrations.SalesforceClassic && !salesforceCommon.isRunningInConsoleMode,
);
