import { Autocomplete, Select, MenuItem } from '@getgo/chameleon-material-ui';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  isWhatObjectsLoadingSelector,
  selectTaskRelatedThemeItems,
  whatObjectNamesSelector,
  whatObjectsSelector,
} from '../salesforce.selectors';
import { injectedIntlSelector } from '../../settings/settings.selector';
import { Box } from '@material-ui/core';
import { clearWhatObjects } from '../salesforce.actions';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { SalesforceActionFacadeBase } from '../salesforce.actionfacade-base';
import { Typography } from '../../theme/Typography.component';
import { makeStyles } from '@material-ui/core/styles';

const definedMessages = defineMessages({
  PLACEHOLDER: {
    id: 'Salesforce.ObjectPicker.Placeholder',
    defaultMessage: 'Search {objectType}',
  },
  LABEL: {
    id: 'Salesforce.WhatPicker.Label',
    defaultMessage: 'Related To',
  },
  NO_OPTIONS_FOUND: {
    id: 'Salesforce.ObjectPicker.Empty',
    defaultMessage: 'No {objectType} found',
  },
  LOADING: {
    id: 'Salesforce.ObjectPicker.Loading',
    defaultMessage: 'Loading...',
  },
});

const useStyles = makeStyles(() => ({
  textFieldRoot: {
    background: 'white',
    '& input': {
      paddingRight: '20px !important',
    },
    '--goto-icon-01': 'var(--goto-icon-04)',
    '& [class*="MuiAutocomplete-inputRoot"].Mui-disabled': {
      color: 'rgba(37, 40, 45, 0.36)',
      borderColor: 'rgba(37, 40, 45, 0.24)',
      backgroundColor: 'white',
    },
  },
  option: {
    borderWidth: 0,
    '&:hover': {
      background: 'var(--goto-option-background-hover) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
    '&[data-focus="true"]': {
      background: 'var(--goto-option-background-hover) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
    '&[aria-selected="true"]': {
      background: 'var(--goto-option-background-selected) !important',
      color: 'var(--goto-option-color-selected) !important',
    },
  },
}));

export interface WhatPickerProp {
  onChange: (selectedWhat?: { id: string; name: string }) => void;
  onTypeChange: (selectedType: string) => void;
  selectedWhat?: { id: string; name: string };
  selectedWhatType: string;
  disabled: boolean;
}

export const WhatPicker: React.FC<WhatPickerProp> = ({
  onChange,
  selectedWhat,
  selectedWhatType,
  onTypeChange,
  disabled,
}) => {
  const intl = useSelector(injectedIntlSelector);
  const whatObjectNames = useSelector(whatObjectNamesSelector);
  const whatObjects = useSelector(whatObjectsSelector);
  const isLoading = useSelector(isWhatObjectsLoadingSelector);
  const themeItems = useSelector(selectTaskRelatedThemeItems);
  const styles = useStyles();

  const allWhatObjects =
    selectedWhat && !whatObjects.some((o) => o.id === selectedWhat.id) ? [selectedWhat, ...whatObjects] : whatObjects;
  const selectedValue = selectedWhat ?? null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedWhatType) {
      void getActionFacade<SalesforceActionFacadeBase>().searchWhatObjects(selectedWhatType);
    }
  }, [selectedWhatType]);

  const WhatTypePicker = () => {
    return (
      <Select
        disabled={disabled}
        noMargin
        fullWidth
        name="what-type"
        value={selectedWhatType}
        onChange={(event) => {
          onTypeChange(event.target.value);
          onChange(undefined);
          dispatch(clearWhatObjects());
        }}
      >
        {whatObjectNames.map((objectType) => (
          <MenuItem key={objectType} value={objectType}>
            <Box display="inline-flex" alignItems="center">
              <Box
                display="inline-flex"
                alignItems="center"
                my={1}
                mr={1}
                style={{ background: `#${themeItems[objectType].color}`, borderRadius: '2px' }}
              >
                {themeItems[objectType].icon ? (
                  <img width={20} height={20} src={themeItems[objectType].icon} alt={objectType} />
                ) : (
                  <div style={{ height: 20, width: 20 }} />
                )}
              </Box>
              {objectType}
            </Box>
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography variant="caption-default-02" color={disabled ? 'text-03' : undefined}>
          <FormattedMessage {...definedMessages.LABEL} />
        </Typography>
      </Box>
      <Box mb={1}>
        <WhatTypePicker />
      </Box>
      <Autocomplete
        options={allWhatObjects}
        openOnFocus
        disabled={disabled}
        forcePopupIcon={false}
        getOptionSelected={(option, selected) => option.id === selected.id}
        getOptionLabel={(option) => option.name}
        value={selectedValue}
        id="salesforce-what-picker"
        onChange={(_, option) => {
          onChange(option ? { id: option.id, name: option.name } : undefined);
        }}
        onInputChange={(_, value, reason) => {
          if (reason === 'input' && !!value && value.length > 2) {
            void getActionFacade<SalesforceActionFacadeBase>().searchWhatObjects(selectedWhatType, value);
          } else if (reason === 'clear') {
            onChange(undefined);
          }
        }}
        loading={isLoading}
        loadingText={<FormattedMessage {...definedMessages.LOADING} />}
        noOptionsText={
          <FormattedMessage {...definedMessages.NO_OPTIONS_FOUND} values={{ objectType: selectedWhatType }} />
        }
        classes={{ option: styles.option }}
        TextFieldProps={{
          classes: { root: styles.textFieldRoot },
          noMargin: true,
          placeholder: intl.formatMessage(definedMessages.PLACEHOLDER, { objectType: selectedWhatType }),
        }}
      />
    </Box>
  );
};
