import { CrmFormActionCreator } from '../../actionFacade/actionCreators/crmForm/crmForm.actioncreator';
import { connectWiseApiUrlSelector } from '../connectwise.selector';
import { OpenCrmFormModel } from '../../actionFacade/actionCreators/crmForm/crmForm.model';
import { EntityType } from '../../constants';

export class ConnectWiseCrmFormActionCreator extends CrmFormActionCreator {
  protected _openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    let query: string = '';
    if (model.entityType === EntityType.CONNECTWISE_ACTIVITY) {
      query = 'routeTo=ActivityFv';
    } else if (model.entityType === EntityType.CONNECTWISE_OPPORTUNITY) {
      query = 'routeTo=OpportunityFv';
    } else {
      throw Error(`Unexpected entity type received when trying to open entity form: ${model.entityType}`);
    }

    const connectWiseUrl = connectWiseApiUrlSelector(this.store.getState());
    const urlToOpen = `${connectWiseUrl}?${query}`;
    window.open(urlToOpen, '_blank', 'openInDefaultBrowser');
    return Promise.resolve();
  }
}
