import { Box, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme } from '@material-ui/core';
import React, { FC, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from '../phone/phone-utils';
import { injectedIntlSelector, selectedLanguageSelector } from '../settings/settings.selector';
import { getCallCreationDateAndTime, getCallTypeDescriptor, getNumberOfTheOtherParty } from './call.helper';
import { CallWithContact } from './calls.reducer';
import { getContactDisplay, getSelectedContactInCall, isContactCallable } from './calls.selector';
import { spacing, tokens } from '@getgo/chameleon-core';
import { CallType } from '@jive/realtime-events';
import { FormattedMessage } from 'react-intl';
import { CallButton, DoNotCallButton } from '../components/CallButton.component';
import { updateActiveCall } from './callEvents.action';
import { call as callAction } from '../phone/phone.actions';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { Tooltip, Typography } from '@getgo/chameleon-material-ui';
import { CallTypeIcon } from '../components/CallTypeIcon';
import getDurationDisplay from '../formatCallDuration';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { newTracker } from '../analytics-new/tracker-new';

const useStyles = makeStyles((theme: Theme) => {
  return {
    listItemContainer: (prop: { isNarrowIntegration: boolean }) => ({
      paddingRight: prop.isNarrowIntegration ? 45 : 55,
      width: '100%',
      position: 'relative',
    }),
    listItem: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: tokens.uiBase01,
      border: `1px solid ${tokens.interactive01}`,
      '&.Mui-focusVisible': {
        boxShadow: tokens.elevationSmall,
        borderColor: tokens.hoverPrimary,
        backgroundColor: tokens.information02,
      },
    },
    listItemButton: {
      '&:hover': {
        backgroundColor: tokens.hoverSecondary,
      },
    },
    listItemGutters: (props: { isNarrowIntegration: boolean }) => ({
      padding: theme.spacing(2, 0, 2, props.isNarrowIntegration ? 2 : 3),
    }),
    listItemTextRoot: {
      margin: '0',
    },
    listItemPrimaryText: {
      fontWeight: 'bold',
      lineHeight: '15px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listItemPhoneText: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '13px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    listItemCallType: {
      fontSize: '11px',
      lineHeight: '13px',
      marginTop: '4px',
      display: 'flex',
    },
    callbackButtonAction: (props: { isNarrowIntegration: boolean }) => {
      if (props.isNarrowIntegration) {
        return {
          transform: 'none',
          top: '7px',
          right: '6px',
        };
      } else {
        return {
          right: '12px',
        };
      }
    },
    listItemDateAndDuration: {
      fontSize: '11px',
      lineHeight: '14px',
    },
    duration: {
      marginLeft: '5px',
    },
  };
});

const trackingEvents = defineTrackingEvents({
  CALL_BUTTON_CLICKED: {
    category: AnalyticsCategory.Call,
    action: AnalyticsAction.ItemClicked,
    label: 'Call back icon | button',
  },
});

export interface CallHistoryItemProps {
  index: number;
  style: CSSProperties;
  data: CallWithContact[];
}

export const CallHistoryItem: FC<CallHistoryItemProps> = ({ index, style, data }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });
  const intl = useSelector(injectedIntlSelector);
  const language = useSelector(selectedLanguageSelector);

  const call = data[index];
  const contactDisplay = getContactDisplay(intl, call.allMatches || [], call.entitySelected);
  const phoneNumber = formatPhoneNumber(getNumberOfTheOtherParty(call));
  const dispatch = useDispatch();

  const moveToCallEnded = (call: CallWithContact) => {
    dispatch(updateActiveCall({ call }));
  };

  const onCallButtonClick = (call: CallWithContact) => {
    newTracker.trackAnalyticsEvent({
      category: AnalyticsCategory.Application,
      action: AnalyticsAction.ContactMatchPulled,
      label: 'call history button',
    });

    dispatch(
      callAction({
        phoneNumber: call.theOtherParty.number,
        contact: getSelectedContactInCall(call),
      }),
    );
  };

  const renderCallButton = (call: CallWithContact) => {
    return isContactCallable(call) ? (
      <CallButton onClick={() => onCallButtonClick(call)} trackingEvent={trackingEvents.CALL_BUTTON_CLICKED} />
    ) : (
      <DoNotCallButton onClick={() => onCallButtonClick(call)} trackingEvent={trackingEvents.CALL_BUTTON_CLICKED} />
    );
  };

  const renderCallType = (callType: CallType) => {
    const descriptor = getCallTypeDescriptor(callType);
    return <FormattedMessage {...descriptor} />;
  };

  return (
    <ListItem
      classes={{
        root: classes.listItem,
        gutters: classes.listItemGutters,
        button: classes.listItemButton,
      }}
      key={call.id}
      divider={false}
      button
      onClick={() => moveToCallEnded(call)}
      style={{
        ...style,
        height: style?.height ? -spacing[3] : 0,
      }}
      ContainerComponent="div"
    >
      <Box className={classes.listItemContainer}>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
          }}
          primary={
            <Typography tag="p" variant="body-small">
              <Tooltip arrow title={contactDisplay}>
                <div className={classes.listItemPrimaryText}>{contactDisplay}</div>
              </Tooltip>
              <Tooltip arrow title={phoneNumber}>
                <div className={classes.listItemPhoneText}>{phoneNumber}</div>
              </Tooltip>
              <div className={classes.listItemCallType}>
                <CallTypeIcon type={call.type} />
                {renderCallType(call.type)}
              </div>
              <div className={classes.listItemDateAndDuration}>
                <span>{getCallCreationDateAndTime(call, language)}</span>
                <span className={classes.duration}>{getDurationDisplay(call)}</span>
              </div>
            </Typography>
          }
        />
        <ListItemSecondaryAction className={classes.callbackButtonAction}>
          {renderCallButton(call)}
        </ListItemSecondaryAction>
      </Box>
    </ListItem>
  );
};
