import { ServiceTicketActions, ServiceTicketActionsType } from './serviceTicket.action';

export interface ServiceTicketState {
  isServiceTicketSaving: boolean;
}

export const INITIAL_STATE: ServiceTicketState = {
  isServiceTicketSaving: false,
};

export const connectWiseServiceTicketReducer = (
  state = INITIAL_STATE,
  action: ServiceTicketActions,
): ServiceTicketState => {
  switch (action.type) {
    case ServiceTicketActionsType.SAVE_SERVICE_TICKET_REQUEST:
      return {
        ...state,
        isServiceTicketSaving: true,
      };
    case ServiceTicketActionsType.SAVE_SERVICE_TICKET_SUCCESS:
    case ServiceTicketActionsType.SAVE_SERVICE_TICKET_ERROR:
      return {
        ...state,
        isServiceTicketSaving: false,
      };
    default:
      return state;
  }
};
