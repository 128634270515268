import { Button } from '@getgo/chameleon-material-ui';
import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AuthButton } from '../authentication/AuthButton.container';
import { seeLicenseWarning } from '../salesforce/salesforce.actions';
import { ErrorPage } from './ErrorPage.component';
import { newTracker } from '../analytics-new/tracker-new';
import { AnalyticsAction, AnalyticsCategory } from '../analytics-new/analytics.models';
import { useEntitlements } from '../authentication/useEntitlements';
import { FormattedMessageWithMessageValues } from '../reactInltModels';
import { Link } from '@getgo/chameleon-web/react';

const definedMessages = defineMessages({
  NO_LICENSE_TITLE: {
    id: 'NoLicense.Title',
    defaultMessage: 'No License',
  },
  NO_LICENSE_DESCRIPTION: {
    id: 'NoLicense.Description',
    defaultMessage: 'To use all of GoTo, {link} about your account.',
  },
  NO_LICENSE_GOTOCONNECT_TITLE: {
    id: 'NoLicense.GoToConnect.Title',
    defaultMessage: 'GoToConnect license required',
  },
  NO_LICENSE_GOTOCONNECT_DESCRIPTION: {
    id: 'NoLicense.GoToConnect.Description',
    defaultMessage: 'To use GoToConnect for Salesforce, {link} about a license',
  },
  NO_LICENSE_GOTOMEETING_TITLE: {
    id: 'NoLicense.GoToMeeting.Title',
    defaultMessage: 'Want to have a meeting?',
  },
  NO_LICENSE_GOTOMEETING_DESCRIPTION: {
    id: 'NoLicense.GoToMeeting.Description',
    defaultMessage: 'To set up meetings with GoToConnect for Salesforce, {link} about a meeting license',
  },
  NO_LICENSE_SKIP_BUTTON: {
    id: 'NoLicense.Skip',
    defaultMessage: 'Thanks, got it',
  },
  NO_LICENSE_SALES_LINK: {
    id: 'NoLicense.SalesLink',
    defaultMessage: 'contact our sales team',
  },
});

const HardError: React.FC<{
  title: FormattedMessageWithMessageValues;
  message: FormattedMessageWithMessageValues;
}> = ({ title, message }) => {
  return (
    <ErrorPage title={title} message={message}>
      <Box mt={4}>
        <AuthButton />
      </Box>
    </ErrorPage>
  );
};

const SoftError: React.FC<{
  title: FormattedMessageWithMessageValues;
  message: FormattedMessageWithMessageValues;
}> = ({ title, message }) => {
  const dispatch = useDispatch();

  return (
    <ErrorPage title={title} message={message}>
      <Box mt={4}>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(seeLicenseWarning());
          }}
        >
          <FormattedMessage {...definedMessages.NO_LICENSE_SKIP_BUTTON} />
        </Button>
      </Box>
    </ErrorPage>
  );
};

const SalesLink: React.FC = () => {
  return (
    <Link
      href="https://www.goto.com/connect/try?utm_source=goto-integration&utm_medium=website-link&utm_campaign=unified-salesforce-extension&utm_content=mainpage"
      target="_blank"
    >
      <FormattedMessage {...definedMessages.NO_LICENSE_SALES_LINK} />
    </Link>
  );
};

export const NoLicenseErrorPage: React.FC = () => {
  const { hasGTCLicense, hasGTMLicense, hasNoLicense } = useEntitlements();

  useEffect(() => {
    if (hasGTMLicense && hasGTCLicense) {
      return;
    }

    newTracker.trackAnalyticsEvent({
      action: AnalyticsAction.PageLoaded,
      category: AnalyticsCategory.Configuration,
      label: hasNoLicense ? 'no license' : !hasGTMLicense ? 'no gtm license' : 'no gtc license',
    });
  }, [hasGTCLicense, hasGTMLicense, hasNoLicense]);

  if (hasNoLicense) {
    return (
      <HardError
        title={definedMessages.NO_LICENSE_TITLE}
        message={{
          ...definedMessages.NO_LICENSE_DESCRIPTION,
          values: {
            link: <SalesLink />,
          },
        }}
      />
    );
  }

  if (!hasGTCLicense) {
    return (
      <HardError
        title={definedMessages.NO_LICENSE_GOTOCONNECT_TITLE}
        message={{
          ...definedMessages.NO_LICENSE_GOTOCONNECT_DESCRIPTION,
          values: {
            link: <SalesLink />,
          },
        }}
      />
    );
  }

  if (!hasGTMLicense) {
    return (
      <SoftError
        title={definedMessages.NO_LICENSE_GOTOMEETING_TITLE}
        message={{
          ...definedMessages.NO_LICENSE_GOTOMEETING_DESCRIPTION,
          values: {
            link: <SalesLink />,
          },
        }}
      />
    );
  }

  return null;
};
