import { Button, ButtonProps } from '@getgo/chameleon-web/react';
import React, { MouseEventHandler } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';

const definedMessages = defineMessages({
  SIGN_IN: {
    id: 'SignIn.Button',
    defaultMessage: `Sign in`,
  },
  SIGN_OUT: {
    id: 'SignOut.Button',
    defaultMessage: `Sign out`,
  },
});

const trackingEvents = defineTrackingEvents({
  SIGN_IN_BUTTON: {
    category: AnalyticsCategory.Authentication,
    action: AnalyticsAction.ItemClicked,
    label: 'Sign in | button',
  },
});

export interface AuthButtonStateProps {
  isAuthenticated: boolean;
}

export interface AuthButtonDispatchProps {
  signIn: MouseEventHandler<HTMLButtonElement>;
  signOut: MouseEventHandler<HTMLButtonElement>;
}

export type AuthButtonProps = ButtonProps;

export const AuthButtonComponent: React.FC<AuthButtonProps & AuthButtonStateProps & AuthButtonDispatchProps> = ({
  isAuthenticated,
  signIn,
  signOut,
  color = 'primary',
  onClick,
  ...buttonProps
}) => {
  const onClickHandler = (event) => {
    if (isAuthenticated) {
      signOut(event);
    } else {
      newTracker.trackAnalyticsEvent(trackingEvents.SIGN_IN_BUTTON);
      signIn(event);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return isAuthenticated ? (
    <Button variant="secondary" fullwidth color={color} onClick={onClickHandler} {...buttonProps}>
      <FormattedMessage {...definedMessages.SIGN_OUT} />
    </Button>
  ) : (
    <Button variant="primary" fullwidth color={color} onClick={onClickHandler} {...buttonProps}>
      <FormattedMessage {...definedMessages.SIGN_IN} />
    </Button>
  );
};
