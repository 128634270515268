import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { allCallSelector, callSelector } from '../calls/calls.selector';
import { AppState } from '../reducers';
import { createEntity, CreateEntityActionPayload } from '../search/entities.action';
import { isCreateEntityLoadingSelector } from '../search/entities.selector';
import NewContactForm, {
  NewContactFormDispatchProps,
  NewContactFormStateProps,
  NewContactFormProps,
} from './NewContactForm.component';

const mapStateToProps = (state: AppState, { callId }: NewContactFormProps): NewContactFormStateProps => {
  const activeCall = callSelector(state, callId);

  return {
    isCreateEntityLoading: isCreateEntityLoadingSelector(state),
    call: allCallSelector(state, callId),
    isActiveCall: Boolean(activeCall && !activeCall.endTime),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AppAction>) => ({
  createContact: (payload: CreateEntityActionPayload) => dispatch(createEntity(payload)),
});

export default connect<NewContactFormStateProps, NewContactFormDispatchProps, NewContactFormProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(NewContactForm);
