import { AuthenticationActions, AuthenticationActionsType } from '../authentication/authentication.actions';
import { SettingsActionTypes } from '../constants';
import { CoxSettings } from '../cox/settings/cox.settings.models';
import { MsTeamsAuthenticationActions } from '../msteams/auth/authentication.actions';
import { MsTeamsAuthenticationActionsType } from '../msteams/constants';
import { SettingsActions } from './settings.action';
import { ZendeskSettings } from '../zendesk/settings/zendesk.settings.models';
import { settingsStateLoaded } from './settings.persistence.action';
import { ActionTypes } from '../actions/actionCreators';

export interface SettingsState {
  selectedLine: string;
  selectedDevice: string;
  selectedLanguage: string;
  isAutomaticCallLoggingEnabled: boolean;
  isOpenCallLogAfterCallEnabled: boolean;
  isShowCallDispositionFormEnabled: boolean;
  isOpportunitiesEnabled: boolean;
  isCasesEnabled: boolean;
  isOpenContactInNewTabEnabled: boolean;
  isSoftphoneEnabled?: boolean;
  hasSeenSoftphoneSettings?: boolean;
  cox?: CoxSettings;
  zendesk?: ZendeskSettings;
  isWidgetAutoPopupEnabled: boolean;
}

export const COX_INITIAL_SETTINGS: CoxSettings = {
  dealerTrackEntities: {
    parts: {
      show: true,
      expand: false,
    },
    services: {
      show: true,
      expand: false,
    },
    history: {
      show: true,
      expand: false,
    },
  },
};

export const ZENDESK_INITIAL_SETTING: ZendeskSettings = {
  isAutomaticCallLoggingEnabled: false,
};

export const SETTINGS_INITIAL_STATE: SettingsState = {
  selectedLine: '',
  selectedDevice: '',
  selectedLanguage: 'en-US',
  isAutomaticCallLoggingEnabled: true,
  isOpenCallLogAfterCallEnabled: false,
  isShowCallDispositionFormEnabled: false,
  isOpportunitiesEnabled: true,
  isCasesEnabled: true,
  isOpenContactInNewTabEnabled: false,
  isSoftphoneEnabled: false,
  hasSeenSoftphoneSettings: false,
  isWidgetAutoPopupEnabled: true,
  cox: COX_INITIAL_SETTINGS,
  zendesk: ZENDESK_INITIAL_SETTING,
};
const settingPersistenceActions = {
  settingsStateLoaded,
};
export const settingsReducer = (
  state = SETTINGS_INITIAL_STATE,
  action:
    | SettingsActions
    | AuthenticationActions
    | MsTeamsAuthenticationActions
    | ActionTypes<typeof settingPersistenceActions>,
) => {
  switch (action.type) {
    case AuthenticationActionsType.LOGOUT_SUCCESS:
    case MsTeamsAuthenticationActionsType.SIGNOUT_SUCCESS: {
      // Clear the personal settings when the user logs out.
      // Not done with listeners to ensure that the analytics middleware does not log personal data.
      return {
        ...SETTINGS_INITIAL_STATE,
        hasSeenSoftphoneSettings: state.hasSeenSoftphoneSettings,
      };
    }
    case SettingsActionTypes.OPEN_SETTINGS_PAGE: {
      return {
        ...state,
        hasSeenSoftphoneSettings: true,
      };
    }
    case SettingsActionTypes.SELECT_LINE: {
      return {
        ...state,
        selectedLine: action.payload,
      };
    }
    case SettingsActionTypes.SELECT_DEVICE: {
      return {
        ...state,
        selectedDevice: action.payload.deviceId,
        selectedLine: action.payload.lineId,
      };
    }
    case SettingsActionTypes.SELECT_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    }
    case SettingsActionTypes.SET_AUTOMATIC_CALL_LOGGING: {
      return {
        ...state,
        isAutomaticCallLoggingEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_OPEN_CALL_LOG_AFTER_CALL: {
      return {
        ...state,
        isOpenCallLogAfterCallEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_CALL_DISPOSITION_FORM: {
      return {
        ...state,
        isShowCallDispositionFormEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_OPPORTUNITIES: {
      return {
        ...state,
        isOpportunitiesEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_CASES: {
      return {
        ...state,
        isCasesEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_OPEN_CONTACT_IN_NEW_TAB: {
      return {
        ...state,
        isOpenContactInNewTabEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_SOFTPHONE_ENABLED: {
      return {
        ...state,
        isSoftphoneEnabled: action.payload,
      };
    }
    case SettingsActionTypes.SET_DEALERTRACK_ENTITIES: {
      return {
        ...state,
        cox: {
          ...state.cox,
          dealerTrackEntities: action.payload,
        },
      };
    }
    case SettingsActionTypes.SET_ZENDESK_SETTINGS: {
      return {
        ...state,
        zendesk: {
          ...state.zendesk,
          ...action.payload,
        },
      };
    }

    case SettingsActionTypes.SET_WIDGET_AUTO_POPUP: {
      return {
        ...state,
        isWidgetAutoPopupEnabled: action.payload,
      };
    }

    case 'settingState/loaded': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
