import { LoadVehiclesRequestState, Vehicle } from './vehicles.models';
import { VehiclesActions, VehiclesActionsType } from './vehicles.actions';

export interface VehiclesState {
  loadRequestState: LoadVehiclesRequestState;
  vehicles: Vehicle[];
}

export const INITIAL_STATE: VehiclesState = {
  loadRequestState: LoadVehiclesRequestState.INITIAL,
  vehicles: [],
};

export const vehiclesReducer = (state: VehiclesState = INITIAL_STATE, action: VehiclesActions): VehiclesState => {
  switch (action.type) {
    case VehiclesActionsType.LOAD_VEHICLES: {
      return {
        ...state,
        vehicles: [],
        loadRequestState: LoadVehiclesRequestState.LOADING,
      };
    }
    case VehiclesActionsType.LOAD_VEHICLES_SUCCESS: {
      return {
        ...state,
        loadRequestState: LoadVehiclesRequestState.LOADED,
        vehicles: action.payload,
      };
    }
    case VehiclesActionsType.LOAD_VEHICLES_ERROR: {
      return {
        ...state,
        loadRequestState: LoadVehiclesRequestState.FAILED,
        vehicles: [],
      };
    }
    default:
      return state;
  }
};
