import { tokenSelector } from '../authentication/authentication.selector';
import { SettingsActionTypes } from '../constants';
import { PbxesEntityActionsType } from '../jiveEntities/pbxesEntity.action';
import { selectedPbxSelector, selectedPbxUserIdSelector } from '../jiveEntities/pbxesEntity.selector';
import { addListener } from '../middlewares/listener.middleware';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import {
  AmbassadorActionsTypes,
  getAmbassadorProviders,
  getAmbassadorProvidersError,
  getAmbassadorProvidersSuccess,
} from './ambassador.action';
import { AmbassadorService } from './ambassador.service';

addListener(PbxesEntityActionsType.ADD)(async (_, __) => {
  return getAmbassadorProviders();
});

addListener(SettingsActionTypes.SELECT_LINE)(async (state, __) => {
  const pbx = selectedPbxSelector(state);
  if (!pbx) {
    return;
  }
  return getAmbassadorProviders();
});

addListener(AmbassadorActionsTypes.GET_PROVIDERS)(async (state, __) => {
  try {
    const token = tokenSelector(state);
    const organizationId = selectedOrganizationIdSelector(state);
    const userId = selectedPbxUserIdSelector(state);

    if (!userId || !organizationId || !token) {
      return getAmbassadorProvidersError();
    }

    const ambassadorProviderResponses = await Promise.all([
      AmbassadorService.getProviders(token, organizationId),
      AmbassadorService.getUserProviders(token, userId, organizationId),
    ]);
    return getAmbassadorProvidersSuccess({
      providers: ambassadorProviderResponses[0],
      userProviders: ambassadorProviderResponses[1],
    });
  } catch (e) {
    return getAmbassadorProvidersError();
  }
});
