import { defineMessages } from 'react-intl';
import config from '../config';
import { Integrations } from '../models';

export const definedMessages = defineMessages({
  OPEN_IN_CRM: {
    id: 'SearchResult.OpenInCRM',
    defaultMessage: 'Open contact in CRM',
    description: 'Open contact in the CRM',
  },
});

export const canOpenContact = (integration?: Integrations) => {
  return (
    integration === undefined ||
    config[integration]?.canOpenContact === undefined ||
    config[integration].canOpenContact === true
  );
};
