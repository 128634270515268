import { withStyles, WithStyles, createStyles, Box } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { PersonInactiveIcon } from '@getgo/chameleon-icons/react';
import React from 'react';
import ContextMenu from '../components/ContextMenu';
import { AssignToCallContextMenuItem } from '../entityActions/AssignToCallContextMenuItem.component';
import OpenEntityContextMenuItem from '../entityActions/OpenEntityContextMenuItem.container';
import { Entity } from '../models';
import { EntityPhoneNumber } from './entities.model';
import { SearchResultType } from './SearchResult.model';
import SearchResultPhoneNumbers from './SearchResultPhoneNumbers.container';
import { SearchResultTile } from './SearchResultTile.component';
import { PhoneUpDisabledInactiveIcon } from '@getgo/chameleon-icons/react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AnalyticsSection } from '../entityActions/OpenEntityContextMenuItem.component';
import { CallType } from '@jive/realtime-events';
import { Typography } from '../theme/Typography.component';
import { Tooltip } from '@getgo/chameleon-web/react';
import { TooltipPosition } from '@getgo/chameleon-web';

const definedMessages = defineMessages({
  DO_NOT_CALL_TOOLTIP: {
    id: 'Call.DoNotCall.Tooltip',
    defaultMessage: 'Do not call!',
  },
});

export const SearchResultStyles = () =>
  createStyles({
    selected: {
      backgroundColor: tokens.inverseFocus,
    },
    label: {
      flex: 1,
    },
    contextMenuIcon: {
      padding: '3px',
      marginRight: '5px',
    },
  });

export interface SearchResultProps {
  entity: Entity;
  type: SearchResultType;
  callType?: CallType;
  analyticsSection?: AnalyticsSection;
}

export interface SearchResultStateProps {
  isEntitySelected: boolean;
  phoneNumbers: EntityPhoneNumber[];
}

export interface SearchResultDispatchProps {
  selectEntity: () => void;
}

const SearchResult: React.FC<
  SearchResultProps & SearchResultStateProps & SearchResultDispatchProps & WithStyles<typeof SearchResultStyles>
> = ({ entity, classes, selectEntity, isEntitySelected, type, phoneNumbers, callType, analyticsSection }) => {
  function renderContextMenu() {
    switch (type) {
      case SearchResultType.DialPad:
        return (
          <ContextMenu iconClass={classes.contextMenuIcon} moreButtonLabel="More Options">
            <OpenEntityContextMenuItem entity={entity} />
          </ContextMenu>
        );
      case SearchResultType.Call:
        return (
          <ContextMenu iconClass={classes.contextMenuIcon} moreButtonLabel="More Options">
            {isEntitySelected ? undefined : <AssignToCallContextMenuItem entity={entity} />}
            <OpenEntityContextMenuItem entity={entity} callType={callType} analyticsSection={analyticsSection} />
          </ContextMenu>
        );
      default:
        throw new Error(`Unsupported SearchResultType: ${type}`);
    }
  }

  function isEntitySelectionEnabled() {
    return type === SearchResultType.DialPad;
  }

  const clickHandler = isEntitySelectionEnabled() ? selectEntity : undefined;
  const showAsSelected = isEntitySelected && type === SearchResultType.Call;

  return (
    <>
      <SearchResultTile onClick={clickHandler} isSelected={showAsSelected}>
        <Box m={2}>
          <PersonInactiveIcon size="18px" color={showAsSelected ? tokens.text04 : tokens.text02} />
        </Box>
        {entity.doNotCall ? (
          <Tooltip label={<FormattedMessage {...definedMessages.DO_NOT_CALL_TOOLTIP} />} position={TooltipPosition.top}>
            <Box pt={2} pb={2} pr={2}>
              <PhoneUpDisabledInactiveIcon size="18px" color="var(--goto-icon-color-danger)" />
            </Box>
          </Tooltip>
        ) : null}
        <Tooltip className={classes.label} label={entity.name} position={TooltipPosition.top}>
          <Typography lineClamp={1} variant="body-medium">
            {entity.label}
          </Typography>
        </Tooltip>
        {renderContextMenu()}
      </SearchResultTile>
      {isEntitySelectionEnabled() && isEntitySelected ? (
        <SearchResultPhoneNumbers entityId={entity.id} phoneNumbers={phoneNumbers} />
      ) : null}
    </>
  );
};
export default withStyles(SearchResultStyles)(SearchResult);
