import { SoftphoneActionCreator } from '../softphone/softphone.actioncreator';
import { ZendeskService } from './zendesk.service';

export class ZendeskSoftphoneActionCreator extends SoftphoneActionCreator {
  constructor(protected zendeskService: ZendeskService) {
    super();
  }

  async openMicrophonePermissionPage(): Promise<void> {
    return this.zendeskService.showMicrophonePermissionModal();
  }
}
