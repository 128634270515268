import { MatterActions, MattersActionsType } from './matter.actions';
import { GetMattersRequestState, MattersState } from './matter.models';

export const INITIAL_STATE: MattersState = {
  requestState: GetMattersRequestState.INITIAL,
  matters: [],
};

export const mattersReducer = (state: MattersState = INITIAL_STATE, action: MatterActions): MattersState => {
  switch (action.type) {
    case MattersActionsType.SEARCH_MATTERS:
      return {
        ...state,
        matters: [],
        requestState: GetMattersRequestState.LOADING,
      };

    case MattersActionsType.SEARCH_MATTERS_SUCCESS:
      return {
        ...state,
        requestState: GetMattersRequestState.LOADED,
        matters: action.payload.matters,
      };

    case MattersActionsType.SEARCH_MATTERS_ERROR:
      return {
        ...state,
        matters: [],
        requestState: GetMattersRequestState.FAILED,
      };
    case MattersActionsType.CLEAR_MATTERS:
      return {
        ...state,
        matters: [],
        requestState: GetMattersRequestState.INITIAL,
      };
    default:
      return state;
  }
};
