import React, { useState } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import {
  AccordionDetails as MuiAccordionDetails,
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

export const AppAccordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderTop: '1px solid #E2E2E2',
    marginBottom: -1,
    minHeight: 56,
    padding: '0',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
    margin: '16px 0',
  },
  expandIcon: {
    borderRadius: '50%',
    marginRight: 0, // keep this on 0, will be rotated
    padding: 0, // keep this on 0, will be rotated
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(
  createStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(0, 0, 3, 0),
    },
  })),
)(MuiAccordionDetails);

export interface AccordionComponentProps {
  isExpandedDefault: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  panelProps?: AccordionProps;
  summary: React.ReactElement;
  body: React.ReactElement;
}

export const Accordion: React.FC<AccordionComponentProps> = ({
  isExpandedDefault,
  onExpand,
  onCollapse,
  panelProps,
  summary,
  body,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);

  return (
    <AppAccordion
      {...panelProps}
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
        if (!isExpanded && onExpand) {
          onExpand();
        } else if (isExpanded && onCollapse) {
          onCollapse();
        }
      }}
    >
      {summary}
      <AccordionDetails>
        <>{isExpanded ? body : null}</>
      </AccordionDetails>
    </AppAccordion>
  );
};
