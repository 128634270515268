import { createStyles, makeStyles } from '@material-ui/core/styles';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { ApplicationRouteType } from '../Main.component';
import ContextMenu from '../components/ContextMenu';
import { ContextMenuLinkItem } from '../components/ContextMenuLinkItem.component';
import { NavigationRail } from './NavigationRail.component';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexDirection: 'row',
      display: 'flex',
      overflow: 'hidden',
      backgroundColor: tokens.surfaceColorInverted,
      border: 'none',
      '--goto-icon-size': '24px',
    },
    contextMenu: {
      marginLeft: '-2px',
    },
    moreIcon: {
      '--goto-icon-color': tokens.icon02,
    },
  }),
);

export interface BottomMenuProps {
  primaryRoutes: ApplicationRouteType[];
  secondaryRoutes: ApplicationRouteType[];
  disabled?: boolean;
  renderedPath: string;
}

export const BottomMenu: React.FC<BottomMenuProps> = ({ primaryRoutes, secondaryRoutes, disabled, renderedPath }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-cy="bottom-menu-bar">
      <NavigationRail renderedPath={renderedPath} orientation="horizontal" routes={primaryRoutes} disabled={disabled} />
      <ContextMenu
        buttonSize="large"
        menuClass={classes.contextMenu}
        iconClass={classes.moreIcon}
        moreButtonLabel="More Pages"
      >
        {secondaryRoutes.map((route) => (
          <ContextMenuLinkItem
            key={route.path}
            linkToOpen={route.path}
            isExternal={route.isExternal}
            leadingIcon={route.icon()}
          >
            <FormattedMessage {...route.description} />
          </ContextMenuLinkItem>
        ))}
      </ContextMenu>
    </div>
  );
};
