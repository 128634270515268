import { defineMessages } from '@formatjs/intl';
import { Store } from 'redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { closeDialog, openDialog } from '../dialog/dialog.actions';
import { isDialogOpenSelector } from '../dialog/dialog.selector';
import { AppState } from '../reducers';
import { isSoftphoneCapableAndEnabledSelector } from '../settings/settings.selector';
import { openGoToConnect, setPresence } from './presence.action';
import { PresenceAppearance } from './presence.models';
import { dismissPresenceOfflinePopup, isOffline, shouldDisplayPresenceOfflinePopup } from './presence.utils';

const definedMessages = defineMessages({
  TITLE: {
    id: 'Presence.Offline.Dialog.Title',
    defaultMessage: "Couldn't detect a phone system",
  },
  BODY: {
    id: 'Presence.Offline.Dialog.Body',
    defaultMessage:
      'Please check that your GoToConnect phone app is running or your GoToConnect Desktop Phone is working.',
  },
  OPEN_APP_BUTTON_LABEL: {
    id: 'Open.App.Button.Label',
    defaultMessage: 'Open App',
  },
});

const trackingEvents = defineTrackingEvents({
  OPEN_APP_CLICKED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: "Open app on the Couldn't detect a phone system popup | button",
  },
  CLOSE_CLICKED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.ItemClicked,
    label: "Couldn't detect a phone system closed by X button | button",
  },
  POPUP_SHOWN: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PopupShown,
    label: "Couldn't detect a phone system",
  },
  POPUP_DISAPPEARED: {
    category: AnalyticsCategory.Application,
    action: AnalyticsAction.PopupDisappeared,
    label: "Couldn't detect a phone system | Phone system detected",
  },
});

export class PresenceActionCreator {
  private readonly DIALOG_ID = 'presence_offline_dialog';

  constructor(protected store: Store<AppState>) {}

  public async setPresence(appearance: PresenceAppearance): Promise<void> {
    this.store.dispatch(setPresence({ appearance }));
    if (isOffline(appearance)) {
      const isSoftphoneEnabled = isSoftphoneCapableAndEnabledSelector(this.store.getState());
      if (!isSoftphoneEnabled && shouldDisplayPresenceOfflinePopup()) {
        this.displayPresenceOfflinePopup();
      }
    } else {
      const isDialogOpen = isDialogOpenSelector(this.store.getState(), this.DIALOG_ID);
      if (isDialogOpen) {
        this.hidePresenceOfflinePopup();
      }
    }
  }

  protected displayPresenceOfflinePopup() {
    newTracker.trackAnalyticsEvent(trackingEvents.POPUP_SHOWN);
    this.store.dispatch(
      openDialog({
        dialogId: this.DIALOG_ID,
        texts: {
          title: definedMessages.TITLE,
          body: definedMessages.BODY,
          confirm: definedMessages.OPEN_APP_BUTTON_LABEL,
        },
        hideCancelButton: true,
        confirmAction: openGoToConnect(),
        confirmTrackingEvent: trackingEvents.OPEN_APP_CLICKED,
        cancelTrackingEvent: trackingEvents.CLOSE_CLICKED,
        closeCallback: ({ isConfirmed }) => {
          if (!isConfirmed) {
            dismissPresenceOfflinePopup();
          }
        },
      }),
    );
  }

  protected hidePresenceOfflinePopup() {
    newTracker.trackAnalyticsEvent(trackingEvents.POPUP_DISAPPEARED);
    this.store.dispatch(closeDialog({ dialogId: this.DIALOG_ID }));
  }
}
