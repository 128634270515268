import React, { FC, useEffect, useState } from 'react';
import { loadTranslation } from './i18n-messages';
import { FullPageAppLoading } from '../components/FullPageAppLoading';
import { useSelector } from 'react-redux';
import { selectedLanguageSelector } from '../settings/settings.selector';

export const TranslationLoader: FC = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedLocale = useSelector(selectedLanguageSelector);

  useEffect(() => {
    if (!selectedLocale) {
      return;
    }
    setIsLoaded(false);
    void loadTranslation(selectedLocale).finally(() => {
      setIsLoaded(true);
      document.documentElement.lang = selectedLocale;
    });
  }, [selectedLocale]);

  if (!isLoaded) {
    return <FullPageAppLoading />;
  }
  return <>{children}</>;
};
