import { createSelector } from 'reselect';
import { Entity } from '../models';
import { AppState } from '../reducers';
import { EntitiesState } from './entities.reducer';

export const entitiesResourceSelector = (state: AppState) => state.entities;

export const entitiesIsLoadingSelector = createSelector(
  entitiesResourceSelector,
  (entities) => entities.isLoading && !entities.phoneNumbers.isLoading,
);

export const entityPhoneNumberIsLoadingSelector = createSelector(
  entitiesResourceSelector,
  (entities) => entities.phoneNumbers.isLoading,
);

export const entityErrorMessageSelector = createSelector(entitiesResourceSelector, (entities) => entities.errorMessage);

export const entityPhoneNumberErrorMessageSelector = createSelector(
  entitiesResourceSelector,
  (entities) => entities.phoneNumbers.errorMessage,
);

export const entitySearchQuerySelector = createSelector(entitiesResourceSelector, (entities) => entities.result.query);

export const entitiesResultSelector = (state: AppState): EntitiesState => state.entities.result;

export const entitiesSelector = createSelector<AppState, EntitiesState, Entity[] | undefined>(
  entitiesResultSelector,
  (result: EntitiesState): Entity[] | undefined => result.entities,
);

export const searchQuerySelector = createSelector<AppState, EntitiesState, string>(
  entitiesResultSelector,
  (result) => result.query,
);

export const entitySelector = (entityId: string) =>
  createSelector(entitiesResultSelector, (result) => result.byId[entityId] || {});

export const entityPhoneNumberSelector = (entityId: string) =>
  createSelector(entitySelector(entityId), (entity) => entity.phoneNumber || '');

export const isCreateEntityLoadingSelector = createSelector(
  entitiesResourceSelector,
  (entities) => entities.result.createEntityIsLoading,
);
