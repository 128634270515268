import { IDBPDatabase, openDB } from 'idb';
import { logger } from '../logging';
import { uuid } from '../uuid';

const DB_NAME = 'GOTOCONNECT_EMBEDDED_INTEGRATIONS';
const VERSION = 3;

export const ObjectStores = {
  CALL_LOGGING: 'callLogging',
  SYNC_TRACKING: 'syncTracking',
  APP_OPTIONS: 'appOptions',
} as const; // for typing

export type ObjectStoreNames = typeof ObjectStores[keyof typeof ObjectStores];

class StorageService {
  private db?: Promise<IDBPDatabase<any>>;

  init() {
    this.db = openDB(DB_NAME, VERSION, {
      upgrade(db, oldversion) {
        // !IMPORTANT: do not add break statements here. The point is to do all necessary changes between version
        // ex: new version is #4 and the user has version #1 of the database. We need to run all migration scripts between #1 and #4.
        switch (oldversion) {
          case 0:
            db.createObjectStore(ObjectStores.CALL_LOGGING, {
              keyPath: 'id',
            });
          // eslint-disable-next-line no-fallthrough
          case 1:
            db.createObjectStore(ObjectStores.SYNC_TRACKING, {
              keyPath: 'id',
            });
          // eslint-disable-next-line no-fallthrough
          case 2:
            db.createObjectStore(ObjectStores.APP_OPTIONS);
          // eslint-disable-next-line no-fallthrough
          case 3:
            break;
          default:
            logger.error(`Unknown indexedDB version: ${oldversion}`);
        }
      },
      blocked: () => {
        // seems an older version of this app is running in another tab.
        // upgrade will be called when all tabs using the old version reload or close
        console.warn('storage.service is in blocked state. Waiting for other tabs to close...');
      },
      blocking: () => {
        // seems the user just opened the app in a new tab where the db version is newer
        // we need to reload this tab to unblock the upgrade process
        window.location.reload();
      },
    });
  }

  async transaction(store: ObjectStoreNames, mode: IDBTransactionMode = 'readwrite') {
    const db = await this.db;
    if (!db) {
      throw Error('DB opening issues');
    }

    return db.transaction(store, mode);
  }

  async cursor(store: ObjectStoreNames, mode: IDBTransactionMode = 'readwrite') {
    const tx = await this.transaction(store, mode);
    return tx.store.openCursor();
  }

  async getOption<T = any>(key: string, defaultValue?: T): Promise<T> {
    const tx = await storageService.transaction(ObjectStores.APP_OPTIONS);
    const store = tx.objectStore(ObjectStores.APP_OPTIONS);
    return ((await store.get(key)) as Promise<T>) ?? defaultValue;
  }

  async setOption<T>(key: string, value: T): Promise<void> {
    const tx = await storageService.transaction(ObjectStores.APP_OPTIONS);
    const store = tx.objectStore(ObjectStores.APP_OPTIONS);
    await store.put(value, key);
  }

  async isAvailable(): Promise<boolean> {
    const isSupportedBrowser = !/Safari/i.test(navigator.userAgent) || /Chrome/i.test(navigator.userAgent);
    if (!isSupportedBrowser) {
      return false;
    }

    const isApiAvailable = !!window.indexedDB;
    if (!isApiAvailable) {
      return false;
    }

    try {
      const tx = await storageService.transaction(ObjectStores.SYNC_TRACKING);
      const storageId = `sync-service-test-${uuid()}`;
      await Promise.all([tx.store.add({ id: storageId }), tx.store.delete(storageId), tx.done]);
      return true;
    } catch (e) {
      logger.error('Error in StorageService.isAvailable', e);
      return false;
    }
  }
}

export const storageService = new StorageService();
