import { makeActionCreator } from '../actions/actionCreators';
import { PresenceAppearance } from './presence.models';

export enum PresenceActionType {
  SET = 'presence/set',
  OPEN_GTC = 'presence/openGoToConnect',
}

export interface SetPresencePayload {
  appearance?: PresenceAppearance;
}

export const setPresence = makeActionCreator(PresenceActionType.SET)<SetPresencePayload>();
export const openGoToConnect = makeActionCreator(PresenceActionType.OPEN_GTC)();

export type PresenceActions = ReturnType<typeof setPresence> | ReturnType<typeof openGoToConnect>;
