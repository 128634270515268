import { Box, makeStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CallDispositionValue } from '../../../../salesforce-shared/salesforce-service.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../../analytics-new/analytics.models';
import { Typography } from '../../theme/Typography.component';
import { Autocomplete } from '@getgo/chameleon-material-ui';

const useStyles = makeStyles(() => ({
  autoComplete: {
    background: tokens.uiBackground,
  },
}));

const definedMessages = defineMessages({
  PLACEHOLDER: {
    id: 'Salesforce.CallDisposition.Picker.Placeholder',
    defaultMessage: 'None',
  },
  LABEL: {
    id: 'Salesforce.CallDisposition.Picker.Label',
    defaultMessage: 'Call disposition',
  },
  NO_OPTIONS_FOUND: {
    id: 'Salesforce.CallDisposition.Picker.Empty',
    defaultMessage: 'No call disposition found',
  },
  HINT_TEXT: {
    id: 'Salesforce.CallDisposition.Picker.HintText',
    defaultMessage: 'Select the outcome of your call:',
  },
});

const trackingEvents = defineTrackingEvents({
  CALL_DISPOSITION_VALUE_CHANGED: {
    category: AnalyticsCategory.CallLog,
    action: AnalyticsAction.ItemChanged,
    label: 'Disposition type | select | $VALUE',
  },
});

export interface CallDispositionPickerProps {
  selectedCallDisposition?: string;
  onChange: (value?: string) => void;
}

export interface CallDispositionPickerStateProps {
  isCallDispositionConfigured: boolean;
  callDispositionValues?: CallDispositionValue[];
}

export const CallDispositionPickerComponent: React.FC<CallDispositionPickerProps & CallDispositionPickerStateProps> = ({
  selectedCallDisposition,
  onChange,
  callDispositionValues,
  isCallDispositionConfigured,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  if (!isCallDispositionConfigured || !callDispositionValues) {
    return null;
  }

  const selectedValue = callDispositionValues.find((option) => option.value === selectedCallDisposition) ?? null;

  return (
    <>
      <Box mb={3}>
        <Typography>
          <b>
            <FormattedMessage {...definedMessages.HINT_TEXT} />
          </b>
        </Typography>
      </Box>
      <Autocomplete
        options={callDispositionValues}
        openOnFocus
        getOptionSelected={(option, selected) => option.value === selected.value}
        getOptionLabel={(option) => option.label}
        value={selectedValue}
        id="call-disposition-picker-values"
        onChange={(_, option) => {
          const trackingEvent = {
            ...trackingEvents.CALL_DISPOSITION_VALUE_CHANGED,
            label: trackingEvents.CALL_DISPOSITION_VALUE_CHANGED.label.replace('$VALUE', option?.label || ''),
          };
          newTracker.trackAnalyticsEvent(trackingEvent);
          onChange(option?.value);
        }}
        noOptionsText={<FormattedMessage {...definedMessages.NO_OPTIONS_FOUND} />}
        TextFieldProps={{
          noMargin: true,
          classes: {
            root: classes.autoComplete,
          },
          placeholder: intl.formatMessage(definedMessages.PLACEHOLDER),
          label: intl.formatMessage(definedMessages.LABEL),
        }}
      />
    </>
  );
};
