import Push, { PushNotification, PushNotificationParams } from 'push.js';
import { IntlShape } from 'react-intl';
import rebrandedLogo from '../assets/goto-black-yellow-favicon.png';
import { DesktopNotificationParams } from './desktopNotification.models';
/**
 * Creates a new desktop notification.
 * @param intl Localization utility.
 * @param desktopNotificationParams Configuration options for the new desktop notification.
 */
function show(intl: IntlShape, desktopNotificationParams: DesktopNotificationParams): Promise<void> {
  if (!intl) {
    throw new Error('Please specify the localization utility!');
  }

  if (!desktopNotificationParams) {
    throw new Error('Please specify the parameters for the new desktop notification!');
  }

  const localizedMessage: string =
    typeof desktopNotificationParams.message === 'string'
      ? desktopNotificationParams.message
      : intl.formatMessage(desktopNotificationParams.message);

  const localizedTitle: string = intl.formatMessage(desktopNotificationParams.title);

  const params: PushNotificationParams = {
    body: localizedMessage,
    icon: rebrandedLogo,
    tag: desktopNotificationParams.tag,
    requireInteraction: !!desktopNotificationParams.showUntilClicked,
    onClick(this: PushNotification) {
      window.parent.parent.focus();
      this.close();
    },
  };

  return Push.create(localizedTitle, params)
    .then((/* notification: PushNotification */) => {
      // Push.create returns a PushNotification object, but we don't want to expose it,
      // because it is specific to Push.js, so this hack ensures we return with Promise<void>.
      return;
    })
    .catch((e) => console.log(e));
}

/**
 * Hides all desktop notification toasts that has the specified tag value.
 * @param tag Value that identifies the notifications to close.
 */
function hide(tag: string): void {
  if (!tag) {
    throw new Error('Please specify the tag of the notifications to hide!');
  }

  Push.close(tag);
}

/**
 * Provides helper functions to manage operating system level notification toasts.
 */
export const DesktopNotification = {
  show,
  hide,
};
