import { debounce } from 'lodash-es';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { Dispatch } from 'redux';
import { makeActionCreator } from '../actions/actionCreators';
import { EntityType } from '../constants';
import { Entity, ErrorWithMessage, IndexedById, WithMessage } from '../models';
import { EntityPhoneNumber } from './entities.model';

export enum EntitiesActionsType {
  ENTITY_SEARCH = 'entity/search',
  ENTITY_SELECT = 'entity/select',
  ENTITY_CLEAR = 'entity/clear',
  ENTITY_SEARCH_SUCCESS = 'entity/searchSuccess',
  ENTITY_SEARCH_ERROR = 'entity/searchError',
  ENTITY_SEARCH_NOT_AUTHORIZED = 'entity/searchNotAuthorizedWarning',
  ENTITY_LOAD_PHONES = 'entity/loadPhone',
  ENTITY_LOAD_PHONES_REQUEST = 'entity/loadPhoneRequest',
  ENTITY_LOAD_PHONES_SUCCESS = 'entity/loadPhoneSuccess',
  ENTITY_LOAD_PHONES_ERROR = 'entity/loadPhoneError',
  ENTITY_LOAD_PHONES_NOT_AUTHORIZED = 'entity/loadPhoneNotAuthorizedWarning',
  OPEN_ENTITY_REQUEST = 'openEntity/openRequest',
  OPEN_ENTITY_ERROR = 'openEntity/openError',
  OPEN_ENTITY_SUCCESS = 'openEntity/openSuccess',
  OPEN_ENTITY_TAB_SUCCESS = 'openEntityTab/openSuccess',
  OPEN_ENTITY_TAB_ERROR = 'openEntityTab/openError',
  CREATE_CONTACT_SUCCESS = 'entity/createContactSuccess',
  CREATE_ENTITY_SUCCESS = 'entity/createSuccess',
  CREATE_ENTITY = 'create/entity',
  CREATE_ENTITY_ERROR = 'create/entity/createError',
  CREATE_ENTITY_FORM_OPENED = 'create/entity/formOpenSuccess',
}

export const definedMessages = defineMessages({
  ENTITIES_NOT_FOUND_ERROR: {
    id: 'Entities.NotFoundError',
    defaultMessage: 'Sorry, something went wrong while loading contacts. Please try again later.',
  },
  ENTITIES_SEARCH_NOT_AUTHORIZED: {
    id: 'Entities.SearchNotAuthorized',
    defaultMessage: 'You are not authorized to search for contacts.',
  },
  ENTITIES_PHONES_LOAD_ERROR: {
    id: 'Entities.PhoneNumbers.LoadError',
    defaultMessage: 'Sorry, something went wrong while loading contact phone numbers. Please try again later.',
  },
  ENTITIES_PHONES_LOAD_NOT_AUTHORIZED: {
    id: 'Entities.PhoneNumbers.LoadNotAuthorized',
    defaultMessage: 'You are not authorized to get phone numbers for this contact.',
  },
});

export interface OpenEntityPayload {
  entity: Entity;
}

export interface EntityResultsNotFoundPayload extends WithMessage {
  error?: any;
}

export const openEntity = makeActionCreator(EntitiesActionsType.OPEN_ENTITY_REQUEST)<OpenEntityPayload>();

export const openEntitySuccess = makeActionCreator(EntitiesActionsType.OPEN_ENTITY_SUCCESS)();

export const openEntityError = makeActionCreator(EntitiesActionsType.OPEN_ENTITY_ERROR)<WithMessage>();

export const openEntityTabSuccess = makeActionCreator(EntitiesActionsType.OPEN_ENTITY_TAB_SUCCESS)();

export const openEntityTabError = makeActionCreator(EntitiesActionsType.OPEN_ENTITY_TAB_ERROR)<WithMessage>();

export interface SearchEntityPayload {
  query: string;
}

export const searchEntity = makeActionCreator(EntitiesActionsType.ENTITY_SEARCH)<SearchEntityPayload>();

export const debouncedSearchEntity = debounce((dispatch: Dispatch, payload) => {
  return dispatch(searchEntity(payload));
}, 300); // TODO: Add UT for this actione

export const entitySearchSuccess = makeActionCreator(EntitiesActionsType.ENTITY_SEARCH_SUCCESS)<{
  results: Entity[];
}>();

export const entityResultsNotFound = (payload: { error?: any }) =>
  makeActionCreator(EntitiesActionsType.ENTITY_SEARCH_ERROR)<EntityResultsNotFoundPayload>()({
    error: payload.error,
    // This is probably not the best place to put the error message, but since this method is
    // used from multiple integration and it's kind of hard to test that everywhere I put it here.
    // Maybe we should consider finding a standard way to manage those cases.
    message: definedMessages.ENTITIES_NOT_FOUND_ERROR,
  });
export const entityResultsNotAuthorized = (payload: { error: any; message?: MessageDescriptor }) =>
  makeActionCreator(EntitiesActionsType.ENTITY_SEARCH_NOT_AUTHORIZED)<ErrorWithMessage>()({
    error: payload.error,
    message: payload.message || definedMessages.ENTITIES_SEARCH_NOT_AUTHORIZED,
    isTopLevel: false,
  });

export const loadEntityPhoneNumbersRequest = makeActionCreator(
  EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST,
)<string>();
export const loadEntityPhoneNumbersSuccess = makeActionCreator(EntitiesActionsType.ENTITY_LOAD_PHONES_SUCCESS)<{
  entityId: string;
  phoneNumbers: IndexedById<EntityPhoneNumber>;
}>();
export const loadEntityPhoneNumbersError = (error: any) =>
  makeActionCreator(EntitiesActionsType.ENTITY_LOAD_PHONES_ERROR)<ErrorWithMessage>()({
    error,
    message: definedMessages.ENTITIES_PHONES_LOAD_ERROR,
  });
export const loadEntityPhoneNumbersNotAuthorized = (error: any) =>
  makeActionCreator(EntitiesActionsType.ENTITY_LOAD_PHONES_NOT_AUTHORIZED)<ErrorWithMessage>()({
    error,
    message: definedMessages.ENTITIES_PHONES_LOAD_NOT_AUTHORIZED,
    isTopLevel: false,
  });

export const clearEntity = makeActionCreator(EntitiesActionsType.ENTITY_CLEAR)();

export interface CreateEntityActionPayload {
  isActiveCall: boolean;
  callId: string;
  entityName: EntityType;
  defaultFieldValues: {
    [id: string]: string;
  };
}

export interface CreateContactSuccessActionPayload {
  contactId: string;
  message: MessageDescriptor;
  entity: Entity;
  callId: string;
}

export interface CreateEntitySuccessActionPayload {
  message: MessageDescriptor;
  entity: Entity;
  callId: string;
}

export const createEntity = makeActionCreator(EntitiesActionsType.CREATE_ENTITY)<CreateEntityActionPayload>();
export const createEntityFormOpened = makeActionCreator(EntitiesActionsType.CREATE_ENTITY_FORM_OPENED)();
export const createEntityError = makeActionCreator(EntitiesActionsType.CREATE_ENTITY_ERROR)<{
  message: MessageDescriptor;
}>();
export const createContactSuccess = makeActionCreator(
  EntitiesActionsType.CREATE_CONTACT_SUCCESS,
)<CreateContactSuccessActionPayload>();
export const createEntitySuccess = makeActionCreator(
  EntitiesActionsType.CREATE_ENTITY_SUCCESS,
)<CreateEntitySuccessActionPayload>();

export type CreateEntityActionCreator = typeof createEntity;

export type EntitiesActions =
  | ReturnType<typeof searchEntity>
  | ReturnType<typeof openEntity>
  | ReturnType<typeof openEntityError>
  | ReturnType<typeof clearEntity>
  | ReturnType<typeof entitySearchSuccess>
  | ReturnType<typeof entityResultsNotFound>
  | ReturnType<typeof entityResultsNotAuthorized>
  | ReturnType<typeof loadEntityPhoneNumbersRequest>
  | ReturnType<typeof loadEntityPhoneNumbersSuccess>
  | ReturnType<typeof loadEntityPhoneNumbersError>
  | ReturnType<typeof loadEntityPhoneNumbersNotAuthorized>
  | ReturnType<CreateEntityActionCreator>
  | ReturnType<typeof createEntityFormOpened>
  | ReturnType<typeof createEntityError>
  | ReturnType<typeof createContactSuccess>
  | ReturnType<typeof createEntitySuccess>;
