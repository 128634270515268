import { ServiceAppointmentActions, ServiceAppointmentActionsType } from './serviceAppointments.actions';
import { LoadServiceAppointmentsRequestState, ServiceAppointment } from './serviceAppointments.models';

export interface ServiceAppointmentsState {
  byVIN: {
    [id: string]: {
      loadRequestState: LoadServiceAppointmentsRequestState;
      serviceAppointments?: ServiceAppointment[];
    };
  };
}

export const INITIAL_STATE: ServiceAppointmentsState = {
  byVIN: {},
};

export const serviceAppointmentsReducer = (
  state: ServiceAppointmentsState = INITIAL_STATE,
  action: ServiceAppointmentActions,
): ServiceAppointmentsState => {
  switch (action.type) {
    case ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            serviceAppointments: undefined,
            loadRequestState: LoadServiceAppointmentsRequestState.LOADING,
          },
        },
      };
    }
    case ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            serviceAppointments: action.payload.serviceAppointments,
            loadRequestState: LoadServiceAppointmentsRequestState.LOADED,
          },
        },
      };
    }
    case ServiceAppointmentActionsType.LOAD_SERVICE_APPOINTMENTS_ERROR: {
      return {
        ...state,
        byVIN: {
          ...state.byVIN,
          [action.payload.VIN]: {
            serviceAppointments: undefined,
            loadRequestState: LoadServiceAppointmentsRequestState.FAILED,
          },
        },
      };
    }
    default:
      return state;
  }
};
