import { WithStyles, withStyles, createStyles, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import JiveForm from '../../entityForms/JiveForm.component';
import { ServiceTicketFormModel } from './connectwise.serviceTicket.model';
import { CompanyPicker } from '../companyPicker/CompanyPicker.container';
import LoadingSpinner from '../../components/LoadingSpinner';
import { TextInputField } from '../../entityForms/TextInputField.component';
import { CallActionFormProps } from '../../calls/CallPageForm.model';
import { Typography } from '../../theme/Typography.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';

const definedMessages = defineMessages({
  SERVICE_TICKET_FORM_TITLE: {
    id: 'ConnectWise.ServiceTicketForm.Title',
    defaultMessage: 'Service ticket',
  },
  SERVICE_TICKET_FORM_ADD_BUTTON_LABEL: {
    id: 'ConnectWise.ServiceTicketForm.AddButton.Label',
    defaultMessage: 'Add Service',
  },
  SERVICE_TICKET_SUMMARY_LABEL: {
    id: 'ConnectWise.ServiceTicketForm.Summary.Label',
    defaultMessage: 'Summary',
  },
});
const styles = () =>
  createStyles({
    summaryHelper: {
      textAlign: 'end',
      margin: 0,
      display: 'block',
    },
  });

const trackingEvents = defineTrackingEvents({
  ADD_SERVICE_CLICKED: {
    category: AnalyticsCategory.ServiceTicket,
    action: AnalyticsAction.ItemClicked,
    label: 'Add Service | button',
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.ServiceTicket,
    action: AnalyticsAction.ItemClicked,
    label: 'Cancel | button',
  },
});

export interface ServiceTicketFormStateProps {
  contactId?: string;
  isServiceTicketSaveLoading: boolean;
  saveServiceTicket: (activity: ServiceTicketFormModel) => void;
}

export type ServiceTicketFormProps = CallActionFormProps;

const ServiceTicketForm: React.FC<ServiceTicketFormStateProps & ServiceTicketFormProps & WithStyles<typeof styles>> = ({
  contactId,
  isServiceTicketSaveLoading,
  saveServiceTicket,
  classes,
  onClose,
  callId,
}) => {
  const [summary, setSummary] = useState<string>('');
  const [companyId, setCompanyId] = useState<number | null>(null);

  if (!contactId) {
    return null;
  }

  return (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => {
        if (!!summary.trim() && !!companyId) {
          saveServiceTicket({
            callId,
            contactId: Number(contactId),
            summary,
            companyId,
          });
        }
      }}
      canSubmit={!isServiceTicketSaveLoading && !!summary.trim() && !!companyId}
      saveButtonDescriptor={definedMessages.SERVICE_TICKET_FORM_ADD_BUTTON_LABEL}
      saveTrackingEvent={trackingEvents.ADD_SERVICE_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
    >
      <Box mb={2}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.SERVICE_TICKET_FORM_TITLE} />
        </Typography>
      </Box>
      <Box mb={4}>
        <TextInputField
          name="ticket-summary"
          noMargin
          labelDescription={definedMessages.SERVICE_TICKET_SUMMARY_LABEL}
          value={summary}
          onChange={({ target: { value } }) => setSummary(value)}
          autoFocus
          maxLength={100}
          multiline
          rows={3}
          helperText={<span className={classes.summaryHelper}>{`${summary.length}/100`}</span>}
        />
      </Box>
      <Box mb={4}>
        <CompanyPicker selectedCompanyId={companyId} onCompanySelected={(value) => value && setCompanyId(value)} />
      </Box>
      <LoadingSpinner isLoading={isServiceTicketSaveLoading}>
        <></>
      </LoadingSpinner>
    </JiveForm>
  );
};

export default withStyles(styles)(ServiceTicketForm);
