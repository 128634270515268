import { createStyles, Box, Theme, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CallWithContact } from './calls.reducer';
import { ApplicationRoute } from '../constants';
import { CallType } from '@jive/realtime-events';
import { Typography } from '../theme/Typography.component';
import { format } from 'date-fns';
import { IconButton } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';
import { PhoneUpActiveIcon, PhoneDownActiveIcon, ChevronLeftIcon } from '@getgo/chameleon-icons/react';
import clsx from 'clsx';
import { CallDuration } from '../activeCalls/CallDuration.component';
import { goToRoute } from '../browserHistory';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { callSelector } from './calls.selector';
import { AppState } from '../reducers';
import { SoftphoneCall } from '../softphone/softphone.model';
import { selectSoftphoneCallByRealtimeCallSelector } from '../softphone/softphoneCall.selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    callHeader: {
      display: 'flex',
      minHeight: 40,
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    callHeaderForNarrowIntegration: {
      minHeight: 30,
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
  }),
);

export interface CallHeaderProps {
  call: CallWithContact;
}

const definedMessages = defineMessages({
  ACTIVECALL_TITLE: {
    id: 'ActiveCall.Title',
    defaultMessage: 'Active Call',
    description: 'header section',
  },
  CALLENDED_TITLE: {
    id: 'CallEnded.Title',
    defaultMessage: 'Call Ended',
    description: 'header section',
  },
  MISSED_CALL_TITLE: {
    id: 'CallEnded.Title.Missed',
    defaultMessage: 'Missed Call',
  },
  DECLINED_CALL_TITLE: {
    id: 'CallEnded.Title.Declined',
    defaultMessage: 'Declined Call',
  },
});

const formatDateTime = (input: Date | number, locale?: Locale) => format(input, 'PPPp', { locale });

const getTitleByCallType = (isActiveCall: boolean | undefined, callType: CallType) => {
  if (isActiveCall) {
    return definedMessages.ACTIVECALL_TITLE;
  }

  switch (callType) {
    case CallType.MissedCall:
      return definedMessages.MISSED_CALL_TITLE;

    case CallType.DeclinedCall:
      return definedMessages.DECLINED_CALL_TITLE;

    default:
      return definedMessages.CALLENDED_TITLE;
  }
};

export const CallHeader: React.FC<CallHeaderProps> = ({ call }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const activeCall = useSelector((state: AppState) => callSelector(state, call.id));
  const classes = useStyles();
  const softphoneCall: SoftphoneCall | undefined = useSelector(selectSoftphoneCallByRealtimeCallSelector(call));

  const isActiveCall = activeCall && !activeCall.endTime;
  const isMissedCall = call.type === CallType.MissedCall || call.type === CallType.DeclinedCall;

  const pageTitle = getTitleByCallType(isActiveCall, call.type);

  const onBackButtonClicked = () => {
    if (isActiveCall) {
      goToRoute(ApplicationRoute.ACTIVE_CALLS_ROUTE);
    } else {
      goToRoute(ApplicationRoute.CALL_HISTORY_ROUTE);
    }
  };

  return (
    <div className={clsx(classes.callHeader, isNarrowIntegration && classes.callHeaderForNarrowIntegration)}>
      <Grid container direction="row">
        <Grid item xs="auto">
          <Box mr={1} mt={isNarrowIntegration ? 0 : 1}>
            <IconButton variant="neutral" size="xsmall" onClick={onBackButtonClicked} aria-label="Back">
              <ChevronLeftIcon aria-hidden />
            </IconButton>
          </Box>
        </Grid>
        <Grid container item direction="column" xs>
          <Grid container item alignItems="center">
            <Grid item xs>
              <Typography
                data-cy="call-header-title"
                tag="h1"
                variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}
              >
                <FormattedMessage {...pageTitle} />
              </Typography>
            </Grid>
            {isMissedCall || softphoneCall ? null : (
              <Grid item xs="auto">
                <Box display="flex" alignItems="center">
                  <Typography variant="body-xsmall" color="text-02">
                    <CallDuration call={call} format="mmss" />
                  </Typography>
                  <div className={classes.icon}>
                    {isActiveCall ? (
                      <PhoneUpActiveIcon color={tokens.green500} size="16px" aria-hidden />
                    ) : (
                      <PhoneDownActiveIcon color={tokens.red500} size="16px" aria-hidden />
                    )}
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography variant="body-xsmall" color="text-02">
              {formatDateTime(call.creationTime)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
