import { CompanyPickerState, GetCompaniesRequestState } from './companyPicker.models';
import { CompanyPickerActions, CompanyPickerActionsType } from './companyPicker.action';

export const INITIAL_STATE: CompanyPickerState = {
  requestState: GetCompaniesRequestState.INITIAL,
  companies: [],
};

export const companyPickerReducer = (
  state: CompanyPickerState = INITIAL_STATE,
  action: CompanyPickerActions,
): CompanyPickerState => {
  switch (action.type) {
    case CompanyPickerActionsType.GET_COMPANIES:
      return {
        ...INITIAL_STATE,
        requestState: GetCompaniesRequestState.LOADING,
      };

    case CompanyPickerActionsType.GET_COMPANIES_SUCCESS:
      return {
        ...INITIAL_STATE,
        requestState: GetCompaniesRequestState.LOADED,
        companies: action.payload.companies,
      };

    case CompanyPickerActionsType.GET_COMPANIES_ERROR:
      return {
        ...INITIAL_STATE,
        requestState: GetCompaniesRequestState.FAILED,
      };
    case CompanyPickerActionsType.CLEAR_COMPANIES:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
