import { ObjectStores, storageService } from '../storage/storage.service';
import { logger } from '../logging';
import { SyncData, ISyncService } from './sync.service.model';

const IDB_NORMAL_ERROR_NAMES = ['ConstraintError', 'TimeoutError'];

export class IDBSyncService implements ISyncService {
  async canCurrentTabOperate(operationPrefix: string, id: string, holdLockForMilliseconds) {
    if (!operationPrefix) {
      throw new Error('Trying to sync without specifying operation prefix');
    }

    try {
      const tx = await storageService.transaction(ObjectStores.SYNC_TRACKING);
      const storageId = `${operationPrefix}_${id}`;
      await Promise.all([
        tx.store.add({ id: storageId, timestamp: Date.now(), holdLockForMilliseconds } as SyncData),
        tx.done,
      ]);

      setTimeout(() => this.cleanUpSyncData(), holdLockForMilliseconds);

      return true;
    } catch (e) {
      if (e instanceof DOMException && IDB_NORMAL_ERROR_NAMES.includes(e.name)) {
        return false;
      } else {
        throw e;
      }
    }
  }

  async isAvailable(): Promise<boolean> {
    return storageService.isAvailable();
  }

  async cleanUpSyncData() {
    try {
      let cursor = await storageService.cursor(ObjectStores.SYNC_TRACKING);

      const now = Date.now();

      while (cursor) {
        const syncData = cursor.value as SyncData;

        // remove old records
        const holdLockForMilliseconds = syncData.holdLockForMilliseconds ?? 5000;
        if (now - syncData.timestamp > holdLockForMilliseconds) {
          await cursor.delete();
        }

        cursor = await cursor.continue();
      }
    } catch (e) {
      logger.error('Error during sync data cleanup', e);
    }
  }
}
