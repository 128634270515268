import { getActionFacade } from '../actionFacade/action.facade.store';
import { ContactActionsType } from '../actions/contacts';
import { closeIntegrationPanel, openIntegrationPanel } from '../embedded/embedded.action';
import { IntegrationsActionsType } from '../integrations/integrations.actions';
import { addListener, addSmartListener } from '../middlewares/listener.middleware';
import { Integrations } from '../models';
import { EntitiesActionsType } from '../search/entities.action';
import { definedMessages } from '../inAppNotification/message.content';
import { openTicketError, openTicketSuccess, TicketsActionsType } from './tickets/tickets.action';
import { ZendeskEntityType } from './zendesk.models';
import { ZendeskService } from './zendesk.service';
import { addCallStartListener, addConversationStartListener } from '../calls/callListeners';
import { PostCallActionsType, SaveCallPayload } from '../calls/callEvents.action';
import { zendeskSettingSelector } from '../settings/settings.selector';
import { logger } from '../logging';
import { CallEventsActionsType } from '@jive/realtime-events';
import { isTestCallSelector } from '../onboarding/testCall/testCall.selectors';
import { contactInCallSelector } from '../calls/calls.selector';
import { showErrorMessage } from '../inAppNotification/message.action';
import { defineMessages } from 'react-intl';
import { ZendeskActionFacade } from './zendesk.actionfacade';

let registered = false;
let zendeskService: ZendeskService;

const zendeskMessages = defineMessages({
  NO_CONTACT_ASSOCIATED_AUTOLOG_ERROR: {
    id: 'Salesforce.CallLog.NoContact.ErrorMessage',
    defaultMessage: 'Could not log the call. Please assign a contact to the call and try again.',
  },
});

// initialize all Zendesk listeners and interceptor
addSmartListener(IntegrationsActionsType.SET_CURRENT)((_, action) => {
  if (action.payload !== Integrations.Zendesk) {
    return;
  }

  zendeskService = new ZendeskService();

  registerZendeskListener();
});

function registerZendeskListener() {
  if (registered) {
    return;
  }

  registered = true;

  addListener(IntegrationsActionsType.INTEGRATION_STARTING)(() => {
    zendeskService.init();
  });

  addListener(openIntegrationPanel.type)(() => {
    zendeskService.showIntegration();
  });

  addListener(closeIntegrationPanel.type)(() => {
    zendeskService.hideIntegration();
  });

  addConversationStartListener(async (_, action) => {
    const { call } = action.payload;

    // do not search contacts if this is not the first call state that we know about this call
    // if we have any previous states we already started a search
    if (call.previousStates.length) {
      return;
    }

    await getActionFacade().createScreenpop(call);
  });

  addCallStartListener(async (_, action) => {
    const { call } = action.payload;

    await getActionFacade().createScreenpop(call);
  });

  addListener(ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER)(async (_, action) => {
    const { contactId, phoneNumber } = action.payload;

    await getActionFacade().updateContactPhoneNumber(contactId, phoneNumber);
  });

  addListener(EntitiesActionsType.OPEN_ENTITY_REQUEST)(async (_, action) => {
    if (!action.payload.entity) {
      return;
    }

    await getActionFacade().openContactInCrm(action.payload.entity.id);
  });

  addListener(EntitiesActionsType.ENTITY_SEARCH)(async (_, action) => {
    await getActionFacade().searchContact(action.payload.query);
  });

  addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_REQUEST)(async (_, action) => {
    await getActionFacade().loadContactPhoneNumbers(action.payload);
  });

  addListener(TicketsActionsType.OPEN_TICKET)(async (_, action) => {
    try {
      await zendeskService.openEntity(ZendeskEntityType.Ticket, action.payload.ticketId);
      return openTicketSuccess();
    } catch {
      return openTicketError({
        message: definedMessages.OPEN_TICKET_ERROR,
      });
    }
  });

  addListener(PostCallActionsType.SAVE_CALL)((state, action) => {
    const { callLog, shouldPersist, call }: SaveCallPayload = action.payload;

    if (!shouldPersist) {
      return;
    }

    if (!callLog) {
      logger.error('No call log to save in zendesk.');
      return;
    }

    const { isAutomaticCallLoggingEnabled } = zendeskSettingSelector(state);
    if (!isAutomaticCallLoggingEnabled) {
      return;
    }
    return getActionFacade<ZendeskActionFacade>().createCallLog(callLog, call);
  });

  addListener(CallEventsActionsType.END_CONVERSATION)((state, action) => {
    const isTestCall = isTestCallSelector(action.payload.call.id)(state);
    if (isTestCall) {
      return;
    }

    const contact = contactInCallSelector(state, action.payload.call.id);

    const { isAutomaticCallLoggingEnabled } = zendeskSettingSelector(state);

    if (!contact && isAutomaticCallLoggingEnabled) {
      return showErrorMessage(zendeskMessages.NO_CONTACT_ASSOCIATED_AUTOLOG_ERROR);
    }
  });
}
