import { CallType } from '@jive/realtime-events';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CallMissedIcon, CallMadeIcon, CallReceivedIcon, PhoneDownInactiveIcon } from '@getgo/chameleon-icons/react';
import React from 'react';
import clsx from 'clsx';
import { tokens } from '@getgo/chameleon-core';

const useStyles = makeStyles(() =>
  createStyles({
    callIcon: {
      display: 'inline-block',
    },
  }),
);

interface CallTypeProps {
  type: CallType;
  size?: string;
  className?: string;
}

export const CallTypeIcon: React.FC<CallTypeProps> = ({ type, className, size }) => {
  const classes = useStyles();
  const icons = {
    [CallType.IncomingCall]: (
      <CallReceivedIcon
        class={clsx(className, classes.callIcon)}
        color={tokens.green500}
        size={size ?? '11px'}
        aria-hidden
      />
    ),
    [CallType.OutgoingCall]: (
      <CallMadeIcon
        class={clsx(className, classes.callIcon)}
        color={tokens.blue500}
        size={size ?? '11px'}
        aria-hidden
      />
    ),
    [CallType.MissedCall]: (
      <CallMissedIcon
        class={clsx(className, classes.callIcon)}
        color={tokens.red500}
        size={size ?? '11px'}
        aria-hidden
      />
    ),
    [CallType.DeclinedCall]: (
      <PhoneDownInactiveIcon
        class={clsx(className, classes.callIcon)}
        color={tokens.red500}
        size={size ?? '11px'}
        aria-hidden
      />
    ),
  };

  return icons[type];
};
