import { getBaseURIWithoutSlash } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { logger } from '../logging';
import { Entity } from '../models';
import { isNotMicrophonePermissionDenied } from '../softphone/microphonePermission.service';
import { getDistinctItemsByProperty } from '../utils';
import { configureZafClient, ZafClient, ZafClientRequestOptions } from './zafclient';
import {
  CreateTicketCommentParams,
  CreateTicketParams,
  ZendeskEntityType,
  ZendeskIdentity,
  ZendeskSearchResult,
  ZendeskTicket,
  ZendeskTicketSearchResultItem,
  ZendeskUser,
} from './zendesk.models';

const convertZendeskUserToEntity = (user: ZendeskUser): Entity => {
  return {
    id: user.id.toString(),
    label: `Contact - ${user.name}`,
    name: user.name,
    phoneNumber: user.phone,
    type: 'Contact',
  };
};

export class ZendeskService {
  private zafClientInstance: Optional<ZafClient>;

  private get zafClient(): ZafClient {
    if (!this.zafClientInstance) {
      const newClient = configureZafClient();

      if (!newClient) {
        logger.error('Could not instantiate zaf client when it was needed');
        throw Error('Could not instantiate zaf client when it was needed');
      }

      this.zafClientInstance = newClient;
    }

    return this.zafClientInstance;
  }

  init() {
    console.log('initializing zendesk', this.zafClient); // yes, the getter will initialize. sorry
  }

  showIntegration() {
    this.zafClient.invoke('popover', 'show');
  }

  hideIntegration() {
    this.zafClient.invoke('popover', 'hide');
  }

  async showMicrophonePermissionModal() {
    const modalContext = await this.zafClient.invoke('instances.create', {
      location: 'modal',
      url: getBaseURIWithoutSlash() + ApplicationRoute.MICROPHONE_PERMISSION,
      size: {
        width: '1000px',
        height: '600px',
      },
    });

    const modalClient = this.zafClient.instance(modalContext['instances.create'][0].instanceGuid);

    const intervalId = setInterval(async () => {
      const isMicrophoneEnabled = await isNotMicrophonePermissionDenied();
      if (isMicrophoneEnabled) {
        clearInterval(intervalId);
        modalClient.off('modal.close', clearMicrophoneCheckInterval);
        modalClient.invoke('destroy');
      }
    }, 2_000);
    const clearMicrophoneCheckInterval = () => clearInterval(intervalId);
    modalClient.on('modal.close', clearMicrophoneCheckInterval);
  }

  async openEntity(type: ZendeskEntityType, id: string) {
    await this.zafClient.invoke('routeTo', type, id);
  }

  async searchEntitites(query: string): Promise<Entity[]> {
    const result: Entity[] = [];
    let hasError = false;

    try {
      const resultByUsername = await this.searchUsersByName(query);
      result.push(...resultByUsername);
    } catch (error) {
      hasError = true;
      logger.error('Error searching entities by name in Zendesk', error);
    }

    try {
      const resultByPhone = await this.searchEntitiesByPhone(query);
      result.push(...resultByPhone);
    } catch (error) {
      hasError = true;
      logger.error('Error searching entities by number in Zendesk', error);
    }

    if (result.length === 0 && hasError) {
      throw Error('Error searching for entities');
    }

    const entities = getDistinctItemsByProperty(result, (entity) => String(entity.id));
    return entities;
  }

  async searchEntitiesByPhone(phone: string): Promise<Entity[]> {
    try {
      const normalizedPhoneNumber = phone.replace('+', '');
      const response = await this.zafClient.request<ZendeskSearchResult<ZendeskUser>>({
        url: `/api/v2/search.json?query=type:user phone:${normalizedPhoneNumber}`,
      });
      return response.results.map(convertZendeskUserToEntity);
    } catch (e) {
      if (e.status === 0) {
        // timeout error
        // eslint-disable-next-line no-throw-literal
        throw { ...e, message: 'Timeout searching Zendesk contacts by phone number' };
      }
      throw e;
    }
  }

  async addPhoneNumber(contactId: string, phoneNumber: string) {
    const settings: ZafClientRequestOptions = {
      url: `/api/v2/users/${contactId}/identities.json`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({ identity: { type: 'phone_number', value: phoneNumber } }),
    };

    try {
      await this.zafClient.request(settings);
    } catch (error) {
      logger.error('Error adding phone number to contact in Zendesk', error);
      throw error;
    }
  }

  async createContact(name: string, phoneNumber: string): Promise<string> {
    const settings: ZafClientRequestOptions = {
      url: '/api/v2/users.json',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        user: {
          name,
          phone: phoneNumber,
        },
      }),
    };

    const response = await this.zafClient.request<{ user: ZendeskUser }>(settings);
    return String(response.user.id);
  }

  async createTicket({
    userId,
    subject,
    description,
    via,
    isCommentPublic,
    ticketStatus,
  }: CreateTicketParams): Promise<string> {
    const settings: ZafClientRequestOptions = {
      url: '/api/v2/tickets.json',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        ticket: {
          subject,
          comment:
            description === undefined
              ? undefined
              : {
                  body: description,
                  public: isCommentPublic,
                },
          requester_id: userId,
          via,
          status: ticketStatus,
        },
      }),
    };

    try {
      const response = await this.zafClient.request<{ ticket: ZendeskTicket }>(settings);
      return String(response.ticket.id);
    } catch (error) {
      logger.error('Error creating ticket in Zendesk', error);
      throw error;
    }
  }

  async getUserPhoneIdentities(userId: string): Promise<ZendeskIdentity[]> {
    const result = await this.zafClient.request<{ identities: ZendeskIdentity[] }>({
      url: `/api/v2/users/${userId}/identities.json`,
    });
    return result.identities.filter((i) => i.type === 'phone_number');
  }

  async addTicketComment({ ticketId, authorId, comment, isPublic = false }: CreateTicketCommentParams): Promise<void> {
    const settings: ZafClientRequestOptions = {
      url: `/api/v2/tickets/${ticketId}.json`,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify({
        ticket: {
          comment: { body: comment, public: isPublic, author_id: authorId },
        },
      }),
    };

    try {
      await this.zafClient.request<{ ticket: ZendeskTicket }>(settings);
    } catch (error) {
      logger.error('Error creating ticket comment in Zendesk', error);
      throw error;
    }
  }

  public async searchTickets(requesterId?: string, searchString?: string): Promise<ZendeskTicketSearchResultItem[]> {
    let searchQueryParam = '';
    if (searchString) {
      const isSearchStringNumber = this.isSearchStringNumber(searchString);
      if (isSearchStringNumber) {
        searchQueryParam = searchString;
      } else {
        searchQueryParam = `subject:${searchString}*`;
      }
    }

    const searchRequests: [
      Promise<ZendeskSearchResult<ZendeskTicket>>,
      Promise<ZendeskSearchResult<ZendeskTicket>> | undefined,
    ] = [
      this.zafClient.request<ZendeskSearchResult<ZendeskTicket>>({
        url: `/api/v2/search.json?per_page=10&query=type:ticket status<closed ${searchQueryParam} `,
      }),
      requesterId
        ? this.zafClient.request<ZendeskSearchResult<ZendeskTicket>>({
            url: `/api/v2/search.json?per_page=10&query=type:ticket status<closed requester_id:${requesterId} ${searchQueryParam}`,
          })
        : undefined,
    ];

    const [searchInAllTicketsResponse, responseWithRequesterId] = await Promise.all(searchRequests);

    return this.removeDuplicates(searchInAllTicketsResponse.results, responseWithRequesterId?.results ?? []);
  }

  private async searchUsersByName(name: string): Promise<Entity[]> {
    const response = await this.zafClient.request<ZendeskSearchResult<ZendeskUser>>({
      url: `/api/v2/search.json?query=type:user name:${name}*`,
    });
    return response.results.map(convertZendeskUserToEntity);
  }

  private isSearchStringNumber(searchString: string): boolean {
    const trimmedString: string = searchString.trim();
    return /\d+$/.test(trimmedString);
  }

  private removeDuplicates(
    allTickets: ZendeskTicket[],
    relatedTickets: ZendeskTicket[],
  ): ZendeskTicketSearchResultItem[] {
    const ids1 = allTickets.map((ticket) => ticket.id);
    const ids2 = relatedTickets.map((ticket) => ticket.id);

    const uniqueIds = [...new Set([...ids1, ...ids2])];
    const uniqueValues: ZendeskTicketSearchResultItem[] = uniqueIds.map((id) => {
      const relatedTicket = relatedTickets.find((ticket) => ticket.id === id);
      if (relatedTicket) {
        return { ...relatedTicket, searchResultType: 'related' };
      }
      const allItem = allTickets.find((ticket) => ticket.id === id);
      return { ...(allItem as ZendeskTicket), searchResultType: 'other' };
    });
    return uniqueValues;
  }
}
