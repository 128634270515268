import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { selectedDateFnsLocaleSelector } from '../../settings/settings.selector';
import {
  ClosedRepairOrdersComponent,
  ClosedRepairOrdersProps,
  ClosedRepairOrdersStateProps,
} from './ClosedRepairOrders.component';
import {
  closedRepairOrderByVINSelector,
  closedRepairOrderDetailsSelectorByVINSelector,
  closedRepairOrdersLoadRequestStateByVINSelector,
  serviceWritersRequestStateSelector,
  serviceWritersSelector,
} from './repairOrders.selectors';

const mapStateToProps = (state: AppState, { VIN }: ClosedRepairOrdersProps): ClosedRepairOrdersStateProps => ({
  requestState: closedRepairOrdersLoadRequestStateByVINSelector(VIN)(state),
  closedRepairOrders: closedRepairOrderByVINSelector(VIN)(state),
  closedRepairOrderDetails: closedRepairOrderDetailsSelectorByVINSelector(VIN)(state),
  locale: selectedDateFnsLocaleSelector(state),
  serviceWritersRequestState: serviceWritersRequestStateSelector(state),
  serviceWriters: serviceWritersSelector(state),
});

export const ClosedRepairOrders = connect<ClosedRepairOrdersStateProps, {}, ClosedRepairOrdersProps, AppState>(
  mapStateToProps,
)(ClosedRepairOrdersComponent);
