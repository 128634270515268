import { SentryMetadata } from './model';
import { withScope, captureException, init, BrowserOptions, Severity } from '@sentry/browser';
import { splunkLogger } from './splunk-logger';
import { CoxError } from '../cox/errors/cox.error';

export let logger: AppLogger;

interface LogExtras {
  [key: string]: any;
}
export interface AppLogger {
  error(message: string, extras?: LogExtras): void;
  error(message: string, exception: Error, extras?: LogExtras): void;
  info(message: string, extras?: LogExtras): void;
  debug(message: string, extras?: LogExtras): void;
  warn(message: string, extras?: LogExtras): void;
  setContext(key: string, data: any): void;
}

class Logger implements AppLogger {
  public error(message: string, ...others: any): void {
    const error: Error = others && others[0] instanceof Error ? others[0] : new Error();

    const extras: LogExtras = (others[0] instanceof Error ? others[1] : others[0]) || {};

    if (error instanceof CoxError) {
      extras.coxErrors = error.coxErrors;
    }

    if (error instanceof AggregateError) {
      error.errors.forEach((err, index) => {
        extras[`error${index}-name`] = err.name;
        extras[`error${index}-message`] = err.message;
        extras[`error${index}-stack`] = err.stack;
      });
    }

    // console log
    try {
      this.logErrorToConsole(message, error, extras);
    } catch (e) {
      console.error('could not log error to console...', e, message, error, extras);
    }

    // splunk log
    try {
      const errorCopy = this.copyError(error); // copy error before changing it in logToSentry
      this.logErrorToSplunk(message, errorCopy, extras);
    } catch (e) {
      console.error('could not log error to splunk...', e, message, error, extras);
    }

    // sentry log
    try {
      this.logToSentry(message, error, extras);
    } catch (e) {
      console.error('could not log error to sentry...', e, message, error, extras);
    }
  }

  info(message: string, extras?: LogExtras): void {
    try {
      this.logInfoToSplunk(message, extras);
      this.logInfoToConsole(message, extras);
    } catch (e) {
      console.error('could not log info to splunk or console...', e, message, extras);
    }
  }

  debug(message: string, extras?: LogExtras): void {
    try {
      this.logDebugToConsole(message, extras);
    } catch (e) {
      console.error('could not log debug to console...', e, message, extras);
    }
  }

  warn(message: string, extras?: LogExtras): void {
    try {
      this.logWarnToSplunk(message, extras);
      this.logWarnToConsole(message, extras);
    } catch (e) {
      console.error('could not log warn to splunk or console...', e, message, extras);
    }
  }

  setContext(key: string, data: any) {
    try {
      splunkLogger.setContext(key, data);
    } catch (e) {
      this.error('could set context...', e);
    }
  }

  private logErrorToConsole(message: string, error: Error, extras: LogExtras) {
    console.group(`%c[ERROR]%c ${message}`, 'color:white;background:red;', '');
    console.log(`%c${error}`, 'color: white; background: red');
    Object.keys(extras).forEach((key) => console.log(key, extras[key]));
    console.groupEnd();
  }

  private logWarnToConsole(message: string, extras?: LogExtras) {
    console.group(`%c[WARN]%c ${message}`, 'color:white;background:orange;', '');
    if (extras) {
      Object.keys(extras).forEach((key) => console.log(key, extras[key]));
    }
    console.groupEnd();
  }

  private logInfoToConsole(message: string, extras?: LogExtras) {
    console.group(`%c[INFO]%c ${message}`, 'color:white;background:blue;', '');
    if (extras) {
      Object.keys(extras).forEach((key) => console.log(key, extras[key]));
    }
    console.groupEnd();
  }

  private logDebugToConsole(message: string, extras?: LogExtras) {
    console.group(`%c[DEBUG]%c ${message}`, 'color:white;background:blue;', '');
    if (extras) {
      Object.keys(extras).forEach((key) => console.log(key, extras[key]));
    }
    console.groupEnd();
  }

  private logToSentry(message: string, error: Error, extras: LogExtras) {
    withScope((scope) => {
      const shouldOnlyUseMessage = message === error.message || !error.message;

      scope.setLevel(Severity.Error);
      scope.setFingerprint([message]);

      // sentry can only handle the error with captureException
      if (!(error instanceof AggregateError)) {
        error.message = shouldOnlyUseMessage ? message : `${message} - ${error.message}`;
      }

      // set extra fields to see them in sentry
      scope.setExtras(extras);

      captureException(error);
    });
  }

  private logErrorToSplunk(message: string, error: Error, extras: LogExtras) {
    splunkLogger.error(message, 'error=', error, 'extras=', extras);
  }

  private logInfoToSplunk(message: string, extras?: LogExtras) {
    splunkLogger.info(message, 'extras=', extras);
  }

  private logWarnToSplunk(message: string, extras?: LogExtras) {
    splunkLogger.warn(message, 'extras=', extras);
  }

  private copyError(error: Error): Error {
    const copy = Object.assign({}, error);
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
    const knownErrorProperties = [
      'message',
      'name',
      'description',
      'number',
      'fileName',
      'lineNumber',
      'columnNumber',
      'stack',
    ];
    knownErrorProperties.forEach((prop) => {
      if (error[prop]) {
        copy[prop] = error[prop];
      }
    });
    return copy;
  }
}

export function initializeLogging(
  dsn: string,
  environment: string,
  release: string,
  metadataCreator: () => SentryMetadata,
): void {
  const sentryConfig: BrowserOptions = {
    dsn,
    environment,
    release,
  };

  sentryConfig.beforeSend = (event) => {
    const metadata = metadataCreator();
    if (event.user) {
      event.user.id = metadata.userId;
    }

    if (!event.tags) {
      event.tags = {};
    }

    for (const tag of metadata.tags) {
      event.tags[tag.key] = tag.value;
    }

    return event;
  };

  init(sentryConfig);

  logger = new Logger();
}
