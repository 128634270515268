import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const changelogSelector = (state: AppState) => state.changelog;

export const lastSeenVersionSelector = createSelector(changelogSelector, (changelog) => changelog.lastSeenVersion);

export const lastSeenVersionLoadingStateSelector = createSelector(
  changelogSelector,
  (changelog) => changelog.lastSeenVersionLoadingState,
);

export const lastSeenAnnouncementSelector = createSelector(
  changelogSelector,
  (changelog) => changelog.lastSeenAnnouncement,
);

export const lastSeenAnnouncementLoadingStateSelector = createSelector(
  changelogSelector,
  (changelog) => changelog.lastSeenAnnouncementLoadingState,
);

export const hasNewAnnouncementSelector = createSelector(
  changelogSelector,
  (changelog) => changelog.hasNewAnnouncement,
);
