import { Badge } from '@getgo/chameleon-web/react';
import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SoftphoneCall } from './softphone.model';
import {
  MicrophoneActiveIcon,
  MicrophoneOnInactiveIcon,
  PauseInactiveIcon,
  DialPadIcon,
  PhoneDownActiveIcon,
} from '@getgo/chameleon-icons/react';
import { format } from 'date-fns';
import { SoftphoneCapableFacade } from '../actionFacade/action.facade.capabilities';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { tokens } from '@getgo/chameleon-core';
import { TrackedRoundButton } from '../analytics-new/TrackedRoundButton.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { SoftphoneDialPad } from './SoftphoneDialPad.component';

interface SoftphoneActiveCallControlProps {
  call: SoftphoneCall;
}

const trackingEvents = defineTrackingEvents({
  MUTE_MICROPHONE: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Mute microphone',
  },
  UNMUTE_MICROPHONE: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Unmute microphone',
  },
  HOLD_CALL: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Hold call',
  },
  RESUME_CALL: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Resume call',
  },
  TOGGLE_KEYPAD: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Toggle keypad',
  },
  HANG_UP: {
    category: AnalyticsCategory.Softphone,
    action: AnalyticsAction.ItemClicked,
    label: 'Hang up',
  },
});

const definedMessages = defineMessages({
  MUTE: {
    id: 'Softphone.Mute',
    defaultMessage: 'Mute',
  },
  HOLD: {
    id: 'Softphone.Hold',
    defaultMessage: 'Hold',
  },
  KEYPAD: {
    id: 'Softphone.Keypad',
    defaultMessage: 'Keypad',
  },
  HANG_UP: {
    id: 'Softphone.HangUp',
    defaultMessage: 'Hang up',
  },
});

export const SoftphoneActiveCallControl: React.FC<SoftphoneActiveCallControlProps> = ({ call }) => {
  const intl = useIntl();
  const [duration, setDuration] = useState('00:00');
  const [isKeyPadVisible, setKeyPadVisible] = useState(false);

  const isOnHold = call.callState === 'on_hold';

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(format(Date.now() - (call.answerTime ?? Date.now()), 'mm:ss'));
    }, 500);
    return () => clearInterval(interval);
  }, [call]);

  return (
    <Box>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item container spacing={3} xs>
          <Grid item xs="auto">
            <TrackedRoundButton
              size="small"
              variant={call.isMuted ? 'primary' : 'secondary'}
              title={intl.formatMessage(definedMessages.MUTE)}
              trackingEvent={call.isMuted ? trackingEvents.UNMUTE_MICROPHONE : trackingEvents.MUTE_MICROPHONE}
              onClick={() => {
                call.isMuted
                  ? void getActionFacade<SoftphoneCapableFacade>().unmuteCall(call.id)
                  : void getActionFacade<SoftphoneCapableFacade>().muteCall(call.id);
              }}
            >
              {call.isMuted ? <MicrophoneOnInactiveIcon /> : <MicrophoneActiveIcon />}
            </TrackedRoundButton>
          </Grid>
          <Grid item xs="auto">
            <TrackedRoundButton
              size="small"
              variant={isOnHold ? 'primary' : 'secondary'}
              title={intl.formatMessage(definedMessages.HOLD)}
              trackingEvent={isOnHold ? trackingEvents.RESUME_CALL : trackingEvents.HOLD_CALL}
              onClick={() => {
                isOnHold
                  ? void getActionFacade<SoftphoneCapableFacade>().resumeCall(call.id)
                  : void getActionFacade<SoftphoneCapableFacade>().holdCall(call.id);
              }}
            >
              <PauseInactiveIcon />
            </TrackedRoundButton>
          </Grid>
          <Grid item xs="auto">
            <TrackedRoundButton
              variant={isKeyPadVisible ? 'primary' : 'secondary'}
              size="small"
              disabled={isOnHold}
              title={intl.formatMessage(definedMessages.KEYPAD)}
              trackingEvent={trackingEvents.TOGGLE_KEYPAD}
              onClick={() => setKeyPadVisible(!isKeyPadVisible)}
            >
              <DialPadIcon />
            </TrackedRoundButton>
          </Grid>
          <Grid item xs="auto">
            <TrackedRoundButton
              variant="danger"
              size="small"
              title={intl.formatMessage(definedMessages.HANG_UP)}
              trackingEvent={trackingEvents.HANG_UP}
              onClick={() => getActionFacade<SoftphoneCapableFacade>().hangupCall(call.id)}
            >
              <PhoneDownActiveIcon />
            </TrackedRoundButton>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Badge variant="success">
            <time style={{ font: tokens.codeTextSmall }}>{duration}</time>
          </Badge>
        </Grid>
      </Grid>
      {isKeyPadVisible && (
        <Box mt={2} ml={-4} mr={-4}>
          <SoftphoneDialPad call={call} />
        </Box>
      )}
    </Box>
  );
};
