import { EmptyState } from '@getgo/chameleon-web/react';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { FullScreenPageWithLogo } from '../theme/FullScreenPageWithLogo.component';

interface EmptyStateWithLogoProps {
  icon: React.ReactNode;
  title: MessageDescriptor;
  subtitle: MessageDescriptor;
}

export const EmptyStateWithLogo: React.FC<EmptyStateWithLogoProps> = ({ title, subtitle, icon }) => {
  const intl = useIntl();

  const formattedTitle = intl.formatMessage(title);
  const formattedSubtitle = intl.formatMessage(subtitle);

  return (
    <FullScreenPageWithLogo fullHeight>
      <EmptyState icon={icon} title={formattedTitle}>
        {formattedSubtitle}
      </EmptyState>
    </FullScreenPageWithLogo>
  );
};
