import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { CAPABILITY_SOFTPHONE } from '../actionFacade/action.facade.capabilities';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { ApplicationRoute } from '../constants';
import {
  enterpriseLinkingNeededForCurrentIntegrationSelector,
  isNarrowIntegrationSelector,
  personalLinkingNeededForCurrentIntegrationSelector,
} from '../integrations/integrations.selector';
import { selectedLineSelector } from '../settings/settings.selector';
import { SoftphoneSettings } from '../softphone/SoftphoneSettings.component';
import { Typography } from '../theme/Typography.component';
import { OnboardingNavigationButton } from './OnboardingNavigationButton.component';
import { OnboardingPage } from './OnboardingPage.component';
import { StepReadySection } from './StepReadySection.component';
import { TestCall } from './testCall/TestCall.component';
import { hasTestCallSucceededSelector } from './testCall/testCall.selectors';

const definedMessages = defineMessages({
  SELECTED_EXTENSION: {
    id: 'Onboarding.TestCall.SelectedExtension',
    defaultMessage: `You're using extension:`,
  },
  TEST_CALL_EXPLAINER_TEXT: {
    id: 'Onboarding.TestCall.Explainer',
    defaultMessage:
      'To test this extension, make sure GoToConnect is running on your browser, mobile device, desktop, or VoIP phone.',
  },
  TEST_CALL_TITLE: {
    id: 'Onboarding.TestCall.Title',
    defaultMessage: 'Make a test call',
  },
  TEST_CALL_SUCCESS: {
    id: 'Onboarding.TestCall.SuccessMessage',
    defaultMessage: 'Testing call was successful.',
  },
  SECTION_MANAGE_CALLS: {
    id: 'SettingsContainer.SectionManageCalls',
    defaultMessage: 'How to manage your calls?',
  },
  SECTION_MANAGE_CALLS_HELPERTEXT: {
    id: 'Onboarding.TestCall.ManageCalls.HelperText',
    defaultMessage: 'You can change this anytime in your settings.',
  },
});

export const OnboardingWithTestCall: FC = () => {
  const selectedLine = useSelector(selectedLineSelector);
  const hasToLinkIntegration = useSelector(personalLinkingNeededForCurrentIntegrationSelector);
  const hasToEnterpriseLinkIntegration = useSelector(enterpriseLinkingNeededForCurrentIntegrationSelector);
  const hasTestCallSucceeded = useSelector(hasTestCallSucceededSelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const isSoftphoneCapable = getActionFacade().isCapable(CAPABILITY_SOFTPHONE);

  const renderNavigation = () => {
    return (
      <Box>
        <Box mb={1}>
          {hasToLinkIntegration ? (
            <OnboardingNavigationButton variant="next" route={ApplicationRoute.ONBOARDING_LINKING} />
          ) : hasToEnterpriseLinkIntegration ? (
            <OnboardingNavigationButton variant="next" route={ApplicationRoute.ONBOARDING_ENTERPRISE_LINKING} />
          ) : (
            <OnboardingNavigationButton variant="done" route={ApplicationRoute.SETTINGS_ROUTE} />
          )}
        </Box>
        <OnboardingNavigationButton variant="previous" route={ApplicationRoute.ONBOARDING_LOGIN} />
      </Box>
    );
  };

  if (isSoftphoneCapable) {
    return (
      <OnboardingPage
        content={
          <>
            <Box>
              <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
                <FormattedMessage {...definedMessages.SECTION_MANAGE_CALLS} />
              </Typography>
              <Typography variant="caption-default-01">
                <FormattedMessage {...definedMessages.SECTION_MANAGE_CALLS_HELPERTEXT} />
              </Typography>
            </Box>
            <Box mt={6}>
              <SoftphoneSettings startSoftPhoneWhenEnabled={false} />
            </Box>
          </>
        }
        navigation={renderNavigation()}
      />
    );
  }

  return (
    <OnboardingPage
      content={
        <>
          <Box mb={6}>
            <FormattedMessage {...definedMessages.SELECTED_EXTENSION} />
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              {selectedLine?.number}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Box mb={3}>
              <Typography variant={isNarrowIntegration ? 'body-medium' : 'body-large'}>
                <FormattedMessage {...definedMessages.TEST_CALL_TITLE} />
              </Typography>
            </Box>
            {hasTestCallSucceeded ? (
              <StepReadySection ready={true}>
                <Typography variant="body-small">
                  <FormattedMessage {...definedMessages.TEST_CALL_SUCCESS} />
                </Typography>
              </StepReadySection>
            ) : (
              <>
                <Box mb={3}>
                  <Typography variant="body-small">
                    <FormattedMessage {...definedMessages.TEST_CALL_EXPLAINER_TEXT} />
                  </Typography>
                </Box>
                <TestCall />
              </>
            )}
          </Box>
        </>
      }
      navigation={renderNavigation()}
    />
  );
};
