import { Reducer } from 'redux';
import { ContactsActions } from '../actions/contacts';
import { SaveCallHistory } from '../calls/callEvents.action';
import { CallWithContact } from '../calls/calls.reducer';
import { IndexedById } from '../models';
import { EntitiesActions } from '../search/entities.action';
import { SalesforceActions } from '../salesforce/salesforce.actions';

export interface CallHistoryState {
  byId: IndexedById<CallWithContact>;
  allIds: string[];
}

export const initialState: CallHistoryState = {
  byId: {},
  allIds: [],
};
export const callHistoryReducer: Reducer<
  CallHistoryState,
  ContactsActions | SaveCallHistory | EntitiesActions | SalesforceActions
> = (state = initialState): CallHistoryState => {
  return state;
};

export default callHistoryReducer;
