import { SalesforceServiceWindtalkProxy } from './salesforceLightning.proxy.model';
import { SoftphoneActionCreator } from '../softphone/softphone.actioncreator';

export class SalesforceLightningSoftphoneActionCreator extends SoftphoneActionCreator {
  constructor(protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super();
  }
  async openMicrophonePermissionPage(): Promise<void> {
    await this.salesforceLightningProxy.hideSoftphonePanel();
    return this.salesforceLightningProxy.openMicrophonePermissionPage();
  }
}
