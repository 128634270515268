import { Middleware } from 'redux';
import { tracker } from '../analytics/tracker';
import { logger } from '../logging';

export const analyticsMiddleware: Middleware = () => (next) => (action) => {
  try {
    next(action);

    if (tracker.isEventTracked(action.type)) {
      const { label, eventName } = extractLabelAndEventName(action.type);
      if (!label || !eventName) {
        return logger.error('could not extract label and eventName from action type', { actionType: action.type });
      }

      tracker.trackEvent(label, eventName, action.payload || '');
    }
  } catch (e) {
    logger.error('Something wrong with analytics: ', e);
  }
};

function extractLabelAndEventName(actionType: string) {
  const actionFragments: string[] = actionType.split('/');

  if (actionFragments.length < 2) {
    return {};
  }

  // there can be actions with multiple '/' in it
  // in that case the first fragment will be the label
  // and the remaining is the action identifier
  const label = actionFragments[0];
  actionFragments.shift();
  const eventName = actionFragments.join('/');
  return { label, eventName };
}
