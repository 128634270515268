import { Button, Link } from '@getgo/chameleon-web/react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { defineTrackingEvents, AnalyticsCategory, AnalyticsAction } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { goToRoute } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { OnboardingNavigationButton } from '../onboarding/OnboardingNavigationButton.component';
import { OnboardingPage } from '../onboarding/OnboardingPage.component';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  TERMS_OF_SERVICE_HEADING: {
    id: 'TermsOfService.Heading',
    defaultMessage: 'Accept Terms of Service',
  },
  TERMS_OF_SERVICE_INSTRUCTION: {
    id: 'TermsOfService.Instruction',
    defaultMessage: 'To use GoToConnect for {integrationName}, read and accept the terms of service in your language.',
  },
  TERMS_OF_SERVICE_ACCEPT_BUTTON_LABEL: {
    id: 'TermsOfService.AcceptButton.Label',
    defaultMessage: 'Accept terms of service',
  },
});

const trackingEvents = defineTrackingEvents({
  ACCEPT_TOS: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Accept terms of service | button',
  },
  OPEN_TOS: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Terms of Service | {language} | link',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'block',
    fontWeight: 600,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
}));

export interface TermsOfServiceStateProps {
  integrationName: string;
  isNarrowIntegration: boolean;
}

export interface TermsOfServiceDispatchProps {
  acceptTermsOfService: () => void;
}

export const TermsOfServiceComponent: React.FC<TermsOfServiceStateProps & TermsOfServiceDispatchProps> = ({
  acceptTermsOfService,
  integrationName,
  isNarrowIntegration,
}) => {
  const classes = useStyles();

  const onAcceptTocClick = () => {
    acceptTermsOfService();
    newTracker.trackAnalyticsEvent(trackingEvents.ACCEPT_TOS);
    // There is check in the onboarding component that redirects to TOS when it's nt accepted
    // dispatching acceptTermsOfService action not executed synchronously, so we had to add set timeout
    setTimeout(() => {
      goToRoute(ApplicationRoute.ONBOARDING_LOGIN);
    }, 100);
  };

  const trackToSClick = (language: string) => {
    newTracker.trackAnalyticsEvent({ ...trackingEvents.OPEN_TOS, label: `Terms of Service | ${language} | link` });
  };

  return (
    <OnboardingPage
      content={
        <>
          <Box>
            <Typography variant={isNarrowIntegration ? 'heading-xsmall' : 'heading-small'}>
              <FormattedMessage {...definedMessages.TERMS_OF_SERVICE_HEADING} />
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'}>
              <FormattedMessage {...definedMessages.TERMS_OF_SERVICE_INSTRUCTION} values={{ integrationName }} />
            </Typography>
          </Box>
          <Box mt={2} flexGrow={1}>
            <Link
              className={classes.link}
              href="https://www.logmeininc.com/legal/terms-and-conditions"
              target="_blank"
              onClick={() => trackToSClick('en')}
            >
              English
            </Link>
            <Link
              className={classes.link}
              href="https://www.logmeininc.com/es/legal/terms-and-conditions"
              target="_blank"
              onClick={() => trackToSClick('es')}
            >
              Español (Spanish)
            </Link>
            <Link
              className={classes.link}
              href="https://www.logmeininc.com/de/legal/terms-and-conditions"
              target="_blank"
              onClick={() => trackToSClick('de')}
            >
              Deutsch (German)
            </Link>
            <Link
              className={classes.link}
              href="https://www.logmeininc.com/fr/legal/terms-and-conditions"
              target="_blank"
              onClick={() => trackToSClick('fr')}
            >
              Français (French)
            </Link>
            <Link
              className={classes.link}
              href="https://www.logmeininc.com/pt/legal/terms-and-conditions"
              target="_blank"
              onClick={() => trackToSClick('pt')}
            >
              Português (Portuguese)
            </Link>
          </Box>
        </>
      }
      navigation={
        <Box>
          <Box mb={1}>
            <Button onClick={onAcceptTocClick} variant="primary" fullwidth>
              <FormattedMessage {...definedMessages.TERMS_OF_SERVICE_ACCEPT_BUTTON_LABEL} />
            </Button>
          </Box>
          <OnboardingNavigationButton variant="previous" route={ApplicationRoute.ONBOARDING} />
        </Box>
      }
    />
  );
};
