import { CallEventsActionsType } from '@jive/realtime-events';
import { ActionOfType, addListeners, ListenerAction } from '../middlewares/listener.middleware';
import { isTestCallPendingSelector, isTestCallSelector } from '../onboarding/testCall/testCall.selectors';

type CallStartListenerAction = ListenerAction<
  ActionOfType<CallEventsActionsType.START_INCOMING_CALL | CallEventsActionsType.START_OUTGOING_CALL>
>;

type ConversationStartListenerAction = ListenerAction<
  ActionOfType<CallEventsActionsType.START_INCOMING_CONVERSATION | CallEventsActionsType.START_OUTGOING_CONVERSATION>
>;

export const addCallStartListener = (listenerAction: CallStartListenerAction) => {
  addListeners([CallEventsActionsType.START_INCOMING_CALL, CallEventsActionsType.START_OUTGOING_CALL])(
    (state, action) => {
      const isTestCallPending = isTestCallPendingSelector(state);
      const isTestCall = isTestCallSelector(action.payload.call.id)(state);

      if (isTestCallPending || isTestCall) {
        return;
      }

      return listenerAction(state, action);
    },
  );
};

export const addConversationStartListener = (listenerAction: ConversationStartListenerAction) => {
  addListeners([CallEventsActionsType.START_INCOMING_CONVERSATION, CallEventsActionsType.START_OUTGOING_CONVERSATION])(
    (state, action) => {
      const isTestCall = isTestCallSelector(action.payload.call.id)(state);

      if (isTestCall) {
        return;
      }

      return listenerAction(state, action);
    },
  );
};
