import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { deleteAllMessages } from '../../inAppNotification/message.action';
import {
  DoNotCallMessageComponent,
  DoNotCallMessageDispatchProps,
  DoNotCallMessageProps,
} from './DoNotCallMessage.component';
import { clickToCallRequest } from '../../calls/click-to-call.action';
import { Dispatch } from 'redux';

const mapDispatch = (dispatch: Dispatch): DoNotCallMessageDispatchProps => ({
  onCall: (contactName: string, phoneNumber: string) => {
    dispatch(deleteAllMessages());
    dispatch(
      clickToCallRequest({
        phoneNumber,
        contactName,
      }),
    );
  },
  onClose: () => {
    dispatch(deleteAllMessages());
  },
});

export default connect<{}, DoNotCallMessageDispatchProps, DoNotCallMessageProps, AppState>(
  null,
  mapDispatch,
)(DoNotCallMessageComponent);
