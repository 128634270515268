import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../components/LoadingSpinner';
import { EntityType } from '../constants';
import { createEntity, CreateEntityActionPayload } from '../search/entities.action';
import JiveForm from './JiveForm.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TextInputField } from './TextInputField.component';
import { newTracker } from '../analytics-new/tracker-new';
import { CallActionFormProps } from '../calls/CallPageForm.model';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { allCallSelector, callSelector } from '../calls/calls.selector';
import { isCreateEntityLoadingSelector } from '../search/entities.selector';

export type NewOpportunityFormProps = CallActionFormProps;

const trackingEvents = defineTrackingEvents({
  TOPIC_CHANGED: {
    category: AnalyticsCategory.Opportunity,
    label: 'Topic changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_OPPORTUNITY_CLICKED: {
    category: AnalyticsCategory.Opportunity,
    label: 'Add opportunity | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Opportunity,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
  OPPORTUNITY_FORM_PAGE_LOADED: {
    category: AnalyticsCategory.Opportunity,
    action: AnalyticsAction.PageLoaded,
    label: 'Create opportunity',
  },
});

const definedMessages = defineMessages({
  OPPORTUNITY_FORM_TITLE: {
    id: 'Opportunity.New.Form.Title',
    defaultMessage: 'Create Opportunity',
  },
  OPPORTUNITY_FORM_SAVE_BUTTON_TEXT: {
    id: 'Opportunity.New.Form.SaveButtonText',
    defaultMessage: 'Add Opportunity',
  },
  OPPORTUNITY_FORM_TOPIC_LABEL: {
    id: 'Opportunity.New.Form.Topic.Label',
    defaultMessage: 'Topic',
  },
});

const NewOpportunity: React.FC<NewOpportunityFormProps> = (props) => {
  const [topic, setTopic] = useState('');

  const { contactId, callId, onClose } = props;

  const activeCall = useSelector((state: AppState) => callSelector(state, callId));
  const isCreateEntityLoading = useSelector(isCreateEntityLoadingSelector);
  const call = useSelector((state: AppState) => allCallSelector(state, callId));
  const isActiveCall = (activeCall && !activeCall.endTime) as boolean;

  const dispatch = useDispatch();
  const createOpportunity = (payload: CreateEntityActionPayload) => dispatch(createEntity(payload));

  useEffect(() => {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.OPPORTUNITY_FORM_PAGE_LOADED);
  }, []);

  return (
    <JiveForm
      onCancelClick={() => onClose()}
      onSaveClick={() => {
        createOpportunity({
          isActiveCall,
          defaultFieldValues: {
            topic,
            // eslint-disable-next-line
            contactId: contactId!, // New opportunity button should only appear if contact is assigned
          },
          entityName: EntityType.OPPORTUNITY,
          callId: call.id,
        });
      }}
      canSubmit={!isCreateEntityLoading}
      saveButtonDescriptor={definedMessages.OPPORTUNITY_FORM_SAVE_BUTTON_TEXT}
      saveTrackingEvent={trackingEvents.ADD_OPPORTUNITY_CLICKED}
      cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
    >
      <Box mb={2}>
        <Typography variant="heading-xsmall">
          <FormattedMessage {...definedMessages.OPPORTUNITY_FORM_TITLE} />
        </Typography>
      </Box>
      <TextInputField
        name="opportunity-topic"
        trackingEvent={trackingEvents.TOPIC_CHANGED}
        labelDescription={definedMessages.OPPORTUNITY_FORM_TOPIC_LABEL}
        value={topic}
        onChange={(event) => setTopic(event.target.value)}
        maxLength={300}
      />
      <LoadingSpinner isLoading={isCreateEntityLoading}>
        <></>
      </LoadingSpinner>
    </JiveForm>
  );
};

export default NewOpportunity;
