export interface Vehicle {
  VIN: string;
  Make: string;
  Model: string;
  ModelYear: string;
  VehicleCost?: number;
  Odometer?: number;
  DateDelivered?: Date;
  LastServiceDate?: Date;
  WarrantyMonths?: number;
  WarrantyMiles?: number;
}

export enum LoadVehiclesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}
