import { PropTypes } from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { jiveIdSelector } from './authentication.selector';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box/Box';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';

const definedMessages = defineMessages({
  SIGNED_IN_AS: {
    id: 'Preferences.SignedInAs',
    defaultMessage: 'Signed in as:',
  },
});

interface OwnProps {
  align?: PropTypes.Alignment;
}

export const SignedInAs: React.FC<OwnProps> = ({ align }) => {
  const jiveId = useSelector(jiveIdSelector);
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);

  return (
    <Box textAlign={align}>
      <Typography variant="heading-xsmall">
        <FormattedMessage {...definedMessages.SIGNED_IN_AS} />
      </Typography>
      <Typography variant={isNarrowIntegration ? 'body-small' : 'body-medium'} style={{ wordBreak: 'break-all' }}>
        {jiveId}
      </Typography>
    </Box>
  );
};
