import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { OpenRepairOrder, ClosedRepairOrder, ClosedRepairOrderDetails, ServiceWriter } from './repairOrders.models';

export enum RepairOrderActionsType {
  LOAD_OPEN_REPAIR_ORDERS = 'openRepairOrders/load',
  LOAD_OPEN_REPAIR_ORDERS_SUCCESS = 'openRepairOrders/load/success',
  LOAD_OPEN_REPAIR_ORDERS_ERROR = 'openRepairOrders/load/error',
  LOAD_CLOSED_REPAIR_ORDERS = 'closedRepairOrders/load',
  LOAD_CLOSED_REPAIR_ORDERS_SUCCESS = 'closedRepairOrders/load/success',
  LOAD_CLOSED_REPAIR_ORDERS_ERROR = 'closedRepairOrders/load/error',
  LOAD_CLOSED_REPAIR_ORDER_DETAILS = 'closedRepairOrderDetails/load',
  LOAD_CLOSED_REPAIR_ORDER_DETAILS_SUCCESS = 'closedRepairOrderDetails/load/success',
  LOAD_CLOSED_REPAIR_ORDER_DETAILS_ERROR = 'closedRepairOrderDetails/load/error',
  LOAD_SERVICE_WRITERS = 'serviceWriters/load',
  LOAD_SERVICE_WRITERS_SUCCESS = 'serviceWriters/load/success',
  LOAD_SERVICE_WRITERS_ERROR = 'serviceWriters/load/error',
}

export const loadOpenRepairOrders = makeActionCreator(RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS)<{
  VIN: string;
}>();
export const loadOpenRepairOrdersSuccess = makeActionCreator(RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS_SUCCESS)<{
  VIN: string;
  repairOrders: OpenRepairOrder[];
}>();
export const loadOpenRepairOrdersError = makeActionCreator(RepairOrderActionsType.LOAD_OPEN_REPAIR_ORDERS_ERROR)<
  WithMessage & { VIN: string }
>();

export const loadClosedRepairOrders = makeActionCreator(RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS)<{
  VIN: string;
}>();
export const loadClosedRepairOrdersSuccess = makeActionCreator(
  RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS_SUCCESS,
)<{
  VIN: string;
  repairOrders: ClosedRepairOrder[];
}>();
export const loadClosedRepairOrdersError = makeActionCreator(RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDERS_ERROR)<
  WithMessage & { VIN: string }
>();

export const loadClosedRepairOrderDetails = makeActionCreator(RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS)<{
  repairOrderNumber: string;
  VIN: string;
}>();
export const loadClosedRepairOrderDetailsSuccess = makeActionCreator(
  RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS_SUCCESS,
)<{
  repairOrder: ClosedRepairOrderDetails;
  VIN: string;
}>();
export const loadClosedRepairOrderDetailsError = makeActionCreator(
  RepairOrderActionsType.LOAD_CLOSED_REPAIR_ORDER_DETAILS_ERROR,
)<WithMessage & { VIN: string; repairOrderNumber: string }>();

export const loadServiceWriters = makeActionCreator(RepairOrderActionsType.LOAD_SERVICE_WRITERS)();
export const loadServiceWritersSuccess = makeActionCreator(RepairOrderActionsType.LOAD_SERVICE_WRITERS_SUCCESS)<{
  serviceWriters: ServiceWriter[];
}>();
export const loadServiceWritersError = makeActionCreator(RepairOrderActionsType.LOAD_SERVICE_WRITERS_ERROR)<
  WithMessage
>();

export type RepairOrderActions =
  | ReturnType<typeof loadOpenRepairOrders>
  | ReturnType<typeof loadOpenRepairOrdersSuccess>
  | ReturnType<typeof loadOpenRepairOrdersError>
  | ReturnType<typeof loadClosedRepairOrders>
  | ReturnType<typeof loadClosedRepairOrdersSuccess>
  | ReturnType<typeof loadClosedRepairOrdersError>
  | ReturnType<typeof loadClosedRepairOrderDetails>
  | ReturnType<typeof loadClosedRepairOrderDetailsSuccess>
  | ReturnType<typeof loadClosedRepairOrderDetailsError>
  | ReturnType<typeof loadServiceWriters>
  | ReturnType<typeof loadServiceWritersSuccess>
  | ReturnType<typeof loadServiceWritersError>;
