import { makeActionCreator } from '../actions/actionCreators';
import { SettingsActionTypes } from '../constants';
import { DealerTrackEntitiesSettings } from '../cox/settings/cox.settings.models';
import './settings.listeners';
import { ZendeskSettings } from '../zendesk/settings/zendesk.settings.models';

export interface SelectLinePayload {
  lineId: string;
}

export interface SelectDevicePayload {
  lineId: string;
  deviceId: string;
}

export const openSettingsPage = makeActionCreator(SettingsActionTypes.OPEN_SETTINGS_PAGE)();
export const selectLine = makeActionCreator(SettingsActionTypes.SELECT_LINE)<string>();
export const selectDevice = makeActionCreator(SettingsActionTypes.SELECT_DEVICE)<SelectDevicePayload>();
export const selectLanguage = makeActionCreator(SettingsActionTypes.SELECT_LANGUAGE)<string>();
export const setAutomaticCallLogging = makeActionCreator(SettingsActionTypes.SET_AUTOMATIC_CALL_LOGGING)<boolean>();
export const setOpenCallLogAfterCall = makeActionCreator(SettingsActionTypes.SET_OPEN_CALL_LOG_AFTER_CALL)<boolean>();
export const setShowCallDispositionForm = makeActionCreator(SettingsActionTypes.SET_CALL_DISPOSITION_FORM)<boolean>();
export const setShowOpportunities = makeActionCreator(SettingsActionTypes.SET_OPPORTUNITIES)<boolean>();
export const setShowCases = makeActionCreator(SettingsActionTypes.SET_CASES)<boolean>();
export const setDealerTrackEntities = makeActionCreator(
  SettingsActionTypes.SET_DEALERTRACK_ENTITIES,
)<DealerTrackEntitiesSettings>();
export const setOpenContactInNewTab = makeActionCreator(SettingsActionTypes.SET_OPEN_CONTACT_IN_NEW_TAB)<boolean>();
export const setSoftphoneEnabled = makeActionCreator(SettingsActionTypes.SET_SOFTPHONE_ENABLED)<boolean>();
export const setWidgetAutoPopup = makeActionCreator(SettingsActionTypes.SET_WIDGET_AUTO_POPUP)<boolean>();
export const setZendeskSettings = makeActionCreator(SettingsActionTypes.SET_ZENDESK_SETTINGS)<ZendeskSettings>();

export type SettingsActions =
  | ReturnType<typeof openSettingsPage>
  | ReturnType<typeof selectLine>
  | ReturnType<typeof selectDevice>
  | ReturnType<typeof selectLanguage>
  | ReturnType<typeof setAutomaticCallLogging>
  | ReturnType<typeof setOpenCallLogAfterCall>
  | ReturnType<typeof setShowCallDispositionForm>
  | ReturnType<typeof setShowOpportunities>
  | ReturnType<typeof setShowCases>
  | ReturnType<typeof setDealerTrackEntities>
  | ReturnType<typeof setSoftphoneEnabled>
  | ReturnType<typeof setOpenContactInNewTab>
  | ReturnType<typeof setZendeskSettings>
  | ReturnType<typeof setWidgetAutoPopup>;
