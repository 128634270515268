import { RealtimeActionTypes } from '@jive/realtime-events';
import { ContactActionsType } from '../actions/contacts';
import { AuthenticationActionsType } from '../authentication/authentication.actions';
import { CommunicationActionTypes } from '../clio/communication/communication.action';
import { ConnectivityActionTypes } from '../connectivity/connectivity.action';
import { ClickToCallActionTypes, SettingsActionTypes } from '../constants';
import { FeedbackActionsType } from '../feedback/feedback.model';
import { NoteActionsType } from '../notes/notes.actions';
import { AppState } from '../reducers';
import { EntitiesActionsType } from '../search/entities.action';
import { TermsOfServiceActionTypes } from '../termsOfService/termsOfService.action';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { FieldsObject, TrackerNames, EventArgs } from 'react-ga';
import { PhoneActionsType } from '../phone/phone.actions';

export interface GATracker {
  set(fieldsObject: FieldsObject, trackerNames?: TrackerNames): void;
  send(fieldsObject: FieldsObject, trackerNames?: TrackerNames): void;
  pageview(path: string, trackerNames?: TrackerNames, title?: string): void;
  event(args: EventArgs, trackerNames?: TrackerNames): void;
}

export enum TrackingEventLabels {
  ClickToCall = 'clickToCall',
  ShowNotesInCrm = 'ShowNotesInCrm',
  AddNote = 'AddNote',
}

export enum TrackingEventNames {
  Success = 'Success',
  Click = 'Click',
  Error = 'Error',
  OpenGoToConnectSuccess = 'openGoToConnectSuccess',
  NoCallEstablishedWarning = 'noCallEstablishedWarning',
}

export const AnalyticsWhiteListEvents: string[] = [
  SettingsActionTypes.SELECT_LINE,
  AuthenticationActionsType.LOGOUT_SUCCESS,
  AuthenticationActionsType.LOGIN_SUCCESS,
  SettingsActionTypes.SELECT_LANGUAGE,
  PhoneActionsType.CALL,
  ClickToCallActionTypes.CALL_REQUEST,
  ClickToCallActionTypes.CALL_SUCCESS,
  ClickToCallActionTypes.CALL_ERROR,
  NoteActionsType.ADD_NOTE_SUCCESS,
  NoteActionsType.ADD_NOTE_ERROR,
  CommunicationActionTypes.SAVE_COMMUNICATION_SUCCESS,
  CommunicationActionTypes.SAVE_COMMUNICATION_ERROR,
  EntitiesActionsType.CREATE_CONTACT_SUCCESS,
  EntitiesActionsType.OPEN_ENTITY_SUCCESS,
  EntitiesActionsType.OPEN_ENTITY_ERROR,
  ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_SUCCESS,
  ContactActionsType.UPDATE_ENTITY_PHONE_NUMBER_ERROR,
  FeedbackActionsType.OPEN_PAGE,
  TermsOfServiceActionTypes.ACCEPT_TERMS_OF_SERVICE,
  RealtimeActionTypes.REALTIME_OFFLINE,
  ConnectivityActionTypes.APP_OFFLINE,
  ConnectivityActionTypes.APP_RESTART,
];

export interface Tracker {
  /**
   * @param  {Function} getState - function to get the state of the app
   * @returns void
   */

  initialize(getState: () => AppState): void;

  /**
   * @param  {string} location - Page location to track
   * @returns void
   */
  trackPageView(location: string): void;

  /**
   * @param  {string} eventLabel - label of the action ex: clickToCall
   * @param  {string} eventAction - The action itself ex: success
   * @param  {string} payload - Additional payload
   * @returns void
   */
  trackEvent(eventLabel: string, eventAction: string, payload?: string): void;

  /**
   * @param  {string} actionType - The action which inspected if needed to track
   * @returns boolean
   */
  isEventTracked(actionType: string): boolean;
}

export interface NewTracker {
  /**
   * @param  {string} gaTracker - Google Analytics tracker object
   * @param  {string} trackingName - tracking name to use for GA sends. Will use default if empty
   * @param  {string} appVersion - version of the app
   * @param  {Function} getState - function to get the state of the app
   * @returns void
   */
  initialize(gaTracker: GATracker, trackingName: string, appVersion: string, getState: () => AppState): void;

  /**
   * @param {event} TrackingEvent Tracking event data
   * @returns void
   */
  trackAnalyticsEvent(event: TrackingEvent): void;

  /**
   * @returns void
   */
  trackPageView(): void;
}

// In tracker-new:
// dimension1: integration,
// dimension2: organizationId,
// dimension3: userId,
// dimension4: appVersion,
// dimension5: pageType,

// In tracker:
// dimension1: integration,
// dimension2: organizationId,
// dimension3: userIdHash,
// dimension4: payload, //can be anything

export interface GoogleAnalyticsPayload {
  label: string;
  action: string;
  category: string;
  value?: number;
  dimension1: string;
  dimension2: string;
  dimension3: string;
  dimension4: string;
  dimension5?: string;
}
