import type { JifTenantPbx } from '@jive/core';
import { createSelector } from 'reselect';
import { selectedOrganizationIdSelector } from '../settings/settings.selector';
import { jiveEntityArraySelector, jiveEntityResourceSelector } from './jiveEntities.selector';

export const pbxesResourceSelector = jiveEntityResourceSelector<JifTenantPbx>('pbxes');
export const pbxesArraySelector = jiveEntityArraySelector<JifTenantPbx>('pbxes');

export const selectedPbxSelector = createSelector(
  selectedOrganizationIdSelector,
  pbxesResourceSelector,
  (organizationId, pbxes) => pbxes && pbxes.byId[organizationId],
);

export const selectedPbxUserIdSelector = createSelector(
  selectedPbxSelector,
  (pbx) => pbx && pbx.userInfo && pbx.userInfo.userId,
);

export const selectedPbxPhoneNumbersSelector = createSelector(selectedPbxSelector, (pbx) => pbx?.phoneNumbers ?? []);

export const smsCapablePhoneNumbersSelector = createSelector(selectedPbxPhoneNumbersSelector, (phoneNumbers) =>
  phoneNumbers.filter((pn) => pn.receiveSMS || pn.sendSMS).map((pn) => pn.dialstring),
);
