import { CircularProgress, createStyles, List, Theme, withStyles, WithStyles } from '@material-ui/core';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import LoadingSpinner from '../components/LoadingSpinner';
import { GetMoreNotesRequestState, GetNotesRequestState, Note } from './notes.models';
import { NotesListDispatchProps, NotesListStateProps } from './NotesList.container';
import { AddIcon } from '@getgo/chameleon-icons/react';
import { goToCallActionRoute } from '../browserHistory';
import { CallActionRoutes, EntityType } from '../constants';
import { CallContextListItem } from '../calls/CallContextListItem.component';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { OpenNotesCapableFacade, CAPABILITY_OPEN_NOTES } from '../actionFacade/action.facade.capabilities';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { CallContextPanelProps } from '../calls/CallContextPanel.models';
import { Typography } from '../theme/Typography.component';
import { OpenInCRMButton } from '../components/OpenInCRMButton.component';
import { Box } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: theme.spacing(2),
      alignItems: 'flex-start',
    },
    meta: {
      fontSize: '11px',
      fontWeight: 300,
    },
    loadMoreButton: {
      marginTop: '10px',
    },
  });

const definedMessages = defineMessages({
  NOTES_LIST_EMPTY_MESSAGE: {
    id: 'Notes.List.Empty',
    defaultMessage: 'This contact has no notes yet.',
    description: 'Message that is displayed when the selected contact has no notes in the CRM.',
  },
  NOTES_LIST_LOAD_MORE_BUTTON: {
    id: 'Notes.List.LoadMore',
    defaultMessage: 'Load more notes',
    description: 'Button text to load more notes',
  },
  NOTES_LIST_CREATE_NOTE_BUTTON: {
    id: 'Notes.List.CreateNote.Button.Label',
    defaultMessage: 'Add a note',
  },
});

const trackingEvents = defineTrackingEvents({
  CREATE_NOTE_BUTTON_CLICKED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.ItemClicked,
    label: 'Create note | button',
  },
  LOAD_MORE_NOTES_BUTTON_CLICKED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.ItemClicked,
    label: 'Load more notes | button',
  },
  OPEN_NOTE_BUTTON_CLICKED: {
    category: AnalyticsCategory.Note,
    action: AnalyticsAction.ItemClicked,
    label: 'Open note in CRM | button',
  },
});

class NotesList extends Component<
  NotesListStateProps & NotesListDispatchProps & WithStyles<typeof styles> & CallContextPanelProps
> {
  componentDidMount(): void {
    const { getNotes, contact } = this.props;

    if (contact) {
      getNotes(contact.id);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.renderCreateNoteButton()}
        {this.renderList()}
      </div>
    );
  }

  renderCreateNoteButton() {
    const { callId } = this.props;
    return (
      <TrackedButton
        fullwidth
        id="createNote"
        variant="secondary"
        trackingEvent={trackingEvents.CREATE_NOTE_BUTTON_CLICKED}
        onClick={() => goToCallActionRoute(callId, CallActionRoutes[EntityType.NOTE])}
        leadingIcon={<AddIcon />}
      >
        <FormattedMessage {...definedMessages.NOTES_LIST_CREATE_NOTE_BUTTON} />
      </TrackedButton>
    );
  }

  renderList() {
    const { requestState } = this.props;

    switch (requestState) {
      case GetNotesRequestState.INITIAL:
        return null; // Do not render anything.

      case GetNotesRequestState.LOADING:
        return <div>{this.renderLoadingProgressIndicator()}</div>;

      case GetNotesRequestState.LOADED:
        const notes: Note[] = this.props.notes;
        return (
          <div style={{ alignSelf: 'stretch' }}>
            {notes.length > 0 ? this.renderNoteList(notes) : this.renderNoNotesMessage()}
          </div>
        );

      case GetNotesRequestState.FAILED:
        return null; // The snack bar will display the error message.
    }
  }

  renderLoadingProgressIndicator(): JSX.Element {
    return (
      <LoadingSpinner isLoading={true}>
        <></>
      </LoadingSpinner>
    );
  }

  renderNoNotesMessage(): JSX.Element {
    return (
      <Box mt={1} mr={1} mb={4} ml={1}>
        <Typography variant="caption-default-01" color="text-02">
          <FormattedMessage {...definedMessages.NOTES_LIST_EMPTY_MESSAGE} />
        </Typography>
      </Box>
    );
  }

  renderNoteContent(content: string): React.ReactNode {
    if (!content) {
      return null;
    }

    const parts = content.split(new RegExp(`({lineBreak})`, 'gm'));
    return (
      <>
        {parts.map((part, i) =>
          part === '{lineBreak}' ? (
            <br key={i} />
          ) : (
            <Box mt={1}>
              <Typography key={i} color="text-01" variant="caption-default-01">
                {part}
              </Typography>
            </Box>
          ),
        )}
      </>
    );
  }

  renderNoteList(notes: Note[]): JSX.Element {
    const { classes } = this.props;
    const actionFacade = getActionFacade();
    const isOpeningNotesSupported = actionFacade.isCapable(CAPABILITY_OPEN_NOTES);

    return (
      <>
        <List dense disablePadding>
          {notes.map((note: Note) => (
            <CallContextListItem
              key={note.id}
              id={note.id}
              className={'note'}
              primary={
                <>
                  <Box mb={1}>
                    <Typography variant="body-medium">{note.subject}</Typography>
                  </Box>
                  <span className={classes.meta}>{format(new Date(note.createdAtIso8601), 'PPPp')}</span>
                </>
              }
              secondary={this.renderNoteContent(note.content)}
              secondaryActionButton={
                isOpeningNotesSupported ? (
                  <Box mt={2} mr={2}>
                    <OpenInCRMButton
                      onClick={() => (actionFacade as OpenNotesCapableFacade).openNote(note.id)}
                      trackingEvent={trackingEvents.OPEN_NOTE_BUTTON_CLICKED}
                    />
                  </Box>
                ) : null
              }
            />
          ))}
        </List>
        {this.renderLoadMoreButton()}
      </>
    );
  }

  renderLoadMoreButton() {
    const { classes, canLoadMoreNotes, moreRequestState, getMoreNotesAction } = this.props;

    if (!canLoadMoreNotes) {
      return;
    }

    return (
      <TrackedButton
        fullwidth
        className={classes.loadMoreButton}
        id="loadMoreNotes"
        variant="secondary"
        onClick={() => getMoreNotesAction()}
        disabled={moreRequestState === GetMoreNotesRequestState.LOADING}
        trackingEvent={trackingEvents.LOAD_MORE_NOTES_BUTTON_CLICKED}
      >
        {moreRequestState === GetMoreNotesRequestState.LOADING ? (
          <CircularProgress size={26} />
        ) : (
          <FormattedMessage {...definedMessages.NOTES_LIST_LOAD_MORE_BUTTON} />
        )}
      </TrackedButton>
    );
  }
}

export default withStyles(styles)(NotesList);
