import { AppAction, makeActionCreator } from '../actions/actionCreators';
import { DialogBadge, DialogStyles, DialogTexts } from './dialog.reducer';
import { DialogBodyNames } from './DialogBodies';
import { TrackingEvent } from '../analytics-new/analytics.models';

export enum DialogActionsType {
  OPEN = 'dialog/open',
  CLOSE = 'dialog/close',
  RESET = 'dialog/reset',
}

export type OpenDialogPayload = {
  dialogId?: string;
  confirmAction?: AppAction;
  confirmTrackingEvent?: TrackingEvent;
  cancellable?: boolean;
  cancelTrackingEvent?: TrackingEvent;
  hideCancelButton?: boolean;
  badge?: DialogBadge;
  closeCallback?: (params: { isConfirmed: boolean }) => void;
  dialogStyles?: DialogStyles;
} & ({ body: JSX.Element } | { bodyName: DialogBodyNames } | { texts: DialogTexts });

export interface CloseDialogPayload {
  dialogId?: string;
}

export const openDialog = makeActionCreator(DialogActionsType.OPEN)<OpenDialogPayload>();
export const closeDialog = makeActionCreator(DialogActionsType.CLOSE)<CloseDialogPayload>();
export const resetDialog = makeActionCreator(DialogActionsType.RESET)();

export type OpenDialogAction = ReturnType<typeof openDialog>;
export type CloseDialogAction = ReturnType<typeof closeDialog>;
export type ResetDialogAction = ReturnType<typeof resetDialog>;

export type DialogActions = OpenDialogAction | CloseDialogAction | ResetDialogAction;
