import { logger } from '../../logging';

export const convertRedtailDateToIso8601String = (redtailDate: string): string => {
  const matches = /\/Date\((\d*)([-+]\d*)\)\//gm.exec(redtailDate);

  if (!matches || matches.length !== 3) {
    logger.error('Cannot parse redtail date', { redtailDate });
    return '';
  }

  let dateEpoch = Number(matches[1]);
  const timeZoneDirection = matches[2][0];
  const timezoneHours = Number(matches[2].substring(1, 3)) * Number(`${timeZoneDirection}1`);
  const timezoneMinutes = Number(matches[2].substring(3)) * Number(`${timeZoneDirection}1`);

  dateEpoch += timezoneHours * 60 * 60 * 1000;
  dateEpoch += timezoneMinutes * 60 * 1000;

  return new Date(dateEpoch).toISOString();
};

export const sanitizeRedtailNote = (note: string): string => {
  // replace <br/>s with {lineBreak} placeholders
  // display minimalistic lists
  // and remove all other html markup
  try {
    const prettyNote = note
      .replace(/<br\s?\/>/gim, '{lineBreak}')
      .replace(/<ul>/gim, '{lineBreak}')
      .replace(/<li>([^<]*)<\/li>/gim, '• $1{lineBreak}')
      .replace(/<[^>]*>/gim, '')
      .replace(/<\/p>/gim, '{lineBreak}')
      .replace(/<tbody>/gim, '{lineBreak}')
      .replace(/<\/tbody>/gim, '{lineBreak}')
      .replace(/<\/tr>/gim, '{lineBreak}')
      .replace(/<ul>/gim, '{lineBreak}')
      .replace(/<[^>]*>/gim, ''); // remove remaining html tags

    // get text from the remaining string (ex: make DOMParser replace &lt; with <)
    return getTextFromHtml(prettyNote) ?? '';
  } catch (error) {
    logger.error('Exception during sanitizing Redtail notes', error, { note });
    return '';
  }
};

function getTextFromHtml(content: string) {
  // DOMParser won't run any scripts; use it only to extract the encoded string content
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<!doctype html><body>${content}</body>`, 'text/html');
  return dom.body.textContent;
}
