import { combineReducers } from 'redux';
import { ClickToCallActionTypes } from '../constants';
import errorReducer from '../reducers/error';
import loadingReducer from '../reducers/loading';

const clickToCallReducer = combineReducers({
  isLoading: loadingReducer(ClickToCallActionTypes.CALL),
  hasError: errorReducer(ClickToCallActionTypes.CALL),
});

export default clickToCallReducer;
