import React, { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getNumberOfTheOtherParty } from '../calls/call.helper';
import { CallWithContact } from '../calls/calls.reducer';
import LoadingSpinner from '../components/LoadingSpinner';
import { EntityType } from '../constants';
import { CreateEntityActionPayload } from '../search/entities.action';
import JiveForm from './JiveForm.component';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { TextInputField } from './TextInputField.component';
import { newTracker } from '../analytics-new/tracker-new';
import { CallActionFormProps } from '../calls/CallPageForm.model';
import { Typography } from '../theme/Typography.component';
import Box from '@material-ui/core/Box';

export interface NewContactFormStateProps {
  call: CallWithContact;
  isCreateEntityLoading: boolean;
  isActiveCall: boolean;
}

export interface NewContactFormDispatchProps {
  createContact: (payload: CreateEntityActionPayload) => void;
}

export type NewContactFormProps = CallActionFormProps;

interface NewContactFormState {
  firstName: string;
  lastName: string;
}

const trackingEvents = defineTrackingEvents({
  FIRST_NAME_CHANGED: {
    category: AnalyticsCategory.Contact,
    label: 'Firstname changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  LAST_NAME_CHANGED: {
    category: AnalyticsCategory.Contact,
    label: 'Lastname changed | textfield',
    action: AnalyticsAction.ItemChanged,
  },
  ADD_CONTACT_CLICKED: {
    category: AnalyticsCategory.Contact,
    label: 'Add contact | button',
    action: AnalyticsAction.ItemClicked,
  },
  CANCEL_CLICKED: {
    category: AnalyticsCategory.Contact,
    label: 'Cancel | button',
    action: AnalyticsAction.ItemClicked,
  },
  CONTACT_FORM_PAGE_LOADED: {
    category: AnalyticsCategory.Contact,
    action: AnalyticsAction.PageLoaded,
    label: 'Create contact',
  },
});

const definedMessages = defineMessages({
  CONTACT_FORM_TITLE: {
    id: 'Contact.New.Form.Title',
    defaultMessage: 'Create Contact',
  },
  CONTACT_FORM_SAVE_BUTTON_TEXT: {
    id: 'Contact.New.Form.SaveButtonText',
    defaultMessage: 'Add Contact',
  },
  CONTACT_FORM_FIRST_NAME_LABEL: {
    id: 'Contact.New.Form.FirstName.Label',
    defaultMessage: 'First Name',
  },
  CONTACT_FORM_LAST_NAME_LABEL: {
    id: 'Contact.New.Form.LastName.Label',
    defaultMessage: 'Last Name',
  },
});

class NewContactForm extends Component<
  NewContactFormProps & NewContactFormStateProps & NewContactFormDispatchProps,
  NewContactFormState
> {
  state = {
    firstName: '',
    lastName: '',
  };

  componentDidMount() {
    newTracker.trackPageView();
    newTracker.trackAnalyticsEvent(trackingEvents.CONTACT_FORM_PAGE_LOADED);
  }

  handleChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  render() {
    const { firstName, lastName } = this.state;
    const { createContact, call, isCreateEntityLoading } = this.props;
    const canSubmitValidation = firstName.length > 0 && lastName.length > 0;

    return (
      <JiveForm
        onCancelClick={() => this.props.onClose()}
        onSaveClick={() => {
          createContact({
            isActiveCall: this.props.isActiveCall,
            defaultFieldValues: {
              phoneNumber: getNumberOfTheOtherParty(call),
              firstName,
              lastName,
            },
            entityName: EntityType.CONTACT,
            callId: call.id,
          });
        }}
        canSubmit={!isCreateEntityLoading && canSubmitValidation}
        saveButtonDescriptor={definedMessages.CONTACT_FORM_SAVE_BUTTON_TEXT}
        saveTrackingEvent={trackingEvents.ADD_CONTACT_CLICKED}
        cancelTrackingEvent={trackingEvents.CANCEL_CLICKED}
      >
        <Box mb={2}>
          <Typography variant="heading-xsmall">
            <FormattedMessage {...definedMessages.CONTACT_FORM_TITLE} />
          </Typography>
        </Box>
        <TextInputField
          name="contact-first-name"
          trackingEvent={trackingEvents.FIRST_NAME_CHANGED}
          labelDescription={definedMessages.CONTACT_FORM_FIRST_NAME_LABEL}
          value={firstName}
          onChange={this.handleChange('firstName')}
          maxLength={32}
        />
        <TextInputField
          name="contact-last-name"
          trackingEvent={trackingEvents.LAST_NAME_CHANGED}
          labelDescription={definedMessages.CONTACT_FORM_LAST_NAME_LABEL}
          value={lastName}
          onChange={this.handleChange('lastName')}
          maxLength={32}
        />
        <LoadingSpinner isLoading={isCreateEntityLoading}>
          <></>
        </LoadingSpinner>
      </JiveForm>
    );
  }
}
export default NewContactForm;
