import React from 'react';
import { defineMessages } from 'react-intl';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { goToCallActionRoute } from '../../browserHistory';
import { getNumberOfTheOtherParty } from '../../calls/call.helper';
import {
  LogCallButton,
  NewAccountButton,
  NewCaseButton,
  NewContactButton,
  NewOpportunityButton,
} from '../../calls/callActions';
import { NewLeadButton } from '../../calls/callActions/NewLeadButton.component';
import { CallActionRoutes, EntityType } from '../../constants';
import { Entity } from '../../models';
import { createEntity } from '../../search/entities.action';
import { SalesforceLightningActionFacade } from '../salesforceLightning.actionfacade';
import { BusinessInactiveIcon } from '@getgo/chameleon-icons/react';

const availableActions = {
  [EntityType.ACCOUNT]: <NewAccountButton />,
  [EntityType.CONTACT]: (
    <NewContactButton
      action={({ call, contact, dispatch }) => {
        if (!call) {
          return;
        }

        const isActiveCall = !call.endTime;
        const phoneNumber = getNumberOfTheOtherParty(call);
        dispatch(
          createEntity({
            isActiveCall,
            entityName: EntityType.CONTACT,
            defaultFieldValues: { Phone: phoneNumber, AccountId: contact!.id },
            callId: call.id,
          }),
        );
      }}
    />
  ),
  [EntityType.CASE]: (
    <NewCaseButton
      action={({ contact }) => getActionFacade<SalesforceLightningActionFacade>().createCaseForContact(contact!)}
    />
  ),
  [EntityType.OPPORTUNITY]: (
    <NewOpportunityButton
      action={({ contact }) => getActionFacade<SalesforceLightningActionFacade>().createOpportunityForContact(contact!)}
    />
  ),
};

const definedMessage = defineMessages({
  CREATE_NEW_ACCOUNT: {
    id: 'Call.FullWidth.NewAccount',
    defaultMessage: 'Create a new account',
  },
  CREATE_NEW_CONTACT: {
    id: 'Call.FullWidth.NewContact',
    defaultMessage: 'Create a new contact',
  },
  CREATE_NEW_LEAD: {
    id: 'Call.FullWidth.NewLead',
    defaultMessage: 'Create a new lead',
  },
});

const noMatchActions = {
  [EntityType.ACCOUNT]: (
    <NewAccountButton
      variant="secondary"
      label={definedMessage.CREATE_NEW_ACCOUNT}
      leadingIcon={<BusinessInactiveIcon style={{ fontSize: 'var(--goto-icon-size)' }} />}
    />
  ),
  [EntityType.CONTACT]: <NewContactButton variant="secondary" label={definedMessage.CREATE_NEW_CONTACT} />,
  [EntityType.LEAD]: <NewLeadButton variant="secondary" label={definedMessage.CREATE_NEW_LEAD} />,
};

export const salesForceCallActionsProvider = {
  getPrimaryAction: (_entityInCall?: Entity) => {
    return (
      <LogCallButton action={({ call }) => goToCallActionRoute(call!.id, CallActionRoutes[EntityType.CALL_LOG])} />
    );
  },
  getSecondaryActions: (mimissingObjectAccessRights: EntityType[], entityInCall?: Entity) => {
    if (!entityInCall) {
      const actions = [noMatchActions[EntityType.CONTACT], noMatchActions[EntityType.ACCOUNT]];

      if (!mimissingObjectAccessRights.includes(EntityType.LEAD)) {
        actions.push(noMatchActions[EntityType.LEAD]);
      }

      return actions;
    }

    switch (entityInCall.type) {
      case EntityType.CONTACT:
        const contactActions = [availableActions[EntityType.ACCOUNT]];

        if (!mimissingObjectAccessRights.includes(EntityType.CASE)) {
          contactActions.push(availableActions[EntityType.CASE]);
        }

        if (!mimissingObjectAccessRights.includes(EntityType.OPPORTUNITY)) {
          contactActions.push(availableActions[EntityType.OPPORTUNITY]);
        }

        return contactActions;
      case EntityType.ACCOUNT:
        const accountActions = [availableActions[EntityType.CONTACT]];

        if (!mimissingObjectAccessRights.includes(EntityType.CASE)) {
          accountActions.push(availableActions[EntityType.CASE]);
        }

        if (!mimissingObjectAccessRights.includes(EntityType.OPPORTUNITY)) {
          accountActions.push(availableActions[EntityType.OPPORTUNITY]);
        }

        return accountActions;
      case EntityType.LEAD:
        const leadActions: JSX.Element[] = [];

        if (!mimissingObjectAccessRights.includes(EntityType.CASE)) {
          leadActions.push(availableActions[EntityType.CASE]);
        }

        if (!mimissingObjectAccessRights.includes(EntityType.OPPORTUNITY)) {
          leadActions.push(availableActions[EntityType.OPPORTUNITY]);
        }

        return leadActions;
      default:
        return [];
    }
  },
};
