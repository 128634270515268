import { Action, Reducer } from 'redux';
import { PersistConfig, PersistPartial, persistReducer, WebStorage } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import localForage from 'localforage';
import { migrator, STORE_VERSION } from './migrations';
import { stateToPersist } from './reducers';
import { openDB } from 'idb';
import { logger } from './logging';

const DB_NAME = 'GOTOCONNECT_EMBEDDED_INTEGRATIONS_REDUX_PERSIST';
const REDUX_PERSIST_STORE = 'reduxPersist';
const REDUX_PERSIST_ROOT_KEY = 'root';
const VERSION = 1;

const persistConfig: PersistConfig = {
  key: REDUX_PERSIST_ROOT_KEY,
  version: STORE_VERSION,
  storage: createStorage(),
  whitelist: stateToPersist as string[],
  migrate: migrator,
};

/**
 * Using Indexed DB in third-party frames fails in older Safari versions. In case of Safari we fallback to localStorage.
 */
function isIndexedDbSupported(): boolean {
  return !/Safari/i.test(navigator.userAgent) || /Chrome/i.test(navigator.userAgent);
}

function createStorage(): WebStorage | LocalForage {
  if (isIndexedDbSupported()) {
    return localForage.createInstance({
      driver: localForage.INDEXEDDB,
      name: DB_NAME,
      storeName: REDUX_PERSIST_STORE,
    });
  } else {
    return storage;
  }
}

export function initReduxPersistStorage(): void {
  openDB(DB_NAME, VERSION, {
    async upgrade(db, oldversion) {
      // !IMPORTANT: do not add break statements here. The point is to do all necessary changes between version
      // ex: new version is #4 and the user has version #1 of the database. We need to run all migration scripts between #1 and #4.
      switch (oldversion) {
        case 0:
          const reduxPersistStore = db.createObjectStore(REDUX_PERSIST_STORE);
          const key = `persist:${REDUX_PERSIST_ROOT_KEY}`;
          const existingState = localStorage.getItem(key);
          if (existingState) {
            await reduxPersistStore.add(existingState, key);
          }
        // eslint-disable-next-line no-fallthrough
        case 1:
          break;
        default:
          logger.error(`Unknown indexedDB version: ${oldversion}`);
      }
    },
    blocked: () => {
      // seems an older version of this app is running in another tab.
      // upgrade will be called when all tabs using the old version reload or close
      console.warn('storage.service is in blocked state. Waiting for other tabs to close...');
    },
    blocking: () => {
      // seems the user just opened the app in a new tab where the db version is newer
      // we need to reload this tab to unblock the upgrade process
      window.location.reload();
    },
  }).catch((reason) => logger.error(`Failed to open database: ${DB_NAME}`, reason));
}

export function applyPersistReducer<S, A extends Action>(baseReducer: Reducer<S, A>): Reducer<S & PersistPartial, A> {
  return persistReducer(persistConfig, baseReducer);
}
