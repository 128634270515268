import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import { tokens } from '@getgo/chameleon-core';
import { Button, ButtonProps } from '@getgo/chameleon-web/react';
import Card from '@material-ui/core/Card';
import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { TrackingEvent } from '../analytics-new/analytics.models';
import { TrackedButton } from '../analytics-new/TrackedButton.component';

const definedMessages = defineMessages({
  FORM_GENERAL_SAVE_BUTTON_TEXT: {
    id: 'Form.General.SaveButtonText',
    defaultMessage: 'Save',
    description: 'General save button text',
  },
  FORM_GENERAL_CANCEL_BUTTON_TEXT: {
    id: 'Form.General.CancelButtonText',
    defaultMessage: 'Cancel',
    description: 'General Cancel button text',
  },
});

const styles = () =>
  createStyles({
    card: {
      marginBottom: '5px',
      padding: '6px 8px',
      flex: '1 1 1px',
      flexDirection: 'column',
      display: 'flex',
      overflowY: 'auto',
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: '8px 0',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flexGrow: 1,
      justifyContent: 'space-between',
      background: tokens.uiBackground,
    },
    button: {
      flexGrow: 1,
    },
  });

export interface JiveFormProps {
  onCancelClick: () => void;
  onSaveClick: () => void;
  canSubmit: boolean;
  saveButtonDescriptor?: MessageDescriptor;
  cancelButtonDescriptor?: MessageDescriptor;
  saveTrackingEvent: TrackingEvent;
  cancelTrackingEvent: TrackingEvent;
  variant?: 'card' | 'no-card';
}

const JiveForm: React.FC<JiveFormProps & WithStyles<typeof styles>> = ({
  onCancelClick,
  onSaveClick,
  children,
  classes,
  canSubmit,
  saveButtonDescriptor,
  cancelButtonDescriptor,
  saveTrackingEvent,
  cancelTrackingEvent,
  variant,
}) => {
  const saveBtnDescriptor = saveButtonDescriptor || definedMessages.FORM_GENERAL_SAVE_BUTTON_TEXT;
  const cancelBtnDescriptor = cancelButtonDescriptor || definedMessages.FORM_GENERAL_CANCEL_BUTTON_TEXT;
  const showCard = variant == null || variant === 'card';

  const cancelButtonProps: ButtonProps = {
    className: classes.button,
    variant: 'secondary',
    style: { marginRight: '8px' },
    onClick: onCancelClick,
  };

  const saveButtonProps: ButtonProps = {
    className: classes.button,
    disabled: !canSubmit,
    variant: 'primary',
    onClick: onSaveClick,
  };

  return (
    <div className={classes.form}>
      {showCard ? <Card className={classes.card}>{children}</Card> : <>{children}</>}
      <div data-cy="jive-form-button-row" className={classes.buttonRow}>
        {cancelTrackingEvent ? (
          <TrackedButton {...cancelButtonProps} trackingEvent={cancelTrackingEvent}>
            <FormattedMessage {...cancelBtnDescriptor} />
          </TrackedButton>
        ) : (
          <Button {...cancelButtonProps}>
            <FormattedMessage {...cancelBtnDescriptor} />
          </Button>
        )}
        {saveTrackingEvent ? (
          <TrackedButton {...saveButtonProps} trackingEvent={saveTrackingEvent}>
            <FormattedMessage {...saveBtnDescriptor} />
          </TrackedButton>
        ) : (
          <Button {...saveButtonProps}>
            <FormattedMessage {...saveBtnDescriptor} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(JiveForm);
