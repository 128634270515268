import { Store } from 'redux';
import { logger } from '../../logging';
import { AppState } from '../../reducers';
import { definedMessages } from '../note/redtail.note.actioncreator';
import { redtailService } from '../redtail.service';
import {
  getRedtailCategories,
  getRedtailCategoriesError,
  getRedtailCategoriesSuccess,
} from './redtail.category.actions';

export class RedtailCategoryActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async getCategories(): Promise<void> {
    this.store.dispatch(getRedtailCategories());
    try {
      this.store.dispatch(
        getRedtailCategoriesSuccess({
          categories: await redtailService.getCategories(),
        }),
      );
    } catch (error) {
      logger.error('Error getting redtail note categories', error);
      this.store.dispatch(getRedtailCategoriesError({ message: definedMessages.REDTAIL_NOTE_CATEGORIES_GET_ERROR }));
    }
  }
}
