import { Box } from '@material-ui/core';
import React from 'react';
import { SoftphoneCall } from './softphone.model';
import { SoftphoneActiveCallControl } from './SoftphoneActiveCallControl';
import { SoftphoneCallHandlingButtons } from './SoftphoneCallHandlingButtons';

export interface SoftphoneCallControlProps {
  call: SoftphoneCall;
}

export const SoftphoneCallControl: React.FC<SoftphoneCallControlProps> = ({ call }) => {
  return (
    <Box py={2}>
      {call.callState === 'answered' || call.callState === 'on_hold' ? (
        <>
          <SoftphoneActiveCallControl call={call} />
        </>
      ) : (
        <SoftphoneCallHandlingButtons call={call} />
      )}
    </Box>
  );
};
