import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { CheckIcon } from '@getgo/chameleon-icons/react';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isNarrowIntegrationSelector } from '../integrations/integrations.selector';
import { tokens } from '@getgo/chameleon-core';

const useStyles = makeStyles(() => ({
  stepReadySection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: (props: { isNarrowIntegration: boolean }) => ({
    width: props.isNarrowIntegration ? 24 : 32,
    height: props.isNarrowIntegration ? 24 : 32,
    backgroundColor: tokens.iconColorBrandDefault,
    '& svg': {
      width: props.isNarrowIntegration ? 24 : 32,
      height: props.isNarrowIntegration ? 24 : 32,
    },
  }),
}));

export interface StepReadyBubbleProps {
  ready: boolean;
  className?: string;
}

export const StepReadySection: FC<StepReadyBubbleProps> = ({ ready, className, children }) => {
  const isNarrowIntegration = useSelector(isNarrowIntegrationSelector);
  const classes = useStyles({ isNarrowIntegration });
  return (
    <Box className={clsx(classes.stepReadySection, className)}>
      <Box flexGrow={1}>{children}</Box>
      <Box ml={1}>
        <Avatar className={classes.avatar} style={{ visibility: ready ? 'visible' : 'hidden' }}>
          <CheckIcon aria-hidden />
        </Avatar>
      </Box>
    </Box>
  );
};
