import { Reducer } from 'redux';
import { Contact } from '../calls/calls.reducer';
import { IndexedById } from '../models';

interface CallHistoryMetadataItem {
  contacts?: Contact;
  isClickToCall?: boolean;
}

interface CallHistoryMetadataState {
  byId: IndexedById<CallHistoryMetadataItem>;
  allIds: string[];
}

const initialState: CallHistoryMetadataState = {
  byId: {},
  allIds: [],
};
export const unifiedCallHistoryMetadataReducer: Reducer<CallHistoryMetadataState> = (
  state = initialState,
): CallHistoryMetadataState => {
  return state;
};
