import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

// US numbers are the default, other numbers are supported in international format (ex: Hungarian numbers as +36...)
const DEFAULT_COUNTRY = 'US';

export function isValidPhoneNumber(phoneNumberString: string): boolean {
  if (!phoneNumberString) {
    return false;
  }

  const defaultCountry = phoneNumberString.startsWith('+') || phoneNumberString.length < 5 ? undefined : 'US';
  const phoneNumber = parsePhoneNumberFromString(phoneNumberString, defaultCountry);

  // allow non phone numbers too, ex: extension numbers
  return phoneNumber ? phoneNumber.isValid() : phoneNumberString.length > 2 && /^\d+$/.test(phoneNumberString);
}

export function isExtension(phoneNumber: string): boolean {
  // NOTE: This is simplification.
  // Admins can configure Extension Digits in System Settings between 3 to 6 digits.
  // We could retrive this setting through the pbxInfo API.
  return !!phoneNumber && phoneNumber.length < 7;
}

export function formatPhoneNumber(phoneNumberString: string): string {
  const defaultCountry = phoneNumberString.startsWith('+') ? undefined : 'US';
  const asYouTypeResult = new AsYouType(defaultCountry).input(phoneNumberString);

  // do not start formatting the string if it's shorter than 5 characters
  if (phoneNumberString.length < 5) {
    return phoneNumberString;
  }

  // do not let AsYouType remove the user input, return the original string if the AsYouType result string is shorter
  return asYouTypeResult.length < phoneNumberString.length ? phoneNumberString : asYouTypeResult;
}

export function formatPhoneNumberToPossibleMatches(phoneNumberString: string): string[] {
  if (phoneNumberString.length < 5) {
    return [phoneNumberString];
  }

  const phoneNumber = parsePhoneNumberFromString(phoneNumberString, DEFAULT_COUNTRY);

  if (!phoneNumber) {
    return [phoneNumberString];
  }

  const phoneNumberNationalFormatted = phoneNumber.format('NATIONAL'); // e.g. "(213) 373-4253"
  const phoneNumberInternationalFormatted = phoneNumber.format('INTERNATIONAL'); // e.g. "+1 213 373 4253"
  const phoneNumberE164Formatted = phoneNumber.format('E.164'); // e.g. "+12133734253"

  return [
    ...new Set([
      phoneNumberString, // let's try the original phone number too
      phoneNumberNationalFormatted,
      phoneNumberInternationalFormatted,
      phoneNumberE164Formatted,
    ]),
  ];
}

export function formatPhoneNumberToDigitsOnlyFormats(phoneNumberToFormat: string): string[] {
  if (phoneNumberToFormat && phoneNumberToFormat.length < 5) {
    return [phoneNumberToFormat];
  }

  const phoneNumber = parsePhoneNumberFromString(phoneNumberToFormat, DEFAULT_COUNTRY);

  if (!phoneNumber) {
    return [phoneNumberToFormat];
  }

  const phoneNumberE164Formatted = phoneNumber.format('E.164'); // e.g. "+12133734253"
  const phoneNumberNationalFormatted = phoneNumber.format('NATIONAL'); // e.g. "(213) 373-4253"

  const phoneNumberNationalDigitsOnly = getDigitsFromString(phoneNumberNationalFormatted); // e.g. "2133734253"
  const phoneNumberE164DigitsOnly = getDigitsFromString(phoneNumberE164Formatted); // e.g. "12133734253"

  return [phoneNumberE164DigitsOnly, phoneNumberNationalDigitsOnly];
}

export function formatPhoneNumberToNationalDigitsOnlyFormats(phoneNumberToFormat: string): string[] {
  if (phoneNumberToFormat && phoneNumberToFormat.length < 5) {
    return [phoneNumberToFormat];
  }

  const phoneNumber = parsePhoneNumberFromString(phoneNumberToFormat, DEFAULT_COUNTRY);

  if (!phoneNumber) {
    return [phoneNumberToFormat];
  }

  const phoneNumberNationalFormatted = phoneNumber.format('NATIONAL'); // e.g. "(213) 373-4253"

  const phoneNumberNationalDigitsOnly = getDigitsFromString(phoneNumberNationalFormatted); // e.g. "2133734253"

  return [phoneNumberNationalDigitsOnly];
}

export function getDigitsFromString(value: string): string {
  return !!value ? value.replace(/\D/g, '') : '';
}
