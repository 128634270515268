import { defineMessages } from '@formatjs/intl';
import { Store } from 'redux';
import { isAuthenticatedSelector } from '../authentication/authentication.selector';
import { logger } from '../logging';
import { AppState } from '../reducers';
import { SalesforceServiceWindtalkProxy } from '../salesforce/salesforceLightning.proxy.model';
import { injectedIntlSelector } from '../settings/settings.selector';
import { PresenceActionCreator } from './presence.actionCreator';
import { PresenceAppearance } from './presence.models';
import { isOffline } from './presence.utils';

const definedMessages = defineMessages({
  OFFLINE_TEXT: {
    id: 'Salesforce.Presence.Offline.Text',
    defaultMessage: 'GoTo - Offline 🔴',
  },
  ONLINE_TEXT: {
    id: 'Salesforce.Presence.Online.Text',
    defaultMessage: 'GoTo',
  },
});

export class SalesforcePresenceActionCreator extends PresenceActionCreator {
  constructor(protected store: Store<AppState>, protected salesforceLightningProxy: SalesforceServiceWindtalkProxy) {
    super(store);
  }
  async setPresence(appearance: PresenceAppearance): Promise<void> {
    await super.setPresence(appearance);
    const intl = injectedIntlSelector(this.store.getState());

    const isAuthenticated = isAuthenticatedSelector(this.store.getState());
    if (!isAuthenticated) {
      return;
    }

    try {
      if (isOffline(appearance)) {
        await this.salesforceLightningProxy.setSoftphonePanelLabel(intl.formatMessage(definedMessages.OFFLINE_TEXT));
        await this.salesforceLightningProxy.setSoftphonePanelIcon('end_call');
      } else {
        await this.salesforceLightningProxy.setSoftphonePanelLabel(intl.formatMessage(definedMessages.ONLINE_TEXT));
        await this.salesforceLightningProxy.setSoftphonePanelIcon('call');
      }
    } catch (err) {
      logger.error('Unable to update softphone panel label', err);
    }
  }
}
