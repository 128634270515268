import React from 'react';
import { PhoneUpInactiveIcon } from '@getgo/chameleon-icons/react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Banner } from '../inAppNotification/Banner';
import { Typography } from '../theme/Typography.component';

const definedMessages = defineMessages({
  CALLING_MESSAGE: {
    id: 'ClickToCall.Calling.Message',
    defaultMessage: 'Calling...',
  },
});

export interface ClickToCallCallingMessageDispatchProps {
  onClose(): void;
}

export interface ClickToCallCallingMessageProps {
  phoneNumber: string;
  contactName?: string;
}

export const ClickToCallCallingMessageComponent: React.FC<ClickToCallCallingMessageProps &
  ClickToCallCallingMessageDispatchProps> = ({ onClose, phoneNumber, contactName }) => {
  return (
    <Banner
      variant="info"
      icon={<PhoneUpInactiveIcon />}
      isClosable={true}
      onClose={onClose}
      message={
        <>
          <Typography tag="p" color="inherit">
            <FormattedMessage {...definedMessages.CALLING_MESSAGE} />
          </Typography>
          <Typography tag="p" variant="caption-default-01" color="inherit">
            {contactName ? `${contactName} (${phoneNumber})` : phoneNumber}
          </Typography>
        </>
      }
    />
  );
};
