import React from 'react';
import { Store } from 'redux';
import { showMessage } from '../../inAppNotification/message.action';
import { MessageVariant } from '../../models';
import { AppState } from '../../reducers';
import { isShowCallDispositionFormEnabledSelector } from '../../settings/settings.selector';
import { CallDispositionConfigurationErrorMessage } from '../messages/CallDispositionConfigurationErrorMessage.component';
export class SalesforceCallDispositionActionCreator {
  constructor(private store: Store<AppState>) {}

  public async handleCallDispositionConfigurationError() {
    const isCallDispositionEnabled = isShowCallDispositionFormEnabledSelector(this.store.getState());

    if (isCallDispositionEnabled) {
      this.store.dispatch(
        showMessage({
          id: 'call_disposition_configuration_error_msg',
          type: MessageVariant.Custom,
          params: {
            autoHide: false,
            customContent: <CallDispositionConfigurationErrorMessage />,
          },
        }),
      );
    }
  }
}
