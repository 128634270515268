import { ActionFacade } from '../actionFacade/action.facade';
import {
  DownloadNotesCapableFacade,
  LoadNotesCountCapableFacade,
  OpenFormInCrmCapableFacade,
  OpenNotesCapableFacade,
  CAPABILITY_OPEN_FORM_IN_CRM,
  DownloadCasesCapableFacade,
  DownloadOpportunitiesCapableFacade,
  CAPABILITY_DOWNLOAD_OPPORTUNITIES,
  CAPABILITY_DOWNLOAD_CASES,
  CAPABILITY_ACCESS_LEAD,
  CAPABILITY_AUTO_CALL_ASSIGNMENT,
} from '../actionFacade/action.facade.capabilities';
import { SalesforceLightningNoteActionCreator } from './notes/salesforceLightning.note.actionCreator';
import { OpenCrmFormModel } from '../actionFacade/actionCreators/crmForm/crmForm.model';
import { SalesforceLightningCrmFormActionCreator } from './crmForm/salesforceLightning.crmForm.actionCreator';
import { SalesforceLightningCallLogActionCreator } from './callLog/salesforceLightning.callLog.actionCreator';
import { SalesforceCallLog } from '../../../salesforce-shared/salesforce-service.models';
import { SalesforceLightningOpportunityActionCreator } from './opportunities/salesforceLightning.opportunity.actionCreator';
import { SalesforceLightningCaseActionCreator } from './cases/salesforceLightning.case.actionCreator';
import { Entity } from '../models/index';

import {
  CAPABILITY_DOWNLOAD_NOTES,
  CAPABILITY_OPEN_NOTES,
  CAPABILITY_LOAD_NOTES_COUNT,
} from '../actionFacade/action.facade.capabilities';
import { SalesforceCallDispositionActionCreator } from './callDisposition/salesforce.callDisposition.actionCreator';
import { UnifiedSalesforceAuthActionCreator } from './unifiedSalesforce.auth.actioncreator';
import { SalesforceActionCreatorFactoryBase } from './salesforce.actionCreator.factory-base';

export abstract class SalesforceActionFacadeBase
  extends ActionFacade
  implements
    DownloadNotesCapableFacade,
    OpenFormInCrmCapableFacade,
    OpenNotesCapableFacade,
    LoadNotesCountCapableFacade,
    DownloadCasesCapableFacade,
    DownloadOpportunitiesCapableFacade
{
  [CAPABILITY_DOWNLOAD_NOTES]: true = true;
  [CAPABILITY_LOAD_NOTES_COUNT]: true = true;
  [CAPABILITY_OPEN_NOTES]: true = true;
  [CAPABILITY_OPEN_FORM_IN_CRM]: true = true;
  [CAPABILITY_DOWNLOAD_OPPORTUNITIES]: true = true;
  [CAPABILITY_DOWNLOAD_CASES]: true = true;
  [CAPABILITY_ACCESS_LEAD] = true;
  [CAPABILITY_AUTO_CALL_ASSIGNMENT] = true;

  private noteActionCreator: SalesforceLightningNoteActionCreator;
  private crmFormActionCreator: SalesforceLightningCrmFormActionCreator;
  private callLogActionCreator: SalesforceLightningCallLogActionCreator;
  private opportunityActionCreator: SalesforceLightningOpportunityActionCreator;
  private caseActionCreator: SalesforceLightningCaseActionCreator;
  private callDispositionActionCreator: SalesforceCallDispositionActionCreator;

  constructor(actionCreatorFactory: SalesforceActionCreatorFactoryBase) {
    super(actionCreatorFactory);
    this.noteActionCreator = actionCreatorFactory.createNoteActionCreator();
    this.crmFormActionCreator = actionCreatorFactory.createCrmFormActionCreator();
    this.callLogActionCreator = actionCreatorFactory.createCallLogActionCreator();
    this.opportunityActionCreator = actionCreatorFactory.createOpportunityActionCreator();
    this.caseActionCreator = actionCreatorFactory.createCaseActionCreator();
    this.callDispositionActionCreator = actionCreatorFactory.createCallDispositionActionCreator();
  }

  loadMostRecentNoteOfContact(contactId: string): Promise<void> {
    return this.noteActionCreator.loadMostRecentNoteOfContact(contactId);
  }

  loadMoreNotesOfContact(): Promise<void> {
    return this.noteActionCreator.loadMoreNotesOfContact();
  }

  openFormInCrm(model: OpenCrmFormModel): Promise<void> {
    return this.crmFormActionCreator.openFormInCrm(model);
  }

  loadSavedCallLog(callId: string): Promise<void> {
    return this.callLogActionCreator.loadSavedCallLog(callId);
  }

  createCallLog(
    callLogToCrate: Pick<SalesforceCallLog, 'CallId' | 'Description' | 'CallDispositionValue' | 'WhatId' | 'WhoId'>,
  ) {
    return this.callLogActionCreator.createCallLog(callLogToCrate);
  }

  updateCallLog(
    callLogToUpdate: Pick<SalesforceCallLog, 'CallId' | 'Description' | 'CallDispositionValue' | 'WhatId' | 'WhoId'> & {
      Id: string;
    },
  ) {
    return this.callLogActionCreator.updateCallLog(callLogToUpdate);
  }

  loadMostRecentOpportunityOfContact(contactId: string): Promise<void> {
    return this.opportunityActionCreator.loadMostRecentOpportunityOfContact(contactId);
  }

  loadMoreOpportunitiesOfContact(): Promise<void> {
    return this.opportunityActionCreator.loadMoreOpportunitiesOfContact();
  }

  createOpportunityForContact(contact: Entity): Promise<void> {
    return this.opportunityActionCreator.createOpportunityForContact(contact);
  }

  createCaseForContact(contact: Entity): Promise<void> {
    return this.caseActionCreator.createCaseForContact(contact);
  }

  loadMoreCasesOfContact(): Promise<void> {
    return this.caseActionCreator.loadMoreCasesOfContact();
  }

  loadMostRecentCaseOfContact(contactId: string): Promise<void> {
    return this.caseActionCreator.loadMostRecentCaseOfContact(contactId);
  }

  openCase(id: string): Promise<void> {
    return this.caseActionCreator.openCase(id);
  }

  openOpportunity(id: string): Promise<void> {
    return this.opportunityActionCreator.openOpportunity(id);
  }

  openNote(id: string): Promise<void> {
    return this.noteActionCreator.openNote(id);
  }

  getCasesCount(contactId: string): Promise<void> {
    return this.caseActionCreator.getCasesCount(contactId);
  }

  getOpportunitiesCount(contactId: string): Promise<void> {
    return this.opportunityActionCreator.getOpportunitiesCount(contactId);
  }

  loadNotesCount(contactId: string): Promise<void> {
    return this.noteActionCreator.loadNotesCount(contactId);
  }

  handleCallDispositionConfigurationError(): Promise<void> {
    return this.callDispositionActionCreator.handleCallDispositionConfigurationError();
  }

  loadAuthToken(): Promise<void> {
    return (this.authenticationActionCreator as UnifiedSalesforceAuthActionCreator)?.loadAuthToken();
  }

  searchWhatObjects(what: string, query?: string): Promise<void> {
    return this.callLogActionCreator.searchWhatObjects(what, query);
  }

  searchWhoObjects(who: string, query?: string): Promise<void> {
    return this.callLogActionCreator.searchWhoObjects(who, query);
  }
}
