import { combineReducers } from 'redux';
import { redtailActivitiesReducer } from './activity/redtail.activity.reducer';
import { redtailCategoriesReducer } from './category/redtail.category.reducer';
import { redtailNotesReducer } from './note/redtail.note.reducer';

export const redtailReducer = combineReducers({
  categories: redtailCategoriesReducer,
  activities: redtailActivitiesReducer,
  notes: redtailNotesReducer,
});
