import React, { useEffect } from 'react';
import { ServiceAppointment, LoadServiceAppointmentsRequestState } from './serviceAppointments.models';
import { ServiceAppointmentComponent } from './ServiceAppointment.component';
import LoadingSpinner from '../../components/LoadingSpinner';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../../theme/Typography.component';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { VehiclesCapableFacade } from '../../actionFacade/action.facade.capabilities';

const definedMessages = defineMessages({
  LIST_TITLE: {
    id: 'Cox.ServiceAppointments.List.Title',
    defaultMessage: 'Service appointments',
  },
  EMPTY_MESSAGE: {
    id: 'Cox.ServiceAppointments.List.Empty',
    defaultMessage: 'There is no service appointment for this vehicle.',
  },
});

export interface ServiceAppointmentsStateProps {
  requestState?: LoadServiceAppointmentsRequestState;
  serviceAppointments?: ServiceAppointment[];
  locale: Locale;
}

export interface ServiceAppointmentsProps {
  VIN: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  }),
);

export const ServiceAppointmentsComponent: React.FC<ServiceAppointmentsProps & ServiceAppointmentsStateProps> = ({
  VIN,
  requestState,
  serviceAppointments,
  locale,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (requestState === undefined) {
      void getActionFacade<VehiclesCapableFacade>().loadServiceAppointmentsForVehicle(VIN);
    }
  }, [VIN, requestState]);

  const renderBody = () => {
    if (requestState === undefined || requestState === LoadServiceAppointmentsRequestState.LOADING) {
      return (
        <LoadingSpinner isLoading={true}>
          <></>
        </LoadingSpinner>
      );
    }

    if (!serviceAppointments || !serviceAppointments.length) {
      return (
        <Typography variant="caption-small-01">
          <FormattedMessage {...definedMessages.EMPTY_MESSAGE} />
        </Typography>
      );
    }

    return (
      <div className={classes.container}>
        {serviceAppointments?.map((a) => (
          <ServiceAppointmentComponent key={a.id} serviceAppointment={a} locale={locale} />
        ))}
      </div>
    );
  };

  return (
    <div>
      <Box mb={2}>
        <Typography variant="caption-default-01">
          <FormattedMessage {...definedMessages.LIST_TITLE} />
        </Typography>
      </Box>
      {renderBody()}
    </div>
  );
};
