import { Call } from '@jive/realtime-events';
import { makeActionCreator } from '../actions/actionCreators';

export enum DebugActionTypes {
  INVALIDATE_TOKEN = 'debug/invalidateToken',
  EXPIRE_TOKEN = 'debug/expireToken',
  SET_TOKEN_VALIDITY = 'debug/setTokenValidity',
  ADD_FAKE_CALL = 'debug/addFakeCall',
  STOP_CONTACT_MATCHING = 'debug/stopContactMatching',
  REMOVE_FAKE_CALL_LOGS = 'debug/removeFakeCallLogs',
  CLEAR_SELECTED_LINE = 'debug/clearSelectedLine',
  RESET_HAS_SEEN_SOFTPHONE_SETTINGS = 'debug/resetHasSeenSoftphoneSettings',
}

export const invalidateToken = makeActionCreator(DebugActionTypes.INVALIDATE_TOKEN)();
export const expireToken = makeActionCreator(DebugActionTypes.EXPIRE_TOKEN)();
export const setTokenValidity = makeActionCreator(DebugActionTypes.SET_TOKEN_VALIDITY)<{
  tokenExpirationInDays: number;
}>();
export const addFakeCall = makeActionCreator(DebugActionTypes.ADD_FAKE_CALL)<{
  call: Call;
}>();
export const stopContactMatchingRequested = makeActionCreator(DebugActionTypes.STOP_CONTACT_MATCHING)<{
  callId: string;
}>();
export const removeFakeCallLogs = makeActionCreator(DebugActionTypes.REMOVE_FAKE_CALL_LOGS)();
export const clearSelectedLine = makeActionCreator(DebugActionTypes.CLEAR_SELECTED_LINE)();
export const resetHasSeenSoftphoneSettings = makeActionCreator(DebugActionTypes.RESET_HAS_SEEN_SOFTPHONE_SETTINGS)();

export type DebugActions =
  | ReturnType<typeof invalidateToken>
  | ReturnType<typeof expireToken>
  | ReturnType<typeof setTokenValidity>
  | ReturnType<typeof addFakeCall>
  | ReturnType<typeof stopContactMatchingRequested>
  | ReturnType<typeof removeFakeCallLogs>
  | ReturnType<typeof resetHasSeenSoftphoneSettings>
  | ReturnType<typeof clearSelectedLine>;
