import { Dispatch, MiddlewareAPI, Store } from 'redux';
import { AppAction } from '../actions/actionCreators';
import { Actions } from '../actions/actions';
import { AppState } from '../reducers';

type ListenerActionReturn = ListenerActionReturnSimple | ListenerActionReturnSimple[];
type ListenerActionReturnSimple = void | AppAction | Promise<AppAction | void>;

export type ListenerAction<T> = (state: AppState, action: T) => ListenerActionReturn;

type SmartListenerAction<T> = (store: Store<AppState>, action: T) => void;

enum ListenerType {
  ListenerAction,
  SmartListenerAction,
}

interface NormalListener<T> {
  type: ListenerType.ListenerAction;
  action: ListenerAction<T>;
}

interface SmartListener<T> {
  type: ListenerType.SmartListenerAction;
  action: SmartListenerAction<T>;
}

type Listener<T> = NormalListener<T> | SmartListener<T>;
export type ActionOfType<T> = Extract<Actions, { type: T }>;
type AppActionTypes = Actions['type'];

const listeners: Map<AppActionTypes, Array<Listener<Actions>>> = new Map();

function addAction<T extends AppActionTypes>(type: T, listener: Listener<ActionOfType<T>>) {
  const actionListeners = listeners.get(type);

  if (actionListeners === undefined) {
    listeners.set(type, [listener] as Array<Listener<Actions>>);
  } else {
    actionListeners.push(listener as Listener<Actions>);
  }
}

function removeAction(type: any, listener: any) {
  const listenersOfType = listeners.get(type);
  if (!listenersOfType || !listenersOfType.length) {
    return;
  }
  const remainingListeners = listenersOfType.filter((l) => l.action !== listener);
  listeners.set(type, remainingListeners);
}

/**
 * @param type to listen (should be enum type) `extends string` is mandatory to test correctly the Enum's value
 * usage : addListener(Actions.MY_ACTION)<ActionsType>((state, action) => { ... })
 *
 * Action can return nothing or an action to dispatch
 * This action can be a Promise<AppAction>
 * Middleware is in charge to dispatch for you the action
 */
export function addListener<T extends AppActionTypes>(type: T) {
  return (listenerAction: ListenerAction<ActionOfType<T>>) => {
    const action: NormalListener<ActionOfType<T>> = {
      type: ListenerType.ListenerAction,
      action: listenerAction,
    };
    addAction(type, action);
  };
}

export function addListeners<T extends AppActionTypes>(types: T[]) {
  return (action: ListenerAction<ActionOfType<T>>) => {
    types.forEach((type) => addListener(type)(action));
  };
}

export function addListenerForOneTimeExecution<T extends AppActionTypes>(type: T) {
  return (action: ListenerAction<ActionOfType<T>>) => {
    const handleActionAndRemoveSelf = (state, appAction) => {
      setTimeout(() => removeAction(type, handleActionAndRemoveSelf));
      return action(state, appAction);
    };
    addListener(type)(handleActionAndRemoveSelf);
  };
}

// register listeners and once any of them are executed all of them will be removed from the list of listeners
export function addListenersForOneTimeExecution<T extends AppActionTypes>(types: T[]) {
  const actionHandlers: Array<(state: any, appAction: any) => ListenerActionReturn> = [];
  return (action: ListenerAction<ActionOfType<T>>) => {
    types.forEach((type) => {
      const handleActionAndRemoveSelf = (state, appAction) => {
        setTimeout(() => actionHandlers.forEach((actionHandler) => removeAction(type, actionHandler)));
        return action(state, appAction);
      };
      addListener(type)(handleActionAndRemoveSelf);
      actionHandlers.push(handleActionAndRemoveSelf);
    });
  };
}

/**
 * @param type to listen (should be enum type) `extends string` is mandatory to test correctly the Enum's value
 *
 * With SmartListener, you can register a function with 2 parameters : (store and action)
 * it should be use only for particular use/cases in your listener. for example you need to use dispatch function more
 * than once
 * The preferable way is to use ListenerAction
 */
export function addSmartListener<T extends AppActionTypes>(type: T) {
  return (listenerAction: SmartListenerAction<ActionOfType<T>>) => {
    const action: SmartListener<ActionOfType<T>> = {
      type: ListenerType.SmartListenerAction,
      action: listenerAction,
    };
    addAction(type, action);
  };
}

export const flushListener = () => listeners.clear();

const listenerMiddleware = (store: MiddlewareAPI<Dispatch, AppState>) => {
  return (next: Dispatch) => (action: Actions) => {
    next(action);

    if (action == null || action.type == null) {
      return;
    }

    const listenerActions = listeners.get(action.type) || [];

    // Gives the ability to hook on Promises when `dispatch` is called. So during unit tests
    // we can just do `await store.dispatch(...)` and it will wait for all actions to be executed.
    return Promise.all(
      listenerActions.map(async (listenerAction) => {
        if (listenerAction.type === ListenerType.ListenerAction) {
          const actionToDispatch = listenerAction.action(store.getState(), action);
          const actionsToDispatch: ListenerActionReturn = Array.isArray(actionToDispatch)
            ? actionToDispatch
            : [actionToDispatch];
          const promises = actionsToDispatch.map((actionResult) => Promise.resolve(actionResult));

          try {
            const promiseResults = await Promise.all(promises);
            if (promiseResults && promiseResults.length) {
              await Promise.all(promiseResults.map((result) => Promise.resolve(result && store.dispatch(result))));
            }
          } catch (error) {
            console.error('Error to dispatch action, Promise on error', error.message, error.stack);
          }
        } else {
          return listenerAction.action(store as Store<AppState>, action);
        }
      }),
    );
  };
};

export default listenerMiddleware;
