import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const definedMessages = defineMessages({
  DIALOG_CONTACT_UPDATE_BODY: {
    id: 'Salesforce.Dialog.ContactUpdate.Body',
    defaultMessage: 'The existing phone number will be replaced. {areyousure}',
  },
  DIALOG_CONTACT_UPDATE_BODY_ARE_YOU_SURE: {
    id: 'Salesforce.Dialog.ContactUpdate.Body.AreYouSure',
    defaultMessage: 'Are you sure?',
  },
});

export const PhoneNumberUpdateConfirmation: React.FC = () => {
  return (
    <>
      <FormattedMessage
        tagName="div"
        {...definedMessages.DIALOG_CONTACT_UPDATE_BODY}
        values={{
          areyousure: (
            <>
              <br />
              <br />
              <strong>
                <FormattedMessage {...definedMessages.DIALOG_CONTACT_UPDATE_BODY_ARE_YOU_SURE} />
              </strong>
            </>
          ),
        }}
      />
    </>
  );
};
