import { Message } from '@ucc/messaging-platform-service';
import { getRecipientsFromConversationId, MessageBody, MessagingAdapter } from '@ucc/messaging-service';
import { chunk, minBy } from 'lodash-es';
import { defineMessages } from 'react-intl';
import { Store } from 'redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { getActiveRoute } from '../browserHistory';
import { ActivityCategory, Matter } from '../clio/clio.models';
import { clioSelfSelector } from '../clio/clio.selector';
import { ClioService } from '../clio/clio.service';
import { ClioActivity, ClioCommunication, ClioRateLimitedResponse, ClioType } from '../clio/clio.service.models';
import { ApplicationRoute } from '../constants';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../inAppNotification/message.action';
import { logger } from '../logging';
import { Entity } from '../models';
import { AppState } from '../reducers';
import { isFulfilled, isRejected, sleep } from '../utils';
import { uuid } from '../uuid';
import {
  deselectAllConversationMessages,
  deselectConversationMessages,
  loadConversationMessagesAction,
  loadConversationMessagesErrorAction,
  loadConversationMessagesSuccessAction,
  sendLogMessagesRequestAction,
  sendLogMessagesRequestErrorAction,
  sendLogMessagesRequestSuccessAction,
} from './conversationMessages.actions';
import { conversationMessagesNextPageIdentifierSelector } from './conversationMessages.selector';
import {
  clearConversationsAction,
  loadConversationsAction,
  loadConversationsErrorAction,
  loadConversationsSuccessAction,
  selectConversationsPhoneNumberAction,
} from './conversations.actions';
import {
  conversationsPageIdentifiersSelector,
  selectedConversationPhoneNumberSelector,
} from './conversations.selector';
import { createConversationsService } from './conversations.service';

const definedMessages = defineMessages({
  COMMUNICATION_CREATING_SUCCEEDED: {
    id: 'ClioSMSLog.Create.Success',
    defaultMessage: 'The selected text messages have been logged as communications in Clio.',
  },
  COMMUNICATION_CREATING_PARTIALLY_SUCCEEDED: {
    id: 'ClioSMSLog.Create.PartialSuccess',
    defaultMessage: "All the messages weren't logged in Clio. Please try again for the remaining messages.",
  },
  COMMUNICATION_CREATING_FAILED: {
    id: 'ClioSMSLog.Create.Error',
    defaultMessage: 'There was an error while creating the communications, please try again.',
  },
});

const trackingEvents = defineTrackingEvents({
  CONVERSATIONS_LOAD_STARTED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListStarted,
    label: 'Text Messages',
  },
  CONVERSATIONS_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Text Messages',
  },
  CONVERSATIONS_LOAD_FAILED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListFailed,
    label: 'Text Messages',
  },
  CONVERSATIONS_MESSAGES_LOAD_STARTED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListStarted,
    label: 'Text Message Thread',
  },
  CONVERSATIONS_MESSAGES_LOAD_SUCCEEDED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListSucceeded,
    label: 'Text Message Thread',
  },
  CONVERSATIONS_MESSAGES_LOAD_FAILED: {
    category: AnalyticsCategory.TextMessage,
    action: AnalyticsAction.LoadListFailed,
    label: 'Text Message Thread',
  },
  CONVERSATIONS_LOG_SUCCEEDED: {
    category: AnalyticsCategory.ConversationLog,
    action: AnalyticsAction.ConversationLogSucceeded,
    label: 'Conversation log succeeded',
  },
  CONVERSATIONS_LOG_PARTIALLY_SUCCEEDED: {
    category: AnalyticsCategory.ConversationLog,
    action: AnalyticsAction.ConversationLogSucceeded,
    label: 'Conversation log partially succeeded',
  },
  CONVERSATIONS_LOG_FAILED: {
    category: AnalyticsCategory.ConversationLog,
    action: AnalyticsAction.ConversationLogFailed,
    label: 'Conversation log failed',
  },
});
export interface LogMessagesParams {
  conversationId: string;
  messages: Array<Message<MessageBody>>;
  matterId?: Matter['id'];
  isBillable: boolean;
  description?: string;
  activityCategoryId?: ActivityCategory['id'];
  selectedContact?: Entity;
}

export class ConversationsActionCreator {
  private static readonly MESSAGE_LOG_BATCH_SIZE = 5;
  private static readonly RATE_LIMIT_RESERVE_IDLE = 15;
  private static readonly RATE_LIMIT_RESERVE_ACTIVE = 25;
  private static readonly RATE_LIMIT_WINDOW_RESERVE_SECS = 5_000;

  private conversationsServices: { [key: string]: MessagingAdapter } = {};
  constructor(protected store: Store<AppState>) {}

  async loadConversations(): Promise<void> {
    try {
      this.store.dispatch(loadConversationsAction());
      newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOAD_STARTED);
      const pageIdentifiers = conversationsPageIdentifiersSelector(this.store.getState());
      const phoneNumber = selectedConversationPhoneNumberSelector(this.store.getState());
      const conversations = await this.getConversationsService(phoneNumber)
        .getConversations(50, pageIdentifiers)
        .toPromise();

      this.store.dispatch(loadConversationsSuccessAction(conversations));
      newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOAD_SUCCEEDED);
    } catch (e) {
      logger.error('Error loading conversations', e);
      this.store.dispatch(loadConversationsErrorAction());
      newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOAD_FAILED);
    }
  }

  async updateConversations(): Promise<void> {
    try {
      await this.clearConversations();
      await this.loadConversations();
    } catch (e) {
      logger.error('Error updating conversations', e);
    }
  }

  async clearConversations(): Promise<void> {
    try {
      this.store.dispatch(clearConversationsAction());
    } catch (e) {
      logger.error('Error clearing conversations', e);
    }
  }

  async loadConversationMessages(conversationId: string): Promise<void> {
    this.store.dispatch(loadConversationMessagesAction({ conversationId }));
    newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_MESSAGES_LOAD_STARTED);

    try {
      const { ownerPhoneNumber } = getRecipientsFromConversationId(conversationId);
      const pageIdentifier = conversationMessagesNextPageIdentifierSelector(conversationId)(this.store.getState());
      const conversation = await this.getConversationsService(ownerPhoneNumber)
        .getConversationMessages(conversationId, 50, pageIdentifier)
        .toPromise();

      this.store.dispatch(loadConversationMessagesSuccessAction({ ...conversation, conversationId }));
      newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_MESSAGES_LOAD_SUCCEEDED);
    } catch (error) {
      this.store.dispatch(loadConversationMessagesErrorAction({ conversationId }));
      logger.error('Error loading conversation', error);
      newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_MESSAGES_LOAD_FAILED);
    }
  }

  async selectConversationPhoneNumber(phoneNumber: string): Promise<void> {
    this.store.dispatch(selectConversationsPhoneNumberAction(phoneNumber));
  }

  async logMessages({
    conversationId,
    messages,
    matterId,
    description,
    isBillable,
    activityCategoryId,
    selectedContact,
  }: LogMessagesParams) {
    this.store.dispatch(sendLogMessagesRequestAction());
    const logId = uuid();
    const now = performance.now();
    const originalOnbeforeunload = window.onbeforeunload;

    logger.info(`Starting to log messages`, { numberOfMessage: messages.length, id: logId });

    const logErrors: PromiseRejectedResult[] = [];
    const successfulLogIds: Array<Message<MessageBody>['id']> = [];

    const chunks = chunk(messages, ConversationsActionCreator.MESSAGE_LOG_BATCH_SIZE);

    try {
      window.onbeforeunload = () => '';

      for (const [index, currentChunk] of chunks.entries()) {
        const { rateLimit, failedLogs, successfulLogs } = await this.logBatch(
          currentChunk,
          selectedContact,
          matterId,
          description,
          isBillable,
          activityCategoryId,
        );
        logErrors.push(...failedLogs);
        successfulLogIds.push(...successfulLogs);

        const isLastIteration = index === chunks.length - 1;
        const isRateLimitBelowThreshold = this.isRateLimitBelowThreshold(rateLimit);

        if (!isLastIteration && isRateLimitBelowThreshold) {
          await this.waitForNewRateLimitWindow(rateLimit!);
        }
      }
    } finally {
      window.onbeforeunload = originalOnbeforeunload;
    }

    const hasLoggingSucceeded = logErrors.length < 1;
    const duration = performance.now() - now;

    if (hasLoggingSucceeded) {
      this.handleLogSuccess(messages, conversationId, logId, duration);
    } else if (successfulLogIds.length) {
      this.handleLogPartialSuccess(messages, conversationId, successfulLogIds, logId, now, logErrors);
    } else {
      this.handleLogError(logErrors, logId, duration);
    }
  }

  private isRateLimitBelowThreshold(rateLimit: ClioRateLimitedResponse<unknown> | undefined) {
    if (rateLimit === undefined) {
      return false;
    }

    const { route } = getActiveRoute(undefined, true);
    if (route === ApplicationRoute.CONVERSATION_LOG_ROUTE) {
      return (
        rateLimit.rateLimit.rateLimitRemaining <
        ConversationsActionCreator.MESSAGE_LOG_BATCH_SIZE * 2 + ConversationsActionCreator.RATE_LIMIT_RESERVE_IDLE
      );
    }

    return (
      rateLimit.rateLimit.rateLimitRemaining <
      ConversationsActionCreator.MESSAGE_LOG_BATCH_SIZE * 2 + ConversationsActionCreator.RATE_LIMIT_RESERVE_ACTIVE
    );
  }

  private handleLogError(logErrors: PromiseRejectedResult[], logId: string, duration: number) {
    const errors = logErrors.filter((result) => result.reason instanceof Error).map<unknown>((result) => result.reason);

    const aggregateError = new AggregateError(errors);

    this.store.dispatch(showErrorMessage(definedMessages.COMMUNICATION_CREATING_FAILED));
    this.store.dispatch(sendLogMessagesRequestErrorAction());
    newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOG_FAILED);
    logger.error('Failed to log all messages', aggregateError, { id: logId, duration });
  }

  private handleLogPartialSuccess(
    messages: Array<Message<MessageBody>>,
    conversationId: string,
    successfulLogs: string[],
    logId: string,
    duration: number,
    logErrors: PromiseRejectedResult[],
  ) {
    this.store.dispatch(showWarningMessage(definedMessages.COMMUNICATION_CREATING_PARTIALLY_SUCCEEDED));
    this.store.dispatch(sendLogMessagesRequestSuccessAction(messages.length));
    this.store.dispatch(deselectConversationMessages({ conversationId, conversationMessageIds: successfulLogs }));
    newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOG_PARTIALLY_SUCCEEDED);
    logger.info('Successfully logged some messages', {
      id: logId,
      duration,
      successfulLogs: successfulLogs.length,
      failedLogs: logErrors.length,
    });
  }

  private handleLogSuccess(
    messages: Array<Message<MessageBody>>,
    conversationId: string,
    logId: string,
    duration: number,
  ) {
    this.store.dispatch(showSuccessMessage(definedMessages.COMMUNICATION_CREATING_SUCCEEDED));
    this.store.dispatch(sendLogMessagesRequestSuccessAction(messages.length));
    this.store.dispatch(deselectAllConversationMessages({ conversationId }));
    newTracker.trackAnalyticsEvent(trackingEvents.CONVERSATIONS_LOG_SUCCEEDED);
    logger.info('Successfully logged all messages', { id: logId, duration });
  }

  private async logBatch(
    currentChunk: Array<Message<MessageBody>>,
    selectedContact: Entity | undefined,
    matterId: number | undefined,
    description: string | undefined,
    isBillable: boolean,
    activityCategoryId: number | undefined,
  ) {
    const successfulLogs: string[] = [];
    const responses = await Promise.allSettled(
      currentChunk.map(async (message) => {
        const { senders, receivers } = this.getSmsParties(message.metadata, selectedContact);

        const saveCommunicationResult = await this.createCommunications(
          message,
          matterId,
          description,
          senders,
          receivers,
        );

        const result = await this.createActivity(
          message,
          saveCommunicationResult.data.id,
          isBillable,
          matterId,
          description,
          activityCategoryId,
        );

        successfulLogs.push(message.id);
        return result;
      }),
    );

    const successResponses = responses.filter(isFulfilled).map((response) => response.value);
    const failedLogs = responses.filter(isRejected);

    const rateLimit = minBy(successResponses, (response) => response.rateLimit.rateLimitRemaining);
    return {
      rateLimit,
      successfulLogs,
      failedLogs,
    };
  }

  private waitForNewRateLimitWindow(rateLimit: ClioRateLimitedResponse<{ id: number }>) {
    const timeToWait =
      rateLimit.rateLimit.rateLimitReset * 1000 -
      Date.now() +
      ConversationsActionCreator.RATE_LIMIT_WINDOW_RESERVE_SECS;
    return sleep(timeToWait);
  }

  private getConversationsService(phoneNumber: string): MessagingAdapter {
    if (!this.conversationsServices[phoneNumber]) {
      this.conversationsServices[phoneNumber] = createConversationsService([phoneNumber]);
    }

    return this.conversationsServices[phoneNumber];
  }

  // We currently cannot fill both the sender and the receiver because we don't do contact matching
  // on the number the user is exchanging SMS with. Whe we start doing that, if there's a match we should
  // add it as a sender or a receiver
  private getSmsParties(
    messageMetadata: Message<MessageBody>['metadata'],
    contact?: Entity,
  ): { senders: ClioCommunication['senders']; receivers: ClioCommunication['receivers'] } {
    const self = clioSelfSelector(this.store.getState());
    const clioSelf = self ? [{ id: self.id, type: ClioType.User }] : undefined;
    const clioContact = contact ? [{ id: Number(contact.id), type: ClioType.Contact }] : undefined;

    const phoneNumber = selectedConversationPhoneNumberSelector(this.store.getState());

    let senders: ClioCommunication['senders'];
    let receivers: ClioCommunication['receivers'];

    if (phoneNumber === messageMetadata.originator.id) {
      senders = clioSelf;
      receivers = clioContact;
    } else {
      senders = clioContact;
      receivers = clioSelf;
    }

    return {
      senders,
      receivers,
    };
  }

  private async createCommunications(
    message: Message<MessageBody>,
    matterId?: Matter['id'],
    description?: string,
    senders?: ClioCommunication['senders'],
    receivers?: ClioCommunication['receivers'],
  ) {
    const communication: ClioCommunication = {
      subject: 'GoTo text messages',
      body: description ? description : message.body?.text ?? '',
      date: message.metadata.timestamp,
      matter: matterId
        ? {
            id: matterId,
          }
        : undefined,
      senders,
      receivers,
      external_properties: [{ name: 'jive_sms_id', value: message.id }],
      type: ClioType.PhoneCommunication,
    };

    return await ClioService.createCommunication(communication);
  }

  private async createActivity(
    message: Message<MessageBody>,
    communicationId: number,
    isBillable: boolean,
    matterId?: Matter['id'],
    description?: string,
    activityCategoryId?: ActivityCategory['id'],
  ) {
    const activity: ClioActivity = {
      date: message.metadata.timestamp,
      quantity: 300, // seconds
      non_billable: !isBillable,
      note: description ?? message.body?.text ?? '',
      communication: {
        id: communicationId,
      },
      matter: matterId
        ? {
            id: matterId,
          }
        : undefined,
      type: ClioType.TimeEntry,
      activity_description: activityCategoryId ? { id: activityCategoryId } : undefined,
    };

    return await ClioService.createActivity(activity);
  }
}
