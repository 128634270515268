import { Entity } from '../models';
import { ClioType } from './clio.service.models';

export interface Matter {
  id: number;
  title: string;
  client?: ClioEntity;
  updatedAt: string;
}

export const mattersSorterByClientId = (clientId: string) => (a: Matter, b: Matter): number => {
  const aClientId = a?.client ? a.client.id : undefined;
  const bClientId = b?.client ? b.client.id : undefined;

  if (aClientId === clientId) {
    if (bClientId === clientId) {
      return sortMattersByUpdatedAt(a, b);
    } else {
      return -1;
    }
  } else {
    if (bClientId === clientId) {
      return 1;
    } else {
      return sortMattersByUpdatedAt(a, b);
    }
  }
};

export const sortMattersByUpdatedAt = (a: Matter, b: Matter): number => {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }
  return a?.updatedAt < b?.updatedAt ? -1 : a?.updatedAt === b?.updatedAt ? 0 : 1;
};

export interface User {
  id: number;
  name: string;
  rate: number;
}

export interface ClioContactPhoneNumber {
  number: string;
  name: string;
}

export interface ClioEntity extends Entity {
  phoneNumbers: ClioContactPhoneNumber[];
}

export interface Communication {
  id: number;
  activityId?: number;
  description: string;
  subject: string;
  matterId?: number;
  isBillable?: boolean;
  duration?: number;
  receivers?: Array<{ id: number; type: ClioType }>;
  senders?: Array<{ id: number; type: ClioType }>;
}

export interface Activity {
  id: number;
  title: string;
}

export interface ClioActivityDescription {
  id: number;
  name: string;
}

export interface ActivityCategory {
  id: number;
  title: string;
}
