import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TextField } from '@getgo/chameleon-material-ui';
import React, { ChangeEvent } from 'react';
import { formatPhoneNumber } from '../phone/phone-utils';
import { PhoneMode } from '../phone/phone.model';
import { FormattedMessageWithMessageValues } from '../reactInltModels';
import { CloseIcon } from '@getgo/chameleon-icons/react';
import { IconButton } from '@getgo/chameleon-material-ui';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchInputContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    searchTextField: {
      '&:not(Mui-disabled)': {
        padding: theme.spacing(1, 3),
      },
    },
  }),
);

export interface SearchInputProps {
  input: string;
  mode: PhoneMode;
  onInputChange: (value: string) => void;
  onEnter?: () => void;
  onClearInput: () => void;
  classes?: any;
  startAdornment: React.ReactNode;
  autoFocus?: boolean;
  inputRef?: React.Ref<any> | React.RefObject<any>;
  placeholder?: FormattedMessageWithMessageValues;
  endAdornment?: React.ReactNode;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  input,
  startAdornment,
  onInputChange,
  onEnter,
  onClearInput,
  mode,
  autoFocus,
  inputRef,
  placeholder,
  endAdornment,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const nativeEvent = event.nativeEvent as any;
    const eventType = nativeEvent && nativeEvent.inputType;
    const value = event.target.value;

    // do not format the text if it's being deleted
    if (eventType === 'deleteContentBackward') {
      return onInputChange(value);
    }

    switch (mode) {
      case PhoneMode.Dialpad:
        return onInputChange(formatPhoneNumber(value));
      case PhoneMode.Search:
      default:
        return onInputChange(value);
    }
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (!!onEnter && event.key === 'Enter') {
      event.preventDefault();
      return onEnter();
    }
  };

  return (
    <div className={classes.searchInputContainer} data-cy={'search-input'}>
      <TextField
        size="medium"
        fullWidth
        noMargin
        value={input}
        onChange={onChange}
        onKeyPress={onKeyPress}
        startAdornment={startAdornment}
        endAdornment={
          input && input.length > 0 ? (
            <>
              {endAdornment}
              <IconButton data-cy="delete" variant="secondary" size="small" onClick={onClearInput} aria-label="clear">
                <CloseIcon aria-hidden />
              </IconButton>
            </>
          ) : (
            <>{endAdornment}</>
          )
        }
        data-cy="input"
        autoFocus={autoFocus}
        inputRef={inputRef}
        placeholder={placeholder ? intl.formatMessage(placeholder, placeholder.values) : ''}
        InputProps={{
          classes: {
            root: classes.searchTextField,
          },
        }}
      />
    </div>
  );
};
