import { Store } from 'redux';
import { AppState } from '../reducers';
import { integrationStartupStateSelector, currentIntegrationSelector } from '../integrations/integrations.selector';
import { registerUnauthorizedRequestInterceptor } from './authentication.interceptor';
import { isAuthenticatedSelector, tokenExpirationInDaysSelector, tokenSelector } from './authentication.selector';
import { goToRoute } from '../browserHistory';
import { ApplicationRoute } from '../constants';
import { OAuthService } from './authentication.service';
import { LoginData } from './authentication.models';
import { IdentityService } from '../settings/identity.service';
import { loginSuccess, loginError, AuthType, logoutSuccess } from './authentication.actions';
import { authenticationEventListener } from './authentication.eventListener';
import { logger } from '../logging';
import { showErrorMessage } from '../inAppNotification/message.action';
import { defineMessages } from 'react-intl';
import axios from 'axios';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { isTermsOfServiceAcceptedSelector } from '../termsOfService/termsOfService.selector';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { openIntegrationPanel } from '../embedded/embedded.action';
import { StartupState } from '../integrations/integrations.actions';
import { Integrations } from '../models';

const definedMessages = defineMessages({
  LOGIN_ERROR_MESSAGE: {
    id: 'Login.Error.Message',
    defaultMessage: 'There is an error in signing you into the application. Please try again.',
  },
});

const trackingEvents = defineTrackingEvents({
  SIGN_IN_SUCCEEDED: {
    category: AnalyticsCategory.Authentication,
    action: AnalyticsAction.LoginSucceeded,
    label: '-',
  },
  SIGN_IN_FAILED: {
    category: AnalyticsCategory.Authentication,
    action: AnalyticsAction.LoginFailed,
    label: '-',
  },
});

export class AuthenticationActionCreator {
  constructor(protected store: Store<AppState>) {
    authenticationEventListener.on('loginProcessing', async (_, payload) => {
      await this.completeAuthentication(payload);
    });
    authenticationEventListener.on('loginError', (_, error) => {
      this.onFailAuthentication(error);
    });
    authenticationEventListener.on('tokenRefreshStart', async () => {
      await this.startTokenRefresh();
    });
    authenticationEventListener.on('tokenRefreshError', async () => {
      await this.completeTokenRefresh();
    });
  }

  login(authType: AuthType = AuthType.IntegrationDefault): void {
    const integration = currentIntegrationSelector(this.store.getState());
    OAuthService.startLogin(integration, authType);
  }

  logout(authType: AuthType = AuthType.IntegrationDefault): void {
    const integration = currentIntegrationSelector(this.store.getState());
    OAuthService.startLogout(integration, authType);
    this.store.dispatch(logoutSuccess());
  }

  async startAuthentication(): Promise<void> {
    const state = this.store.getState();

    registerUnauthorizedRequestInterceptor(axios, this.store);

    // if user never signed in go to onboarding
    if (!tokenSelector(state)) {
      const isTermsOfServiceAccepted = isTermsOfServiceAcceptedSelector(state);
      await this.onOnboardingScreen();

      if (isTermsOfServiceAccepted) {
        goToRoute(ApplicationRoute.ONBOARDING_LOGIN);
      } else {
        goToRoute(ApplicationRoute.ONBOARDING);
      }

      return;
    }

    // if user is unauthenticated or they have an old access token let's try to refresh it
    if (!isAuthenticatedSelector(state) || tokenExpirationInDaysSelector(state) < 6) {
      await this.startTokenRefresh();
    } else {
      await getActionFacade().startMainApplication();
    }
  }

  async completeAuthentication(loginData: LoginData): Promise<void> {
    const { token, pathToRedirect, expiration } = loginData;
    try {
      const { id, displayName, userName, entitlements } = await IdentityService.whoAmI(token);

      // in goToRoute history.push can only change the route not the whole URL
      // so the user cannot be redirected to evil.com by somehow altering the state param
      if (pathToRedirect) {
        goToRoute(pathToRedirect);
      }

      authenticationEventListener.fireLoginSuccess(); // for interceptors to complete token refresh
      this.store.dispatch(
        loginSuccess({ token, jiveId: userName, externalUserKey: id, expiration, name: displayName, entitlements }),
      );

      if (loginData.isTokenRefresh) {
        await this.completeTokenRefresh();
      }

      newTracker.trackAnalyticsEvent(trackingEvents.SIGN_IN_SUCCEEDED);
    } catch (e) {
      authenticationEventListener.fireLoginError(`Error retrieving user from Identity service: ${e}`);
    }
  }

  async completeTokenRefresh(): Promise<void> {
    const state = this.store.getState();

    const integrationStartupState = integrationStartupStateSelector(state);
    if (integrationStartupState === StartupState.Started || integrationStartupState === StartupState.Starting) {
      return;
    }

    if (isAuthenticatedSelector(state)) {
      await getActionFacade().startMainApplication();
      return;
    }

    goToRoute(ApplicationRoute.ONBOARDING);
  }

  private onFailAuthentication(error: string) {
    logger.error('Error logging user in', { error });
    this.store.dispatch(loginError({ error }));
    this.store.dispatch(showErrorMessage(definedMessages.LOGIN_ERROR_MESSAGE));
    newTracker.trackAnalyticsEvent({ ...trackingEvents.SIGN_IN_FAILED, label: error });
  }

  protected async startTokenRefresh(): Promise<void> {
    const currentIntegration = currentIntegrationSelector(this.store.getState());
    OAuthService.startTokenRefresh(currentIntegration);
  }

  protected async onOnboardingScreen(): Promise<void> {
    const currentIntegration = currentIntegrationSelector(this.store.getState());
    if (currentIntegration !== Integrations.SalesforceLightning) {
      this.store.dispatch(openIntegrationPanel({ reason: 'Onboarding' }));
    }
  }
}
