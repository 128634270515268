import { format } from 'date-fns';
import { difference, uniq } from 'lodash-es';
import { CallWithContact } from '../calls/calls.reducer';
import { logger } from '../logging';
import { stockPileService, StockpileTtl } from '../stockPile/stockPile.service';
import { CallHistoryMetadataItem } from './persistedCallHistoryMetadata.reducer';
import { CallHistoryMetadataPersistItem } from './unifiedCallHistoryMigration.selector';

export interface LoadCallHistoryMetadataResult {
  loadedBuckets: string[];
  callHistoryMetadata: Record<string, CallHistoryMetadataItem>;
}

class CallHistoryMetadataService {
  public async loadCallHistoryMetadata(
    integration: string,
    dates: Array<number | Date>,
    loadedBuckets: string[],
  ): Promise<LoadCallHistoryMetadataResult> {
    const buckets = uniq(dates.map((date) => this.getBucketIdByDate(integration, date)));
    const bucketsToLoad = difference(buckets, loadedBuckets);

    const callHistoryMetadataResults = await Promise.allSettled(
      bucketsToLoad.map((bucket) => stockPileService.getBucket<Record<string, CallHistoryMetadataItem>>(bucket)),
    );

    const failedRequests = callHistoryMetadataResults.filter(
      (metadata): metadata is PromiseRejectedResult => metadata.status === 'rejected',
    );
    if (failedRequests.length) {
      logger.error('Failed to load call history metadata from stockPile', {
        reasons: failedRequests.map<unknown>((req) => req.reason),
      });
    }

    const callHistoryMetadata = callHistoryMetadataResults
      .filter(
        <T>(result: PromiseSettledResult<T>): result is PromiseFulfilledResult<T> => result.status === 'fulfilled',
      )
      .map((metadata) => metadata.value);

    const mergedMetadata = Object.assign({}, ...callHistoryMetadata) as Record<string, CallHistoryMetadataItem>;

    const newlyLoadedBuckets = bucketsToLoad.filter(
      (_, index) => callHistoryMetadataResults[index].status === 'fulfilled',
    );

    return {
      loadedBuckets: newlyLoadedBuckets,
      callHistoryMetadata: mergedMetadata,
    };
  }

  public async storeCallHistoryMetadata(integration: string, calls: CallHistoryMetadataPersistItem[]): Promise<void> {
    const mappedCallHistory: Record<string, Record<string, CallWithContact>> = calls.reduce(
      (accumulator, historyItem) => {
        const key = this.getBucketIdByDate(integration, historyItem.creationTime);

        if (accumulator[key] === undefined) {
          accumulator[key] = {};
        }

        accumulator[key][historyItem.id] = {
          contacts: historyItem.contacts,
          isClickToCall: historyItem.isClickToCall,
        };

        return accumulator;
      },
      {},
    );

    await Promise.all(
      Object.entries(mappedCallHistory).map(([key, metadata]) =>
        stockPileService.updateBucket(key, metadata, StockpileTtl.OneYear),
      ),
    );
  }

  public async updateCallHistoryMetadataItem(
    integration: string,
    call: CallWithContact,
    metadata: CallHistoryMetadataItem,
  ): Promise<void> {
    const persistedBucket = await this.loadCallHistoryMetadata(integration, [call.creationTime], []);

    // let's save the metadata for all legs of the call
    // by doing that we can ensure that we'll be able to find the right metadata after a app/page load
    for (const callId of metadata.allIds ?? [call.id]) {
      persistedBucket.callHistoryMetadata[callId] = metadata;
    }
    await stockPileService.updateBucket(persistedBucket.loadedBuckets[0], persistedBucket.callHistoryMetadata);
  }

  private getBucketIdByDate(integration: string, date: Date | number) {
    return `${integration}_callHistory_${format(date, 'yyyy_MM')}`;
  }
}

export const callHistoryMetadataService = new CallHistoryMetadataService();
