import { AmbassadorProxyAxiosInterceptor } from '../ambassador/proxy/proxy.interceptor';
import { IntegrationNames, Integrations } from '../models';
import { CONNECTWISE_API_PATH } from './connectwise.model';
import { AxiosResponse } from 'axios';
import { logger } from '../logging';
import { proxyUserProviderSelector } from '../ambassador/proxy/proxy.selector';

export class ConnectWiseAxiosInterceptor extends AmbassadorProxyAxiosInterceptor {
  integrationName = IntegrationNames[Integrations.ConnectWise];
  providerHeaders = {
    'Provider-Authorization':
      'basic --ambassador--company--ambassador--+--ambassador--publicKey--ambassador--:--ambassador--privateKey--ambassador--',
    'Provider-clientId': '--ambassador--clientId--ambassador--',
  };

  isUrlToIntercept(url: string): boolean {
    return url.includes(CONNECTWISE_API_PATH);
  }

  protected async unauthorizedResponseHandler(response: AxiosResponse): Promise<AxiosResponse> {
    // TODO handle unauthorized response errors: https://getjive.atlassian.net/browse/JIF-3139
    logger.error(`${this.integrationName} unauthorized response error`, response);
    return Promise.reject<AxiosResponse>(response);
  }

  protected getLinkParameters(): undefined | { linkId: string; selectedProviderId: string } {
    const state = this.store.getState();
    const userProvider = proxyUserProviderSelector(state);

    if (!userProvider) {
      return undefined;
    }

    return {
      linkId: userProvider.id,
      selectedProviderId: userProvider.customAttributes?.selectedProviderId ?? userProvider.providerUuid,
    };
  }
}
