import { logoutSuccess } from './authentication.actions';
import { AppState } from '../reducers';
import { Store } from 'redux';
import { EventListenerBase } from '../EventListenerBase';
import { LoginData } from './authentication.models';

export enum AuthenticationEventMessages {
  LOGIN_PROCESSING = 'authenticationEventMessages/loginProcessing',
  LOGIN_ERROR = 'authenticationEventMessages/loginError',
  LOGOUT_SUCCESS = 'authenticationEventMessages/logoutSuccess',
  REFRESH_TOKEN_ERROR = 'authenticationEventMessages/token/refreshError',
}

class AuthenticationEventListener extends EventListenerBase<{
  tokenRefreshStart;
  tokenRefreshSuccess;
  tokenRefreshError;
  loginError;
  loginSuccess;
  loginProcessing;
}> {
  fireTokenRefreshStart() {
    this.fireEvent('tokenRefreshStart');
  }
  fireTokenRefreshError() {
    this.fireEvent('tokenRefreshError');
  }
  fireLoginSuccess() {
    this.fireEvent('loginSuccess');
  }
  fireLoginError(error: string) {
    this.fireEvent('loginError', error);
  }

  listen = (store: Store<AppState>) => {
    window.addEventListener('message', (message) => {
      if (message && message.data && message.data.type) {
        switch (message.data.type) {
          case AuthenticationEventMessages.LOGIN_PROCESSING: {
            const payload = message.data.payload as LoginData;
            this.fireEvent('loginProcessing', payload);

            if (payload.isTokenRefresh) {
              this.fireEvent('tokenRefreshSuccess');
            }
            break;
          }
          case AuthenticationEventMessages.LOGIN_ERROR: {
            const { error } = message.data.payload;
            this.fireEvent('loginError', error);
            break;
          }
          case AuthenticationEventMessages.LOGOUT_SUCCESS: {
            store.dispatch(logoutSuccess());
            break;
          }
          case AuthenticationEventMessages.REFRESH_TOKEN_ERROR: {
            this.fireEvent('tokenRefreshError');

            break;
          }
        }
      }
    });
  };
}

export const authenticationEventListener = new AuthenticationEventListener();
