import { connect } from 'react-redux';
import { AppState } from '../reducers';
import { TrackedButtonComponent, TrackedButtonOwnProps, TrackedButtonStateProps } from './TrackedButton.component';
import { tracker } from './tracker';

const mapState = (): TrackedButtonStateProps => ({
  trackEvent: (eventLabel: string, eventName: string) => {
    tracker.trackEvent(eventLabel, eventName);
  },
});

export default connect<TrackedButtonStateProps, {}, TrackedButtonOwnProps, AppState>(
  mapState,
  () => ({}), // to prevent dispatch passed to the component
)(TrackedButtonComponent);
