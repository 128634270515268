import { FeatureFlagActions } from './featureFlag.action';
import { FeatureFlagActionTypes, FeatureFlagState } from './featureFlag.model';

export const INITIAL_STATE: FeatureFlagState = {
  watched: [],
};

export const featureFlagReducer = (state = INITIAL_STATE, action: FeatureFlagActions): FeatureFlagState => {
  switch (action.type) {
    case FeatureFlagActionTypes.WATCH: {
      return {
        ...state,
        watched: [...state.watched, action.payload],
      };
    }
    case FeatureFlagActionTypes.UNWATCH: {
      return {
        ...state,
        watched: state.watched.filter((id) => id !== action.payload),
      };
    }
  }

  return state;
};

export default featureFlagReducer;
