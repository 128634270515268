import { makeActionCreator } from '../actions/actionCreators';
import { UserCallHistoryItem } from './callHistory.models';
import { LoadCallHistoryMetadataResult } from './callHistoryMetadata.service';

export interface CallHistoryItems {
  items: UserCallHistoryItem[];
  page: number;
  hasNextPage: boolean;
}

export enum UnifiedCallHistoryActionsType {
  ADD = 'unifiedCallHistory/add',
  LOAD_CALL_HISTORY = 'unifiedCallHistory/load',
  LOAD_CALL_HISTORY_SUCCESS = 'unifiedCallHistory/load/success',
  LOAD_CALL_HISTORY_DIFF_SUCCESS = 'unifiedCallHistory/loadDiff/success',
  LOAD_CALL_HISTORY_ERROR = 'unifiedCallHistory/load/error',
  LOAD_CALL_HISTORY_METADATA_SUCCESS = 'callHistoryMetadata/load/success',
}

export const addUserCallHistoryItem = makeActionCreator(UnifiedCallHistoryActionsType.ADD)<UserCallHistoryItem>();

export const loadCallHistory = makeActionCreator(UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY)<{
  requestDate: Date;
  page: number;
}>();
export const loadCallHistorySuccess = makeActionCreator(UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_SUCCESS)<
  CallHistoryItems
>();
export const loadCallHistoryDiffSuccess = makeActionCreator(
  UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_DIFF_SUCCESS,
)<UserCallHistoryItem[]>();
export const loadCallHistoryError = makeActionCreator(UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_ERROR)();

export const loadCallHistoryMetadataSuccess = makeActionCreator(
  UnifiedCallHistoryActionsType.LOAD_CALL_HISTORY_METADATA_SUCCESS,
)<LoadCallHistoryMetadataResult>();

export type UnifiedCallHistoryActions =
  | ReturnType<typeof addUserCallHistoryItem>
  | ReturnType<typeof loadCallHistory>
  | ReturnType<typeof loadCallHistorySuccess>
  | ReturnType<typeof loadCallHistoryDiffSuccess>
  | ReturnType<typeof loadCallHistoryError>
  | ReturnType<typeof loadCallHistoryMetadataSuccess>;
