import { connect } from 'react-redux';
import { proxyLinkedSelector, proxyProviderAvailableSelector } from '../ambassador/proxy/proxy.selector';
import { currentIntegrationNameSelector } from '../integrations/integrations.selector';
import { AppState } from '../reducers';
import { ClioSettingsComponent, ClioSettingsComponentStateProps } from './SettingsLinkingSection.component';

const mapStateToProps = (state: AppState): ClioSettingsComponentStateProps => ({
  providerAvailable: proxyProviderAvailableSelector(state),
  linked: proxyLinkedSelector(state),
  integrationName: currentIntegrationNameSelector(state),
});

export default connect<ClioSettingsComponentStateProps, {}, {}, AppState>(mapStateToProps)(ClioSettingsComponent);
