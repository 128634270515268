import React, { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { getActiveRoute } from '../browserHistory';
import { ApplicationRoute, InAppNotificationVariantType } from '../constants';
import { InAppNotificationMessage } from '../models';
import { InAppNotification } from '../inAppNotification/InAppNotification.component';
import { TrackedButton } from '../analytics-new/TrackedButton.component';
import { AlertInactiveIcon } from '@getgo/chameleon-icons/react';
import { Box, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { createStyles } from '@material-ui/styles';
import {
  dismissPermissionNotification,
  isPermissionNotificationDismissed,
  isNotMicrophonePermissionDenied,
} from './microphonePermission.service';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SoftphoneCapableFacade } from '../actionFacade/action.facade.capabilities';
import { useDispatch } from 'react-redux';
import { setSoftphoneEnabled } from '../settings/settings.action';
import { openDialog, closeDialog } from '../dialog/dialog.actions';
import { DialogBodyNames } from '../dialog/DialogBodies';

const trackingEvents = defineTrackingEvents({
  USE_MICROPHONE_DEFAULT: {
    category: AnalyticsCategory.Information,
    label: 'Microphone request warning',
    action: AnalyticsAction.PopupShown,
  },
  USE_MICROPHONE_DISABLED: {
    category: AnalyticsCategory.Information,
    label: 'Microphone disabled warning',
    action: AnalyticsAction.PopupShown,
  },
  ENABLE_MICROPHONE_CLICKED: {
    category: AnalyticsCategory.Information,
    label: 'Microphone enable | button',
    action: AnalyticsAction.ItemClicked,
  },
  DISMISS_CLICKED: {
    category: AnalyticsCategory.Information,
    label: 'Microphone dismiss | button',
    action: AnalyticsAction.ItemClicked,
  },
});

export const definedMessages = defineMessages({
  MESSAGE: {
    id: 'Microphone.Permission.Snackbar.Message',
    defaultMessage: 'To manage calls, give microphone permission.',
  },
  UPDATE_BUTTONTEXT: {
    id: 'Microphone.Permission.Snackbar.UpdateButton',
    defaultMessage: 'Update settings',
  },
  NOTNOW_BUTTONTEXT: {
    id: 'Microphone.Permission.Snackbar.NotnowButton',
    defaultMessage: 'Not now',
  },
  NO_PERMISSIONS_DIALOG_TITLE: {
    id: 'Microphone.Permission.Dialog.Title',
    defaultMessage: 'Mic permissions needed',
  },
  NO_PERMISSIONS_DIALOG_BUTTON: {
    id: 'Microphone.Permission.Dialog.Button',
    defaultMessage: 'Thanks, got it',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }),
);

export const MicrophonePermissionsSnackbarComponent: React.FC = () => {
  const classes = useStyles();
  const [isMicrophoneEnabled, setMicrophoneEnabled] = useState<boolean | undefined>(undefined);

  const dispatch = useDispatch();

  const queryPermissions = useCallback(async () => {
    const hasMicrophone = await isNotMicrophonePermissionDenied();
    setMicrophoneEnabled(hasMicrophone);
  }, [setMicrophoneEnabled]);

  useEffect(() => {
    void queryPermissions();
  }, []);

  const isOnMicrophonePermissionPage = getActiveRoute()?.route === ApplicationRoute.MICROPHONE_PERMISSION;
  const [isDismissed, setIsDismissed] = useState(isPermissionNotificationDismissed());
  const shouldDisplayMicrophonePermissionSnackbar =
    !isDismissed && isMicrophoneEnabled === false && !isOnMicrophonePermissionPage;

  useEffect(() => {
    const updatePermissions = async () => {
      const hasMicrophone = await isNotMicrophonePermissionDenied();
      setMicrophoneEnabled(hasMicrophone);
    };

    window.addEventListener('focus', updatePermissions);

    return () => {
      window.removeEventListener('focus', updatePermissions);
    };
  }, []);

  useEffect(() => {
    if (shouldDisplayMicrophonePermissionSnackbar) {
      const trackingEvent = isMicrophoneEnabled
        ? trackingEvents.USE_MICROPHONE_DEFAULT
        : trackingEvents.USE_MICROPHONE_DISABLED;
      newTracker.trackAnalyticsEvent(trackingEvent);
    }
  }, [isMicrophoneEnabled, shouldDisplayMicrophonePermissionSnackbar]);

  useEffect(() => {
    if (isMicrophoneEnabled === false && isDismissed) {
      dispatch(setSoftphoneEnabled(false));
    }
  }, [isMicrophoneEnabled, isDismissed]);

  if (!shouldDisplayMicrophonePermissionSnackbar) {
    return null;
  }

  const onNotNowButtonClick = () => {
    const DIALOG_ID = 'mic_permission_dialog';

    dispatch(
      openDialog({
        dialogId: DIALOG_ID,
        cancellable: false,
        closeCallback: () => {
          dismissPermissionNotification();
          setIsDismissed(true);
          dispatch(closeDialog({ dialogId: DIALOG_ID }));
        },
        texts: {
          title: definedMessages.NO_PERMISSIONS_DIALOG_TITLE,
          confirm: definedMessages.NO_PERMISSIONS_DIALOG_BUTTON,
        },
        bodyName: DialogBodyNames.NoMicPermission,
      }),
    );
  };

  const notificationMessage: InAppNotificationMessage = {
    id: 'definedMessages.MICROPHONE_PERMISSION_DEFAULT_MESSAGE', // this id is used in a repeater only
    message: definedMessages.MESSAGE,
    params: {
      autoHide: false,
      dismissible: false,
      icon: <AlertInactiveIcon />,
      actions: (
        <Box display="flex" className={classes.buttonContainer} justifyContent="space-between" flex={2}>
          <TrackedButton
            trackingEvent={trackingEvents.DISMISS_CLICKED}
            variant="neutral-inverse"
            size="small"
            onClick={onNotNowButtonClick}
          >
            <FormattedMessage {...definedMessages.NOTNOW_BUTTONTEXT} />
          </TrackedButton>
          <TrackedButton
            trackingEvent={trackingEvents.ENABLE_MICROPHONE_CLICKED}
            variant="neutral-inverse"
            size="small"
            onClick={() => {
              void getActionFacade<SoftphoneCapableFacade>().openMicrophonePermissionPage();
            }}
          >
            <FormattedMessage {...definedMessages.UPDATE_BUTTONTEXT} />
          </TrackedButton>
        </Box>
      ),
    },
    type: InAppNotificationVariantType.NEUTRAL,
  };

  return <InAppNotification message={notificationMessage} close={() => undefined} />;
};
