import type { JifTenantPbx } from '@jive/core';
import { combineReducers } from 'redux';
import { FeatureFlag } from '../featureFlag/featureFlag.model';
import { EntityPhoneNumber } from '../search/entities.model';
import { NormalizedDevice } from '../settings/devices.model';
import { Voicemail } from '../voicemails/Voicemail.model';
import { DevicesEntityActions, DevicesEntityActionsType } from './devicesEntity.action';
import { FeatureFlagEntityActions, FeatureFlagEntityActionsType } from './featureFlags.action';
import jiveEntityReducer from './jiveEntitiesCommon.reducer';
import { PbxesEntityActions, PbxesEntityActionsType } from './pbxesEntity.action';
import { PhoneNumbersActions, PhoneNumbersActionsType } from './phoneNumbers.action';
import relationshipsReducer from './relationships/relationships.reducer';
import { VoicemailsEntityActions, VoicemailsEntityActionsType } from './voicemailsEntity.action';

const jiveEntities = combineReducers({
  relationships: relationshipsReducer,
  devices: jiveEntityReducer<NormalizedDevice, DevicesEntityActions>({
    add: DevicesEntityActionsType.ADD,
    clean: DevicesEntityActionsType.CLEAN,
  }),
  voicemails: jiveEntityReducer<Voicemail, VoicemailsEntityActions>({
    add: VoicemailsEntityActionsType.ADD,
    clean: VoicemailsEntityActionsType.CLEAN,
  }),
  featureFlags: jiveEntityReducer<FeatureFlag, FeatureFlagEntityActions>({
    add: FeatureFlagEntityActionsType.ADD,
    clean: FeatureFlagEntityActionsType.CLEAN,
  }),
  phoneNumbers: jiveEntityReducer<EntityPhoneNumber, PhoneNumbersActions>({
    add: PhoneNumbersActionsType.ADD,
    clean: PhoneNumbersActionsType.CLEAN,
  }),
  pbxes: jiveEntityReducer<JifTenantPbx, PbxesEntityActions>({
    add: PbxesEntityActionsType.ADD,
    clean: PbxesEntityActionsType.CLEAN,
  }),
});

export default jiveEntities;
