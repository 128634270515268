import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Typography, TypographyProps } from '../theme/Typography.component';

interface DataRowProps extends Pick<TypographyProps, 'color'> {
  label: MessageDescriptor;
  value: string | React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginRight: theme.spacing(2),
    },
  }),
);

export const DataRow: React.FC<DataRowProps> = ({ label, value, color }) => {
  const classes = useStyles();
  return (
    <Typography variant="caption-default-01" color={color}>
      <FormattedMessage {...label}>{(txt) => <span className={classes.label}>{txt}</span>}</FormattedMessage>
      <b>{value}</b>
    </Typography>
  );
};
