import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '../theme/Typography.component';
import { LinkAccount } from './link-account/LinkAccount.component';

const definedMessages = defineMessages({
  INTEGRATION_STATUS: {
    id: 'Integration.Status',
    defaultMessage: '{integrationName} Status',
  },
  INTEGRATION_STATUS_LABEL: {
    id: 'SettingsContainer.StatusLabel',
    defaultMessage: 'Status',
  },
  INTEGRATION_STATUS_LINKED: {
    id: 'SettingsContainer.StatusLinked',
    defaultMessage: 'Linked',
  },
  INTEGRATION_STATUS_UNLINKED: {
    id: 'SettingsContainer.StatusUnlinked',
    defaultMessage: 'Unlinked',
  },
  CONFIGURE: {
    id: 'Configure',
    defaultMessage: 'Configure',
  },
});

export interface ClioSettingsComponentStateProps {
  linked?: boolean;
  providerAvailable?: boolean;
  integrationName: string;
}

export const ClioSettingsComponent: React.FC<ClioSettingsComponentStateProps> = (props) => {
  const [version, setVersion] = useState<string | undefined>(undefined);
  const { linked, integrationName } = props;

  useEffect(() => {
    const getElectronAppVersion = (window as any).getElectronAppVersion as () => Promise<string>;
    if (getElectronAppVersion) {
      getElectronAppVersion()
        .then((value) => setVersion(value))
        .catch((err) => console.log('Error getting Electron app version', err));
    }
  }, []);

  return (
    <>
      <div>
        <Typography variant="heading-xsmall" tag="span">
          <FormattedMessage {...definedMessages.INTEGRATION_STATUS} values={{ integrationName }} />
        </Typography>
        {linked && (
          <Box ml={4} component="span">
            <Typography tag="span">
              <b>
                <FormattedMessage {...definedMessages.INTEGRATION_STATUS_LINKED} />
              </b>
            </Typography>
          </Box>
        )}
        {!linked && (
          <Box ml={4} component="span">
            <Typography tag="span" color="text-error-01">
              <b>
                <FormattedMessage {...definedMessages.INTEGRATION_STATUS_UNLINKED} />
              </b>
            </Typography>
          </Box>
        )}
      </div>
      <Box mt={3}>
        <LinkAccount />
      </Box>
      <Box display="flex" flexGrow={1} flexDirection="column-reverse">
        <Typography variant="caption-default-01">GoToConnect Embedded Integrations v{version}</Typography>
      </Box>
    </>
  );
};
