import { makeActionCreator } from '../../actions/actionCreators';
import { WithMessage } from '../../models';
import { InventoryPartId, InventoryPartItem } from './parts.models';

export enum PartsActionsType {
  LOAD_INVENTORY_PART = 'inventoryPart/load',
  LOAD_INVENTORY_PART_SUCCESS = 'inventoryPart/load/success',
  LOAD_INVENTORY_PART_ERROR = 'inventoryPart/load/error',
}

export const loadInventoryPart = makeActionCreator(PartsActionsType.LOAD_INVENTORY_PART)<InventoryPartId>();
export const loadInventoryPartSuccess = makeActionCreator(PartsActionsType.LOAD_INVENTORY_PART_SUCCESS)<
  InventoryPartId & { part: InventoryPartItem | undefined }
>();
export const loadInventoryPartError = makeActionCreator(PartsActionsType.LOAD_INVENTORY_PART_ERROR)<
  WithMessage & InventoryPartId
>();

export type PartsActions =
  | ReturnType<typeof loadInventoryPart>
  | ReturnType<typeof loadInventoryPartSuccess>
  | ReturnType<typeof loadInventoryPartError>;
