import { createSelector } from 'reselect';
import { IndexedById } from '../../models';
import { EntityPhoneNumber } from '../../search/entities.model';
import { relationshipOneToManyGetChildren } from './relationshipsOneToMany.selector';

export const getEntityPhoneNumbersSelector = (entityId: string) =>
  relationshipOneToManyGetChildren<EntityPhoneNumber>('entityPhoneNumbers', entityId, 'phoneNumbers');

export const getAlreadySavedPhoneNumbersForLoadingSelector = (entityId: string) =>
  createSelector(getEntityPhoneNumbersSelector(entityId), (entityPhoneNumbers) => {
    return entityPhoneNumbers
      .filter((e) => e.phoneNumber) // without this, search results without phone numbers show up as empty lines
      .reduce<IndexedById<EntityPhoneNumber>>((prev, entityPhoneNumber) => {
        prev[entityId + entityPhoneNumber.phoneNumber] = entityPhoneNumber;
        return prev;
      }, {});
  });
