import { IconButton } from '@getgo/chameleon-material-ui';
import { tokens } from '@getgo/chameleon-core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import clsx from 'clsx';

export const useStyles = makeStyles(
  () =>
    createStyles({
      toggleButtons: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '3px',
        border: `solid 1px ${tokens.interactive01}`,
        color: tokens.interactive01,
        cursor: 'pointer',
        userSelect: 'none',
      },
      actionButton: {
        border: '0 !important',
        borderRadius: '0 !important',
        '--goto-icon-color': `${tokens.interactive01}`,
      },
      active: {
        '--goto-icon-color': `${tokens.inverse02} !important`,
        backgroundColor: `${tokens.interactive01} !important`,
      },
    }),
  { name: 'IconsToggler' },
);

export interface IconsTogglerProps {
  rightIsActive: boolean;
  handleLeftIconClick: () => void;
  handleRightIconClick: () => void;
  leftIcon: ReactNode;
  rightIcon: ReactNode;
  leftLabel: string;
  rightLabel: string;
}

export const IconsToggler: React.FC<IconsTogglerProps> = ({
  handleLeftIconClick,
  handleRightIconClick,
  leftIcon,
  rightIcon,
  rightIsActive,
  leftLabel,
  rightLabel,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.toggleButtons}>
      <IconButton
        classes={{ root: clsx(!rightIsActive && classes.active, classes.actionButton) }}
        size="xsmall"
        onClick={handleLeftIconClick}
        inverse={!rightIsActive}
        aria-label={leftLabel}
      >
        {leftIcon}
      </IconButton>
      <IconButton
        classes={{ root: clsx(rightIsActive && classes.active, classes.actionButton) }}
        size="xsmall"
        inverse={rightIsActive}
        onClick={handleRightIconClick}
        aria-label={rightLabel}
      >
        {rightIcon}
      </IconButton>
    </div>
  );
};
