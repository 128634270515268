import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const messageResourceSelector = (state: AppState) => state.messages.result;

const messageAllIdsSelector = createSelector(messageResourceSelector, (messagesResource) => messagesResource.allIds);

const messageByIdSelector = createSelector(messageResourceSelector, (messagesResource) => messagesResource.byId);

export const allMessagesSelector = createSelector(messageAllIdsSelector, messageByIdSelector, (allIds, byId) =>
  allIds.map((messageId) => byId[messageId]),
);
