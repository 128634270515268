import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { AmbassadorProvider, AmbassadorUserProvider } from './ambassador.service';

const ambassadorReducerSelector = (state: AppState) => state.ambassador;

export const ambassadorProvidersSelector: (state: AppState) => AmbassadorProvider[] | undefined = createSelector(
  ambassadorReducerSelector,
  (ambassadorReducer) => ambassadorReducer.providers,
);

export const ambassadorProviderByNameSelector = (
  state: AppState,
  providerName: string,
): AmbassadorProvider | undefined => {
  const userProviders = ambassadorProvidersSelector(state);
  return userProviders
    ? userProviders.find(
        (p) => typeof p.name !== 'undefined' && p.name.toLocaleLowerCase() === providerName.toLocaleLowerCase(),
      )
    : undefined;
};

export const ambassadorUserProvidersSelector: (
  state: AppState,
) => AmbassadorUserProvider[] | undefined = createSelector(
  ambassadorReducerSelector,
  (ambassadorReducer) => ambassadorReducer.userProviders,
);

export const ambassadorUserProviderByNameSelector = (
  state: AppState,
  providerName: string,
): AmbassadorUserProvider | undefined => {
  const providerByName = ambassadorProviderByNameSelector(state, providerName);
  if (!providerByName) {
    return undefined;
  }

  const userProviders = ambassadorUserProvidersSelector(state);
  return userProviders && userProviders.find((p) => providerByName.id === p.providerUuid);
};
