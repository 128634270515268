import { memoize } from 'lodash-es';
import { AppState } from '../reducers/index';
import { createSelector } from 'reselect';
import { EntityType } from '../constants';

export const salesforceCommonSelector = (state: AppState) => state.salesforce.common;

export const isCallDispositionConfiguredSelector = createSelector(
  salesforceCommonSelector,
  (common) => !!(common.isCallDispositionConfigured && common.callDispositionValues?.length),
);
export const callDispositionValuesSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.callDispositionValues,
);

export const savedCallLogsSelector = createSelector(salesforceCommonSelector, (common) => common.savedCallLogs);

export const savedTaskRecordIdsSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.savedTaskRecordIds,
);

export const isRunningInConsoleModeSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.isRunningInConsoleMode,
);

export const savedTaskRecordIdSelector = (state: AppState, callId: string) => savedTaskRecordIdsSelector(state)[callId];

export const salesforceInitializationErrorSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.initError,
);

export const salesforceLastCallLogErrorSelector = createSelector(
  salesforceCommonSelector,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (common) => common.lastCallLogError,
);

export const getStoredSalesforceCallLogByCallId = (state: AppState, callId: string) =>
  salesforceCommonSelector(state).storedCallLogs[callId];

export const mimissingObjectAccessRightsSelector = (state: AppState): EntityType[] =>
  salesforceCommonSelector(state).missingObjectAccessRights;

export const hasAccessToEntity = memoize((entity: EntityType) =>
  createSelector(
    mimissingObjectAccessRightsSelector,
    (missingObjectAccessRights): boolean => !missingObjectAccessRights.includes(entity),
  ),
);

export const whatObjectNamesSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.taskWhatIdObjectNames,
);
export const whoObjectNamesSelector = createSelector(salesforceCommonSelector, (common) => common.taskWhoIdObjectNames);
export const whoObjectsSelector = createSelector(salesforceCommonSelector, (common) => common.whoObjects);
export const isWhoObjectsLoadingSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.whoObjectsLoading,
);
export const whatObjectsSelector = createSelector(salesforceCommonSelector, (common) => common.whatObjects);
export const isWhatObjectsLoadingSelector = createSelector(
  salesforceCommonSelector,
  (common) => common.whatObjectsLoading,
);

export const selectTaskRelatedThemeItems = createSelector(
  salesforceCommonSelector,
  (common) => common.taskRelatedThemeItems,
);

export const selectSalesforceObjectNameByObjectId = (id?: string) =>
  createSelector(
    salesforceCommonSelector,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (common) => (id ? common.taskRelatedObjectPrefixes[id.substr(0, 3)] : undefined),
  );
