import { GetMoreNotesRequestMeta } from './notes.actions';

export interface Note {
  id: string;
  subject: string;
  content: string;
  createdAtIso8601: string;
}

export enum GetNotesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export enum GetMoreNotesRequestState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface GetNotesState {
  requestState: GetNotesRequestState;
  moreRequestState: GetMoreNotesRequestState;

  requestMeta?: GetMoreNotesRequestMeta;
  notes: Note[];
  notesCount?: number;
}

export interface AddNotePayload {
  contactId: string;
  contactName?: string;
  noteSubject: string;
  content: string;
  isActiveCall: boolean;
}

export interface SalesforceAddNotePayload extends AddNotePayload {
  id: string;
}
