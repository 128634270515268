import { Button } from '@getgo/chameleon-material-ui';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../../analytics-new/analytics.models';
import { newTracker } from '../../analytics-new/tracker-new';
import { selectedLineSelector } from '../../settings/settings.selector';
import { makeTestCall } from './testCall.actionCreator';
import { isTestCallPendingSelector } from './testCall.selectors';

const definedMessages = defineMessages({
  TEST_CALL_BUTTON_INITIAL_TEXT: {
    id: 'Onboarding.TestCall.Button.Initial',
    defaultMessage: 'Test a call',
  },
  TEST_CALL_BUTTON_LOADING_TEXT: {
    id: 'Onboarding.TestCall.Button.Loading',
    defaultMessage: 'Call in progress',
  },
});

const trackingEvents = defineTrackingEvents({
  TEST_CALL_BUTTON: {
    category: AnalyticsCategory.Configuration,
    action: AnalyticsAction.ItemClicked,
    label: 'Test call | button',
  },
});

interface TestCallProps {
  showBannerOnSuccess?: boolean;
}

export const TestCall: React.FC<TestCallProps> = ({ showBannerOnSuccess }) => {
  const dispatch = useDispatch();
  const isTestCallPending = useSelector(isTestCallPendingSelector);
  const selectedLine = useSelector(selectedLineSelector);

  const makeTestCallClicked = () => {
    if (!selectedLine) {
      return;
    }

    void makeTestCall(dispatch, selectedLine, { showBannerOnSuccess });
    newTracker.trackAnalyticsEvent(trackingEvents.TEST_CALL_BUTTON);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        disabled={isTestCallPending || !selectedLine}
        loading={isTestCallPending}
        onClick={() => makeTestCallClicked()}
      >
        {isTestCallPending ? (
          <FormattedMessage {...definedMessages.TEST_CALL_BUTTON_LOADING_TEXT} />
        ) : (
          <FormattedMessage {...definedMessages.TEST_CALL_BUTTON_INITIAL_TEXT} />
        )}
      </Button>
    </>
  );
};
