import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { getEntityInCallSelector } from '../../calls/calls.selector';
import { clearMatters } from './matter.actions';
import { getMattersRequestStateSelector, matterListSelector } from './matter.selectors';
import {
  MatterPickerComponent,
  MatterPickerDispatchProps,
  MatterPickerProps,
  MatterPickerStateProps,
} from './MatterPicker.component';
import { getActionFacade } from '../../actionFacade/action.facade.store';
import { ClioActionFacade } from '../clio.action.facade';
import { Dispatch } from 'redux';

const mapStateToProps = (state: AppState, ownProps: MatterPickerProps): MatterPickerStateProps & MatterPickerProps => ({
  ...ownProps,
  matters: matterListSelector(state),
  requestState: getMattersRequestStateSelector(state),
  entity: ownProps.callId ? getEntityInCallSelector(state, ownProps.callId) : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchMatters: async (query: string) => {
    await getActionFacade<ClioActionFacade>().searchMatters(query);
  },
  clearMatters: () => dispatch(clearMatters()),
});

export const MatterPicker = connect<MatterPickerStateProps, MatterPickerDispatchProps, MatterPickerProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(MatterPickerComponent);
