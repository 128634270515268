import { ExtendedTheme } from '@getgo/chameleon-material-ui';
import { makeStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { tokens } from '@getgo/chameleon-core';

export interface NavigationRailItemProps {
  isActive: boolean;
  disabled: boolean;
  orientation: 'vertical' | 'horizontal';
}

const useStyles = makeStyles((theme: ExtendedTheme) => ({
  navigationRailItem: ({ isActive, disabled, orientation }: NavigationRailItemProps) => {
    const normalIconColor = tokens.navigationRailIcon;
    const iconColor = disabled
      ? tokens.navigationRailIconDisabled
      : isActive
      ? tokens.navigationRailIconSelected
      : normalIconColor;
    return {
      display: 'flex',
      padding: theme.spacing(orientation === 'vertical' ? 3 : 2),
      color: iconColor,
      '--goto-icon-color': iconColor,
      '& a': {
        color: iconColor,
        '--goto-icon-color': iconColor,
        textDecoration: 'none',
      },
    };
  },
}));

export const NavigationRailItem = React.forwardRef<HTMLDivElement, NavigationRailItemProps & { children: ReactNode }>(
  ({ isActive, disabled, orientation, children, ...rest }, ref) => {
    const styles = useStyles({ isActive, disabled, orientation });

    return (
      <div {...rest} ref={ref} className={styles.navigationRailItem}>
        {children}
      </div>
    );
  },
);
