import { logger } from '../../logging';
import { Store } from 'redux';
import { AppState } from '../../reducers';
import { defineMessages } from 'react-intl';
import { clearCompanies, getCompanies, getCompaniesError, getCompaniesSuccess } from './companyPicker.action';
import { ConnectWiseService } from '../connectwise.service';

export const definedMessages = defineMessages({
  CONNECTWISE_COMPANIES_GET_ERROR: {
    id: 'ConnectWise.Companies.Get.Error',
    defaultMessage: 'There was an error while trying to download ConnectWise companies, please try again.',
  },
});

export class CompanyActionCreator {
  constructor(protected store: Store<AppState>) {}

  public async getCompanies(): Promise<void> {
    this.store.dispatch(getCompanies());
    try {
      this.store.dispatch(
        getCompaniesSuccess({
          companies: await ConnectWiseService.getCompanies(),
        }),
      );
    } catch (error) {
      logger.error('Error getting ConnectWise companies', error);
      this.store.dispatch(getCompaniesError({ message: definedMessages.CONNECTWISE_COMPANIES_GET_ERROR }));
    }
  }

  public async clearCompanies(): Promise<void> {
    this.store.dispatch(clearCompanies());
  }
}
