import { ThemeActions } from './theme.action';
import { JiveThemes, ThemeActionsType, ThemeState } from './theme.model';

export const INITIAL_STATE: ThemeState = {
  selected: JiveThemes.Light,
};

export function themeReducer(state = INITIAL_STATE, action: ThemeActions) {
  switch (action.type) {
    case ThemeActionsType.SET:
      return {
        ...state,
        selected: action.payload,
      };
  }

  return state;
}

export default themeReducer;
