import { PostCallActionsType } from '../calls/callEvents.action';
import { clickToCallRequest } from '../calls/click-to-call.action';
import { addPhoneNumbers } from '../jiveEntities/phoneNumbers.action';
import { replaceEntityPhoneNumberRelationship } from '../jiveEntities/relationships/entityPhoneNumbers.action';
import { getEntityPhoneNumbersSelector } from '../jiveEntities/relationships/entityPhoneNumbers.selector';
import { addListener, addListenerForOneTimeExecution } from '../middlewares/listener.middleware';
import { EntitiesActionsType, loadEntityPhoneNumbersRequest } from '../search/entities.action';
import { PhoneActionsType, phoneClear, setPhoneSelectedNumber } from './phone.actions';
import { showMessage } from '../inAppNotification/message.action';
import { Integrations, MessageVariant } from '../models';
import React from 'react';
import DoNotCallMessage from '../salesforce/messages/DoNotCallMessage.container';
import { AnalyticsAction, AnalyticsCategory, defineTrackingEvents } from '../analytics-new/analytics.models';
import { newTracker } from '../analytics-new/tracker-new';
import { getActionFacade } from '../actionFacade/action.facade.store';
import { SalesforceLightningActionFacade } from '../salesforce/salesforceLightning.actionfacade';
import { isSoftphoneCapableAndEnabledSelector } from '../settings/settings.selector';
import { currentIntegrationSelector } from '../integrations/integrations.selector';

export const definedMessages = {
  CALL_DONOTCALL_TITLE: {
    id: 'Call.DoNotCall.Warning.Title',
    defaultMessage: 'You are about to call a "Do Not Call" number',
  },
  CALL_DONOTCALL_CALLANYWAY: {
    id: 'Call.DoNotCall.Warning.Button',
    defaultMessage: 'Call this number anyway',
  },
};

const trackingEvents = defineTrackingEvents({
  CALL_LIMITATIONS_DO_NOT_CALL_POPUP: {
    category: AnalyticsCategory.CallLimitations,
    action: AnalyticsAction.PopupShown,
    label: 'You are about to call a do not disturb number',
  },
});

addListener(PhoneActionsType.CALL)((state, action) => {
  const { phoneNumber, contact } = action.payload;

  if (contact && contact.doNotCall) {
    newTracker.trackAnalyticsEvent(trackingEvents.CALL_LIMITATIONS_DO_NOT_CALL_POPUP);

    return showMessage({
      id: 'do_not_call_msg',
      message: definedMessages.CALL_DONOTCALL_TITLE,
      type: MessageVariant.Custom,
      params: {
        autoHide: false,
        customContent: <DoNotCallMessage contactName={contact?.name ? contact.name : ''} phoneNumber={phoneNumber} />,
      },
    });
  }

  const integration = currentIntegrationSelector(state);
  if (integration === Integrations.MsTeams) {
    return clickToCallRequest({
      phoneNumber,
      contactName: contact ? contact.name : undefined,
    });
  }

  const isSoftphoneCapableAndEnabled = isSoftphoneCapableAndEnabledSelector(state);

  if (isSoftphoneCapableAndEnabled) {
    return getActionFacade<SalesforceLightningActionFacade>().makeCall(phoneNumber);
  }

  return clickToCallRequest({
    phoneNumber,
    contactName: contact ? contact.name : undefined,
  });
});

addListener(EntitiesActionsType.ENTITY_SEARCH)((_) => {
  return phoneClear();
});

addListener(PhoneActionsType.SET_SELECTED_ENTITY)((state, action) => {
  if (action.payload.entityId === undefined) {
    return;
  }
  const entityPhoneNumbers = getEntityPhoneNumbersSelector(action.payload.entityId)(state);
  const selectedPhoneNumber = entityPhoneNumbers.length > 0 ? entityPhoneNumbers[0].phoneNumber : '';

  addListenerForOneTimeExecution(EntitiesActionsType.ENTITY_LOAD_PHONES_SUCCESS)((_, action) => {
    const phoneNumbersKeys = Object.keys(action.payload.phoneNumbers);

    if (phoneNumbersKeys.length === 1) {
      return setPhoneSelectedNumber(action.payload.phoneNumbers[phoneNumbersKeys[0]].phoneNumber);
    }
  });

  return [loadEntityPhoneNumbersRequest(action.payload.entityId), setPhoneSelectedNumber(selectedPhoneNumber)];
});

addListener(EntitiesActionsType.ENTITY_LOAD_PHONES_SUCCESS)((_, action) => {
  return [
    addPhoneNumbers(action.payload.phoneNumbers),
    replaceEntityPhoneNumberRelationship({
      [action.payload.entityId]: Object.keys(action.payload.phoneNumbers),
    }),
  ];
});

// when a new active call is selected clear the selected entity id state
addListener(PostCallActionsType.UPDATE_ACTIVE_CALL)(() => {
  return phoneClear();
});
