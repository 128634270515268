import { makeActionCreator } from '../actions/actionCreators';

export enum ChangelogActionTypes {
  SAVE_LAST_SEEN_VERSION = 'changelog/saveLastSeenVersion',
  LOAD_LAST_SEEN_VERSION = 'changelog/loadLastSeenVersion',
  LOAD_LAST_SEEN_VERSION_SUCCESS = 'changelog/loadLastSeenVersion/success',
  LOAD_LAST_SEEN_VERSION_ERROR = 'changelog/loadLastSeenVersion/error',
  SAVE_LAST_SEEN_ANNOUNCEMENT = 'changelog/saveLastSeenAnnouncement',
  LOAD_LAST_SEEN_ANNOUNCEMENT = 'changelog/loadLastSeenAnnouncement',
  LOAD_LAST_SEEN_ANNOUNCEMENT_SUCCESS = 'changelog/loadLastSeenAnnouncement/success',
  LOAD_LAST_SEEN_ANNOUNCEMENT_ERROR = 'changelog/loadLastSeenAnnouncement/error',
  SET_HAS_NEW_ANNOUNCEMENT = 'changelog/setHasNewAnnouncement',
}

export const saveLastSeenVersion = makeActionCreator(ChangelogActionTypes.SAVE_LAST_SEEN_VERSION)<string | undefined>();
export const loadLastSeenVersion = makeActionCreator(ChangelogActionTypes.LOAD_LAST_SEEN_VERSION)();
export const loadLastSeenVersionSuccess = makeActionCreator(ChangelogActionTypes.LOAD_LAST_SEEN_VERSION_SUCCESS)<
  string | undefined
>();
export const loadLastSeenVersionError = makeActionCreator(ChangelogActionTypes.LOAD_LAST_SEEN_VERSION_ERROR)();
export const saveLastSeenAnnouncement = makeActionCreator(ChangelogActionTypes.SAVE_LAST_SEEN_ANNOUNCEMENT)<
  string | undefined
>();
export const loadLastSeenAnnouncement = makeActionCreator(ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT)();
export const loadLastSeenAnnouncementSuccess = makeActionCreator(
  ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT_SUCCESS,
)<string | undefined>();
export const loadLastSeenAnnouncementError = makeActionCreator(
  ChangelogActionTypes.LOAD_LAST_SEEN_ANNOUNCEMENT_ERROR,
)();
export const setHasNewAnnouncement = makeActionCreator(ChangelogActionTypes.SET_HAS_NEW_ANNOUNCEMENT)<boolean>();

export type ChangelogActions =
  | ReturnType<typeof saveLastSeenVersion>
  | ReturnType<typeof loadLastSeenVersion>
  | ReturnType<typeof loadLastSeenVersionSuccess>
  | ReturnType<typeof loadLastSeenVersionError>
  | ReturnType<typeof saveLastSeenAnnouncement>
  | ReturnType<typeof loadLastSeenAnnouncement>
  | ReturnType<typeof loadLastSeenAnnouncementSuccess>
  | ReturnType<typeof loadLastSeenAnnouncementError>
  | ReturnType<typeof setHasNewAnnouncement>;
