import { combineReducers } from 'redux';
import {
  EntityPhoneNumbersRelationshipActions,
  EntityPhoneNumbersRelationshipActionsType,
} from './entityPhoneNumbers.action';
import {
  LineVoicemailsEntityRelationshipActionsType,
  LineVoicemailsRelationshipActions,
} from './lineVoicemails.action';
import jiveEntityRelationshipsOneToManyReducer from './relationshipsOneToMany.reducer';

const relationshipsReducer = combineReducers({
  lineVoicemails: jiveEntityRelationshipsOneToManyReducer<LineVoicemailsRelationshipActions>({
    replace: LineVoicemailsEntityRelationshipActionsType.REPLACE,
    clean: LineVoicemailsEntityRelationshipActionsType.CLEAN,
  }),
  entityPhoneNumbers: jiveEntityRelationshipsOneToManyReducer<EntityPhoneNumbersRelationshipActions>({
    replace: EntityPhoneNumbersRelationshipActionsType.REPLACE,
    clean: EntityPhoneNumbersRelationshipActionsType.CLEAN,
  }),
});

export default relationshipsReducer;
