import { MessageDescriptor } from 'react-intl';
import { makeActionCreator } from '../actions/actionCreators';
import { WithMessage } from '../models';
import { AddNotePayload, Note } from './notes.models';

export enum NoteActionsType {
  RESET = 'notes/reset',
  GET_NOTES = 'notes/get',
  GET_NOTES_SUCCESS = 'notes/get/success',
  GET_NOTES_ERROR = 'notes/get/error',

  GET_MORE_NOTES = 'notes/more/get',
  GET_MORE_NOTES_SUCCESS = 'notes/more/success',
  GET_MORE_NOTES_ERROR = 'notes/more/error',

  ADD_NOTE = 'notes/add',
  ADD_NOTE_SUCCESS = 'notes/add/success',
  ADD_NOTE_ERROR = 'notes/add/error',

  OPEN_NOTE = 'note/open/request',
  OPEN_NOTE_SUCCESS = 'note/open/success',
  OPEN_NOTE_ERROR = 'note/open/error',

  LOAD_NOTES_COUNT = 'note/getCount/request',
  LOAD_NOTES_COUNT_SUCCESS = 'note/getCount/success',
  LOAD_NOTES_COUNT_ERROR = 'note/getCount/error',
}

export const addNote = makeActionCreator(NoteActionsType.ADD_NOTE)<AddNotePayload>();

export const addNoteSuccess = makeActionCreator(NoteActionsType.ADD_NOTE_SUCCESS)<{
  contactId?: string;
  message: MessageDescriptor;
}>();
export const addNoteError = makeActionCreator(NoteActionsType.ADD_NOTE_ERROR)<{
  message: MessageDescriptor;
}>();

export const resetNotes = makeActionCreator(NoteActionsType.RESET)();

export interface GetNotesPayload {
  contactId: string;
}

export interface ShowNotesPayload {
  contactId: string;
}

export interface ShowNotesErrorPayload extends WithMessage {
  contactId: string;
}

export const getNotes = makeActionCreator(NoteActionsType.GET_NOTES)<GetNotesPayload>();

export interface GetNotesSuccessPayload {
  meta: GetMoreNotesRequestMeta;
  notes: Note[];
}

export const getNotesSuccess = makeActionCreator(NoteActionsType.GET_NOTES_SUCCESS)<GetNotesSuccessPayload>();

export interface GetNotesErrorPayload {
  message: {
    id: string;
    defaultMessage?: string;
    description?: string;
  };
}

export const getNotesError = makeActionCreator(NoteActionsType.GET_NOTES_ERROR)<GetNotesErrorPayload>();

export interface GetMoreNotesRequestMeta {
  next?: string;
  notesCount: number; // current number of downloaded notes
  contactId?: string;
}

export const getMoreNotes = makeActionCreator(NoteActionsType.GET_MORE_NOTES)();
export const getMoreNotesSuccess = makeActionCreator(NoteActionsType.GET_MORE_NOTES_SUCCESS)<GetNotesSuccessPayload>();
export const getMoreNotesError = makeActionCreator(NoteActionsType.GET_MORE_NOTES_ERROR)<GetNotesErrorPayload>();

export const openNoteRequest = makeActionCreator(NoteActionsType.OPEN_NOTE)();
export const openNoteSuccess = makeActionCreator(NoteActionsType.OPEN_NOTE_SUCCESS)();
export const openNoteError = makeActionCreator(NoteActionsType.OPEN_NOTE_ERROR)<WithMessage>();

export interface LoadNotesCountPayload {
  notesCount: number; // number of all Notes in CRM
}

export const loadNotesCountRequest = makeActionCreator(NoteActionsType.LOAD_NOTES_COUNT)();
export const loadNotesCountSuccess = makeActionCreator(NoteActionsType.LOAD_NOTES_COUNT_SUCCESS)<
  LoadNotesCountPayload
>();
export const loadNotesCountError = makeActionCreator(NoteActionsType.LOAD_NOTES_COUNT_ERROR)();

export type NoteActions =
  | ReturnType<typeof resetNotes>
  | ReturnType<typeof getNotes>
  | ReturnType<typeof getNotesSuccess>
  | ReturnType<typeof getNotesError>
  | ReturnType<typeof getMoreNotes>
  | ReturnType<typeof getMoreNotesSuccess>
  | ReturnType<typeof getMoreNotesError>
  | ReturnType<typeof openNoteRequest>
  | ReturnType<typeof openNoteError>
  | ReturnType<typeof openNoteSuccess>
  | ReturnType<typeof addNote>
  | ReturnType<typeof addNoteSuccess>
  | ReturnType<typeof addNoteError>
  | ReturnType<typeof loadNotesCountRequest>
  | ReturnType<typeof loadNotesCountSuccess>
  | ReturnType<typeof loadNotesCountError>;
